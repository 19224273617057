import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
} from "@mui/material";
import CustomDataGrid from "@components/CustomDataGrid";
import { DateTime } from "luxon";

const StatusChangesPanel = ({ statusChanges }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Status
            </TableCell>
            <TableCell>
              Date
            </TableCell>
            <TableCell>
              Notes
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusChanges.map(({ status, date, notes }) => {
            return (
              <TableRow key={date}>
                <TableCell>
                  {status}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(date).toLocaleString()}
                </TableCell>
                <TableCell>
                  {notes}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

const ApplicationsPanel = ({ orgAsset }) => {
  const getDetailPanelHeight = useCallback(() => 300, []);
  const getOrgAssetsDetailPanel = useCallback(
    ({ row: application }) => <StatusChangesPanel statusChanges={application.statusChanges} />,
    []
  );
  const columns = useMemo(
    () => [
      { headerName: "ID", field: "id", flex: 0.8 },
      { headerName: "Status", field: "status", flex: 1 },
      { headerName: "Customer Project", field: "customerProject", valueGetter: ({ value: customerProject }) => customerProject?.title, flex: 1.2 },
    ],
    []
  );
  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Applications
        </Typography>
      </Box>
      <div style={{ height: "100%", width: "100%" }}>
        <CustomDataGrid
          style={{ border: 0 }}
          autoHeight
          rowHeight={50}
          rows={orgAsset.applications ?? []}
          columns={columns}
          disableSelectionOnClick
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getOrgAssetsDetailPanel}
        />
      </div>
    </Paper>
  );
};

ApplicationsPanel.propTypes = {
  orgAsset: PropTypes.shape({}),
};

export default ApplicationsPanel;
