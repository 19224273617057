import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { createProjectReport, getProjectReportCalculation } from "@views/admin/project/actions";
import CreateVoluntaryProjectReport from "./CreateVoluntaryProjectReport"
import CreateComplianceProjectReport from "./CreateComplianceProjectReport"
import CreateCSACleanProjectsProjectReport from "./CreateCSACleanProjectsProjectReport";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateProjectReportEditor = ({ open, onClose, project, startDate }) => {
  const dispatch = useDispatch();

  const isVoluntary = project.environmentalCredit === "REWATT_VOLUNTARY_CREDIT";
  const isAlbertaEmissionsOffset = project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";
  const isCSACleanProjects = project.environmentalCredit === "CSA_CLEAN_PROJECTS_OFFSET";

  const done = () => {
    dispatch(createProjectReport.reset());
    dispatch(getProjectReportCalculation.reset());
    onClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={done} TransitionComponent={Transition}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={done} aria-label="close" size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Create Project Report
          </Typography>
        </Toolbar>
      </AppBar>
      {isVoluntary && <CreateVoluntaryProjectReport project={project} startDate={startDate} />}
      {isAlbertaEmissionsOffset && <CreateComplianceProjectReport project={project} startDate={startDate} />}
      {isCSACleanProjects && <CreateCSACleanProjectsProjectReport project={project} startDate={startDate} />}
    </Dialog>
  );
};

CreateProjectReportEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({}).isRequired,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
};

export default CreateProjectReportEditor;
