import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CustomDataGrid from "@components/CustomDataGrid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getPublicProjects } from "../actions";
import Page from '@components/Page';
import { LinkIcon } from "@heroicons/react/solid";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import PageHeader from '@components/PageHeader';
import { renderCellExpand } from '@components/GridCellExpand';
import { Box } from '@mui/system';
import { EMISSION_REDUCTION_ACTIVITIES } from "@views/program/components/Program";
import HeroIcon from 'components/HeroIcon';
import LaunchIcon from '@mui/icons-material/Launch';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopy } from '@mui/icons-material';
import { getURL } from '@views/customerProjects/CustomerProjects/components/CustomerProjectDetails';

const CopyLink = ({ open, handleClose, customerProject, url }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="Copy Link"
    aria-describedby="Copy a link to this project for a website or for emails"
  >
    <DialogTitle id="alert-dialog-title">Share Project Link</DialogTitle>
    <DialogContent>
      <DialogContentText variant="body1">
        Copy this link to share this project, <strong>{customerProject?.title}</strong>. Feel free
        to include in emails, websites, DMs, and more. We will track and compensate you
        for every environmental credit sold when people use this link with your referral code.
      </DialogContentText>
      <TextField
        variant="standard"
        value={url}
        autoFocus
        margin="dense"
        label="Program Link"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mb: 1 }}>
              <CopyToClipboard text={url}>
                <Tooltip title="Copy link to clipboard">
                  <IconButton size="large">
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
          inputProps: { readOnly: true },
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Done
      </Button>
    </DialogActions>
  </Dialog>
);

const Programs = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { status, projects } = useSelector((state) => state.getPublicProjects);
  const isLoading = status === "request";
  useEffect(() => {
    const init = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getPublicProjects({ accessToken }));
    };
    init();
  }, [dispatch, getAccessTokenSilently]);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([]);

  const [selectedProject, setSelectedProject] = useState(null);

  console.log(selectedProject, userProfile.organizationId, getURL(selectedProject, userProfile?.organizationId));
  const columns = [
    {
      headerName: 'Managing Organization',
      field: 'organization',
      flex: 1,
      valueGetter: ({ row: project }) => project.customer?.name,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Project Title',
      field: 'title',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Emissions Reduction Activity',
      field: 'emissionReductionActivity',
      flex: 1,
      valueGetter: ({ row: { emissionReductionActivity } }) =>
        get(
          EMISSION_REDUCTION_ACTIVITIES,
          emissionReductionActivity,
          emissionReductionActivity
        ),
      renderCell: renderCellExpand,
    },
    {
      field: "applyAction",
      headerName: "Apply",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row: project }) => (
        <Link
          href={getURL(project, userProfile?.organizationId)}
          target="_blank"
          rel="noopener"
        >
          <IconButton edge="end" aria-label="apply-to-project" size="large">
            <LaunchIcon />
          </IconButton>
        </Link>
      )
    },
    {
      field: "shareLinkAction",
      headerName: "Share",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row: project }) => (
        <IconButton
          onClick={() => {
            setSelectedProject(project);
          }}
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
        >
          <HeroIcon icon={LinkIcon} />
        </IconButton>
      ),
    },
  ];

  return (
    <Page py={3} title="Programs">
      <Container maxWidth="lg">
        <PageHeader
          title="List of Projects"
          subtitle="All projects you can refer your customers to."
        />
        <Paper sx={{ mt: 3 }}>
          <Box height={400}>
            <CustomDataGrid
              style={{ border: 0 }}
              rowHeight={50}
              loading={isLoading}
              rows={projects ?? []}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50]}
            />
          </Box>
        </Paper>
        <CopyLink
          customerProject={selectedProject}
          open={selectedProject !== null}
          handleClose={() => setSelectedProject(null)}
          url={getURL(selectedProject, userProfile?.organizationId)}
        />
      </Container>
    </Page>
  );
};

export default Programs;
