import { put, call, takeLatest, select } from "redux-saga/effects";
import logger from "debug";
import { saveOrganization } from "../actions";
import getOrganization from "../selectors";
import axios from "axios";
import { isEmpty, join, values } from "lodash";
import { showAppMessage } from "@appMessage/actions";
import { labelAddresses } from "@utils/locationHelpers";

const callApi = async (accessToken, organization) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations`,
    {
      ...organization,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export const prepareOrganization = (organization) => {
  const {
    contact: {
      firstName: givenName,
      lastName: familyName,
      email,
      phone,
      jobTitle,
    },
    addresses,
  } = organization;

  const transformed = {
    name: organization.name,
    phone: isEmpty(organization.phone) ? undefined : organization.phone,
    website: isEmpty(organization.website) ? undefined : organization.website,
    industry: isEmpty(organization.industry) ? undefined : organization.industry,
    addresses: labelAddresses(addresses),
    contact: {
      givenName,
      familyName,
      email,
      phone,
      jobTitle: isEmpty(jobTitle) ? "N/A" : jobTitle,
    },
  };
  return transformed;
};

function* saveOrganizationWorker(action) {
  yield put(saveOrganization.request());

  const organization = yield select(getOrganization);
  const { accessToken, userId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, prepareOrganization(organization));
    yield put(
      saveOrganization.success({
        organization: response.data,
        // this is for the action.payload of any listeners (eg. getUserProfile)
        accessToken,
        userId,
      })
    );
    yield put(
      showAppMessage({
        severity: "success",
        message: `Successfuly added ${response.data.name}. Please press Continue.`,
        duration: 5000,
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error saving organization: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error saving organization: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error saving organization: ", err);
      message = err.message;
    }
    message = `Unable to save organization: ${message}`;
    yield put(
      saveOrganization.failure({
        error: message,
      })
    );
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* saveOrganizationWatcher() {
  yield takeLatest(saveOrganization.TRIGGER, saveOrganizationWorker);
}

export default [saveOrganizationWatcher];
