import React, { useEffect } from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { isNil } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizations } from "@admin/marketplace/actions";
import { patchSitePayee, getProjectSites } from "../../actions";
import Notification, { notificationReducer } from "@components/Notification";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
});

/**
 * Specify an existing user on the platform to be payee.
 *
 * @param  {[type]} options.site    This is the org asset
 * @param  {[type]} options.project This is the project we are looking at.
 * @param  {[type]} options.onClose Close the dialog
 */
const SetPayeeEditor = ({ site, project, onClose }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [notification, setNotification] = React.useReducer(notificationReducer, {
    open: false,
  });

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      notify: true,
    },
  });

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_sites admin_marketplace",
    });

    const onError = (message) =>
      setNotification({
        type: "show",
        payload: {
          severity: "error",
          message,
        },
      });

    const onSuccess = () =>
      setNotification({
        type: "show",
        payload: {
          severity: "success",
          message: "Successfully set payee. Please press done.",
        },
      });

    const { email: payee, notify } = data;

    dispatch(
      patchSitePayee({ accessToken, payee, siteId: site.id, notify, onError, onSuccess })
    );
  };

  const { status } = useSelector((state) => state.patchSitePayee);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleDone = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });

    // refetch sites, to update the payee cell
    dispatch(getProjectSites({ accessToken, projectId: project.id }));
    onClose();
  };

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_marketplace",
      });
      dispatch(getOrganizations({ accessToken }));
    }
    init();
  }, [dispatch, getAccessTokenSilently]);

  return (
    <>
      <Notification
        open={notification.open}
        hide={() => setNotification({ type: "hide" })}
        message={notification.message}
        severity={notification.severity}
      />
      <DialogContent sx={{ m: 0 }}>
        <DialogContentText>
          Specify an existing user on the platform to be paid when credits are sold.
        </DialogContentText>

        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.email?.message)}
              label="Email"
              placeholder="john@company.org"
              helperText={errors.email?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
              autoFocus
            />
          )}
          control={control}
          name="email"
        />
        <Controller
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(e, checked) => {
                    onChange(checked);
                    e.stopPropagation(); // prevent dismiss dialog
                  }}
                  color="secondary"
                />
              }
              label="Notify user by email"
              sx={{ mt: 1 }}
            />
          )}
          control={control}
          name="notify"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="text"
          disabled={isSubmitting || isSuccess}
          sx={{ marginRight: 1 }}
        >
          Cancel
        </Button>
        {!isSuccess && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(onSubmit)()}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Set Payee
          </LoadingButton>
        )}
        {isSuccess && (
          <Button variant="contained" color="primary" onClick={handleDone}>
            Done
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default SetPayeeEditor;
