import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  CardActions,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { patchCertificate, createCertificate, deleteCertificate } from "../actions";
import CertificateEditor from "./CertificateEditor";

const CreateCertificateCard = ({ createAction }) => (
  <Card sx={{ p: 1 }}>
    <CardContent>
      <Typography>
        This certificate allows you to publicly share your investment in emission reductions. After
        adding your company logo, a statement about your investment, and a brief title, anyone can
        view your certificate. A link will be provided that you can share or use on your own
        website.
      </Typography>
    </CardContent>
    <CardActions sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}>
      <Button variant="contained" onClick={createAction}>
        Create
      </Button>
    </CardActions>
  </Card>
);

const ViewCertificateCard = ({ projectReport, editAction }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const getURL = () =>
    `${process.env.REACT_APP_PUBLIC_URL}/public/certificates/${projectReport.id}`;
  const viewCertificate = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const { status: deleteCertStatus } = useSelector((state) => state.deleteCertificate);
  const isDeleting = deleteCertStatus === "request";

  return (
    <Card sx={{ p: 1 }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              value={getURL()}
              autoFocus
              margin="dense"
              label="Public Cerificate Link"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ mt: -1 }}>
                    <CopyToClipboard text={getURL()}>
                      <Tooltip title="Copy link to clipboard">
                        <div>
                          <IconButton size="large">
                            <FileCopyIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
                inputProps: { readOnly: true },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}>
        <LoadingButton
          loading={isDeleting}
          disabled={isDeleting}
          variant="contained"
          color="error"
          onClick={async (event) => {
            const accessToken = await getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: "manage_credits",
            });
            dispatch(deleteCertificate({ accessToken, projectReportId: projectReport.id }));
          }}
        >
          Delete
        </LoadingButton>
        <Button variant="contained" onClick={editAction}>
          Edit
        </Button>
        <Button
          variant="contained"
          onClick={() => viewCertificate(getURL())}
          endIcon={<ArrowForwardIcon />}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

const CertificatePanel = ({ projectReport }) => {
  const dispatch = useDispatch();

  const [certificate, setCertificate] = useState(null);
  useEffect(() => {
    setCertificate(projectReport?.certificate);
  }, [projectReport]);

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const openEditor = () => {
    setIsEditorOpen(true);
  };
  const closeEditor = () => {
    dispatch(patchCertificate.reset());
    dispatch(createCertificate.reset());
    setIsEditorOpen(false);
  };

  const handleSave = (certificate) => {
    setCertificate(certificate);
  };

  // after delete
  const { status: deleteStatus } = useSelector(state => state.deleteCertificate)
  useEffect(() => {
    if (deleteStatus === "success") {
      dispatch(deleteCertificate.reset());
      setCertificate(null);
    }
  }, [deleteStatus, dispatch]);

  return (
    <>
      <Typography variant="h3" gutterBottom ml={2}>
        Certificate of Authenticity
      </Typography>
      <CertificateEditor
        open={isEditorOpen}
        close={closeEditor}
        onSave={handleSave}
        certificate={certificate}
        projectReportId={projectReport.id}
      />
      {certificate ? (
        <ViewCertificateCard projectReport={projectReport} editAction={openEditor} />
      ) : (
        <CreateCertificateCard createAction={openEditor} />
      )}
    </>
  );
};

export default CertificatePanel;
