import React, { useMemo, forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import CustomDataGrid from "@components/CustomDataGrid";
import { findIndex, includes, find, map, every, isNil } from "lodash";
import { getSiteColumns } from "@views/sites/components/Sites";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import ViewIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrgAsset } from "@views/admin/orgAsset/actions";
import { renderCellExpand } from "@components/GridCellExpand";
import { getFullName } from "@utils/stringHelpers";
import PayeeEditor from "./payeeEditor/PayeeEditor";
import { removeSiteFromProject } from "../actions";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import isNull from "lodash/isNull";
import EmailLink from "@components/EmailLink";
import LinkTooltip from "@components/LinkTooltip";

const ConfirmRemoveSite = ({ open, close, project, site }) => {
  const { status } = useSelector((state) => state.removeSiteFromProject);
  const isRemoving = status === "request";
  const isRemoveSuccessful = status === "success";
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const doRemove = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects"
    });
    dispatch(removeSiteFromProject({
      accessToken,
      projectId: project.id,
      siteId: site.id
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="remove-site-dialog-title"
      aria-describedby="remove-site-dialog-description"
    >
      <DialogTitle id="remove-site-dialog-title">Remove Site From Project</DialogTitle>
      <DialogContent>
        <DialogContentText color="primary" gutterBottom>
          {isRemoveSuccessful
            ? "You have successfully removed the site from the project. You may close this menu."
            : `Are you sure you want to remove ${site?.name} from ${project.title}?`
          }
        </DialogContentText>
        <DialogContentText variant="body2">
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isRemoveSuccessful ? (
          <Button onClick={close}>
            Close
          </Button>
        ) : (
          <>
            <Button onClick={close}>
              Cancel
            </Button>
            <LoadingButton
              onClick={doRemove}
              loading={isRemoving}
              disabled={isRemoving}
              color="primary"
              autoFocus
            >
              Yes
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const ProjectSites = forwardRef(({ project }, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [siteToRemove, setSiteToRemove] = useState(null);
  const confirmRemoveSite = ({ site }) => {
    setSiteToRemove(site);
  };
  const closeRemove = () => {
    setSiteToRemove(null);
    dispatch(removeSiteFromProject.reset());
  };
  const isOpen = !isNull(siteToRemove);

  const [tableRows, setTableRows] = useState([]);
  const { sites, status } = useSelector((state) => state.getProjectSites);
  const loading = includes(["init", "request"], status);

  const { invites } = useSelector((state) => state.getPayeeInvites);

  // merge sites and invites
  useEffect(() => {
    const rows = map(sites, (site) => {
      if (site.payee) return site;
      const invite = find(invites, (invite) => invite.asset.id === site.id);
      if (invite?.status === "INVITED") {
        return { ...site, invite };
      }
      return site;
    });
    setTableRows(rows);
  }, [sites, invites]);

  // table columns
  const columns = useMemo(() => {
    const columns = getSiteColumns({});

    // add activity start date
    let idx = findIndex(columns, { field: "status" });
    columns.splice(idx, 0, {
      headerName: "Activity Start",
      field: "activityStartDate",
      flex: 1,
      valueGetter: ({ row: site }) => {
        const projectRow = find(site.projects, { id: project.id });
        return DateTime.fromISO(projectRow?.activityStartDate)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT);
      },
      renderCell: renderCellExpand
    });

    // payee
    idx += 1;
    columns.splice(idx, 0, {
      headerName: "Payee",
      field: "payee",
      flex: 1,
      description: "Double-click to set or invite a payee.",
      valueGetter: ({ row: site }) => site.payee,
      renderCell: ({ row: site }) => {
        const emailLink = site.invite
          ? <EmailLink email={site.invite?.invitee}>{site.invite?.inviteeName} {`<${site.invite?.invitee}>`}</EmailLink>
          : <EmailLink email={site.payee?.email}>{site.payee?.email}</EmailLink>;
        return (<LinkTooltip title={emailLink}>
          <Typography variant="body2">
            {site.invite ? "INVITED" : getFullName(site.payee, null, "MISSING")}
          </Typography>
        </LinkTooltip>);
      },
      cellClassName: ({ row: site }) => (site.invite ? "invited" : (site.payee ? "" : "missing")),
      editable: true,
      renderEditCell: (params) => <PayeeEditor {...params} project={project} />
    });

    // bank info
    const isBankInfoSet = (site) => {
      if (!site.payee?.bankInformation) return false;
      const { institutionNumber, transitNumber, accountNumber } = site.payee.bankInformation;
      return every([institutionNumber, transitNumber, accountNumber], (s) => !isNil(s));
    };
    idx += 1;
    columns.splice(idx, 0, {
      headerName: "Bank Info",
      field: "bankInfo",
      flex: 1,
      description: "Has the payee set their bank information",
      type: "boolean",
      valueGetter: ({ row: site }) => isBankInfoSet(site),
      cellClassName: ({ row: site }) => (isBankInfoSet(site) ? "checkmark" : "xmark")
    });

    // override view
    idx = findIndex(columns, { field: "viewAction" });
    columns.splice(idx, 1, {
      field: "viewAction",
      headerName: "View",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(ev) => {
            dispatch(getOrgAsset.reset());
            const orgAsset = params.row;
            const url = `/admin/orgAssets/${orgAsset.id}?projectId=${project.id}`;
            navigate(url);
          }}
          aria-label="view org asset"
          size="small"
          sx={{ ml: 1 }}
        >
          <ViewIcon />
        </IconButton>
      )
    });

    // remove site from project
    idx += 1;
    columns.splice(idx, 1, {
      field: "removeAction",
      headerName: "Remove",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: ({ row: site }) => (
        <IconButton
          onClick={async () => {
            confirmRemoveSite({ site });
          }}
          aria-label="remove site from project"
          size="small"
          sx={{ ml: 1 }}
        >
          <DeleteIcon />
        </IconButton>
      )
    });

    return columns;
  }, [navigate, project, dispatch]);

  const [pageSize, setPageSize] = useState(10);
  return (
    <>
      <Typography ref={ref} variant="h3" gutterBottom ml={2}>
        Project Sites
      </Typography>

      <Paper>
        <Box
          sx={{
            height: 400,
            width: "100%",
            "& .missing p": {
              color: "error.main"
            },
            "& .invited p": {
              color: "info.main"
            },
            "& .checkmark .MuiSvgIcon-root.MuiDataGrid-booleanCell[data-value='true']": {
              color: "success.main"
            },
            "& .xmark .MuiSvgIcon-root.MuiDataGrid-booleanCell[data-value='false']": {
              color: "error.main"
            }
          }}
        >
          <CustomDataGrid
            style={{ border: 0 }}
            rowHeight={50}
            loading={loading}
            rows={tableRows}
            columns={columns}
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            localeText={{
              noRowsLabel: "No project sites."
            }}
          />
          <ConfirmRemoveSite
            open={isOpen}
            close={closeRemove}
            project={project}
            site={siteToRemove}
          />
        </Box>
      </Paper>
    </>
  );
});

ProjectSites.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  })
};

export default ProjectSites;
