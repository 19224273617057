import { connect } from "react-redux";
import Landing from "../components/Landing";
import { bindActionCreators } from "redux";
import { verifyToken, patchApplication } from "../actions";

const mapStateToProps = ({
  verifyToken: verifyTokenResult,
  patchApplication: patchApplicationResult,
}) => ({
  verifyTokenResult,
  patchApplicationResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verifyToken,
      patchApplication,
      resetPatchApplication: () => dispatch(patchApplication.reset()),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
