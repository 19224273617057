import { call, put, takeLatest } from "redux-saga/effects";
import { patchSitePayee } from "../actions";
import axios from "axios";
import logger from "debug";

const callApi = async (accessToken, assetId, payee, notify) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/assets/${assetId}`,
    {
      payee,
      notify,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* patchSitePayeeWorker(action) {
  yield put(patchSitePayee.request());

  const { accessToken, siteId, payee, notify, onSuccess, onError } = action.payload;

  try {
    const response = yield call(callApi, accessToken, siteId, payee, notify);
    const site = response.data;
    yield put(patchSitePayee.success({ site }));
    onSuccess();
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error updating site: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error updating site: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error updating site: ", err);
      message = err.message;
    }
    message = `Unable to update site: ${message}`;
    yield put(patchSitePayee.failure({ error: message }));
    onError(message);
  }
}

function* patchSitePayeeWatcher() {
  yield takeLatest(patchSitePayee.TRIGGER, patchSitePayeeWorker);
}

export default [patchSitePayeeWatcher];
