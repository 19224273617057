import React, { useEffect, useMemo, useState } from "react";
import { Paper, Box, IconButton, Container } from "@mui/material";
import CustomDataGrid from "@components/CustomDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { renderCellExpand } from "@components/GridCellExpand";
import Page from "@components/Page";
import PageHeader from "components/PageHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { getCustomerProject, getCustomerProjects } from "../actions";
import ViewIcon from "@mui/icons-material/Visibility";
import { EMISSION_REDUCTION_ACTIVITIES } from "views/program/components/Program";
import CustomerProjectCreator from "./CustomerProjectCreator";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import { useNavigate } from "react-router";

const CustomerProjects = () => {
  // get projects
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.getOrganization);
  useEffect(() => {
    async function init(organizationId) {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProjects({ accessToken, organizationId }));
    }
    if (organization?.id) {
      init(organization.id);
    }
  }, [getAccessTokenSilently, dispatch, organization?.id]);

  const { status, customerProjects } = useSelector((state) => state.getCustomerProjects);
  const loading = status === "request";

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "created",
      sort: "desc",
    },
  ]);

  // customer projects table
  const columns = useMemo(() => [
    {
      headerName: "Title",
      field: "title",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Activity",
      field: "emissionReductionActivity",
      flex: 1,
      renderCell: ({ row: { emissionReductionActivity } }) => EMISSION_REDUCTION_ACTIVITIES[emissionReductionActivity],
    },
    {
      headerName: "Site Count",
      field: "siteCount",
      flex: 1,
      type: "number",
    },
    {
      headerName: "Is Public",
      field: "isPublic",
      flex: 1,
      renderCell: ({ row: { isPublic }}) => isPublic ? 'Yes' : 'No',
    },
    {
      headerName: "Application Count",
      field: "applicationCount",
      flex: 1,
      type: "number",
      renderCell: ({ row: { isPublic, applicationCount }}) => isPublic ? applicationCount : "",
    },
    {
      headerName: "Date Created",
      field: "created",
      flex: 1,
      valueFormatter: ({ value }) =>
        DateTime.fromSeconds(value)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT),
    },
    {
      field: "viewAction",
      headerName: "View",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            const customerProjectId = params.row.id;
            dispatch(getCustomerProject.reset());
            navigate(`/projects/${customerProjectId}`);
          }}
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
        >
          <ViewIcon />
        </IconButton>
      ),
    },
  ], [navigate, dispatch]);

  // add project action
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);

  const handleAdd = () => {
    setIsCreateProjectOpen(true);
  };
  const handleClose = () => {
    setIsCreateProjectOpen(false);
  };

  return (
    <Page py={3} title="Projects">
      <Container maxWidth="lg">
        <PageHeader
          title="Projects"
          action={{
            name: "Add Project",
            handle: handleAdd,
          }}
        />
        <Paper sx={{ mt: 3 }}>
          <Box sx={{ height: 400 }}>
            <CustomDataGrid
              style={{ border: 0 }}
              rowHeight={50}
              loading={loading}
              rows={customerProjects ?? []}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50]}
            />
            <CustomerProjectCreator open={isCreateProjectOpen} onClose={handleClose} />
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default CustomerProjects;
