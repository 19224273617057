import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Breadcrumb from 'components/Breadcrumb';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { isNil, includes, startCase } from "lodash";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getCustomerProject } from "../../CustomerProjects/actions";
import { getCustomerProjectSite, patchCustomerProjectSite } from "../actions";
import { ViewLocation, EditLocation } from "./Location";
import { ViewDetails, EditDetails } from "./Details";
import { ViewDocuments, EditSiteAddDocuments } from "./Documents";
import { EditSiteAddDevices, ViewDevices } from "../../Devices/components/Devices";
import DialogTransition from "components/DialogTransition";
import ViewOpsSummary from "./ViewOpsSummary";
import SiteIncidents from "views/site/containers/incidents/SiteIncidents";

const Site = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { customerProjectId, siteId } = useParams();

  // get the customer project, if we don't have it
  const getCustomerProjectState = useSelector(state => state.getCustomerProject);
  useEffect(() => {
    async function fetchProject() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProject({ accessToken, customerProjectId }));
    };
    if (
      isNil(getCustomerProjectState.customerProject)
      || getCustomerProjectState.customerProject.id !== +customerProjectId
    ) {
      fetchProject();
    }
  }, [dispatch, getAccessTokenSilently, customerProjectId, getCustomerProjectState.customerProject]);
  const { customerProject } = getCustomerProjectState;
  const isProjectLoading = includes(['request', 'init'], getCustomerProjectState.status);

  // get the site
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProjectSite({ accessToken, customerProjectId, siteId }));
    }
    init();
  }, [getAccessTokenSilently, customerProjectId, siteId, dispatch]);
  const getCustomerProjectSiteState = useSelector(state => state.getCustomerProjectSite);
  const { site } = getCustomerProjectSiteState;
  const isSiteLoading = includes(['request', 'init'], getCustomerProjectSiteState.status);

  // edit the site
  const [editing, setEditing] = useState(null);
  const closeEditor = async () => {
    setEditing(null);
    dispatch(patchCustomerProjectSite.reset());
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(getCustomerProjectSite({
      accessToken,
      customerProjectId: customerProject.id,
      siteId: site.id,
    }));
  };
  const isLoading = isProjectLoading || isSiteLoading;

  return (
    <Page title={`View Site: ${site?.name}`} py={3}>
      <Container maxWidth="lg">
        <PageHeader title={site?.name}>
          <Breadcrumb title="Projects List" destination="/projects" />
          <Breadcrumb title={`Project ${customerProjectId}`} destination={`/projects/${customerProjectId}`} />
        </PageHeader>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={!!editing}
          onClose={closeEditor}
          TransitionComponent={DialogTransition}
          sx={{
            "& .MuiPaper-root.MuiDialog-paper": {
              bgcolor: "background.dark",
            },
          }}
        >
          <AppBar position="sticky">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeEditor}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" color="common.white" ml={2}>
                {`${startCase(editing)}`}
              </Typography>
            </Toolbar>
          </AppBar>
          {editing === 'details' && (
            <EditDetails
              site={site}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
          {editing === 'location' && (
            <EditLocation
              site={site}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
          {editing === 'documents' && (
            <EditSiteAddDocuments
              site={site}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
          {editing === 'addDevice' && (
            <EditSiteAddDevices
              site={site}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
        </Dialog>
        <Box mt={3}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ViewOpsSummary customerProject={customerProject} site={site} isLoading={isLoading}/>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Card>
                <CardHeader
                  title="Site Details"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isSiteLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('details');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewDetails customerProject={customerProject} site={site} isLoading={isLoading}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={7}>
              <Card>
                <CardHeader
                  title="Location"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isSiteLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('location');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewLocation site={site} isLoading={isSiteLoading}/>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Documents"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isSiteLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('documents');
                      }}
                    >
                      Add
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewDocuments site={site} customerProjectId={customerProjectId} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Devices"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isSiteLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('addDevice');
                      }}
                    >
                      Add
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewDevices
                    devices={site?.meters}
                    customerProject={customerProject}
                    siteId={site?.id}
                    editable
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Incidents"/>
                <CardContent>
                  <SiteIncidents />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default Site;
