import { put, call, takeLatest } from "redux-saga/effects";
import logger from "debug";
import { acceptAssetClaimInvite } from "../actions";
import axios from "axios";
import { join, values } from "lodash";

const callApi = async (
  accessToken,
  inviteId,
  organizationId,
  acceptedTermsAndConditions
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/invites/${inviteId}/accept`,
    {
      organizationId,
      acceptedTermsAndConditions,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* acceptAssetClaimInviteWorker(action) {
  yield put(acceptAssetClaimInvite.request());

  const {
    accessToken,
    inviteId,
    organizationId,
    acceptedTermsAndConditions,
  } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      inviteId,
      organizationId,
      acceptedTermsAndConditions
    );
    const { invite, userProfile, siteOwnerAsset } = response.data;
    yield put(acceptAssetClaimInvite.success({ invite, userProfile, siteOwnerAsset }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error accepting invite: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error accepting invite: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error accepting invite: ", err);
      message = err.message;
    }
    message = `Unable to accept invite: ${message}`;
    yield put(
      acceptAssetClaimInvite.failure({
        error: message,
      })
    );
  }
}

function* acceptAssetClaimInviteWatcher() {
  yield takeLatest(acceptAssetClaimInvite.TRIGGER, acceptAssetClaimInviteWorker);
}

export default [acceptAssetClaimInviteWatcher];
