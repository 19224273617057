import {
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const getMyApplications = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("GET_MY_APPLICATIONS");

export { getMyApplications };
