import getOrgAsset from "./getOrgAsset";
import patchOrgAssetDocuments from "./patchOrgAssetDocuments";
import patchActivityStartDate from "./patchActivityStartDate";
import getGenerationChart from "./getGenerationChart";

export default [
  ...getOrgAsset,
  ...patchOrgAssetDocuments,
  ...patchActivityStartDate,
  ...getGenerationChart,
];
