import {
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";
import { createAction } from "redux-actions";
import { getProgram } from "@views/program/actions"

const initApplication = createAction("APPLY_INIT");
initApplication.TYPE = initApplication.toString();

const acceptTermsAndConditions = createAction(
  "ACCEPT_PROGRAM_TERMS_AND_CONDITIONS"
);
acceptTermsAndConditions.TYPE = acceptTermsAndConditions.toString();

const saveOrganizationInfo = createAction("APPLY_SAVE_ORGANIZATION_INFO");
saveOrganizationInfo.TYPE = saveOrganizationInfo.toString();

const saveOwnerType = createAction("APPLY_SAVE_OWNER_TYPE");
saveOwnerType.TYPE = saveOwnerType.toString();

const saveSiteOwner = createAction("APPLY_SAVE_SITE_OWNER");
saveSiteOwner.TYPE = saveSiteOwner.toString();

const saveSiteIsBuilt = createAction("APPLY_SAVE_SITE_IS_BUILT");
saveSiteIsBuilt.TYPE = saveSiteIsBuilt.toString();

const saveManufacturer = createAction("APPLY_SAVE_MANUFACTURER");
saveManufacturer.TYPE = saveManufacturer.toString();

const saveInstallerInfo = createAction("APPLY_SAVE_INSTALLER_INFO");
saveInstallerInfo.TYPE = saveInstallerInfo.toString();

const saveCredentials = createAction("APPLY_SAVE_CREDENTIALS");
saveCredentials.TYPE = saveCredentials.toString();

const saveSite = createAction("APPLY_SAVE_SITE");
saveSite.TYPE = saveSite.toString();

const saveSiteLocation = createAction("APPLY_SAVE_SITE_LOCATION");
saveSiteLocation.TYPE = saveSiteLocation.toString();

const saveSiteDocuments = createAction("APPLY_SAVE_SITE_DOCUMENTS");
saveSiteDocuments.TYPE = saveSiteDocuments.toString();

const saveSiteDetails = createAction("APPLY_SAVE_SITE_INFO");
saveSiteDetails.TYPE = saveSiteDetails.toString();

const saveIndividualAddress = createAction("APPLY_SAVE_INDIVIDUAL_ADDRESS");
saveIndividualAddress.TYPE = saveIndividualAddress.toString();

const saveReferrer = createAction("APPLY_SAVE_REFERRER");
saveReferrer.TYPE = saveReferrer.toString();

const resetApplication = createAction("APPLY_RESET_APPLICATION");
resetApplication.TYPE = resetApplication.toString();

const submitApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("SUBMIT_APPLICATION");

export {
  getProgram,
  initApplication,
  acceptTermsAndConditions,
  saveOrganizationInfo,
  saveSiteOwner,
  saveOwnerType,
  saveSiteIsBuilt,
  saveManufacturer,
  saveInstallerInfo,
  saveCredentials,
  saveSite,
  saveSiteDetails,
  saveSiteLocation,
  saveSiteDocuments,
  saveIndividualAddress,
  saveReferrer,
  submitApplication,
  resetApplication,
};
