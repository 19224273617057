import getBuyerInvites from "./getBuyerInvites";
import getOrganizations from "./getOrganizations";
import addBuyerInvite from "./addBuyerInvite";
import deleteBuyerInvite from "./deleteBuyerInvite";
import resendBuyerInvite from "./resendBuyerInvite";
import getAdminPurchaseRequests from "./getAdminPurchaseRequests";
import getPurchaseRequestForProjectReport from "./getPurchaseRequestForProjectReport";
import updatePurchaseRequestStatus from "./updatePurchaseRequestStatus";
import getProjectReportsForStatements from "./getProjectReportsForStatements";
import getProjectReportForStatements from "./getProjectReportForStatements";
import createPayeeStatement from "./createPayeeStatement";
import downloadEft from "./downloadEft";
import sendPayeeStatements from "./sendPayeeStatements";

export default [
  ...getBuyerInvites,
  ...addBuyerInvite,
  ...getOrganizations,
  ...deleteBuyerInvite,
  ...resendBuyerInvite,
  ...getAdminPurchaseRequests,
  ...getPurchaseRequestForProjectReport,
  ...updatePurchaseRequestStatus,
  ...createPayeeStatement,
  ...getProjectReportsForStatements,
  ...getProjectReportForStatements,
  ...downloadEft,
  ...sendPayeeStatements
];
