import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty, isNil } from "lodash";
import CustomDataGrid from "@components/CustomDataGrid";
import { getSiteColumns } from "@views/sites/components/Sites";
import pluralize from "pluralize";
import { useAppMessage } from "@components/appMessage";
import GreenButton from "@components/GreenButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InviteQualifiedSites = ({
  open,
  onClose,
  program,
  getQualifiedSites,
  getQualifiedSitesResult,
  inviteQualifiedSites,
  inviteQualifiedSitesResult,
  resetInviteQualifiedSites,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  const { status, sites, total } = getQualifiedSitesResult;
  const loading = status === "request";

  // table
  const columns = useMemo(() => getSiteColumns({}), []);

  // state of the table (no redux)
  const [tableState, setTableState] = useState({
    page: 0,
    pageSize: 10,
    sortModel: [],
    filterModel: {
      items: [],
      linkOperator: "and",
    },
  });
  const { page, pageSize, sortModel, filterModel } = tableState;
  const programId = program.id;

  // refetch if table state changes
  useEffect(() => {
    const fetchRows = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getQualifiedSites({
        accessToken,
        programId,
        page: page + 1,
        pageSize: pageSize,
        order: !isEmpty(sortModel)
          ? `${sortModel[0]?.field}:${sortModel[0]?.sort}`
          : undefined,
        search:
          !isEmpty(filterModel?.items) && !isNil(filterModel?.items?.[0].value)
            ? `${filterModel.items[0].columnField}:${filterModel.items[0].value}`
            : undefined,
      });
    };
    fetchRows();
  }, [
    programId,
    page,
    pageSize,
    sortModel,
    filterModel,
    getAccessTokenSilently,
    getQualifiedSites,
  ]);

  const [selectionModel, setSelectionModel] = useState([]);

  // submit
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    if (isEmpty(selectionModel)) {
      showAppMessage({
        severity: "warning",
        message: "Please choose at least 1 site.",
      });
    } else {
      inviteQualifiedSites({ accessToken, programId, siteIds: selectionModel });
    }
  };

  const isSubmitting = inviteQualifiedSitesResult.status === "request";
  const isSuccess = inviteQualifiedSitesResult.status === "success";

  // if success, we show a continue button
  const onContinue = () => {
    resetInviteQualifiedSites();
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <AppBar sx={{ bgcolor: "primary.main" }} position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Invite Existing Sites to Program
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Box py={3}>
          <Typography fontWeight="bold">
            Program Name
            <Typography component="span" ml={1}>
              {program.name}
            </Typography>
          </Typography>
          <Typography variant="h4" mt={3} gutterBottom>
            Select sites to include
          </Typography>
          <div style={{ height: "100%", width: "100%" }}>
            <CustomDataGrid
              autoHeight
              loading={loading}
              rows={sites}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50]}
              disableSelectionOnClick
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(sortModel) =>
                setTableState({ ...tableState, sortModel })
              }
              page={page}
              onPageChange={(page) => setTableState({ ...tableState, page })}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) =>
                setTableState({ ...tableState, pageSize })
              }
              filterModel={filterModel}
              onFilterModelChange={(filterModel) =>
                setTableState({ ...tableState, filterModel })
              }
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              localeText={{
                footerRowSelected: (count) =>
                  `${count.toLocaleString()} ${pluralize(
                    "site",
                    count
                  )} selected`,
                noRowsLabel: "No existing sites qualify",
              }}
              rowCount={total || 0}
            />
          </div>
          <Box display="flex" justifyContent="flex-end" mt={5}>
            {!isSuccess && (
              <LoadingButton
                aria-label="Invite Sites"
                color="primary"
                variant="contained"
                onClick={onSubmit}
                loading={isSubmitting}
                size="large"
                sx={{ marginBottom: 1 }}
              >
                Invite Sites
              </LoadingButton>
            )}
            {isSuccess && (
              <GreenButton onClick={onContinue} size="large">
                Continue
              </GreenButton>
            )}
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

InviteQualifiedSites.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  program: PropTypes.shape({}).isRequired,
  getQualifiedSites: PropTypes.func.isRequired,
  getQualifiedSitesResult: PropTypes.shape({}).isRequired,
  inviteQualifiedSites: PropTypes.func.isRequired,
  inviteQualifiedSitesResult: PropTypes.shape({}).isRequired,
  resetInviteQualifiedSites: PropTypes.func.isRequired,
};

export default InviteQualifiedSites;
