import { call, put, takeLatest } from "redux-saga/effects";
import { getProjectChartByMonth, getProjectChartByYear } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { map } from "lodash";

const callApi = async (accessToken, projectId, type) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/chart`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        type,
      },
    }
  );
  return response;
};

function* getProjectChartWorker(action, type) {
  const reducer =
    type === "monthly" ? getProjectChartByMonth : getProjectChartByYear;
  const { projectId, accessToken } = action.payload;

  yield put(reducer.request());

  try {
    const response = yield call(callApi, accessToken, projectId, type);
    yield put(
      reducer.success({
        data: map(response.data, (datum) => ({
          ...datum,
          date: datum.date?.toString(),
        })),
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting project chart: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting project chart: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting project chart: ", err);
      message = err.message;
    }
    message = `Unable to get project chart: ${message}`;
    yield put(reducer.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProjectChartByMonthWatcher() {
  yield takeLatest(getProjectChartByMonth.TRIGGER, (action) =>
    getProjectChartWorker(action, "monthly")
  );
}

function* getProjectChartByYearWatcher() {
  yield takeLatest(getProjectChartByYear.TRIGGER, (action) =>
    getProjectChartWorker(action, "yearly")
  );
}

export default [getProjectChartByMonthWatcher, getProjectChartByYearWatcher];
