import React, { useEffect, useState } from "react";
import { Paper, Box, Grid, Typography, IconButton, Link } from "@mui/material";
import { includes } from "lodash";
import CustomDataGrid from "@components/CustomDataGrid";
import { useSelector } from "react-redux";
import { renderCellExpand } from "@components/GridCellExpand";
import Consent from "@admin/components/Consent";
import { getChannelPartners } from "../actions";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import ViewIcon from "@mui/icons-material/Visibility";
import { EMISSION_REDUCTION_ACTIVITIES } from "views/program/components/Program";

const ChannelPartnersList = () => {
  const navigate = useNavigate();
  const { isLoading } = useAuth0();
  const [loading, setLoading] = useState(true);

  const { status, channelPartners } = useSelector((state) => state.getChannelPartners);
  useEffect(() => {
    // if we get consent_required, we'll be stuck on init
    const fetchingChannelPartners = includes(["init", "request"], status);
    setLoading(fetchingChannelPartners || isLoading);
  }, [isLoading, status]);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "name",
      sort: "desc",
    },
  ]);

  // channel partners table
  const columns = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Primary Contact",
      field: "primaryContactName",
      flex: 1,
      renderCell: ({ row: { primaryContact: { givenName, familyName, email } }}) =>
        <Link href={`mailto://${email}`}>{`${givenName} ${familyName}`}</Link>
    },
    {
      headerName: "Emission Reduction Activities",
      field: "emissionReductionActivities",
      flex: 1,
      renderCell: ({ row: { contractDetails } }) => contractDetails
        .map(cd => EMISSION_REDUCTION_ACTIVITIES[cd.emissionsReductionActivity])
        .join(',')
    },
    {
      field: "viewAction",
      headerName: "View",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row: channelPartner }) => {
        return (
          <IconButton
            onClick={(ev) => {
              const { id } = channelPartner;
              navigate(`/admin/channelPartners/${id}`, { state: { channelPartner } });
            }}
            aria-label="view"
            size="small"
            sx={{ ml: 1 }}
          >
            <ViewIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Consent action={getChannelPartners} scope="admin_sites">
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              List of Operating Channel Partners
            </Typography>
            <Paper>
              <Box height={400}>
                <CustomDataGrid
                  style={{ border: 0 }}
                  rowHeight={50}
                  loading={loading}
                  rows={channelPartners ?? []}
                  columns={columns}
                  disableSelectionOnClick
                  sortModel={sortModel}
                  onSortModelChange={setSortModel}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Consent>
  );
};

export default ChannelPartnersList;
