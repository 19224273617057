import PropTypes from "prop-types";
import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';

const defaultUtilities = ["ENMAX", "EPCOR", "ATCO", "FORTIS", "EQUS", "SaskPower"];

const filter = createFilterOptions();

const UtilityComboBox = ({ utility, setUtility, ...other }) => {
  return (
    <Autocomplete
      value={utility}
      onChange={(event, newValue) => {
        const match = newValue.match(/^Add "(.+)"$/);
        setUtility({ utility: match ? match[1] : newValue });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push(`Add "${params.inputValue}"`);
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      disableClearable
      handleHomeEndKeys
      blurOnSelect
      id="utility"
      options={defaultUtilities}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Utility Name"
          fullWidth
          variant="standard"
          helperText="Your energy provider. Enter a new one by typing."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          {...other}
        />
      )}
    />
  );
};

UtilityComboBox.defaultProps = {
  utility: "",
};

UtilityComboBox.propTypes = {
  setUtility: PropTypes.func.isRequired,
  utility: PropTypes.string,
};

export default UtilityComboBox;
