import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationInfo from "@views/apply/components/OrganizationInfo";
import { prepareOrganization } from "@views/onboarding/sagas/saveOrganization";
import { acceptChannelPartnerInvite } from "../actions";
import { useAppMessage } from "@components/appMessage";

const Organization = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { invite } = useSelector((state) => state.verifyChannelPartnerInviteToken);

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { familyName, givenName, email, phone } = userProfile;
    dispatch(
      acceptChannelPartnerInvite({
        accessToken,
        inviteId: invite.id,
        acceptedTermsAndConditions: userProfile.acceptedTermsAndConditions,
        channelPartner: prepareOrganization({
          ...data,
          contact: {
            lastName: familyName,
            firstName: givenName,
            email,
            phone,
            jobTitle: "N/A",
          },
        }),
      })
    );
  };

  // submit status
  const { status } = useSelector((state) => state.acceptChannelPartnerInvite);
  const isSubmitting = status === "request";

  useEffect(() => {
    if (status === "success") {
      dispatch(acceptChannelPartnerInvite.reset());
      navigate("/dashboard");
    }
  }, [
    dispatch,
    navigate,
    showAppMessage,
    status,
    getAccessTokenSilently,
    invite.id,
    userProfile.acceptedTermsAndConditions,
  ]);

  const initialOrg = {
    name: "",
    industry: "",
    phone: "",
    website: "",
    addresses: [],
  };

  return (
    <OrganizationInfo
      processTitle="Channel Partner Registration"
      organization={initialOrg}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
    />
  );
};

export default Organization;
