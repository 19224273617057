import React from "react";
import { Box, Container, Grid, IconButton, Link, Paper } from "@mui/material";
import Page from "@components/Page";
import CustomDataGrid from "@components/CustomDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { round, last, find } from "lodash";
import { getAdminPurchaseRequests } from "../../actions";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import { Visibility } from "@mui/icons-material";
import { ENVIRONMENTAL_CREDIT_TYPES } from "views/admin/project/components/Project";
import PurchaseRequest from "./PurchaseRequest";
import PageHeader from "components/PageHeader";
import { renderCellExpand } from "components/GridCellExpand";
import { PURCHASE_REQUEST_STATUSES } from "./PurchaseRequest";

const PurchaseRequests = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { status, purchaseRequests } = useSelector((state) => state.getAdminPurchaseRequests);
  const isLoading = status === "request";
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_marketplace",
      });
      dispatch(getAdminPurchaseRequests({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch]);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "statusDate",
      sort: "desc",
    },
  ]);
  const [purchaseRequestToShow, setPurchaseRequestToShow] = React.useState(null);

  const closePurchaseRequest = () => {
    setPurchaseRequestToShow(null);
  };

  const columns = [
    {
      headerName: "Request Status",
      field: "status",
      flex: 1,
      valueGetter: ({ value }) => PURCHASE_REQUEST_STATUSES[value].display,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Status Date",
      field: "statusDate",
      flex: 1,
      valueFormatter: ({ value }) =>
        DateTime.fromISO(value)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT),
    },
    {
      headerName: "Buyer",
      field: "buyer",
      flex: 0.7,
      renderCell: ({ value }) =>
        <Link
          href={`mailto:${value.email}`}
        >
          {`${value.givenName} ${value.familyName}`}
        </Link>
    },
    {
      headerName: "Credit Type",
      field: "environmentalCredit",
      flex: 1.2,
      valueGetter: ({ value }) => ENVIRONMENTAL_CREDIT_TYPES[value] ?? 'Unknown',
      renderCell: renderCellExpand,
    },
    {
      headerName: "Credits",
      field: "totalEnvironmentalCredits",
      flex: 0.6,
      valueGetter: ({ value }) => round(value),
    },
    {
      headerName: "Total Cost",
      field: "totalCost",
      flex: 1,
      valueGetter: ({ value }) => `$${round(value, 2).toLocaleString()}`,
      renderCell: renderCellExpand,
    },
    {
      field: "id",
      headerName: "View",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => (
        <IconButton
          aria-label="view request"
          size="small"
          sx={{ ml: 1 }}
          onClick={() => {
            setPurchaseRequestToShow(find(purchaseRequests, { id: value }));
          }}
        >
          <Visibility />
        </IconButton>
      )
    },
  ];

  const rows = purchaseRequests.map(pr => {
    const mostRecentStatus = last(pr.statusHistory);
    return {
      id: pr.id,
      status: mostRecentStatus.status,
      statusDate: mostRecentStatus.date,
      buyer: pr.buyer,
      project: pr.projectReport.project.title,
      environmentalCredit: pr.projectReport.project.environmentalCredit,
      totalEnvironmentalCredits: pr.projectReport.totalEnvironmentalCredits,
      totalCost: pr.projectReport.totalEnvironmentalCredits * pr.pricePerCredit,
      viewPurchaseRequest: pr.id,
    };
  }) ?? []

  return purchaseRequestToShow ? (
    <PurchaseRequest
      purchaseRequest={purchaseRequestToShow}
      close={closePurchaseRequest}
    />
  ) : (
    <Page py={3} title="Purchase Requests">
      <Container maxWidth="lg">
        <PageHeader title="Purchase Requests" />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper>
                <Box height={400}>
                  <CustomDataGrid
                    style={{ border: 0 }}
                    rowHeight={50}
                    loading={isLoading}
                    rows={rows}
                    columns={columns}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default PurchaseRequests;
