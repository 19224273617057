import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isNil, isEmpty } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomerProjectFormFields, { schema } from "./CustomerProjectFormFields";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerProject, patchCustomerProject } from "../actions";
import { AppBar, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogTransition from "components/DialogTransition";
import DialogSaveButton from "components/DialogSaveButton";

const CustomerProjectEditor = ({ open, onClose, customerProject }) => {
  const customerProjectId = customerProject.id;
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      title: customerProject.title,
      description: customerProject.description || "",
      emissionReductionActivity: customerProject.emissionReductionActivity,
      isPublic: customerProject.isPublic || false,
      logo: customerProject.logo || null, // filespec
      termsAndConditions: customerProject.termsAndConditions || null, // filespec
      managerId: customerProject.manager?.id || "",
    },
  });

  // submit
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const onSubmit = async (payload) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    if (isNil(payload.description) || isEmpty(payload.description)) {
      payload.description = null;
    }
    if (payload.managerId) payload.managerId *= 1;
    if (payload.termsAndConditions) {
      const tcs = payload.termsAndConditions;
      payload.termsAndConditions = {
        name: tcs.file?.name || tcs.name,
        tags: tcs.tags?.map(t => t.name || t),
        uri: tcs.uri,
      };
    }
    if (payload.logo) {
      const { logo } = payload;
      payload.logo = {
        name: logo.file?.name || logo.name,
        tags: logo.tags?.map(t => t.name || t),
        uri: logo.uri,
      };
    }
    dispatch(patchCustomerProject({
      payload,
      accessToken,
      customerProjectId,
    }));
  };
  const { status } = useSelector((state) => state.patchCustomerProject);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleClose = async () => {
    methods.reset();
    dispatch(patchCustomerProject.reset());
    if (isSuccess) {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProject({ accessToken, customerProjectId }));
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={DialogTransition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          bgcolor: "background.dark",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Edit Project
          </Typography>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <DialogContent>
          {isSuccess && (
            <Typography gutterBottom>
              Success! Your project has been edited. You may close this window.
            </Typography>
          )}
          <CustomerProjectFormFields disabled={isSuccess}/>
        </DialogContent>
        <DialogActions>
          <DialogSaveButton
            isSubmitting={isSubmitting}
            isSuccess={isSuccess}
            handleClose={handleClose}
            handleSubmit={methods.handleSubmit(onSubmit)}
          />
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default CustomerProjectEditor;