import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import TermsAndConditions from "@components/TermsAndConditions";
import Page from "@components/Page";
import { Container, Box, Typography } from "@mui/material";
import RewattTermsPDF from "@assets/ReWatt - Account Terms and Conditions - v.3.pdf";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "@views/common/actions";
import { showAppMessage } from "@appMessage/actions";

const ProjectApplicationTermsAndConditions = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  const { userProfile } = useSelector((state) => state.getUserProfile);
  const dispatch = useDispatch();

  // submit - distinct from the program T&C
  const submit = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(updateUserProfile({
        accessToken,
        acceptedTermsAndConditions: true,
      }));
    } catch (err) {
      dispatch(showAppMessage({ severity: "warning", message: err.message }));
    }
  };

  // submission progress and action
  const { status } = useSelector((state) => state.updateUserProfile);
  const isSubmitting = status === "request";
  useEffect(() => {
    if (userProfile.acceptedTermsAndConditions && status === "success") {
      dispatch(updateUserProfile.reset());
      navigate("/projects/apply/start");
    }
  }, [
    dispatch,
    userProfile.acceptedTermsAndConditions,
    navigate,
    status,
  ]);

  return (
    <Page title="Terms and Conditions" py={3}>
      <Container maxWidth="xl">
        <Box ml={10} mr={10}>
          <Typography variant="h2">
            Welcome to {customerProject?.title} Registration!
          </Typography>
          <Typography variant="h4">
            Before you continue, please read below.
          </Typography>
          <TermsAndConditions
            pdf={{
              url: RewattTermsPDF,
              name: "Rewatt Terms and Conditions.pdf",
            }}
            acceptTermsAndConditions={submit}
            isSubmitting={isSubmitting}
            submitActionText="Continue"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ProjectApplicationTermsAndConditions;
