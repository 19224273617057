import { createTheme, colors } from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";
import typography from "./typography";

const primaryColor = "#082B4B"; // dark blue

const theme = createTheme({
  shape: {
    borderRadius: 15,
  },
  palette: {
    background: {
      dark: "#F4F6F8", // off-white
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: primaryColor,
      dark: "#051E34",
      light: "#39556F",
    },
    secondary: {
      main: "#00BABE", // turquoise blue
      dark: "#00A1A5",
      light: "#C0EBEC",
    },
    text: {
      primary: primaryColor,
      secondary: "rgba(0, 0, 0, 0.5)", // grey
    },
    info: {
      main: "#0583C0",
      light: "#039FEA",
      dark: "#046999",
    },
    success: {
      light: "#4CAF50",
      dark: "#1B5E20",
      main: "#2E7D32",
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EF5350",
    },
    warn: {
      light: "#FF9800",
      main: "#ED6C02",
      dark: "#E65100",
    },
  },
  typography: typography(primaryColor),
  components: {
    MuiTooltip: {
      defaultProps: {},
      styleOverrides: {
        tooltip: {
          borderRadius: 8,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
