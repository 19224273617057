import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const getSiteStats = createRoutine("GET_SITE_STATS");
const getSiteCredits = createRoutine("GET_SITE_CREDITS");
const getSiteEnergyToday = createRoutine("GET_SITE_ENERGY_TODAY");
const getIndividualApplications = createRoutineCreator([...defaultRoutineStages, "RESET"])("GET_INDIVIDUAL_APPLICATIONS");

export {
  getSiteStats,
  getSiteCredits,
  getSiteEnergyToday,
  getIndividualApplications,
};