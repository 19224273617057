import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OrganizationInfo from "../components/OrganizationInfo";
import { saveOrganizationInfo, saveOrganization, resetOrganization } from "../actions";

const mapStateToProps = ({ organization, saveOrganization }) => ({
  organization,
  saveOrganizationResult: saveOrganization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveOrganization,
      saveOrganizationInfo,
      resetOrganization,
      resetSaveOrganization: () => saveOrganization.reset(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationInfo);
