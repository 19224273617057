import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SubmittedApplication from "../components/SubmittedApplication";
import { getApplication, getApplicationNotes } from "@views/reviewApplication/actions";

const mapStateToProps = ({
  getApplication,
  approveApplication,
  declineApplication,
}) => ({
  getApplicationResult: getApplication,
  approveApplicationResult: approveApplication,
  declineApplicationResult: declineApplication,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getApplication,
      getApplicationNotes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedApplication);
