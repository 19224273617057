import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectReportForStatements, getProjectReportsForStatements } from "../../actions";
import CustomDataGrid from "components/CustomDataGrid";
import { Box, Container, IconButton, Paper, Typography } from "@mui/material";
import Page from "components/Page";
import { renderCellExpand } from "components/GridCellExpand";
import PageHeader from "components/PageHeader";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import { Visibility } from "@mui/icons-material";
import { includes } from "lodash";
import { ENVIRONMENTAL_CREDIT_TYPES } from "views/admin/project/components/Project";
import { useNavigate } from "react-router";
import { find } from "lodash";
import { getPayeeInvites } from "views/admin/project/actions";
import { localeStringCurrencyOpts as currencyOpts } from "utils/stringHelpers";
import { calculateAmountOwed, calculateFeeValue, calculateGrossSubTotal } from "utils/financialHelpers";

const ProjectReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const fetchProjectReports = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports admin_marketplace admin_sites",
      });
      dispatch(getProjectReportsForStatements({ accessToken }));
      dispatch(getPayeeInvites({ accessToken }));
    };
    fetchProjectReports();
  }, [dispatch, getAccessTokenSilently]);


  const { status, projectReports } = useSelector((state) => state.getProjectReportsForStatements);
  const isLoading = includes(['init', 'request'], status);
  const [pageSize, setPageSize] = useState(10);
  const [sortModel, setSortModel] = useState([{
    field: "amountOwed",
    sort: "desc",
  }]);

  const loadProjectReport = (projectReportId) => {
    const projectReport = find(projectReports, { id: projectReportId });
    dispatch(getProjectReportForStatements.preload({ projectReport }));
    navigate(`/admin/marketplace/payeeStatements/${projectReportId}`);
  };
  const columns = [
    {
      headerName: "Project",
      field: "project",
      flex: 1.5,
      renderCell: renderCellExpand,
      valueGetter: ({ value }) => value.title,
    },
    {
      headerName: "Credit",
      field: "credit",
      flex: 1.2,
      renderCell: renderCellExpand,
      valueGetter: ({ row }) => ENVIRONMENTAL_CREDIT_TYPES[row.project.environmentalCredit],
    },
    {
      headerName: "Start Date",
      field: "startDate",
      type: "date",
      flex: 0.7,
      valueFormatter: ({ value }) => DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT)
    },
    {
      headerName: "End Date",
      field: "endDate",
      type: "date",
      flex: 0.7,
      valueFormatter: ({ value }) => DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT)
    },
    {
      headerName: "Unit Price",
      field: "pricePerCredit",
      type: "number",
      hide: true,
      flex: 1,
      valueFormatter: ({ value }) => value
        ? (value / 100).toLocaleString(undefined, currencyOpts)
        : "",
    },
    {
      headerName: "Credits",
      field: "totalEnvironmentalCredits",
      type: "number",
      hide: true,
      flex: 1,
      valueFormatter: ({ value }) => value.toLocaleString(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        }),
    },
    {
      headerName: "Statements",
      field: "statements",
      align: "right",
      flex: 0.5,
      valueGetter: ({ row:{ contributions } }) => {
        const payeeStatementCount = contributions.filter(c => !!c.payeeStatement).length;
        return `${payeeStatementCount} / ${contributions.length}`;
      },
    },
    {
      headerName: "Gross Value",
      field: "grossValue",
      type: "number",
      flex: 0.5,
      valueGetter: ({ row: { id }, api }) => {
        const pricePerCredit = api.getCellValue(id, 'pricePerCredit');
        const credits = api.getCellValue(id, 'totalEnvironmentalCredits');
        return pricePerCredit ? calculateGrossSubTotal({ credits, pricePerCredit }) : '';
      },
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
    },
    {
      headerName: "Disbursed to Date",
      field: "disbursedToDate",
      type: "number",
      flex: 0.5,
      valueGetter: ({ row: { contributions } }) =>
        contributions.reduce((total, c) => total + (c.payeeStatement?.amountOwed || 0), 0),
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
    },
    {
      headerName: "Amount Owing*",
      field: "amountOwed",
      type: "number",
      flex: 0.5,
      valueGetter: ({ row: { id, contributions }, api }) => {
        const pricePerCredit = api.getCellValue(id, 'pricePerCredit');
        if (pricePerCredit) {
          const disbursedToDate = api.getCellValue(id, 'disbursedToDate') || 0;
          const feeValue = contributions.reduce((total, { organizationAsset, vintages }) => {
            const transactionFee = organizationAsset.payee?.transactionFee ?? 'N/A';
            const credits = vintages.reduce((total, v) => total + v.credits, 0);
            const grossValue = calculateGrossSubTotal({ pricePerCredit, credits });
            return total + calculateFeeValue({ transactionFee, grossValue });
          }, 0);
          const grossValue = api.getCellValue(id, 'grossValue');
          const totalOwing = calculateAmountOwed({ feeValue, grossValue });
          return totalOwing - disbursedToDate;
        }
        return '';
      },
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
    },
    {
      field: "viewAction",
      headerName: "View",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            loadProjectReport(params.row.id);
          }}
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
        >
          <Visibility />
        </IconButton>
      )
    },
  ];

  return (
    <Page py={3} title="Statements">
      <Container maxWidth="lg">
        <PageHeader title="Project Reports" />
          <Paper sx={{ mt: 3 }}>
            <CustomDataGrid
              style={{ border: 0 }}
              autoHeight
              loading={isLoading}
              rows={projectReports ?? []}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
            />
          </Paper>
          <Box display="flex">
            <Box flexGrow="1" />
            <Box mt={1}>
              <Typography variant="body2">*Amount Owing uses a default 25% Rewatt fee if one is not available</Typography>
            </Box>
          </Box>
      </Container>
    </Page>
  );
};

export default ProjectReports;