import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { find, map, includes, difference, first, filter } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import Navigation from "@components/Navigation";
import { getSitePickerColumns, transformSites } from "@addSites/sitePicker";
import CustomDataGrid from "@components/CustomDataGrid";
import pluralize from "pluralize";
import { ValidationError } from "yup";
import { useAppMessage } from "@components/appMessage";
import { useSelector, useDispatch } from "react-redux";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import { setTableState } from "@views/common/actions";
import { findSites } from "@addSites/actions";

const tableStateKey = "CONNECTION_EDITOR_SITE_PICKER";
const initialTableState = {
  sortModel: [
    {
      field: "capacity",
      sort: "desc",
    },
  ],
};

const SitePicker = ({ program, siteInfo, prev, next, saveSite }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();

  const sitePickerTableState = useSelector(
    (state) =>
      state.tableState[tableStateKey] || getDefaultTableState(initialTableState)
  );
  const setSitePickerTableState = (props) =>
    dispatch(setTableState({ ...props, key: tableStateKey }));

  const findSitesResult = useSelector((state) => state.findSites);

  // get columns from addSites
  const columns = useMemo(
    () =>
      map(
        filter(
          getSitePickerColumns(),
          (col) => !includes(["documents", "details"], col.field)
        ),
        (col) => ({ ...col, editable: false })
      ),
    []
  );

  // find sites
  const { manufacturer, credentials } = siteInfo;
  useEffect(() => {
    async function fetchSites() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(
        findSites({
          accessToken,
          manufacturer,
          credentials,
          organizationId: program?.organization?.id,
          programId: program?.id,
        })
      );
    }
    fetchSites();
  }, [
    manufacturer,
    credentials,
    getAccessTokenSilently,
    program,
    dispatch,
  ]);

  // put sites in state
  const isLoading =
    findSitesResult.status === "request" || findSitesResult.status === "init";
  const [sites, setSites] = useState([]);
  useEffect(() => {
    setSites(transformSites(findSitesResult.sites));

    // reselect site
    if (siteInfo?.site?.id) {
      setSelectionModel([siteInfo.site.id]);
    }
  }, [findSitesResult.sites, siteInfo?.site?.id]);

  // selecting sites
  const [selectionModel, setSelectionModel] = useState([]);

  // add sites
  const submit = async () => {
    try {
      if (selectionModel.length !== 1) {
        throw new ValidationError("At least one site must be checked");
      }
      saveSite({ site: find(sites, { id: first(selectionModel) }) });
      next();
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
    }
  };

  const { page, pageSize, sortModel, filterModel } = sitePickerTableState;
  return (
    <>
      <Typography variant="h4" color="textPrimary">
        Choose one site to include in this application
      </Typography>
      <Typography mb={2} color="textSecondary">
        Greyed out sites have already been added to a program. Please be
        patient, this can take some time.
      </Typography>
      <div style={{ height: "100%", width: "100%" }}>
        <CustomDataGrid
          autoHeight
          loading={isLoading}
          rows={sites}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(sortModel) =>
            setSitePickerTableState({ sortModel })
          }
          page={page}
          onPageChange={(page) => setSitePickerTableState({ page })}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => setSitePickerTableState({ pageSize })}
          rowsPerPageOptions={[10, 20, 50]}
          filterModel={filterModel}
          onFilterModelChange={(filterModel) =>
            setSitePickerTableState({ filterModel })
          }
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            const diff = difference(newSelectionModel, selectionModel);
            setSelectionModel(diff.length > 1 ? diff.slice(0, 1) : diff);
          }}
          selectionModel={selectionModel}
          isRowSelectable={({ row: site }) => site.eligible}
          localeText={{
            footerRowSelected: (count) =>
              `${count.toLocaleString()} ${pluralize("site", count)} selected`,
            noRowsLabel: "No sites found.",
          }}
          getRowClassName={({ row: site }) => !site.eligible && "disabled"}
        />
      </div>

      <Navigation
        backAction={prev}
        nextAction={submit}
        nextActionText="Continue"
      />
    </>
  );
};

SitePicker.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number,
    organization: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  siteInfo: PropTypes.shape({
    manufacturer: PropTypes.string.isRequired,
    credentials: PropTypes.shape({}),
  }),

  saveSite: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export default SitePicker;
