import { put, call, takeLatest } from "redux-saga/effects";
import logger from "debug";
import { addPayeeInvite } from "../actions";
import axios from "axios";
import { join, values } from "lodash";

const callApi = async (accessToken, invite) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/marketplace/payee/invites`,
    invite,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* addPayeeInviteWorker(action) {
  yield put(addPayeeInvite.request());

  const { accessToken, invite, onError, onSuccess } = action.payload;

  try {
    const response = yield call(callApi, accessToken, invite);
    yield put(addPayeeInvite.success({ invite: response.data, accessToken }));
    onSuccess();
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error creating invitation: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error creating invitation: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error creating invitation: ", err);
      message = err.message;
    }
    message = `Unable to create invitation: ${message}`;
    yield put(
      addPayeeInvite.failure({
        error: message,
      })
    );
    onError(message);
  }
}

function* addPayeeInviteWatcher() {
  yield takeLatest(addPayeeInvite.TRIGGER, addPayeeInviteWorker);
}

export default [addPayeeInviteWatcher];
