import React from "react";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  Typography,
  Grid,
  TextField,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const DataConnector = 'swift';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Charger IDs"
              variant="standard"
              fullWidth
              error={!isNil(errors.chargerIds?.message)}
              helperText={errors.chargerIds?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="ualberta.windsor.1, ualberta.windsor.2"
              autoFocus
            />
          )}
          name="chargerIds"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={dataConnectorParams?.chargerIds || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Instructions to Grant Rewatt Access
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Test the connection"
            secondary="Input the serial numbers/charger ID(s) you have shared with Rewatt in the field below. Separate each charger ID with a comma if you have more than one."
          />
        </ListItem>
      </List>
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;