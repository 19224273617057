import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ConnectionTest from "../components/credentials/ConnectionTest";
import { showAppMessage } from "@appMessage/actions";

const mapStateToProps = ({ testConnection: connectionTestResult }) => ({
  connectionTestResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppMessage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionTest);
