import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import ManufacturerForm from "@addSites/manufacturer/components/ManufacturerForm";
import Navigation from "@components/Navigation";

const Manufacturer = (props) => {
  const formMethods = useForm();
  const {
    connectionTestResult: { canAccessData },
    showAppMessage,
    nextAction,
  } = props;

  // next button
  const onNext = async () => {
    // make sure we show any validation on the embedded form ie. required fields
    // the only way we can do that is if we get the form setup here
    await formMethods.trigger();

    // we can only get this if we had a successful connection test
    if (canAccessData) {
      nextAction();
    } else {
      showAppMessage({
        severity: "warning",
        message: "Please ensure you have a valid connection first.",
      });
    }
  };

  return (
    <>
      {/* manufacturer, instructions, creds */}
      <ManufacturerForm {...props} formMethods={formMethods} />

      <Navigation nextAction={onNext} nextActionText="Continue" />
    </>
  );
};

Manufacturer.propTypes = {
  siteInfo: PropTypes.shape({
    manufacturer: PropTypes.string,
  }),
  showAppMessage: PropTypes.func.isRequired,
  saveManufacturer: PropTypes.func.isRequired,
  saveCredentials: PropTypes.func.isRequired,
  testConnection: PropTypes.func.isRequired,
  resetTestConnection: PropTypes.func.isRequired,
  connectionTestResult: PropTypes.shape({
    canAccessData: PropTypes.bool,
    status: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,

  // just the navigation; we do other things with the form separately
  nextAction: PropTypes.func.isRequired,
};

export default Manufacturer;
