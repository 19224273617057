import getPrograms from "./getPrograms";
import createProgram from "./createProgram";
import uploadTerms from "./uploadTerms";
import uploadProgramLogo from "./uploadProgramLogo";
import getApplications from "./getApplications";
import getOrganizationUsers from "./getOrganizationUsers";
import applicationTableState from "./applicationTableState";
import programTableState from "./programTableState";

const reducers = {
  getPrograms,
  createProgram,
  uploadTerms,
  uploadProgramLogo,
  getApplications,
  getOrganizationUsers,
  applicationTableState,
  programTableState,
};

export default reducers;
