import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";

const EnergSyncInstructionPanel = () => (
  <Box mt={4} pt={4} borderTop={1} borderColor="divider">
    <Typography variant="h4" fontWeight="bold" gutterBottom>
      If your charger manufacturer has enabled EnergSync, you can use this form
      to connect.
    </Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="EnergSync API root URL"
          secondary="Don't forget the trailing slash. eg. https://energsync.rewatt.amazonaws.com/v2/"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Get an API Key"
          secondary="You need to provide an API key to access the EnergSync endpoints"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Test the connection"
          secondary="Input the serial numbers/charger ID(s) you want connected in the field below. Separate each charger ID with a comma if you have more than one."
        />
      </ListItem>
    </List>
  </Box>
);

export default EnergSyncInstructionPanel;
