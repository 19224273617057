import React from "react";
import { Box, Container, Typography } from "@mui/material";

const WelcomeMessage = ({ invite }) => (
  <>
    <Typography variant="h3" color="textPrimary" gutterBottom>
      Welcome {invite.inviteeName}!
    </Typography>
    <Typography variant="h4" color="textSecondary" gutterBottom>
      Invitation to become a Channel Partner
    </Typography>

    <Container maxWidth="sm">
      <Box
        py={3}
        mb={4}
        sx={{
          my: 4,
          py: 3,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="body1" gutterBottom>
          {invite.inviteeName} ({invite.inviteeEmail}),
        </Typography>
        <Typography variant="body1" gutterBottom>
          You've been invited by {invite.shareName} to become a Channel Partner on the Rewatt Platform.
        </Typography>
        <Typography variant="body1" gutterBottom>
          To continue, you will need to sign up and enter your organization information.
        </Typography>
        {invite.notes && (
          <>
            <Typography variant="body1">Notes from {invite.shareName}:</Typography>
            <Typography variant="body2" color="textSecondary">
              {invite.notes}
            </Typography>
          </>
        )}
      </Box>
    </Container>
  </>
);

export default WelcomeMessage;
