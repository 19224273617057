import { put, call, takeLatest } from "redux-saga/effects";
import { createPayeeStatement } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, projectReportId, organizationAssetId, statementDetails) => {
  const path = `admin/projectReports/${projectReportId}/organizationAssets/${organizationAssetId}/payeeStatements`;
  return await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/${path}`,
    statementDetails,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
};

function* createPayeeStatementWorker(action) {
  try {
    const { accessToken, projectReportId, organizationAssetId, details  } = action.payload;
    yield put(createPayeeStatement.request());
    const response = yield call(callApi, accessToken, projectReportId, organizationAssetId, details);
    const payeeStatement = response.data;
    yield put(createPayeeStatement.success({ payeeStatement }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to create payee statement: ${message}`;
    yield put(createPayeeStatement.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* createPayeeStatementWatcher() {
  yield takeLatest(createPayeeStatement.TRIGGER, createPayeeStatementWorker);
}

export default [createPayeeStatementWatcher];
