import React, { useState } from "react";
import {
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  InputAdornment,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { patchActivityStartDate } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RelativeLink } from "@components";
/**
 * We may have multiple project memberships for an orgasset. Each has an activity start date.
 *
 * @param projectMembership
 * @param orgAsset
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectMembership = ({ projectMembership, orgAsset }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [activityStartDate, setActivityStartDate] = useState(
    DateTime.fromISO(projectMembership.activityStartDate)
  );
  const joinDate = projectMembership.dateAdded;

  const onChange = async (activityStartDate) => {
    setActivityStartDate(activityStartDate);

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });

    dispatch(
      patchActivityStartDate({
        accessToken,
        projectId: projectMembership.id,
        orgAssetId: orgAsset.id,
        activityStartDate,
      })
    );
  };

  const { status } = useSelector((state) => state.patchActivityStartDate);
  const isSubmitting = status === "request";

  return (
    <TableContainer>
      <Table aria-label="asset project">
        <TableBody>
          <TableRow>
            <TableCell component="th" width="50%">
              Project Name
            </TableCell>
            <TableCell>
              <RelativeLink to={`/admin/projects/${projectMembership.id}`}>
                {projectMembership.title}
              </RelativeLink>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              <Tooltip title="Date this site was added to the project">
                <Typography variant="body2">Join Date</Typography>
              </Tooltip>
            </TableCell>
            <TableCell>
              {joinDate
                ? DateTime.fromISO(joinDate)
                    .setLocale(getDefaultLocale())
                    .toLocaleString(DateTime.DATE_FULL)
                : "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              <Tooltip title="The date from which credits are calculated">
                <Typography variant="body2">Activity Start Date</Typography>
              </Tooltip>
            </TableCell>
            <TableCell>
              <DatePicker
                value={activityStartDate}
                onChange={onChange}
                views={["year", "month", "day"]}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    helperText="Inclusive"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: isSubmitting ? (
                        <InputAdornment position="end">
                          <CircularProgress size={18} />
                        </InputAdornment>
                      ) : (
                        params.InputProps.endAdornment
                      ),
                    }}
                  />
                )}
                disableMaskedInput
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectMembership;
