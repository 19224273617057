import React from "react";
import PropTypes from "prop-types";
import { Typography, Container, Box, Grid, TextField, MenuItem } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { isNil } from "lodash";
import { parseAddress } from "@utils/locationHelpers";
import { AddressTextField } from "@addSites/sitePicker";
import Places from "@components/Places";
import { ownershipTypes } from "@views/site/components/details/SiteDetails";
import FundingSourceComboBox from "@views/site/components/details/FundingSourceComboBox";

const SiteProfileForm = ({ isContactOwner }) => {
  const { errors, control, setValue } = useFormContext();

  // from the autocomplete
  const populateAddress = React.useCallback(
    (newValue) => {
      const address = parseAddress(newValue);
      setValue("address.street", address.street, { shouldValidate: true });
      setValue("address.city", address.city, { shouldValidate: true });
      setValue("address.region", address.region, { shouldValidate: true });
      setValue("address.country", address.country, { shouldValidate: true });
      setValue("address.postal", address.postal, { shouldValidate: true });
    },
    [setValue]
  );

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          ml: { xs: 1, sm: 2 },
          mr: { xs: 1, sm: 2 },
        }}
      >
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Site Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    label="Site Name"
                    variant="standard"
                    fullWidth
                    error={!isNil(errors.name?.message)}
                    helperText={errors.name?.message}
                    required
                    onChange={onChange}
                    value={value}
                    color="secondary"
                  />
                )}
                control={control}
                name="name"
              />
            </Grid>
            {isContactOwner && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ mt: 4 }}>
                    Site Owner
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="First Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.firstName?.message)}
                        helperText={errors.firstName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.lastName?.message)}
                        helperText={errors.lastName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Email"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.email?.message)}
                        helperText={errors.email?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Phone"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.phone?.message)}
                        helperText={errors.phone?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="phone"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Places changeHandler={populateAddress} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.street"
                label="Street"
                placeholder="621 23 Ave NE"
                helperText="Street and number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.city"
                label="City"
                placeholder="Calgary"
                helperText="City, town or municipality"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.region"
                label="Province/State/Region"
                placeholder="Alberta"
                helperText="Full region name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.country"
                label="Country"
                placeholder="Canada"
                helperText="Full country name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.postal"
                label="Postal Code"
                placeholder="T2E 1W5"
                helperText="Postal or zip code"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Funding
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    name="equipmentOwnership"
                    variant="standard"
                    select
                    fullWidth
                    label="Type of Ownership"
                    value={value}
                    onChange={onChange}
                  >
                    {ownershipTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
                name="equipmentOwnership"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <FundingSourceComboBox value={value} onChange={onChange} readOnly={false} />
                )}
                control={control}
                name="fundingSource"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

SiteProfileForm.defaultProps = {
  isContactOwner: true,
};

SiteProfileForm.defaultProps = {
  isContactOwner: PropTypes.bool,
};

export default SiteProfileForm;
