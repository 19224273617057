import { call, put, takeLatest } from "redux-saga/effects";
import { getReferrals } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, channelPartnerId) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/channelPartners/${channelPartnerId}/referrals`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getReferralsWorker(action) {
  yield put(getReferrals.request());

  const { accessToken, channelPartnerId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, channelPartnerId);
    yield put(
      getReferrals.success({ referrals: response.data })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting referrals: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting referrals: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting referrals: ", err);
      message = err.message;
    }
    message = `Unable to get referrals: ${message}`;
    yield put(getReferrals.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getReferralsWatcher() {
  yield takeLatest(getReferrals.TRIGGER, getReferralsWorker);
}

export default [getReferralsWatcher];
