import {
  createRoutineCreator,
  defaultRoutineStages,
  createRoutine,
} from "redux-saga-routines";
import { createAction } from "redux-actions";

const getOrganizationStats = createRoutine("GET_ORG_STATS");
const getOrganizationEnergy = createRoutine("GET_ORG_ENERGY");
const getOrganizationEmissions = createRoutine("GET_ORG_EMISSIONS");
const getOrganizationCredits = createRoutine("GET_ORG_CREDITS");

const dataUpdated = createAction("DATA_UPDATED");
dataUpdated.TYPE = dataUpdated.toString();

const getChartData = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_CHART_DATA"
);

export {
  getOrganizationStats,
  getOrganizationEnergy,
  getOrganizationEmissions,
  dataUpdated,
  getChartData,
  getOrganizationCredits,
};
