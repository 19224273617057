import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Typography,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SiteLocationForm, {
  schema,
} from "@views/apply/components/SiteLocationForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SiteLocationCellEditor = ({
  id,
  value: initialSiteLocation,
  api,
  field,
  row: site,
}) => {
  const [open, setOpen] = useState(true);

  const handleCloseEditor = () => {
    methods.reset();
    setOpen(false);
    api.setCellMode(id, field, "view");
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      addresses: initialSiteLocation.addresses,
      timeZone: initialSiteLocation.timeZone || "",
      latitude: initialSiteLocation.latitude || "",
      longitude: initialSiteLocation.longitude || "",
    },
  });

  const onSubmit = async (data) => {
    api.setEditCellValue({ id, field, value: data });
    const isValid = await api.commitCellChange({ id, field });
    if (isValid) {
      setOpen(false);
      api.setCellMode(id, field, "view");
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseEditor}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEditor}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Site Location - {site.name}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => methods.handleSubmit(onSubmit)()}
          >
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <SiteLocationForm />
      </FormProvider>
    </Dialog>
  );
};

SiteLocationCellEditor.propTypes = {
  /**
   * GridApi that let you manipulate the grid.
   */
  api: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.shape({
    addresses: PropTypes.shape({
      main: PropTypes.shape({
        city: PropTypes.string,
        region: PropTypes.string,
        country: PropTypes.string,
        postal: PropTypes.string,
        street: PropTypes.string,
      }),
    }),
    timeZone: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
};

export default SiteLocationCellEditor;
