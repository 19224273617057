import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SiteContributions from "./SiteContributions";
import { includes } from "lodash";

const CalculationByCreditRequest = ({ project, credits }) => {
  const { status } = useSelector((state) => state.getProjectReportCalculation);
  const isLoading = status === "request";
  const showCalculations = includes(["request", "success", "failure"], status) && credits > 0;

  return showCalculations ? <SiteContributions project={project} isLoading={isLoading} /> : null;
};

CalculationByCreditRequest.propTypes = {
  project: PropTypes.shape({}).isRequired,
  credits: PropTypes.number.isRequired,
};

export default CalculationByCreditRequest;
