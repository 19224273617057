import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SitePicker from "../components/SitePicker";
import {
  findSites,
  addSites,
  resetSiteInfo,
  testConnection,
  uploadFiles,
} from "@addSites/actions";
import { showAppMessage } from "@appMessage/actions";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import { setTableState } from "@views/common/actions";

const tableStateKey = "SITE_PICKER";
const initialTableState = {
  sortModel: [
    {
      field: "capacity",
      sort: "desc",
    },
  ],
};

const mapStateToProps = ({
  findSites,
  siteInfo: { manufacturer, credentials },
  addSites,
  tableState,
}) => ({
  findSitesResult: findSites,
  addSitesResult: addSites,
  manufacturer,
  credentials,
  sitePickerTableState:
    tableState[tableStateKey] || getDefaultTableState(initialTableState),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      findSites,
      showAppMessage,
      addSites,
      setSitePickerTableState: (props) =>
        setTableState({ ...props, key: tableStateKey }),
    },
    dispatch
  ),
  resetFindSitesResult: () => dispatch(findSites.reset()),
  resetAll: () => {
    dispatch(findSites.reset());
    dispatch(addSites.reset());
    dispatch(testConnection.reset());
    dispatch(uploadFiles.reset());
    dispatch(resetSiteInfo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SitePicker);
