import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "@components/Logo";
import { AppBar, Box, Tooltip, Button, Toolbar } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const TopBar = ({ className, ...rest }) => {
  const { loginWithRedirect } = useAuth0();

  const login = () => {
    loginWithRedirect({
      redirectUri: window.location.origin,
      appState: { returnTo: `/callback` },
    });
  };

  return (
    <AppBar
      sx={{
        color: "primary.main",
        backgroundColor: "common.white",
      }}
      elevation={1}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Tooltip title="Login or sign up.">
          <Button variant="text" color="primary" onClick={login}>
            Login
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
