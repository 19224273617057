import { getBuyerProjectReports } from "../actions";

export const initialState = {
  status: "init",
  projectReports: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getBuyerProjectReports.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getBuyerProjectReports.SUCCESS: {
      const { projectReports } = action.payload;
      return {
        ...state,
        status: "success",
        projectReports,
      };
    }

    case getBuyerProjectReports.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
