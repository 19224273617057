import getBuyerProjectReports from "./getBuyerProjectReports";
import getBuyerProjectReport from "./getBuyerProjectReport";
import getBuyerProject from "./getBuyerProject";
import submitRequestToPurchase from "./submitRequestToPurchase";

export default [
  ...getBuyerProjectReports,
  ...getBuyerProjectReport,
  ...submitRequestToPurchase,
  ...getBuyerProject,
];
