import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { getUserProfile } from "@views/common/actions";
import { useNavigate } from "react-router-dom";
import { AttachMoney } from "@mui/icons-material";

const ActionMenu = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutWithRedirect = () => {
    dispatch(getUserProfile.reset());
    logout({
      returnTo: window.location.origin,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box>
        <IconButton
          onClick={handleOpenMenu}
          size="small"
          aria-controls={open ? "action-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: (theme) => theme.palette.primary.light }}>
            <PersonIcon />
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="action-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate("/individual/dashboard")}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          Dashboard
        </MenuItem>
        <MenuItem onClick={() => navigate("/individual/account")}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          My Account
        </MenuItem>
        <MenuItem onClick={() => navigate("/individual/statements")}>
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          Statements
        </MenuItem>
        <Divider />
        <MenuItem onClick={logoutWithRedirect}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ActionMenu;
