import React, { useEffect } from "react";
import GreenButton from "@components/GreenButton";
import PropTypes from "prop-types";

const ConnectionTest = ({
  disabled,
  connectionTestResult: { status, canAccessData, error },
  showAppMessage,
}) => {
  const loading = status === "request";
  useEffect(() => {
    if (error) {
      showAppMessage({ severity: "error", message: error, duration: 4000 });
    } else if (canAccessData) {
      showAppMessage({
        severity: "success",
        message: "Success. We can access the monitoring portal.",
        duration: 4000,
      });
    } else if (
      !canAccessData &&
      !error &&
      status !== "init" &&
      status !== "request"
    ) {
      showAppMessage({
        severity: "warning",
        message: "Those credentials appear invalid. Please try again.",
      });
    }
  }, [error, canAccessData, showAppMessage, status]);

  return (
    <GreenButton type="submit" disabled={disabled} loading={loading}>
      Test Connection
    </GreenButton>
  );
};

ConnectionTest.propTypes = {
  connectionTestResult: PropTypes.shape({
    status: PropTypes.string,
    canAccessData: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  showAppMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ConnectionTest;
