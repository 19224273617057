import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Container } from "@mui/material";

const CreateVoluntarySuccess = ({ project, projectReport }) => {
  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "calc(80vh - 64px)" }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h1" color="secondary.main">
            Success!
          </Typography>
          <Typography mt={2}>
            You have created a new project report for the project:{" "}
            <Typography fontWeight="bold" component="span">
              {project.title}
            </Typography>
          </Typography>
          <Typography mt={1}>
            Date range for your report is from{" "}
            <Typography fontWeight="bold" component="span">
              {projectReport.startDate}
            </Typography>{" "}
            to{" "}
            <Typography fontWeight="bold" component="span">
              {projectReport.endDate}
            </Typography>
            .
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

CreateVoluntarySuccess.propTypes = {
  project: PropTypes.shape({}).isRequired,
  projectReport: PropTypes.shape({}).isRequired,
};

export default CreateVoluntarySuccess;
