import {
  createRoutineCreator,
  defaultRoutineStages,
  createRoutine,
} from "redux-saga-routines";

const getBuyerProjectReports = createRoutine("GET_BUYER_PROJECT_REPORTS");
const getBuyerProjectReport = createRoutine("GET_BUYER_PROJECT_REPORT");
const getBuyerProject = createRoutine("GET_BUYER_PROJECT");

const submitRequestToPurchase = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "SUBMIT_REQUEST_TO_PURCHASE"
);


export { getBuyerProjectReports, getBuyerProjectReport, submitRequestToPurchase, getBuyerProject };
