/**
 * How docs work
 *
 * Uploading
 * - we use react-dropzone which provide dnd upload, file browsing, multi-file upload
 * - we generally POST to /files, with a public flag
 * - we get back a uri to the file
 * - to download, you will need an access token, or none if public
 *
 * - the uri is not at this point saved anywhere or associated with anything in the db!
 * - we then hand off this uri to redux which will make it part of a document, which in turn is usually in a list belonging to an entity (eg site.documents)
 * - when we save this entity, the document looks like this:
 * {
 *   "name": "2017 Santa Cruz Hightower CC.pdf",
 *   "uri": "https://staging-surge.rewattpower.com/uploads/36006c31726cac74c21a9922b2370188",
 *   "tags": []
 * }
 * - to get the name of the file for creation of a document, we look at fs.path or file.path, which comes from react-dropzone
 *   - they are not used after that
 *
 * - when we fetch it back, it looks like this:
 * {
 *   "id": "488b69db-ece2-456b-8290-8c1416f0c164",
 *   "created": 1655408197,
 *   "modified": 1655408197,
 *   "name": "2017 Santa Cruz Hightower CC.pdf",
 *   "size": 218749,
 *   "mediaType": "application/pdf",
 *   "status": "PENDING_REVIEW",
 *   "notes": "",
 *   "uri": "https://staging-surge.rewattpower.com/uploads/36006c31726cac74c21a9922b2370188",
 *   "tags": []
 * }
 *
 * - for tags:
 * POST:
 * ["Installation"]
 *
 * GET:
 * [{
 *   "id": 782,
 *   "created": 1648176314,
 *   "modified": 1648176314,
 *   "name": "Installation"
 * }]
 * so tags must be mapped
 *
 */
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  IconButton,
  Card,
  CardHeader,
  Avatar,
  Toolbar,
  Box,
  CircularProgress,
} from "@mui/material";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import { useAuth0 } from "@auth0/auth0-react";
import { find, map, filter, isNil, isEmpty } from "lodash";
import { red } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/GetApp";
import TagManager from "./TagManager";
import logger from "debug";
import { v4 as uuid } from "uuid";
import { downloadFile, fileSizeValidator } from "@utils/fileHelpers";
import byteSize from "byte-size";
import DropZone from "@components/DropZone";

const maxFileSize = 30 * 1000 * 1000; // bytes

export const documentsReducer = (documents, action) => {
  switch (action.type) {
    case "addDocuments":
      const { documents: newDocs } = action.payload;
      return [...documents, ...newDocs];
    case "removeDocument": {
      const { file: fileToRemove } = action.payload.document;
      return documents.filter((doc) => doc.file !== fileToRemove);
    }
    case "addUris": {
      const { filespecs } = action.payload;
      const newDocs = map(documents, (doc) => {
        const newFs = find(filespecs, (fs) => fs.file === doc.file);
        return newFs || doc;
      });
      return newDocs;
    }
    case "setTags": {
      const { document, tags } = action.payload;
      return documents.map((doc) => {
        if (doc.file === document.file) {
          return {
            ...document,
            tags,
          };
        }
        return doc;
      });
    }
    case "reset":
      return action.payload;
    default:
      throw new Error();
  }
};

const DocumentUploader = ({
  uploadFiles,
  uploadFilesResult,
  documents,
  dispatch,
  showAppMessage,
  hideAppMessage,
  readOnly,
  displayFilter = (doc) => true,
  isPublic = false,
  defaultTags = []
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    validator: fileSizeValidator(maxFileSize),
  });

  const { getAccessTokenSilently } = useAuth0();

  // we have some new files to upload
  useEffect(() => {
    async function uploadNewFiles() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      // make sure we don't re-upload already uploaded files
      const newDocs = map(
        filter(acceptedFiles, (file) =>
          isNil(find(documents, (doc) => doc.file.path === file.path))
        ),
        (file) => ({ file, tags: defaultTags, public: isPublic })
      );

      if (newDocs.length) {
        // save to reducer, so we can show loading
        dispatch({
          type: "addDocuments",
          payload: { documents: newDocs },
        });

        // upload
        uploadFiles({ filespecs: newDocs, accessToken });
      } else if (acceptedFiles.length) {
        logger("weedle:warn")("No new docs to upload.");
      }
    }
    uploadNewFiles();
    // eslint-disable-next-line
  }, [acceptedFiles]);

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      showAppMessage({
        severity: "error",
        message: fileRejections[0].errors[0].message,
      });
    } else {
      hideAppMessage();
    }
  }, [fileRejections, showAppMessage, hideAppMessage]);

  // after upload, now we have a uri
  useEffect(() => {
    // merge with documents in component state
    dispatch({
      type: "addUris",
      payload: { filespecs: uploadFilesResult.filespecs },
    });
    // eslint-disable-next-line
  }, [uploadFilesResult]);

  // set the tags for a doc
  const setTags = ({ document, tags }) => {
    dispatch({
      type: "setTags",
      payload: { document, tags },
    });
  };

  // delete
  // todo: for some reason, first click on either iconbutton does nothing
  const removeDoc = (doc) => {
    dispatch({
      type: "removeDocument",
      payload: { document: doc },
    });
  };

  // hack to download the file, sort of like opening in new window
  const [uriDownloading, setUriDownloading] = useState("");
  const downloadDoc = async (doc) => {
    try {
      setUriDownloading(doc.uri);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(doc, accessToken);
    } catch (err) {
      showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      });
    }
    setUriDownloading("");
  };

  const isUploading = (doc) => {
    const found = find(acceptedFiles, (fs) => fs.path === doc.file.path);
    return uploadFilesResult.status === "request" && !isNil(found);
  };

  const filteredDocs = documents.filter(displayFilter);

  return (
    <Grid container spacing={2}>
      {!readOnly && (
        <Grid item xs={12}>
          <DropZone
            {...getRootProps({
              isFocused,
              isDragAccept,
              isDragReject,
              isDragActive,
            })}
            width="unset"
            height={100}
          >
            <input {...getInputProps()} />
            <Box m={1} mt={3}>
              <Typography color="textPrimary" gutterBottom>
                Drag and drop some files here, or click to select files
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Max file size: {byteSize(maxFileSize, { precision: 0 }).toString()}
              </Typography>
            </Box>
          </DropZone>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {filteredDocs.map((doc) => {
            const isUploadingDoc = isUploading(doc);
            const isDocDownloading = uriDownloading === doc.uri;
            return (
              <React.Fragment key={uuid()}>
                <Grid item xs={12} md={4}>
                  <Card elevation={0}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" sx={{ bgcolor: red[500] }}>
                          {isUploadingDoc ? (
                            <CircularProgress size={18} color="inherit" />
                          ) : (
                            <FileIcon />
                          )}
                        </Avatar>
                      }
                      title={doc.file.path}
                      subheader={`${doc.file.size} bytes`}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TagManager
                    document={doc}
                    setTags={setTags}
                    disabled={isUploadingDoc || readOnly}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Toolbar sx={{ height: "100%" }}>
                    <Box flexGrow={1} />
                    <IconButton
                      edge="end"
                      aria-label="view"
                      color="inherit"
                      onClick={() => downloadDoc(doc)}
                      disabled={isUploadingDoc || isDocDownloading}
                      size="large"
                      sx={{ p: 2 }}
                    >
                      {isDocDownloading ? <CircularProgress size={18} /> : <DownloadIcon />}
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="inherit"
                      onClick={() => removeDoc(doc)}
                      disabled={isUploadingDoc || isDocDownloading || readOnly}
                      size="large"
                      sx={{ p: 2 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Toolbar>
                </Grid>
              </React.Fragment>
            );
          })}
          {filteredDocs.length === 0 && (
            <Grid item xs={12}>
              <Typography color="textSecondary" textAlign="center" mt={2}>
                No documents uploaded
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

DocumentUploader.defaultProps = {
  readOnly: false,
};

DocumentUploader.propTypes = {
  showAppMessage: PropTypes.func.isRequired,
  hideAppMessage: PropTypes.func.isRequired,
  uploadFilesResult: PropTypes.shape({}),
  uploadFiles: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.oneOfType([
        PropTypes.instanceOf(File),
        PropTypes.shape({
          path: PropTypes.string,
          size: PropTypes.number,
        }),
      ]),
      uri: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  readOnly: PropTypes.bool,
};

export default DocumentUploader;
