import React, { useEffect } from "react";
import { Typography, Container, Box, Grid, TextField, Paper } from "@mui/material";
import Page from "@components/Page";
import { useForm, Controller } from "react-hook-form";
import { isNil, includes } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { personalInfoSchema } from "@views/apply/components/PersonalInfo";
import { updateUserProfile } from "@views/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "@components";
import { getFirstName, getLastName } from "@utils/stringHelpers";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { invite } = useSelector((state) => state.verifyBuyerInviteToken);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(personalInfoSchema),
    mode: "onChange",
    defaultValues: {
      ...userProfile,
      firstName: userProfile.givenName || getFirstName(invite.inviteeName) || "",
      lastName: userProfile.familyName || getLastName(invite.inviteeName) || "",
      phone: userProfile.phone || "",
    },
  });

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { firstName, lastName, phone } = data;
    dispatch(
      updateUserProfile({
        accessToken,
        firstName,
        lastName,
        phone,
      })
    );
  };

  // nav on success
  const { status: updateUserProfileStatus } = useSelector((state) => state.updateUserProfile);
  useEffect(() => {
    if (updateUserProfileStatus === "success") {
      dispatch(updateUserProfile.reset());
      if (invite.organization?.id) {
        navigate("/marketplace/buyer/join");
      } else {
        navigate("/marketplace/buyer/organization");
      }
    }
  }, [
    navigate,
    updateUserProfileStatus,
    getAccessTokenSilently,
    invite,
    dispatch,
    userProfile.organizationId,
  ]);

  const isSubmitting = includes(["request"], updateUserProfileStatus);

  return (
    <Page title="Contact Info" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              Buyer Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Create your account
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography mt={4}>What's your name?</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="First Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.firstName?.message)}
                        helperText={errors.firstName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.lastName?.message)}
                        helperText={errors.lastName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="lastName"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography mt={4}>What's your contact information?</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Email"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.email?.message)}
                        helperText={errors.email?.message}
                        required
                        onChange={onChange}
                        value={value}
                        type="email"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                    control={control}
                    name="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Best Number to Reach You"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.phone?.message)}
                        helperText={errors.phone?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="phone"
                  />
                </Grid>
              </Grid>
              <Navigation
                nextAction={() => handleSubmit(onSubmit)()}
                nextActionText="Continue"
                isSubmitting={isSubmitting}
              />
            </form>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default PersonalInfo;
