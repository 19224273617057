import { call, put, takeLatest } from "redux-saga/effects";
import { requestSmaPermission } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, email) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/onboarding/sma_permission`,
    {
      email,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export function* requestSmaPermissionWorker(action) {
  yield put(requestSmaPermission.request());

  const { accessToken, email } = action.payload;

  try {
    const response = yield call(callApi, accessToken, email);
    if (response.data.status === 'ok') {
      yield put(requestSmaPermission.success());
    } else {
      const message = response?.data?.reason ||  "Failed to get permission.";
      yield put(requestSmaPermission.failure({ error: message }));
      yield put(showAppMessage({ severity: "error", message }));
    }
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error requesting SMA permission: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error requesting SMA permission: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error requesting SMA permission: ", err);
      message = err.message;
    }
    message = `Unable to request SMA permission: ${message}`;
    yield put(requestSmaPermission.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* requestSmaPermissionWatcher() {
  yield takeLatest(requestSmaPermission.TRIGGER, requestSmaPermissionWorker);
}

export default [requestSmaPermissionWatcher];
