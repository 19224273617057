import { call, put, takeLatest } from "redux-saga/effects";
import { omit, get, split, trim } from "lodash";
import { flattenObject } from "@utils/errorHelpers";
import { testConnection } from "../actions";
import axios from "axios";
import logger from "debug";

// old (guest url): https://www.solarweb.com/Home/GuestLogOn?pvSystemid=60f0c8df-eb2d-4261-a103-4b979249f44a
// new (browser url): https://www.solarweb.com/PvSystemSettings/Permissions?pvSystemId=72f5bd60-3112-4c44-ad89-a64b01843ab6
const regex = new RegExp('pvsystemid', 'i');
export const extractFroniusSystemId = (url) => {
  if (regex.test(url)) {
    return new URL(url.toLowerCase()).searchParams.get("pvsystemid");
  }
  return null;
};

const callApi = async (accessToken, credentials, manufacturer, apiUrl) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/verifyDataAccess`,
    {
      manufacturer,
      ...credentials,
      apiUrl,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const transform = (credentials) => ({
  ...omit(credentials, "froniusAccessUrl", "manufacturer"),
  pvSystemId: extractFroniusSystemId(get(credentials, "froniusAccessUrl")),
  chargerIds: split(get(credentials, 'chargerIds'), ',').map(id => trim(id)),
});

export function* testConnectionWorker(action) {
  yield put(testConnection.request());

  const { accessToken, manufacturer, credentials, apiUrl } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      transform(credentials),
      manufacturer,
      apiUrl,
    );
    const { canAccessData } = response.data;
    yield put(testConnection.success({ canAccessData }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error testing connection: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error testing connection: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error testing connection: ", err);
      message = err.message;
    }
    message = `Unable to test connection: ${message}`;
    yield put(testConnection.failure({ error: message }));
  }
}

export function* testConnectionWatcher() {
  yield takeLatest(testConnection.TRIGGER, testConnectionWorker);
}

export default [testConnectionWatcher];
