import { call, put, takeLatest } from "redux-saga/effects";
import { getProgram } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { patchProgram } from "@views/program/actions";

const callApi = async (programId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/programs/${programId}`
  );
  return response;
};

function* getProgramWorker(action) {
  yield put(getProgram.request());

  const { programId } = action.payload;

  try {
    const response = yield call(callApi, programId);
    const program = response.data;
    yield put(getProgram.success({ program }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting program: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting program: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting program: ", err);
      message = err.message;
    }
    message = `Unable to get program: ${message}`;
    yield put(getProgram.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProgramWatcher() {
  yield takeLatest(getProgram.TRIGGER, getProgramWorker);
}

function* patchProgramWatcher() {
  yield takeLatest(patchProgram.SUCCESS, getProgramWorker);
}

export default [getProgramWatcher, patchProgramWatcher];
