import { getOrganizationEnergy } from "../actions";

export const initialState = {
  status: "init",
  "generation": 0,
  "consumption": 0,
  "exported": 0,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getOrganizationEnergy.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getOrganizationEnergy.SUCCESS: {
      const { generation, consumption, exported } = action.payload;
      return {
        ...state,
        status: "success",
        generation,
        consumption,
        exported,
      };
    }

    case getOrganizationEnergy.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
