import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Address = ({ address, ...other }) => {
  if (!address) {
    return "N/A";
  }
  return (
    <Box sx={{ overflow: "auto" }} {...other}>
      {address?.street}
      <br />
      {address?.city}, {address?.region}, {address?.country}
      <br />
      {address?.postal}
    </Box>
  );
};

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    postal: PropTypes.string,
  }),
};

export default Address;
