import { call, put, takeLatest } from "redux-saga/effects";
import { createDevices, deleteDocument, getCustomerProjectSite, patchDevice } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async ({ accessToken, customerProjectId, siteId }) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites/${siteId}`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getCustomerProjectSiteWorker(action) {
  yield put(getCustomerProjectSite.request());
  try {
    const response = yield call(callApi, action.payload);

    yield put(getCustomerProjectSite.success({ site: response.data }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get site: ${message}`;
    yield put(getCustomerProjectSite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getCustomerProjectSiteWatcher() {
  yield takeLatest(getCustomerProjectSite.TRIGGER, getCustomerProjectSiteWorker);
}

function* patchDeviceWatcher() {
  yield takeLatest(patchDevice.SUCCESS, getCustomerProjectSiteWorker);
}

function* createDevicesWatcher() {
  yield takeLatest(createDevices.SUCCESS, getCustomerProjectSiteWorker);
}

function* deleteDocumentWatcher() {
  yield takeLatest(deleteDocument.SUCCESS, getCustomerProjectSiteWorker);
}

export default [
  getCustomerProjectSiteWatcher,
  patchDeviceWatcher,
  createDevicesWatcher,
  deleteDocumentWatcher,
];
