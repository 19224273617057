import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Grid,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import TabPanel from "@components/TabPanel";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import UptimeChart from "./UptimeChart";
import { Granularity, getAvailabilityStats } from "./IncidentCalculator";
import { round } from "@utils/numberHelpers";
import { Duration } from "luxon";
import { isEmpty, isNil, find, includes } from "lodash";
import IncidentExplorer from "@site/containers/incidents/IncidentExplorer";
import { DateTime } from "luxon";
import useUserProfile from "@views/common/useUserProfile";

const IncidentsTab = ({ loading, incidents, granularity, firstReportDate }) => {
  const [availabilityStats, setAvailabilityStats] = useState({
    availability: 0,
    meanTimeToRepair: Duration.fromMillis(0),
    meanTimeBetweenFails: Duration.fromMillis(0),
  });
  useEffect(() => {
    if (!isEmpty(incidents)) {
      const stats = getAvailabilityStats(
        incidents,
        granularity,
        firstReportDate
      );
      setAvailabilityStats(stats);
    } else {
      setAvailabilityStats({
        availability: 1,
        meanTimeToRepair: null,
        meanTimeBetweenFails: null,
      });
    }
  }, [incidents, granularity, firstReportDate]);

  const {
    availability,
    meanTimeToRepair,
    meanTimeBetweenFails,
  } = availabilityStats;
  return (
    <>
      {loading && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          height={139}
        >
          <Box alignItems="center" display="flex" flexDirection="column">
            <CircularProgress />
            <Box m={2}>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {!loading && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography fontWeight="bold">
                  Availability
                  <Typography component="span" ml={1}>
                    {isNil(availability)
                      ? "N/A"
                      : `${round(availability * 100, 1).toLocaleString()}%`}
                  </Typography>
                </Typography>
                <Typography fontWeight="bold">
                  Mean Time Between Failures
                  <Typography component="span" ml={1}>
                    {isNil(meanTimeBetweenFails)
                      ? "N/A"
                      : `${round(
                          meanTimeBetweenFails.as("days"),
                          1
                        ).toLocaleString()} days`}
                  </Typography>
                </Typography>
                <Typography fontWeight="bold">
                  Mean Time To Repair
                  <Typography component="span" ml={1}>
                    {isNil(meanTimeToRepair)
                      ? "N/A"
                      : `${round(
                          meanTimeToRepair.as("days"),
                          1
                        ).toLocaleString()} days`}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 3, mt: 3 }} />
            </Grid>
            <Grid item xs={12}>
              <UptimeChart
                incidents={incidents}
                granularity={granularity}
                firstReportDate={firstReportDate}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const SiteIncidents = ({
  getSiteIncidents,
  getSiteIncidentsResult,
  getFirstReportDateResult: { firstReport },
  subscribeToSiteNotifications,
  subscribeToSiteNotificationsResult,
  unsubscribeFromSiteNotifications,
  unsubscribeFromSiteNotificationsResult,
  getSubscriptions,
  getSubscriptionsResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { siteId } = useParams();
  const userProfile = useUserProfile();

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getSiteIncidents({ accessToken, siteId });
      getSubscriptions({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getSiteIncidents, getSubscriptions, siteId]);

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const { status, incidents } = getSiteIncidentsResult;
  const loading =
    status === "request" || getSiteIncidentsResult.status === "request";

  // the date we first get a reading from the site
  const firstReportDate = DateTime.fromISO(firstReport);

  // sub/unsub from site incidents
  const toggleSubscription = async (ev, value) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    setHasSubscription(value);
    const toggleSubscription = value
      ? subscribeToSiteNotifications
      : unsubscribeFromSiteNotifications;
    toggleSubscription({
      accessToken,
      siteId,
      notifications: ["INCIDENTS"],
    });
  };

  // get subscriptions so we can set subscription state
  const { subscriptions } = getSubscriptionsResult;
  const subscription = find(
    subscriptions,
    (sub) =>
      sub.type === "SITE" &&
      sub.assetId === parseInt(siteId) &&
      includes(sub.notifications, "INCIDENTS")
  );
  const [hasSubscription, setHasSubscription] = useState(!isNil(subscription));
  useEffect(() => {
    setHasSubscription(!isNil(subscription));
  }, [subscription]);

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Incident Chart tabs"
          sx={{ pl: 3 }}
        >
          <Tab label={Granularity.NINETY_DAYS.name} sx={{ px: 3 }} />
          <Tab label={Granularity.YEAR.name} sx={{ px: 3 }} />
          <Tab label={Granularity.FIVE_YEARS.name} sx={{ px: 3 }} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <IncidentsTab
            firstReportDate={firstReportDate}
            incidents={incidents}
            granularity={Granularity.NINETY_DAYS}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <IncidentsTab
            firstReportDate={firstReportDate}
            incidents={incidents}
            granularity={Granularity.YEAR}
            loading={loading}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <IncidentsTab
            firstReportDate={firstReportDate}
            incidents={incidents}
            granularity={Granularity.FIVE_YEARS}
            loading={loading}
          />
        </TabPanel>
      </Paper>
      <Box mt={3}>
        <IncidentExplorer incidents={incidents} loading={loading} />
      </Box>
      <Box mt={3}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              onChange={toggleSubscription}
              checked={hasSubscription}
              disabled={loading}
            />
          }
          label={`Notify me (${userProfile.email})`}
        />
        <Typography color="text.secondary">
          Send emails for new and resolved incidents, for this site
        </Typography>
      </Box>
    </>
  );
};

SiteIncidents.propTypes = {
  getSiteIncidents: PropTypes.func.isRequired,
  getSiteIncidentsResult: PropTypes.shape({}).isRequired,
  getFirstReportDateResult: PropTypes.shape({
    firstReport: PropTypes.string,
  }),
  subscribeToSiteNotifications: PropTypes.func.isRequired,
  subscribeToSiteNotificationsResult: PropTypes.shape({}).isRequired,
  unsubscribeFromSiteNotifications: PropTypes.func.isRequired,
  unsubscribeFromSiteNotificationsResult: PropTypes.shape({}).isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  getSubscriptionsResult: PropTypes.shape({}).isRequired,
};

export default SiteIncidents;
