import { getPurchaseSummary } from "../actions";

export const initialState = {
  status: "init",
  purchaseSummary: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getPurchaseSummary.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getPurchaseSummary.SUCCESS: {
      const { purchaseSummary } = action.payload;
      return {
        ...state,
        status: "success",
        purchaseSummary,
      };
    }

    case getPurchaseSummary.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
