import { getCustomerProject } from "../actions";

export const initialState = {
  status: "init",
  customerProject: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getCustomerProject.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getCustomerProject.SUCCESS: {
      const { customerProject } = action.payload;
      return {
        ...state,
        status: "success",
        customerProject,
      };
    }

    case getCustomerProject.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    case getCustomerProject.RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
