import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContactInfo from "../components/ContactInfo";
import { saveContactInfo } from "../actions";
import { updateUserProfile } from "@views/common/actions";

const mapStateToProps = ({ updateUserProfile, organization }) => ({
  contactInfo: organization.contact,
  updateUserProfileResult: updateUserProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveContactInfo,
      updateUserProfile,
      resetUpdateUserProfile: () => updateUserProfile.reset(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
