import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import Page from "@components/Page";
import UserProfilePanel from "@views/individual/account/components/UserProfilePanel";
import BankInformationPanel from "@views/individual/account/components/BankInformationPanel";
import LegalPanel from "./LegalPanel";
import ApiKeyPanel from "./ApiKeyPanel";

const Account = () => {
  return (
    <Page title="User Profile" py={3}>
      <Container maxWidth="lg">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1">Account</Typography>
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserProfilePanel />
          </Grid>
          <Grid item xs={12}>
            <LegalPanel />
          </Grid>
          <Grid item xs={12}>
            <BankInformationPanel />
          </Grid>
          <Grid item xs={12} mb={3}>
            <ApiKeyPanel />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
