import { useAuth0 } from "@auth0/auth0-react";
import { DialogContent, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AddDevices, ViewDevices } from "views/customerProjects/Devices/components/Devices";
import { v4 as uuid } from "uuid";
import { patchApplicationAddMeters } from "../actions";
import { useEffect, useState } from "react";
import { Navigation } from "components";

const DevicesEditor = ({ close, customerProject, applicationId }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [showReview, setShowReview] = useState(false);

  // close editor
  const handleClose = () => {
    dispatch(patchApplicationAddMeters.reset());
    close();
  };

  const { meters } = useSelector((state) => state.addDevicesForm);
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(patchApplicationAddMeters({
      accessToken,
      applicationId,
      meters,
    }));
  };

  useEffect(() => () => {
    dispatch(patchApplicationAddMeters.reset());
  }, [dispatch]);

  const { status } = useSelector((state) => state.patchApplicationAddMeters);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";
  return (
    <DialogContent>
      {isSuccess ? (
        <>
          <Typography>
            Success! You have added new devices to your site. Feel free to close this window.
          </Typography>
          <Navigation nextAction={handleClose} nextActionText="Close" />
        </>
      ) : (
        showReview ? (
          <div>
            <ViewDevices
              devices={meters?.map(m => ({ ...m, id: m.id || uuid() }))}
              reviewOnly
            />
            <Navigation
              backAction={() => { setShowReview(false); }}
              nextAction={onSubmit}
              nextActionText="Submit"
              isSubmitting={isSubmitting}
            />
          </div>
        ) : (
          <AddDevices
            customerProject={customerProject}
            handleFinish={() => { setShowReview(true); }}
            handleBack={handleClose}
          >
            <Typography>
              Add new systems, devices, and/or meters to your existing site. Click "Continue" below to get started.
            </Typography>
          </AddDevices>
        )
      )}
    </DialogContent>
  );
};

export default DevicesEditor;
