import { useAuth0 } from "@auth0/auth0-react";
import CustomDataGrid from "components/CustomDataGrid";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import { findSystems  } from "../actions";
import { transformSites } from "views/addSites/sitePicker";
import { setTableState } from "@views/common/actions";
import {
  filter,
  flatten,
  includes,
  isNil,
  omitBy,
  startCase,
  uniq,
  first,
} from "lodash";
import { showAppMessage } from "@appMessage/actions";
import { renderCellExpand } from "@components/GridCellExpand";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import { v4 as uuid } from "uuid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { saveMeters, saveSiteId } from "views/customerProjects/Devices/actions";

const tableStateKey = "CONNECTION_EDITOR_SYSTEM_PICKER";
const initialTableState = {
  sortModel: [
    {
      field: "name",
      sort: "desc",
    },
  ],
};

const DeviceTable = ({ devices }) => {
  const columns = useMemo(() => {
    const excludedFields = [
      "backOffCount",
      "created",
      "dataEnd",
      "fetchFrequency",
      "hasPermissionToFetch",
      "id",
      "inBackOff",
      "lastAlertCheck",
      "lastCompareDate",
      "meter",
      "meterDataConnectorId",
      "modified",
      "timeToCompare",
      "timeToFetch",
    ];
    const newFields = {};
    for (const device of devices) {
      const { dataConnector } = device;
      for (const field in dataConnector) {
        // add to the table if there is a value to render and it's not among our list of excluded fields
        if (dataConnector[field] && !includes(excludedFields, field)) {
          newFields[field] = {
            headerName: startCase(field),
            field,
            flex: 1,
            editable: false,
            valueGetter: ({ field, row: device }) =>
              device.dataConnector[field],
          };
        }
      }
    }
    return [
      {
        headerName: "Manufacturer",
        field: "manufacturer",
        flex: 1,
        editable: false,
        renderCell: renderCellExpand,
      },
      {
        headerName: "Model",
        field: "model",
        flex: 1,
        editable: false,
        renderCell: renderCellExpand,
      },
      {
        headerName: "Serial Number",
        field: "serialNumber",
        flex: 1,
        editable: false,
        renderCell: renderCellExpand,
      },
      ...Object.values(newFields),
    ];
  }, [devices]);

  return (
    <DataGridPro
      style={{ border: 0 }}
      disableSelectionOnClick
      density="compact"
      columns={columns}
      rows={devices ?? []}
      hideFooter
      autoHeight
    />
  );
};

/**
 * Pick a system to add to the site
 *
 * @param customerProject the customer project
 *
 * @returns
 */
const SystemPicker = ({ customerProject }) => {
  // find systems/sites/meters
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { systems: foundSystems, status } = useSelector(state => state.findSystems);
  const { dataConnectorParams } = useSelector(state => state.addDevicesForm);

  // put the found systems in state
  const isLoading = status === "request" || status === "init";
  useEffect(() => {
    const init = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(findSystems({
        accessToken,
        dataConnectorParams,
        customerProjectId: customerProject?.id,
      }));
    };
    init();
  }, [
    dispatch,
    getAccessTokenSilently,
    dataConnectorParams,
    customerProject?.id,
  ]);
  const [availableSystems, setAvailableSystems] = useState([]);
  useEffect(() => {
    setAvailableSystems(transformSites(foundSystems));
  }, [foundSystems]);

  // selecting systems
  const [selectedSystemIds, setSelectedSystemIds] = useState([]);

  const onSelectSystem = (newSelectedSystemIds) => {
    setSelectedSystemIds(newSelectedSystemIds);

    const selectedSystems = filter(availableSystems, (system) =>
      includes(newSelectedSystemIds, system.id)
    );
    const meters = selectedSystems.map((system) =>
      system.meters.map((m) => ({
        ...omitBy(m, (v) => v === "" || isNil(v)),
        dataConnector: {
          ...m.dataConnector,
          ...dataConnectorParams
        }
      }))
    );
    // selected system must all come from the same site or all be new.
    const allNew = selectedSystems.reduce(
      (prev, { isNew }) => prev && isNew,
      true
    );
    const assetIds = uniq(selectedSystems.map(({ id }) => id));
    const sameSite = assetIds.length === 1;
    if (allNew) {
      dispatch(saveMeters({ meters: flatten(meters) }));
    } else if (sameSite) {
      dispatch(saveMeters({ meters: flatten(meters) }));
      dispatch(saveSiteId({ id: first(assetIds) }));
    } else {
      // n.b. user is trying to assign to the same site systems that have already been onboarded to different sites
      // e.g. A first user previously onboarded EV charger id 1234. Then a second user comes to onboard EV charger
      // id 1234 and 5432 to their site (application) this operation will effectively add an EV charger to the first
      // user's site. We should do a little manual investigation before we proceed with such a step.
      //
      // or e.g. A first user onboards some solar system. A second user onboards a different solar system. When a third
      // user tries to submit an application that includes these two systems this error is thrown because the user is
      // effectively claiming these systems are at the same site whereas the previous two users have indicated they
      // are in different locations.
      dispatch(showAppMessage({
        severity: "error",
        message: "We have an issue assigning these systems to your application. Please contact Rewatt for assistant."
      }));
      setSelectedSystemIds([]);
    }
  }

  // which columns to show in our data grid
  const columns = [
    {
      headerName: "System Name",
      field: "name",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Install Date",
      field: "completionDate",
      flex: 1,
      editable: false,
      valueFormatter: ({ value }) =>
        value
          ? DateTime.fromISO(value)
              .setLocale(getDefaultLocale())
              .toLocaleString(DateTime.DATE_SHORT)
          : "",
      type: "date",
    },
    {
      headerName: "Device Count",
      field: "deviceCount",
      flex: 1,
      editable: false,
      valueGetter: ({ row: system }) => system.meters?.length || "",
    },
  ];

  // manage our table state
  const { page, pageSize, sortModel, filterModel } = useSelector(
    ({ tableState }) =>
      tableState[tableStateKey] || getDefaultTableState(initialTableState)
  );
  const setSitePickerTableState = (props) =>
    dispatch(setTableState({ ...props, key: tableStateKey }));

  // detail panels
  const getDevicesPanel = useCallback(
    ({ row: system }) => (
      <DeviceTable devices={system.meters.map((m) => ({ ...m, id: uuid() }))} />
    ),
    []
  );

  const getDevicesHeight = useCallback(
    ({ row: system }) => 40 * (system.meters.length + 1),
    []
  );

  return (
    <CustomDataGrid
      autoHeight
      loading={isLoading}
      rows={availableSystems}
      columns={columns}
      disableSelectionOnClick
      sortModel={sortModel}
      onSortModelChange={(sortModel) =>
        setSitePickerTableState({ sortModel })
      }
      page={page}
      onPageChange={(page) => setSitePickerTableState({ page })}
      pageSize={pageSize}
      onPageSizeChange={(pageSize) => setSitePickerTableState({ pageSize })}
      rowsPerPageOptions={[10, 20, 50]}
      filterModel={filterModel}
      onFilterModelChange={(filterModel) =>
        setSitePickerTableState({ filterModel })
      }
      checkboxSelection
      onSelectionModelChange={onSelectSystem}
      selectionModel={selectedSystemIds}
      isRowSelectable={({ row: system }) => system.eligible}
      localeText={{
        footerRowSelected: (count) =>
          `${count.toLocaleString()} ${pluralize("system", count)} selected`,
        noRowsLabel: "No systems found.",
      }}
      getRowClassName={({ row: system }) => !system.eligible && "disabled"}
      getDetailPanelContent={getDevicesPanel}
      getDetailPanelHeight={getDevicesHeight}
    />
  );
};

export default SystemPicker;
