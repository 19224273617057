import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "@components/Page";
import { Container, Typography } from "@mui/material";
import PageLoader from "@components/PageLoader";
import { EmailLink } from "@components";

const Callback = ({ getUserProfile, getUserProfileResult }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { user, getAccessTokenSilently } = useAuth0();

  // get user profile
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getUserProfile({ accessToken, userId: user.sub });
    }
    if (user?.sub) {
      fetchUserProfile();
    }
  }, [navigate, getAccessTokenSilently, user, getUserProfile]);

  const isSuccess = getUserProfileResult?.status === "success";
  useEffect(() => {
    if (isSuccess) {
      const { userProfile } = getUserProfileResult;

      // individual
      if (userProfile.isIndividualOwner) {
        navigate("/individual/dashboard");
      }
      else if (
        userProfile.acceptedTermsAndConditions === true &&
        userProfile.organizationId > 0
      ) {
        navigate(state?.returnTo ? state.returnTo : "/projects");
      } else if (userProfile.acceptedTermsAndConditions === true) {
        navigate("/onboarding/contact");
      } else {
        navigate("/onboarding/terms");
      }
    }
  }, [isSuccess, getUserProfileResult, navigate, state]);

  const isError = getUserProfileResult?.status === "failure";
  return (
    <Page title="Start" py={3}>
      <Container maxWidth="lg">
        {!isError && <PageLoader message="Just a moment..." />}
        {isError && (
          <Typography variant="h4">
            Sorry we had a problem logging you in. Please contact{" "}
            <EmailLink email="support@rewattpower.com" />
          </Typography>
        )}
      </Container>
    </Page>
  );
};

Callback.propTypes = {
  getUserProfile: PropTypes.func.isRequired,
  getUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default Callback;
