import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { omit } from "lodash";

/**
 * The textarea variant of a textfield, styled with our theme.
 * NB. Padding changes a bit if you pass a label prop.
 * You can also specify whether the top corners are rounded or not.
 *
 */
const TextArea = styled((props) => (
  <TextField
    fullWidth
    color="secondary" // the underline
    multiline
    rows={6}
    variant="filled"
    {...omit(props, "rounded")}
  />
))(({ theme, label, rounded = true }) => {
  const styles = {
    "& .MuiFilledInput-root": {
      borderRadius: 0,
      backgroundColor:
        theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[200],
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
      "&.Mui-focused": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  };
  if (!label) {
    return {
      ...styles,
      "& .MuiFilledInput-root": {
        ...styles["& .MuiFilledInput-root"],
        padding: "10px 12px",
      },
    };
  }
  if (rounded) {
    return {
      ...styles,
      "& .MuiFilledInput-root": {
        ...styles["& .MuiFilledInput-root"],
        borderRadius: (theme) => `${theme.shape.morderRadius}px ${theme.shape.morderRadius}px 0 0`,
      },
    };
  }
  return styles;
});

export default TextArea;
