import getLiveSite from "./getLiveSite";
import getLiveChartData from "./getLiveChartData";
import getLiveStats from "./getLiveStats";
import getLiveEnergyToday from "./getLiveEnergyToday";

export default [
  ...getLiveSite,
  ...getLiveChartData,
  ...getLiveStats,
  ...getLiveEnergyToday,
];
