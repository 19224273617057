import { createProject } from "../actions";

export const initialState = {
  status: "init",
  project: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case createProject.RESET:
      return {
        ...initialState,
      };

    case createProject.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case createProject.SUCCESS: {
      const { project } = action.payload;
      return {
        ...state,
        status: "success",
        project,
      };
    }

    case createProject.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
