/**
 * Use the timeZone api; https://developers.google.com/maps/documentation/timezone/overview
 * For this api, you cannot use a referrer-restricted key.
 */
import { call, put, takeLatest } from "redux-saga/effects";
import { getTimeZone } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { isNil } from "lodash";

const callApi = async (latitude, longitude, timestamp) => {
  const response = await axios.get("https://maps.googleapis.com/maps/api/timezone/json", {
    params: {
      location: `${latitude},${longitude}`,
      timestamp,
      key: process.env.REACT_APP_TZ_API_KEY,
    },
    withCredentials: false, // we normally send cookies to maintain our php session
  });
  return response;
};

export function* getTimeZoneWorker(action) {
  yield put(getTimeZone.request());

  const { latitude, longitude } = action.payload;

  try {
    const response = yield call(
      callApi,
      latitude,
      longitude,
      Math.floor(new Date().getTime() / 1000)
    );
    if (!isNil(response.data) && !response.data.errorMessage) {
      yield put(getTimeZone.success({ timeZone: response.data }));
    } else {
      const message = `Failed to get time zone: ${response.data.errorMessage}`;
      yield put(getTimeZone.failure({ error: message }));
      yield put(showAppMessage({ severity: "error", message }));
    }
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting timeZone: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting timeZone: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting timeZone: ", err);
      message = err.message;
    }
    message = `Unable to get timeZone: ${message}`;
    yield put(getTimeZone.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getTimeZoneWatcher() {
  yield takeLatest(getTimeZone.TRIGGER, getTimeZoneWorker);
}

export default [getTimeZoneWatcher];
