import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import FundingSourceComboBox from "@views/site/components/details/FundingSourceComboBox";
import { ownershipTypes } from "@views/site/components/details/SiteDetails";
import * as Yup from "yup";
import { isNil, isEmpty, lowerCase } from "lodash";
import { phoneRegExp } from "utils/errorHelpers";
import { getFullName } from "utils/stringHelpers";
import { useSelector } from "react-redux";

export const siteOwnershipSchema = Yup.object().shape({
  equipmentOwnership: Yup.string().notRequired(),
  fundingSource: Yup.string().notRequired(),
  ownerType: Yup.string(),

  // conditional validation
  siteOwner: Yup.object().when("ownerType", {
    is: "INDIVIDUAL",
    then: Yup.object().shape({
      individual: Yup.object().shape({
        firstName: Yup.string()
          .max(60, "Too long.")
          .required("First name is required."),
        lastName: Yup.string()
          .max(60, "Too long.")
          .required("Last name is required."),
        email: Yup.string().email().required("Email is required."),
        phone: Yup.string()
          .trim()
          .transform((value) => (value === "" ? undefined : value))
          .min(10, "Too short.")
          .max(20, "Too long.")
          .test("phone", "Phone number is not valid.", (value) => {
            if (!isEmpty(value)) {
              return phoneRegExp.test(value);
            }
            return true;
          })
          .required("Phone number is required."),
      }),
    }),
  }),
});

// we need to have the forms in place, but not always show it
const FormRows = ({ ownerType, forOwnerType, ownerErrors, control }) => {
  // for external, we can change the contact info
  const isOwnerSet = !isEmpty(ownerType);
  const editable = !isOwnerSet || ownerType === "EXTERNAL";
  const display = ownerType === forOwnerType ? "block" : "none";

  return (
    <>
      <Grid item xs={12} sm={6} sx={{ display }}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="First Name"
              variant="standard"
              fullWidth
              error={!isNil(ownerErrors?.firstName?.message)}
              helperText={ownerErrors?.firstName?.message}
              required
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                readOnly: !editable,
              }}
            />
          )}
          control={control}
          name={`siteOwner.${lowerCase(forOwnerType)}.firstName`}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ display }}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Last Name"
              variant="standard"
              fullWidth
              error={!isNil(ownerErrors?.lastName?.message)}
              helperText={ownerErrors?.lastName?.message}
              required
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                readOnly: !editable,
              }}
            />
          )}
          control={control}
          name={`siteOwner.${lowerCase(forOwnerType)}.lastName`}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ display }}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Email"
              variant="standard"
              fullWidth
              error={!isNil(ownerErrors?.email?.message)}
              helperText={ownerErrors?.email?.message}
              required
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                readOnly: !editable,
              }}
            />
          )}
          control={control}
          name={`siteOwner.${lowerCase(forOwnerType)}.email`}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ display }}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Phone"
              variant="standard"
              fullWidth
              error={!isNil(ownerErrors?.phone?.message)}
              helperText={ownerErrors?.phone?.message}
              required
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                readOnly: !editable,
              }}
            />
          )}
          control={control}
          name={`siteOwner.${lowerCase(forOwnerType)}.phone`}
        />
      </Grid>
    </>
  );
};

/**
 * The idea is that you can set the site ownership, if it has not already been set.
 * If it is EXTERNAL, you can change the contact info.
 */
const SiteOwnershipForm = () => {
  const { control, errors, watch } = useFormContext();

  const ownerType = watch("ownerType", "");
  const ownerErrors = errors.siteOwner?.[lowerCase(ownerType)];

  // non-empty only if the org is the owner
  const orgOwner = watch("siteOwner.organization");

  // if unset, we can change it to our org
  const { organization } = useSelector((state) => state.getOrganization);

  // we can only change owner types, if not initially set
  const isOwnerSet = !isEmpty(ownerType);
  const [isSaved] = useState(isOwnerSet);

  return (
    <Container maxWidth="lg">
      <Box sx={{ pb: 4, mx: { xs: 1, sm: 2 } }}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Funding
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    name="equipmentOwnership"
                    variant="standard"
                    select
                    fullWidth
                    label="Equipment Ownership"
                    value={value}
                    onChange={onChange}
                  >
                    {ownershipTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
                name="equipmentOwnership"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <FundingSourceComboBox
                    value={value}
                    onChange={onChange}
                    readOnly={false}
                  />
                )}
                control={control}
                name="fundingSource"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 3 }}>
                Site Owner
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Controller
                  render={({ onChange, value }) => (
                    <RadioGroup
                      row
                      aria-labelledby="site-owner-label"
                      name="ownerType"
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="INDIVIDUAL"
                        control={<Radio />}
                        label="Individual"
                        disabled
                      />
                      <FormControlLabel
                        value="ORGANIZATION"
                        control={<Radio />}
                        label="My Organization"
                        disabled={isSaved}
                      />
                      <FormControlLabel
                        value="EXTERNAL"
                        control={<Radio />}
                        label="External"
                        disabled={isSaved}
                      />
                    </RadioGroup>
                  )}
                  control={control}
                  name="ownerType"
                />
              </FormControl>
            </Grid>
            <FormRows
              forOwnerType="INDIVIDUAL"
              ownerType={ownerType}
              control={control}
              ownerErrors={ownerErrors}
            />
            <FormRows
              forOwnerType="EXTERNAL"
              ownerType={ownerType}
              control={control}
              ownerErrors={ownerErrors}
            />
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: ownerType === "ORGANIZATION" ? "block" : "none" }}
            >
              <Typography fontWeight="bold">{orgOwner?.name}</Typography>
              <Typography>Primary Contact</Typography>
              <Box ml={1}>
                <Typography>
                  Name: {getFullName(orgOwner?.primaryContact || organization.primaryContact)}
                </Typography>
                <Typography>
                  Email: {orgOwner?.primaryContact?.email || organization.primaryContact.email}
                </Typography>
                <Typography>
                  Phone: {orgOwner?.primaryContact?.phone || organization.primaryContact.phone}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default SiteOwnershipForm;
