import React from "react";
import { usePrev, useNext } from "./useNextPrev";
import { useDispatch, useSelector } from "react-redux";
import { application as appActions } from "../actions";
import { ApplicationPage } from "./ApplicationLayout";
import { DateTime } from "luxon";
import { AddLocation } from "../../Site/components/Location";

const Location = () => {
  const application = useSelector((state) => state.customerProjectApplication);
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  const prev = usePrev();
  const next = useNext();

  const nextAction = () => {
    const { completionDate } = application.asset;
    const isBuilt = completionDate && DateTime.fromISO(completionDate) < DateTime.now();
    if (isBuilt) {
      next("devices");
    } else {
      next("docs");
    }
  };

  const dispatch = useDispatch();
  const save = (formData) => dispatch(appActions.saveAssetDetails(formData));
  return (
    <ApplicationPage pageName="Location">
      <AddLocation
        backAction={prev}
        nextAction={nextAction}
        save={save}
        siteDetails={application.asset}
        allRequired={customerProject?.emissionReductionActivity !== 'LOW_CARBON_FUEL'}
      />
    </ApplicationPage>
  );
};

export default Location;
