import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Members from "@members/components/Members";
import {
  getOrgInvites,
  deleteInvite,
  resendInvite,
  setMemberTableState,
  updatePrimaryContact,
} from "@members/actions";

const mapStateToProps = ({
  getOrgInvites: getOrgInvitesResult,
  getOrganization: getOrganizationResult,
  deleteInvite: deleteInviteResult,
  resendInvite: resendInviteResult,
  updatePrimaryContact: updatePrimaryContactResult,
  memberTableState,
}) => ({
  getOrgInvitesResult,
  getOrganizationResult,
  deleteInviteResult,
  resendInviteResult,
  memberTableState,
  updatePrimaryContactResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrgInvites,
      deleteInvite,
      resendInvite,
      setMemberTableState,
      updatePrimaryContact,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Members);
