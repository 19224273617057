import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ApplicationNotes from "../components/ApplicationNotes";
import { putApplicationNotes } from "../actions";

const mapStateToProps = ({ getApplicationNotes, putApplicationNotes }) => ({
  getApplicationNotesResult: getApplicationNotes,
  putApplicationNotesResult: putApplicationNotes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      putApplicationNotes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationNotes);
