import React from "react";
import { Link } from "@mui/material";

const EmailLink = ({ email, missing = "N/A", subject, body, cc, children }) =>
  email ? (
    <Link href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}&cc=${cc || ""}`}>
      {children || email}
    </Link>
  ) : (
    missing
  );

export default EmailLink;
