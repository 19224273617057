import getBuyerProjectReports from "./getBuyerProjectReports";
import getBuyerProjectReport from "./getBuyerProjectReport";
import getBuyerProject from "./getBuyerProject";
import submitRequestToPurchase from "./submitRequestToPurchase";

const reducers = {
  getBuyerProjectReports,
  getBuyerProjectReport,
  submitRequestToPurchase,
  getBuyerProject,
};

export default reducers;
