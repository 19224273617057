import { getLiveEnergyToday } from "../actions";

export const initialState = {
  status: "init",
  generated: 0,
  exported: 0,
  imported: 0,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getLiveEnergyToday.RESET: {
      return initialState;
    }

    case getLiveEnergyToday.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getLiveEnergyToday.SUCCESS: {
      const { generated, exported, imported } = action.payload;
      return {
        ...state,
        status: "success",
        generated,
        exported,
        imported,
      };
    }

    case getLiveEnergyToday.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
