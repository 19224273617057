import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { DialogActions, DialogContent } from "@mui/material";
import DialogSaveButton from "components/DialogSaveButton";
import { includes } from "lodash";
import { find } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SiteLocationForm from "views/apply/components/SiteLocationForm";
import { schema, transformLocation } from "views/customerProjects/Site/components/Location";
import { patchApplicationEditDetails } from "../actions";
import { useEffect } from "react";

const LocationEditor = ({ applicationId, site, close }) => {
    // setup the form
  // SiteLocationForm is looking for site.addresses.main, so if we have a main address, we need to set it here
  const mainAddress = find(site?.addresses, ({ types }) => includes(types, 'MAIN'));
  if (mainAddress) {
    site.addresses.main = mainAddress;
  };

  const { status } = useSelector((state) => state.patchApplicationEditDetails);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      timeZone: site?.timeZone || "",
      latitude: site?.latitude || "",
      longitude: site?.longitude || "",
      addresses: site?.addresses,
    }
  });

  // submit
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const onSubmit = async (formValues) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    if(formValues.addresses?.main) {
      formValues.addresses = transformLocation(formValues.addresses);
    }
    dispatch(patchApplicationEditDetails({
      application: {
        id: applicationId,
        asset: formValues,
      },
      accessToken,
      siteId: site.id
    }));
  };

  useEffect(() => () => {
    dispatch(patchApplicationEditDetails.reset());
  }, [dispatch]);

  return (
    <>
      <DialogContent>
        <FormProvider {...methods}>
          <SiteLocationForm allRequired={false} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          handleClose={close}
          handleSubmit={() => methods.handleSubmit(onSubmit)()}
        />
      </DialogActions>
    </>
  );
};

export default LocationEditor;
