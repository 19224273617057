import { call, put, takeLatest } from "redux-saga/effects";
import { approveApplication } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (
  accessToken,
  applicationId,
  notifyApplicant,
  externalTermsAndConditions
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${applicationId}/approve`,
    { externalTermsAndConditions, notifyOwner: notifyApplicant },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export function* approveApplicationWorker(action) {
  yield put(approveApplication.request());

  const {
    accessToken,
    applicationId,
    externalTermsAndConditions,
    notifyApplicant,
  } = action.payload;

  const doc = externalTermsAndConditions
    ? {
        name: externalTermsAndConditions.file.name,
        uri: externalTermsAndConditions.uri,
        tags: ["Terms and Conditions", "Override", "External"],
      }
    : undefined;

  try {
    const response = yield call(
      callApi,
      accessToken,
      applicationId,
      notifyApplicant,
      doc
    );
    yield put(approveApplication.success({ application: response.data }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to approve application: ${message}`;
    yield put(approveApplication.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* approveApplicationWatcher() {
  yield takeLatest(approveApplication.TRIGGER, approveApplicationWorker);
}

export default [approveApplicationWatcher];
