import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TermsAndConditions from "../components/TermsAndConditions";
import { getUserProfile, updateUserProfile } from "@views/common/actions";
import { showAppMessage } from "@appMessage/actions";

const mapStateToProps = ({ updateUserProfile, getUserProfile }) => ({
  updateUserProfileResult: updateUserProfile,
  getUserProfileResult: getUserProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfile,
      getUserProfile,
      showAppMessage,
      resetUpdateUserProfile: () => updateUserProfile.reset(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
