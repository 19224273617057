import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const patchApplicationEditDetails = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_APPLICATION/EDIT_DETAILS");
const patchApplicationAddDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_APPLICATION/ADD_DOCUMENTS");
const patchApplicationAddMeters = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_APPLICATION/ADD_METERS");

export {
  patchApplicationEditDetails,
  patchApplicationAddDocuments,
  patchApplicationAddMeters,
};
