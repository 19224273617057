import { put, call, takeLatest } from "redux-saga/effects";
import logger from "debug";
import { getUserProfile, updateUserProfile } from "@views/common/actions";
import { saveOrganization } from "@onboarding/actions";
import { submitApplication } from "@views/apply/actions";
import { submitProjectApplication as submitCustomerProjectApplication } from "@views/customerProjects/Apply/actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { includes } from "lodash";

const callApi = async (accessToken, userId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/userprofiles`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        userId,
      },
    }
  );
  return response;
};

function* getUserProfileWorker(action) {
  yield put(getUserProfile.request());

  const { accessToken, userId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, userId);
    const {
      data: { userProfile },
    } = response;
    yield put(getUserProfile.success({ userProfile }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting user profile: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting user profile: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting user profile: ", err);
      message = err.message;
    }
    message = `Unable to get user profile: ${message}`;
    yield put(getUserProfile.failure({ error: message }));
    // token scope error is handled in the component
    if (!includes(message, "Scope required")) {
      yield put(showAppMessage({ severity: "error", message }));
    }
  }
}

function* updateUserProfileWorker(action) {
  // updating works on a queue, so re-fetching might not get the user details
  // similarly, the update response doesn't contain any non-provided details (ie email)
  // so we must update the reducer in a custom way
  const { userProfile } = action.payload;
  // supported fields (nb. no email)
  const {
    acceptedTermsAndConditions,
    givenName,
    familyName,
    phone,
    isIndividualOwner,
    bankInformation,
  } = userProfile;
  yield put(
    getUserProfile.update({
      userProfile: {
        acceptedTermsAndConditions,
        givenName,
        familyName,
        phone,
        isIndividualOwner,
        bankInformation,
      },
    })
  );
}

function* getUserProfileWatcher() {
  yield takeLatest(getUserProfile.TRIGGER, getUserProfileWorker);
}

function* updateUserProfileWatcher() {
  // make sure the user profile gets updated with the orgId
  yield takeLatest(updateUserProfile.SUCCESS, updateUserProfileWorker);
}

function* saveOrganizationWatcher() {
  // make sure the user profile gets updated with the orgId
  yield takeLatest(saveOrganization.SUCCESS, getUserProfileWorker);
}

function* submitApplicationWatcher() {
  // we've created an org, so refetch the userProfile in order to get the orgId (for the dashboard)
  yield takeLatest(submitApplication.SUCCESS, getUserProfileWorker);
}

function* submitCustomerProjectApplicationWatcher() {
  // refetch the user profile so we can get the asset id associated with the application and show
  // the individual daashboard
  yield takeLatest(submitCustomerProjectApplication.SUCCESS, getUserProfileWorker);
}

export default [
  getUserProfileWatcher,
  updateUserProfileWatcher,
  saveOrganizationWatcher,
  submitApplicationWatcher,
  submitCustomerProjectApplicationWatcher,
];
