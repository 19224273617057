import React, { useEffect } from "react";
import { Typography, Container, Box, Paper } from "@mui/material";
import Page from "@components/Page";
import { useForm, FormProvider } from "react-hook-form";
import { includes } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { updateUserProfile } from "@views/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "@components";
import BankInformationForm, { bankInfoSchema } from "./BankInformationForm";

const BankInformation = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { userProfile } = useSelector((state) => state.getUserProfile);

  const methods = useForm({
    resolver: yupResolver(bankInfoSchema),
    mode: "onChange",
    defaultValues: {
      institutionNumber: userProfile.bankInformation?.institutionNumber || "",
      transitNumber: userProfile.bankInformation?.transitNumber || "",
      accountNumber: userProfile.bankInformation?.accountNumber || "",
    },
  });

  const onSubmit = async (bankInformation) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(
      updateUserProfile({
        accessToken,
        bankInformation,
      })
    );
  };

  // nav on success
  const { status: updateUserProfileStatus } = useSelector((state) => state.updateUserProfile);
  useEffect(() => {
    if (updateUserProfileStatus === "success") {
      dispatch(updateUserProfile.reset());
      navigate("/marketplace/payee/join");
    }
  }, [navigate, updateUserProfileStatus, dispatch]);

  const isSubmitting = includes(["request"], updateUserProfileStatus);

  return (
    <Page title="Bank Info" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              Site Payee
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Create your account
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <FormProvider {...methods}>
              <BankInformationForm />
            </FormProvider>
            <Navigation
              nextAction={() => methods.handleSubmit(onSubmit)()}
              nextActionText="Continue"
              isSubmitting={isSubmitting}
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default BankInformation;
