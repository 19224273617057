import { createAction } from "redux-actions";
import {
  createRoutineCreator,
  defaultRoutineStages,
  createRoutine,
} from "redux-saga-routines";

// save site details using siteInfo reducer

const saveSiteActivity = createAction("SAVE_SITE_ACTIVITY");
saveSiteActivity.TYPE = saveSiteActivity.toString();

const saveManufacturer = createAction("SAVE_MANUFACTURER");
saveManufacturer.TYPE = saveManufacturer.toString();

const saveCredentials = createAction("SAVE_CREDENTIALS");
saveCredentials.TYPE = saveCredentials.toString();

const resetSiteInfo = createAction("RESET_SITE_INFO");
resetSiteInfo.TYPE = resetSiteInfo.toString();

// find sites from surge, for specified credentials
const findSites = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "FIND_SITES"
);

// see if credentials provided for a manufacturer work
const testConnection = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "TEST_CONNECTION"
);

// set up a permission request with SMA portal
const requestSmaPermission = createRoutine("REQUEST_SMA_PERMISSION");

// get a timeZone from coords
const getTimeZone = createRoutine("GET_TIMEZONE");

// reverse geocoding - get address from coords
const getAddress = createRoutineCreator([...defaultRoutineStages, "RESET"])("GET_ADDRESS");

// send multiple files to the server
const uploadFiles = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "UPLOAD_FILES"
);

// add new (or existing) assets to an org (the ultimate submit)
const addSites = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_SITE"
);

export {
  saveSiteActivity,
  saveManufacturer,
  saveCredentials,
  testConnection,
  requestSmaPermission,
  findSites,
  getTimeZone,
  getAddress,
  uploadFiles,
  addSites,
  resetSiteInfo,
};
