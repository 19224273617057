import { call, put, takeLatest } from "redux-saga/effects";
import { getSiteChart } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { fillData } from "@utils/chartHelpers";

const callApi = async (accessToken, siteId, startDate, endDate) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/${siteId}/chart`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        start: startDate.toISODate(),
        end: endDate.toISODate(),
      },
    }
  );
  return response;
};

// strip the timezone portion and replace 'T' with a space
const normalizeDate = (date) => {
  return date.replace(/-\d{2}:\d{2}/, "").replace("T", " ");
};

export const transform = (data, granularity, dateRange) => {
  return [
    {
      name: "Generation",
      stat: "generation",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date), // yyyy-MM-dd HH:mm:ss || yyyy-MM-ddTHH:mm:ss-06:00
          y: datum.generation ? datum.generation / 1000 / 60 : null,
        })),
        granularity,
        dateRange,
      }),
    },
    {
      name: "Grid Export",
      stat: "export",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date),
          y: datum.export ? datum.export / 1000 / 60 : null,
        })),
        granularity,
        dateRange,
      }),
    },
    {
      name: "Grid Import",
      stat: "consumption",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date),
          y: datum.consumption ? datum.consumption / 1000 / 60 : null,
        })),
        granularity,
        dateRange,
      }),
    },
    {
      name: "Temperature",
      stat: "temperature",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date),
          y: datum.temperature,
        })),
        granularity,
        dateRange,
      }),
    },
    {
      name: "Snow",
      stat: "snow",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date),
          y: datum.snow,
        })),
        granularity,
        dateRange,
      }),
    },
    {
      name: "Daylight",
      stat: "daylight",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date),
          y: datum.daylight,
        })),
        granularity,
        dateRange,
      }),
    },
    {
      name: "Emission Reductions",
      stat: "emissionReductions",
      data: fillData({
        data: data.map((datum) => ({
          x: normalizeDate(datum.date),
          y: datum.emissionReductions,
        })),
        granularity,
        dateRange,
      }),
    },
  ];
};

export function* getSiteChartWorker(action) {
  yield put(getSiteChart.request());

  const { accessToken, startDate, endDate, siteId } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      siteId,
      startDate,
      endDate
    );
    const { data, granularity } = response.data;
    yield put(
      getSiteChart.success({
        data: transform(data, granularity, [startDate, endDate]),
        granularity,
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting chart data: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting chart data: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting chart data: ", err);
      message = err.message;
    }
    message = `Unable to get chart data: ${message}`;
    yield put(getSiteChart.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getSiteChartWatcher() {
  yield takeLatest(getSiteChart.TRIGGER, getSiteChartWorker);
}

export default [getSiteChartWatcher];
