import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { includes } from "lodash";
import TermsAndConditions from "@components/TermsAndConditions";
import Page from "@components/Page";
import { Container } from "@mui/material";
import RewattTermsPDF from "@assets/ReWatt - Account Terms and Conditions - v.3.pdf";
import { updateUserProfile } from "@views/common/actions";
import { useAppMessage } from "@appMessage";
import { useDispatch, useSelector } from "react-redux";

const InviteTermsAndConditions = () => {
  const navigate = useNavigate();
  const showAppMessage = useAppMessage();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // submit
  const submit = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      // this also fills up getUserProfile
      dispatch(
        updateUserProfile({
          accessToken,
          acceptedTermsAndConditions: true,
        })
      );
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
    }
  };

  const { status: updateUserProfileStatus } = useSelector((state) => state.updateUserProfile);
  const { invite } = useSelector((state) => state.verifyAssetClaimInviteToken);  

  useEffect(() => {
    if (updateUserProfileStatus === "success") {
      dispatch(updateUserProfile.reset());
      navigate("/assetclaim/accept/personal");
    }
  }, [
    navigate,
    updateUserProfileStatus,
    getAccessTokenSilently,
    invite,
    dispatch,
  ]);

  const isSubmitting = includes(["request"], updateUserProfileStatus);

  return (
    <Page title="Terms and Conditions">
      <Container maxWidth={false}>
        <TermsAndConditions
          pdf={{
            url: RewattTermsPDF,
            name: "Rewatt Terms and Conditions.pdf",
          }}
          acceptTermsAndConditions={submit}
          isSubmitting={isSubmitting}
          submitActionText="Continue"
        />
      </Container>
    </Page>
  );
};

export default InviteTermsAndConditions;
