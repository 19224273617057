/**
 * Rounds a number to the desired decimal precision, as needed.
 */
const round = (num, precision = 2) => Math.round(
  (num + Number.EPSILON) * Math.pow(10, precision)
) / Math.pow(10, precision);

/**
 * True if a string has only digits.
 */
const isNumeric = (val) => (val - parseFloat(val) + 1) >= 0;

export { round, isNumeric };
