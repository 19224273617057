import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Tooltip } from "@mui/material";
import { map } from "lodash";
import { getDefaultLocale } from "@utils/dateHelpers";
import { DateTime } from "luxon";
import {
  getStartDate,
  getChartData,
  IncidentStatus,
} from "./IncidentCalculator";

const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // only useful if we overflow
    const getDimensions = () => ({
      width: myRef.current.scrollWidth,
      height: myRef.current.scrollHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

const Pill = styled("div", { shouldForwardProp: false })(
  ({ theme, $incidentStatus }) => {
    const backgroundColor =
      $incidentStatus === IncidentStatus.INCIDENT
        ? theme.palette.error.main
        : $incidentStatus === IncidentStatus.NO_INCIDENT
        ? theme.palette.secondary.main
        : theme.palette.grey[300];
    return {
      width: 5,
      height: $incidentStatus === IncidentStatus.INCIDENT ? 48 : 37,
      borderRadius: 5,
      backgroundColor,
      paddingRight: 3,
      marginTop: $incidentStatus === IncidentStatus.INCIDENT ? 0 : 5,
      flexShrink: 0,
    };
  }
);

// one bar for each date, blue or red
const UptimeChart = ({ incidents, granularity, firstReportDate }) => {
  const ref = useRef(null);
  // scroll for small screens, otherwise "Today" is in the wrong place
  const { width } = useContainerDimensions(ref);

  // show a nice start date
  const locale = getDefaultLocale();
  const startDate = getStartDate(granularity);

  // compute
  const chartData = getChartData(incidents, granularity, firstReportDate);

  return (
    <Box display="flex" flexDirection="column" sx={{ overflowX: "auto" }}>
      <Box display="flex" ref={ref} justifyContent="space-between">
        {map(chartData, (datum, index) => (
          <Tooltip
            title={datum.date
              .setLocale(locale)
              .toLocaleString(DateTime.DATE_FULL)}
            key={index}
            placement="top"
            arrow
          >
            <Pill $incidentStatus={datum.incidentStatus} />
          </Tooltip>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={1}
        width={width === 0 ? 9999 : width}
      >
        <Typography>
          {startDate.setLocale(locale).toLocaleString(DateTime.DATE_MED)}
        </Typography>
        <Typography>Today</Typography>
      </Box>
    </Box>
  );
};

export default UptimeChart;
