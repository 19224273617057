import { connect } from "react-redux";
import IncidentExplorer from "@site/components/incidents/IncidentExplorer";
import { bindActionCreators } from "redux";
import { getIncidentLog } from "@site/actions";

const mapStateToProps = ({
  getIncidentLog: getIncidentLogResult,
}) => {
  return {
    getIncidentLogResult,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIncidentLog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IncidentExplorer);
