import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/AddCircle";
import TagSelector from "./TagSelector";
import { uniq, map, intersection } from "lodash";

export const TagList = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  listStyle: "none",
  padding: theme.spacing(0.5),
  margin: 0,
  height: "100%",
}));

// if doc has any of the provided tagNames
export const hasOneOfTags = (doc, tagNames) => {
  const docTagNames = map(doc.tags, (tag) => tag.name || tag);
  return intersection(tagNames, docTagNames).length > 0;
};

const TagManager = ({ setTags, document, disabled }) => {
  const { file, tags } = document;
  const [open, setOpen] = useState(false);

  const handleDelete = (tagToDelete) => () => {
    setTags({
      tags: tags.filter((tag) => tag !== tagToDelete),
      document,
    });
  };

  const handleAddTag = (tag) => {
    setOpen(false);
    if (tag) {
      setTags({
        tags: uniq([...tags, tag]),
        document,
      });
    }
  };

  return (
    <TagList component="ul" elevation={0}>
      {tags.map((data) => {
        return (
          <li key={data}>
            <Chip label={data} onDelete={handleDelete(data)} disabled={disabled} sx={{ m: 0.5 }} />
          </li>
        );
      })}
      {!disabled && (
        <li key="add">
          <Chip
            label="Add Type"
            color="primary"
            sx={{ m: 0.5 }}
            icon={<AddIcon />}
            onClick={() => setOpen(true)}
          />
        </li>
      )}
      <TagSelector open={open} setValue={handleAddTag} documentName={file.path} />
    </TagList>
  );
};

TagManager.propTypes = {
  document: PropTypes.shape({
    file: PropTypes.oneOfType([
      PropTypes.instanceOf(File),
      PropTypes.shape({
        path: PropTypes.string,
      }),
    ]),
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setTags: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TagManager;
