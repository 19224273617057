import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Box, Tooltip, Button } from "@mui/material";
import Logo from "@components/Logo";
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { getUserProfile } from "@views/common/actions";

const TopBar = ({ className, resetUserProfile, ...rest }) => {
  const { logout } = useAuth0();

  const logoutWithRedirect = () => {
    resetUserProfile();
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <AppBar
      sx={{
        color: "primary.main",
        backgroundColor: "common.white",
      }}
      elevation={1}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Tooltip title="Log out and return to the home page.">
          <Button
            variant="text"
            color="primary"
            onClick={logoutWithRedirect}
          >
            Logout
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  resetUserProfile: PropTypes.func.isRequired,
};

// connect to redux

const mapDispatchToProps = (dispatch) => ({
  resetUserProfile: () => dispatch(getUserProfile.reset()),
});

export default connect(null, mapDispatchToProps)(TopBar);
