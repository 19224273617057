import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "components";
import { createSite } from "../actions";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuid } from "uuid";
import { ViewDocuments } from "./Documents";
import { ViewLocation, transformLocation } from "./Location";
import { ViewDetails, transformCompletionDate } from "./Details";
import { ViewDevices, transformDevices } from "../../Devices/components/Devices";
import { omitBy } from "lodash";

export const ReviewDetails = (props) => (
  <Grid container spacing={4}>
    <Grid item xs={12}>
      <Typography>
        {props.description}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardHeader title="Site Details"/>
        <CardContent>
          <ViewDetails {...props} reviewOnly />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardHeader title="Location"/>
        <CardContent>
          <ViewLocation {...props} reviewOnly />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardHeader title="Documents"/>
        <CardContent>
          <ViewDocuments {...props} reviewOnly />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card elevation={0}>
        <CardHeader title="Device List"/>
        <CardContent>
          <ViewDevices
            devices={props.site.meters?.map(m => ({ ...m, id: m.id || uuid() }))}
            customerProject={props.customerProject}
            reviewOnly
          />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);



const ReviewSitetoAdd = ({
  addAnother,
  backAction,
  siteDetails,
  customerProject,
}) => {
  const { status } = useSelector((state) => state.createSite);
  const isSubmitting = status === "request";
  const wasSuccessful = status === "success";
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const handleSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const trimmed = omitBy(siteDetails, value => value === "");
    if (trimmed.completionDate) {
      trimmed.completionDate = transformCompletionDate(trimmed.completionDate);
    }
    if (trimmed.meters) {
      trimmed.meters = transformDevices(trimmed.meters, trimmed.credentials);
    }
    if (trimmed.addresses?.main) {
      trimmed.addresses = transformLocation(trimmed.addresses);
    }
    dispatch(createSite({ accessToken, siteDetails: trimmed, customerProject }));
  };

  return wasSuccessful ? (
    <>
      <Card elevation={0}>
        <CardHeader title="Success!"/>
        <Typography>
          You have successfully added a new site to your project. You may close this menu or add another site.
        </Typography>
      </Card>
      <Navigation
        backAction={null}
        nextAction={addAnother}
        nextActionText="Add Another"
      />
    </>
  ) : (
    <>
      <ReviewDetails
        site={siteDetails}
        customerProject={customerProject}
        description='Review the following site details. Click "Submit" at the bottom of this page to finalize the creation of your site.'
      />
      <Navigation
        backAction={backAction}
        nextAction={handleSubmit}
        nextActionText="Submit"
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default ReviewSitetoAdd;
