import getSiteStats from "./getSiteStats";
import getSiteCredits from "./getSiteCredits";
import getSiteEnergyToday from "./getSiteEnergyToday";
import getIndividualApplications from "./getIndividualApplications";

export default [
  ...getSiteStats,
  ...getSiteCredits,
  ...getSiteEnergyToday,
  ...getIndividualApplications,
];
