import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import EmailLink from "@components/EmailLink";

const CachelanInstructionPanel = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>Instructions to Grant Rewatt Access</Typography>
      <Typography gutterBottom>
        To connect with Cachelan, two actions are required.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="You need to send an email to Cachelan saying that you approve of Rewatt Power receiving your system data."
            secondary={
              <EmailLink
                email="customerrelations@cachelan.com"
                subject="Permission request"
                body="Please allow Rewatt Power to access data from my system."
                cc="support@rewattpower.com"
              />
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Provide us with the URL you use to access your Cachelan portal."
            secondary="It should be something like this: https://mysite.solarvu.net"
          />
        </ListItem>
      </List>
      <Typography gutterBottom>
        Once Cachelan receives your email, they will grant Rewatt access to your system. 
        This may take a few days, and then you can return to this screen to add your site.
      </Typography>      
    </Box>
  );
};

export default CachelanInstructionPanel;
