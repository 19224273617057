import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import ManufacturerForm from "./ManufacturerForm";
import { EMISSION_REDUCTION_ACTIVITIES } from "views/program/components/Program";

const Manufacturer = (props) => {
  const formMethods = useForm();
  const {
    connectionTestResult: { canAccessData },
    showAppMessage,
    stepLayout: StepLayout,
    backAction,
    nextAction,
    StepNav
  } = props;

  // next button
  const onNext = async () => {
    // make sure we show any validation on the embedded form ie. required fields
    // the only way we can do that is if we get the form setup here
    const valid = await formMethods.trigger();

    if (valid && canAccessData) {
      nextAction();
    } else if (!valid) {
      showAppMessage({
        severity: "warning",
        message: "Please fix all form errors.",
      });
    } else {
      showAppMessage({
        severity: "warning",
        message: "Please ensure you have a valid connection first.",
      });      
    }
  };

  return (
    <StepLayout activeStep={1}>
      {/* manufacturer, instructions, creds */}
      <ManufacturerForm {...props} formMethods={formMethods} />

      {/* next/prev */}
      <StepNav backAction={backAction} nextAction={onNext} />
    </StepLayout>
  );
};

Manufacturer.propTypes = {
  siteInfo: PropTypes.shape({
    manufacturer: PropTypes.string,
  }),
  showAppMessage: PropTypes.func.isRequired,
  saveManufacturer: PropTypes.func.isRequired,
  saveCredentials: PropTypes.func.isRequired,
  testConnection: PropTypes.func.isRequired,
  resetTestConnection: PropTypes.func.isRequired,
  connectionTestResult: PropTypes.shape({
    canAccessData: PropTypes.bool,
    status: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,

  // ie. renderable
  stepLayout: PropTypes.func.isRequired,

  // override the back and next button actions
  backAction: PropTypes.func.isRequired,

  // just the navigation; we do other things with the form separately
  nextAction: PropTypes.func.isRequired,

  // Navigation component
  StepNav: PropTypes.func.isRequired,

  // filter manufacturer based on the emission reduction activity
  emissionReductionActivity: PropTypes.oneOf(Object.keys(EMISSION_REDUCTION_ACTIVITIES)),
};

export default Manufacturer;
