// NB. getUserProfile will be updated on SUCCESS
import { put, call, takeLatest, select } from "redux-saga/effects";
import logger from "debug";
import { updateUserProfile } from "@views/common/actions";
import axios from "axios";
import { join, values, pickBy, isNil } from "lodash";
import { showAppMessage } from "@appMessage/actions";
import { getUserProfile } from "@views/common/selectors";

const callApi = async (accessToken, userProfileId, requestBody) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/userprofiles/${userProfileId}`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* updateUserProfileWorker(action) {
  yield put(updateUserProfile.request());

  // any null or undefined props will be filtered out, so send whichever are of interest
  const {
    accessToken,
    acceptedTermsAndConditions,
    firstName,
    lastName,
    phone,
    isIndividualOwner,
    bankInformation,
  } = action.payload;
  const { id: userProfileId } = yield select(getUserProfile);

  const requestBody = {
    acceptedTermsAndConditions,
    givenName: firstName,
    familyName: lastName,
    phone,
    isIndividualOwner,
    bankInformation,
  };

  try {
    const response = yield call(
      callApi,
      accessToken,
      userProfileId,
      pickBy(requestBody, (val) => !isNil(val))
    );
    const { userProfile } = response.data;
    yield put(updateUserProfile.success({ userProfile, userId: userProfile.userId }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error updating user profile: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error updating user profile: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error updating user profile: ", err);
      message = err.message;
    }
    message = `Unable to update user profile: ${message}`;
    yield put(
      updateUserProfile.failure({
        error: message,
      })
    );
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* updateUserProfileWatcher() {
  yield takeLatest(updateUserProfile.TRIGGER, updateUserProfileWorker);
}

export default [updateUserProfileWatcher];
