import React from "react";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

/**
 * This component is used as the button in a Dialog based form.
 * It will save (i.e. trigger a backend request) and, on success, allow
 * you to close the dialog.
 * 
 * @param {*} isSubmitting whether or not the backend request was successful
 * @param {*} isSuccess whether or not the backend request was successful
 * @param {*} handleSubmit submit the form, i.e. trigger the backend request
 * @param {*} handleClose close the dialog and any other side-effects
 */

const DialogSaveButton = styled(
  ({ isSuccess, isSubmitting, handleSubmit, handleClose, ...props }) => (
    <LoadingButton
      variant="contained"
      onClick={isSuccess ? handleClose : handleSubmit}
      disabled={isSubmitting}
      loading={isSubmitting}
      size="large"
      {...props}
    >
      {isSuccess ? "Close" : "Save"}
    </LoadingButton>
  )
)(({ theme, isSuccess }) => {
  const color = isSuccess ? "success" : "primary";
  return {
    color: theme.palette.common.white,
    backgroundColor: theme.palette[color].main,
    "&:hover": {
      backgroundColor: theme.palette[color].dark,
    },
  };
});

export default DialogSaveButton;
