import React, { useEffect } from "react";
import {
  Box,
  Button,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_STATUSES } from "@views/programs";
import { DateTime } from "luxon";
import { getDefaultLocale, getDefaultTimeZone } from "@utils/dateHelpers";
import { find, first, includes, isEmpty, isNil, last, sortBy } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { getSiteCredits } from "../actions";
import { useNavigate } from "react-router";

const getStatusDate = (application) => {
  const statusChange = find(application.statusChanges, { status: application.status });
  // n.b. no use case where statusChange is nil, but jic don't crash the app
  const dt = isNil(statusChange) ? DateTime.fromSeconds(application.created) : DateTime.fromISO(statusChange.date)
  return dt.setZone(getDefaultTimeZone()).setLocale(getDefaultLocale());
};

export const DisplayBox = ({ label, value, first = false, withEmphasis = false }) => (
  <Box
    ml={{
      xs: 0, // no margin left on mobile
      sm: first ? 0 : 3,
    }}
    mt={{
      xs: 2, // add top margin on mobile
      sm: 0,
    }}
    mr={3}
    minWidth={150}
  >
    <Typography variant="body1">
      {label}
    </Typography>
    <Typography
      color={withEmphasis ? "warn.main" : "secondary.main"}
      variant="body2"

    >
      {withEmphasis ? (<strong>{value}</strong>) : value}
    </Typography>
  </Box>
);

const ProjectStatus = ({ projectReports }) => {
  const steps = [
    'Generating',
    'Verifying',
    'Registering',
    'Selling'
  ];
  const determineProjectStatusStep = (projectReports) => {
    if (isEmpty(projectReports)) {
      // no project reports means the the project is still
      // accumulating credits for it's first project report
      return 0;
    }
    const latestPr = last(sortBy(projectReports, 'startDate'));
    const { statusHistory } = latestPr;
    if (isEmpty(statusHistory)) {
      // newly created which means we're doing a verification
      return 1;
    };
    const latestStatus = last(sortBy(statusHistory, 'created'));
    switch (latestStatus.status) {
      // verification report is being review by third-party
      case 'VERIFICATION_SUBMITTED':
        return 1;
      // third-party has verified report - now time to register
      case 'VERIFIED':
      case 'VALIDATED':
        return 2;
      // project report has been submitted to governing authority - registering
      case 'REGISTRATION_SUBMITTED':
        return 2;
      // project report has been accepted by governing authority - time to sell!
      case 'REGISTERED':
        return 3;
      // someone has made an offer - negotiation in progress, we're still selling.
      case 'PENDING_TRANSFER':
        return 3;
      default:
        // RETIRED falls here, which means we've successfully sold the PR
        // and generating again for the next project report
        return 0;
    }
  };
  const activeStep = determineProjectStatusStep(projectReports);
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, idx) => (
        <Step key={label}>
          {/* @todo using optional we can add the date the step happened*/}
          <StepLabel>
              {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
};

const ProjectStats = ({ application }) => {
  // @todo possible for the site associated with the application 
  // to end up in more than one project.
  const assetProjectRelation = first(application.assetProjectRelations);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { userProfile } = useSelector((state) => state.getUserProfile);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSiteCredits({ accessToken, siteId: userProfile.assetId, projectId: assetProjectRelation.project.id }));
    }
    if (assetProjectRelation?.project?.id) {
      init();
    }
  }, [dispatch, getAccessTokenSilently, assetProjectRelation?.project?.id, userProfile.assetId]);
  const { credits, status } = useSelector(state => state.getSiteCredits);
  const isLoading = status === 'request';


  let accountingStartDate = assetProjectRelation.activityStartDate;
  const newestPr = last(sortBy(assetProjectRelation?.project?.projectReports, 'startDate'));
  if (newestPr) {
    const { statusHistory } = newestPr;
    const mostRecentStatusChange = last(sortBy(statusHistory, 'created'));
    if (mostRecentStatusChange?.status === 'RETIRED') {
      accountingStartDate = newestPr.endDate;
    }
  }
  
  return (
    <Stack spacing={1}>
      <Box display="flex" flexWrap="wrap" pt={3}>
        <DisplayBox
          label="Credit Accounting Start Date"
          value={isLoading ? <Skeleton variant="text"/> : DateTime.fromISO(accountingStartDate)
            .setZone(getDefaultTimeZone())
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_FULL)}
          first
        />
        <DisplayBox
          label="Credits Accumulated"
          value={isLoading ? <Skeleton variant="text"/> : credits?.toLocaleString(undefined, {
            maximumFractionDigits: 3,
            minimumFractionDigits: 1,
          })}
        />
      </Box>
      <Box pt={3}>
        <Typography>Status of Credits</Typography>
        <ProjectStatus projectReports={assetProjectRelation.project.projectReports} />
      </Box>
    </Stack>
  );
};

const ViewApplicationSummary = ({ application }) => {

  const inProject = application.assetProjectRelations.length > 0;
  if (inProject) {
    return <ProjectStats application={application} />
  }

  const notApproved = includes([
    'READY_FOR_REVIEW',
    'MISSING_TERMS_AND_CONDITIONS',
    'PERMANENTLY_DECLINED',
    'ON_HOLD',
    'CHANGES_REQUIRED',
    'INCOMPLETE',
  ], application.status);
  const approved = application.status === 'APPROVED';

  return (
    <Box display="flex" flexWrap="wrap" pt={3}>
      {notApproved && (
        <>
          <DisplayBox
              label="Application Status"
              value={APPLICATION_STATUSES[application?.status]}
              withEmphasis={application?.status === 'CHANGES_REQUIRED'}
              first
            />
          <DisplayBox
            label="Status Updated On"
            value={getStatusDate(application).toLocaleString(
              DateTime.DATE_FULL
            )}
          />
          <DisplayBox
            label="Application Submitted on"
            value={
              DateTime
                .fromSeconds(application.created)
                .setZone(getDefaultTimeZone())
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_FULL)
            }
          />
        </>
      )}
      {approved && (
        <>
          <DisplayBox
            label="Approved On"
            value={getStatusDate(application).toLocaleString(
              DateTime.DATETIME_FULL
            )}
          />
          <DisplayBox
            label="Has Credit accounting started?"
            value="No"
          />
        </>
      )}
    </Box>
  );
};

const ApplicationStatusPanel = ({ applications }) => {
  const navigate = useNavigate();
  const [applicationIdx, setApplicationIndex] = React.useState(0);
  const handleChange = (event, newIndex) => {
    setApplicationIndex(newIndex);
  };
  const handleViewApplication = () => {
    const application = applications[applicationIdx];
    navigate(`/individual/applications/${application.id}`);
  };

  return (
    <>
      <Tabs
        value={applicationIdx}
        onChange={handleChange}
        aria-label="Applications"
      >
        {applications?.map((application) => (
          <Tab
            label={application.customerProjectTitle || application.programName}
            key={application.id}
          />
        ))}
      </Tabs>
      {applications?.map((application, index) => (
        <div
          role="tabpanel"
          hidden={applicationIdx !== index}
          id={`application-tabpanel-${index}`}
          aria-labelledby={`application-tab-${index}`}
          key={index}
        >
          <ViewApplicationSummary application={application} />
        </div>
      ))}
      <Box mt={2}>
        <Button
          onClick={handleViewApplication}
        >
          View Application Details
        </Button>
      </Box>
    </>
  )
};

export default ApplicationStatusPanel;
