import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import StatsCard from "./StatsCard";
import pluralize from "pluralize";

const ProjectStatsPanel = ({
  project,
  getProjectStatsResult,
  goProjectSites,
  goProjectReports,
}) => {
  const {
    projectReportCount,
    siteCount,
    totalCapacity,
  } = getProjectStatsResult.projectStats;

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <StatsCard
            title="Total Capacity (kW)"
            value={(totalCapacity / 1000).toLocaleString()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatsCard
            title={`${pluralize("Site", siteCount)} in Project`}
            value={siteCount}
            action={goProjectSites}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatsCard
            title="Project Reports"
            value={projectReportCount}
            action={goProjectReports}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ProjectStatsPanel.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  getProjectStatsResult: PropTypes.shape({}).isRequired,
  goProjectSites: PropTypes.func.isRequired,
  goProjectReports: PropTypes.func.isRequired,
};

export default ProjectStatsPanel;
