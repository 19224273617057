import { getProjectReportCertificate } from "../actions";

export const initialState = {
  status: "init",
  certificate: {
    created: 0,
    logo: null,
    title: "",
    message: null,
    verifierStatement: null,
    verifierLogo: null,
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getProjectReportCertificate.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getProjectReportCertificate.SUCCESS: {
      const { certificate } = action.payload;
      return {
        ...state,
        status: "success",
        certificate: {
          ...state.certificate,
          ...certificate,
        },
      };
    }

    case getProjectReportCertificate.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
