import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const getCustomerProjects = createRoutine("GET_CUSTOMER_PROJECTS");
const getCustomerProject = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_CUSTOMER_PROJECT"
);

const createCustomerProject = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_CUSTOMER_PROJECTS"
);
const patchCustomerProject = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_CUSTOMER_PROJECTS"
);

const getIncompleteSitesCount = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_INCOMPLETE_SITE_COUNT"
);

const uploadCustomerProjectTerms = createRoutine("UPLOAD_CUSTOMER_PROJECT_TERMS");
const uploadCustomerProjectLogo = createRoutine("UPLOAD_CUSTOMER_PROJECT_LOGO");


const getProjectApplications = createRoutine("GET_PROJECT_APPLICATIONS");
const getProjectApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
  "LOAD",
])("GET_PROJECT_APPLICATION");

const getCustomerProjectChart = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_CUSTOMER_PROJECT_CHART"
);

const getCustomerProjectCreditSummary = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_CUSTOMER_PROJECT_CREDIT_SUMMARY"
);


export {
  getCustomerProject,
  getCustomerProjects,
  createCustomerProject,
  patchCustomerProject,
  getIncompleteSitesCount,
  uploadCustomerProjectTerms,
  uploadCustomerProjectLogo,
  getProjectApplications,
  getProjectApplication,
  getCustomerProjectChart,
  getCustomerProjectCreditSummary,
};
