import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  TextField,
  Paper,
} from "@mui/material";
import Page from "@components/Page";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { isNil, isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Navigation from "@components/Navigation";
import { useNavigate } from "react-router-dom";
import { phoneRegExp } from "utils/errorHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { usePrevPath, usePrev } from "./useNextPrev";

export const personalInfoSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(60, "Too long.")
    .required("First name is required."),
  lastName: yup
    .string()
    .max(60, "Too long.")
    .required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  phone: yup
    .string()
    .trim()
    .transform((value) => (value === "" ? undefined : value))
    .min(10, "Too short.")
    .max(20, "Too long.")
    .test("phone", "Phone number is not valid.", (value) => {
      if (!isEmpty(value)) {
        return phoneRegExp.test(value);
      }
      return true;
    })
    .required("Phone number is required."),
});

const PersonalInfo = ({
  getProgramResult,
  application,
  updateUserProfile,
  updateUserProfileResult,
  resetUpdateUserProfile,
  getUserProfileResult,
}) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { userProfile } = getUserProfileResult;

  const prevPath = usePrevPath();
  const prev = usePrev();
  const backAction = prevPath ? () => prev() : null; // null, terms

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(personalInfoSchema),
    mode: "onChange",
    defaultValues: {
      ...userProfile,
      firstName: userProfile.givenName || "",
      lastName: userProfile.familyName || "",
      phone: userProfile.phone || "",
    },
  });

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { firstName, lastName, phone } = data;
    updateUserProfile({
      accessToken,
      firstName,
      lastName,
      phone,
    });
  };

  // nav on success
  const missingPersonal =
    isNil(userProfile.givenName) ||
    isNil(userProfile.familyName) ||
    isNil(userProfile.phone);

  useEffect(() => {
    if (!missingPersonal && updateUserProfileResult.status === "success") {
      resetUpdateUserProfile();
      navigate("/apply/start");
    }
  }, [
    missingPersonal,
    navigate,
    resetUpdateUserProfile,
    application.programId,
    updateUserProfileResult.status,
  ]);

  const isSubmitting = updateUserProfileResult.status === "request";

  return (
    <Page title="Contact Info" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              {getProgramResult.program.name} Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Create your account
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography mt={4}>What's your name?</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="First Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.firstName?.message)}
                        helperText={errors.firstName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.lastName?.message)}
                        helperText={errors.lastName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="lastName"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography mt={4}>
                    What's your contact information?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Email"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.email?.message)}
                        helperText={errors.email?.message}
                        required
                        onChange={onChange}
                        value={value}
                        type="email"
                        color="secondary"
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                    control={control}
                    name="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Best Number to Reach You"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.phone?.message)}
                        helperText={errors.phone?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="phone"
                  />
                </Grid>
              </Grid>
              <Navigation
                backAction={backAction}
                nextAction={() => handleSubmit(onSubmit)()}
                nextActionText="Continue"
                isSubmitting={isSubmitting}
              />
            </form>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

PersonalInfo.propTypes = {
  getProgramResult: PropTypes.shape({
    program: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  getUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  application: PropTypes.shape({}).isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserProfileResult: PropTypes.shape({}).isRequired,
  resetUpdateUserProfile: PropTypes.func.isRequired,
};

export default PersonalInfo;
