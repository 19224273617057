import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { uniq } from "lodash";
import DisplayStatus from "@views/sites/components/DisplayStatus";

const SiteConnectionPanel = ({ orgAsset }) => {
  const connection = {
    meters: orgAsset?.meters,
    status: orgAsset?.status,
    timeInStatus: orgAsset?.timeInStatus,
  }

  const tableRows = [
    {
      label: "Manufacturer",
      value:
        uniq(
          connection?.meters?.map((meter) => meter.manufacturer)
        )?.join(", ") || "N/A",
    },
    {
      label: "Connection Status",
      value: <DisplayStatus status={connection} />,
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Connection
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

SiteConnectionPanel.propTypes = {
  application: PropTypes.shape({}),
};

export default SiteConnectionPanel;
