import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSiteStats } from "@views/individual/dashboard/actions";
import AvailabilityIcon from "@mui/icons-material/PowerSettingsNew";
import PerformanceIcon from "@mui/icons-material/Poll";
import PowerIcon from "@mui/icons-material/Power";
import { getSiteAvailability } from "@site/actions";
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from "@mui/material";
import { pick } from "lodash";
import { getDisplayStatus } from "views/sites";
import humanizeDuration from "humanize-duration";
import { includes } from "lodash";
import { round } from "@utils/numberHelpers";
import ChartPanel from "views/site/containers/operations/ChartPanel";
import { DateTime, } from "luxon";
import { Tag as NumberIcon, Update as DurationIcon } from "@mui/icons-material";

const ScoreCard = ({
  title,
  children,
  icon,
  loading,
}) => (
  <Card
    sx={{
      height: "100%",
      opacity: 1,
    }}
  >
    <CardHeader
      sx={{ bgcolor: "info.light", color: "common.white" }}
      avatar={icon}
      title={
        <Typography color="common.white" variant="h5" fontWeight="bold">
          {title}
        </Typography>
      }
    />
    <CardContent
      sx={{
        minHeight: 88,
        "&:last-child": {
          pb: 2,
        },
      }}
    >
      {loading ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <CircularProgress sx={{ color: "grey.500" }} size={24} />
        </Box>
      ) : (
        <Box>
          {children}
        </Box>
      )}
    </CardContent>
  </Card>
);

const ViewLCFSOpsSummary = ({ site, isLoadingSite }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSiteStats({ accessToken, siteId: site.id }));
    }
    if (site) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, site]);
  const { stats, status } = useSelector(state => state.getSiteStats);
  const loadingStats = status === 'request';
  const chargingStats = stats?.chargingStats;
  const { year: thisYear } = DateTime.now().toObject();
  const lastYear = thisYear - 1;
  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <ScoreCard
            title="Total Energy"
            icon={<PowerIcon />}
            loading={includes(["init", "request"], isLoadingSite) || loadingStats}
          >
            <Typography gutterBottom variant="h5" sx={{ pl: 5 }}>
              {thisYear} {chargingStats?.totalkWh && chargingStats.totalkWh[thisYear] ? round(chargingStats.totalkWh[thisYear], 0).toLocaleString() : 'Unavailable' }
            </Typography>
            <Typography sx={{ pl: 5 }}>
              {lastYear} {chargingStats?.totalkWh && chargingStats.totalkWh[lastYear] ? round(chargingStats.totalkWh[lastYear], 0).toLocaleString() : 'Unavailable' }
            </Typography>
            <Box display="flex" sx={{ pt: 2 }}>
              <Box flexGrow="1" />
              <Typography variant="body2" color="textSecondary">kWh</Typography>
            </Box>
          </ScoreCard>
        </Grid>
        <Grid item sm={3} xs={12}>
          <ScoreCard
            title="Avg. Session Duration"
            icon={<DurationIcon />}
            loading={includes(["init", "request"], isLoadingSite) || loadingStats}
          >
            <Typography gutterBottom variant="h5" sx={{ pl: 5 }}>
              {thisYear} {chargingStats?.avgSessionDuration && chargingStats.avgSessionDuration[thisYear] ? round(chargingStats.avgSessionDuration[thisYear], 0).toLocaleString() : 'Unavailable' }
            </Typography>
            <Typography sx={{ pl: 5 }}>
              {lastYear} {chargingStats?.avgSessionDuration && chargingStats.avgSessionDuration[lastYear] ? round(chargingStats.avgSessionDuration[lastYear], 0).toLocaleString() : 'Unavailable' }
            </Typography>
            <Box display="flex" sx={{ pt: 2 }}>
              <Box flexGrow="1" />
              <Typography variant="body2" color="textSecondary">minutes</Typography>
            </Box>
          </ScoreCard>
        </Grid>
        <Grid item sm={3} xs={12}>
          <ScoreCard
            title="Avg. Session Energy"
            icon={<PowerIcon />}
            loading={includes(["init", "request"], isLoadingSite) || loadingStats}
          >
              <Typography gutterBottom variant="h5" sx={{ pl: 5 }}>
                {thisYear} {chargingStats?.avgkWh && chargingStats.avgkWh[thisYear] ? round(chargingStats.avgkWh[thisYear], 0).toLocaleString() : 'Unavailable' }
              </Typography>
              <Typography sx={{ pl: 5 }}>
                {lastYear} {chargingStats?.avgkWh && chargingStats.avgkWh[lastYear] ? round(chargingStats.avgkWh[lastYear], 0).toLocaleString() : 'Unavailable' }
              </Typography>
              <Box display="flex" sx={{ pt: 2 }}>
                <Box flexGrow="1" />
                <Typography variant="body2" color="textSecondary">kWh</Typography>
              </Box>
          </ScoreCard>
        </Grid>
        <Grid item sm={3} xs={12}>
          <ScoreCard
            title="Number of Sessions"
            icon={<NumberIcon />}
            loading={includes(["init", "request"], isLoadingSite) || loadingStats}
          >
            <Typography gutterBottom variant="h5" sx={{ pl: 5 }}>
              {thisYear} {chargingStats?.sessionCount && chargingStats.sessionCount[thisYear] ? round(chargingStats.sessionCount[thisYear], 0).toLocaleString() : 'Unavailable' }
            </Typography>
            <Typography sx={{ pl: 5 }}>
              {lastYear} {chargingStats?.sessionCount && chargingStats.sessionCount[lastYear] ? round(chargingStats.sessionCount[lastYear], 0).toLocaleString() : 'Unavailable' }
            </Typography>
          </ScoreCard>
        </Grid>
      </Grid>
    </>
  );
}

const ViewSolarOpsSummary = ({ site, isLoadingSite }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSiteStats({ accessToken, siteId: site.id }));
      dispatch(getSiteAvailability({ accessToken, siteId: site.id }));
    }
    if (site) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, site]);
  const { stats, status: getStatsStatus } = useSelector(state => state.getSiteStats);
  const { availability, status: getAvailabilityStatus } = useSelector(state => state.getSiteAvailability);

  const siteStatus = pick(site, ["status", "timeInStatus"]);
  // for the color of the status scorecard
  const displayStatus = getDisplayStatus(siteStatus?.status);

  return (
    <Grid container spacing={3}>
      <Grid item sm={4} xs={12}>
        <ScoreCard
          title="Status"
          primary={displayStatus.statusName}
          secondary={
            siteStatus.timeInStatus
              ? humanizeDuration(siteStatus.timeInStatus * 60 * 60 * 1000, {
                  units: ["d", "h"],
                })
              : "N/A"
          }
          icon={<PowerIcon />}
          color="blue"
          loading={includes(["init", "request"], isLoadingSite)}
          sx={{
            "& .primary": {
              color: displayStatus.color,
            },
          }}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <ScoreCard
          title="Specific Yield, 12 Months"
          primary={round(stats?.specificYield).toLocaleString()}
          secondary={"kWh/kWp"}
          icon={<PerformanceIcon />}
          color="green"
          loading={includes(["init", "request"], getStatsStatus)}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <ScoreCard
          title="Availability, 12 Months"
          primary={
            availability.last12Months
              ? `${round(availability.last12Months).toLocaleString()}%`
              : "N/A"
          }
          secondary={
            availability.prev12Months
              ? `${round(availability.prev12Months).toLocaleString()}%`
              : "N/A"
          }
          icon={<AvailabilityIcon />}
          color="red"
          loading={includes(["init", "request"], getAvailabilityStatus)}
        />
      </Grid>
      <Grid item xs={12}>
        <ChartPanel />
      </Grid>
    </Grid>
  );
};

const ViewOpsSummary = ({
  customerProject,
  site,
  isLoading
}) => {
  switch (customerProject?.emissionReductionActivity) {
  case 'LOW_CARBON_FUEL':
    return <ViewLCFSOpsSummary site={site} isLoading={isLoading} />
  case 'SOLAR_ELECTRICITY_POWER_GENERATION':
    return <ViewSolarOpsSummary site={site} isLoading={isLoading} />
  default:
    return null;
  }
};

export default ViewOpsSummary;
