import { call, put, takeLatest, select } from "redux-saga/effects";
import { findSystems } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { isNil, omit, get, map, pick, split, trim } from "lodash";
import { extractFroniusSystemId } from "./testDataConnectorConnection";
import md5 from "md5";
import { getOrganization } from "@views/common/selectors";
import { getUserProfile } from "@views/common/selectors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async (
  accessToken,
  organizationId,
  programId,
  customerProjectId,
  dataConnectorParams,
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/find`,
    {
      organizationId,
      programId: programId || undefined,
      customerProjectId: customerProjectId || undefined,
      ...dataConnectorParams,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const transform = (credentials) => ({
  ...omit(credentials, "froniusAccessUrl"),
  pvSystemIds: [extractFroniusSystemId(get(credentials, "froniusAccessUrl"))],
  chargerIds: map(split(credentials?.chargerIds, ","), chargerId => trim(chargerId)),
});

// some assets are brand new from the manufacturer and have no surge id
const assignIds = (foundAssets) =>
  map(foundAssets, (foundAsset) => ({
    ...foundAsset,
    id:
      foundAsset.id ||
      md5(
        JSON.stringify(
          foundAsset.meters.map((m) =>
            pick(m, [
              "commissioningDate",
              "name",
              "manufacturer",
              "model",
              "serialNumber",
              "type",
              "dataConnector.siteId",
            ])
          )
        )
      ),
    isNew: !!!foundAsset.id
  }));

export function* findSystemsWorker(action) {
  yield put(findSystems.request());

  const { accessToken, dataConnectorParams } = action.payload;
  const organization = yield select(getOrganization);

  // we may only have org in the user profile (because we cleared it out),
  // or in the payload (from apply, before an org is created)
  const userProfile = yield select(getUserProfile);
  const organizationId =
    action.payload.organizationId ||
    organization?.id ||
    userProfile.organizationId;
  const programId = action.payload.programId;
  const customerProjectId = action.payload.customerProjectId;
  try {
    const response = yield call(
      callApi,
      accessToken,
      organizationId,
      programId,
      customerProjectId,
      transform(dataConnectorParams),
    );
    if (!isNil(response.data.assets)) {
      const systems = assignIds(response.data.assets);
      yield put(findSystems.success({ systems }));
    } else {
      const message =
        response?.data?.reason ||
        response?.data?.message ||
        "Failed to find sites.";
      yield put(findSystems.failure({ error: message }));
      yield put(showAppMessage({ severity: "error", message }));
    }
  } catch (err) {
    // n.b. custom error handling due to potential validation errors
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error finding systems: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error finding systems: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error finding systems: ", err);
      message = err.message;
    }
    message = `Error finding systems: ${message}`;
    yield put(findSystems.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* findSystemsWatcher() {
  yield takeLatest(findSystems.TRIGGER, findSystemsWorker);
}

export default [findSystemsWatcher];
