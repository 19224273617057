import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, includes } from "lodash";

const ROLES_CLAIM = "https://rewattpower.com/roles";

const MarketplaceGuard = ({ children }) => {
  const navigate = useNavigate();
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const checkIsBuyer = async () => {
      const claims = await getIdTokenClaims();
      const roles = get(claims, ROLES_CLAIM);
      const isBuyer = includes(roles, "Buyer");
      if (!isBuyer) {
        navigate("/404", { replace: true });
        return null;
      }
    };
    checkIsBuyer();
  }, [getIdTokenClaims, navigate]);

  return children;
};

MarketplaceGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MarketplaceGuard;
