const typography = (primaryColor) => ({
  htmlFontSize: 16, // for rem
  fontSize: 14,
  fontFamily: '"Karla", "Helvetica", "Arial", sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  allVariants: {
    color: primaryColor,
  },
  h1: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    fontSize: "2.75rem",
    letterSpacing: "normal",
    lineHeight: 1.227,
  },
  h2: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    fontSize: "2.375rem",
    letterSpacing: "normal",
    lineHeight: 1.158,
  },
  h3: {
    fontWeight: 400,
    fontSize: "1.875rem",
    letterSpacing: "normal",
  },
  h4: {
    fontWeight: 400,
    fontSize: "1.5rem",
    letterSpacing: "normal",
  },
  h5: {
    fontWeight: 400,
    fontSize: "1.125rem",
    letterSpacing: "normal",
  },
  h6: {
    fontWeight: 700,
    fontSize: "1rem",
    letterSpacing: "normal",
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: "1.25rem",
    letterSpacing: "normal",
    lineHeight: 1.5,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "1rem",
    letterSpacing: "normal",
    lineHeight: 1.875,
  },
  body1: {
    fontWeight: 400,
    fontSize: "1rem",
    letterSpacing: "normal",
    lineHeight: 1.25,
  },
  body2: {
    fontWeight: 500,
    fontSize: "0.875rem",
    letterSpacing: "normal",
    lineHeight: 1.25,
  },
  button: {
    fontWeight: 700,
    fontSize: "1.0625rem",
    lineHeight: 1.75,
    letterSpacing: "normal",
    textTransform: "uppercase",
  },
  caption: {
    fontWeight: 400,
    fontSize: "1rem",
    letterSpacing: "normal",
  },
  overline: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 2.66,
    textTransform: "uppercase",
  },
});

export default typography;
