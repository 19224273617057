import { call, put, takeLatest } from "redux-saga/effects";
import { createProjectReport } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async (accessToken, projectId, projectReport) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/projectReports`,
    projectReport,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const transform = (projectReport) => ({
  reportingSheetTemplateUri: projectReport.aggregatedReportingSheetTemplate?.uri,
  startDate: projectReport.startDate.toISODate(),
  endDate: projectReport.endDate.toISODate(),
  credits: projectReport.credits,
});

function* createProjectReportWorker(action) {
  yield put(createProjectReport.request());

  const { accessToken, projectReport, projectId } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      projectId,
      transform(projectReport)
    );
    yield put(
      createProjectReport.success({ projectReport: response.data, accessToken, projectId })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error creating project report: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = (
          <ValidationErrors validationErrors={validationErrors} />
        );
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error creating project report: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error creating project report: ", err);
      message = err.message;
    }
    message = `Unable to create project report: ${message}`;
    yield put(createProjectReport.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* createProjectReportWatcher() {
  yield takeLatest(createProjectReport.TRIGGER, createProjectReportWorker);
}

export default [createProjectReportWatcher];
