import React from "react";
import PropTypes from "prop-types";
import { Typography, Box, Link } from "@mui/material";
import { get } from "lodash";

const PORTAL_URLS = {
  HUAWEI: "https://locusnoc.datareadings.com/",
  REFUSOL: "https://refu-log.com/",
  HOYMILES: "https://global.hoymiles.com/platform/login"
};

const UsernamePasswordInstructionPanel = ({ manufacturer }) => {
  const portalUrl = get(PORTAL_URLS, manufacturer);
  return (
    <Box mt={4} mb={2} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Please enter your username and password for the {manufacturer} portal.
      </Typography>
      <Typography>
        <Link href={portalUrl} target="_new">
          {portalUrl}
        </Link>
      </Typography>
    </Box>
  );
};

UsernamePasswordInstructionPanel.propTypes = {
  manufacturer: PropTypes.string.isRequired,
};

export default UsernamePasswordInstructionPanel;
