/**
 * Helpers for energy and power.
 */

import { formatPower } from "./stringHelpers";
import { getDefaultLocale } from "./dateHelpers";

const minutesInHour = 60;

/**
 * Convert an energy value in units of Watt-minutes to Megawatt-hours
 *
 * @param {number} wm the energy in watt-minutes
 *
 * @return the energy in megawatt-hours
 */
const wmToMWh = (wm) => wm / minutesInHour / 1000 / 1000;

/**
 * Convert an energy value in units of Watt-minutes to Kilowatt-hours
 *
 * @param {number} wm the energy in watt-minutes
 *
 * @return the energy in kilowatt-hours
 */
const wmTokWh = (wm) => wm / minutesInHour / 1000;

/**
 * Formats power in kW, in default locale.
 *
 * @param  {int} capacity in watts
 *
 * @return {string} the formatted capacity
 */
const formatCapacity = (capacity) =>
  (capacity / 1000).toLocaleString(getDefaultLocale(), {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

export { wmTokWh, wmToMWh, formatPower, formatCapacity };
