// this is a common uploadFiles saga, just add your action and a watcher (and your own reducer)
import { call, put, takeLatest, all } from "redux-saga/effects";
import { uploadCorporateLogo, uploadVerifierLogo } from "@admin/projectReport/actions";
import { uploadTerms, uploadProgramLogo } from "@views/programs/actions";
import { uploadReportingSheetTemplate, uploadProjectFiles } from "@views/admin/project/actions";
import { uploadCustomerProjectTerms, uploadCustomerProjectLogo } from "@views/customerProjects/CustomerProjects/actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { some, has, join, map, values } from "lodash";

const callApi = async (accessToken, filespec) => {
  const body = new FormData();
  body.append("file", filespec.file);
  body.append("isPublic", filespec.public === true);

  const response = await axios.post(`${process.env.REACT_APP_SURGE_API}/api/v2/files`, body, {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  });
  return response;
};

function* uploadFilesWorker(action, uploadFiles) {
  const { accessToken, filespecs, discriminator } = action.payload;

  yield put(uploadFiles.request({ discriminator }));

  const callEffects = filespecs.map((filespec) => {
    return call(function* () {
      try {
        const response = yield call(callApi, accessToken, filespec);
        const { uri } = response.data;
        return { uri, ...filespec };
      } catch (err) {
        let message;
        if (err.response) {
          // client received an error response (5xx, 4xx)
          logger("weedle:error")("Error uploading files: ", err.response);
          if (err.response.data?.validationErrors) {
            message = join(values(err.response.data.validationErrors), "; ");
          } else {
            message = err.response.data.reason || err.response.data.message;
          }
        } else if (err.request) {
          // client never received a response, or request never left
          logger("weedle:error")("Error uploading files: ", err.request);
          message = err.message;
        } else {
          // anything else
          logger("weedle:error")("Error uploading files: ", err);
          message = err.message;
        }
        message = `Unable to upload files: ${message}`;
        return { error: message };
      }
    });
  });

  const uploadedFilespecs = yield all(callEffects);

  // fail the whole thing if we get any errors, no biggie
  if (some(uploadedFilespecs, (f) => has(f, "error"))) {
    const errors = map(uploadedFilespecs, (f) => f.error);
    const message = join(errors, "; ");
    yield put(uploadFiles.failure({ errors, discriminator }));
    yield put(showAppMessage({ severity: "error", message }));
  } else {
    yield put(uploadFiles.success({ filespecs: uploadedFilespecs, discriminator }));
  }
}

function* uploadTermsWorker(action) {
  yield* uploadFilesWorker(action, uploadTerms);
}

function* uploadTermsWatcher() {
  yield takeLatest(uploadTerms.TRIGGER, uploadTermsWorker);
}

function* uploadProgramLogoWorker(action) {
  yield* uploadFilesWorker(action, uploadProgramLogo);
}

function* uploadProgramLogoWatcher() {
  yield takeLatest(uploadProgramLogo.TRIGGER, uploadProgramLogoWorker);
}

function* uploadCustomerProjectTermsWorker(action) {
  yield* uploadFilesWorker(action, uploadCustomerProjectTerms);
}

function* uploadCustomerProjectTermsWatcher() {
  yield takeLatest(uploadCustomerProjectTerms.TRIGGER, uploadCustomerProjectTermsWorker);
}

function* uploadCustomerProjectLogoWorker(action) {
  yield* uploadFilesWorker(action, uploadCustomerProjectLogo);
}

function* uploadCustomerProjectLogoWatcher() {
  yield takeLatest(uploadCustomerProjectLogo.TRIGGER, uploadCustomerProjectLogoWorker);
}

function* uploadVerifierLogoWorker(action) {
  yield* uploadFilesWorker(action, uploadVerifierLogo);
}

function* uploadVerifierLogoWatcher() {
  yield takeLatest(uploadVerifierLogo.TRIGGER, uploadVerifierLogoWorker);
}

function* uploadCorporateLogoWorker(action) {
  yield* uploadFilesWorker(action, uploadCorporateLogo);
}

function* uploadCorporateLogoWatcher() {
  yield takeLatest(uploadCorporateLogo.TRIGGER, uploadCorporateLogoWorker);
}

function* uploadReportingSheetTemplateWorker(action) {
  yield* uploadFilesWorker(action, uploadReportingSheetTemplate);
}

function* uploadReportingSheetTemplateWatcher() {
  yield takeLatest(uploadReportingSheetTemplate.TRIGGER, uploadReportingSheetTemplateWorker);
}

function* uploadProjectFilesWorker(action) {
  yield* uploadFilesWorker(action, uploadProjectFiles);
}

function* uploadProjectFilesWatcher() {
  yield takeLatest(uploadProjectFiles.TRIGGER, uploadProjectFilesWorker);
}

export default [
  uploadProgramLogoWatcher,
  uploadTermsWatcher,
  uploadCustomerProjectLogoWatcher,
  uploadCustomerProjectTermsWatcher,
  uploadReportingSheetTemplateWatcher,
  uploadProjectFilesWatcher,
  uploadVerifierLogoWatcher,
  uploadCorporateLogoWatcher,
];
