import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Toolbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@components/PanelDisplayTable";
import { getFullName } from "@utils/stringHelpers";
import InstallerEditor from "./InstallerEditor";
import { PhoneLink, EmailLink } from "@components";

const InstallerPanel = ({ application }) => {
  // put installer in component state
  const [installer, setInstaller] = useState(application?.siteInfo?.installer);

  const tableRows = [
    {
      label: "Name",
      value: getFullName(installer),
    },
    {
      label: "Email",
      value: <EmailLink email={installer?.email} />
    },
    {
      label: "Phone",
      value: <PhoneLink phone={installer?.phone} />
    },
  ];


  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleEdit = () => {
    setIsEditorOpen(true);
  };
  const handleClose = () => {
    setIsEditorOpen(false);
  };

  const handleSave = (installer) => {
    setInstaller(installer);
  };

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Contractor
          </Typography>
          <Toolbar sx={{ mt: -2 }}>
            <Tooltip title="Edit contractor/installer information">
              <span>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="edit contractor"
                  onClick={handleEdit}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
        </Box>
        <TableContainer>
          <Table aria-label="contractor info">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <InstallerEditor 
          open={isEditorOpen}
          onClose={handleClose}
          onSave={handleSave}
          installer={installer}
        />
      </Paper>
    </>
  );
};

InstallerPanel.propTypes = {
  application: PropTypes.shape({}),
};

export default InstallerPanel;
