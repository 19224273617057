import { call, put, takeLatest } from "redux-saga/effects";
import { getCustomerProjectCreditSummary } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, customerProject) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProject.id}/creditSummary`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getCustomerProjectCreditSummaryWorker(action) {
    yield put(getCustomerProjectCreditSummary.request());

    const { accessToken, customerProject } = action.payload;

  try {
    const response = yield call(callApi, accessToken, customerProject)
    const summary = response.data;
    yield put(getCustomerProjectCreditSummary.success({ summary }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get customer project: ${message}`;
    yield put(getCustomerProjectCreditSummary.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getCustomerProjectCreditSummaryWatcher() {
  yield takeLatest(getCustomerProjectCreditSummary.TRIGGER, getCustomerProjectCreditSummaryWorker);
}

export default [getCustomerProjectCreditSummaryWatcher];
