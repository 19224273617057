import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { get } from "lodash";
import { ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import { Controller, useFormContext } from "react-hook-form";
import { isNil } from "lodash";

const TableHeader = styled(TableCell)(() => ({
  fontWeight: "bold",
}));

const PricingPanel = ({ projectReport }) => {
  const { errors, control, watch } = useFormContext();
  const pricePerCredit = watch('pricePerCredit', null);
  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            Specify a price per credit you want to offer and click Finalize Purchase Request to submit your request to purchase this project report's credits. The table below will automatically calculate your total amount due after specifying a price per credit.
          </Typography>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Price per credit"
                variant="standard"
                type="number"
                step={1}
                fullWidth
                error={!isNil(errors.pricePerCredit?.message)}
                helperText={errors.pricePerCredit?.message}
                required
                onChange={onChange}
                inputProps={{
                  autoComplete: "new-password", // disable browser auto-complete
                }}
                value={value}
                color="secondary"
                placeholder="60"
                sx={{ mt: 1, maxWidth: 300 }}
              />
            )}
            control={control}
            name="pricePerCredit"
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableHeader>Year</TableHeader>
                  <TableHeader>Credit</TableHeader>
                  <TableHeader align="right">Quantity</TableHeader>
                  <TableHeader align="right">Price</TableHeader>
                  <TableHeader align="right">Total</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectReport.vintages.map((row) => (
                  <TableRow key={row.year}>
                    <TableCell>{row.year}</TableCell>
                    <TableCell>
                      {get(
                        ENVIRONMENTAL_CREDIT_TYPES,
                        projectReport.project.environmentalCredit,
                        ""
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.credits.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {pricePerCredit.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </TableCell>
                    <TableCell align="right">
                      {(pricePerCredit * row.credits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ "td, th": { border: 0, pt: 2 } }}>
                  <TableCell align="right" colSpan={4}>
                    Subtotal
                  </TableCell>
                  <TableHeader align="right">
                    {(pricePerCredit * projectReport.totalEnvironmentalCredits)
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </TableHeader>
                </TableRow>
                <TableRow sx={{ "td, th": { border: 0 } }}>
                  <TableCell align="right" colSpan={4}>
                    GST (5%)
                  </TableCell>
                  <TableHeader align="right">
                    {(
                      pricePerCredit *
                      projectReport.totalEnvironmentalCredits *
                      0.05
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableHeader>
                </TableRow>
                <TableRow sx={{ "td, th": { border: 0 } }}>
                  <TableHeader align="right" colSpan={4}>
                    Amount Due
                  </TableHeader>
                  <TableHeader align="right">
                    {(
                      pricePerCredit *
                      projectReport.totalEnvironmentalCredits *
                      1.05
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableHeader>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PricingPanel;
