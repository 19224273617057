import React from "react";
import { styled } from "@mui/material/styles";
import acrobatImg from "@assets/adobeacrobatreader.png";

const AcrobatLogo = styled(() => (
  <img
    width={16}
    src={acrobatImg}
    alt="Adobe Acrobat Document"
    style={{
      border: "1px solid rgba(255, 0, 0, 0.5)",
      padding: 2,
      marginRight: 12,
      verticalAlign: "text-top",
    }}
  />
))({});

export default AcrobatLogo;
