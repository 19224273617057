import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Callback from "../components/Callback";
import { getUserProfile } from "@views/common/actions";

const mapStateToProps = ({ getUserProfile }) => ({
  getUserProfileResult: getUserProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
