import { call, put, takeLatest } from "redux-saga/effects";
import { createCertificate } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, projectReportId, certificate) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/marketplace/projectReports/${projectReportId}/certificate`,
    certificate,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* createCertificateWorker(action) {
  yield put(createCertificate.request());

  const { certificate, projectReportId, accessToken } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      projectReportId,
      certificate,
    );
    yield put(createCertificate.success({ certificate: response.data }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error creating certificate for project report: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error creating certificate for project report: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error creating certificate for project report: ", err);
      message = err.message;
    }
    message = `Unable to creating certificate: ${message}`;
    yield put(createCertificate.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* createCertificateWatcher() {
  yield takeLatest(createCertificate.TRIGGER, createCertificateWorker);
}

export default [createCertificateWatcher];
