import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
});

export const ContentContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

export const Content = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const GenericLayout = () => (
  <>
    <TopBar />
    <Wrapper>
      <ContentContainer>
        <Content>
          <Outlet />
        </Content>
      </ContentContainer>
    </Wrapper>
  </>
);


export default GenericLayout;
