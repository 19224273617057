import { call, put, takeLatest } from "redux-saga/effects";
import { getLiveSite } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (siteId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/public/assets/${siteId}`
  );
  return response;
};

function* getLiveSiteWorker(action) {
  yield put(getLiveSite.request());

  const { siteId } = action.payload;

  try {
    const response = yield call(callApi, siteId);
    const site = response.data;
    yield put(getLiveSite.success({ site }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting live site details: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting live site details: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting live site details: ", err);
      message = err.message;
    }
    message = `Unable to get live site details: ${message}`;
    yield put(getLiveSite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getLiveSiteWatcher() {
  yield takeLatest(getLiveSite.TRIGGER, getLiveSiteWorker);
}

export default [getLiveSiteWatcher];
