import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const acceptPayeeInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ACCEPT_PAYEE_INVITE"
);
const verifyPayeeInviteToken = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "VERIFY_PAYEE_INVITE_TOKEN"
);

export { verifyPayeeInviteToken, acceptPayeeInvite };
