import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tooltip,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import CustomDataGrid from "components/CustomDataGrid";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import { PURCHASE_REQUEST_STATUSES } from "./PurchaseRequest";
import { downloadFile } from "utils/fileHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { renderCellExpand } from "components/GridCellExpand";

const PurchaseRequestStatusHistory = ({ statusHistory }) => {
  const [showNote, setShowNote] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(10);
  const closeShowNote = () => {
    setShowNote(null);
  };
  const { getAccessTokenSilently } = useAuth0();
  const handleDownloadDocument = async (document) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace",
    });
    downloadFile(document, accessToken);
  };
  const columns = [
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      valueGetter: ({ value }) => PURCHASE_REQUEST_STATUSES[value].display,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Date",
      field: "date",
      flex: 1,
      valueFormatter: ({ value }) =>
        DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
    },
    {
      headerName: "Changed By",
      field: "changedBy",
      flex: 0.7,
      renderCell: renderCellExpand,
      valueGetter: ({ value }) => `${value.givenName} ${value.familyName}`,
    },
    {
      headerName: "Documents",
      field: "documents",
      flex: 1.2,
      renderCell: ({ value: documents }) =>
        documents.map((doc) => (
          <Button key={doc.id} variant="text" onClick={() => handleDownloadDocument(doc)} mx={1}>
            {doc.name}
          </Button>
        )),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (param) => (
        <Tooltip title={param.row.notes}>
          <Visibility color={param.row.notes ? "primary" : "disabled"} />
        </Tooltip>
      ),
    },
  ];

  return (
    <Paper>
      <Dialog open={!!showNote} onClose={closeShowNote}>
        <DialogTitle>
          {showNote ? `Notes for ${PURCHASE_REQUEST_STATUSES[showNote.status].display}` : "Notes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{showNote?.notes ?? ""}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeShowNote}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box height={400}>
        <CustomDataGrid
          style={{ border: 0 }}
          rowHeight={50}
          rows={statusHistory}
          columns={columns}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Box>
    </Paper>
  );
};

export default PurchaseRequestStatusHistory;
