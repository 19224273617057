import { call, put, takeLatest } from "redux-saga/effects";
import { getSiteIncidents } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, siteId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/${siteId}/incidents`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export function* getSiteIncidentsWorker(action) {
  yield put(getSiteIncidents.request());

  const { accessToken, siteId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, siteId);
    const incidents = response.data;
    yield put(getSiteIncidents.success({ incidents }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting site incidents: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting site incidents: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting site incidents: ", err);
      message = err.message;
    }
    message = `Unable to get site incidents: ${message}`;
    yield put(getSiteIncidents.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getSiteIncidentsWatcher() {
  yield takeLatest(getSiteIncidents.TRIGGER, getSiteIncidentsWorker);
}

export default [getSiteIncidentsWatcher];
