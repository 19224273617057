import React from "react";
import { getLiveChartData } from "@views/liveSite/actions";
import { useDispatch } from "react-redux";
import ChartPanel from "@views/common/components/ChartPanel";

const LiveChartPanel = ({ siteId }) => {
  const dispatch = useDispatch();

  const fetchChartData = React.useCallback(
    ({ startDate, endDate }) => {
      dispatch(
        getLiveChartData({
          siteId,
          startDate,
          endDate,
        })
      );
    },
    [dispatch, siteId]
  );

  return (
    <ChartPanel
      fetchChartData={fetchChartData}
      resetChartData={() => dispatch(getLiveChartData.reset())}
      fetchSelector={(state) => state.getLiveChartData}
      defaultTabName="custom"
      defaultGranularity="day"
    />
  );
};

export default LiveChartPanel;
