import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { acceptAssetClaimInvite, verifyAssetClaimInviteToken } from "../actions";
import { useAppMessage } from "@components/appMessage";
import { Typography, Container, Box, Paper } from "@mui/material";
import Page from "@components/Page";
import { Navigation } from "@components";
import { getUserProfile } from "@views/common/actions";
import { pick } from "lodash";

const AcceptInvite = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { invite } = useSelector((state) => state.verifyAssetClaimInviteToken);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const acceptInvite = async () => {
    setIsSubmitting(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    // nb. we never collected an address for this user profile
    dispatch(
      acceptAssetClaimInvite({
        accessToken,
        inviteId: invite.id,
        acceptedTermsAndConditions: userProfile.acceptedTermsAndConditions,
      })
    );
  };
  const { status: acceptAssetClaimInviteStatus, userProfile: updatedUserProfile } = useSelector(
    (state) => state.acceptAssetClaimInvite
  );

  // invite accepted
  useEffect(() => {
    const finish = async () => {
      // get a new token, so it has the new roles
      await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        ignoreCache: true,
      });
      setIsSubmitting(false);

      // update userProfile
      dispatch(
        getUserProfile.update({
          userProfile: { ...pick(updatedUserProfile, ["assetId", "isIndividualOwner"]) },
        })
      );

      dispatch(verifyAssetClaimInviteToken.reset());
      dispatch(acceptAssetClaimInvite.reset());

      navigate("/individual/dashboard");
    };
    if (acceptAssetClaimInviteStatus === "success") {
      finish();
    }
  }, [
    showAppMessage,
    acceptAssetClaimInviteStatus,
    dispatch,
    navigate,
    getAccessTokenSilently,
    updatedUserProfile,
  ]);

  return (
    <Page title="Accept Invite" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              Site Claim
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Create your account
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <Typography gutterBottom>You're all set!</Typography>
            <Typography>
              Press continue to accept your invitation and go to your dashboard.
            </Typography>
            <Navigation
              nextAction={acceptInvite}
              nextActionText="Continue"
              isSubmitting={isSubmitting}
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default AcceptInvite;
