import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { isNil, get } from "lodash";
import { Controller } from "react-hook-form";

const AddressTextField = ({
  label,
  placeholder,
  errors,
  readOnly,
  control,
  name,
  helperText,
  onChange: handleChange,
  required = true,
}) => (
  <Controller
    render={({ onChange, value, name, ref }) => (
      <TextField
        name={name}
        variant="standard"
        label={label}
        fullWidth
        placeholder={placeholder}
        error={!isNil(get(errors, name)?.message)}
        helperText={get(errors, name)?.message || helperText}
        inputProps={{
          readOnly,
        }}
        onChange={(e) => {
          onChange(e);
          handleChange(e);
        }}
        inputRef={ref}
        value={value}
        color="secondary"
        required={required}
      />
    )}
    control={control}
    name={name}
    defaultValue=""
  />
);

AddressTextField.defaultProps = {
  readOnly: false,
  onChange: () => {},
};

AddressTextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errors: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default AddressTextField;
