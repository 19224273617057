import React from "react";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const StyledList = styled(List)`
  & .MuiListSubheader-root {
    line-height: 1rem;
  }
  ,
  & .MuiListItem-root {
    padding-bottom: 0;
  }
  ,
  & .MuiListItemText-root {
    margin: 0;
  }
`;

const Built = () => (
  <>
    <Typography variant="h5" mb={2}>
      Please provide four types of documents to support your application. Upload
      one or more of each and tag them with their type. If you don't have these documents now, you
      can add them later.
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StyledList
          dense
          subheader={
            <ListSubheader>
              Proof of Site Equipment Description and Ownership (provide one or
              more):
            </ListSubheader>
          }
        >
          <ListItem dense>
            <ListItemText primary="- Equipment purchase invoice, including detailed equipment list" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Equipment lease agreement (if leasing)" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Equipment list" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Single line diagram (optional)" />
          </ListItem>
        </StyledList>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledList
          dense
          subheader={
            <ListSubheader>
              Proof of Installation (optional, but recommended):
            </ListSubheader>
          }
        >
          <ListItem dense>
            <ListItemText primary="- Photo(s) of inverter and its serial number" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Photo(s) of measurement device and its serial number (if applicable)" />
          </ListItem>
        </StyledList>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledList
          dense
          subheader={
            <ListSubheader>
              Proof of Connection to Grid (provide one or more):
            </ListSubheader>
          }
        >
          <ListItem dense>
            <ListItemText primary="- Microgeneration, interconnection or operating agreement" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- System commissioning report" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Final electrical inspection report" />
          </ListItem>
        </StyledList>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledList
          dense
          subheader={
            <ListSubheader>
              Proof of Enviromental Credits Ownership (if applicable):
            </ListSubheader>
          }
        >
          <ListItem dense>
            <ListItemText primary="- Grant funding agreement (if site has received government assistance)" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Equipment lease agreement (if leasing)" />
          </ListItem>
        </StyledList>
      </Grid>
    </Grid>
  </>
);

const Unbuilt = () => (
  <>
    <Typography variant="h5" mb={2}>
      For sites that are not built, we request documents that describe the proposed site location, system size and ownership.
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledList
          dense
          subheader={
            <ListSubheader>
              Please upload one or more of the following types of documents, and then tag with their type:
            </ListSubheader>
          }
        >
          <ListItem dense>
            <ListItemText primary="- Quotation for system" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Application for micro-generation, interconnection or operating agreement" />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="- Lease agreement (if applicable)" />
          </ListItem>
        </StyledList>
      </Grid>
    </Grid>
  </>
);

const DocumentsInfo = ({ children }) => {
  const { isBuilt } = useSelector((state) => state.application.siteInfo);

  return (
    <>
      <Box mb={2}>
        {isBuilt ? <Built /> : <Unbuilt />}
      </Box>
      {children}
    </>
  );
};

export default DocumentsInfo;
