import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/**
 * Map legacy programs to their customer project replacements:
 * 23 => 30 is Rewatt's Utility NET program
 * 24 => 9 is EQUS' program
 * 3 => 11 is Yukon's program 
 */
const legacyProgramToCustomerProjectMap = {
  23: 30,
  24: 9,
  3: 11,
};

const Welcome = () => {
  const { programId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const id = legacyProgramToCustomerProjectMap[programId] ?? programId;
    navigate(`/projects/apply/${id}${search}`);
  }, [navigate, programId, search]);

  return null;
};

export default Welcome;
