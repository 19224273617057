import { getOrganization } from "../actions";

export const initialState = {
  status: "init",
  organization: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getOrganization.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getOrganization.SUCCESS: {
      const { organization } = action.payload;
      return {
        ...state,
        status: "success",
        organization,
      };
    }

    case getOrganization.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    // special case after calling an update
    case getOrganization.UPDATE: {
      return {
        ...state,
        status: "success",
        organization: {
          ...state.organization,
          ...action.payload.organization,
        },
      };
    }    

    default: {
      return state;
    }
  }
};

export default reducer;
