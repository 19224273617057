import React from "react";
import { Typography, Grid, TextField, Link } from "@mui/material";
import * as Yup from "yup";
import { isNumeric } from "@utils/numberHelpers";
import { useFormContext, Controller } from "react-hook-form";
import { isNil } from "lodash";

export const bankInfoSchema = Yup.object().shape({
  institutionNumber: Yup.string()
    .length(3, "Institution number must be 3 digits")
    .test("numeric", "Only digits are acceptable", isNumeric)
    .required("Institution number is required."),
  transitNumber: Yup.string()
    .length(5, "Transit (branch) number must be 5 digits")
    .test("numeric", "Only digits are acceptable", isNumeric)
    .required("Transit (branch) number is required."),
  accountNumber: Yup.string()
    .min(7, "Account number must be 7-12 digits")
    .max(12, "Account number must be 7-12 digits")
    .test("numeric", "Only digits are acceptable", isNumeric)
    .required("Account number is required."),
});

const BankInformationForm = () => {
  const { errors, control } = useFormContext();

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography mt={4} gutterBottom>
            Please enter your bank details. Proceeds from the sale of credits will be deposited in
            this account.
          </Typography>
          <Typography>
            These numbers can be found on a cheque, from your online banking account, or at your
            bank.{" "}
            <Link href="https://wise.com/ca/bank-codes/transit-number" target="_new">
              Read more here.
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Institution Number"
                variant="standard"
                fullWidth
                error={!isNil(errors.institutionNumber?.message)}
                helperText={
                  errors.institutionNumber?.message || "3 digit number representing your bank."
                }
                required
                onChange={onChange}
                value={value}
                color="secondary"
                inputProps={{
                  maxLength: 3,
                  inputMode: "numeric",
                  pattern: "\\d*",
                  autoComplete: "new-password",
                }}
              />
            )}
            control={control}
            name="institutionNumber"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Transit Number"
                variant="standard"
                fullWidth
                error={!isNil(errors.transitNumber?.message)}
                helperText={
                  errors.transitNumber?.message || "5 digit number representing your branch."
                }
                required
                onChange={onChange}
                value={value}
                color="secondary"
                inputProps={{
                  maxLength: 5,
                  inputMode: "numeric",
                  pattern: "\\d*",
                  autoComplete: "new-password",
                }}
              />
            )}
            control={control}
            name="transitNumber"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Account Number"
                variant="standard"
                fullWidth
                error={!isNil(errors.accountNumber?.message)}
                helperText={
                  errors.accountNumber?.message || "7-12 digit number representing your account."
                }
                required
                onChange={onChange}
                value={value}
                color="secondary"
                inputProps={{
                  maxLength: 12,
                  inputMode: "numeric",
                  pattern: "\\d*",
                  autoComplete: "new-password",
                }}
              />
            )}
            control={control}
            name="accountNumber"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BankInformationForm;
