import { call, put, takeLatest, select } from "redux-saga/effects";
import { getChartData } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getUserProfile } from "@views/common/selectors";
import { fillData } from "@utils/chartHelpers";
import { wmTokWh } from "@utils/energyHelpers";

const callApi = async (accessToken, organizationId, startDate, endDate) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/chart`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        start: startDate.toISODate(),
        end: endDate.toISODate(),
      },
    }
  );
  return response;
};

// nb in org context, a chart does not have a timezone in its dates
// export for testing
export const transform = (data, granularity, dateRange) => {
  return [
    {
      stat: "generation",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date, // yyyy-MM-dd HH:mm:ss
          y: datum.generation ? wmTokWh(datum.generation) : null,
        })),
        granularity,
        dateRange
      }),
    },
    {
      stat: "export",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date,
          y: datum.export ? wmTokWh(datum.export) : null,
        })),
        granularity,
        dateRange
      }),
    },
    {
      stat: "consumption",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date,
          y: datum.consumption ? wmTokWh(datum.consumption) : null,
        })),
        granularity,
        dateRange
      }),
    },
    {
      stat: "emissionReductions",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date,
          y: datum.emissionReductions,
        })),
        granularity,
        dateRange
      }),
    },
  ];
};

export function* getChartDataWorker(action) {
  yield put(getChartData.request());

  const userProfile = yield select(getUserProfile);
  const { accessToken, startDate, endDate } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      userProfile.organizationId,
      startDate,
      endDate
    );
    const { data, granularity } = response.data;
    yield put(getChartData.success({ data: transform(data, granularity, [startDate, endDate]), granularity }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting chart data: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting chart data: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting chart data: ", err);
      message = err.message;
    }
    message = `Unable to get chart data: ${message}`;
    yield put(getChartData.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getChartDataWatcher() {
  yield takeLatest(getChartData.TRIGGER, getChartDataWorker);
}

export default [getChartDataWatcher];
