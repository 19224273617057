import React, { useRef, useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";

const containerStyle = {
  width: "100%",
  height: "641px", // same as left form column height
};

/**
 * Render a google map widget
 * NB google maps api loaded in App.jsx
 *
 * @param {Object} marker a map marker: e.g. set when a user clicks on the map
 * @param {function} setMarker callback when setting the marker (i.e. clicking on the map)
 * @param {bool} readOnly effectively disables clicking on the map
 * @param {Object} mapContainerStyle custom style for the map container (e.g. setting custom width/height)
 * @param {function} onLoad callback when the map has been loaded - takes a Google Maps map object as an argument
 *
 * @returns
 */
const SimpleMap = ({
  marker,
  setMarker,
  readOnly,
  mapContainerStyle = containerStyle,
  onLoad = (map) => null
}) => {
  const mapRef = useRef(null);

  const [center] = useState(marker || { lat: 51.0215399, lng: -114.0448038 });

  const handleClick = (event) => {
    if (!readOnly) {
      setMarker({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    }
  };

  const handleLoad = (map) => {
    mapRef.current = map;
    onLoad(map);
  };

  // move the map when we change the marker (autocompleter, pin drop, cancel changes, initialization)
  useEffect(() => {
    if (marker) {
      mapRef.current?.panTo(marker);
    }
  }, [marker]);

  // make sure we wait for google to be loaded
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (window.google) {
      setLoaded(true);
    }
  }, []);

  return !loaded ? null : (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onLoad={handleLoad}
      center={center}
      zoom={9}
      onClick={handleClick}
    >
      {marker && <Marker position={marker} />}
    </GoogleMap>
  );
};

SimpleMap.defaultProps = {
  readOnly: false,
};

SimpleMap.propTypes = {
  readOnly: PropTypes.bool,
  onLoad: PropTypes.func,
};

export default SimpleMap;
