import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { isNull } from "lodash";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import NextIcon from "@mui/icons-material/ArrowForwardIos";
import LoadingButton from "@mui/lab/LoadingButton";

const Navigation = ({
  backAction,
  nextAction,
  skipAction,
  nextActionText,
  isSubmitting,
}) => (
  <Box
    display="flex"
    flexDirection="row"
    flexWrap="nowrap"
    justifyContent="space-between"
    mt={5}
    pt={2}
    borderColor="divider"
  >
    <Button
      variant="contained"
      onClick={backAction}
      disabled={isNull(backAction)}
      startIcon={<BackIcon style={{ fontSize: 14 }} />}
    >
      Back
    </Button>
    <Box>
      {skipAction && (
        <Button
          variant="contained"
          onClick={skipAction}
          endIcon={<NextIcon style={{ fontSize: 14 }} />}
          sx={{ mr: 2 }}
        >
          Skip
        </Button>
      )}
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={nextAction}
        disabled={isSubmitting || isNull(nextAction)}
        endIcon={<NextIcon style={{ fontSize: 14 }} />}
        loading={isSubmitting}
      >
        {nextActionText}
      </LoadingButton>
    </Box>
  </Box>
);

Navigation.defaultProps = {
  backAction: null,
  nextAction: null,
  skipAction: null,
  nextActionText: "Next",
  isSubmitting: false,
};

Navigation.propTypes = {
  backAction: PropTypes.func,
  nextAction: PropTypes.func,
  skipAction: PropTypes.func,
  nextActionText: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default Navigation;
