import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Typography, Container, Box, Paper } from "@mui/material";
import Page from "@components/Page";
import { find, map, includes, difference, first, filter } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import Navigation from "@components/Navigation";
import { getSitePickerColumns, transformSites } from "@addSites/sitePicker";
import CustomDataGrid from "@components/CustomDataGrid";
import pluralize from "pluralize";
import { usePrev, useNext } from "./useNextPrev";
import { ValidationError } from "yup";

const SitePicker = ({
  findSitesResult,
  saveSite,
  findSites,
  application,
  getProgramResult,
  showAppMessage,
  setSitePickerTableState,
  sitePickerTableState,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const prev = usePrev();
  const next = useNext();

  // get columns from addSites
  const columns = useMemo(
    () =>
      map(
        filter(
          getSitePickerColumns(),
          (col) => !includes(["documents", "details"], col.field)
        ),
        (col) => ({ ...col, editable: false })
      ),
    []
  );

  // find sites
  const { manufacturer, credentials } = application.siteInfo;
  const {
    organization: { id: organizationId },
    id: programId,
  } = getProgramResult.program;
  useEffect(() => {
    async function fetchSites() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      findSites({
        accessToken,
        manufacturer,
        credentials,
        organizationId,
        programId,
      });
    }
    fetchSites();
  }, [
    findSites,
    manufacturer,
    credentials,
    getAccessTokenSilently,
    organizationId,
    programId,
  ]);

  // put sites in state
  const isLoading =
    findSitesResult.status === "request" || findSitesResult.status === "init";
  const [sites, setSites] = useState([]);
  useEffect(() => {
    setSites(transformSites(findSitesResult.sites));

    // reselect site
    if (application.siteInfo?.site?.id) {
      setSelectionModel([application.siteInfo.site.id]);
    }
  }, [findSitesResult.sites, application.siteInfo?.site?.id]);

  // selecting sites
  const [selectionModel, setSelectionModel] = useState([]);

  // add sites
  const submit = async () => {
    try {
      if (selectionModel.length !== 1) {
        throw new ValidationError("At least one site must be checked");
      }
      saveSite({ site: find(sites, { id: first(selectionModel) }) });
      next("/apply/sitedetails");
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
    }
  };

  const { page, pageSize, sortModel, filterModel } = sitePickerTableState;
  return (
    <Page title="Choose a Site" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box ml={3}>
            <Typography variant="h2" color="textPrimary">
              {getProgramResult.program?.name} Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Choose one site to include in your application
            </Typography>
          </Box>
          <Paper sx={{ mt: 2, p: 3 }}>
            <Typography mb={2} color="textSecondary">
              Greyed out sites have already been added to a program. Please be
              patient, this can take some time. Some fields may be empty, but you can fill them in later.
            </Typography>
            <div style={{ height: "100%", width: "100%" }}>
              <CustomDataGrid
                autoHeight
                loading={isLoading}
                rows={sites}
                columns={columns}
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(sortModel) =>
                  setSitePickerTableState({ sortModel })
                }
                page={page}
                onPageChange={(page) => setSitePickerTableState({ page })}
                pageSize={pageSize}
                onPageSizeChange={(pageSize) =>
                  setSitePickerTableState({ pageSize })
                }
                rowsPerPageOptions={[10, 20, 50]}
                filterModel={filterModel}
                onFilterModelChange={(filterModel) =>
                  setSitePickerTableState({ filterModel })
                }
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                  const diff = difference(newSelectionModel, selectionModel);
                  setSelectionModel(diff.length > 1 ? diff.slice(0, 1) : diff);
                }}
                selectionModel={selectionModel}
                isRowSelectable={({ row: site }) => site.eligible}
                localeText={{
                  footerRowSelected: (count) =>
                    `${count.toLocaleString()} ${pluralize(
                      "site",
                      count
                    )} selected`,
                  noRowsLabel: "No sites found.",
                }}
                getRowClassName={({ row: site }) =>
                  !site.eligible && "disabled"
                }
              />
            </div>

            <Navigation
              backAction={prev}
              nextAction={submit}
              nextActionText="Continue"
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

SitePicker.propTypes = {
  findSitesResult: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  saveSite: PropTypes.func.isRequired,
  findSites: PropTypes.func.isRequired,
  application: PropTypes.shape({
    siteInfo: PropTypes.shape({
      manufacturer: PropTypes.string.isRequired,
      credentials: PropTypes.shape({}),
    }),
  }),
  getProgramResult: PropTypes.shape({
    program: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  showAppMessage: PropTypes.func.isRequired,
  setSitePickerTableState: PropTypes.func.isRequired,
  sitePickerTableState: PropTypes.shape({}).isRequired,
};

export default SitePicker;
