import { saveDataConnectorParams, saveMeters, saveSiteId } from "../actions";

export const initialState = {
  meters: null,
  dataConnectorParams: null,
};

const reducer = (form = initialState, action) => {
  switch (action.type) {
    case saveDataConnectorParams.type: {
      return {
        ...form,
        dataConnectorParams: {
          ...form.dataConnectorParams,
          ...action.payload,
        }
      };
    }

    case saveMeters.type: {
      const { meters } = action.payload;
      return {
        ...form,
        meters
      };
    }

    case saveSiteId.type: {
      const { siteId } = action.payload;
      return {
        ...form,
        siteId,
      };
    }

    default: {
      return form;
    }
  }
};

export default reducer;
