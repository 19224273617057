import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { acceptBuyerInvite, verifyBuyerInviteToken } from "../actions";
import { useAppMessage } from "@components/appMessage";
import { Typography, Container, Box, Paper } from "@mui/material";
import Page from "@components/Page";
import { Navigation } from "@components";
import { getUserProfile } from "@views/common/actions";

const AcceptInvite = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { invite } = useSelector((state) => state.verifyBuyerInviteToken);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const acceptInvite = async () => {
    setIsSubmitting(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(
      acceptBuyerInvite({
        accessToken,
        inviteId: invite.id,
        organizationId: userProfile.organizationId || invite.organization?.id,
        acceptedTermsAndConditions: userProfile.acceptedTermsAndConditions,
      })
    );
  };
  const { status: acceptBuyerInviteStatus } = useSelector((state) => state.acceptBuyerInvite);

  // invite accepted
  useEffect(() => {
    const finish = async () => {
      // get a new token, so it has the new roles
      await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        ignoreCache: true,
      });
      setIsSubmitting(false);
      dispatch(acceptBuyerInvite.reset());
      dispatch(verifyBuyerInviteToken.reset());

      // any org id is not in the fetched userprofile yet (but will be after accepting)
      if (invite.organization?.id) {
        dispatch(
          getUserProfile.update({ userProfile: { organizationId: invite.organization?.id } })
        );
      }

      navigate("/marketplace/portfolio");
    };
    if (acceptBuyerInviteStatus === "success") {
      finish();
    }
  }, [
    showAppMessage,
    acceptBuyerInviteStatus,
    dispatch,
    navigate,
    getAccessTokenSilently,
    invite.organization?.id,
  ]);

  return (
    <Page title="Accept Invite" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              Buyer Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Create your account
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <Typography gutterBottom>You're all set!</Typography>
            <Typography>
              Press continue to accept your invitation and go to your dashboard.
            </Typography>
            <Navigation
              nextAction={acceptInvite}
              nextActionText="Continue"
              isSubmitting={isSubmitting}
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default AcceptInvite;
