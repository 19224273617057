import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationPage } from "./ApplicationLayout";
import { application as appActions } from "../actions";
import { useNext, usePrev } from "./useNextPrev";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { isNil } from "lodash";
import { Navigation } from "components";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";

const FromRadicle = () => {
  const application = useSelector((state) => state.customerProjectApplication);
  const next = useNext();
  const prev = usePrev();
  const dispatch = useDispatch();
  const save = (formData) => dispatch(appActions.save(formData));
  /*

  */
  return (
    <ApplicationPage pageName="Site Details">
      <Form
        application={application}
        save={save}
        nextAction={() => next("location")}
        backAction={prev}
      />
    </ApplicationPage>
  );
};

const Form = ({ application, save, nextAction, backAction }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Yup.object().shape({ transferredFromRadicle: Yup.string().oneOf(['Yes', 'No']).typeError('You must specify if you are transferring your site/project from Radicle.'), })),
    defaultValues: {
      hasReceivedGrants: !isNil(application?.hasReceivedGrants)
        ? (application.hasReceivedGrants ? 'Yes' : 'No')
        : "",
    }
  });
  const onSubmit = details => {
    save({
      ...details,
      transferredFromRadicle: details.transferredFromRadicle === 'Yes' ? true : false,
    });
    nextAction();
  };

  const { errors, control, handleSubmit } = methods;

  return (
    <form>
      <FormControl
        component="fieldset"
        error={!isNil(errors.transferredFromRadicle?.message)}
      >
        <FormLabel>
          Have you registered with Radicle and are you transferring your project to Rewatt?
        </FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <RadioGroup
              aria-label="from-radicle"
              name="transferredFromRadicle"
              value={value}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          )}
          control={control}
          name="transferredFromRadicle"
        />
        {!isNil(errors.transferredFromRadicle?.message) && (
          <FormHelperText>{errors.transferredFromRadicle.message}</FormHelperText>
        )}
      </FormControl>
      <Navigation
        backAction={backAction}
        nextAction={() => handleSubmit(onSubmit)()}
        nextActionText="Continue"
      />
    </form>
  )
};

export default FromRadicle;

