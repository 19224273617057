import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddInvite from "@members/components/AddInvite";
import { addInvite } from "@members/actions";

const mapStateToProps = ({
  addInvite: addInviteResult,
}) => ({
  addInviteResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addInvite,
      resetAddInvite: () => dispatch(addInvite.reset()),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddInvite);
