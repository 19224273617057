import React from "react";
import { Container, Grid } from "@mui/material";
import Page from "@components/Page";
import ChannelPartnerInvites from "./ChannelPartnerInvites";
import ChannelPartnersList from "./ChannelPartnersList";
import PageHeader from "components/PageHeader";

const ChannelPartnersPage = () => (
  <Page py={3} title="Channel Partners">
    <Container maxWidth="lg">
      <PageHeader
        title="Channel Partners"
      />
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <ChannelPartnersList />
        </Grid>
        <Grid item xs={12}>
          <ChannelPartnerInvites />
        </Grid>
      </Grid>
    </Container>
  </Page>
);

export default ChannelPartnersPage;
