import React, { useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { isNil, map, keys, includes } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { EMISSION_REDUCTION_ACTIVITIES } from "@views/program/components/Program";
import TextArea from "@components/TextArea";
import LogoUploader from "@views/common/components/LogoUploader";
import { getOrganizationUsers } from "@views/programs/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { getFullName } from "@utils/stringHelpers";
import { Help } from "@mui/icons-material";
import { uploadCustomerProjectLogo } from "../actions";
import TermsUploader from './TermsUploader';

export const schema = yup.object().shape({
  title: yup.string().max(255, "Too long.").required("Project title is required."),
  description: yup.string().max(4096, "Too long.").nullable(),
  emissionReductionActivity: yup.string().required("Emission reduction activity is required."),
  isPublic: yup.bool(),
  managerId: yup.string().when("isPublic", {
    is: true,
    then: (schema) => schema.required("A point of contact is required"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  logo: yup.object().when("isPublic", {
    is: true,
    then: (schema) => schema.required("A logo is required"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  termsAndConditions: yup.object().when("isPublic", {
    is: true,
    then: (schema) => schema.required("Terms and conditions are required"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
});

const CustomerProjectFormFields = ({ disabled }) => {
  const { errors, control, watch } = useFormContext();
  const isPublic = watch('isPublic', false);
  const emissionReductionActivity = watch('emissionReductionActivity');
  const disablePublic = emissionReductionActivity !== "SOLAR_ELECTRICITY_POWER_GENERATION";

  const { status, users } = useSelector((state) => state.getOrganizationUsers);
  const loadingUsers = includes(["init", "request"], status);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getOrganizationUsers({ accessToken }));
    }
    const alreadyFetched = status === "success";
    if (isPublic && !alreadyFetched) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, isPublic, status]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Title"
              variant="standard"
              fullWidth
              error={!isNil(errors.title?.message)}
              helperText={errors.title?.message || "Provide a title for your project"}
              required
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
          control={control}
          name="title"
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex">
          <Controller
            render={({ onChange, value }) => (
              <TextArea
                label="Project Description"
                error={!isNil(errors.description?.message)}
                helperText={errors.description?.message}
                onChange={onChange}
                disabled={disabled}
                value={value}
                inputProps={{
                  maxLength: 4096,
                }}
                placeholder={`Add a description summarizing what your project is for.`}
              />
            )}
            control={control}
            name="description"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Emission Reduction Activity"
              variant="standard"
              fullWidth
              error={!isNil(errors.emissionReductionActivity?.message)}
              helperText={
                errors.emissionReductionActivity?.message ||
                "Select an emission reduction activity"
              }
              onChange={onChange}
              value={value}
              disabled={disabled}
              color="secondary"
              select
              required
            >
              {map(keys(EMISSION_REDUCTION_ACTIVITIES), (key) => (
                <MenuItem key={key} value={key}>
                  {EMISSION_REDUCTION_ACTIVITIES[key]}
                </MenuItem>
              ))}
            </TextField>
          )}
          control={control}
          name="emissionReductionActivity"
        />
      </Grid>
      {!disablePublic && (
        <Grid item xs={12}>
          <Controller
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e, checked) => {
                      onChange(checked);
                      e.stopPropagation(); // prevent dismiss dialog
                    }}
                    color="secondary"
                    disabled={disablePublic}
                  />
                }
                label={(
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography>Make this project public</Typography>
                    <Tooltip
                      title="Allow external stakeholders to submit applications to join your project."
                    >
                      <Help fontSize="1rem"/>
                    </Tooltip>
                  </Box>
                )}
              />
            )}
            control={control}
            name="isPublic"
          />
        </Grid>
      )}
      {isPublic && (
        <>
          <Grid item xs={12}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Point of Contact for Public Inquiries"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.managerId?.message)}
                  helperText={errors.managerId?.message || "Select a person"}
                  required
                  onChange={onChange}
                  value={value}
                  color="secondary"
                  select={!loadingUsers}
                  disabled={loadingUsers}
                >
                  {map(users, (user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {getFullName(user, null, user.userId)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="managerId"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Upload your organization's logo *</Typography>
            <Controller
              render={({ onChange, value }) => (
                <Box m={1}>
                  <LogoUploader
                    onChange={onChange}
                    value={value}
                    error={errors.logo?.message}
                    discriminator="PUBLIC_PROJECT"
                    uploadLogo={uploadCustomerProjectLogo}
                    selector={(state) => state.uploadCustomerProjectLogo}
                    tags={["Customer Project", "Logo"]}
                  />
                </Box>
              )}
              control={control}
              name="logo"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Upload Terms and Conditions *</Typography>
            <Typography variant="body2">Upload any terms and conditions you require external stakeholders to agree to in order to participate in your project.</Typography>
            <Controller
              render={({ onChange, value }) => (
                <TermsUploader
                  onChange={onChange}
                  value={value}
                  error={errors.termsAndConditions?.message}
                />
              )}
              control={control}
              name="termsAndConditions"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CustomerProjectFormFields;
