import { patchOrgAssetDocuments } from "../actions";

export const initialState = {
  status: "init",
  orgAsset: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case patchOrgAssetDocuments.RESET: {
      return initialState;
    }

    case patchOrgAssetDocuments.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case patchOrgAssetDocuments.SUCCESS: {
      const { orgAsset } = action.payload;
      return {
        ...state,
        status: "success",
        orgAsset,
      };
    }

    case patchOrgAssetDocuments.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        status: "failure",
        error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
