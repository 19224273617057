import { call, put, select, takeLatest } from "redux-saga/effects";
import { getUserProfile } from "@views/common/selectors";
import axios from "axios";
import { submitProjectApplication } from "../actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, customerProject, application) => await axios.post(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProject.id}/applications`,
  application,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* submitProjectApplicationWorker(action) {
  yield put(submitProjectApplication.request());
  try {
    const { accessToken, customerProject, application } = action.payload;
    const userProfile = yield select(getUserProfile);
    const response = yield call(
      callApi,
      accessToken,
      customerProject,
      {
        ...application,
        applicantIsOwner: true,
      }
    );
    yield put(submitProjectApplication.success({
      application: response.data,
      accessToken,
      userId: userProfile.userId,
    }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to submit application: ${message}`;
    yield put(submitProjectApplication.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* submitProjectApplicationWatcher() {
  yield takeLatest(submitProjectApplication.TRIGGER, submitProjectApplicationWorker);
}

export default [submitProjectApplicationWatcher];
