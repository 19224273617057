import { put, call, takeLatest } from "redux-saga/effects";
import { getProjectReportForStatements } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async ({ accessToken, projectReportId }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projectReports/${projectReportId}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getProjectReportForStatementsWorker(action) {
  yield put(getProjectReportForStatements.request());

  try {
    const response = yield call(callApi, action.payload);
    const projectReport = response.data;
    yield put(getProjectReportForStatements.success({ projectReport }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to get project report: ${message}`;
    yield put(getProjectReportForStatements.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProjectReportForStatementsWatcher() {
  yield takeLatest(getProjectReportForStatements.TRIGGER, getProjectReportForStatementsWorker);
}

export default [getProjectReportForStatementsWatcher];
