import { application } from "../actions";

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case application.RESET: {
      return initialState;
    }

    case application.SAVE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case application.SAVE_ASSET_DETAILS: {
      return {
        ...state,
        asset: {
          ...state?.asset,
          ...action.payload,
        },
      };
    }

    case application.SAVE_SYSTEM_DETAILS: {
      return {
        ...state,
        asset: {
          ...state?.asset,
          meters: action.payload,
        }
      }
    }

    default: {
      return state;
    }
  }
};

export default reducer;
