import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashboardLayout from "../components/DashboardLayout";
import { getOrganization } from "@views/common/actions";

const mapStateToProps = ({
  getOrganization: getOrganizationResult,
}) => ({
  getOrganizationResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrganization,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
