import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { omit } from "lodash";

// for new props, add a new version and migration
const migrations = {
  0: state => ({
    ...state,
    siteInfo: {
      ...state.siteInfo,
      credentials: {
        ...omit(state.siteInfo.credentials, "enphaseUserId"),
        authCode: "",
        cachelanPortalUrl: "",
      }
    }
  }),
};

export default function configureStore({ reducer, mainSaga }) {
  const sagaMiddleware = createSagaMiddleware();

  const localPersistConfig = {
    key: "weedle",
    version: 0,
    storage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations, { debug: false }),

    whitelist: [
      // onboarding/applying, save as we are building these entities
      "organization",
      "siteInfo",
      "application",
      "customerProjectApplication",

      // sagas are free to use these reducers
      "getUserProfile",
      "getOrganization",      
      "dataUpdated",

      // track some page nav history for a wizard
      "history",

      // allows us to reload breadcrumbs with name properly; ideally session storage
      // used in Projects.jsx, Project.jsx
      "getPrograms",
      "getProgram",

      // holds an invite, in case of reload or interrupt
      "verifyBuyerInviteToken",
      "verifyAssetClaimInviteToken",
      "verifyPayeeInviteToken",
      "verifyChannelPartnerInviteToken"
    ],
  };

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    persistReducer(localPersistConfig, reducer),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  /* eslint-enable */
  const persistor = persistStore(store);
  sagaMiddleware.run(mainSaga);

  return { store, persistor };
}
