import React from "react";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const GreenButton = styled((props) => (
  <LoadingButton variant="contained" {...props} />
))(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.main,
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },
}));

export default GreenButton;
