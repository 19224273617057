import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TermsAndConditions from "../components/TermsAndConditions";
import { acceptInvite } from "@views/orgInvites/actions";
import { getUserProfile } from "@views/common/actions";
import { showAppMessage } from "@appMessage/actions";

const mapStateToProps = ({ acceptInvite, getUserProfile }) => ({
  acceptInviteResult: acceptInvite,
  getUserProfileResult: getUserProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      acceptInvite,
      getUserProfile,
      showAppMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
