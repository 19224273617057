import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Typography,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isEmpty, every } from "lodash";
import { useAppMessage } from "@components/appMessage";
import { useDispatch } from "react-redux";
import { uploadFiles } from "@addSites/actions";
import DocumentUploader from "../containers/DocumentUploader";
import DocumentsInfo from "./DocumentsInfo";
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SiteDocumentsCellEditor = ({
  id,
  value: initialDocuments,
  api,
  field,
}) => {
  const [open, setOpen] = useState(true);
  const [documents, setDocuments] = useReducer(
    documentsReducer,
    initialDocuments
  );
  const showAppMessage = useAppMessage();
  const dispatch = useDispatch();

  const handleCloseEditor = () => {
    setDocuments({ type: "reset", payload: initialDocuments });
    setOpen(false);
    api.setCellMode(id, field, "view");
  };

  const handleSave = async () => {
    // validate and save
    const isTagged = every(documents, (doc) => !isEmpty(doc.tags));
    if (isTagged) {
      dispatch(uploadFiles.reset());
      api.setEditCellValue({ id, field, value: documents });
      const isValid = await api.commitCellChange({ id, field });
      if (isValid) {
        setOpen(false);
        api.setCellMode(id, field, "view");
      }
    } else {
      showAppMessage({
        severity: "warning",
        message: "Make sure you add a type for each file.",
        duration: 2500,
      });
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseEditor}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEditor}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Upload Documents
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Box m={4}>
        <DocumentsInfo>
          <DocumentUploader documents={documents} dispatch={setDocuments} />
        </DocumentsInfo>
      </Box>
    </Dialog>
  );
};

SiteDocumentsCellEditor.propTypes = {
  /**
   * GridApi that let you manipulate the grid.
   */
  api: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SiteDocumentsCellEditor;
