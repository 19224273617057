import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import {
  ApiKeyPanel,
  EnphaseAuthorizationCodePanel,
  UsernamePasswordPanel,
  FroniusAccessUrlPanel,
  SmaPermissionRequestPanel,
  CachelanUrlPanel,
  ApsCredentialPanel,
  ChargerIdPanel,
  EnergSyncPanel,
} from "@addSites/manufacturer";
import InstructionPanel from "./InstructionPanel";
import { FormProvider } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { includes, omit, filter, map } from "lodash";
import { EMISSION_REDUCTION_ACTIVITIES } from "views/program/components/Program";

const ManufacturerForm = ({
  siteInfo,
  saveManufacturer,
  saveCredentials,
  saveApiUrl,
  testConnection,
  resetTestConnection,
  formMethods,
  emissionReductionActivity,
  omittedManufacturers
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // select manufacturer
  const handleManufacturerChange = (event) => {
    saveManufacturer({ manufacturer: event.target.value });
    resetTestConnection();
  };

  // test connection form
  const runConnectionTest = async (data) => {
    const credentials = omit(data, "apiUrl");
    saveCredentials({ credentials });
    if (saveApiUrl) {
      saveApiUrl({ apiUrl: data.apiUrl });
    }
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    testConnection({
      manufacturer: siteInfo.manufacturer,
      apiUrl: data.apiUrl,
      credentials,
      accessToken,
    });
  };

  const manufacturers = emissionReductionActivity === 'LOW_CARBON_FUEL'
    ? [
      { name: "Wallbox", value: "WALLBOX" },
      { name: "Swift", value: "SWIFT" },
      { name: "EnergSync", value: "ENERGSYNC" },
    ] : [
      { name: "SolarEdge", value: "SOLAREDGE" },
      { name: "Enphase", value: "ENPHASE" },
      { name: "Fronius", value: "FRONIUS" },
      { name: "SMA", value: "SMA" },
      { name: "Hoymiles S-Miles Cloud", value: "HOYMILES" },
      { name: "Huawei LocusNoc", value: "HUAWEI" },
      { name: "REFUsol", value: "REFUSOL" },
      { name: "AP Systems (APS)", value: "APS" },
      { name: "Cachelan", value: "CACHELAN" },
    ];
  const { program } = useSelector((state) => state.getProgram);
  const manufacturersToOmit = omittedManufacturers ?? program?.omittedManufacturers;
  const includedManufacturers = manufacturersToOmit
    ? filter(manufacturers, (man) => !includes(manufacturersToOmit, man.value))
    : manufacturers;

  return (
    <>
      {/* select manufacturer */}
      <form noValidate autoComplete="off">
        <Typography variant="h4" fontWeight="bold">
          Who is the manufacturer of your metering device(s)?
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          We currently allow you to connect one manufacturer at a time. If you use more than one
          manufacturer, you will get a chance to connect them later.
        </Typography>
        <FormControl variant="standard" sx={{ minWidth: 300 }}>
          <InputLabel id="manufacturerLabel">Manufacturer/API</InputLabel>
          <Select
            labelId="manufacturerLabel"
            name="manufacturer"
            value={siteInfo.manufacturer}
            onChange={handleManufacturerChange}
          >
            {map(includedManufacturers, (manufacturer) => (
              <MenuItem
                key={manufacturer.value}
                value={manufacturer.value}
                disabled={manufacturer.disabled}
              >
                {manufacturer.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>The manufacturer of (or API for) the monitoring device for this site.</FormHelperText>
        </FormControl>
      </form>

      {/* instructions */}
      <InstructionPanel manufacturer={siteInfo.manufacturer} />

      {/* form and credentials test */}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(runConnectionTest)}>
          {siteInfo.manufacturer === "SOLAREDGE" && (
            <ApiKeyPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "ENPHASE" && (
            <EnphaseAuthorizationCodePanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "FRONIUS" && (
            <FroniusAccessUrlPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "WALLBOX" && (
            <ChargerIdPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "SWIFT" && (
            <ChargerIdPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "SMA" && (
            <SmaPermissionRequestPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "APS" && (
            <ApsCredentialPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "HOYMILES" && (
            <UsernamePasswordPanel manufacturer="HOYMILES" credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "HUAWEI" && (
            <UsernamePasswordPanel manufacturer="HUAWEI" credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "REFUSOL" && (
            <UsernamePasswordPanel manufacturer="REFUSOL" credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "CACHELAN" && (
            <CachelanUrlPanel credentials={siteInfo.credentials} />
          )}
          {siteInfo.manufacturer === "ENERGSYNC" && (
            <EnergSyncPanel credentials={siteInfo.credentials} apiUrl={siteInfo.apiUrl} />
          )}
        </form>
      </FormProvider>
    </>
  );
};

ManufacturerForm.propTypes = {
  siteInfo: PropTypes.shape({
    manufacturer: PropTypes.string,
    credentials: PropTypes.shape({}),
    apiUrl: PropTypes.string,
  }),
  saveApiUrl: PropTypes.func,
  saveManufacturer: PropTypes.func.isRequired,
  saveCredentials: PropTypes.func.isRequired,
  testConnection: PropTypes.func.isRequired,
  resetTestConnection: PropTypes.func.isRequired,
  formMethods: PropTypes.shape({}).isRequired,
  emissionReductionActivity: PropTypes.oneOf(Object.keys(EMISSION_REDUCTION_ACTIVITIES)),
};

export default ManufacturerForm;
