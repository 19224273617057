import ApsEMA from './ApsEMA';
import ApsPublic from './ApsPublic';
import CachelanSolarVu from './CachelanSolarVu';
import EnergSync from './EnergSync';
import EnphaseEnlighten from './EnphaseEnlighten';
import FroniusSolarWeb from './FroniusSolarWeb';
import HoymilesSmilesCloud from './HoymilesSmilesCloud';
import HuaweiLocusNOC from './HuaweiLocusNOC';
import RefusolRefulog from './RefusolRefulog';
import SMASunnyPortalEnnexOS from './SMASunnyPortalEnnexOS';
import SolarEdgeMonitoringAPI from './SolarEdgeMonitoringAPI';
import Swift from './Swift';
import Wallbox from './Wallbox';
import SaasCharge from './SaasCharge';

const getDataConnectorComponents = ({ type }) => {
  switch (type) {
  case ApsEMA.DataConnector:
    return ApsEMA;
  case ApsPublic.DataConnector:
    return ApsPublic;
  case CachelanSolarVu.DataConnector:
    return CachelanSolarVu;
  case EnergSync.DataConnector:
    return EnergSync;
  case EnphaseEnlighten.DataConnector:
    return EnphaseEnlighten;
  case FroniusSolarWeb.DataConnector:
    return FroniusSolarWeb;
  case HoymilesSmilesCloud.DataConnector:
    return HoymilesSmilesCloud;
  case HuaweiLocusNOC.DataConnector:
    return HuaweiLocusNOC;
  case RefusolRefulog.DataConnector:
    return RefusolRefulog;
  case SaasCharge.DataConnector:
    return SaasCharge;
  case SMASunnyPortalEnnexOS.DataConnector:
    return SMASunnyPortalEnnexOS;
  case SolarEdgeMonitoringAPI.DataConnector:
    return SolarEdgeMonitoringAPI;
  case Swift.DataConnector:
    return Swift;
  case Wallbox.DataConnector:
    return Wallbox;
  default:
    return { Form: () => <></>, Instructions: () => <></>, DataConnector: '' };
  }
};

export default getDataConnectorComponents;