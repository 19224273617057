import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditProgramEditor from "../components/EditProgramEditor";
import { patchProgram } from "../actions";

const mapStateToProps = ({ patchProgram: patchProgramResult }) => ({
  patchProgramResult,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      patchProgram,
    },
    dispatch
  ),
  resetPatchProgram: () => {
    dispatch(patchProgram.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProgramEditor);
