import PropTypes from "prop-types";
import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const FundingSourceComboBox = ({ fundingSource, setFundingSource }) => {
  return (
    <Autocomplete
      value={fundingSource}
      onChange={(event, newValue) => {
        const match = newValue.match(/^Add "(.+)"$/);
        setFundingSource({ fundingSource: match ? match[1] : newValue });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push(`Add "${params.inputValue}"`);
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      disableClearable
      handleHomeEndKeys
      blurOnSelect
      id="fundingSource"
      options={fundingSources}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label="Grant/Funding Source"
          placeholder="Type or choose a funding source. eg. a municipal solar program."
          helperText="Providing the source helps determine the markets in which you can participate."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const fundingSources = [
  "Canada Greener Homes Grant",
  "City of Edmonton Change Homes for Climate - Solar Program",
  "Energy Efficiency Alberta's Residential and Commercial Solar Program",
  "The EQUS Rural Electrification Association Solar Incentive Financing Program", 
  "Solar for Schools Program",
  "Alberta Residential and Commercial Solar Program",
  "On-Farm Solar Photovoltaics Program",
  "Alberta Municipal Solar Program (AMSP)",
  "Capital Cost Allowance (CCA) Renewable Energy",
  "Canadian Renewable and Conservation Expenses (CRCE)",
];

FundingSourceComboBox.defaultProps = {
  fundingSource: "",
};

FundingSourceComboBox.propTypes = {
  setFundingSource: PropTypes.func.isRequired,
  fundingSource: PropTypes.string,
};

export default FundingSourceComboBox;
