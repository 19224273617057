import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";

const FroniusAccessUrlPanel = ({ credentials }) => {
  const { errors, control } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="System URL"
              variant="standard"
              fullWidth
              placeholder="https://www.solarweb.com/PvSystemSettings/Permissions?pvSystemId=6a98063d-a0f1-13bd-a6fe-765c09823ks"
              autoFocus
              error={!isNil(errors.froniusAccessUrl?.message)}
              helperText={errors.froniusAccessUrl?.message || " "}
            />
          }
          name="froniusAccessUrl"
          control={control}
          rules={{ required: "Fronius System URL is required." }}
          defaultValue={credentials.froniusAccessUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

FroniusAccessUrlPanel.propTypes = {
  credentials: PropTypes.shape({
    froniusAccessUrl: PropTypes.string,
  }).isRequired,
};

export default FroniusAccessUrlPanel;
