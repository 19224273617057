import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import { DateTime } from "luxon";
import { useForm, Controller } from "react-hook-form";
import { isNil, join } from "lodash";
import FundingSourceComboBox from "./FundingSourceComboBox";
import UtilityComboBox from "@views/addSites/sitePicker/components/UtilityComboBox";
import { getDefaultLocale } from "@utils/dateHelpers";
import logger from "debug";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const mountingTypes = [
  { label: "Ground", value: "GROUND" },
  { label: "Rooftop", value: "ROOFTOP" },
  { label: "Tracking", value: "TRACKING" },
];

export const ownershipTypes = [
  { label: "Lease", value: "LEASE" },
  { label: "Freehold", value: "FREEHOLD" },
];

const SiteDetails = ({
  getSiteResult,
  getFirstReportDateResult: { firstReport },
  patchSiteDetails,
  patchSiteDetailsResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editing, setEditing] = useState(false);

  // our initial state is set when clicking on a table row, which does a partial update of site
  const { site } = getSiteResult;
  const [siteDetails, setSiteDetails] = useState({
    name: site.name || "",
    capacity: site.capacity / 1000,
    completionDate: site.completionDate || "", // iso format "2017-09-29"
    mountingType: site.mountingType || "",
    utility: site.utility || "",
    customerId: site.customerId || "",
    equipmentOwnership: site.equipmentOwnership || "",
    fundingSource: site.fundingSource || "",
    cost: site.cost || "",
  });

  // set up form state
  const { handleSubmit, errors, control, reset } = useForm({
    mode: "onChange",
    defaultValues: siteDetails,
  });

  // if we edit the site, need to re-initialize the component state
  useEffect(() => {
    if (patchSiteDetailsResult.status === "success") {
      // reset component state
      const { site } = patchSiteDetailsResult;
      setSiteDetails({
        name: site.name || "",
        capacity: site.capacity / 1000,
        completionDate: site.completionDate || "",
        mountingType: site.mountingType || "",
        utility: site.utility || "",
        customerId: site.customerId || "",
        equipmentOwnership: site.equipmentOwnership || "",
        fundingSource: site.fundingSource || "",
        cost: site.cost || "",
      });
      setEditing(false);
    }
  }, [patchSiteDetailsResult]);

  // once the site fetch comes in, re-initialize the component state
  useEffect(() => {
    if (getSiteResult.status === "success") {
      const { site } = getSiteResult;
      setSiteDetails({
        name: site.name || "",
        capacity: site.capacity / 1000,
        completionDate: site.completionDate || "", // iso format "2017-09-29"
        mountingType: site.mountingType || "",
        utility: site.utility || "",
        customerId: site.customerId || "",
        equipmentOwnership: site.equipmentOwnership || "",
        fundingSource: site.fundingSource || "",
        cost: site.cost || "",
      });
      setEditing(false);
    }
  }, [getSiteResult]);

  // if our component state is changed, update form
  useEffect(() => {
    reset(siteDetails);
  }, [siteDetails, reset]);

  const isSubmitting = patchSiteDetailsResult.status === "request";

  const onSubmit = async (siteDetails) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    patchSiteDetails({ accessToken, siteId: site.id, siteDetails });
  };

  const handleCancel = () => {
    setEditing(false);
    reset(siteDetails);
  };

  // unhandled errors
  const onError = (err) => {
    logger("weedle:warn")("Error with site details edit form: ", err);
  };

  const firstReportDate = firstReport
    ? DateTime.fromISO(firstReport)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATETIME_SHORT)
    : "N/A";

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Site Information"
            subheader={`Rewatt Site ID: ${getSiteResult?.site?.id}`}
            action={
              <>
                {!editing && (
                  <Button
                    aria-label="edit"
                    sx={{ mr: 1 }}
                    onClick={() => setEditing(true)}
                  >
                    Edit
                  </Button>
                )}
                {editing && (
                  <>
                    <Button
                      aria-label="cancel"
                      variant="text"
                      disabled={isSubmitting}
                      onClick={() => {
                        handleCancel();
                      }}
                      sx={{ mr: 1 }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      aria-label="save"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                      disabled={isSubmitting}
                      onClick={() => handleSubmit(onSubmit, onError)()}
                      loading={isSubmitting}
                    >
                      Save Changes
                    </LoadingButton>
                  </>
                )}
              </>
            }
          />
          <Divider />
          <CardContent>
            <form noValidate autoComplete="off">
              <Typography variant="h5" gutterBottom>
                General
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        variant="standard"
                        label="Site Name"
                        fullWidth
                        error={!isNil(errors.name?.message)}
                        helperText={errors.name?.message}
                        InputProps={{
                          readOnly: !editing,
                        }}
                        required
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    control={control}
                    name="name"
                    rules={{
                      required: "Site Name is required.",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        variant="standard"
                        label="Capacity (kW)"
                        fullWidth
                        error={!isNil(errors.capacity?.message)}
                        helperText={errors.capacity?.message}
                        InputProps={{
                          readOnly: !editing,
                        }}
                        required
                        onChange={onChange}
                        value={value}
                        type="number"
                      />
                    )}
                    control={control}
                    name="capacity"
                    rules={{
                      required: "Capacity is required.",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <DatePicker
                        label="Installation Date"
                        value={DateTime.fromISO(value)}
                        onChange={onChange}
                        readOnly={!editing}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="standard"
                            fullWidth
                            {...params}
                            error={!isNil(errors.completionDate?.message)}
                            helperText={errors.completionDate?.message}
                          />
                        )}
                        disableMaskedInput
                      />
                    )}
                    control={control}
                    name="completionDate"
                    rules={{
                      required: "Installation Date is required.",
                    }}                    
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        variant="standard"
                        name="mountingType"
                        required
                        InputProps={{
                          readOnly: !editing,
                        }}
                        select
                        fullWidth
                        label="Mounting Type"
                        value={value}
                        onChange={onChange}
                        error={!isNil(errors.mountingType?.message)}
                        helperText={errors.mountingType?.message}                        
                      >
                        {mountingTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                    name="mountingType"
                    rules={{
                      required: "Mounting Type is required.",
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                  Utility
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <UtilityComboBox
                          utility={value}
                          setUtility={({ utility }) => onChange(utility)}
                          readOnly={!editing}
                          helperText=""
                        />
                      )}
                      control={control}
                      name="utility"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          variant="standard"
                          label="Existing Customer ID"
                          fullWidth
                          error={!isNil(errors.customerId?.message)}
                          helperText={errors.customerId?.message}
                          InputProps={{
                            readOnly: !editing,
                          }}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                      name="customerId"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={4}>
                <Typography variant="h5" gutterBottom>
                  Ownership
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          variant="standard"
                          name="equipmentOwnership"
                          InputProps={{
                            readOnly: !editing,
                          }}
                          select
                          fullWidth
                          label="Equipment Ownership"
                          value={value}
                          onChange={onChange}
                        >
                          {ownershipTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      control={control}
                      name="equipmentOwnership"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          variant="standard"
                          label="Installation Cost"
                          fullWidth
                          error={!isNil(errors.cost?.message)}
                          helperText={errors.cost?.message}
                          InputProps={{
                            readOnly: !editing,
                          }}
                          onChange={onChange}
                          value={value}
                          type="number"
                        />
                      )}
                      control={control}
                      name="cost"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <FundingSourceComboBox
                          value={value}
                          onChange={onChange}
                          readOnly={!editing}
                        />
                      )}
                      control={control}
                      name="fundingSource"
                    />
                  </Grid>
                </Grid>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title="Device Information" />
          <Divider />
          <CardContent>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Manufacturers"
                    value={join(site.manufacturers, ", ") || "N/A"}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="First Report Date"
                    value={firstReportDate}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Activity Type"
                    value={site.type || "N/A"}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Number of Devices"
                    value={site.numMeters || "N/A"}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

SiteDetails.propTypes = {
  getSiteResult: PropTypes.shape({
    site: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  getFirstReportDateResult: PropTypes.shape({
    firstReport: PropTypes.string,
  }),
  patchSiteDetails: PropTypes.func.isRequired,
  patchSiteDetailsResult: PropTypes.shape({
    status: PropTypes.string,
    site: PropTypes.shape({}),
  }),
};

export default SiteDetails;
