import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "@components/Page";
import { Container, Typography } from "@mui/material";
import { getUserProfile } from "@views/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Start = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hasErr, setHasErr] = useState(false);

  // has inviteId from welcome page (set in onRedirectCallback)
  const { state } = useLocation();
  const { invite } = useSelector((state) => state.verifyPayeeInviteToken);
  const inviteId = parseInt(state?.inviteId) || invite.id;

  // get user profile
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getUserProfile({ accessToken, userId: user.sub }));
    }

    fetchUserProfile();
  }, [user, getAccessTokenSilently, dispatch]);

  // once userProfile is loaded, navigate
  const { status, userProfile } = useSelector((state) => state.getUserProfile);
  useEffect(() => {
    if (status === "success") {
      // if emails don't match, we have to stop this process
      if (invite.inviteeEmail !== userProfile.email) {
        setHasErr(true);
      } else {
        navigate("/marketplace/payee/terms");
      }      
    }
    if (status === "failure") {
      setHasErr(true);
    }
  }, [status, getAccessTokenSilently, inviteId, navigate, invite.inviteeEmail, userProfile.email]);

  return hasErr ? (
    <Page title="Payee Onboarding" py={3}>
      <Container maxWidth="lg">
        <Typography variant="h4">
          Sorry we had a problem starting your onboarding. Please try again.
          Make sure you sign up with the email where you received your invite.
        </Typography>
      </Container>
    </Page>
  ) : null;
};

export default Start;
