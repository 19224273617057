import { call, put, takeLatest } from "redux-saga/effects";
import { getSiteCredits } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, siteId, projectId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/${siteId}/credits`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        projectId,
      }
    }
  );
  return response;
};

function* getSiteCreditsWorker(action) {
  yield put(getSiteCredits.request());

  const { accessToken, siteId, projectId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, siteId, projectId);
    yield put(getSiteCredits.success(response.data));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to get credit summary: ${message}`;
    yield put(getSiteCredits.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getSiteCreditsWatcher() {
  yield takeLatest(getSiteCredits.TRIGGER, getSiteCreditsWorker);
}

export default [getSiteCreditsWatcher];
