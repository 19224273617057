import React from "react";
import { Typography, Paper, Grid, Box, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { useSelector } from "react-redux";
import { round } from "@utils/numberHelpers";
import { wmTokWh } from "@utils/energyHelpers";

const TodayCard = () => {
  const lastCheck = DateTime.now();

  const { site } = useSelector((state) => state.getLiveSite);
  const { generated, exported, imported } = useSelector(
    (state) => state.getLiveEnergyToday
  );

  return (
    <Paper sx={{ height: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Typography variant="h3">Today's Energy</Typography>
            <Typography color="text.secondary">
              {lastCheck
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATETIME_MED)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center" height="113px">
            <Typography variant="h5" textAlign="center">
              SOLAR GENERATION
            </Typography>
            <Typography
              variant="h2"
              color="success.main"
              textAlign="center"
              p={1}
              pt={2}
            >
              {round(wmTokWh(generated), 1).toLocaleString()}
              <Typography color="success.main" component="span">
                {" "}
                kWh
              </Typography>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center" height="113px">
            <Typography variant="h5" textAlign="center">
              GRID EXPORT
            </Typography>
            <Typography
              variant="h2"
              color="secondary.dark"
              textAlign="center"
              p={1}
              pt={2}
            >
              {round(wmTokWh(exported), 0).toLocaleString()}
              <Typography color="secondary.dark" component="span">
                {" "}
                kWh
              </Typography>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center" height="113px">
            <Typography variant="h5" textAlign="center">
              GRID IMPORT
            </Typography>
            <Typography
              variant="h2"
              color="info.main"
              textAlign="center"
              p={1}
              pt={2}
            >
              {round(wmTokWh(imported), 0).toLocaleString()}
              <Typography color="info.main" component="span">
                {" "}
                kWh
              </Typography>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            backgroundColor="primary.main"
            p={1}
            sx={(theme) => ({
              borderBottomLeftRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius,
            })}
          >
            <Typography variant="h3" color="common.white">
              Installed Capacity: {(site.capacity / 1000).toLocaleString()} kW
              (DC)
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default TodayCard;
