import addInvite from "./addInvite";
import getOrgInvites from "./getOrgInvites";
import deleteInvite from "./deleteInvite";
import resendInvite from "./resendInvite";
import updatePrimaryContact from "./updatePrimaryContact";
import memberTableState from "./memberTableState";

const reducers = {
  addInvite,
  getOrgInvites,
  deleteInvite,
  resendInvite,
  updatePrimaryContact,
  memberTableState,
};

export default reducers;
