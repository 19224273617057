import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DocumentUploader from "@addSites/sitePicker/containers/DocumentUploader";
import DocumentsInfo from "@addSites/sitePicker/components/DocumentsInfo";
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { patchOrgAssetDocuments } from "../actions";
import { useSelector, useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentsEditor = ({ open, onClose, onSave, documents: initialDocuments, orgAsset }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [documents, setDocuments] = useReducer(documentsReducer, initialDocuments);

  // once loaded, initialDocuments changes (but not the reducer)
  useEffect(() => {
    setDocuments({
      type: "reset",
      payload: initialDocuments,
    });
  }, [initialDocuments]);

  // patch application on BE
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_sites",
    });

    dispatch(patchOrgAssetDocuments({ accessToken, documents, orgAssetId: orgAsset.id }));
  };

  // close and cleanup on success
  const { status, orgAsset: updatedOrgAsset } = useSelector(
    (state) => state.patchOrgAssetDocuments
  );
  useEffect(() => {
    if (status === "success") {
      // ie. update component state in DocumentsPanel
      onSave(updatedOrgAsset.documents);
    }
  }, [updatedOrgAsset.documents, status, onSave]);

  const isSubmitting = status === "request";
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          backgroundColor: (theme) => theme.palette.background.dark,
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Upload Documents
          </Typography>
          <LoadingButton
            color="inherit"
            onClick={onSubmit}
            title="Save"
            loading={isSubmitting}
            loadingIndicator={<CircularProgress sx={{ color: "common.white" }} size={16} />}
          >
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Box m={4}>
        <Paper elevation={1} sx={{ p: 2 }}>
          <DocumentsInfo>
            <DocumentUploader documents={documents} dispatch={setDocuments} />
          </DocumentsInfo>
        </Paper>
      </Box>
    </Dialog>
  );
};

DocumentsEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DocumentsEditor;
