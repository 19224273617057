/*
  Validate the assets _before_ transformation, except for addresses, as we get them from the material table.
 */
import * as yup from "yup";
import { size, isNil } from "lodash";

const addressSchema = yup.object().required().default(undefined).shape({
  street: yup.string().required(),
  city: yup.string().required(),
  region: yup.string().required(),
  country: yup.string().required(),
  postal: yup.string().required(),
});

const documentSchema = yup.object().shape({
  uri: yup.string().required(),
  file: yup
    .mixed()
    .test("path", "File path is required", (f) => !isNil(f.path)),
  tags: yup.array().of(yup.string()).min(1),
});

// not exhaustive, because we just mirror what the server gives us, for validation
const meterSchema = yup.object().shape({
  id: yup.number().nullable(),
  manufacturer: yup.string().required(),
  type: yup.string().required(),
  dataConnector: yup.object().required(),
});

const assetSchema = yup.object().shape({
  name: yup.string().required(),
  capacity: yup.number().required().positive(),
  completionDate: yup.date().required(), // allows iso strings and Date instances
  location: yup
    .object()
    .required()
    .default(undefined)
    .shape({
      addresses: yup.object().required().default(undefined).shape({
        main: addressSchema,
      }),
      latitude: yup.number().required(),
      longitude: yup.number().required(),
      timeZone: yup.string().required(),
    }),
  documents: yup.array().notRequired().of(documentSchema),
  details: yup.object().notRequired().shape({
    utility: yup.string(),
    customerId: yup.string(),
    equipmentOwnership: yup.string(),
    hasReceivedGrants: yup.bool().nullable(),
    fundingSource: yup.string(),
    mountingType: yup.string(),
  }),
  meters: yup.array().required().of(meterSchema),
});

const schema = yup
  .array()
  .of(assetSchema)
  .test(
    "atLeastOneChecked",
    "At least one site must be checked.",
    (assets, context) => size(assets) > 0
  );

// returns the validated assets, or throws a ValidationError
const validate = async (assets) => {
  return schema.validate(assets);
};

// checks if all provided assets are valid
const isValid = async (assets) => schema.isValid(assets);

export { validate, isValid };
