import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { getFullName } from "@utils/stringHelpers";
import { PhoneLink, EmailLink } from "@components";

const InstallerPanel = ({ orgAsset }) => {
  const tableRows = [
    {
      label: "Name",
      value: getFullName(orgAsset?.installer),
    },
    {
      label: "Email",
      value: <EmailLink email={orgAsset?.installer?.email} />
    },
    {
      label: "Phone",
      value: <PhoneLink phone={orgAsset?.installer?.phone} />
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Contractor
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="contractor info">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default InstallerPanel;
