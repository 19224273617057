import approveApplication from "./approveApplication";
import declineApplication from "./declineApplication";
import requireChangesToApplication from "./requireChangesToApplication";
import applicationsTable from "./applicationsTable";

const reducers = {
  approveApplication,
  declineApplication,
  requireChangesToApplication,
  applicationsTable,
};

export default reducers;
