import { call, put, takeLatest } from "redux-saga/effects";
import { getProjectReportCalculation } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { find, includes, map } from "lodash";

const callApi = async (accessToken, projectId, startDate, endDate, credits) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/projectReports/calculate`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        startDate,
        endDate: endDate ? endDate : undefined,
        credits,
      },
    }
  );
  return response;
};

const transform = (sites) =>
  map(sites, (site) => ({
    ...site,
    location: find(site.addresses, (address) => includes(address.types, "MAIN")),
  }));

function* getProjectReportCalculationWorker(action) {
  yield put(getProjectReportCalculation.request());

  const { projectId, startDate, endDate, credits, accessToken } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      projectId,
      startDate.toISODate(),
      endDate?.toISODate(),
      credits
    );
    yield put(
      getProjectReportCalculation.success({
        projectReportCalculation: { sites: transform(response.data.assets), ...response.data },
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting project report calculation: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting project report calculation: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting project report calculation: ", err);
      message = err.message;
    }
    message = `Unable to get project report calculation: ${message}`;
    yield put(getProjectReportCalculation.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProjectReportCalculationWatcher() {
  yield takeLatest(getProjectReportCalculation.TRIGGER, getProjectReportCalculationWorker);
}

export default [getProjectReportCalculationWatcher];
