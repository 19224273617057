import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Box, Button } from "@mui/material";
import { transform, pick } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import SiteLocationCellEditor from "./SiteLocationCellEditor";
import SiteDocumentsCellEditor from "./SiteDocumentsCellEditor";
import SiteDetailsCellEditor from "./SiteDetailsCellEditor";
import GoBackDialog from "./GoBackDialog";
import { map, includes } from "lodash";
import { DateTime } from "luxon";
import { validate } from "../AssetValidator";
import LoadingButton from "@mui/lab/LoadingButton";
import GreenButton from "@components/GreenButton";
import { getDefaultLocale } from "@utils/dateHelpers";
import CustomDataGrid from "@components/CustomDataGrid";
import pluralize from "pluralize";
import {
  areSiteDetailsComplete,
  areSiteDetailsDone,
} from "./SiteDetailsCellEditor";
import { renderCellExpand } from "@components/GridCellExpand";

export const getSitePickerColumns = () => {
  return [
    {
      headerName: "Site Name*",
      field: "name",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Capacity (kW)*",
      field: "capacity",
      flex: 0.8,
      valueFormatter: ({ value }) => {
        return (value / 1000).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
      },
      editable: true,
      type: "number",
    },
    {
      headerName: "Install Date*",
      field: "completionDate",
      flex: 1,
      editable: true,
      valueFormatter: ({ value }) => {
        return DateTime.fromISO(value)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT);
      },
      type: "date",
      valueGetter: ({ value }) => DateTime.fromISO(value),
      valueSetter: ({ value, row: site }) => ({
        ...site,
        completionDate: DateTime.fromJSDate(value).toFormat("yyyy-MM-dd"),
      }),
    },
    {
      headerName: "Location*",
      field: "location",
      flex: 1.2,
      editable: true,
      renderCell: ({ value }) => {
        const location = value.addresses.main;
        return (
          <Typography fontWeight="bold" component="div" variant="body2">
            {location.city},{" "}
            <Typography display="inline" variant="body2">
              {location.region}
            </Typography>
            <Typography variant="body2">{location.street}</Typography>
          </Typography>
        );
      },
      renderEditCell: (params) => <SiteLocationCellEditor {...params} />,
    },
    {
      headerName: "Documents",
      field: "documents",
      flex: 1,
      editable: true,
      renderEditCell: (params) => <SiteDocumentsCellEditor {...params} />,
      valueFormatter: ({ value: docs }) => `${docs.length} Docs`,
    },
    {
      headerName: "Site Details",
      field: "details",
      flex: 1,
      editable: true,
      renderEditCell: (params) => <SiteDetailsCellEditor {...params} />,
      valueFormatter: ({ value: details }) =>
        areSiteDetailsComplete(details)
          ? "Complete"
          : areSiteDetailsDone(details)
          ? "Done"
          : "Incomplete",
    },
  ];
};

// massage data for display
export const transformSites = (foundSites) => {
  const sites = foundSites.map((foundSite) => ({
    id: foundSite.id,
    name: foundSite.name,
    capacity: foundSite.capacity,
    completionDate: foundSite.completionDate,
    // n.b. *isNew* is true if the site is new to Rewatt (i.e. foundSite.id is generated by Weedle)
    // and false it is not new to Rewatt (i.e. foundSite.id is from Surge)
    isNew: foundSite.isNew,
    location: {
      addresses: {
        // we only get one address from a found site
        main: transform(
          pick(foundSite.address, [
            "street",
            "city",
            "region",
            "country",
            "postal",
          ]),
          (result, value, key) => (result[key] = value ? value : ""),
          {}
        ),
      },
      latitude: foundSite.address?.latitude,
      longitude: foundSite.address?.longitude,
      timeZone:
        foundSite.address?.timezone ||
        foundSite.address?.timeZone ||
        "America/Edmonton",
    },
    documents: foundSite.documents.map((doc) => ({
      ...doc,
      uri: `${process.env.REACT_APP_SURGE_API}/api/v2/files/${doc.path}`,
      file: {
        path: doc.name,
        size: doc.size,
      },
      tags: map(doc.tags, (tag) => tag.name),
    })),
    details: {
      utility: foundSite.utility || "",
      customerId: foundSite.customerId || "",
      equipmentOwnership: foundSite.equipmentOwnership || "",
      hasReceivedGrants: foundSite.hasReceivedGrants || null,
      fundingSource: foundSite.fundingSource || "",
      mountingType: foundSite.mountingType || "",
    },
    // submission requires meters
    meters: foundSite.meters,
    // can't select or edit if the site is not eligible
    eligible: foundSite.eligible,
  }));
  return sites;
};

const SitePicker = ({
  findSitesResult,
  findSites,
  manufacturer,
  credentials,
  resetFindSitesResult,
  showAppMessage,
  addSites,
  addSitesResult,
  resetAll,
  stepLayout: StepLayout,
  backAction,
  nextAction,
  setSitePickerTableState,
  sitePickerTableState,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const columns = useMemo(() => getSitePickerColumns(), []);

  // get sites on load
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      findSites({ accessToken, manufacturer, credentials });
    }
    init();
  }, [findSites, manufacturer, credentials, getAccessTokenSilently]);

  const isLoading =
    findSitesResult.status === "request" || findSitesResult.status === "init";
  const [sites, setSites] = useState([]);
  useEffect(() => {
    setSites(transformSites(findSitesResult.sites));
  }, [findSitesResult.sites]);

  // selecting sites
  const [selectionModel, setSelectionModel] = useState([]);

  // editing cells
  const getCellValue = useCallback((field, value) => {
    if (field === "completionDate") {
      return DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
    }
    return value;
  }, []);

  const handleCellEditCommit = ({ field, id, value }) => {
    const updatedSites = map(sites, (site) =>
      site.id === id ? { ...site, [field]: getCellValue(field, value) } : site
    );
    setSites(updatedSites);
  };

  // add sites
  const submit = async (data) => {
    setIsSubmitting(true);
    try {
      const validatable = data.filter((site) =>
        includes(selectionModel, site.id)
      );
      await validate(validatable);

      // nb we submit data, not validatable
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      addSites({
        sites: data.filter((site) => includes(selectionModel, site.id)),
        accessToken,
      });
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
      setIsSubmitting(false);
    }
  };

  // isSubmitting
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (includes(["success", "failure"], addSitesResult.status)) {
      setIsSubmitting(false);
    }
  }, [addSitesResult.status]);

  // add sites success
  const isSuccess = addSitesResult.status === "success";

  // don't lose changes going back
  const [open, setOpen] = useState(false);
  const cancelAction = () => {
    setOpen(false);
  };
  const okAction = () => {
    setOpen(false);
    resetFindSitesResult();
    backAction();
  };
  const goBack = () => {
    setOpen(true);
  };

  // cleanup on continue
  const goNext = () => {
    resetAll();
    nextAction();
  };

  const { page, pageSize, sortModel, filterModel } = sitePickerTableState;
  return (
    <StepLayout activeStep={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" mt={1} mb={1}>
            Choose sites to include for credit reporting.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Looking for sites with your credentials. Please be patient, this can
            take some time. Once finished, choose to include all sites, or a
            subset. Greyed out sites have already been added. Double-click a
            cell to make changes.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <CustomDataGrid
              autoHeight
              loading={isLoading}
              rows={sites}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(sortModel) =>
                setSitePickerTableState({ sortModel })
              }
              page={page}
              onPageChange={(page) => setSitePickerTableState({ page })}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) =>
                setSitePickerTableState({ pageSize })
              }
              rowsPerPageOptions={[10, 20, 50]}
              filterModel={filterModel}
              onFilterModelChange={(filterModel) =>
                setSitePickerTableState({ filterModel })
              }
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              isRowSelectable={({ row: site }) => site.eligible}
              isCellEditable={({ row: site }) => site.eligible}
              onCellEditCommit={handleCellEditCommit}
              localeText={{
                footerRowSelected: (count) =>
                  `${count.toLocaleString()} ${pluralize(
                    "site",
                    count
                  )} selected`,
                noRowsLabel: "No sites found.",
              }}
              getRowClassName={({ row: site }) => !site.eligible && "disabled"}
            />
          </div>
        </Grid>
      </Grid>

      <Box
        sx={{ mt: 4 }}
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
      >
        <Button variant="text" onClick={goBack} disabled={isSuccess}>
          Back
        </Button>
        {!isSuccess && (
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => submit(sites)}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        )}
        {isSuccess && <GreenButton onClick={goNext}>Continue</GreenButton>}
      </Box>

      <GoBackDialog
        open={open}
        cancelAction={cancelAction}
        okAction={okAction}
      />
    </StepLayout>
  );
};

SitePicker.propTypes = {
  findSitesResult: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  resetFindSitesResult: PropTypes.func.isRequired,
  findSites: PropTypes.func.isRequired,
  manufacturer: PropTypes.string.isRequired,
  credentials: PropTypes.shape({}),
  showAppMessage: PropTypes.func.isRequired,
  addSites: PropTypes.func.isRequired,
  addSitesResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  resetAll: PropTypes.func.isRequired,

  // ie. a renderable
  stepLayout: PropTypes.func.isRequired,

  // override the back and next button actions
  backAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,

  setSitePickerTableState: PropTypes.func.isRequired,
  sitePickerTableState: PropTypes.shape({}).isRequired,
};

export default SitePicker;
