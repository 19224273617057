import React from "react";
import { Stack, Box, IconButton, Link } from "@mui/material";
import { map } from "lodash";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProjectDocument } from "../../actions";

const ProjectDocumentList = ({ documents, project, editable }) => (
  <Stack mr={2}>
    {map(documents, (doc) => (
      <ProjectDocument
        doc={doc}
        project={project}
        key={doc.id}
        editable={editable}
      />
    ))}
  </Stack>
);

const ProjectDocument = ({ doc, project, editable }) => {
  const showAppMessage = useAppMessage();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const downloadDoc = async (doc) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(doc, accessToken);
    } catch (err) {
      showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      });
    }
  };

  const deleteDoc = async (document) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });
    dispatch(deleteProjectDocument({ document, project, accessToken }));
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Link
        variant="body1"
        onClick={() => downloadDoc(doc)}
        sx={{ cursor: "pointer", overflowWrap: "anywhere" }}
      >
        {doc.name}
      </Link>
      {editable && (
        <IconButton
          color="primary"
          aria-label="delete doc"
          onClick={() => deleteDoc(doc)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ProjectDocumentList;
