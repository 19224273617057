import { call, put, takeLatest, select } from "redux-saga/effects";
import { patchAppSiteOwnership } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getApplication } from "../selectors";
import { omit, lowerCase } from "lodash";
import { mapSiteOwner } from "./getApplication";

const callApi = async (accessToken, application) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${application.id}`,
    {
      application,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const prepareApplicationRequest = (siteOwnership, application) => {
  const siteOwnerInfo =
    siteOwnership.siteOwner[lowerCase(siteOwnership.ownerType)];
  return {
    id: application.id,
    asset: {
      equipmentOwnership: siteOwnership.equipmentOwnership,
      fundingSource: siteOwnership.fundingSource,
    },
    siteOwner:
      siteOwnership.ownerType === "ORGANIZATION"
        ? { orgId: siteOwnership.organization.id }
        : siteOwnership.ownerType === "EXTERNAL"
        ? {
            ...omit(siteOwnerInfo, ["firstName", "lastName"]),
            givenName: siteOwnerInfo.firstName,
            familyName: siteOwnerInfo.lastName,
            roles: ["OWNER"],
          }
        : undefined,
  };
};

function* patchAppSiteOwnershipWorker(action) {
  yield put(patchAppSiteOwnership.request());

  const { accessToken, siteOwnership } = action.payload;
  const app = yield select(getApplication);

  try {
    const response = yield call(
      callApi,
      accessToken,
      prepareApplicationRequest(siteOwnership, app)
    );
    yield put(
      patchAppSiteOwnership.success({
        application: {
          ...response.data,
          siteOwner: mapSiteOwner(response.data),
        },
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")(
        "Error updating application site ownership: ",
        err.response
      );
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")(
        "Error updating application site ownership: ",
        err.request
      );
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")(
        "Error updating application site ownership: ",
        err
      );
      message = err.message;
    }
    message = `Unable to update application site ownership: ${message}`;
    yield put(patchAppSiteOwnership.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchAppSiteOwnershipWatcher() {
  yield takeLatest(patchAppSiteOwnership.TRIGGER, patchAppSiteOwnershipWorker);
}

export default [patchAppSiteOwnershipWatcher];
