import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import TermsAndConditions from "@components/TermsAndConditions";
import Page from "@components/Page";
import { Container, Box, Typography } from "@mui/material";
import RewattTermsPDF from "@assets/ReWatt - Account Terms and Conditions - v.3.pdf";

const ApplyTermsAndConditions = ({
  getUserProfileResult,
  getProgramResult,
  application,
  updateUserProfile,
  updateUserProfileResult,
  showAppMessage,
  resetUpdateUserProfile,
}) => {
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const { program } = getProgramResult;
  const { userProfile } = getUserProfileResult;

  // submit - distinct from the program T&C
  const submit = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      updateUserProfile({
        accessToken,
        acceptedTermsAndConditions: true,
      });
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
    }
  };

  // submission progress and action
  const isSubmitting = updateUserProfileResult.status === "request";
  useEffect(() => {
    if (userProfile.acceptedTermsAndConditions && updateUserProfileResult.status === "success") {
      resetUpdateUserProfile();
      navigate("/apply/start");
    }
  }, [
    userProfile.acceptedTermsAndConditions,
    navigate,
    resetUpdateUserProfile,
    program,
    updateUserProfileResult.status,
  ]);

  return (
    <Page title="Terms and Conditions" py={3}>
      <Container maxWidth="xl">
        <Box ml={10} mr={10}>
          <Typography variant="h2">
            Welcome to {program.name} Registration!
          </Typography>
          <Typography variant="h4">
            Before you continue, please read below.
          </Typography>
          <TermsAndConditions
            pdf={{
              url: RewattTermsPDF,
              name: "Rewatt Terms and Conditions.pdf",
            }}
            acceptTermsAndConditions={submit}
            isSubmitting={isSubmitting}
            submitActionText="Continue"
          />
        </Box>
      </Container>
    </Page>
  );
};

ApplyTermsAndConditions.propTypes = {
  getProgramResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  getUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  application: PropTypes.shape({
    programId: PropTypes.number,
  }).isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  showAppMessage: PropTypes.func.isRequired,
  resetUpdateUserProfile: PropTypes.func.isRequired,
};

export default ApplyTermsAndConditions;
