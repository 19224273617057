import { call, put, takeLatest } from "redux-saga/effects";
import { getProjectApplication } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, customerProjectId, applicationId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/applications/${applicationId}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getProjectApplicationWorker(action) {
  yield put(getProjectApplication.request());

  const { accessToken, customerProjectId, applicationId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, customerProjectId, applicationId);
    yield put(getProjectApplication.success(response.data));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get application: ${message}`;
    yield put(getProjectApplication.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProjectApplicationWatcher() {
  yield takeLatest(getProjectApplication.TRIGGER, getProjectApplicationWorker);
}

export default [getProjectApplicationWatcher];
