import React from "react";
import PropTypes from "prop-types";
import { Typography, Stack, Box, IconButton } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";

const StatusStep = ({ title, subtitle, isEnd, incomplete, onChangeStatus, disabled, children, ...others }) => (
  <Stack sx={{ minWidth: 250 }} {...others}>
    <Typography variant="h6">{title}</Typography>
    {incomplete ? (
      <Typography sx={{ fontStyle: "italic" }} color="textSecondary">
        Incomplete
      </Typography>
    ) : (
      <Typography>{subtitle}</Typography>
    )}
    <Box display="flex" direction="row" alignItems="center">
      <IconButton
        color="primary"
        aria-label="created"
        disabled={!incomplete || disabled}
        onClick={onChangeStatus}
      >
        {incomplete ? <RadioButtonUncheckedIcon /> : <CircleIcon />}
      </IconButton>
      {!isEnd && (
        <Box
          sx={{
            flex: "1 1 auto",
            borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
          }}
        />
      )}
    </Box>
    {!incomplete && children}
  </Stack>
);

StatusStep.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isEnd: PropTypes.bool,
  incomplete: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func,
  children: PropTypes.node,
};

export default StatusStep;
