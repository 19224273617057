import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SiteProfileEditor from "../components/SiteProfileEditor";
import { patchAppSiteProfile } from "../actions";

const mapStateToProps = ({ patchAppSiteProfile }) => ({
  patchAppSiteProfileResult: patchAppSiteProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchAppSiteProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiteProfileEditor);
