import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  FormControl,
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isNil, isEmpty, every } from "lodash";
import UtilityComboBox from "./UtilityComboBox";
import FundingSourceComboBox from "./FundingSourceComboBox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// everything entered
export const areSiteDetailsComplete = (siteDetails) => {
  let isComplete = every(
    ["utility", "customerId", "equipmentOwnership", "mountingType"],
    (prop) => !isEmpty(siteDetails[prop])
  );
  // hasReceivedGrants and fundingSource
  isComplete = isComplete && !isNil(siteDetails.hasReceivedGrants);
  if (siteDetails.hasReceivedGrants) {
    isComplete = isComplete && !isEmpty(siteDetails.fundingSource);
  }
  return isComplete;
};

// the easily entered fields
export const areSiteDetailsDone = (siteDetails) => {
  let isComplete = every(
    ["utility", "equipmentOwnership", "mountingType"],
    (prop) => !isEmpty(siteDetails[prop])
  );
  // hasReceivedGrants
  isComplete = isComplete && !isNil(siteDetails.hasReceivedGrants);
  return isComplete;
};

const SiteDetailsCellEditor = ({
  id,
  value: initialSiteDetails,
  api,
  field,
}) => {
  const [open, setOpen] = useState(true);
  const [siteDetails, setSiteDetails] = useState(initialSiteDetails);

  const handleCloseEditor = () => {
    setSiteDetails(initialSiteDetails);
    setOpen(false);
    api.setCellMode(id, field, "view");
  };

  const handleSave = async () => {
    api.setEditCellValue({ id, field, value: siteDetails });
    const isValid = await api.commitCellChange({ id, field });
    if (isValid) {
      setOpen(false);
      api.setCellMode(id, field, "view");
    }
  };

  const handleChange = (props) => {
    setSiteDetails({
      ...siteDetails,
      ...props,
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseEditor}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseEditor}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Site Details
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Box m={4}>
        <form noValidate autoComplete="off">
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                What is the name of your utility?
              </Typography>
              <UtilityComboBox
                utility={siteDetails.utility}
                setUtility={handleChange}
                sx={{ minWidth: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Existing Customer ID"
                placeholder="90350-123"
                helperText="A reference account number or customer id."
                value={siteDetails.customerId}
                onChange={(event) =>
                  handleChange({ customerId: event.target.value })
                }
                variant="standard"
                sx={{ minWidth: 300 }}
              />
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                What type of contract do you have?
              </Typography>
              <FormControl  sx={{ minWidth: 200 }}>
                <ToggleButtonGroup
                  value={siteDetails.equipmentOwnership}
                  exclusive
                  onChange={(event, equipmentOwnership) =>
                    handleChange({ equipmentOwnership })
                  }
                  aria-label="What type of contract do you have?"
                  fullWidth
                >
                  <ToggleButton
                    value="LEASE"
                    aria-label="LEASE"
                    size="small"
                  >
                    Lease
                  </ToggleButton>
                  <ToggleButton
                    value="FREEHOLD"
                    aria-label="FREEHOLD"
                    size="small"
                  >
                    Freehold
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Have you received grants or funding for your site?
              </Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <ToggleButtonGroup
                  value={siteDetails.hasReceivedGrants}
                  exclusive
                  onChange={(event, hasReceivedGrants) =>
                    handleChange({ hasReceivedGrants })
                  }
                  aria-label="Have you received grants or funding for your site?"
                  fullWidth
                >
                  <ToggleButton
                    value={true}
                    aria-label="yes"
                    size="small"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value={false}
                    aria-label="no"
                    size="small"
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
            {siteDetails.hasReceivedGrants && (
              <Grid item xs={12} md={6}>
                <FundingSourceComboBox
                  fundingSource={siteDetails.fundingSource}
                  setFundingSource={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12} />

            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                Mounting Type
              </Typography>
              <FormControl sx={{ minWidth: 300 }}>
                <ToggleButtonGroup
                  value={siteDetails.mountingType}
                  exclusive
                  onChange={(event, mountingType) =>
                    handleChange({ mountingType })
                  }
                  aria-label="Mounting Type"
                  size="small"
                  fullWidth
                >
                  <ToggleButton
                    value="GROUND"
                    aria-label="ground"
                    size="small"
                  >
                    Ground
                  </ToggleButton>
                  <ToggleButton
                    value="ROOFTOP"
                    aria-label="rooftop"
                    size="small"
                  >
                    Rooftop
                  </ToggleButton>
                  <ToggleButton
                    value="TRACKING"
                    aria-label="tracking"
                    size="small"
                  >
                    Tracking
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>{" "}
      </Box>
    </Dialog>
  );
};

SiteDetailsCellEditor.propTypes = {
  /**
   * GridApi that let you manipulate the grid.
   */
  api: PropTypes.object.isRequired,
  /**
   * The column field of the cell that triggered the event.
   */
  field: PropTypes.string.isRequired,
  /**
   * The grid row id.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.shape({
    utility: PropTypes.string,
    customerId: PropTypes.string,
    equipmentOwnership: PropTypes.string,
    hasReceivedGrants: PropTypes.bool,
    fundingSource: PropTypes.string,
    mountingType: PropTypes.string,
  }).isRequired,
};

export default SiteDetailsCellEditor;
