import {
  reset, showAppMessage,
} from '../actions';

export const initialState = {
  open: false,
  message: '',
  severity: 'info',
  duration: null, // null for infinite, otherwise ms
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case reset.TYPE: {
      return {
        ...initialState,
        // keep the severity, so that we don't get the flash when closing the message
        // make sure you set it when showing, otherwise you get the last severity
        severity: state.severity,
      };
    }
    case showAppMessage.TYPE: {
      const { message, severity, duration } = action.payload;
      return {
        ...state,
        open: true,
        message,
        severity: severity || initialState.severity,
        duration: duration === undefined ? initialState.duration : duration,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
