import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmRegistrationDialog = ({ open, cancelAction, okAction }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={cancelAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Project Report Registration</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" gutterBottom>
            The credits generated in this Project Report are ready to be sold. 
            To complete the transaction, however, relevant payees must have up-to-date bank information.
          </DialogContentText>
          <DialogContentText>
            This action will send an email to those payees, reminding them to update their bank information,
            and that payment is imminent.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mr: 2, mb: 1 }}>
          <Button onClick={cancelAction} variant="text">
            Cancel
          </Button>
          <Button onClick={okAction} color="primary" variant="contained" autoFocus>
            Register Project Report
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmRegistrationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func.isRequired,
  okAction: PropTypes.func.isRequired,
};

export default ConfirmRegistrationDialog;
