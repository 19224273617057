import { call, put, takeLatest } from "redux-saga/effects";
import { getLiveChartData } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { transform } from "@views/site/sagas/getSiteChart";
import { includes, pickBy } from "lodash";

const callApi = async (siteId, startDate, endDate) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/public/assets/${siteId}/chart`,
    {
      params: {
        start: startDate.toISODate(),
        end: endDate.toISODate(),
      },
    }
  );
  return response;
};

export function* getLiveChartDataWorker(action) {
  yield put(getLiveChartData.request());

  const { siteId, startDate, endDate } = action.payload;

  try {
    const response = yield call(callApi, siteId, startDate, endDate);
    const { data, granularity } = response.data;
    const transformed = transform(data, granularity, [startDate, endDate]);
    yield put(
      getLiveChartData.success({
        data: pickBy(transformed, (series) =>
          includes(
            ["generation", "export", "consumption", "emissionReductions"],
            series.stat
          )
        ),
        granularity,
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting chart data: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting chart data: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting chart data: ", err);
      message = err.message;
    }
    message = `Unable to get chart data: ${message}`;
    yield put(getLiveChartData.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getLiveChartDataWatcher() {
  yield takeLatest(getLiveChartData.TRIGGER, getLiveChartDataWorker);
}

export default [getLiveChartDataWatcher];
