import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Container,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import GreenButton from "@components/GreenButton";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import TermsUploader from "@views/programs/components/TermsUploader";
import { useAppMessage } from "@components/appMessage";
import { useDispatch, useSelector } from "react-redux";
import { approveApplication } from "../actions";

export const schema = yup.object().shape({
  externalTermsAndConditions: yup.object().required("A terms and conditions document is required"),
});

const ApproveWithTermsDialog = ({
  open,
  onClose,
  applicationId,
  notifyApplicant,
}) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  const { handleSubmit, errors, control, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      externalTermsAndConditions: null, // filespec
    },
  });

  // submit
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { externalTermsAndConditions } = data;
    dispatch(approveApplication({
      accessToken,
      applicationId,
      externalTermsAndConditions,
      notifyApplicant,
    }));
  };

  const { status } = useSelector(state => state.approveApplication);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  useEffect(() => {
    if (isSuccess) {
      showAppMessage({
        severity: "success",
        message: "Application approved successfully.",
        duration: 2000,
      });
    }
  }, [isSuccess, showAppMessage]);

  const onContinue = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Program Override
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <form noValidate autoComplete="off">
          <Box mx={5} my={3}>
            <Typography gutterBottom>
              This application is missing the site owner’s acceptance of your program’s terms and
              conditions. This agreement was sent to their email address when the application was
              submitted.
            </Typography>
            <Typography mb={3}>
              If you wish to override, please upload a PDF or image as proof of external acceptance
              by the site owner.
            </Typography>
            <Controller
              render={({ onChange, value }) => (
                <TermsUploader
                  onChange={onChange}
                  value={value}
                  error={errors.externalTermsAndConditions?.message}
                />
              )}
              control={control}
              name="externalTermsAndConditions"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" m={5}>
            {isSuccess && (
              <GreenButton size="large" onClick={onContinue}>
                Continue
              </GreenButton>
            )}
            {!isSuccess && (
              <LoadingButton
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Approve
              </LoadingButton>
            )}
          </Box>
        </form>
      </Container>
    </Dialog>
  );
};

ApproveWithTermsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  applicationId: PropTypes.number.isRequired,
  notifyApplicant: PropTypes.bool.isRequired,
};

export default ApproveWithTermsDialog;
