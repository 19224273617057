import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Typography, Grid, Box, Button, Card, CardContent } from "@mui/material";
import { includes } from "lodash";
import Page from "@components/Page";
import PageLoader from "@components/PageLoader";
import { useLocation, useParams } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  UserCircleIcon,
  LightningBoltIcon,
  DocumentDownloadIcon,
} from "@heroicons/react/solid";
import Image from "@jy95/material-ui-image";
import { useAuth0 } from "@auth0/auth0-react";
import { getDims } from "@utils/imageHelpers";
import { useResetHistory } from "./useNextPrev";
import { useDispatch, useSelector } from "react-redux";
import { getOrganization, getUserProfile } from "@views/common/actions";
import { getMyApplications } from "@views/myApplications/actions";
import notFoundImg from "@assets/undraw_page_not_found_su7k.svg";
import { application as appActions } from "../actions";
import { getCustomerProject } from "../../CustomerProjects/actions";

const GuideCard = ({ icon: Icon, title, children }) => (
  <Card variant="elevation" square sx={{ minWidth: 275, minHeight: 275 }}>
    <CardContent sx={{ p: 4 }}>
      <Box display="flex" justifyContent="center" m={2}>
        <Icon style={{ height: 80 }} />
      </Box>
      <Typography gutterBottom variant="h4">
        {title}
      </Typography>
      <Typography>
        {children}
      </Typography>
    </CardContent>
  </Card>
);

GuideCard.propTypes = {
  // hero icons ignore fontSize, use dims instead
  icon: PropTypes.object.isRequired,

  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Welcome = () => {
  const pathParams = useParams();
  const customerProjectId = parseInt(pathParams.customerProjectId);
  const resetHistory = useResetHistory();
  const dispatch = useDispatch();

  const { loginWithRedirect } = useAuth0();
  const signup = () => {
    loginWithRedirect({
      redirectUri: window.location.origin, // callback to process ?code
      appState: { customerProjectId, returnTo: `projects/apply/start` },
      screen_hint: "signup",
    });
  };

  useEffect(() => {
    dispatch(appActions.save({ customerProjectId }));
  }, [dispatch, customerProjectId]);

  useEffect(() => {
    dispatch(getUserProfile.reset());
    dispatch(getOrganization.reset());
    dispatch(getMyApplications.reset());
    dispatch(getCustomerProject.reset());
    resetHistory();
  }, [dispatch, resetHistory]);

  // save the referrer if we have one
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referrer = params.get("referrer");
  useEffect(() => {
    if (referrer) {
      dispatch(appActions.save({ referrer }));
    }
  }, [referrer, dispatch]);


  const [aspectRatio, setAspectRatio] = React.useState(1);
  const imgBoxDim = 225 - 2 * 8;

  // get the customer project we're applying to
  useEffect(() => {
    dispatch(getCustomerProject({ customerProjectId }));
  }, [dispatch, customerProjectId]);
  const { customerProject, status, error } = useSelector(state => state.getCustomerProject);
  const isLoading = includes(["init", "request"], status);

  return (
    <Page title="Welcome to Rewatt" py={3} bgcolor="common.white">
      <Container maxWidth="xl">
        {isLoading && <PageLoader message="Just a moment..." />}
        {!isLoading && !error && (
          <>
            <Box ml={10} mr={10}>
              <Grid container>
                <Grid item xs={12}>
                  {/* row 1 */}
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignContent="stretch"
                    alignItems="center"
                  >
                    {/* position image in center */}
                    <Box
                      style={{
                        flex: `0 0 225px`,
                        boxShadow: "3px 3px 8px 2px rgb(0 0 0 / 15%)",
                        padding: 8,
                      }}
                    >
                      <Image
                        src={customerProject?.logo?.uri}
                        imageStyle={getDims(aspectRatio, imgBoxDim)}
                        onLoad={(e) => {
                          if (e?.target) {
                            setAspectRatio(
                              e.target.naturalWidth / e.target.naturalHeight
                            );
                          }
                        }}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </Box>

                    {/* title */}
                    <Box flex={1}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h2" pl={12}>
                          Join {customerProject.customer?.name}'s {customerProject.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* end of row 1 */}

                <Grid item xs={12}>
                  <Box mt={8}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mb: 3,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {customerProject.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <KeyboardArrowDownIcon
                      sx={{
                        fontSize: 80,
                        strokeWidth: 1,
                        color: "grey.300",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item xs={12} sx={{ bgcolor: "#EAEFF1" }}>
                <Box m={6} ml={10} mr={10}>
                  <Typography variant="h6" sx={{ textTransform: "uppercase" }}>
                    {customerProject.title}
                  </Typography>
                  <Typography variant="h1" sx={{ my: 4 }}>
                    Connection Starts Here
                  </Typography>
                  <Typography variant="subtitle1">
                    This process will guide you through 3 important steps:
                    filling in your contact information,
                    connecting your site to the measurement portal, and making
                    sure you provide all the essential site documents for site
                    verification.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ bgcolor: "primary.main" }}>
                <Box m={6} ml={10} mr={10}>
                  <Typography
                    variant="h4"
                    sx={{
                      textTransform: "uppercase",
                      color: "common.white",
                      mb: 6,
                    }}
                  >
                    We will guide you to complete the following:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <GuideCard
                        icon={UserCircleIcon}
                        title="Create an Account"
                      >
                        First step is easy. <br /> Fill in your / your company's{" "}
                        <strong>contact information</strong>.
                      </GuideCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <GuideCard
                        icon={LightningBoltIcon}
                        title="Connect the Site"
                      >
                        Have ready the device credentials to{" "}
                        <strong>connect</strong> the site portal.
                      </GuideCard>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <GuideCard
                        icon={DocumentDownloadIcon}
                        title="Provide Documents"
                      >
                        Documents need to be provided for application approval.
                      </GuideCard>
                    </Grid>
                  </Grid>
                  <Box mt={6} justifyContent="flex-end" display="flex">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={signup}
                      sx={{ color: "common.white" }}
                    >
                      Start Application
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        {!isLoading && error && (
          <Page title="Project not available" py={3}>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
              <Container maxWidth="md">
                <Typography align="center" color="textPrimary" variant="h1">
                  Can’t submit application at this time.
                </Typography>
                <Typography align="center" color="textPrimary" variant="subtitle2">
                  The project you are submitting an application to either does not exist or is not open to applications at this time.
                  Whichever it is, check the URL that brought you here.
                </Typography>
                <Box textAlign="center" mt={6} sx={{ img: { display: "inline-block", width: 560 } }}>
                  <img alt="Under development" src={notFoundImg} />
                </Box>
              </Container>
            </Box>
          </Page>
        )}
      </Container>
    </Page>
  );
};

export default Welcome;
