import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { includes } from "lodash";
import TermsAndConditions from "@components/TermsAndConditions";
import Page from "@components/Page";
import { Container } from "@mui/material";
import RewattTermsPDF from "@assets/ReWatt - Account Terms and Conditions - v.3.pdf";

const InviteTermsAndConditions = ({
  acceptInvite,
  acceptInviteResult,
  showAppMessage,
  getUserProfile,
  getUserProfileResult,
}) => {
  const navigate = useNavigate();

  // has orgId from welcome page (set in onRedirectCallback)
  const { state } = useLocation();

  const { getAccessTokenSilently, user } = useAuth0();

  // submit
  const acceptTermsAndConditions = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      acceptInvite({
        accessToken,
        inviteId: state.inviteId,
        organizationId: state.orgId,
        acceptedTermsAndConditions: true,
      });
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
    }
  };

  // get user profile, needed for orgId in dashboard
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getUserProfile({ accessToken, userId: user.sub });
    }

    if (acceptInviteResult.status === "success") {
      fetchUserProfile();
    }
  }, [
    acceptInviteResult,
    user,
    getAccessTokenSilently,
    getUserProfile,
  ]);

  // go to dashboard on success
  useEffect(() => {
    if (
      acceptInviteResult.status === "success" &&
      getUserProfileResult.status === "success"
    ) {
      const key = `${user.sub}-skipToDashboard`;
      localStorage.setItem(key, true);
      navigate("/dashboard");
    }
  }, [navigate, acceptInviteResult, getUserProfileResult, user]);

  const isSubmitting =
    includes(["request"], getUserProfileResult.status) ||
    includes(["request"], acceptInviteResult.status);

  return (
    <Page title="Terms and Conditions">
      <Container maxWidth={false}>
        <TermsAndConditions
          pdf={{
            url: RewattTermsPDF,
            name: "Rewatt Terms and Conditions.pdf",
          }}
          acceptTermsAndConditions={acceptTermsAndConditions}
          isSubmitting={isSubmitting}
        />
      </Container>
    </Page>
  );
};

InviteTermsAndConditions.propTypes = {
  showAppMessage: PropTypes.func.isRequired,
  acceptInvite: PropTypes.func.isRequired,
  acceptInviteResult: PropTypes.shape({}).isRequired,
  getUserProfile: PropTypes.func.isRequired,
  getUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default InviteTermsAndConditions;
