import React from "react";
import {
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
  Typography,
  Box,
  Skeleton,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  TableCell,
} from "@mui/material";
import { isNil, find, isNaN, round } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { patchCustomerProjectSite } from "../actions";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { Navigation } from "components";
import DialogSaveButton from "components/DialogSaveButton";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import FundingSourceComboBox from "@views/site/components/details/FundingSourceComboBox";
import RequiredField from "views/common/components/RequiredField";

const CHARGING_TYPES = [
  {
    enum: 'RESIDENTIAL',
    readable: 'Single Dwelling Residential',
  },
  {
    enum: 'MULTIUNIT_RESIDENTIAL',
    readable: 'Multi-Unit Residential',
  },
  {
    enum: 'PUBLIC',
    readable: 'Public Service Station',
  },
  {
    enum: 'FLEET',
    readable: 'Private Fleet',
  },
  {
    enum: 'COMMERCIAL_PRIVATE',
    readable: 'Commercial Private',
  },
  {
    enum: 'COMMERCIAL_PUBLIC',
    readable: 'Commercial Public',
  }
];

const EQUIPMENT_OWNERSHIP = [
  {
    enum: "LEASE",
    readable: "Lease"
  },
  {
    enum: "FREEHOLD",
    readable: "Freehold"
  }
];

const MOUNTING_TYPE = [
  {
    enum: "GROUND",
    readable: "Ground"
  },
  {
    enum: "ROOFTOP",
    readable: "Rooftop"
  },
  {
    enum: "TRACKING",
    readable: "Tracking"
  }
];

const evFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(60, "Too long.")
    .required("Site name is required."),
  fuelSupplier: Yup.string(),
  chargingType: Yup.string(),
});

export const solarFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(60, "Too long.")
    .required("Site name is required."),
  capacity: Yup.number().transform((value) => isNaN(value) ? undefined : round(value * 1000)).min(0).required("Capacity is required."),
  isBuilt: Yup.string().oneOf(['Yes', 'No']).typeError('You must specify if your site has been built.'),
  completionDate: Yup.date().required("Installation date is required.").typeError('You must specify an installtion date.'),
  hasReceivedGrants: Yup.string().oneOf(['Yes', 'No']).typeError('You must specify if you have received grant funding.'),
  fundingSource: Yup.string().when('hasReceivedGrants', {
    is: 'Yes',
    then: (schema) => schema.required("Funding source is required"),
  }),
  utility: Yup.string(),
  customerId: Yup.string(),
  equipmentOwnership: Yup.string(),
  mountingType: Yup.string(),
  cost: Yup.number().integer().transform((value) => isNaN(value) ? undefined : value),
});

/**
 * Standard form for inputting ev/lcf site details
 * - must wrap this in <FormContext> to function properly
 * - caller/parent is responsible for form management
 *
 * @param {*} param0 
 * @returns 
 */
export const EVSiteDetailsForm = ({ disabled }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Site Name"
              variant="standard"
              fullWidth
              error={!isNil(errors.name?.message)}
              helperText={errors.name?.message}
              required
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="name"
          rules={{
            required: 'Site name is required',
            max: {
              value: 60,
              message: 'Site name is too long.'
            }
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Electric Utility Supplier"
              variant="standard"
              fullWidth
              error={!isNil(errors.fuelSupplier?.message)}
              helperText={errors.fuelSupplier?.message}
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="fuelSupplier"
          rules={{
            required: 'Electricity supplier is required',
            max: {
              value: 250,
              message: 'Electricity supplier is too long.'
            }
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <FormControl variant="standard" sx={{ width: "100%" }} disabled={disabled}>
              <InputLabel id={`ev-charging-type-label-id`} color="secondary">
                Charging Type
              </InputLabel>
              <Select
                labelId={`ev-charging-type-selection-label`}
                id={`ev-charging-type-selection-id`}
                value={value}
                label="Charging Type"
                onChange={onChange}
              >
                {CHARGING_TYPES.map(type => (<MenuItem key={type.enum} value={type.enum}>{type.readable}</MenuItem>))}
              </Select>
            </FormControl>
          )}
          control={control}
          name="chargingType"
        />
      </Grid>
    </Grid>
  );
};

export const EVSiteDetails = ({ nextAction, save, siteDetails }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(evFormSchema),
    defaultValues: {
      name: siteDetails?.name || "",
      fuelSupplier: siteDetails?.fuelSupplier || "",
      chargingType: siteDetails?.chargingType || "",
    }
  });
  const onSubmit = details => {
    save(details);
    nextAction();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <EVSiteDetailsForm />
        <Navigation
          backAction={null}
          nextAction={() => methods.handleSubmit(onSubmit)()}
          nextActionText="Continue"
        />
      </form>
    </FormProvider>
  );
};

/**
 * Standard form for inputting solar site details
 * - must wrap this in <FormContext> to function properly
 * - caller/parent is responsible for form management
 *
 * @param {*} param0 
 * @returns 
 */
export const SolarSiteDetailsForm = ({ disabled }) => {
  const { errors, watch, control } = useFormContext();
  const hasReceivedGrants = watch("hasReceivedGrants");
  const isBuilt = watch("isBuilt");
  const isBuiltSelected = isBuilt === 'Yes' || isBuilt === 'No';
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Site Name"
              variant="standard"
              fullWidth
              error={!isNil(errors.name?.message)}
              helperText={errors.name?.message}
              required
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="name"
          rules={{
            required: 'Site name is required',
            max: {
              value: 60,
              message: 'Site name is too long.'
            }
          }}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Capacity (kW)"
              variant="standard"
              fullWidth
              type="number"
              required
              error={!isNil(errors.capacity?.message)}
              helperText={errors.capacity?.message}
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="capacity"
          rules={{
            required: 'Capacity is required',
            min: {
              value: 0,
              message: 'Capacity must be greater than 0 kW.'
            }
          }}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          render={({ onChange, value }) => (
            <FormControl variant="standard" sx={{ width: "100%" }} disabled={disabled}>
              <InputLabel id="utility-label-id" color="secondary">
                Electricity Distribution Company
              </InputLabel>
              <Select
                labelId="utility-label"
                id="utility-id"
                value={value}
                label="Electricity Distribution Company"
                onChange={onChange}
              >
                {[
                  "ENMAX Power",
                  "EPCOR",
                  "ATCO",
                  "Fortis",
                  "EQUS",
                  "City of Medecine Hat",
                  "City of Lethbridge",
                  "City of Red Deer",
                  "Other",
                ].map(utility => (<MenuItem key={utility} value={utility}>{utility}</MenuItem>))}
              </Select>
            </FormControl>
          )}
          control={control}
          name="utility"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Site ID"
              variant="standard"
              fullWidth
              error={!isNil(errors.customerId?.message)}
              helperText={errors.customerId?.message ?? "Your 13 digit utility site ID"}
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="customerId"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          render={({ onChange, value }) => (
            <FormControl variant="standard" sx={{ width: "100%" }} disabled={disabled}>
              <InputLabel id="equipment-ownership-label-id" color="secondary">
                Equipment Ownership
              </InputLabel>
              <Select
                labelId="equipment-ownership-label"
                id="equipment-ownership-id"
                value={value}
                label="Equipment OWnership"
                onChange={onChange}
              >
                {EQUIPMENT_OWNERSHIP.map(type => (<MenuItem key={type.enum} value={type.enum}>{type.readable}</MenuItem>))}
              </Select>
            </FormControl>
          )}
          control={control}
          name="equipmentOwnership"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          render={({ onChange, value }) => (
            <FormControl variant="standard" sx={{ width: "100%" }} disabled={disabled}>
              <InputLabel id="mounting-type-label-id" color="secondary">
                Mounting Type
              </InputLabel>
              <Select
                labelId="mounting-type-label"
                id="mounting-type-id"
                value={value}
                label="Mounting Type"
                onChange={onChange}
              >
                {MOUNTING_TYPE.map(type => (<MenuItem key={type.enum} value={type.enum}>{type.readable}</MenuItem>))}
              </Select>
            </FormControl>
          )}
          control={control}
          name="mountingType"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Cost"
              variant="standard"
              fullWidth
              type="number"
              error={!isNil(errors.cost?.message)}
              helperText={errors.cost?.message}
              disabled={disabled}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="cost"
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={1}>
          <FormControl
            component="fieldset"
            error={!isNil(errors.isBuilt?.message)}
          >
            <FormLabel>
              Is your system built and operational?
            </FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <RadioGroup
                  aria-label="Is Built"
                  name="isBuilt"
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              )}
              control={control}
              name="isBuilt"
            />
            {!isNil(errors.isBuilt?.message) && (
              <FormHelperText>{errors.isBuilt.message}</FormHelperText>
            )}
          </FormControl>
          {isBuiltSelected && (
            <Controller
              render={({ onChange, value }) => (
                <Box>
                  <DatePicker
                    label={`${isBuilt === 'Yes' ? 'Installation' : 'Expected Installation' } Date`}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    disableFuture={isBuilt === 'Yes'}
                    disablePast={isBuilt !== 'Yes'}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        fullWidth
                        {...params}
                        error={!isNil(errors.completionDate?.message)}
                        helperText={errors.completionDate?.message || ""}
                      />
                    )}
                    disableMaskedInput
                  />
                </Box>
              )}
              control={control}
              name="completionDate"
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1}>
          <FormControl
            component="fieldset"
            error={!isNil(errors.hasReceivedGrants?.message)}
          >
            <FormLabel>
              I attest that <em>no</em> funding/grants were received from any third-parties that may take ownership of offsets generated from this site
            </FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <RadioGroup
                  aria-label="Received grants"
                  name="hasReceivedGrants"
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="I have not received a grant"
                  />
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="I have received a grant"
                  />
                </RadioGroup>
              )}
              control={control}
              name="hasReceivedGrants"
            />
            {!isNil(errors.hasReceivedGrants?.message) && (
              <FormHelperText>{errors.hasReceivedGrants.message}</FormHelperText>
            )}
          </FormControl>
          {hasReceivedGrants === "Yes" && (
            <Controller
              render={({ onChange, value }) => (
                <FundingSourceComboBox
                  value={value}
                  onChange={onChange}
                  readOnly={false}
                  errorMessage={errors?.fundingSource?.message}
                />
              )}
              control={control}
              name="fundingSource"
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export const SolarSiteDetails = ({ nextAction, save, siteDetails }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(solarFormSchema),
    defaultValues: {
      name: siteDetails?.name || "",
      isBuilt: !isNil(siteDetails?.isBuilt)
        ? (siteDetails.isBuilt ? 'Yes' : 'No')
        : "",
      completionDate: siteDetails?.completionDate ? DateTime.fromISO(siteDetails.completionDate).toJSDate() : null,
      capacity: siteDetails?.capacity / 1000 || "",
      utility: siteDetails?.utility || "",
      customerId: siteDetails?.customerId || "",
      equipmentOwnership: siteDetails?.equipmentOwnership || "",
      mountingType: siteDetails?.mountingType || "",
      cost: siteDetails?.cost || "",
      hasReceivedGrants: !isNil(siteDetails?.hasReceivedGrants)
        ? (siteDetails.hasReceivedGrants ? 'Yes' : 'No')
        : "",
      fundingSource: siteDetails?.fundingSource || "",
    }
  });
  const onSubmit = details => {
    save({
      ...details,
      isBuilt: details.isBuilt === 'Yes' ? true : false,
      hasReceivedGrants: details.hasReceivedGrants === 'Yes' ? true : false,
      fundingSource: details.hasReceivedGrants === 'Yes' ? details.fundingSource : '',
      completionDate: DateTime.fromJSDate(details.completionDate).toISO(),
    });
    nextAction();
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <SolarSiteDetailsForm />
        <Navigation
          backAction={null}
          nextAction={() => methods.handleSubmit(onSubmit)()}
          nextActionText="Continue"
        />
      </form>
    </FormProvider>
  );
};

export const AddDetails = ({ customerProject, ...props }) => {
  switch (customerProject.emissionReductionActivity) {
  case 'LOW_CARBON_FUEL':
    return <EVSiteDetails {...props} />;
  case 'SOLAR_ELECTRICITY_POWER_GENERATION':
    return <SolarSiteDetails {...props} />;
  default:
    return null;
  };
};

export const transformCompletionDate = (completionDate) => DateTime.fromISO(completionDate).toISODate();
export const transformHasReceivedGrants = (hasReceivedGrants) => hasReceivedGrants === 'Yes' ? true : false;

export const EditDetails = ({
  customerProject,
  site,
  close,
}) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // setup the form
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(customerProject.emissionReductionActivity === 'LOW_CARBON_FUEL' ? evFormSchema : solarFormSchema),
    defaultValues: customerProject.emissionReductionActivity === 'LOW_CARBON_FUEL' ? {
      name: site?.name || "",
      fuelSupplier: site?.fuelSupplier || "",
      chargingType: site?.chargingType || "",
    } : {
      name: site?.name || "",
      capacity: site?.capacity ? site.capacity / 1000 : "",
      isBuilt: site?.completionDate ? (DateTime.fromISO(site.completionDate) < DateTime.now() ? 'Yes' : 'No') : "",
      completionDate: site?.completionDate ? DateTime.fromISO(site.completionDate) : "",
      utility: site?.utility || "",
      customerId: site?.customerId || "",
      equipmentOwnership: site?.equipmentOwnership || "",
      mountingType: site?.mountingType || "",
      cost: site?.cost || "",
      hasReceivedGrants: !isNil(site?.hasReceivedGrants)
        ? (site.hasReceivedGrants ? 'Yes' : 'No')
        : "",
      fundingSource: site?.fundingSource || "",
    }
  });
  // submit patch
  const onSubmit = async (payload) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    for (const key in payload) {
      if (payload[key] === "") {
        payload[key] = null;
      }
      if (key === 'completionDate' && payload[key]) {
        // NB DatePicker form stores completionDate as a JS date
        payload[key] = DateTime.fromJSDate(payload[key]).toISODate();
      }
      if (key === 'hasReceivedGrants' && payload[key]) {
        payload[key] = transformHasReceivedGrants(payload[key])
      }
    }
    dispatch(patchCustomerProjectSite({
      payload,
      accessToken,
      customerProjectId: customerProject.id,
      siteId: site.id
    }));
  };
  const { status } = useSelector((state) => state.patchCustomerProjectSite);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <DialogContent>
          {isSuccess && (
            <Typography gutterBottom>
              Success! Your site has been edited. You may close this window.
            </Typography>
          )}
          {customerProject.emissionReductionActivity === 'LOW_CARBON_FUEL' ? (
            <EVSiteDetailsForm disabled={isSuccess}/>
          ) : (
            <SolarSiteDetailsForm disabled={isSuccess}/>
          )}
        </DialogContent>
        <DialogActions>
          <DialogSaveButton
            isSubmitting={isSubmitting}
            isSuccess={isSuccess}
            handleClose={close}
            handleSubmit={() => methods.handleSubmit(onSubmit)()}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export const ViewSolarDetails = ({ siteDetails, reviewOnly, isLoading }) => {
  const capacity = siteDetails?.capacity ? round(siteDetails.capacity / 1000, 2) : null;
  const completionDate = siteDetails?.completionDate ? DateTime.fromISO(siteDetails.completionDate).toLocaleString() : null;
  return (
    <TableContainer>
      <Table aria-label="site details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" width="50%">
              Site Name
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={siteDetails?.name} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Capacity (kW)
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={capacity} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Utility
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : siteDetails?.utility ?? ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Customer ID
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : siteDetails?.customerId ?? ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Equipment Ownership
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : siteDetails?.equipmentOwnership ?? ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Mounting Type
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : siteDetails?.mountingType ?? ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Cost
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : siteDetails?.cost?.toLocaleString() ?? ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Completion Date
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={completionDate} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width="50%">
              Funding Source
            </TableCell>
            <TableCell>
              {isLoading ? <Skeleton variant="text" /> : siteDetails?.fundingSource ?? ""}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ViewEVDetails = ({ siteDetails, reviewOnly, isLoading }) => (
  <TableContainer>
    <Table aria-label="site details" size="small">
      <TableBody>
        <TableRow>
          <TableCell component="th" width="50%">
            Site Name
          </TableCell>
          <TableCell>
            {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={siteDetails?.name} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" width="50%">
            Electric Utility Supplier
          </TableCell>
          <TableCell>
            {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={siteDetails?.fuelSupplier} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" width="50%">
            Charging Type
          </TableCell>
          <TableCell>
            {isLoading
              ? <Skeleton variant="text" />
              : <RequiredField
                  reviewOnly={reviewOnly}
                  value={find(CHARGING_TYPES, { enum: siteDetails?.chargingType })?.readable}
                />
            }
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export const ViewDetails = ({ site, customerProject, reviewOnly = false, isLoading = false }) => {
  return customerProject?.emissionReductionActivity === 'LOW_CARBON_FUEL'
    ? <ViewEVDetails siteDetails={site} reviewOnly={reviewOnly} isLoading={isLoading} />
    : <ViewSolarDetails siteDetails={site} reviewOnly={reviewOnly} isLoading={isLoading} />;
};
