import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  TextField,
  MenuItem,
  Paper,
} from "@mui/material";
import Page from "@components/Page";
import PropTypes from "prop-types";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { isNil, keys, has, isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import Navigation from "@components/Navigation";
import OrgAddressesForm from "./OrgAddressesForm";
import { schema } from "@views/settings/organization/components/OrganizationEditor";

export const INDUSTRIES = {
  MUNICIPALITY: "Municipality",
  UTILITY: "Utility",
  INSTALLER: "Installer",
  EPC: "Engineering, Procurement, Construction (EPC)",
  PROJECT_DEVELOPER: "Project Developer",
  OTHER: "Other",
};

const OrganizationInfo = ({
  processTitle,
  organization,
  backAction,
  onSubmit,
  submitText,
  isSubmitting,
}) => {
  // ultimate value of industry is freetext, only enum is here, for convenience
  // either the key in caps, or the free value (non-caps)
  // todo: can consolidate upon submission, split when fetching
  const hasNamedIndustry =
    has(INDUSTRIES, organization?.industry) &&
    organization?.industry !== "OTHER";
  const hasAnyIndustry = !isEmpty(organization?.industry);
  const industry = hasNamedIndustry
    ? {
        industry: organization.industry,
        otherIndustry: "",
      }
    : hasAnyIndustry
    ? { industry: "OTHER", otherIndustry: organization.otherIndustry }
    : { industry: "", otherIndustry: "" };

  const defaultValues = {
    ...organization,
    ...industry,
    phone: organization.phone || "",
    website: organization.website || "",
  };

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const [showOtherIndustry, setShowOtherIndustry] = useState(
    industry.industry === "OTHER"
  );

  return (
    <Page title="Company Profile" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box ml={3}>
            <Typography variant="h2" color="textPrimary">
              {processTitle}
            </Typography>
            <Typography variant="h4">Create your account</Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight="bold" mt={3} mb={1}>
                    Fleet Manager / Individual / Organization
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Company Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.name?.message)}
                        helperText={errors.name?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        name="industry"
                        variant="standard"
                        error={!isNil(errors.industry?.message)}
                        helperText={errors.industry?.message}
                        required
                        select
                        fullWidth
                        label="Industry"
                        value={value}
                        onChange={(event) => {
                          onChange(event);
                          setShowOtherIndustry(event.target.value === "OTHER");
                        }}
                        color="secondary"
                      >
                        {keys(INDUSTRIES).map((key) => (
                          <MenuItem key={key} value={key}>
                            {INDUSTRIES[key]}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                    name="industry"
                  />
                </Grid>
                {showOtherIndustry && (
                  <>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <TextField
                            label="Other Industry"
                            variant="standard"
                            fullWidth
                            error={!isNil(errors.otherIndustry?.message)}
                            helperText={errors.otherIndustry?.message}
                            required
                            onChange={onChange}
                            value={value}
                            color="secondary"
                          />
                        )}
                        control={control}
                        name="otherIndustry"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Phone Number"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.phone?.message)}
                        helperText={errors.phone?.message}
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="phone"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Website"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.website?.message)}
                        helperText={errors.website?.message}
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="website"
                  />
                </Grid>
                <FormProvider {...{ errors, control, setValue }}>
                  <OrgAddressesForm />
                </FormProvider>
              </Grid>
              <Navigation
                backAction={backAction}
                nextAction={() => handleSubmit(onSubmit)()}
                nextActionText={submitText}
                isSubmitting={isSubmitting}
              />
            </form>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

OrganizationInfo.defaultProps = {
  submitText: "Submit",
  isSubmitting: false,
  backAction: null,
};

OrganizationInfo.propTypes = {
  processTitle: PropTypes.string.isRequired,
  organization: PropTypes.shape({}).isRequired,
  backAction: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default OrganizationInfo;
