import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SmaPermissionRequestPanel from "../components/credentials/SmaPermissionRequestPanel";
import { requestSmaPermission } from "@addSites/actions";

const mapStateToProps = ({
  requestSmaPermission: smaPermissionResult,
}) => ({
  smaPermissionResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestSmaPermission },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmaPermissionRequestPanel);
