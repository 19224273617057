import { put, call, takeLatest } from "redux-saga/effects";
import logger from "debug";
import { verifyInviteToken } from "../actions";
import axios from "axios";
import { join, values } from "lodash";

const callApi = async (token) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/invites/verify`,
    {
      token,
    },
  );
  return response;
};

function* verifyInviteTokenWorker(action) {
  yield put(verifyInviteToken.request());

  const { token } = action.payload;

  try {
    const response = yield call(callApi, token);
    yield put(verifyInviteToken.success({ invite: response.data }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error verifying token: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error verifying token: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error verifying token: ", err);
      message = err.message;
    }
    message = `Unable to verify token: ${message}`;
    yield put(
      verifyInviteToken.failure({
        error: message,
      })
    );
  }
}

function* verifyInviteTokenWatcher() {
  yield takeLatest(verifyInviteToken.TRIGGER, verifyInviteTokenWorker);
}

export default [verifyInviteTokenWatcher];
