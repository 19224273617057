import { call, put, takeLatest } from "redux-saga/effects";
import { getApplication } from "../actions";
import axios from "axios";
import logger from "debug";
import { isNil } from "lodash";
import { showAppMessage } from "@appMessage/actions";
import { initialState } from "@views/apply/reducers/application";

const callApi = async (accessToken, applicationId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${applicationId}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

// our siteOwner doesn't quite match what we get from BE:
// - we use site instead of asset
// - we store the siteOwner under the application, rather than under the asset
// - we use firsName and lastName, rather than givenName and familyName on BE
// - the BE doesn't tell us the ownerType
export const mapSiteOwner = (application) => {
  const isIndividual = !isNil(application.asset?.siteOwner?.individual);
  const isExternal =
    !isNil(application.asset?.siteOwner?.contact) ||
    !isNil(application.asset?.siteOwner?.external);
  const isOrg = !isNil(application.asset?.siteOwner?.organization);
  const ownerType = isIndividual
    ? "INDIVIDUAL"
    : isExternal
    ? "EXTERNAL"
    : isOrg
    ? "ORGANIZATION"
    : null;
  switch (ownerType) {
    case "INDIVIDUAL": {
      const {
        familyName,
        givenName,
        email,
        phone,
      } = application.asset?.siteOwner?.individual;
      return {
        ownerType,
        individual: {
          lastName: familyName,
          firstName: givenName,
          email,
          phone,
        },
      };
    }
    case "EXTERNAL": {
      const { familyName, givenName, email, phone } =
        application.asset?.siteOwner?.external ||
        application.asset?.siteOwner?.contact;
      return {
        ownerType,
        external: {
          lastName: familyName,
          firstName: givenName,
          email,
          phone,
        },
      };
    }
    case "ORGANIZATION": {
      const { familyName, givenName, email, phone } =
        application.asset.siteOwner?.organization?.primaryContact || {};
      const { id, name } = application.asset.siteOwner?.organization;
      return {
        ownerType,
        organization: {
          id,
          name,
          primaryContact: {
            lastName: familyName,
            firstName: givenName,
            email,
            phone,
          },
        },
      };
    }
    default:
      // sometimes we don't yet have a siteOwner
      logger("weedle:warn")("No such ownertype: ", ownerType);
      return null;
  }
};

function* getApplicationWorker(action) {
  yield put(getApplication.request());

  const { accessToken, applicationId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, applicationId);
    const application = response.data;

    // important to match our application reducer
    yield put(
      getApplication.success({
        application: {
          ...application,
          siteInfo: {
            ...(application.siteInfo || initialState.siteInfo),
            installer: {
              ...(application.asset.installer ||
                initialState.siteInfo.installer),
            },
          },
          siteOwner: mapSiteOwner(application),
        },
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting application: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting application: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting application: ", err);
      message = err.message;
    }
    message = `Unable to get application: ${message}`;
    yield put(getApplication.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getApplicationWatcher() {
  yield takeLatest(getApplication.TRIGGER, getApplicationWorker);
}

export default [getApplicationWatcher];
