import { call, put, takeLatest } from "redux-saga/effects";
import { getChannelPartners } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/admin/channelPartners`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getChannelPartnersWorker(action) {
  yield put(getChannelPartners.request());

  const { accessToken } = action.payload;

  try {
    const response = yield call(callApi, accessToken);
    const channelPartners = response.data;
    yield put(getChannelPartners.success({ channelPartners }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting channel partners: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting channel partners: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting channel partners: ", err);
      message = err.message;
    }
    message = `Unable to get channel partners: ${message}`;
    yield put(getChannelPartners.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getChannelPartnersWatcher() {
  yield takeLatest(getChannelPartners.TRIGGER, getChannelPartnersWorker);
}

export default [getChannelPartnersWatcher];
