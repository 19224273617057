import { call, put, takeLatest } from "redux-saga/effects";
import { getCustomerProject } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callProtectedApi = async (accessToken, customerProjectId) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

const callPublicApi = async (customerProjectId) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/public`,
);

function* getCustomerProjectWorker(action) {
    yield put(getCustomerProject.request());

    const { accessToken, customerProjectId } = action.payload;

  try {
    const response = accessToken
      ? yield call(callProtectedApi, accessToken, customerProjectId)
      : yield call(callPublicApi, customerProjectId);
    const customerProject = response.data;
    yield put(getCustomerProject.success({ customerProject }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get customer project: ${message}`;
    yield put(getCustomerProject.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getCustomerProjectWatcher() {
  yield takeLatest(getCustomerProject.TRIGGER, getCustomerProjectWorker);
}

export default [getCustomerProjectWatcher];
