import React from "react";
import { Alert as MuiAlert, Snackbar } from "@mui/material";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export const notificationReducer = (notification, action) => {
  switch (action.type) {
    case "show":
      const { message, severity } = action.payload;
      return {
        ...notification,
        open: true,
        message,
        severity,
      };
    case "hide":
      return {
        ...notification,
        open: false,
      };
    default:
      throw new Error();
  }
};

const Notification = ({ open, hide, severity = "success", message }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    hide();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
