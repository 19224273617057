import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { map, join, isEmpty } from "lodash";
import DownloadIcon from "@mui/icons-material/GetApp";
import DocumentIcon from "@mui/icons-material/Description";
import { downloadFile } from "@utils/fileHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppMessage } from "@components/appMessage";
import EditIcon from "@mui/icons-material/Edit";
import DocumentsEditor from "../containers/DocumentsEditor";

const mapDocs = (docs) =>
  map(docs, (doc) => ({
    ...doc,
    file: {
      path: doc.name,
      size: doc.size,
    },
    tags: map(doc.tags, (tag) => tag.name),
  }));

const Documents = ({
  application,
  resetPatchAppSiteDocuments,
  approveApplicationResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  // reset any patching that occurred before
  useEffect(() => {
    resetPatchAppSiteDocuments();
  }, [resetPatchAppSiteDocuments]);

  // update state after approve
  useEffect(() => {
    if (
      approveApplicationResult.status === "success" &&
      !isEmpty(approveApplicationResult.application.asset?.documents)
    ) {
      setDocuments(
        mapDocs(approveApplicationResult.application.asset?.documents)
      );
    }
  }, [approveApplicationResult]);

  const downloadDoc = async (doc) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(doc, accessToken);
    } catch (err) {
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error",
      });
    }
  };

  const downloadAll = async (docs) => {
    for (const doc of docs) {
      await downloadDoc(doc);
    }
  };

  // store documents from application in component state
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    if (!isEmpty(application.asset?.documents)) {
      setDocuments(mapDocs(application.asset?.documents));
    }
  }, [application]);

  // edit documents action
  const [isDocumentsEditorOpen, setIsDocumentsEditorOpen] = useState(false);
  const handleEdit = () => {
    setIsDocumentsEditorOpen(true);
  };
  const handleClose = () => {
    setIsDocumentsEditorOpen(false);
  };

  const handleSave = useCallback(
    (documents) => {
      setDocuments(mapDocs(documents));
      handleClose();
    },
    [setDocuments]
  );

  const isApplicationApproved = application.status === "APPROVED";

  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Documents</Typography>
        <Toolbar sx={{ mt: -2 }}>
          <Tooltip title="Download all documents">
            <span>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="download all"
                disabled={isEmpty(documents)}
                onClick={() => downloadAll(documents)}
                sx={{ mr: 0 }}
                size="large"
              >
                <DownloadIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              isApplicationApproved
                ? "Application already approved"
                : "Edit Documents"
            }
          >
            <span>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="edit documents"
                disabled={isApplicationApproved}
                onClick={handleEdit}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </Box>
      <Typography variant="body1" gutterBottom>
        Documents provided for this site application.
      </Typography>

      <TableContainer>
        <Table aria-label="site documents">
          <TableBody>
            {map(documents, (doc) => (
              <TableRow key={doc.id}>
                <TableCell component="th">
                  <Box display="flex" alignItems="center">
                    <DocumentIcon sx={{ mr: 1 }} />
                    <span>{doc.name}</span>
                  </Box>
                </TableCell>
                <TableCell>{join(doc.tags, ", ")}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    startIcon={<DownloadIcon />}
                    onClick={() => downloadDoc(doc)}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEmpty(documents) && (
        <Box ml={2}>
          <Typography color="textSecondary">No documents available</Typography>
        </Box>
      )}
      <DocumentsEditor
        open={isDocumentsEditorOpen}
        onClose={handleClose}
        onSave={handleSave}
        documents={documents}
      />
    </Paper>
  );
};

Documents.propTypes = {
  application: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  resetPatchAppSiteDocuments: PropTypes.func.isRequired,
  approveApplicationResult: PropTypes.shape({
    status: PropTypes.string.isRequired,
    application: PropTypes.shape({}).isRequired,
  }),
};

export default Documents;
