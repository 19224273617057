import { createAction } from "redux-actions";
import {
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

// organization redux

const saveContactInfo = createAction("SAVE_CONTACT_INFO");
saveContactInfo.TYPE = saveContactInfo.toString();

const saveOrganizationInfo = createAction("SAVE_ORGANIZATION_INFO");
saveOrganizationInfo.TYPE = saveOrganizationInfo.toString();

const resetOrganization = createAction("RESET_ORGANIZATION");
resetOrganization.TYPE = resetOrganization.toString();

// push to api

const saveOrganization = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("SAVE_ORGANIZATION");

export {
  saveContactInfo,
  saveOrganizationInfo,
  resetOrganization,
  
  saveOrganization,
};
