import { getPrograms } from "../actions";

export const initialState = {
  status: "init",
  programs: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getPrograms.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getPrograms.SUCCESS: {
      const { programs } = action.payload;
      return {
        ...state,
        status: "success",
        programs,
      };
    }

    case getPrograms.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
