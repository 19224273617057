import {
  createAction
} from "redux-actions";
import {
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const saveDataConnectorParams = createAction("ADD_DEVICES_FORM/SAVE_DATA_CONNECTOR_PARAMS");
saveDataConnectorParams.type = saveDataConnectorParams.toString();

const saveMeters = createAction("ADD_DEVICES_FORM/SAVE_METERS");
saveMeters.type = saveMeters.toString();

const saveSiteId = createAction("ADD_DEVICES_FORM/SAVE_SITE_ID");
saveSiteId.type = saveSiteId.toString();

// see if credentials provided for a data connector work
const testDataConnectorConnection = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "TEST_DATA_CONNECTOR_CONNECTION"
);
// find sites from surge, for specified credentials
const findSystems = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "FIND_SYSTEMS"
);


export {
  saveDataConnectorParams,
  saveMeters,
  saveSiteId,
  testDataConnectorConnection,
  findSystems,
};