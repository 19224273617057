import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const verifyToken = createRoutine("SITE_OWNER_VERIFY_TOKEN");

const patchApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("PATCH_APPLICATION_SITE_OWNER");

export { verifyToken, patchApplication };
