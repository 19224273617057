import React from "react";
import {
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { usePrev, useNext } from "./useNextPrev";
import { application as appActions } from "../actions";
import { ApplicationPage } from "./ApplicationLayout";
import { useDispatch, useSelector } from "react-redux";
import { AddDevices } from "../../Devices/components/Devices";
import { Box } from "@mui/system";

const Devices = () => {
  const dispatch = useDispatch();
  const formMethods = useForm();
  const prev = usePrev();
  const next = useNext();

  const { canAccessData } = useSelector((state) => state.testDataConnectorConnection);
  const { meters } = useSelector(state => state.addDevicesForm);

  // next button
  const handleFinish = async () => {
    // make sure we show any validation on the embedded form ie. required fields
    // the only way we can do that is if we get the form setup here
    await formMethods.trigger();

    // we can only get this if we had a successful connection test
    // we could probably do more here, eg. if you delete the key after a success,
    // we don't clear the form/redux straight away (so it is still a success), but diminishing returns
    if (canAccessData) {
      dispatch(appActions.saveAssetDetails({ meters }));
      next("docs");
    }
  };

  const { customerProject } = useSelector((state) => state.getCustomerProject);

  // n.b. different from components/Site/Devices/Manufacturer because it needs to be skippable
  // for applicants to don't have their credentials.
  return (
    <ApplicationPage pageName="Add Devices">
      <AddDevices
        customerProject={customerProject}
        handleFinish={handleFinish}
        handleBack={prev}
        skipAction={() => { next("installer"); }}
      />
      {canAccessData === false && (
        <Box mt={2}>
          <Typography>
            Having trouble connecting? Press Skip to continue without connecting your system.
          </Typography>
        </Box>
      )}
    </ApplicationPage>
  );
};

export default Devices;
