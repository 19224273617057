import PropTypes from "prop-types";
import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { fundingSources } from "@addSites/sitePicker/components/FundingSourceComboBox";
import { isNil } from "lodash";

const filter = createFilterOptions();

const FundingSourceComboBox = ({ value, onChange, readOnly, errorMessage }) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        const match = newValue.match(/^Add "(.+)"$/);
        onChange(match ? match[1] : newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push(`Add "${params.inputValue}"`);
        }

        return readOnly ? [] : filtered;
      }}
      selectOnFocus
      clearOnBlur
      disableClearable
      handleHomeEndKeys
      blurOnSelect
      options={fundingSources}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label="Grant/Funding Source"
          error={!isNil(errorMessage)}
          helperText={errorMessage || ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
            readOnly,
          }}
        />
      )}
    />
  );
};

FundingSourceComboBox.defaultProps = {
  value: "",
};

FundingSourceComboBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
};

export default FundingSourceComboBox;
