import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Chip, Container, Paper, IconButton } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "lodash";
import { DateTime } from "luxon";
import humanizeDuration from "humanize-duration";
import { useNavigate } from "react-router-dom";
import Page from "@components/Page";
import CustomDataGrid from "@components/CustomDataGrid";
import ViewIcon from "@mui/icons-material/Visibility";
import PageHeader from "@components/PageHeader";
import { renderCellExpand } from "@components/GridCellExpand";

export const APPLICATION_STATUS_COLORS = {
  APPROVED: {
    color: "success.light",
  },
  MISSING_TERMS_AND_CONDITIONS: {
    color: "warning.light",
  },
  READY_FOR_REVIEW: {
    color: "info.main",
  },
  PERMANENTLY_DECLINED: {
    color: "error.main",
  },
  ON_HOLD: {
    color: "grey.600",
  },
  CHANGES_REQUIRED: {
    color: "warning.light",
  },
  INCOMPLETE: {
    color: "warning.light",
  },
};

export const APPLICATION_STATUSES = {
  APPROVED: "Approved",
  MISSING_TERMS_AND_CONDITIONS: "Missing Terms & Conditions",
  READY_FOR_REVIEW: "Ready for Review",
  PERMANENTLY_DECLINED: "Permanently Declined",
  ON_HOLD: "On Hold",
  CHANGES_REQUIRED: "Requiring Changes",
  INCOMPLETE: "Incomplete",
};

export const PENDING_APPLICATION_STATUSES = [
  "MISSING_TERMS_AND_CONDITIONS",
  "READY_FOR_REVIEW",
  "ON_HOLD",
  "CHANGES_REQUIRED",
  "INCOMPLETE",
];

export const APPLICATION_SOURCES = {
  PUBLIC_LINK: "Public Link",
  INTERNAL_INVITE: "Internal Invite",
};

export const getApplicationColumns = (viewAction) => [
  {
    field: "id",
    headerName: "ID",
    flex: 0.5,
  },
  {
    field: "programName",
    headerName: "Program",
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    valueGetter: ({ row: application }) => get(APPLICATION_SOURCES, application.source, APPLICATION_SOURCES.PUBLIC_LINK),
    renderCell: renderCellExpand,
  },
  {
    field: "siteName",
    headerName: "Site Name",
    flex: 1,
    valueGetter: ({ row: application }) => application.siteName || "-",
    renderCell: renderCellExpand,
  },
  {
    field: "siteOwnerName",
    headerName: "Site Owner",
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    field: "created",
    headerName: "Received",
    flex: 1,
    valueGetter: ({ row: application }) => {
      const duration = humanizeDuration(
        DateTime.fromMillis(application.created * 1000)
          .diffNow()
          .toMillis(),
        {
          units: ["mo", "d", "h"],
          round: true,
          largest: 1,
        }
      );
      return `${duration} ago`;
    },
    renderCell: renderCellExpand,
    sortComparator: (v1, v2, param1, param2) => {
      const created1 = param1.api.getRow(param1.id).created;
      const created2 = param2.api.getRow(param2.id).created;
      return created2 - created1;
    }
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 175,
    renderCell: (params) => {
      const status = params.value;
      return (
        <Chip
          label={get(APPLICATION_STATUSES, status, status)}
          variant="outlined"
          sx={{
            borderColor: get(APPLICATION_STATUS_COLORS, `${status}.color`),
            color: get(APPLICATION_STATUS_COLORS, `${status}.color`),
          }}
        />
      );
    },
  },
  {
    field: "action",
    headerName: "View",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => viewAction(params.id)}
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
        >
          <ViewIcon />
        </IconButton>
      );
    },
  },
];

const Applications = ({
  getApplications,
  getApplicationsResult,
  setApplicationTableState,
  applicationTableState,
}) => {
  const navigate = useNavigate();

  // get applications on load
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getApplications({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getApplications]);

  const applicationsLoading =
    getApplicationsResult.status === "request" || getApplicationsResult.status === "init";
  const { applications } = getApplicationsResult;

  const columns = React.useMemo(() => {
    const viewAction = (applicationId) => navigate(`/applications/${applicationId}`);
    return getApplicationColumns(viewAction);
  }, [navigate]);

  const { page, pageSize, sortModel, filterModel } = applicationTableState;
  return (
    <Page title="Application List" py={3}>
      <Container maxWidth="lg">
        <PageHeader
          title="Application List"
          subtitle="All applications submitted to your program(s)."
        />
        <Paper sx={{ mt: 3 }}>
          <div style={{ height: "100%", width: "100%" }}>
            <CustomDataGrid
              style={{ border: 0 }}
              autoHeight
              loading={applicationsLoading}
              rows={applications}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(sortModel) => setApplicationTableState({ sortModel })}
              page={page}
              onPageChange={(page) => setApplicationTableState({ page })}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => setApplicationTableState({ pageSize })}
              rowsPerPageOptions={[10, 20, 50]}
              filterModel={filterModel}
              onFilterModelChange={(filterModel) => setApplicationTableState({ filterModel })}
            />
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

Applications.propTypes = {
  getApplications: PropTypes.func.isRequired,
  getApplicationsResult: PropTypes.shape({}).isRequired,
  setApplicationTableState: PropTypes.func.isRequired,
  applicationTableState: PropTypes.shape({}).isRequired,
};

export default Applications;
