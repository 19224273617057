import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";

const EnphaseInstructionPanel = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Instructions to Grant Rewatt Access
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Login to the Enphase Enlighten platform, using this link"
            secondary={
              <Link
                href="https://api.enphaseenergy.com/oauth/authorize?response_type=code&client_id=c13505b8b3ee8a565186ca347fdc83b1&redirect_uri=https://api.enphaseenergy.com/oauth/redirect_uri"
                target="_blank"
                rel="noopener"
              >
                https://api.enphaseenergy.com/oauth/authorize?...
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText primary="Grant access to Rewatt" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText primary="Copy and paste your Authorization code" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="For more detailed instructions and screenshots, see this page."
            secondary={
              <Link href="https://rewattpower.com/enphase/" target="_blank" rel="noopener">
                https://rewattpower.com/enphase/
              </Link>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default EnphaseInstructionPanel;
