import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import { isNil, isEmpty, trim } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { urlRegExp } from "@utils/errorHelpers";
import { ConnectionTest } from "@addSites/manufacturer";

const CachelanUrlPanel = ({ credentials }) => {
  const { errors, control } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="Cachelan Portal URL"
              variant="standard"
              fullWidth
              placeholder="https://{siteName}.solarvu.net"
              autoFocus
              error={!isNil(errors.cachelanPortalUrl?.message)}
              helperText={errors.cachelanPortalUrl?.message || " "}
            />
          }
          name="cachelanPortalUrl"
          control={control}
          rules={{
            required: "The Cachelan Portal URL is required.",
            validate: (value) => {
              if (!isEmpty(trim(value))) {
                return urlRegExp.test(value) || "A valid URL is required.";
              }
              return true;
            },
          }}
          defaultValue={credentials.cachelanPortalUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>      
    </Grid>
  );
};

CachelanUrlPanel.propTypes = {
  credentials: PropTypes.shape({
    cachelanPortalUrl: PropTypes.string,
  }).isRequired,
};

export default CachelanUrlPanel;
