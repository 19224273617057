/**
 * Get locale based on browser settings.
 */
const getDefaultLocale = () => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};

const getDefaultTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export { getDefaultLocale, getDefaultTimeZone };
