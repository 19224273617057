import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { map, isEmpty, first } from "lodash";
import { CameraIcon } from "@heroicons/react/solid";
import { useAuth0 } from "@auth0/auth0-react";
import Image from "@jy95/material-ui-image";
import { fileSizeValidator } from "@utils/fileHelpers";
import byteSize from "byte-size";
import { getDims } from "@utils/imageHelpers";
import { useDispatch, useSelector } from "react-redux";
import { hideAppMessage, showAppMessage } from "@appMessage/actions";
import DropZone from "@components/DropZone";
import HeroIcon from "@components/HeroIcon";

const maxFileSize = 1 * 1000 * 1000; // bytes

const LogoUploader = ({
  onChange,
  value,
  error,
  title,
  discriminator,
  uploadLogo,
  selector,
  tags = [],
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    validator: fileSizeValidator(maxFileSize),
  });

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      dispatch(
        showAppMessage({
          severity: "error",
          message: fileRejections[0].errors[0].message,
        })
      );
    } else {
      dispatch(hideAppMessage());
    }
  }, [fileRejections, dispatch]);

  // we have some new files to upload
  useEffect(() => {
    async function uploadNewFiles() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      dispatch(
        uploadLogo({
          filespecs: map(acceptedFiles, (file) => ({
            file,
            tags,
            public: true,
            name: file.name,
          })),
          accessToken,
          discriminator,
        })
      );
    }
    if (acceptedFiles.length) {
      uploadNewFiles();
    }
    // eslint-disable-next-line
  }, [acceptedFiles]);

  // inform our form
  const { filespecs, status } = useSelector(selector);
  const isUploading = status === "request";
  const uploadedFilespec = first(filespecs);
  useEffect(() => {
    if (uploadedFilespec) {
      onChange(uploadedFilespec);
    }
  }, [onChange, uploadedFilespec]);

  // img display
  const [aspectRatio, setAspectRatio] = React.useState(1);
  const imgBoxDim = 200 - 2 * 8;

  return (
    <DropZone
      {...getRootProps({
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
        isError: !isEmpty(error),
      })}
    >
      <input {...getInputProps()} />
      {value?.uri && (
        <Box width="100%" height="100%" p={1}>
          <Image
            src={value.uri}
            imageStyle={getDims(aspectRatio, imgBoxDim)}
            onLoad={(e) => {
              if (e?.target) {
                setAspectRatio(e.target.naturalWidth / e.target.naturalHeight);
              }
            }}
            style={{
              backgroundColor: "transparent",
            }}
          />
        </Box>
      )}
      {!value?.uri && (
        <Box mt={2}>
          <HeroIcon icon={CameraIcon} />
          <Typography variant="body2" color="textPrimary" gutterBottom>
            {title}
          </Typography>
          <Box mt={3}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Max file size: {byteSize(maxFileSize, { precision: 0 }).toString()}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              JPEG or PNG only
            </Typography>
          </Box>
        </Box>
      )}
      {isUploading && (
        <CircularProgress
          size={32}
          sx={{
            color: (theme) => theme.palette.primary.main,
            position: "absolute",
            top: "50%",
            left: "50%",
            mt: -2,
            ml: -2,
          }}
        />
      )}
    </DropZone>
  );
};

LogoUploader.defaultProps = {
  title: "Drag and drop your logo, or browse",
};

LogoUploader.propTypes = {
  // provide the upload action for your specific logo
  // your custom reducer will be filled
  // make sure you are listening in common/sagas/uploadFiles
  uploadLogo: PropTypes.func.isRequired,

  // how do we access the reducer from state
  selector: PropTypes.func.isRequired,

  // unique to this uploader, since we share a saga
  discriminator: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
  error: PropTypes.string,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default LogoUploader;
