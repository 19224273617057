import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationInfo from "@views/apply/components/OrganizationInfo";
import { prepareOrganization } from "@views/onboarding/sagas/saveOrganization";
import { addBuyerOrganization } from "../actions";
import { useAppMessage } from "@components/appMessage";
import { getUserProfile } from "@views/common/actions";

/**
 * Page is only shown if the invite has no organization.
 */
const Organization = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { invite } = useSelector((state) => state.verifyBuyerInviteToken);

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { familyName, givenName, email, phone } = userProfile;
    dispatch(
      addBuyerOrganization({
        accessToken,
        organization: prepareOrganization({
          ...data,
          contact: {
            lastName: familyName,
            firstName: givenName,
            email,
            phone,
            jobTitle: "N/A",
          },
        }),
      })
    );
  };

  // submit status
  const { status: addBuyerOrganizationStatus, organization } = useSelector(
    (state) => state.addBuyerOrganization
  );
  const isSubmitting = addBuyerOrganizationStatus === "request";

  useEffect(() => {
    if (addBuyerOrganizationStatus === "success") {
      dispatch(getUserProfile.update({ userProfile: { organizationId: organization.id } }));
      dispatch(addBuyerOrganization.reset());
      navigate("/marketplace/buyer/join");
    }
  }, [
    dispatch,
    navigate,
    showAppMessage,
    addBuyerOrganizationStatus,
    getAccessTokenSilently,
    invite.id,
    organization.id,
    userProfile.acceptedTermsAndConditions,
  ]);

  const initialOrg = {
    name: "",
    industry: "",
    phone: "",
    website: "",
    addresses: [],
  };

  return (
    <OrganizationInfo
      processTitle="Buyer Registration"
      organization={initialOrg}
      onSubmit={onSubmit}
      submitText={"Continue"}
      isSubmitting={isSubmitting}
    />
  );
};

export default Organization;
