import React, { useEffect } from "react";
import { Chip, Paper, IconButton, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ViewIcon from "@mui/icons-material/Visibility";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "lodash";
import { DateTime } from "luxon";
import humanizeDuration from "humanize-duration";
import { useNavigate } from "react-router-dom";

import CustomDataGrid from "@components/CustomDataGrid";
import { renderCellExpand } from "@components/GridCellExpand";
import { APPLICATION_STATUSES, APPLICATION_STATUS_COLORS } from "@views/programs/components/Applications";
import { includes, filter } from "lodash";
import { getProjectApplications } from "../../CustomerProjects/actions";
import { setApplicationsTableState } from "../actions";

export const getApplicationColumns = (viewAction) => [
  {
    field: "id",
    headerName: "ID",
    flex: 0.5,
  },
  {
    field: "siteName",
    headerName: "Site Name",
    flex: 1,
    valueGetter: ({ row: application }) => application.siteName || "-",
    renderCell: renderCellExpand,
  },
  {
    field: "siteOwnerName",
    headerName: "Site Owner",
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    field: "created",
    headerName: "Received",
    flex: 1,
    valueFormatter: (params) => {
      const diff = DateTime.fromSeconds(params.value).diffNow().toMillis();
      const opts = { units: ["mo", "d", "h"], round: true, largest: 1 };
      const duration = humanizeDuration(diff, opts);
      return `${duration} ago`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 175,
    renderCell: ({ value: status }) => (
      <Chip
        label={get(APPLICATION_STATUSES, status, status)}
        variant="outlined"
        sx={{
          borderColor: get(APPLICATION_STATUS_COLORS, `${status}.color`),
          color: get(APPLICATION_STATUS_COLORS, `${status}.color`),
        }}
      />
    ),
  },
  {
    field: "action",
    headerName: "View",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <IconButton
        onClick={() => viewAction(params.id)}
        aria-label="view"
        size="small"
        sx={{ ml: 1 }}
      >
        <ViewIcon />
      </IconButton>
    ),
  },
];

const Applications = ({ customerProject, title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get applications on load
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getProjectApplications({ accessToken, customerProject }));
    }
    if (customerProject) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, customerProject]);

  const { applications, status } = useSelector((state) => state.getProjectApplications);
  const rows = customerProject ? filter(applications, (app) => app.customerProjectId === customerProject.id) : [];
  const applicationsLoading = includes(["request", "init"], status);

  const columns = React.useMemo(() => {
    const viewAction = (applicationId) => {
      navigate(`/projects/${customerProject?.id}/applications/${applicationId}`);
    }
    return getApplicationColumns(viewAction);
  }, [navigate, customerProject?.id]);

  const { page, pageSize, sortModel, filterModel } = useSelector(state => state.applicationsTable);
  return (
    <Paper sx={{ p: 3 , pt: 1}}>
      <Box py={1}>
        {title && <Typography variant="h5">{title}</Typography>}
      </Box>
      <div style={{ height: "100%", width: "100%" }}>
        <CustomDataGrid
          style={{ border: 0 }}
          autoHeight
          loading={applicationsLoading}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(sortModel) => dispatch(setApplicationsTableState({ sortModel }))}
          page={page}
          onPageChange={(page) => dispatch(setApplicationsTableState({ page }))}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => dispatch(setApplicationsTableState({ pageSize }))}
          rowsPerPageOptions={[10, 20, 50]}
          filterModel={filterModel}
          onFilterModelChange={(filterModel) => dispatch(setApplicationsTableState({ filterModel }))}
        />
      </div>
    </Paper>
  );
};

export default Applications;
