import React from "react";
import { Box, Link, Typography } from "@mui/material";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import logoIcon from "@assets/logo-icon.svg";
import logoRewatt from "@assets/rewatt_logo.svg";
import shutterstock from "@assets/shutterstock-1928779172.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

const StyledLink = styled(Link)({
  cursor: "pointer",
});

const Container = ({ isMobile, children }) => {
  if (!isMobile) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        {children}
      </Box>
    );
  }
  return <Box pt={3}>{children}</Box>;
};

const Landing = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  // todo: there is a use case where you login, close your browser, then login as a different user, and get some errors
  // when you click login, we could clear the userProfile?

  const login = async () => {
    if (isAuthenticated) {
      navigate("/callback");
    } else {
      await loginWithRedirect({
        redirectUri: window.location.origin,
        appState: { returnTo: `/callback` },
        prompt: 'select_account',
      });
    }
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Page
      title="Welcome"
      px={3}
      bgcolor="common.white"
      height="100%"
      minWidth={320}
    >
      <Container isMobile={isMobile}>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box mb={3}>
            <StyledLink href="https://rewattpower.com">
              <img alt="Rewatt" src={logoIcon} width={130} height={130} />
            </StyledLink>
          </Box>
          <Box mb={3}>
            <StyledLink href="https://rewattpower.com">
              <img alt="Rewatt Power" src={logoRewatt} width={260} />
            </StyledLink>
          </Box>

          <Typography
            variant="h2"
            align="center"
            mb={3}
            sx={{ fontWeight: 500 }}
          >
            Welcome to the Rewatt Portal
          </Typography>
          <Box mb={3}>
            <img
              alt="Rewatt Power"
              src={shutterstock}
              width={isMobile ? 300 : 560}
            />
          </Box>

          <Typography
            variant="h3"
            align="center"
            mb={3}
            sx={{ fontWeight: 400 }}
          >
            <StyledLink color="secondary.main" href="https://apply.rewattpower.com">
              Start your Solar Credits Program Application now!
            </StyledLink>
          </Typography>

          <Typography variant="h5" align="center" color="textSecondary">
            Already have a login?{" "}
            <StyledLink onClick={login}>
              Click here to continue.
            </StyledLink>
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default Landing;
