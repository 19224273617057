import MuiGlobalStyles from "@mui/material/GlobalStyles";
import { useTheme } from "@mui/material/styles";

const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <MuiGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          height: "100%",
          width: "100%",
        },
        body: {
          backgroundColor: "#f4f6f8",
          height: "100%",
          width: "100%",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          height: "100%",
          width: "100%",
        },

        // just the chart menu (download svg, etc)
        ".apexcharts-menu.apexcharts-menu-open": {
          textAlign: "left",
          fontFamily: theme.typography.fontFamily,
        },
      }}
    />
  );
};

export default GlobalStyles;
