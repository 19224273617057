import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Breadcrumbs, Typography, Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { isEmpty } from "lodash";

// we always have a (spot for a) title, even if it is (initially) empty
// can have a title and breadcrumbs
// can have a title and action
// can have a title and breadcrumbs and action
const PageHeader = forwardRef(({ title, children: breadcrumbs, action, subtitle = "" }, ref) => {
  // action can be null/undefined, an object, or a react element
  const actionButton =
    action?.name && action?.handle ? (
      <Button
        variant="contained"
        onClick={action.handle}
        disabled={!action.handle}
      >
        {action.name}
      </Button>
    ) : (
      action
    );
  if (isEmpty(breadcrumbs) && actionButton) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" ref={ref}>
        <Box>
          <Typography variant="h1">
            {title}
          </Typography>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Box>
        <Box>{actionButton}</Box>
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" alignItems="center" ref={ref}>
        <Box flexGrow={1} ml={-1}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        {action && <Box>{actionButton}</Box>}
      </Box>
      <Box>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
    </>
  );
});

PageHeader.propTypes = {
  // these should generally be of type BreadCrumb
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  title: PropTypes.string, // allow for loading, so can be empty
  subtitle: PropTypes.string,
  action: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handle: PropTypes.func,
    }),
    PropTypes.element, // eg a custom button
  ]),
};

export default PageHeader;
