import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";
import { createAction } from "redux-actions";

const getPrograms = createRoutine("GET_PROGRAMS");
const uploadTerms = createRoutine("UPLOAD_TERMS");
const uploadProgramLogo = createRoutine("UPLOAD_PROGRAM_LOGO");
const createProgram = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_PROGRAM"
);
const getOrganizationUsers = createRoutine("GET_ORG_USERS");

const getApplications = createRoutine("GET_APPLICATIONS");

const setApplicationTableState = createAction("SET_APPLICATION_TABLE_STATE");
setApplicationTableState.TYPE = setApplicationTableState.toString();

const setProgramTableState = createAction("SET_PROGRAM_TABLE_STATE");
setProgramTableState.TYPE = setProgramTableState.toString();

export {
  getPrograms,
  createProgram,
  uploadTerms,
  getApplications,
  getOrganizationUsers,
  setApplicationTableState,
  setProgramTableState,
  uploadProgramLogo,
};
