import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Paper, IconButton, Tooltip } from "@mui/material";
import Page from "@components/Page";
import AddInvite from "@members/containers/AddInvite";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { includes, map, get } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import ConfirmDeleteInvite from "./ConfirmDeleteInvite";
import CustomDataGrid from "@components/CustomDataGrid";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioIcon from "@mui/icons-material/RadioButtonUnchecked";
import { EmailLink } from "@components";

const statusColors = {
  joined: "success.main",
  invited: "warning.main",
};

const Members = ({
  getOrgInvites,
  getOrgInvitesResult,
  getOrganizationResult,
  deleteInvite,
  deleteInviteResult,
  resendInvite,
  resendInviteResult,
  memberTableState,
  setMemberTableState,
  updatePrimaryContact,
  updatePrimaryContactResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // confirm if deleting an invite
  const [open, setOpen] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const cancelAction = () => {
    setOpen(false);
  };
  const okAction = async () => {
    setOpen(false);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    deleteInvite({ accessToken, inviteId: inviteToDelete.id });
    setInviteToDelete(null);
  };
  const confirmDeleteInvite = ({ invite }) => {
    setInviteToDelete(invite);
    setOpen(true);
  };

  // fetch invites
  useEffect(() => {
    async function fetchInvites() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getOrgInvites({ accessToken });
    }

    fetchInvites();
  }, [getAccessTokenSilently, getOrgInvites]);

  // lodaing statuses
  const isLoading = !(
    includes(["success", "failure"], getOrgInvitesResult.status) &&
    includes(["success", "failure"], getOrganizationResult.status)
  );

  const isResending = resendInviteResult.status === "request";
  const isDeleting = deleteInviteResult.status === "request";

  // map the table data
  const invites = map(getOrgInvitesResult.invites, (invite) => ({
    ...invite,
    date: invite.dateAccepted
      ? DateTime.fromISO(invite.dateAccepted)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT)
      : DateTime.fromSeconds(invite.created)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT),
    status: invite.status === "ACCEPTED" ? "Joined" : "Invited",
  }));
  // todo: inevitably we have active members without an invite, also the org creator is missing

  // column definitions
  const { primaryContact } = getOrganizationResult.organization;
  const columns = React.useMemo(
    () => [
      { headerName: "Name", field: "inviteeName", flex: 1 },
      {
        headerName: "Email",
        field: "invitee",
        flex: 1.2,
        renderCell: ({ value }) => <EmailLink email={value} />,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 0.6,
        renderCell: ({ value }) => (
          <Typography color={get(statusColors, value.toLowerCase(), "primary")}>
            {value}
          </Typography>
        ),
      },
      { headerName: "Date", field: "date", flex: 0.6 },
      {
        field: "setPrimaryContact",
        headerName: "Primary Contact",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          if (params.row.invitee === primaryContact?.email) {
            return <Typography color="secondary.dark">Primary</Typography>;
          }
          return (
            <Tooltip title="Set as primary contact for this organization.">
              <span>
                <IconButton
                  onClick={async (ev) => {
                    const invite = params.row;
                    const accessToken = await getAccessTokenSilently({
                      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    });
                    updatePrimaryContact({
                      accessToken,
                      inviteeId: invite.inviteeId,
                    });
                  }}
                  aria-label="set as primary contact"
                  size="small"
                  sx={{ ml: 1 }}
                  disabled={params.row.status !== "Joined"}
                >
                  <RadioIcon />
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: "resendInviteAction",
        headerName: "Resend",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const invite = params.row;
          return (
            <IconButton
              onClick={async (ev) => {
                const accessToken = await getAccessTokenSilently({
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                });
                resendInvite({ accessToken, inviteId: invite.id });
              }}
              aria-label="resend invite"
              size="small"
              sx={{ ml: 1 }}
              disabled={invite.status === "Joined"}
            >
              <ReplayIcon />
            </IconButton>
          );
        },
      },
      {
        field: "CancelInviteAction",
        headerName: "Cancel",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row: invite }) => {
          return (
            <IconButton
              onClick={async (ev) => {
                confirmDeleteInvite({ invite });
              }}
              aria-label="cancel invite"
              size="small"
              sx={{ ml: 1 }}
              disabled={invite.status === "Joined"}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    [getAccessTokenSilently, resendInvite, updatePrimaryContact, primaryContact?.email]
  );

  const { page, pageSize, sortModel, filterModel } = memberTableState;
  return (
    <Page title="Members" py={3}>
      <Container maxWidth="lg">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1">Members</Typography>
          </Box>
          <Box>
            <AddInvite />
          </Box>
        </Box>
        <Paper sx={{ mt: 3 }}>
          <div style={{ height: "100%", width: "100%" }}>
            <CustomDataGrid
              style={{ border: 0 }}
              autoHeight
              loading={isLoading || isResending || isDeleting}
              rows={invites}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(sortModel) => setMemberTableState({ sortModel })}
              page={page}
              onPageChange={(page) => setMemberTableState({ page })}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => setMemberTableState({ pageSize })}
              rowsPerPageOptions={[10, 20, 50]}
              filterModel={filterModel}
              onFilterModelChange={(filterModel) => setMemberTableState({ filterModel })}
            />
            <ConfirmDeleteInvite
              open={open}
              cancelAction={cancelAction}
              okAction={okAction}
              invite={inviteToDelete}
            />
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

Members.propTypes = {
  getOrgInvites: PropTypes.func.isRequired,
  getOrgInvitesResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  getOrganizationResult: PropTypes.shape({
    status: PropTypes.string,
    organization: PropTypes.shape({}),
  }),
  deleteInvite: PropTypes.func.isRequired,
  deleteInviteResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  resendInvite: PropTypes.func.isRequired,
  resendInviteResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  updatePrimaryContact: PropTypes.func.isRequired,
  updatePrimaryContactResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  setMemberTableState: PropTypes.func.isRequired,
  memberTableState: PropTypes.shape({}).isRequired,
};

export default Members;
