import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Box } from "@mui/material";
import Logo from "./PoweredByReWattLogo";

const TopBar = () => (
  <AppBar
    sx={{
      color: "primary.main",
      backgroundColor: "common.white",
    }}
    elevation={1}
  >
    <Toolbar>
      <Logo />
      <Box flexGrow={1} />
    </Toolbar>
  </AppBar>
);

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
