import React from "react";
import { Link } from "@mui/material";
import PhoneFormatter from "phone-formatter";

const PhoneLink = ({ phone, missing = "N/A" }) =>
  phone ? (
    <Link href={`tel:${phone}`}>{PhoneFormatter.format(phone, "(NNN) NNN-NNNN")}</Link>
  ) : (
    missing
  );

export default PhoneLink;
