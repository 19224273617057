import getCustomerProjects from "./getCustomerProjects";
import getCustomerProject from "./getCustomerProject";
import createCustomerProject from "./createCustomerProject";
import patchCustomerProject from "./patchCustomerProject";
import getIncompleteSitesCount from "./getIncompleteSitesCount";
import uploadCustomerProjectTerms from "./uploadCustomerProjectTerms";
import uploadCustomerProjectLogo from "./uploadCustomerProjectLogo";
import getProjectApplications from "./getProjectApplications";
import getProjectApplication from "./getProjectApplication";
import getCustomerProjectChart from "./getCustomerProjectChart";
import getCustomerProjectCreditSummary from "./getCustomerProjectCreditSummary";

const reducers = {
  getCustomerProject,
  getCustomerProjects,
  createCustomerProject,
  patchCustomerProject,
  getIncompleteSitesCount,
  uploadCustomerProjectTerms,
  uploadCustomerProjectLogo,
  getProjectApplications,
  getProjectApplication,
  getCustomerProjectChart,
  getCustomerProjectCreditSummary,
};

export default reducers;
