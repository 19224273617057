import { call, put, takeLatest } from "redux-saga/effects";
import { patchSiteDetails } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { join, values } from "lodash";
import { DateTime } from "luxon";

const callApi = async (accessToken, siteId, siteDetails) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/${siteId}`,
    siteDetails,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const transform = (siteDetails) => ({
  ...siteDetails,
  capacity: Math.floor(siteDetails.capacity * 1000), // 450.32123 kW becomes 450321 watts
  fundingSource: siteDetails.fundingSource || null,
  utility: siteDetails.utility || null,
  customerId: siteDetails.customerId || null,
  equipmentOwnership: siteDetails.equipmentOwnership || null,
  cost: siteDetails.cost ? (siteDetails.cost * 1).toFixed(2) : null,
  completionDate:
    siteDetails.completionDate &&
    DateTime.fromISO(siteDetails.completionDate).toISODate(), // can be a luxon or iso format
});

export function* patchSiteDetailsWorker(action) {
  yield put(patchSiteDetails.request());

  const { accessToken, siteId, siteDetails } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      siteId,
      transform(siteDetails)
    );
    const site = response.data;
    yield put(patchSiteDetails.success({ site }));
    yield put(
      showAppMessage({
        severity: "success",
        message: "Updated site successfully.",
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error patching site details: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error patching site details: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error patching site details: ", err);
      message = err.message;
    }
    message = `Unable to patch site details: ${message}`;
    yield put(patchSiteDetails.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* patchSiteDetailsWatcher() {
  yield takeLatest(patchSiteDetails.TRIGGER, patchSiteDetailsWorker);
}

export default [patchSiteDetailsWatcher];
