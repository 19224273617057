import React, { useEffect } from "react";
import {
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { patchApplicationEditDetails } from "../actions";
import { DateTime } from "luxon";
import { SolarSiteDetailsForm, solarFormSchema, transformHasReceivedGrants } from "views/customerProjects/Site/components/Details";
import DialogSaveButton from "components/DialogSaveButton";
import { isNil } from "lodash";
import { omitBy } from "lodash";

const SiteDetailsEditor = ({ applicationId, site, close }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(solarFormSchema),
    defaultValues: {
      name: site?.name || "",
      capacity: site?.capacity ? site.capacity / 1000 : "",
      isBuilt: site?.completionDate ? (DateTime.fromISO(site.completionDate) < DateTime.now() ? 'Yes' : 'No') : "",
      completionDate: site?.completionDate ? DateTime.fromISO(site.completionDate) : "",
      utility: site?.utility || "",
      customerId: site?.customerId || "",
      equipmentOwnership: site?.equipmentOwnership || "",
      mountingType: site?.mountingType || "",
      cost: site?.cost || "",
      hasReceivedGrants: !isNil(site?.hasReceivedGrants)
        ? (site.hasReceivedGrants ? 'Yes' : 'No')
        : "",
      fundingSource: site?.fundingSource || "",
    },
  });

  // generally when site loads
  // useEffect(() => {
  //   reset(defaultValues(site));
  // }, [site, reset]);

  // patch application on BE
  const onSubmit = async (formValues) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    for (const key in formValues) {
      if (key === 'completionDate' && formValues[key]) {
        // NB DatePicker form stores completionDate as a JS date
        formValues[key] = DateTime.fromJSDate(formValues[key]).toISODate();
      }
      if (key === 'hasReceivedGrants' && formValues[key]) {
        formValues[key] = transformHasReceivedGrants(formValues[key])
      }
    }
    const asset = omitBy(formValues, (value) => value === "");
    dispatch(patchApplicationEditDetails({
      accessToken,
      applicationId,
      asset,
    }));
  };

  useEffect(() => () => {
    dispatch(patchApplicationEditDetails.reset());
  }, [dispatch]);

  const { status } = useSelector((state) => state.patchApplicationEditDetails);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";
  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <DialogContent>
          {isSuccess && (
            <Typography gutterBottom>
              Success! Your site has been edited. You may close this window.
            </Typography>
          )}
          <SolarSiteDetailsForm disabled={isSuccess}/>
        </DialogContent>
        <DialogActions>
          <DialogSaveButton
            isSubmitting={isSubmitting}
            isSuccess={isSuccess}
            handleClose={close}
            handleSubmit={() => methods.handleSubmit(onSubmit)()}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default SiteDetailsEditor;
