import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Projects from "../components/Projects";
import { getProjects, setProjectTableState } from "../actions";

const mapStateToProps = ({
  getProjects: getProjectsResult,
  projectTableState,
}) => ({
  getProjectsResult,
  projectTableState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProjects,
      setProjectTableState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
