import React, { useState } from "react";
import { Typography, Paper, Box } from "@mui/material";
import { find } from "lodash";
import { DateTime } from "luxon";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import ProjectReportDocumentList from "./ProjectReportDocumentList";
import { addProjectReportDocuments, setProjectReportStatus } from "@admin/projectReport/actions";
import { uploadProjectFiles } from "@admin/project/actions";
import ProjectFilesUploader from "@admin/project/components/projectStatus/ProjectFilesUploader";
import StatusStep from "@admin/project/components/projectStatus/StatusStep";
import ConfirmRegistrationDialog from "./ConfirmRegistrationDialog";

const ProjectReportStatusPanel = ({ projectReport, editable = true, title = "Status" }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const registrationSubmitted = find(projectReport.statusHistory, {
    status: "REGISTRATION_SUBMITTED",
  });
  const registered = find(projectReport.statusHistory, { status: "REGISTERED" });
  const verificationSubmitted = find(projectReport.statusHistory, {
    status: "VERIFICATION_SUBMITTED",
  });
  const verified = find(projectReport.statusHistory, { status: "VERIFIED" });

  // file was uploaded so record it with project report and status
  const handleUploaded = async (filespecs, status) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_project_reports",
    });
    dispatch(addProjectReportDocuments({ accessToken, projectReport, status, filespecs }));
    dispatch(uploadProjectFiles.reset());
  };

  // project report status is changed
  const handleProjectReportStatusChange = async (status) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_project_reports",
    });
    dispatch(setProjectReportStatus({ accessToken, projectReport, status }));
  };

  // project report registration
  const [open, setOpen] = useState(false);
  const cancelAction = () => {
    setOpen(false);
  };
  const okAction = async () => {
    setOpen(false);
    await handleProjectReportStatusChange("REGISTERED");
  };

  const isVoluntary = projectReport.project?.environmentalCredit === "REWATT_VOLUNTARY_CREDIT";
  return (
    <>
      {title && (
        <Typography variant="h3" gutterBottom ml={2}>
          {title}
        </Typography>
      )}

      <Paper sx={{ p: 3, overflowX: "auto" }}>
        <Box display="flex" direction="row" justifyContent="flex-start">
          <StatusStep
            title="Created"
            subtitle={
              projectReport.created &&
              DateTime.fromSeconds(projectReport.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!projectReport.created}
          />
          <StatusStep
            title="Submitted for Verification"
            subtitle={
              verificationSubmitted &&
              DateTime.fromSeconds(verificationSubmitted.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!verificationSubmitted}
            onChangeStatus={() => handleProjectReportStatusChange("VERIFICATION_SUBMITTED")}
            disabled={!editable}
          >
            {editable && (
              <ProjectFilesUploader
                onChange={(fs) => handleUploaded(fs, "VERIFICATION_SUBMITTED")}
                sx={{ mr: 3 }}
                discriminator="VERIFICATION_SUBMITTED"
              />
            )}
            <ProjectReportDocumentList
              documents={verificationSubmitted?.documents}
              projectReport={projectReport}
              editable={editable}
            />
          </StatusStep>

          <StatusStep
            title="Verified"
            subtitle={
              verified && DateTime.fromSeconds(verified.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!verified}
            onChangeStatus={() => handleProjectReportStatusChange("VERIFIED")}
            disabled={!editable}
            isEnd={isVoluntary}
          >
            {editable && (
              <ProjectFilesUploader
                onChange={(fs) => handleUploaded(fs, "VERIFIED")}
                sx={{ mr: 3 }}
                discriminator="VERIFIED"
              />
            )}
            <ProjectReportDocumentList
              documents={verified?.documents}
              projectReport={projectReport}
              editable={editable}
            />
          </StatusStep>

          {!isVoluntary && (
            <StatusStep
              title="Submitted for Registration"
              subtitle={
                registrationSubmitted &&
                DateTime.fromSeconds(registrationSubmitted.created).toLocaleString(
                  DateTime.DATE_MED
                )
              }
              incomplete={!registrationSubmitted}
              onChangeStatus={() => handleProjectReportStatusChange("REGISTRATION_SUBMITTED")}
              disabled={!editable}
            >
              {editable && (
                <ProjectFilesUploader
                  onChange={(fs) => handleUploaded(fs, "REGISTRATION_SUBMITTED")}
                  sx={{ mr: 3 }}
                  discriminator="REGISTRATION_SUBMITTED"
                />
              )}
              <ProjectReportDocumentList
                documents={registrationSubmitted?.documents}
                projectReport={projectReport}
                editable={editable}
              />
            </StatusStep>
          )}

          {!isVoluntary && (
            <StatusStep
              title="Registered"
              subtitle={
                registered &&
                DateTime.fromSeconds(registered.created).toLocaleString(DateTime.DATE_MED)
              }
              incomplete={!registered}
              onChangeStatus={() => setOpen(true)}
              disabled={!editable}
              isEnd
            >
              {editable && (
                <ProjectFilesUploader
                  onChange={(fs) => handleUploaded(fs, "REGISTERED")}
                  sx={{ mr: 3 }}
                  discriminator="REGISTERED"
                />
              )}
              <ProjectReportDocumentList
                documents={registered?.documents}
                projectReport={projectReport}
                editable={editable}
              />
            </StatusStep>
          )}
        </Box>

        <ConfirmRegistrationDialog open={open} cancelAction={cancelAction} okAction={okAction} />
      </Paper>
    </>
  );
};

export default ProjectReportStatusPanel;
