import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  CircularProgress,
  Container,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import InstallerForm from "@views/apply/components/InstallerForm";
import { contactSchema } from "@views/common/components/ContactForm";
import { useDispatch, useSelector } from "react-redux";
import { patchAppSiteInstaller } from "../actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InstallerEditor = ({ open, onClose, onSave, installer }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(contactSchema),
    defaultValues: {
      ...installer,
      firstName: installer.givenName,
      lastName: installer.familyName,
    },
  });

  // patch application on BE
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    dispatch(patchAppSiteInstaller({ accessToken, installer: data }));
  };

  const { status, application } = useSelector((state) => state.patchAppSiteInstaller);

  // close and cleanup on success
  useEffect(() => {
    if (status === "success") {
      const { installer } = application.asset;

      // ie. update component state in InstallerPanel
      onSave(installer);

      // make sure our form has the proper default values
      methods.reset({
        ...installer,
        firstName: installer.givenName,
        lastName: installer.familyName,
      });

      // reset patch
      dispatch(patchAppSiteInstaller.reset());

      onClose();
    }
    // "methods" get changed on re-render, so ignore dependency (otherwise circular ref)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, onSave]);

  const isSubmitting = status === "request";
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Contractor Information
          </Typography>
          <LoadingButton
            color="inherit"
            onClick={() => methods.handleSubmit(onSubmit)()}
            loading={isSubmitting}
            loadingIndicator={<CircularProgress sx={{ color: "common.white" }} size={16} />}
          >
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <FormProvider {...methods}>
          <InstallerForm />
        </FormProvider>
      </Container>
    </Dialog>
  );
};

InstallerEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  installer: PropTypes.shape({}),
};

export default InstallerEditor;
