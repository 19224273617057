import {
  saveSiteActivity,
  saveManufacturer,
  saveCredentials,
  resetSiteInfo,
} from "../actions";
import { isUndefined } from "lodash";

export const initialState = {
  activityType: "solar",
  siteOwner: null,
  manufacturer: "",
  credentials: {
    apiKey: "",
    authCode: "",
    froniusAccessUrl: "",
    plantId: "",
    username: "",
    password: "",
    cachelanPortalUrl: "",
  },
  // NB. we don't save the sites from sitepicker in redux, just in component state
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resetSiteInfo.TYPE: {
      return initialState;
    }

    case saveSiteActivity.TYPE: {
      const { activityType, siteOwner } = action.payload;
      return {
        ...state,
        activityType: activityType || state.activityType,
        siteOwner: isUndefined(siteOwner) ? state.siteOwner : siteOwner,
      };
    }

    case saveManufacturer.TYPE: {
      const { manufacturer } = action.payload;
      return {
        ...state,
        manufacturer,
      };
    }

    case saveCredentials.TYPE: {
      const {
        apiKey,
        authCode,
        froniusAccessUrl,
        cachelanPortalUrl,
        plantId,
        username,
        password,
      } = action.payload;
      return {
        ...state,
        credentials: {
          apiKey: isUndefined(apiKey) ? state.credentials.apiKey : apiKey,
          authCode: isUndefined(authCode)
            ? state.credentials.authCode
            : authCode,
          froniusAccessUrl: isUndefined(froniusAccessUrl)
            ? state.credentials.froniusAccessUrl
            : froniusAccessUrl,
          cachelanPortalUrl: isUndefined(cachelanPortalUrl)
            ? state.credentials.cachelanPortalUrl
            : cachelanPortalUrl,
          plantId: isUndefined(plantId)
            ? state.credentials.plantId
            : plantId,
          username: isUndefined(username)
            ? state.credentials.username
            : username,
          password: isUndefined(password)
            ? state.credentials.password
            : password,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
