import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Container,
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { isNil, map, keys, includes, filter, reduce, pick, without } from "lodash";
import * as yup from "yup";
import {
  PROGRAM_TYPES,
  ACTIVITY_TYPES,
  TARGET_PARTICIPANTS,
  EMISSION_REDUCTION_ACTIVITIES,
} from "@views/program/components/Program";
import LogoUploader from "@views/common/components/LogoUploader";
import TermsUploader from "./TermsUploader";
import { getFullName } from "@utils/stringHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import TextArea from "@components/TextArea";
import { PENDING_APPLICATION_STATUSES } from "@views/programs/components/Applications";
import { uploadProgramLogo } from "../actions";

export const schema = yup.object().shape({
  name: yup.string().max(255, "Too long.").required("Program name is required."),
  description: yup.string().max(4096, "Too long.").required("Program objectives are required."),
  type: yup.string().required("Program type is required."),
  emissionReductionActivity: yup.string().when("type", {
    is: "ENVIRONMENTAL_CREDITS",
    then: yup.string().required("Emission reduction activity is required."),
    otherwise: yup.string(),
  }),
  logo: yup.object().required("A logo is required"),
  termsAndConditions: yup.object().required("A terms and conditions document is required"),
  managerId: yup.string().required("A point of contact is required"),
});

const ProgramFormFields = ({
  getOrganizationUsers,
  getOrganizationUsersResult,
  getProgramSiteStatusesResult: { siteStatuses },
}) => {
  const { errors, control, watch } = useFormContext();
  const watchProgramType = watch("type", "");

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getOrganizationUsers({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getOrganizationUsers]);

  const isLoading = includes(["init", "request"], getOrganizationUsersResult.status);

  // can't change a program if it already has applications in these states
  const pending = reduce(
    pick(siteStatuses, without(PENDING_APPLICATION_STATUSES, "INCOMPLETE")),
    (result, value) => {
      return result + value;
    },
    0
  );
  const readOnly = pending > 0;

  return (
    <Container maxWidth={false}>
      <Box sx={{ mt: 4, mx: { xs: 1, sm: 2 } }}>
        <form noValidate autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex">
                <Box mr={5}>
                  <Controller
                    render={({ onChange, value }) => (
                      <LogoUploader
                        onChange={onChange}
                        value={value}
                        error={errors.logo?.message}
                        discriminator="PROGRAM"
                        uploadLogo={uploadProgramLogo}
                        selector={(state) => state.uploadProgramLogo}
                      />
                    )}
                    control={control}
                    name="logo"
                  />
                </Box>
                <Controller
                  render={({ onChange, value }) => (
                    <TextArea
                      label="Program Summary"
                      error={!isNil(errors.description?.message)}
                      helperText={errors.description?.message}
                      required
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 4096,
                      }}
                      placeholder={`Add your program summary here. We recommend a couple of short, \
polished sentences describing your program and how participants can expect to benefit. \
This summary will be included at the top of participant application pages. \
It will be seen by participants, and in some instances, the public.`}
                    />
                  )}
                  control={control}
                  name="description"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Enter Program Details</Typography>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Program Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.name?.message)}
                        helperText={errors.name?.message || "Name your program"}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                        inputProps={{
                          maxLength: 255,
                        }}
                      />
                    )}
                    control={control}
                    name="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Program Type"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.type?.message)}
                        helperText={
                          errors.type?.message ||
                          (readOnly && "Read Only") ||
                          "Select a program type"
                        }
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                        select
                        inputProps={{
                          readOnly,
                        }}
                      >
                        {map(keys(PROGRAM_TYPES), (key) => (
                          <MenuItem key={key} value={key} disabled={key === "REBATE"}>
                            {PROGRAM_TYPES[key]}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                    name="type"
                  />
                </Grid>
                {watchProgramType === "MONITORING" && (
                  <Grid item xs={12}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          label="Activity Type"
                          variant="standard"
                          fullWidth
                          error={!isNil(errors.activityType?.message)}
                          helperText={
                            errors.activityType?.message ||
                            (readOnly && "Read Only") ||
                            "Select an activity"
                          }
                          onChange={onChange}
                          value={value}
                          color="secondary"
                          select
                          inputProps={{
                            readOnly,
                          }}
                        >
                          {map(keys(ACTIVITY_TYPES), (key) => (
                            <MenuItem key={key} value={key} disabled={key !== "SOLAR"}>
                              {ACTIVITY_TYPES[key]}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      control={control}
                      name="activityType"
                    />
                  </Grid>
                )}
                {watchProgramType === "ENVIRONMENTAL_CREDITS" && (
                  <Grid item xs={12}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          label="Emissions Reductions Activity"
                          variant="standard"
                          fullWidth
                          error={!isNil(errors.emissionReductionActivity?.message)}
                          helperText={
                            errors.emissionReductionActivity?.message ||
                            (readOnly && "Read Only") ||
                            "Select an emissions reductions activity"
                          }
                          onChange={onChange}
                          value={value}
                          color="secondary"
                          select
                          required
                          inputProps={{
                            readOnly,
                          }}
                        >
                          {map(keys(EMISSION_REDUCTION_ACTIVITIES), (key) => (
                            <MenuItem key={key} value={key}>
                              {EMISSION_REDUCTION_ACTIVITIES[key]}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      control={control}
                      name="emissionReductionActivity"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Point of Contact for Public Inquiries"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.managerId?.message)}
                        helperText={errors.managerId?.message || "Select a person"}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                        select={!isLoading}
                        disabled={isLoading}
                      >
                        {map(getOrganizationUsersResult.users, (user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {getFullName(user, null, user.userId)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                    name="managerId"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Choose Target Participants</Typography>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormControl component="fieldset">
                        <FormGroup sx={{ ml: 1 }}>
                          {map(keys(TARGET_PARTICIPANTS), (tp) => (
                            <FormControlLabel
                              key={tp}
                              control={
                                <Checkbox
                                  checked={includes(value, tp)}
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      onChange([...value, tp]);
                                    } else {
                                      onChange(filter(value, (val) => val !== tp));
                                    }
                                  }}
                                  name={tp}
                                />
                              }
                              label={TARGET_PARTICIPANTS[tp]}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    )}
                    control={control}
                    name="targetParticipants"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Upload Program Terms and Conditions *</Typography>
                  <Controller
                    render={({ onChange, value }) => (
                      <TermsUploader
                        onChange={onChange}
                        value={value}
                        error={errors.termsAndConditions?.message}
                      />
                    )}
                    control={control}
                    name="termsAndConditions"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

ProgramFormFields.propTypes = {
  getOrganizationUsers: PropTypes.func.isRequired,
  getOrganizationUsersResult: PropTypes.shape({}).isRequired,
  getProgramSiteStatusesResult: PropTypes.shape({}).isRequired,
};

export default ProgramFormFields;
