import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Container, Paper } from "@mui/material";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { includes, get } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import ViewIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import PageHeader from "@components/PageHeader";
import CreateProjectEditor from "./CreateProjectEditor";
import { PROTOCOLS, ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import CustomDataGrid from "@components/CustomDataGrid";
import { useDispatch } from "react-redux";
import { getProject } from "@views/admin/project/actions";
import { renderCellExpand } from "@components/GridCellExpand";
import Consent from "@admin/components/Consent";

const Projects = ({ getProjects, getProjectsResult, setProjectTableState, projectTableState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useAuth0();
  const [loading, setLoading] = useState(true);

  const { projects } = getProjectsResult;

  useEffect(() => {
    // if we get consent_required, we'll be stuck on init
    const projectsLoading = includes(["init", "request"], getProjectsResult.status);
    setLoading(projectsLoading || isLoading);
  }, [isLoading, getProjectsResult.status]);

  // create projects action
  const [isProjectEditorOpen, setIsProjectEditorOpen] = useState(false);
  const handleOpenProjectEditor = () => {
    setIsProjectEditorOpen(true);
  };
  const handleCloseProjectEditor = () => {
    setIsProjectEditorOpen(false);
  };

  // column definitions
  const columns = React.useMemo(
    () => [
      {
        headerName: "Project Name",
        field: "title",
        flex: 1.2,
        renderCell: renderCellExpand,
      },
      {
        headerName: "# Sites",
        field: "assetIds",
        flex: 0.6,
        valueFormatter: ({ value }) => value.length,
      },
      {
        headerName: "Creation Date",
        field: "created",
        flex: 0.6,
        hide: true,
        valueFormatter: ({ value }) =>
          DateTime.fromSeconds(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Start Date",
        field: "startDate",
        flex: 0.6,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "End Date",
        field: "endDate",
        flex: 0.6,
        valueFormatter: ({ value }) =>
          value
            ? DateTime.fromISO(value)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)
            : "N/A",
      },
      {
        headerName: "Registry",
        field: "registry",
        flex: 0.8,
        renderCell: renderCellExpand,
        valueGetter: ({ row: project }) => project.registry.name,
      },
      {
        headerName: "Protocol",
        field: "protocol",
        flex: 1,
        valueGetter: ({ row: project }) => get(PROTOCOLS, project.protocol.name, "N/A"),
        renderCell: renderCellExpand,
      },
      {
        headerName: "Credit",
        field: "environmentalCredit",
        flex: 1,
        valueGetter: ({ row: project }) =>
          get(ENVIRONMENTAL_CREDIT_TYPES, project.environmentalCredit, "N/A"),
        renderCell: renderCellExpand,
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={(ev) => {
                const { id, title } = params.row;
                dispatch(getProject.init({ project: { id, title } }));
                navigate(`/admin/projects/${id}`);
              }}
              aria-label="view"
              size="small"
              sx={{ ml: 1 }}
            >
              <ViewIcon />
            </IconButton>
          );
        },
      },
    ],
    [navigate, dispatch]
  );

  const { page, pageSize, sortModel, filterModel } = projectTableState;
  return (
    <Page py={3} title="Projects">
      <Container maxWidth="lg">
        <Consent action={getProjects} scope="admin_projects">
          <PageHeader
            title="Projects"
            action={{ name: "Create Project", handle: handleOpenProjectEditor }}
          />
          <Paper sx={{ mt: 3 }}>
            <div style={{ height: "100%", width: "100%" }}>
              <CustomDataGrid
                style={{ border: 0 }}
                autoHeight
                loading={loading}
                rows={projects}
                columns={columns}
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(sortModel) => setProjectTableState({ sortModel })}
                page={page}
                onPageChange={(page) => setProjectTableState({ page })}
                pageSize={pageSize}
                onPageSizeChange={(pageSize) => setProjectTableState({ pageSize })}
                rowsPerPageOptions={[10, 20, 50]}
                filterModel={filterModel}
                onFilterModelChange={(filterModel) => setProjectTableState({ filterModel })}
              />
              <CreateProjectEditor open={isProjectEditorOpen} onClose={handleCloseProjectEditor} />
            </div>
          </Paper>
        </Consent>
      </Container>
    </Page>
  );
};

Projects.propTypes = {
  getProjects: PropTypes.func.isRequired,
  getProjectsResult: PropTypes.shape({}).isRequired,
  setProjectTableState: PropTypes.func.isRequired,
  projectTableState: PropTypes.shape({}).isRequired,
};

export default Projects;
