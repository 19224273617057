import testConnection from "./testConnection";
import requestSmaPermission from "./requestSmaPermission";
import findSites from "./findSites";
import getTimeZone from "./getTimeZone";
import getAddress from "./getAddress";
import uploadFiles from "./uploadFiles";
import addSites from "./addSites";

export default [
  ...testConnection,
  ...requestSmaPermission,
  ...findSites,
  ...getTimeZone,
  ...getAddress,
  ...uploadFiles,
  ...addSites,
];
