import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ProgramFormFields from "../containers/ProgramFormFields";
import { schema } from "./ProgramFormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import GreenButton from "@components/GreenButton";
import LoadingButton from '@mui/lab/LoadingButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateProgramEditor = ({ open, onClose, createProgram, createProgramResult, resetProgram }) => {
  const { getAccessTokenSilently } = useAuth0();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      type: "",
      activityType: "",
      emissionReductionActivity: "",
      dateRange: [null, null],
      targetParticipants: [], // string names
      logo: null, // filespec
      termsAndConditions: null, // filespec
      managerId: "", // user id
    },
  });

  // submit
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    createProgram({ program: data, accessToken });
  };

  const isSubmitting = createProgramResult.status === "request";
  const isSuccess = createProgramResult.status === "success";

  const onContinue = () => {
    methods.reset();
    resetProgram();
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          bgcolor: "background.dark",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Create Program
          </Typography>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <ProgramFormFields />
        <Box display="flex" justifyContent="flex-end" m={5}>
          {isSuccess && (
            <GreenButton
              size="large"
              onClick={onContinue}
            >
              Continue
            </GreenButton>
          )}
          {!isSuccess && (
            <LoadingButton
              variant="contained"
              size="large"
              color="primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Create Program
            </LoadingButton>
          )}
        </Box>
      </FormProvider>
    </Dialog>
  );
};

CreateProgramEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resetProgram: PropTypes.func.isRequired,
  createProgram: PropTypes.func.isRequired,
  createProgramResult: PropTypes.shape({}).isRequired,
};

export default CreateProgramEditor;
