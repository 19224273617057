import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, CardActions, Button, Grid } from "@mui/material";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import HeroIcon from "@components/HeroIcon";

const StatsCard = ({ title, value, action }) => (
  <Card>
    <CardContent sx={{ pl: 7, pb: 1 }}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h3">{value}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" mb={action ? 0 : 5} color="grey.500">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
    {action && (
      <CardActions
        sx={{
          pl: 6,
        }}
      >
        <Button
          sx={{ color: "secondary.dark" }}
          variant="text"
          endIcon={<HeroIcon icon={ArrowNarrowRightIcon} />}
          onClick={action}
        >
          View
        </Button>
      </CardActions>
    )}
  </Card>
);

StatsCard.defaults = {
  action: null,
  value: "",
};

StatsCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.func,
};

export default StatsCard;
