import React, { useEffect, useRef } from "react";
import { Box, Container, Grid } from "@mui/material";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "@components/PageLoader";
import PageHeader from "@components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrgAsset } from "@views/admin/orgAsset/actions";
import ProfilePanel from "./ProfilePanel";
import OrganizationPanel from "./OrganizationPanel";
import OwnershipPanel from "./OwnershipPanel";
import ConnectionPanel from "./ConnectionPanel";
import InstallerPanel from "./InstallerPanel";
import DocumentsPanel from "./DocumentsPanel";
import ProjectMembershipPanel from "./ProjectMembershipPanel";
import ApplicationsPanel from "./ApplicationsPanel";

const OrgAsset = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { orgAssetId } = useParams();
  const pageRef = useRef();

  useEffect(() => {
    async function fetchOrgAsset() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_sites",
      });
      dispatch(
        getOrgAsset({
          accessToken,
          orgAssetId,
        })
      );
    }

    fetchOrgAsset();
  }, [getAccessTokenSilently, orgAssetId, dispatch]);

  const { orgAsset, status } = useSelector((state) => state.getOrgAsset);
  const isLoading = status === "request";

  // scroll to top
  useEffect(() => {
    if (!isLoading) {
      pageRef?.current?.scrollIntoView();
    }
  }, [isLoading]);

  return (
    <Page title={orgAsset.name} py={3} ref={pageRef}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Loading..." />}
        {!isLoading && (
          <>
            <PageHeader title={orgAsset.name} />
            <Box mt={3} ml={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProfilePanel orgAsset={orgAsset} />
                </Grid>
                {/* <Grid item xs={12}>
                  <ChartPanel />
                </Grid> */}
                <Grid item xs={12}>
                  <ApplicationsPanel orgAsset={orgAsset} />
                </Grid>
                <Grid item xs={12}>
                  <ProjectMembershipPanel orgAsset={orgAsset} />
                </Grid>
                {orgAsset.organization && (
                  <Grid item xs={12}>
                    <OrganizationPanel orgAsset={orgAsset} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <OwnershipPanel orgAsset={orgAsset} />
                </Grid>
                <Grid item xs={12}>
                  <ConnectionPanel orgAsset={orgAsset} />
                </Grid>
                <Grid item xs={12}>
                  <InstallerPanel orgAsset={orgAsset} />
                </Grid>
                <Grid item xs={12}>
                  <DocumentsPanel orgAsset={orgAsset} />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};
export default OrgAsset;
