import { createRoutine, createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const getProjectReport = createRoutineCreator([...defaultRoutineStages, "INIT"])(
  "GET_PROJECT_REPORT"
);
const setProjectReportStatus = createRoutine("SET_PROJECT_REPORT_STATUS");
const addProjectReportDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_PROJECT_REPORT_DOCUMENTS"
);
const deleteProjectReportDocument = createRoutine("DELETE_PROJECT_REPORT_DOCUMENT");
const patchProjectReport = createRoutine("PATCH_PROJECT_REPORT");
const patchProjectReportWithStatus = createRoutine("PATCH_PROJECT_REPORT_WITH_STATUS");
const uploadCorporateLogo = createRoutine("UPLOAD_CORPORATE_LOGO");
const uploadVerifierLogo = createRoutine("UPLOAD_VERIFIER_LOGO");

export {
  getProjectReport,
  addProjectReportDocuments,
  setProjectReportStatus,
  deleteProjectReportDocument,
  patchProjectReport,
  patchProjectReportWithStatus,
  uploadCorporateLogo,
  uploadVerifierLogo,
};
