import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import Page from "@components/Page";
import PageLoader from "@components/PageLoader";
import { useSelector, useDispatch } from "react-redux";
import { getSite } from "@views/site/actions";
import { useAuth0 } from "@auth0/auth0-react";
import UserProfilePanel from "./UserProfilePanel";
import BankInformationPanel from "./BankInformationPanel";

const Account = () => {
  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { site, status } = useSelector((state) => state.getSite);

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // load the site if not loaded
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSite({ accessToken, siteId: userProfile.assetId }));
    }

    if (!site.id && status !== "failure") {
      init();
    }
  }, [dispatch, getAccessTokenSilently, site.id, userProfile.assetId, status]);

  const isPayee = userProfile.email && userProfile.email === site.payee?.email;
  const isLoading = status === "request";
  return (
    <Page title="Account Settings" py={3}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Loading..." />}
        {!isLoading && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserProfilePanel />
            </Grid>
            {isPayee  && (
              <Grid item xs={12}>
                <BankInformationPanel />
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default Account;
