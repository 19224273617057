import { call, put, takeLatest } from "redux-saga/effects";
import { createSite, getCustomerProjectSites } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { isNil, omitBy } from "lodash";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, customerProjectId, page, pageSize, order, search) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
    params: omitBy(
      {
        page,
        pageSize,
        order,
        search,
      },
      isNil
    ),
  }
);

function* getCustomerProjectSitesWorker(action) {
    yield put(getCustomerProjectSites.request());

    const { accessToken, customerProjectId, page, pageSize, order, search } = action.payload;

  try {
    const response = yield call(callApi, accessToken, customerProjectId, page, pageSize, order, search);
    const { assets, total } = response.data;
    yield put(getCustomerProjectSites.success({ sites: assets, total }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get project sites: ${message}`;
    yield put(getCustomerProjectSites.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getCustomerProjectSitesWatcher() {
  yield takeLatest(getCustomerProjectSites.TRIGGER, getCustomerProjectSitesWorker);
}

function* createSiteWatcher() {
  yield takeLatest(createSite.SUCCESS, getCustomerProjectSitesWorker);
} 

export default [getCustomerProjectSitesWatcher, createSiteWatcher];
