import getFleetStats from "./getFleetStats";
import getOnboardingChart from "./getOnboardingChart";
import getDataConnectorHealth from "./getDataConnectorHealth";
import getSitesByAdmin from "./getSitesByAdmin";
import addAssetInvite from "./addAssetInvite";

const reducers = {
  getFleetStats,
  getOnboardingChart,
  getDataConnectorHealth,
  getSitesByAdmin,
  addAssetInvite,
};

export default reducers;
