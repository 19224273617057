import { useAuth0 } from "@auth0/auth0-react";
import CustomDataGrid from "components/CustomDataGrid";
import Page from "components/Page";
import PageLoader from "components/PageLoader";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localeStringCurrencyOpts } from "utils/stringHelpers";
import { Container, Paper } from "@mui/material";
import PageHeader from "components/PageHeader";
import { getPayeeStatements } from "../actions";

const PayeeStatements = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getPayeeStatements({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch]);
  const { statements, status } = useSelector((state) => state.getPayeeStatements);
  const isLoading = status === 'request';

  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([
    {
      field: "sendDate",
      sort: "desc",
    },
  ]);

  const columns = [
    {
      field: 'sentDate',
      headerName: 'Date Sent',
      valueFormatter: ({ value }) => value ? DateTime.fromISO(value).toLocaleString() : '',
      flex: 1,
    },
    {
      field: 'credits',
      headerName: 'Number of Credits',
      valueGetter: ({ row: { lineItems } }) => lineItems.reduce((total, { quantity }) => total + quantity, 0),
      valueFormatter: ({ value }) => value.toLocaleString(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }),
      flex: 1,
    },
    {
      field: 'creditPeriod',
      headerName: 'Credit Period',
      valueGetter: ({ row: { contribution: { projectReport } } }) => {
        const startDate = DateTime.fromISO(projectReport.startDate).toLocaleString();
        const endDate = DateTime.fromISO(projectReport.endDate).toLocaleString();
        return `${startDate} - ${endDate}`;
      },
      flex: 1,
    },
    {
      field: 'amountOwed',
      headerName: 'Amount Paid',
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, localeStringCurrencyOpts),
      flex: 1,
    }
  ];

  return (
    <Page title="Statements" py={3}>
      {isLoading ? <PageLoader message="Loading site..." /> : (
        <Container maxWidth="lg">
          <PageHeader title="Statements"/>
          <Paper>
            <CustomDataGrid
              autoHeight
              style={{ border: 0 }}
              rows={statements || []}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
            />
          </Paper>
        </Container>
      )}
    </Page>
  );
};

export default PayeeStatements;
