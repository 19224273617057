import { call, put, takeLatest } from "redux-saga/effects";
import { getCustomerProjects } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, organizationId) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/customerProjects`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getCustomerProjectsWorker(action) {
    yield put(getCustomerProjects.request());

    const { accessToken, organizationId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, organizationId);
    const customerProjects = response.data;
    yield put(getCustomerProjects.success({ customerProjects }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get customer projects: ${message}`;
    yield put(getCustomerProjects.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getCustomerProjectsWatcher() {
  yield takeLatest(getCustomerProjects.TRIGGER, getCustomerProjectsWorker);
}

export default [getCustomerProjectsWatcher];
