import { call, put, takeLatest } from "redux-saga/effects";
import { removeSiteFromProject } from "../actions";
import { setProjectTableState } from "@views/admin/projects/actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async (accessToken, projectId, siteId) => await axios.delete(
  `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/assets/${siteId}`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* removeSiteFromProjectWorker(action) {
  yield put(removeSiteFromProject.request());

  const { accessToken, projectId, siteId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, projectId, siteId);
    yield put(removeSiteFromProject.success({ project: response.data, projectId, accessToken }));
    yield put(
      setProjectTableState({
        sortModel: [
          {
            field: "created",
            sort: "desc",
          },
        ],
      })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error patching project: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error patching project: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error patching project: ", err);
      message = err.message;
    }
    message = `Unable to patch project: ${message}`;
    yield put(removeSiteFromProject.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* removeSiteFromProjectWatcher() {
  yield takeLatest(removeSiteFromProject.TRIGGER, removeSiteFromProjectWorker);
}

export default [removeSiteFromProjectWatcher];
