import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Toolbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@components/PanelDisplayTable";
import { uniq, upperFirst } from "lodash";
import DisplayStatus from "@views/sites/components/DisplayStatus";
import { ConnectionEditor } from "./connectionEditor";

const SiteConnectionPanel = ({ application }) => {
  // put connection in component state
  const [connection, setConnection] = useState({
    meters: application?.asset?.meters,
    status: application?.asset?.status,
    timeInStatus: application?.asset?.timeInStatus,
  });

  const tableRows = [
    {
      label: "Manufacturer",
      value:
        uniq(
          connection?.meters?.map((meter) => upperFirst(meter.manufacturer.toLowerCase()))
        )?.join(", ") || "N/A",
    },
    {
      label: "Connection Status",
      value: <DisplayStatus status={connection} />,
    },
  ];

  const isConnected = connection.status !== "NOT_INSTALLED";

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleEdit = () => {
    setIsEditorOpen(true);
  };
  const handleClose = () => {
    setIsEditorOpen(false);
  };

  const handleSave = useCallback((asset) => {
    setConnection({
      meters: asset.meters,
      status: asset.status,
      timeInStatus: asset.timeInStatus,
    });
  }, []);

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Connection
          </Typography>
          <Toolbar sx={{ mt: -2 }}>
            <Tooltip title="Edit Connection">
              <span>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="edit connection"
                  disabled={isConnected}
                  onClick={handleEdit}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ConnectionEditor
          open={isEditorOpen}
          onClose={handleClose}
          onSave={handleSave}
          application={application}
        />
      </Paper>
    </>
  );
};

SiteConnectionPanel.propTypes = {
  application: PropTypes.shape({}),
};

export default SiteConnectionPanel;
