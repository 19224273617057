import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const getApplication = createRoutine("GET_APPLICATION");
const sendProgramTerms = createRoutine("SEND_PROGRAM_TERMS");

// patch the site profile portions of the application
const patchAppSiteProfile = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_APP_SITE_PROFILE"
);

// patch the site owner
const patchAppSiteOwnership = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_APP_SITE_OWNERSHIP"
);

// patch the documents portions of the application
const patchAppSiteDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_APP_SITE_DOCUMENTS"
);

// patch the connection portion of the application
const patchAppSiteConnection = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_APP_SITE_CONNECTION"
);

// patch the installer portion of the application
const patchAppSiteInstaller = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_APP_SITE_INSTALLER"
);

// the review notes
const getApplicationNotes = createRoutine("GET_APPLICATION_NOTES");
const putApplicationNotes = createRoutine("PUT_APPLICATION_NOTES");

export {
  getApplication,
  patchAppSiteProfile,
  patchAppSiteDocuments,
  patchAppSiteOwnership,
  patchAppSiteConnection,
  patchAppSiteInstaller,
  getApplicationNotes,
  putApplicationNotes,
  sendProgramTerms,
};
