import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { includes } from "lodash";
import PageHeader from "@components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { getBuyerInvites } from "../../actions";
import Consent from "@admin/components/Consent";
import CustomDataGrid from "@components/CustomDataGrid";
import { EmailLink } from "@components";
import { get } from "lodash";
import { addBuyerInvite } from "../../actions";
import InviteBuyerEditor from "./InviteBuyerEditor";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { renderCellExpand } from "@components/GridCellExpand";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteInvite from "@views/settings/members/components/ConfirmDeleteInvite";
import { deleteBuyerInvite, resendBuyerInvite } from "../../actions";

const statusColors = {
  joined: "success.main",
  invited: "warning.main",
};

const Buyers = () => {
  const dispatch = useDispatch();
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);

  const { status, invites } = useSelector((state) => state.getBuyerInvites);
  useEffect(() => {
    // if we get consent_required, we'll be stuck on init
    const invitesLoading = includes(["init", "request"], status);
    setLoading(invitesLoading || isLoading);
  }, [isLoading, status]);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "created",
      sort: "desc",
    },
  ]);

  // confirm if deleting an invite
  const [open, setOpen] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const cancelAction = () => {
    setOpen(false);
  };
  const okAction = async () => {
    setOpen(false);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace",
    });
    dispatch(deleteBuyerInvite({ accessToken, inviteId: inviteToDelete.id }));
    setInviteToDelete(null);
  };
  const confirmDeleteInvite = ({ invite }) => {
    setInviteToDelete(invite);
    setOpen(true);
  };

  // invites table
  const columns = React.useMemo(
    () => [
      { headerName: "Name", field: "inviteeName", flex: 1, renderCell: renderCellExpand },
      {
        headerName: "Creation Date",
        field: "created",
        flex: 1,
        hide: true,
        valueFormatter: ({ value }) =>
          DateTime.fromSeconds(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Email",
        field: "invitee",
        flex: 1.2,
        renderCell: ({ value }) => <EmailLink email={value} />,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 0.6,
        renderCell: ({ value: status }) => (
          <Typography color={get(statusColors, status.toLowerCase(), "primary")}>
            {status === "ACCEPTED" ? "Joined" : "Invited"}
          </Typography>
        ),
      },
      {
        headerName: "Date",
        field: "date",
        flex: 0.6,
        valueGetter: ({ row: invite }) =>
          invite.dateAccepted
            ? DateTime.fromISO(invite.dateAccepted)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)
            : DateTime.fromSeconds(invite.modified)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Organization",
        field: "organization",
        flex: 1,
        valueGetter: ({ row: invite }) => invite.organization?.name || "N/A",
        renderCell: renderCellExpand,
      },
      {
        field: "resendInviteAction",
        headerName: "Resend",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const invite = params.row;
          return (
            <IconButton
              onClick={async (ev) => {
                const accessToken = await getAccessTokenSilently({
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                  scope: "admin_marketplace",
                });
                dispatch(resendBuyerInvite({ accessToken, inviteId: invite.id }));
              }}
              aria-label="resend invite"
              size="small"
              sx={{ ml: 1 }}
              disabled={invite.status === "ACCEPTED"}
            >
              <ReplayIcon />
            </IconButton>
          );
        },
      },
      {
        field: "CancelInviteAction",
        headerName: "Cancel",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row: invite }) => {
          return (
            <IconButton
              onClick={async (ev) => {
                confirmDeleteInvite({ invite });
              }}
              aria-label="cancel invite"
              size="small"
              sx={{ ml: 1 }}
              disabled={invite.status === "ACCEPTED"}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    [dispatch, getAccessTokenSilently]
  );

  // create invite action
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleOpenEditor = () => {
    setIsEditorOpen(true);
    dispatch(addBuyerInvite.reset());
  };
  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  return (
    <Page py={3} title="Buyers">
      <Container maxWidth="lg">
        <Consent action={getBuyerInvites} scope="admin_marketplace">
          <PageHeader
            title="Buyers"
            action={{
              name: "Invite Buyer",
              handle: handleOpenEditor,
            }}
          />
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper>
                  <Box height={400}>
                    <CustomDataGrid
                      style={{ border: 0 }}
                      rowHeight={50}
                      loading={loading}
                      rows={invites}
                      columns={columns}
                      disableSelectionOnClick
                      sortModel={sortModel}
                      onSortModelChange={setSortModel}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20]}
                    />
                    <ConfirmDeleteInvite
                      open={open}
                      cancelAction={cancelAction}
                      okAction={okAction}
                      invite={inviteToDelete}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <InviteBuyerEditor open={isEditorOpen} onClose={handleCloseEditor} />
        </Consent>
      </Container>
    </Page>
  );
};

export default Buyers;
