import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCustomerProject } from '../actions';
import Page from 'components/Page';
import { Container, Grid } from '@mui/material';
import PageHeader from 'components/PageHeader';
import Breadcrumb from 'components/Breadcrumb';
import { Box } from '@mui/system';
import CustomerProjectDetails from './CustomerProjectDetails';
import CustomerProjectSites from './CustomerProjectSites';
import IncompleteSitesAlert from './IncompleteSitesAlert';
import Applications from '../../Applications/components/Applications';
import CustomerProjectChart from './CustomerProjectChart';
import { isNil } from 'lodash';

const CustomerProject = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { customerProjectId } = useParams();

  const { customerProject, status } = useSelector(state => state.getCustomerProject);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProject({ accessToken, customerProjectId }));
    }
    if (isNil(customerProject) || customerProject.id !== +customerProjectId) {
      init();
    }
  }, [getAccessTokenSilently, customerProjectId, dispatch, customerProject]);

  const isLoading = status === 'request';

  return (
    <Page title={`View Project: ${customerProject?.title}`} py={3}>
      <Container maxWidth="lg">
        <PageHeader title={customerProject?.title}>
          <Breadcrumb title="Projects List" destination="/projects" />
        </PageHeader>
        <Box mt={3}>
          <Grid container spacing={4}>
            {customerProject?.emissionReductionActivity === 'LOW_CARBON_FUEL' && !isLoading && (
              <Grid item sm={12}>
                <IncompleteSitesAlert customerProject={customerProject} title="Credit Summary"/>
              </Grid>
            )}
            {customerProject?.emissionReductionActivity === 'SOLAR_ELECTRICITY_POWER_GENERATION' && (
              <Grid item sm={12}>
                <CustomerProjectChart customerProject={customerProject}/>
              </Grid>
            )}
            <Grid item sm={12}>
              <CustomerProjectDetails customerProject={customerProject} title="Details"/>
            </Grid>
            <Grid item sm={12}>
              <CustomerProjectSites customerProject={customerProject} title="Sites"/>
            </Grid>
            {customerProject?.isPublic && (
              <Grid item sm={12}>
                <Applications customerProject={customerProject} title="Applications"/>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerProject;