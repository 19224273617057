import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "@components/Logo";

const TopBar = ({ onMobileNavOpen, ...rest }) => {
  return (
    <AppBar
      sx={{
        color: "primary.main",
        backgroundColor: "common.white",
      }}
      elevation={1}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/dashboard">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
