import { connect } from "react-redux";
import SiteIncidents from "@site/components/incidents/SiteIncidents";
import { bindActionCreators } from "redux";
import {
  getSiteIncidents,
  subscribeToSiteNotifications,
  unsubscribeFromSiteNotifications,
  getSubscriptions,
} from "@site/actions";

const mapStateToProps = ({
  getSiteIncidents: getSiteIncidentsResult,
  getFirstReportDate: getFirstReportDateResult,
  subscribeToSiteNotifications: subscribeToSiteNotificationsResult,
  unsubscribeFromSiteNotifications: unsubscribeFromSiteNotificationsResult,
  getSubscriptions: getSubscriptionsResult,
}) => {
  return {
    getSiteIncidentsResult,
    getFirstReportDateResult,
    subscribeToSiteNotificationsResult,
    unsubscribeFromSiteNotificationsResult,
    getSubscriptionsResult,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSiteIncidents,
      subscribeToSiteNotifications,
      unsubscribeFromSiteNotifications,
      getSubscriptions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiteIncidents);
