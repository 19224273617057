import React from "react";
import { Error } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { isNil } from "lodash";

const RequiredField = ({ reviewOnly, value }) => (!reviewOnly && isNil(value)) ? (
  <Tooltip title="required to generate credits">
    <Error color="error" />
  </Tooltip>
) : value;

export default RequiredField;
