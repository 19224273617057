import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextArea from "@components/TextArea";

const ApplicationNotes = ({
  applicationId,
  getApplicationNotesResult,
  putApplicationNotes,
  putApplicationNotesResult,
  dense = false,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // store notes in component state
  const [notes, setNotes] = useState("");
  useEffect(() => {
    if (getApplicationNotesResult.status === "success") {
      setNotes(getApplicationNotesResult.notes || "");
    }
  }, [getApplicationNotesResult]);

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    putApplicationNotes({ accessToken, applicationId, notes });
  };

  const isSubmitting = putApplicationNotesResult.status === "request";

  return (
    <Paper sx={dense ? { p: 4 } : { mt: 2, px: 10, py: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Notes
        </Typography>
        <LoadingButton
          aria-label="save"
          color="primary"
          size="small"
          onClick={handleSave}
          loading={isSubmitting}
          sx={{ marginBottom: 1 }}
        >
          Save
        </LoadingButton>
      </Box>
      <Box>
        <TextArea
          onChange={(ev) => setNotes(ev.target.value)}
          value={notes}
          inputProps={{
            maxLength: 8192,
          }}
          placeholder="Add any review notes for this application and save them. They are private to members of your organization."
        />
      </Box>
    </Paper>
  );
};

ApplicationNotes.propTypes = {
  applicationId: PropTypes.string,

  getApplicationNotesResult: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,

  putApplicationNotes: PropTypes.func.isRequired,
  putApplicationNotesResult: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default ApplicationNotes;
