import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * This is a light tooltip suitable for use where we want to show a hyperlink.
 * eg. in a table cell 
 */
const LinkTooltip = styled(({ className, ...props }) => (
  // this is a system-provided className eg. "css-9qgv68"
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: "0.875rem",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default LinkTooltip;
