import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@components/PanelDisplayTable";
import { useSelector } from "react-redux";
import BankInformationEditor from "./BankInformationEditor";

// PATTERN:
// get (eg user profile)
// put in state
// display state
// update user profile
// update state with patch results
// - no need to fetch again
// - no need to mutate the getUserProfile reducer

const BankInformationPanel = () => {
  const { userProfile: initialUserProfile } = useSelector((state) => state.getUserProfile);

  // put userProfile in component state
  const [userProfile, setUserProfile] = useState(initialUserProfile);

  const tableRows = [
    {
      label: "Institution Number",
      value: userProfile.bankInformation?.institutionNumber,
    },
    {
      label: "Transit Number",
      value: userProfile.bankInformation?.transitNumber,
    },
    {
      label: "Account Number",
      value: userProfile.bankInformation?.accountNumber,
    },
  ];

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleEdit = () => {
    setIsEditorOpen(true);
  };
  const handleClose = () => {
    setIsEditorOpen(false);
  };

  const handleSave = (userProfile) => {
    setUserProfile(userProfile);
  };

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Bank Information
          </Typography>
          <Tooltip title="Edit bank information">
            <IconButton
              edge="end"
              color="primary"
              aria-label="edit bank information"
              onClick={handleEdit}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TableContainer>
          <Table aria-label="bank info">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BankInformationEditor
          open={isEditorOpen}
          onClose={handleClose}
          onSave={handleSave}
          userProfile={userProfile}
        />
      </Paper>
    </>
  );
};

export default BankInformationPanel;
