import React, { useMemo, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { Typography, Paper, IconButton, Box, Button } from "@mui/material";
import ViewIcon from "@mui/icons-material/Visibility";
import CustomDataGrid from "@components/CustomDataGrid";
import { includes, reduce } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { wmTokWh } from "@utils/energyHelpers";
import CreateProjectReportEditor from "./createProjectReport/CreateProjectReportEditor";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { round } from "@utils/numberHelpers";

const ProjectReports = forwardRef(({ project, getProjectReportsResult }, ref) => {
  const { projectReports } = getProjectReportsResult;
  const loading = includes(["init", "request"], getProjectReportsResult.status);
  const { projectId } = useParams();
  const navigate = useNavigate();

  // figure out the start date for a new project report (YYYY-mm-dd)
  let projectReportStartDate = reduce(
    projectReports,
    (result, projectReport) =>
      DateTime.max(DateTime.fromISO(projectReport.endDate), DateTime.fromISO(result)),
    DateTime.fromISO(project.startDate)
  );
  if (projectReports.length) {
    projectReportStartDate = projectReportStartDate.plus({ days: 1 });
  }

  // table columns
  const columns = useMemo(
    () => [
      { headerName: "Report ID", field: "id", flex: 1 },
      {
        headerName: "Date Created",
        field: "created",
        flex: 1,
        valueFormatter: ({ value }) =>
          DateTime.fromSeconds(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Start Date",
        field: "startDate",
        flex: 1,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "End Date",
        field: "endDate",
        flex: 1,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Solar Energy (kWh)",
        flex: 1,
        field: "totalEnergyGenerated",
        valueFormatter: ({ value }) =>
          wmTokWh(value).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
      },
      {
        headerName: "Emissions Offsets",
        flex: 1,
        field: "totalEnvironmentalCredits",
        valueFormatter: ({ value }) => round(value, 3).toLocaleString(),
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="view"
              size="small"
              sx={{ ml: 1 }}
              onClick={(ev) => {
                const projectReport = params.row;
                navigate(`/admin/projects/${projectId}/projectReports/${projectReport.id}`);
              }}
            >
              <ViewIcon />
            </IconButton>
          );
        },
      },
    ],
    [navigate, projectId]
  );

  // create project reports action
  const [isProjectReportEditorOpen, setIsProjectReportEditorOpen] = useState(false);
  const handleOpenProjectReportEditor = () => {
    setIsProjectReportEditorOpen(true);
  };
  const handleCloseProjectReportEditor = () => {
    setIsProjectReportEditorOpen(false);
  };

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "endDate",
      sort: "desc",
    },
  ]);
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography ref={ref} variant="h3" gutterBottom ml={2}>
          Project Reports
        </Typography>
        <Box>
          <Button
            variant="contained"
            size="small"
            onClick={handleOpenProjectReportEditor}
            disabled={loading}
            sx={{ mt: -1 }}
          >
            Create Project Report
          </Button>
        </Box>
      </Box>

      <Paper>
        <div style={{ height: 358, width: "100%" }}>
          <CustomDataGrid
            style={{ border: 0 }}
            rowHeight={50}
            loading={loading}
            rows={projectReports}
            columns={columns}
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            localeText={{
              noRowsLabel: "No project reports.",
            }}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </div>
      </Paper>
      <CreateProjectReportEditor
        project={project}
        startDate={projectReportStartDate}
        open={isProjectReportEditorOpen}
        onClose={handleCloseProjectReportEditor}
      />
    </>
  );
});

ProjectReports.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    startDate: PropTypes.string, // YYYY-mm-dd
  }),
  getProjectReportsResult: PropTypes.shape({}).isRequired,
};

export default ProjectReports;
