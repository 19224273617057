import { call, put, takeLatest } from "redux-saga/effects";
import { submitRequestToPurchase } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, projectReportId, pricePerCredit) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/marketplace/purchaseRequests`,
    { projectReportId, pricePerCredit },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* submitRequestToPurchaseWorker(action) {
  yield put(submitRequestToPurchase.request());

  const { accessToken, projectReportId, pricePerCredit } = action.payload;
  try {
    const response = yield call(callApi, accessToken, projectReportId, pricePerCredit);
    const purchaseRequest = response.data;
    yield put(submitRequestToPurchase.success({ purchaseRequest }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to purchase project report: ${message}`;
    yield put(submitRequestToPurchase.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* submitRequestToPurchaseWatcher() {
  yield takeLatest(submitRequestToPurchase.TRIGGER, submitRequestToPurchaseWorker);
}

export default [submitRequestToPurchaseWatcher];
