import React, { useEffect } from "react";
import Page from "@components/Page";
import { Container, Typography } from "@mui/material";
import PageLoader from "@components/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { find, isEmpty } from "lodash";
import { getUserProfile } from "views/common/actions";
import { getCustomerProject } from "../../CustomerProjects/actions";
import { getMyApplications } from "@views/myApplications/actions";
import { isNil } from "lodash";
import { application as appActions, submitProjectApplication } from "../actions";
import { key as historyKey, useNext } from "./useNextPrev";
import { last } from "lodash";
import { getSite } from "views/site/actions";

const Start = () => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const next = useNext();

  const application = useSelector((state) => state.customerProjectApplication);
  const { customerProjectId } = application;

  // get the user profile
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getUserProfile({ accessToken, userId: user.sub }));
    }
    // don't refetch the user profile if we already have one
    fetchUserProfile();
  }, [dispatch, user, getAccessTokenSilently]);
  const { userProfile, status: getUserStatus } = useSelector((state) => state.getUserProfile);

  // get the customer project details
  const { customerProject, status: getProjectStatus } = useSelector((state) => state.getCustomerProject);
  useEffect(() => {
    // get it if we don't already have it
    if (customerProject?.id !== customerProjectId && getProjectStatus !== "request") {
      dispatch(getCustomerProject({ customerProjectId }));
    }
  }, [customerProjectId, customerProject, getProjectStatus, dispatch]);

  // once userProfile is loaded, we can check for terms and personal
  const navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(userProfile)) {
      // legacy user who missed out on supplying personal info
      const missingPersonal =
        isNil(userProfile.givenName) ||
        isNil(userProfile.familyName) ||
        isNil(userProfile.phone);
      const missingTerms = !userProfile.acceptedTermsAndConditions;

      // nb. we must return to this page, after visiting either of the first 2 (terms and personal)
      // hence we use navigate instead of next
      if (missingTerms) {
        navigate("/projects/apply/terms");
      } else if (missingPersonal) {
        navigate("/projects/apply/personal");
      }
    }
  }, [user, userProfile, dispatch, navigate]);

  // get applicant's submitted applications
  const { applications, status: getAppsStatus } = useSelector((state) => state.getMyApplications);
  useEffect(() => {
    async function fetchApplications() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getMyApplications({ accessToken }));
    }
    if (userProfile?.acceptedTermsAndConditions && getAppsStatus === "init") {
      fetchApplications();
    }
  }, [dispatch, getAccessTokenSilently, getAppsStatus, userProfile]);

  // get applicant's site, if they have one
  useEffect(() => {
    async function fetchSite(siteId) {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSite({ accessToken, siteId })); // status
    }
    if (userProfile?.assetId) {
      fetchSite(userProfile.assetId);
    }
  }, [dispatch, getAccessTokenSilently, userProfile]);
  const { site, status: getApplicantSiteStatus } = useSelector((state) => state.getSite);

  const history = useSelector((state) => state.history[historyKey])
  // handle the navigation for an onboarded user (i.e. accepted T&Cs and provided personal info)
  useEffect(() => {
    // each application page depends on the project so we can't nav without it
    if (getProjectStatus === "success") {
      // we'll have history if the customer refreshed the page or logged out and logged back in
      if (!isEmpty(history)) {
        const latestHistory = last(history);
        navigate(latestHistory);
      // the rest inits the application
      } else if (userProfile && getAppsStatus === "success") {
        // N.B no applications = no sites - single-site homeonwers can only onboard sites via applications
        if (isEmpty(applications)) {
          dispatch(appActions.save({
            customerProjectId,
            userId: user.sub,
            email: user.email,
          }));
          next("sitedetails");
        } else if (getApplicantSiteStatus === "success" && site) {
          const submittedApplication = find(applications, { customerProjectId });
          // they already submitted an application to *this* project - so just show the thank you page
          if (submittedApplication) {
            dispatch(submitProjectApplication.loadSaved({ application: submittedApplication }));
            next("thankyou");
          } else {
            // homeowner submitted application to another project - now submit an application for the existing site to *this* project
            dispatch(appActions.save({
              customerProjectId,
              userId: user.sub,
              email: user.email,
              asset: {
                ...site,
                documents: [],
                exists: true, // hint for resubmission of same site to a different program
              },
            }));
            // we just need to collect and other docs required for this project
            next("docs");
          }
        }
      }
    }
  }, [
    applications,
    customerProjectId,
    getAppsStatus,
    getProjectStatus,
    getApplicantSiteStatus,
    site,
    userProfile,
    user,
    next,
    navigate,
    history,
    dispatch
  ]);

  const isError =
    getProjectStatus === "failure" ||
    getUserStatus === "failure" ||
    getAppsStatus === "failure";

  return (
    <Page title="Start" py={3}>
      <Container maxWidth="lg">
        {isError ? (
          <Typography variant="h4">
            Sorry we had a problem starting your application. Please try again.
          </Typography>
        ) : (
          <PageLoader message="Just a moment..." />
        )}
      </Container>
    </Page>
  );
};

export default Start;
