import { call, put, takeLatest } from "redux-saga/effects";
import { publishLiveSite } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { join, values } from "lodash";

const callApi = async (accessToken, siteId, publicEnabled) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/${siteId}`,
    {
      publicEnabled,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export function* publishLiveSiteWorker(action) {
  yield put(publishLiveSite.request());

  const { accessToken, siteId, publicEnabled } = action.payload;

  try {
    const response = yield call(callApi, accessToken, siteId, publicEnabled);
    const site = response.data;
    yield put(publishLiveSite.success({ site }));
    const message = publicEnabled
      ? "Live site successfully published"
      : "Live site has been unpublished successfully";
    yield put(showAppMessage({ severity: "success", message }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error publishing live site: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error publishing live site: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error publishing live site: ", err);
      message = err.message;
    }
    message = `Unable to publish live site: ${message}`;
    yield put(publishLiveSite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* publishLiveSiteWatcher() {
  yield takeLatest(publishLiveSite.TRIGGER, publishLiveSiteWorker);
}

export default [publishLiveSiteWatcher];
