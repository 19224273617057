import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { patchAppSiteDocuments } from "@views/reviewApplication/actions";
import Documents from "../components/Documents";

const mapStateToProps = ({ approveApplication }) => ({
  approveApplicationResult: approveApplication,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPatchAppSiteDocuments: () => dispatch(patchAppSiteDocuments.reset()),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
