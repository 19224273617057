import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  CardHeader,
  alpha,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { get } from "lodash";

const ProgressBar = styled(LinearProgress)(({ theme, barcolor, value }) => {
  const startColor = get(theme.palette, barcolor);
  const endColor = alpha(startColor, 0.5);
  return {
    height: 16,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      background: `linear-gradient(90deg, ${startColor} ${
        100 - value
      }%, ${endColor} 100%);`,
    },
  };
});

const ScoreCard = ({
  title,
  primary,
  secondary,
  color,
  loading,
  percentComplete,
}) => (
  <Card
    sx={{
      height: "100%",
    }}
  >
    <CardHeader title={<Typography variant="h5">{title}</Typography>} />
    <CardContent
      sx={{
        minHeight: 68,
        "&:last-child": {
          py: 0,
        },
      }}
    >
      {loading && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <CircularProgress sx={{ color: "grey.500" }} size={24} />
        </Box>
      )}
      {!loading && (
        <>
          <Typography variant="body1" component="span" color={color}>
            {primary}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="span"
            ml={2}
          >
            {secondary}
          </Typography>
          <Box sx={{ width: "100%" }} mt={1}>
            <ProgressBar
              barcolor={color}
              variant="determinate"
              value={percentComplete}
            />
          </Box>
        </>
      )}
    </CardContent>
  </Card>
);

ScoreCard.defaultProps = {
  loading: false,
};

ScoreCard.propTypes = {
  title: PropTypes.string.isRequired,

  // primary stat line
  primary: PropTypes.string.isRequired,

  //secondary stat line
  secondary: PropTypes.string.isRequired,

  percentComplete: PropTypes.number.isRequired,

  // for the bar, gradient end color is 0.5 alpha
  color: PropTypes.string,

  loading: PropTypes.bool,
};

export default ScoreCard;
