import { getIndividualApplications } from "../actions";

export const initialState = {
  status: "init",
  applications: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getIndividualApplications.REQUEST: {
      return {
        ...state,
        ...initialState,
        status: "request",
      };
    }

    case getIndividualApplications.SUCCESS: {
      const { applications } = action.payload;
      return {
        ...state,
        status: "success",
        applications,
      };
    }

    case getIndividualApplications.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    case getIndividualApplications.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
