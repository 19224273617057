import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChannelPartnerGuard = ({ children }) => {
  const navigate = useNavigate();

  // if a non-channel partner user tries to access a channel partner specific page, send them to a 404

  const { organization, status } = useSelector((state) => state.getOrganization);
  if (!organization.isChannelPartner && status === "success") {
    navigate("/404", { replace: true });
    return null;
  }

  return children;
};

ChannelPartnerGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChannelPartnerGuard;
