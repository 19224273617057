import { patchSitePayee } from "../actions";

export const initialState = {
  status: "init",
  site: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case patchSitePayee.RESET:
      return initialState;

    case patchSitePayee.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case patchSitePayee.SUCCESS: {
      const { site } = action.payload;
      return {
        ...state,
        status: "success",
        site,
      };
    }

    case patchSitePayee.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
