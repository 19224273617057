import React from "react";
import Page from "@components/Page";
import PurchaseRequests from "./PurchaseRequests";
import { Container, Grid, Typography } from "@mui/material";
import PurchaseSummary from "./PurchaseSummary";
import PurchaseRequestChart from "./PurchaseRequestChart";
import PageHeader from "@components/PageHeader";

const Portfolio = () => (
  <Page title="Portfolio" py={3}>
    <Container maxWidth="lg">
      <PageHeader title="Portfolio" />
      <Grid container spacing={4} pt={1}>
        <Grid item xs={12}>
          <Typography variant="h3" ml={1} gutterBottom>
            Purchase Summary
            <Typography variant="caption" color="textSecondary">
              {" "}
              • Completed Transactions
            </Typography>
          </Typography>
          <PurchaseSummary />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" ml={1} gutterBottom>
            Status of Purchase Requests
            <Typography variant="caption" color="textSecondary">
              {" "}
              • In Progress Transactions
            </Typography>
          </Typography>
          <PurchaseRequestChart />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" ml={1} gutterBottom>
            Purchase Request Details
            <Typography variant="caption" color="textSecondary">
              {" "}
              • All Transactions
            </Typography>
          </Typography>
          <PurchaseRequests />
        </Grid>
      </Grid>
    </Container>
  </Page>
);

export default Portfolio;
