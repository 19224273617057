import { DateTime, Interval, Duration } from "luxon";
import { some, map, filter, reduce, sortBy } from "lodash";
import logger from "debug";

class Granularity {
  static NINETY_DAYS = new Granularity("90 Days");
  static YEAR = new Granularity("1 Year");
  static FIVE_YEARS = new Granularity("All Time"); // on purpose

  constructor(name) {
    this.name = name;
  }
}

class IncidentStatus {
  static INCIDENT = new IncidentStatus("Incident");
  static NO_INCIDENT = new IncidentStatus("NO_INCIDENT");
  static NOT_APPLICABLE = new IncidentStatus("NOT_APPLICABLE"); // before site came online

  constructor(name) {
    this.name = name;
  }
}

/**
 * Filters out incidents occurring (in their entirety) before chartStartDate,
 * converts incident start/end dates to luxon datetime,
 * and calculates incident intervals.
 * @param  {Array} incidents
 * @param  {DateTime} chartStartDate
 * @return {void
 */
const prepareIncidents = (incidents, chartStartDate) => {
  const preparedIncidents = map(incidents, (incident) => {
    const startDate = DateTime.fromISO(incident.startDate, {
      zone: "UTC",
    }).startOf("day");
    const endDate = incident.endDate
      ? DateTime.fromISO(incident.endDate, {
          zone: "UTC",
        }).endOf("day")
      : DateTime.utc().endOf("day");
    return {
      ...incident,
      startDate,
      endDate,
      interval: Interval.fromDateTimes(startDate, endDate),
    };
  });
  return sortBy(
    filter(preparedIncidents, (inc) => inc.endDate > chartStartDate),
    "startDate"
  );
};

/**
 * Check to see if any of the incident intervals overlap the provided date.
 * @param  {DateTime} date
 * @param  {Array} incidents
 * @param  {DateTime} firstReportDate
 *
 * @return {IncidentStatus}
 */
const incidentStatus = (date, incidents, firstReportDate) => {
  if (date < firstReportDate) {
    return IncidentStatus.NOT_APPLICABLE;
  } else {
    return some(
      incidents,
      (incident) => incident.endDate >= date && incident.startDate <= date
    )
      ? IncidentStatus.INCIDENT
      : IncidentStatus.NO_INCIDENT;
  }
};

/**
 * Check to see if the provided interval overlaps with any of the incident intervals.
 * @param  {Interval} interval
 * @param  {Array}    incidents
 * @param  {DateTime} firstReportDate
 *
 * @return {IncidentStatus}
 */
const incidentIntersection = (interval, incidents, firstReportDate) => {
  if (interval.isBefore(firstReportDate)) {
    return IncidentStatus.NOT_APPLICABLE;
  } else {
    return some(
      incidents,
      (incident) => incident.interval.intersection(interval) != null
    )
      ? IncidentStatus.INCIDENT
      : IncidentStatus.NO_INCIDENT;
  }
};

/**
 * Figure out when our chart should start, based on granularity, in UTC.
 * @param  {Granularity} granularity
 * @return {DateTime}
 */
const getStartDate = (granularity) => {
  switch (granularity) {
    case Granularity.NINETY_DAYS:
      return DateTime.utc().minus({ days: 90 });
    case Granularity.YEAR:
      // this is the monday
      return DateTime.utc()
        .endOf("week")
        .plus({ days: 1 })
        .minus({ weeks: 52 });
    case Granularity.FIVE_YEARS:
      return DateTime.utc().startOf("month").minus({ years: 5 });
    default:
      logger("weedle:error")("Illegal argument: ", granularity);
      return null;
  }
};

/**
 * Takes our list of incidents, and computes, for example,
 * for each day over the last 90 days, was the site in an incident or not?
 * Also, was this before the firstReportDate? Then null.
 * @param  {Array}       incidents        assumes not empty
 * @param  {Granularity} granularity
 * @param  {DateTime}    firstReportDate
 * @return {Array<{date, incidentStatus}>}
 */
const getChartData = (incidents, granularity, firstReportDate) => {
  const data = [];
  let preparedIncidents;
  switch (granularity) {
    case Granularity.NINETY_DAYS:
      let startDate = getStartDate(granularity);
      preparedIncidents = prepareIncidents(incidents, startDate);
      for (let i = 0; i < 90; i++) {
        data.push({
          date: startDate,
          incidentStatus: incidentStatus(
            startDate,
            preparedIncidents,
            firstReportDate
          ),
        });
        startDate = startDate.plus({ days: 1 });
      }
      return data;
    case Granularity.YEAR:
      // first day of starting week
      let startWeek = getStartDate(granularity);
      preparedIncidents = prepareIncidents(incidents, startWeek);
      for (let i = 0; i < 52; i++) {
        const interval = Interval.fromDateTimes(
          startWeek,
          startWeek.endOf("week")
        );
        data.push({
          date: startWeek,
          incidentStatus: incidentIntersection(
            interval,
            preparedIncidents,
            firstReportDate
          ),
        });
        startWeek = startWeek.plus({ weeks: 1 });
      }
      return data;
    case Granularity.FIVE_YEARS:
      // just do last 5 years
      preparedIncidents = prepareIncidents(
        incidents,
        DateTime.fromISO("1900-01-01")
      );
      const currentMonth = DateTime.utc().startOf("month");
      let startMonth = getStartDate(granularity);
      while (startMonth <= currentMonth) {
        const interval = Interval.fromDateTimes(
          startMonth,
          startMonth.endOf("month")
        );
        data.push({
          date: startMonth,
          incidentStatus: incidentIntersection(
            interval,
            preparedIncidents,
            firstReportDate
          ),
        });
        startMonth = startMonth.plus({ months: 1 });
      }
      return data;

    default:
      return null;
  }
};

/**
 * Gets some stats about these incidents.
 * NB. availability must respect reportStartDate
 * NB. assumption: we don't have incidents before reportStartDate
 *
 * Availability: total time not in an incident, compared to total time.
 * MTBF: The average duration between incidents (end to start).
 * MTTR: The average duration of the incident. NB for an open incident, we use now as the end date.
 * @param  {Array} incidents
 * @param  {Granularity} granularity
 * @param  {DateTime} firstReportDate
 * @return {{availability<float|null>, meanTimeToRepair<Duration|null>, meanTimeBetweenFails<Duration|null>}}
 */
const getAvailabilityStats = (incidents, granularity, firstReportDate = null) => {
  const startDate = DateTime.fromMillis(Math.max(firstReportDate, getStartDate(granularity)));
  const filteredIncidents = sortBy(
    filter(incidents, (inc) => {
      const endDate = inc.endDate
        ? DateTime.fromISO(inc.endDate, {
            zone: "UTC",
          })
        : DateTime.utc();
      return endDate > startDate;
    }),
    "startDate"
  );

  const unavailableDuration = reduce(
    filteredIncidents,
    (total, inc) => {
      const startDate = DateTime.fromISO(inc.startDate, {
        zone: "UTC",
      });
      const endDate = inc.endDate
        ? DateTime.fromISO(inc.endDate, {
            zone: "UTC",
          })
        : DateTime.utc();
      const duration = Interval.fromDateTimes(startDate, endDate).toDuration(
        "seconds"
      );
      return total.plus(duration);
    },
    Duration.fromMillis(0)
  );

  const totalDuration = Interval.fromDateTimes(startDate, DateTime.utc()).toDuration(
    "seconds"
  );

  // if eg. 3 incidents, can only have 2 intervals where we are measuring mtbf
  //  (regardless of whether incident is open, or if it starts before startDate)
  const availableBetweenDuration = reduce(
    filteredIncidents,
    (total, inc, index) => {
      if (index < filteredIncidents.length - 1) {
        const endDate = DateTime.fromISO(inc.endDate, {
          zone: "UTC",
        });
        const nextIncident = filteredIncidents[index + 1];
        const startDate = DateTime.fromISO(nextIncident.startDate, {
          zone: "UTC",
        });
        const duration = Interval.fromDateTimes(endDate, startDate).toDuration(
          "seconds"
        );
        return total.plus(duration);
      }
      return total;
    },
    Duration.fromMillis(0)
  );

  return {
    availability: totalDuration > 0 ? (totalDuration - unavailableDuration) / totalDuration : null,
    meanTimeToRepair:
      filteredIncidents.length > 0
        ? Duration.fromMillis(unavailableDuration.toMillis() / filteredIncidents.length)
        : null,
    meanTimeBetweenFails:
      filteredIncidents.length > 1
        ? Duration.fromMillis(
            availableBetweenDuration.toMillis() / (filteredIncidents.length - 1)
          )
        : null,
  };
};

export {
  Granularity,
  IncidentStatus,
  prepareIncidents,
  incidentStatus,
  incidentIntersection,
  getStartDate,
  getChartData,
  getAvailabilityStats,
};
