import { call, put, takeLatest } from "redux-saga/effects";
import { downloadEft } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async ({ accessToken, payeeStatementId }) => {
  return await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/payeeStatements/${payeeStatementId}/downloadEft`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      responseType: 'blob', // important
    }
  );
};

const processError = async (response) => {
  let formattedMessage, message;
  const text = await response.data.text();
  const data = JSON.parse(text);

  // client received an error response (5xx, 4xx)
  logger("weedle:error")(
    "Error saving EFT file: ",
    data
  );
  const validationErrors = data?.validationErrors;
  if (validationErrors) {
    formattedMessage = (
      <ValidationErrors validationErrors={validationErrors} />
    );
    message = JSON.stringify(flattenObject(validationErrors));
  } else {
    message = data.reason || data.message;
  }
  return { formattedMessage, message };
}

function* downloadEftWorker(action) {
  yield put(downloadEft.request());

  try {
    const response = yield call(callApi, action.payload);
    yield put(downloadEft.success());

    let header = response.headers.get("Content-Disposition");
    const regex = /filename="(.+)"/gm;
    const matches = regex.exec(header);
    const fname = matches ? matches[1] : "Untitled.csv";

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fname);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // slightly different approach than normal, because we asked for a blob
      ({ message, formattedMessage } =  yield call(processError, err.response));
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")(
        "Error saving EFT file: ",
        err.request
      );
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error saving EFT file: ", err);
      message = err.message;
    }
    message = `Unable to save EFT file: ${message}`;
    yield put(downloadEft.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* downloadEftWatcher() {
  yield takeLatest(downloadEft.TRIGGER, downloadEftWorker);
}

export default [downloadEftWatcher];
