import { call, put, takeLatest } from "redux-saga/effects";
import { deleteProjectReportDocument } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async (accessToken, projectReport, doc) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projectReports/${projectReport.id}/docs/${doc.id}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* deleteProjectReportDocumentWorker(action) {
  yield put(deleteProjectReportDocument.request());

  const { accessToken, projectReport, document } = action.payload;

  try {
    const response = yield call(callApi, accessToken, projectReport, document);
    yield put(deleteProjectReportDocument.success({ projectReport: response.data }));
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error deleting project report document: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error deleting project report document: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error deleting project report document: ", err);
      message = err.message;
    }
    message = `Unable to delete project report document: ${message}`;
    yield put(deleteProjectReportDocument.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* deleteProjectReportDocumentWatcher() {
  yield takeLatest(deleteProjectReportDocument.TRIGGER, deleteProjectReportDocumentWorker);
}

export default [deleteProjectReportDocumentWatcher];
