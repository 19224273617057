import findSites from "./findSites";
import requestSmaPermission from "./requestSmaPermission";
import siteInfo from "./siteInfo";
import testConnection from "./testConnection";
import getTimeZone from "./getTimeZone";
import getAddress from "./getAddress";
import uploadFiles from "./uploadFiles";
import addSites from "./addSites";

const reducers = {
  findSites,
  requestSmaPermission,
  siteInfo,
  testConnection,
  getTimeZone,
  getAddress,
  uploadFiles,
  addSites,
};

export default reducers;