import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Defaults are set in reducer. Note when showing a message from a dialog box, we have a small
 * problem. Clicking the away button will also close the dialog, and not hit the onClose handler for
 * that dialog. This is because of the location of the <AppMessage /> element. In that case, it is
 * better to use Notification and handle the state yourself.
 */
const AppMessage = ({
  appMessage: { open, message, severity, duration },
  reset,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    reset();
  };

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

AppMessage.propTypes = {
  appMessage: PropTypes.shape({
    open: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    severity: PropTypes.oneOf(["error", "info", "success", "warning"]),
    duration: PropTypes.number, // time in ms, or null for infinite (default)
  }).isRequired,
  reset: PropTypes.func.isRequired,
};

export default AppMessage;
