import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper } from "@mui/material";
import { APPLICATION_STATUSES } from "@views/programs";
import { get } from "lodash";
import { getFullName } from "utils/stringHelpers";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";

const StatusPanel = ({ application }) => {
  const [status, setStatus] = useState(application.status);
  const [lastStatusChange, setLastStatusChange] = useState(
    application.lastStatusChange
  );

  // if we load up another application
  useEffect(() => {
    setStatus(application.status);
    setLastStatusChange(application.lastStatusChange);
  }, [application.status, application.lastStatusChange]);

  // render
  const statusChangeDate = lastStatusChange
    ? DateTime.fromISO(lastStatusChange.date)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATETIME_SHORT)
    : null;
  const changedBy = ` Changed by ${getFullName(
    lastStatusChange?.changedBy,
    null,
    lastStatusChange?.changedBy?.email
  )} on ${statusChangeDate}`;
  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4" gutterBottom>
          Status
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          This application is{" "}
          <strong>{get(APPLICATION_STATUSES, status)}</strong>.
          {statusChangeDate && changedBy}
        </Typography>
      </Box>
    </Paper>
  );
};

StatusPanel.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
  }),
};

export default StatusPanel;
