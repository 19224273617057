import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { isNil, map, keys } from "lodash";
import * as Yup from "yup";
import LaunchIcon from "@mui/icons-material/Launch";
import ProjectSitesPicker from "../containers/ProjectSitesPicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PROTOCOLS, ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import { startsWith } from "lodash";

export const editProjectSchema = Yup.object().shape({
  title: Yup.string().max(255, "Too long.").required("Project name is required."),
  environmentalCredit: Yup.string().required("Environmental credit is required."),
  startDate: Yup.date().required("Start date is required."),
  endDate: Yup.date().nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .when("environmentalCredit", {
      is: (credit) => credit === "REWATT_VOLUNTARY_CREDIT",
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required("End date is required."),
    }),
  listingUrl: Yup
    .string()
    .transform((value) => isNil(value) || value === ""
      ? null
      : (startsWith(value, 'http')  ? value : `https://${value}`))
    .nullable()
    .url(),
  emissionsFactor: Yup.number()
  .typeError("Emissions factor must be a number")
  .nullable()
  .transform((_, val) => (val !== "" ? Number(val) : null)),
});

export const createProjectSchema = editProjectSchema.shape({
  assetIds: Yup.array().of(Yup.number()).min(1, "Choose at least 1 asset."),
});

const AEOProjectFields = () => {
  const { errors, control } = useFormContext();
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Protocol"
          variant="standard"
          fullWidth
          required
          value={PROTOCOLS.DISTRIBUTED_RENEWABLE_ENERGY_GENERATION}
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://open.alberta.ca/publications/9781460107942"
                  href="https://open.alberta.ca/publications/9781460107942"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Authority"
          variant="standard"
          fullWidth
          required
          value="Government of Alberta"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www.alberta.ca/alberta-emission-offset-system.aspx"
                  href="https://www.alberta.ca/alberta-emission-offset-system.aspx"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Registry"
          variant="standard"
          fullWidth
          required
          value="CSA Group"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://alberta.csaregistries.ca"
                  href="https://alberta.csaregistries.ca"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="Start Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.startDate?.message)}
                  helperText={errors.startDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="startDate"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="End Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              required
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.endDate?.message)}
                  helperText={errors.endDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="endDate"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Emissions Factor"
              variant="standard"
              fullWidth
              error={!isNil(errors.emissionsFactor?.message)}
              helperText={errors.emissionsFactor?.message || "Emissions Factor (tCO2e/MWh)"}
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
          control={control}
          name="emissionsFactor"
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Listing URL"
              variant="standard"
              fullWidth
              error={!isNil(errors.listingUrl?.message)}
              helperText={errors.listingUrl?.message || "URL where the project is listed on the registry"}
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
          control={control}
          name="listingUrl"
        />
      </Grid>
    </>
  );
};

const CSACleanProjectFields = () => {
  const { errors, control } = useFormContext();
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Protocol"
          variant="standard"
          fullWidth
          required
          value={PROTOCOLS.DISTRIBUTED_RENEWABLE_ENERGY_GENERATION}
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://open.alberta.ca/publications/9781460107942"
                  href="https://open.alberta.ca/publications/9781460107942"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Authority"
          variant="standard"
          fullWidth
          required
          value="CSA Group"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www.csaregistries.ca/GHG_VR_Listing/CPR_LandingPage"
                  href="https://www.csaregistries.ca/GHG_VR_Listing/CPR_LandingPage"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Registry"
          variant="standard"
          fullWidth
          required
          value="CSA Group"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www.csaregistries.ca/GHG_VR_Listing/CleanProjectProjects"
                  href="https://www.csaregistries.ca/GHG_VR_Listing/CleanProjectProjects"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="Start Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.startDate?.message)}
                  helperText={errors.startDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="startDate"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="End Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              required
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.endDate?.message)}
                  helperText={errors.endDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="endDate"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Emissions Factor"
              variant="standard"
              fullWidth
              error={!isNil(errors.emissionsFactor?.message)}
              helperText={errors.emissionsFactor?.message || "Emissions Factor (tCO2e/MWh)"}
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
          control={control}
          name="emissionsFactor"
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Listing URL"
              variant="standard"
              fullWidth
              error={!isNil(errors.listingUrl?.message)}
              helperText={errors.listingUrl?.message || "URL where the project is listed on the registry"}
              onChange={onChange}
              value={value}
              color="secondary"
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
          control={control}
          name="listingUrl"
        />
      </Grid>
    </>
  );
};

const RewattVoluntaryProjectFields = () => {
  const { errors, control } = useFormContext();
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Protocol"
          variant="standard"
          fullWidth
          required
          value={PROTOCOLS.DISTRIBUTED_RENEWABLE_ENERGY_GENERATION}
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://open.alberta.ca/publications/9781460107942"
                  href="https://open.alberta.ca/publications/9781460107942"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Authority"
          variant="standard"
          fullWidth
          required
          value="Rewatt Power"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://app.rewattpower.com"
                  href="https://app.rewattpower.com"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Registry"
          variant="standard"
          fullWidth
          required
          value="Rewatt Power"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://app.rewattpower.com"
                  href="https://app.rewattpower.com"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="Start Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.startDate?.message)}
                  helperText={errors.startDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="startDate"
        />
      </Grid>
    </>
  );
};

const BCLCFSProjectFields = () => {
  const { errors, control } = useFormContext();
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Protocol"
          variant="standard"
          fullWidth
          required
          value={PROTOCOLS.DISTRIBUTED_RENEWABLE_ENERGY_GENERATION}
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www2.gov.bc.ca/gov/content/industry/electricity-alternative-energy/transportation-energies/renewable-low-carbon-fuels/electricity"
                  href="https://www2.gov.bc.ca/gov/content/industry/electricity-alternative-energy/transportation-energies/renewable-low-carbon-fuels/electricity"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Authority"
          variant="standard"
          fullWidth
          required
          value="Government of British Columbia"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/394_2008"
                  href="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/394_2008"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Registry"
          variant="standard"
          fullWidth
          required
          value="Transportation Fuels Reporting System"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://lowcarbonfuels.gov.bc.ca/"
                  href="https://lowcarbonfuels.gov.bc.ca/"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="Start Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.startDate?.message)}
                  helperText={errors.startDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="startDate"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="End Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              required
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.endDate?.message)}
                  helperText={errors.endDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="endDate"
        />
      </Grid>
    </>
  );
};

const CFRProjectFields = () => {
  const { errors, control } = useFormContext();
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Protocol"
          variant="standard"
          fullWidth
          required
          value={PROTOCOLS.DISTRIBUTED_RENEWABLE_ENERGY_GENERATION}
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www.canadagazette.gc.ca/rp-pr/p2/2022/2022-07-06/html/sor-dors140-eng.html"
                  href="https://www.canadagazette.gc.ca/rp-pr/p2/2022/2022-07-06/html/sor-dors140-eng.html"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Authority"
          variant="standard"
          fullWidth
          required
          value="Government of Canada"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://www.canadagazette.gc.ca/rp-pr/p2/2022/2022-07-06/html/sor-dors140-eng.html"
                  href="https://www.canadagazette.gc.ca/rp-pr/p2/2022/2022-07-06/html/sor-dors140-eng.html"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Registry"
          variant="standard"
          fullWidth
          required
          value="Credit and Tracking System"
          color="secondary"
          disabled
          inputProps={{
            maxLength: 255,
            readOnly: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="https://marchescarbone-carbonmarkets.canada.ca/"
                  href="https://marchescarbone-carbonmarkets.canada.ca/"
                  target="_blank"
                  edge="end"
                >
                  <LaunchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="Start Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.startDate?.message)}
                  helperText={errors.startDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="startDate"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="End Date"
              value={value}
              onChange={onChange}
              views={["year", "month", "day"]}
              required
              renderInput={(params) => (
                <TextField
                  required
                  variant="standard"
                  fullWidth
                  {...params}
                  error={!isNil(errors.endDate?.message)}
                  helperText={errors.endDate?.message || "Inclusive"}
                />
              )}
              disableMaskedInput
            />
          )}
          control={control}
          name="endDate"
        />
      </Grid>
    </>
  );
};

const ProjectFormFields = ({ mode }) => {
  const { errors, control, setValue, watch } = useFormContext();
  const environmentalCredit = watch("environmentalCredit");

  return (
    <Box mt={4}>
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Project Name"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.title?.message)}
                  helperText={errors.title?.message || "Name your project"}
                  required
                  onChange={onChange}
                  value={value}
                  color="secondary"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              )}
              control={control}
              name="title"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Environmental Credit"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.environmentalCredit?.message)}
                  helperText={
                    errors.environmentalCredit?.message ||
                    "Select the type of credit for this project"
                  }
                  required
                  onChange={(ev) => {
                    const { value } = ev.target;
                    setValue("protocol", "DISTRIBUTED_RENEWABLE_ENERGY_GENERATION", {
                      shouldValidate: true,
                    });
                    switch (value) {
                      case "REWATT_VOLUNTARY_CREDIT": {
                        setValue("authority", "Rewatt Power", {
                          shouldValidate: true,
                        });
                        setValue("registry", "Rewatt Power", {
                          shouldValidate: true,
                        });
                        break;
                      }
                      case "CSA_CLEAN_PROJECTS_OFFSET": {
                        setValue("authority", "Canadian Standards Association", {
                          shouldValidate: true,
                        });
                        setValue("registry", "Canadian Standards Association", {
                          shouldValidate: true,
                        });
                        break;
                      }
                      default: {
                        setValue("authority", "Government of Alberta", {
                          shouldValidate: true,
                        });
                        setValue("registry", "Canadian Standards Association", {
                          shouldValidate: true,
                        });
                      }
                    }

                    onChange(ev);
                  }}
                  value={value}
                  color="secondary"
                  select
                >
                  {map(keys(ENVIRONMENTAL_CREDIT_TYPES), (key) => (
                    <MenuItem key={key} value={key}>
                      {ENVIRONMENTAL_CREDIT_TYPES[key]}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
              name="environmentalCredit"
            />
          </Grid>
          {environmentalCredit === 'ALBERTA_EMISSION_OFFSET' && <AEOProjectFields />}
          {environmentalCredit === 'CSA_CLEAN_PROJECTS_OFFSET' && <CSACleanProjectFields />}
          {environmentalCredit === 'REWATT_VOLUNTARY_CREDIT' && <RewattVoluntaryProjectFields />}
          {environmentalCredit === 'BC_LOW_CARBON_FUEL' && <BCLCFSProjectFields />}
          {environmentalCredit === 'CANADA_FUEL_REGULATION' && <CFRProjectFields />}
          {environmentalCredit && (
            <Grid item xs={12}>
              <Typography variant="h4" mb={1}>
                {mode === "CREATE"
                  ? "Select sites to add to the project"
                  : "Add new sites to the project (optional)"}
              </Typography>
              <Controller
                render={({ onChange, value }) => (
                  <ProjectSitesPicker
                    environmentalCredit={environmentalCredit}
                    onChange={onChange}
                    value={value}
                    error={errors.assetIds?.message}
                  />
                )}
                control={control}
                name="assetIds"
              />
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};

ProjectFormFields.defaultProps = {
  mode: "CREATE",
};

ProjectFormFields.propTypes = {
  mode: PropTypes.oneOf(["EDIT", "CREATE"]),
};

export default ProjectFormFields;
