import {
  createRoutineCreator,
  defaultRoutineStages,
  createRoutine,
} from "redux-saga-routines";

const getLiveSite = createRoutine("GET_LIVE_SITE");
const getLiveStats = createRoutine("GET_LIVE_SITE_STATS");
const getLiveEnergyToday = createRoutine("GET_LIVE_ENERGY_TODAY");
const getLiveChartData = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("GET_LIVE_CHART_DATA");

export { getLiveSite, getLiveChartData, getLiveStats, getLiveEnergyToday };
