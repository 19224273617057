import { call, put, takeLatest, select } from "redux-saga/effects";
import { createProgram } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getUserProfile } from "@views/common/selectors";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";
import { first, nth } from "lodash";

const callApi = async (accessToken, organizationId, program) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/programs`,
    {
      ...program,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const transform = (program) => ({
  ...program,
  logo: {
    name: program.logo.file.name,
    tags: ["Logo", "Program"],
    uri: program.logo.uri,
  },
  termsAndConditions: {
    name: program.termsAndConditions.file.name,
    tags: ["Terms and Conditions", "Program"],
    uri: program.termsAndConditions.uri,
  },
  activityType: program.activityType || undefined,
  emissionReductionActivity: program.emissionReductionActivity || undefined,
  startDate: first(program.dateRange)?.isValid
    ? program.dateRange[0].toISODate()
    : undefined,
  endDate: nth(program.dateRange, 1)?.isValid
    ? program.dateRange[1].toISODate()
    : undefined,
  managerId: program.managerId * 1,
});

function* createProgramWorker(action) {
  yield put(createProgram.request());

  const { accessToken, program } = action.payload;
  const userProfile = yield select(getUserProfile);

  try {
    const response = yield call(
      callApi,
      accessToken,
      userProfile.organizationId,
      transform(program)
    );
    yield put(createProgram.success({ program: response.data, accessToken }));
    yield put(
      showAppMessage({
        severity: "success",
        message: "Created program. Please press Continue.",
      })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error creating program: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = (
          <ValidationErrors validationErrors={validationErrors} />
        );
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error creating program: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error creating program: ", err);
      message = err.message;
    }
    message = `Unable to create program: ${message}`;
    yield put(createProgram.failure({ error: message }));
    yield put(
      showAppMessage({ severity: "error", message: formattedMessage || message })
    );
  }
}

function* createProgramWatcher() {
  yield takeLatest(createProgram.TRIGGER, createProgramWorker);
}

export default [createProgramWatcher];
