import { put, call, takeLatest } from "redux-saga/effects";
import { sendPayeeStatements } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async ({ accessToken, payeeStatementId }) => await axios.post(
  `${process.env.REACT_APP_SURGE_API}/api/v2/admin/payeeStatements/${payeeStatementId}/send`,
  null,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* sendPayeeStatementsWorker(action) {
  try {
    yield put(sendPayeeStatements.request());
    yield call(callApi, action.payload);
    yield put(sendPayeeStatements.success());
    yield put(
      showAppMessage({
        severity: "success",
        message: "Sent payee statement(s).",
        duration: 3000,
      })
    );
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to send payee statement: ${message}`;
    yield put(sendPayeeStatements.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* sendPayeeStatementsWatcher() {
  yield takeLatest(sendPayeeStatements.TRIGGER, sendPayeeStatementsWorker);
}

export default [sendPayeeStatementsWatcher];
