import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { getFullName } from "@utils/stringHelpers";

const ApplicantProfile = ({ application }) => {
  const tableRows = [
    {
      label: "Organization",
      value: application?.applicant?.organization?.name || "N/A",
    },
    {
      label: "First and last name",
      value: getFullName(
        application?.applicant?.familyName,
        application?.applicant?.givenName
      ),
    },
    {
      label: "Contact Email",
      value: application?.applicant?.email || "N/A",
    },
  ];

  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Typography variant="h4" mb={1}>
        Acting applicant profile
      </Typography>
      <TableContainer>
        <Table aria-label="applicant profile">
          <TableBody>
            {tableRows.map((row) => (
              <TableRow key={row.label}>
                <TableCell component="th" width="50%">{row.label}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

ApplicantProfile.propTypes = {
  application: PropTypes.shape({
    applicant: PropTypes.shape({
      familyName: PropTypes.string,
      givenName: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default ApplicantProfile;
