import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";
import { createAction } from "redux-actions";

const getOrgInvites = createRoutine("GET_ORG_INVITES");
const resendInvite = createRoutine("RESEND_ORG_INVITE");
const deleteInvite = createRoutine("DELETE_ORG_INVITE");
const updatePrimaryContact = createRoutine("UPDATE_PRIMARY_CONTACT");

const addInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_INVITE"
);

const setMemberTableState = createAction("SET_MEMBER_TABLE_STATE");
setMemberTableState.TYPE = setMemberTableState.toString();

export {
  addInvite,
  getOrgInvites,
  resendInvite,
  deleteInvite,
  setMemberTableState,
  updatePrimaryContact,
};
