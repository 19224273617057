import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { find, includes, get } from "lodash";
import Address from "@components/Address";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { APPLICATION_SOURCES } from "@views/programs/components/Applications";

const ApplicationDetails = ({ application }) => {
  const tableRows = [
    {
      label: "Source",
      value: get(
        APPLICATION_SOURCES,
        application?.source,
        APPLICATION_SOURCES.PUBLIC_LINK
      ),
    },
    {
      label: "Date Created",
      value: DateTime.fromSeconds(application?.created)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_MED),
    },
    {
      label: "Program",
      value: application?.program?.name,
    },    
    {
      label: "Applicant Organization",
      value: application?.applicant?.organization?.name || "N/A",
    },
    {
      label: "Applicant Contact",
      value: application?.applicant?.email || "N/A",
    },
    {
      label: "Applicant Address",
      value: (
        <Address
          address={find(
            application?.applicant?.organization?.addresses,
            (address) => includes(address.types, "MAIN")
          )}
        />
      ),
    },
  ];

  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Application Details
      </Typography>
      <TableContainer>
        <Table aria-label="applicant profile">
          <TableBody>
            {tableRows.map((row) => (
              <TableRow key={row.label}>
                <TableCell component="th" width="50%">
                  {row.label}
                </TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

ApplicationDetails.propTypes = {
  application: PropTypes.shape({
    applicant: PropTypes.shape({
      familyName: PropTypes.string,
      givenName: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default ApplicationDetails;
