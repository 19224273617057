import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProjectReports from "../components/ProjectReports";
import { getProjectReports } from "../actions";

const mapStateToProps = ({ getProjectReports }) => ({
  getProjectReportsResult: getProjectReports,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProjectReports,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ProjectReports);
