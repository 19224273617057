import React, { useReducer } from 'react';
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";
import DocumentUploader from "@addSites/sitePicker/containers/DocumentUploader";
import { ApplicationPage } from "./ApplicationLayout";
import { application as appActions } from "../actions";
import { useNext, usePrev } from './useNextPrev';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Navigation } from 'components';
import { DateTime } from 'luxon';
import { EVTags, SolarTags } from 'views/addSites/sitePicker/components/TagSelectorContext';
import { LCFDocs as BuiltLCFSDocs, BuiltSolarList, UnbuiltLCFSList, UnbuiltSolarList } from '../../Site/components/Documents';
import { every } from 'lodash';
import { isEmpty } from 'lodash';
import { showAppMessage } from 'components/appMessage/actions';

const LCFDocs = ({ children, isBuilt }) => isBuilt ? (
  <BuiltLCFSDocs>
    {children}
  </BuiltLCFSDocs>
) : (
  <>
    <Typography variant="h5" gutterBottom>
      Upload supporting documents 
    </Typography>
    <Typography
      gutterBottom
      variant="body2"
      color="textSecondary"
    >
      Uploading documents is optional at this time. If you are not able to upload documents now, you may click <em>Continue</em> below and add documents later.
    </Typography>
    <Typography
      variant="body2"
      color="textSecondary"
    >
      For sites that are not built, we request documents that describe the proposed site location and ownership.
    </Typography>
    <Typography
      variant="body2"
      color="textSecondary"
    >
      If you have any of these documents handy, please upload them now and provide an appropriate tag:
    </Typography>
    <UnbuiltLCFSList />
    <EVTags>
      {children}
    </EVTags>
  </>
);

const SolarDocs = ({ children, isBuilt }) => isBuilt ? (
  <>
    <Typography variant="h5" gutterBottom>
    Please provide four types of documents to support your application. Upload one or more of each and tag them with their type. If you don't have these documents now, you can add them later.
    </Typography>
    <BuiltSolarList />
    <SolarTags>
      {children}
    </SolarTags>
  </>
) : (
  <>
    <Typography variant="h5" gutterBottom>
      For sites that are not built, we request documents that describe the proposed site location, system size and ownership.
    </Typography>
    <UnbuiltSolarList />
    <SolarTags>
      {children}
    </SolarTags>
  </>
);

const ApplicationDocuments = () => {
  const application = useSelector((state) => state.customerProjectApplication);
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  const next = useNext();
  const prev = usePrev();
  const dispatch = useDispatch();

  const initialDocuments = application.documents ?? [];

  const [documents, setDocuments] = useReducer(documentsReducer, initialDocuments);

  const onSubmit = () => {
    const isValid = every(documents, (doc) => !isEmpty(doc.tags));
    if (isValid) {
      dispatch(appActions.saveAssetDetails({ documents: documents.map(d => ({ ...d, name: d.file.path })) }));
      next("projectterms");
    } else {
      dispatch(showAppMessage({ severity: "warning", message: "Please tag all uploaded documents." }));
    }
  };
  const { completionDate } = application.asset;
  const isBuilt = completionDate && DateTime.fromISO(completionDate) < DateTime.now();
  return (
    <ApplicationPage pageName="Documents">
      <Box mb={2}>
        {customerProject?.emissionReductionActivity === 'LOW_CARBON_FUEL'
          ? <LCFDocs isBuilt={isBuilt}><DocumentUploader documents={documents} dispatch={setDocuments} /></LCFDocs>
          : <SolarDocs isBuilt={isBuilt}><DocumentUploader documents={documents} dispatch={setDocuments} /></SolarDocs>
        }
      </Box>

      <Navigation
        backAction={prev}
        nextAction={onSubmit}
        nextActionText="Continue"
      />
    </ApplicationPage>
  );
};

export default ApplicationDocuments;
