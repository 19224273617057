import React, { useEffect } from "react";
import { Typography, Box, Paper, SvgIcon } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as SolarIcon } from "../assets/solar-panel.svg";
import { ReactComponent as CO2Icon } from "../assets/co2.svg";
import { ReactComponent as CarIcon } from "../assets/car.svg";
import { ReactComponent as HomeIcon } from "../assets/home.svg";
import { ReactComponent as TreeIcon } from "../assets/tree.svg";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { getOrganizationEnergy, getOrganizationEmissions } from "../actions";
import { wmToMWh } from "@utils/energyHelpers";
import { round } from "@utils/numberHelpers";

const LightTooltip = styled(({ className, ...props }) => (
  // this is a system-provided className eg. "css-9qgv68"
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    borderRadius: 2,
    padding: 8,
  },
}));

const StatLine = styled(({ value, text }) => (
  <Typography
    color="text.secondary"
    sx={{ ml: 2, maxHeight: 40, minWidth: 50, overflow: "hidden" }}
  >
    <Typography color="secondary.main" component="span">
      {value}
    </Typography>{" "}
    {text}
  </Typography>
))({});

const GenerationStatsPanel = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getOrganizationEnergy({ accessToken }));
      dispatch(getOrganizationEmissions({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch]);

  const { generation } = useSelector((state) => state.getOrganizationEnergy);

  const { emissionReductions, cars, homes, trees } = useSelector(
    (state) => state.getOrganizationEmissions
  );

  return (
    <Paper sx={{ height: "100%", maxHeight: 519 }}>
      <Box sx={{ p: 3, maxHeight: "100%", overflow: "auto" }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Generation To Date</Typography>
          <LightTooltip title="The total generation (energy) recorded from this site, over its entire lifetime.">
            <InfoOutlinedIcon />
          </LightTooltip>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          my={2}
        >
          <SvgIcon
            component={SolarIcon}
            inheritViewBox
            sx={{ width: 42, height: 41 }}
          />
          <StatLine
            value={round(wmToMWh(generation), 1).toLocaleString()}
            text="MWh Solar"
          />
        </Box>

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Typography variant="h5">Emissions Avoided</Typography>
          <LightTooltip title="By generating this power cleanly, we have avoided emitting CO2 into the atmosphere. This is a measure of that amount of CO2.">
            <InfoOutlinedIcon />
          </LightTooltip>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          my={2}
        >
          <SvgIcon
            component={CO2Icon}
            inheritViewBox
            sx={{ width: 36, height: 38, mr: "6px" }}
          />
          <StatLine
            value={round(emissionReductions, 1).toLocaleString()}
            text="Metric tons of CO2e"
          />
        </Box>

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Typography variant="h5">Equivalent To</Typography>
          <LightTooltip title="Other methods in which one could have avoided emitting the above amount of CO2, besides using solar generation.">
            <InfoOutlinedIcon />
          </LightTooltip>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          my={2}
        >
          <SvgIcon
            component={CarIcon}
            inheritViewBox
            sx={{ width: 38, height: 32, mr: "4px" }}
          />
          <StatLine
            value={round(cars).toLocaleString()}
            text="Cars off road per year"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          my={3}
        >
          <SvgIcon
            component={HomeIcon}
            inheritViewBox
            sx={{ width: 36, height: 39, mr: "6px" }}
          />
          <StatLine
            value={round(homes).toLocaleString()}
            text="Homes powered per year"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          my={2}
        >
          <SvgIcon
            component={TreeIcon}
            inheritViewBox
            sx={{ width: 38, height: 37, mr: "4px" }}
          />
          <StatLine
            value={round(trees).toLocaleString()}
            text="Trees planted"
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default GenerationStatsPanel;
