import { put, call, takeLatest, select } from "redux-saga/effects";
import logger from "debug";
import {
  getOrgInvites,
  deleteInvite,
  resendInvite,
  addInvite,
} from "../actions";
import axios from "axios";
import { join, values } from "lodash";
import { getOrganization } from "@views/common/selectors";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, organization) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organization.id}/invites`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getOrgInvitesWorker(action) {
  yield put(getOrgInvites.request());

  const { accessToken } = action.payload;
  const organization = yield select(getOrganization);

  try {
    const response = yield call(callApi, accessToken, organization);
    yield put(getOrgInvites.success({ invites: response.data }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")(
        "Error fetching organization invites: ",
        err.response
      );
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")(
        "Error fetching organization invites: ",
        err.request
      );
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error fetching organization invites: ", err);
      message = err.message;
    }
    message = `Unable to fetch organization invites: ${message}`;
    yield put(
      getOrgInvites.failure({
        error: message,
      })
    );
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* resendInviteWatcher() {
  yield takeLatest(resendInvite.SUCCESS, getOrgInvitesWorker);
}

function* deleteInviteWatcher() {
  yield takeLatest(deleteInvite.SUCCESS, getOrgInvitesWorker);
}

function* addInviteWatcher() {
  yield takeLatest(addInvite.SUCCESS, getOrgInvitesWorker);
}

function* getOrgInvitesWatcher() {
  yield takeLatest(getOrgInvites.TRIGGER, getOrgInvitesWorker);
}

export default [
  getOrgInvitesWatcher,
  deleteInviteWatcher,
  resendInviteWatcher,
  addInviteWatcher,
];
