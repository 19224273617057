import testDataConnectorConnection from "./testDataConnectorConnection";
import findSystems from "./findSystems";
import addDevicesForm from "./addDevicesForm";


const reducers = {
  testDataConnectorConnection,
  findSystems,
  addDevicesForm,
};

export default reducers;
