import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  CircularProgress,
  Container,
  Grid,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { objectifyAddresses, labelAddresses } from "utils/locationHelpers";
import { phoneRegExp, urlRegExp } from "@utils/errorHelpers";
import { isEmpty, isNil, keys, has } from "lodash";
import { useAppMessage } from "@components/appMessage";
import { INDUSTRIES } from "@views/apply/components/OrganizationInfo";
import OrgAddressesForm, {
  schema as addressesSchema,
} from "@views/apply/components/OrgAddressesForm";

export const schema = addressesSchema.shape({
  name: Yup.string().max(255, "Too long.").required("Name is required."),
  phone: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .transform((value) => (value === "" ? undefined : value)) // so that we can have length=0 too
    .min(10, "Too short.")
    .max(20, "Too long.")
    .test("phone", "Phone number is not valid.", (value) => {
      if (!isEmpty(value)) {
        return phoneRegExp.test(value);
      }
      return true;
    }),
  website: Yup.string()
    .nullable()
    .notRequired()
    .trim()
    .transform((value) => (value ? value : ""))
    .test("website", "Invalid URL.", (value) => {
      if (!isEmpty(value)) {
        return urlRegExp.test(value);
      }
      return true;
    }),
  industry: Yup.string().max(60, "Too long.").required("Industry is required."),
  otherIndustry: Yup.string()
    .nullable()
    .notRequired()
    .when("industry", {
      is: (val) => val !== "OTHER",
      otherwise: Yup.string()
        .max(60, "Too long.")
        .required("Industry is required."),
    }),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrganizationEditor = ({
  open,
  onClose,
  organization,
  updateOrganization,
  updateOrganizationResult,
  resetUpdateOrganization,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  const hasIndustry = has(INDUSTRIES, organization?.industry);
  const industry = hasIndustry
    ? {
        otherIndustry: "",
      }
    : {
        industry: "OTHER",
        otherIndustry: organization.industry,
      };

  const defaultValues = {
    ...organization,
    ...industry,
    addresses: objectifyAddresses(organization.addresses),
    phone: organization.phone || "",
    website: organization.website || "",
  };

  const { handleSubmit, errors, control, reset, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // patch application on BE
  const onSubmit = async (org) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    const industry =
      org.industry === "OTHER" ? org.otherIndustry : org.industry;

    updateOrganization({
      accessToken,
      organization: {
        id: organization.id,
        ...org,
        industry: industry || null,
        addresses: labelAddresses(org.addresses),
        phone: org.phone || "",
      },
    });
  };

  // close and cleanup on success
  useEffect(() => {
    if (updateOrganizationResult.status === "success") {
      const { organization } = updateOrganizationResult;

      // reset status in this request (ie so we don't keep onClosing)
      resetUpdateOrganization();

      // form needs new defaults
      const hasIndustry = has(INDUSTRIES, organization.industry);
      const industry = hasIndustry
        ? {
            otherIndustry: "",
          }
        : {
            industry: "OTHER",
            otherIndustry: organization.industry,
          };
      const defaultValues = {
        ...organization,
        ...industry,
        addresses: objectifyAddresses(organization.addresses),
        phone: organization.phone || "",
        website: organization.website || "",
      };

      reset(defaultValues);

      showAppMessage({
        severity: "success",
        message: "Organization saved successfully.",
        duration: 3000,
      });

      onClose();
    }
  }, [
    updateOrganizationResult,
    organization,
    reset,
    onClose,
    resetUpdateOrganization,
    showAppMessage,
  ]);

  const hasOtherIndustry = !isEmpty(defaultValues.otherIndustry);
  const [showOtherIndustry, setShowOtherIndustry] = useState(hasOtherIndustry);

  const isSubmitting = updateOrganizationResult.status === "request";
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Edit Organization
          </Typography>
          <LoadingButton
            color="inherit"
            onClick={() => handleSubmit(onSubmit)()}
            loading={isSubmitting}
            loadingIndicator={
              <CircularProgress sx={{ color: "common.white" }} size={16} />
            }
          >
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Box sx={{ mt: 3, mb: 5 }}>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ mt: 3 }}>Company Profile</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      label="Name"
                      variant="standard"
                      fullWidth
                      error={!isNil(errors.name?.message)}
                      helperText={errors.name?.message}
                      required
                      onChange={onChange}
                      value={value}
                      color="secondary"
                    />
                  )}
                  control={control}
                  name="name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      name="industry"
                      variant="standard"
                      error={!isNil(errors.industry?.message)}
                      helperText={errors.industry?.message}
                      required
                      select
                      fullWidth
                      label="Industry"
                      value={value}
                      onChange={(event) => {
                        onChange(event);
                        setShowOtherIndustry(event.target.value === "OTHER");
                      }}
                      color="secondary"
                    >
                      {keys(INDUSTRIES).map((key) => (
                        <MenuItem key={key} value={key}>
                          {INDUSTRIES[key]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                  name="industry"
                />
              </Grid>
              {showOtherIndustry && (
                <>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <TextField
                          label="Other Industry"
                          variant="standard"
                          fullWidth
                          error={!isNil(errors.otherIndustry?.message)}
                          helperText={errors.otherIndustry?.message}
                          required
                          onChange={onChange}
                          value={value}
                          color="secondary"
                        />
                      )}
                      control={control}
                      name="otherIndustry"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      label="Phone Number"
                      variant="standard"
                      fullWidth
                      error={!isNil(errors.phone?.message)}
                      helperText={errors.phone?.message}
                      onChange={onChange}
                      value={value}
                      color="secondary"
                    />
                  )}
                  control={control}
                  name="phone"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      label="Website"
                      variant="standard"
                      fullWidth
                      error={!isNil(errors.website?.message)}
                      helperText={errors.website?.message}
                      onChange={onChange}
                      value={value}
                      color="secondary"
                    />
                  )}
                  control={control}
                  name="website"
                />
              </Grid>
              <FormProvider {...{ errors, control, setValue }}>
                <OrgAddressesForm addresses={defaultValues.addresses} />
              </FormProvider>
            </Grid>
          </form>
        </Box>
      </Container>
    </Dialog>
  );
};

OrganizationEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.shape({}),

  updateOrganization: PropTypes.func.isRequired,
  updateOrganizationResult: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  resetUpdateOrganization: PropTypes.func.isRequired,
};

export default OrganizationEditor;
