import React, { useState } from 'react';
import Image from "@jy95/material-ui-image";
import { Box, Button, Grid, Typography } from "@mui/material";
import { getDims } from "@utils/imageHelpers";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ThankYou = () => {
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  const { application } = useSelector((state) => state.submitProjectApplication);
  
  // logo handling
  const [aspectRatio, setAspectRatio] = useState(1);
  const imgBoxDim = 225 - 2 * 8;

  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        {/* row 1 */}
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="stretch"
          alignItems="center"
        >
          {/* position image in center */}
          <Box
            style={{
              flex: `0 0 225px`,
              boxShadow: "3px 3px 8px 2px rgb(0 0 0 / 15%)",
              padding: 8,
            }}
          >
            <Image
              src={customerProject?.logo?.uri}
              imageStyle={getDims(aspectRatio, imgBoxDim)}
              onLoad={(e) => {
                if (e?.target) {
                  setAspectRatio(
                    e.target.naturalWidth / e.target.naturalHeight
                  );
                }
              }}
              style={{
                backgroundColor: "transparent",
              }}
            />
          </Box>

          {/* title */}
          <Box flex={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h2" pl={12}>
                {`Thank you for applying to the ${customerProject?.customer?.name} ${customerProject?.title}.`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* end of row 1 */}

      <Grid item xs={12}>
        {application?.id && (
          <Box
            mt={8}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              variant="h3"
              sx={{ mb: 3, textAlign: "center" }}
            >
              Please keep the following application reference number id
              for your records:
            </Typography>
            <Typography
              variant="h2"
              sx={{ mb: 3, textAlign: "center" }}
            >
              {application.id}
            </Typography>
            <Typography
              variant="h4"
              sx={{ mb: 3, textAlign: "center" }}
            >
              You will be notified by email once your application has been reviewed.
            </Typography>
            <Typography
              variant="h4"
              sx={{ mb: 3, textAlign: "center" }}
            >
              Thank you for your application. We will be in touch soon.
            </Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/individual/dashboard")}
                sx={{ mr: 0.5 }}
              >
                View Dashboard
              </Button>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ThankYou;
