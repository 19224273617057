import getChannelPartnerInvites from './getChannelPartnerInvites';
import addChannelPartnerInvite from './addChannelPartnerInvite';
import getChannelPartners from './getChannelPartners';
import getChannelPartner from './getChannelPartner';
import getChannelPartnerReferrals from "./getChannelPartnerReferrals";

const reducers = {
  getChannelPartnerInvites,
  addChannelPartnerInvite,
  getChannelPartners,
  getChannelPartner,
  getChannelPartnerReferrals,
};

export default reducers;
