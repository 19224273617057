import React, { useEffect } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import PageHeader from "@components/PageHeader";
import { getSite } from "@views/site/actions";
import StatsPanel from "./StatsPanel";
import IndividualChartPanel from "./IndividualChartPanel";
import ApplicationStatusPanel from "./ApplicationStatusPanel";
import { getIndividualApplications } from "../actions";
import PageLoader from "components/PageLoader";
import { showAppMessage } from "components/appMessage/actions";
import { join } from "lodash";
import { isEmpty } from "lodash";
import { filter } from "lodash";
import { first } from "lodash";

const IndividualDashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { userProfile: { assetId: siteId } } = useSelector((state) => state.getUserProfile);

  // loading this users applications
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSite({ accessToken, siteId }));
      dispatch(getIndividualApplications({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch, siteId]);
  const { site, status } = useSelector((state) => state.getSite);
  const { applications } = useSelector((state) => state.getIndividualApplications);

  // notify this user if any applications require changes
  useEffect(() => {
    const changesReqApps = filter(applications, ({ status }) => status === 'CHANGES_REQUIRED');
    if (!isEmpty(changesReqApps)) {
      if (changesReqApps.length === 1) {
        const message = `Your application to ${first(changesReqApps).customerProjectTitle} has been reviewed and requires changes`;
        dispatch(showAppMessage({ severity: 'warning', message, duration: 5000 }));
      } else {
        const descriptions = join(changesReqApps.map(app => `your application to ${app.customerProjectTitle}.`), ', ');
        const message = `Your following applications have been reviewed and require changes: ${descriptions}`;
        dispatch(showAppMessage({ severity: 'warning', message, duration: 5000 }));
      }
    }
  }, [applications, dispatch]);

  // clean up
  useEffect(() => () => {
    dispatch(getIndividualApplications.reset());
  }, [dispatch]);

  const isLoadingSite = status === "request";
  return (
    <Page title={site?.name ?? 'Dashboard'} py={3}>
      {isLoadingSite ? <PageLoader message="Loading site..." /> : (
        <Container maxWidth="lg">
          <PageHeader title={`${site?.name}`} />
          <Box mt={2} ml={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 3 , pt: 1 }}>
                  <Typography variant="h5">Your Applications</Typography>
                  <ApplicationStatusPanel applications={applications} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 3 , pt: 1 }}>
                  <Typography variant="h5">Performance</Typography>
                  <StatsPanel />
                  <IndividualChartPanel siteId={site.id}/>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
};

export default IndividualDashboard;
