import { getLiveStats } from "../actions";

export const initialState = {
  status: "init",
  stats: {
    dailyAverageEnergy: 0,
    last30DaysAverageEnergy: 0,
    specificYield: 0,
    totalGeneration: 0,
    totalEmissionsReduction: 0,
    totalTrees: 0,
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getLiveStats.RESET: {
      return initialState;
    }

    case getLiveStats.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getLiveStats.SUCCESS: {
      const { stats } = action.payload;
      return {
        ...state,
        status: "success",
        stats,
      };
    }

    case getLiveStats.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
