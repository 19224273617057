import React, { useEffect} from "react";
import { GlobalStyles, Box, Container, Typography, Button, Link } from "@mui/material";
import Page from "@components/Page";
import PageLoader from "@components/PageLoader";
import { useLocation } from "react-router-dom";
import { includes } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { verifyAssetClaimInviteToken } from "../actions";
import { getUserProfile } from "@views/common/actions";
import { RewattAddressFooter } from "@components";

/**
 * For individual asset claim invites, we follow the same general flow as other invites (buyer, orgs)
 * - we accept the invite (and T&C), at the end of the process
 * - keep the back actions off, so we don't need to store progress or info or re-populate forms
 */
const Welcome = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(verifyAssetClaimInviteToken({ token }));
    }
  }, [token, dispatch]);

  useEffect(() => {
    // just to be safe, if you closed without logging out, then signed up again
    dispatch(getUserProfile.reset());
  });

  const { status, invite, error } = useSelector(state => state.verifyAssetClaimInviteToken)
  const isLoading = includes(
    ["init", "request"],
    status
  );

  const { loginWithRedirect } = useAuth0();
  const signup = () => {
    loginWithRedirect({
      redirectUri: window.location.origin,
      appState: {
        inviteId: invite.id,
        returnTo: "/assetclaim/accept/start",
      },
      screen_hint: "signup",
    });
  };

  return (
    <Page title="Welcome" py={3}>
      <GlobalStyles styles={{
        // hide the login button
        "header .MuiButton-root": {
          display: "none"
        }
      }} />
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Checking invite..." />}
        {!isLoading && error && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "calc(100vh - 64px)" }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Sorry, we had a problem verifying your invitation. Please
                request another one (or <Link href="/">login</Link>).
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {error}
              </Typography>
            </Box>
          </Box>
        )}
        {!isLoading && !error && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            minHeight="75vh"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h3" color="textPrimary" gutterBottom>
                Welcome {invite.inviteeName}!
              </Typography>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Invitation to Claim Site
              </Typography>

              <Container maxWidth="sm">
                <Box
                  py={3}
                  mb={4}
                  sx={{
                    my: 4,
                    py: 3,
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    {invite.inviteeName} ({invite.inviteeEmail}),
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    You've been invited by {invite.shareName} to claim a site on the Rewatt Power
                    platform.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    To continue, you will need to sign up and enter your personal information.
                    Make sure you use the above email for your signup.
                  </Typography>
                  {invite.notes && (
                    <>
                      <Typography variant="body1">Notes from {invite.shareName}:</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {invite.notes}
                      </Typography>
                    </>
                  )}
                </Box>
              </Container>

              <Button
                variant="contained"
                color="primary"
                onClick={signup}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        )}
        <RewattAddressFooter />
      </Container>
    </Page>
  );
};

export default Welcome;
