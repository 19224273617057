import React from "react";
import {
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { isNil, isEmpty } from "lodash";
import * as Yup from "yup";
import { phoneRegExp } from "utils/errorHelpers";

export const contactSchema = Yup.object().shape({
  firstName: Yup
    .string()
    .max(60, "Too long.")
    .required("First name is required."),
  lastName: Yup
    .string()
    .max(60, "Too long.")
    .required("Last name is required."),
  email: Yup.string().email().required("Email is required."),
  phone: Yup
    .string()
    .trim()
    .transform((value) => (value === "" ? undefined : value))
    .min(10, "Too short.")
    .max(20, "Too long.")
    .test("phone", "Phone number is not valid.", (value) => {
      if (!isEmpty(value)) {
        return phoneRegExp.test(value);
      }
      return true;
    }),
});

const ContactForm = ({ instructions, email = true }) => {
  const { errors, control } = useFormContext();

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography mt={4}>
            {instructions}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="First Name"
                variant="standard"
                fullWidth
                error={!isNil(errors.firstName?.message)}
                helperText={errors.firstName?.message || "Only editable for non-social accounts."}
                required
                onChange={onChange}
                value={value}
                color="secondary"
              />
            )}
            control={control}
            name="firstName"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Last Name"
                variant="standard"
                fullWidth
                error={!isNil(errors.lastName?.message)}
                helperText={errors.lastName?.message || "Only editable for non-social accounts."}
                required
                onChange={onChange}
                value={value}
                color="secondary"
              />
            )}
            control={control}
            name="lastName"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                error={!isNil(errors.email?.message)}
                helperText={errors.email?.message || (email ? "contact@org.com" : "Read-only.")}
                required
                onChange={onChange}
                value={value}
                type="email"
                color="secondary"
                inputProps={{
                  readOnly: !email
                }}
              />
            )}
            control={control}
            name="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Phone"
                variant="standard"
                fullWidth
                error={!isNil(errors.phone?.message)}
                helperText={errors.phone?.message}
                required
                onChange={onChange}
                value={value}
                color="secondary"
              />
            )}
            control={control}
            name="phone"
          />
        </Grid>
      </Grid>
    </form>
  );
};

ContactForm.propTypes = {
  instructions: PropTypes.string.isRequired,
  email: PropTypes.bool,
}

export default ContactForm;
