import { call, put, takeLatest } from "redux-saga/effects";
import { getSitesByAdmin } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { omitBy, isNil } from "lodash";
const callApi = async (accessToken, page, pageSize, order, search) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/monitoring/assets`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: omitBy(
        {
          page,
          pageSize,
          order,
          search,
        },
        isNil
      ),
    }
  );
  return response;
};

function* getSitesWorker(action) {
  yield put(getSitesByAdmin.request());
  const { accessToken, page, pageSize, order, search } = action.payload;
  try {
    const response = yield call(callApi, accessToken, page, pageSize, order, search);
    const { total, assets } = response.data;
    yield put(
      getSitesByAdmin.success({
        total,
        sites: assets,
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting sites for admin: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting sites for admin: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting sites for admin: ", err);
      message = err.message;
    }
    message = `Unable to get sites for admin: ${message}`;
    yield put(
      getSitesByAdmin.failure({
        error: message,
      })
    );
    yield put(
      showAppMessage({
        severity: "error",
        message,
      })
    );
  }
}

function* getSitesWatcher() {
  yield takeLatest(getSitesByAdmin.TRIGGER, getSitesWorker);
}
export default [getSitesWatcher];
