import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Manufacturer from "../components/Manufacturer";
import { testConnection } from "@addSites/actions";
import { saveManufacturer, saveCredentials } from "../actions";
import { showAppMessage } from "@appMessage/actions";

const mapStateToProps = ({
  application: { siteInfo },
  testConnection: connectionTestResult,
  getProgram: getProgramResult,
}) => ({
  siteInfo,
  connectionTestResult,
  getProgramResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveManufacturer,
      saveCredentials,
      showAppMessage,
      testConnection: testConnection.trigger,
      resetTestConnection: testConnection.reset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Manufacturer);
