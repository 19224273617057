import { getChannelPartnerReferrals } from "../actions";

export const initialState = {
  status: "init",
  referrals: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getChannelPartnerReferrals.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getChannelPartnerReferrals.SUCCESS: {
      const { referrals } = action.payload;
      return {
        ...state,
        status: "success",
        referrals,
      };
    }

    case getChannelPartnerReferrals.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

  case getChannelPartnerReferrals.RESET: {
    return initialState;
  }

    default: {
      return state;
    }
  }
};

export default reducer;
