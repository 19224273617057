import React from "react";
import { isNil, isEmpty, trim } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { urlRegExp } from "@utils/errorHelpers";
import ConnectionTest from "./ConnectionTest";

import { Typography, Box, Grid, TextField, ListItem, ListItemIcon, ListItemText, List } from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import { EmailLink } from "components";

export const DataConnector = 'cachelanSolarVu';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="Cachelan Portal URL"
              variant="standard"
              fullWidth
              placeholder="https://{siteName}.solarvu.net"
              autoFocus
              error={!isNil(errors.cachelanPortalUrl?.message)}
              helperText={errors.cachelanPortalUrl?.message || " "}
            />
          }
          name="cachelanPortalUrl"
          control={control}
          rules={{
            required: "The Cachelan Portal URL is required.",
            validate: (value) => {
              if (!isEmpty(trim(value))) {
                return urlRegExp.test(value) || "A valid URL is required.";
              }
              return true;
            },
          }}
          defaultValue={dataConnectorParams?.cachelanPortalUrl || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>      
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>Instructions to Grant Rewatt Access</Typography>
      <Typography gutterBottom>
        To connect with Cachelan, two actions are required.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="You need to send an email to Cachelan saying that you approve of Rewatt Power receiving your system data."
            secondary={
              <EmailLink
                email="customerrelations@cachelan.com"
                subject="Permission request"
                body="Please allow Rewatt Power to access data from my system."
                cc="support@rewattpower.com"
              />
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Provide us with the URL you use to access your Cachelan portal."
            secondary="It should be something like this: https://mysite.solarvu.net"
          />
        </ListItem>
      </List>
      <Typography gutterBottom>
        Once Cachelan receives your email, they will grant Rewatt access to your system. 
        This may take a few days, and then you can return to this screen to add your site.
      </Typography>      
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;
