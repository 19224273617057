import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Project from "../components/Project";
import {
  getProject,
  getProjectCredits,
  getProjectSites,
  getProjectReports,
  getProjectStats,
  getProjectChartByMonth,
  getPayeeInvites,
} from "../actions";

const mapStateToProps = ({ getProject }) => ({
  getProjectResult: getProject,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProject,
      getProjectCredits,
      getProjectSites,
      getProjectReports,
      getProjectStats,
      getProjectChartByMonth,
      getPayeeInvites,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Project);
