import { updatePrimaryContact } from "../actions";

export const initialState = {
  status: "init",
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case updatePrimaryContact.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case updatePrimaryContact.SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }

    case updatePrimaryContact.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
