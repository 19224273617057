import React from "react";
import { Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { parseAddress } from "@utils/locationHelpers";
import { AddressTextField } from "@addSites/sitePicker";
import Places from "@components/Places";

export const schema = Yup.object().shape({
  addresses: Yup.object().shape({
    main: Yup.object().shape({
      street: Yup.string()
        .max(60, "Too long.")
        .required("Street name is required."),
      city: Yup.string().max(60, "Too long.").required("City is required."),
      region: Yup.string().max(60, "Too long.").required("Region is required."),
      country: Yup.string()
        .max(60, "Too long.")
        .required("Country is required."),
      postal: Yup.string().matches(
        /^[a-z0-9][a-z0-9\- ]{2,10}[a-z0-9]$/i,
        "That does not look like a valid postal code format."
      ), // sanity-only
    }),
  }),
});

const OrgAddressesForm = ({ addresses }) => {
  const { errors, control, setValue } = useFormContext();

  // from the autocomplete
  const populateMainAddress = React.useCallback(
    (newValue) => {
      const address = parseAddress(newValue);
      setValue("addresses.main.street", address.street, {
        shouldValidate: true,
      });
      setValue("addresses.main.city", address.city, { shouldValidate: true });
      setValue("addresses.main.region", address.region, {
        shouldValidate: true,
      });
      setValue("addresses.main.country", address.country, {
        shouldValidate: true,
      });
      setValue("addresses.main.postal", address.postal, {
        shouldValidate: true,
      });
    },
    [setValue]
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography mt={3} fontWeight="bold">
          Main Address
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Places changeHandler={populateMainAddress} />
      </Grid>
      <Grid item xs={6}>
        <AddressTextField
          errors={errors}
          control={control}
          name="addresses.main.street"
          label="Street"
          placeholder="621 23 Ave NE"
          helperText="The street and number of your company."
        />
      </Grid>
      <Grid item xs={6}>
        <AddressTextField
          errors={errors}
          control={control}
          name="addresses.main.city"
          label="City"
          placeholder="Calgary"
          helperText="The city, town or municipality in which your company resides."
        />
      </Grid>
      <Grid item xs={6}>
        <AddressTextField
          errors={errors}
          control={control}
          name="addresses.main.region"
          label="Province/State/Region"
          placeholder="Alberta"
          helperText="The region in which your company resides."
        />
      </Grid>
      <Grid item xs={6}>
        <AddressTextField
          errors={errors}
          control={control}
          name="addresses.main.country"
          label="Country"
          placeholder="Canada"
          helperText="The full country name in which your company resides."
        />
      </Grid>
      <Grid item xs={6}>
        <AddressTextField
          errors={errors}
          control={control}
          name="addresses.main.postal"
          label="Postal Code"
          placeholder="T2E 1W5"
          helperText="The postal or zip code of your company."
        />
      </Grid>
    </>
  );
};

OrgAddressesForm.propTypes = {
  addresses: PropTypes.shape({
    main: PropTypes.shape({}),
  }),
};

export default OrgAddressesForm;
