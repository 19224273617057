import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import CustomerProjectFormFields from "./CustomerProjectFormFields";
import { schema } from "./CustomerProjectFormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import isEmpty from "lodash/isEmpty";
import { createCustomerProject, getCustomerProjects } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import DialogTransition from "components/DialogTransition";
import DialogSaveButton from "components/DialogSaveButton";

const CustomerProjectCreator = ({ open, onClose }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      description: "",
      emissionReductionActivity: "",
      isPublic: false,
      logo: null, // filespec
      termsAndConditions: null, // filespec
      managerId: "",
    },
  });

  // submit
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.getOrganization);
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { description, ...customerProject } = data;
    if (description && !isEmpty(description)) {
      customerProject.description = description;
    }
    if (customerProject.managerId) customerProject.managerId *= 1;
    dispatch(createCustomerProject({
      customerProject,
      accessToken,
      organizationId: organization?.id,
    }));
  };
  const { status } = useSelector((state) => state.createCustomerProject);

  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleClose = async () => {
    methods.reset();
    dispatch(createCustomerProject.reset());
    if (isSuccess) {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProjects({ accessToken, organizationId: organization?.id }));
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={DialogTransition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          bgcolor: "background.dark",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Create Project
          </Typography>
        </Toolbar>
      </AppBar>
        <DialogContent>
          {isSuccess && (
            <Typography gutterBottom>
              Success! Your project has been created. You may close this window.
            </Typography>
          )}
          <FormProvider {...methods}>
            <CustomerProjectFormFields disabled={isSuccess}/>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <DialogSaveButton
            isSubmitting={isSubmitting}
            isSuccess={isSuccess}
            handleClose={handleClose}
            handleSubmit={methods.handleSubmit(onSubmit)}
          />
        </DialogActions>
    </Dialog>
  );
};

export default CustomerProjectCreator;
