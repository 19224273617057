import getPrograms from "./getPrograms";
import createProgram from "./createProgram";
import getApplications from "./getApplications";
import getOrganizationUsers from "./getOrganizationUsers";

export default [
  ...getPrograms,
  ...createProgram,
  ...getApplications,
  ...getOrganizationUsers,
];
