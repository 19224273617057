import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { includes } from "lodash";

const getColor = ({ theme, isDragAccept, isDragReject, isFocused, isDragActive, isError }) => {
  if (isDragActive) {
    return theme.palette.info.main;
  }
  if (isDragAccept) {
    return theme.palette.success.main;
  }
  if (isDragReject) {
    return theme.palette.error.main;
  }
  if (isFocused) {
    return theme.palette.secondary.main;
  }
  if (isError) {
    return theme.palette.error.main;
  }
  return theme.palette.primary.main;
};

const DropZone = styled(Box, {
  shouldForwardProp: (prop) =>
    !includes(["isDragAccept", "isDragReject", "isFocused", "isDragActive", "isError"], prop),
})(({ theme, ...props }) => ({
  position: "relative",
  border: `2px dashed ${getColor({ theme, ...props })}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  outline: "none",
  transition: "border 0.24s ease-in-out",
  textAlign: "center",
  cursor: "pointer",
  width: props.width || 200,
  height: props.height || 200,
}));

export default DropZone;
