import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Link,
  IconButton,
} from "@mui/material";
import Page from "@components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "@components/PageLoader";
import { includes, get, map, isString, reduce, pick, isNil } from "lodash";
import { getDims } from "@utils/imageHelpers";
import Image from "@jy95/material-ui-image";
import { DateTime } from "luxon";
import { getFullName } from "@utils/stringHelpers";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import EditProgramEditor from "../containers/EditProgramEditor";
import EditIcon from "@mui/icons-material/Edit";
import StatsCard from "./StatsCard";
import Breadcrumb from "@components/Breadcrumb";
import PageHeader from "@components/PageHeader";
import InviteButton from "./InviteButton";
import CopyProgramLink from "@views/programs/components/CopyProgramLink";
import InviteQualifiedSites from "../containers/InviteQualifiedSites";
import { PENDING_APPLICATION_STATUSES } from "@views/programs/components/Applications";
import AcrobatLogo from "@components/AcrobatLogo";
import { useDispatch, useSelector } from "react-redux";
import { getProgram, getProgramSiteStatuses } from "../actions";

export const PROGRAM_TYPES = {
  ENVIRONMENTAL_CREDITS: "Environmental credits",
  MONITORING: "Monitoring",
  REBATE: "Rebate Program",
};

export const EMISSION_REDUCTION_ACTIVITIES = {
  SOLAR_ELECTRICITY_POWER_GENERATION: "Solar electricity power generation",
  LOW_CARBON_FUEL: "Low Carbon Fuel",
};

export const TARGET_PARTICIPANTS = {
  RESIDENTIAL: "Residential",
  COMMERCIAL_AG_INDUSTRIAL: "Commercial / Agriculture / Industrial",
  UTILITY: "Utility",
  PROJECT_DEVELOPER: "Project developer",
  SERVICE_PROVIDER: "Service provider",
};

export const ACTIVITY_TYPES = {
  SOLAR: "Solar",
  WIND: "Wind",
  BIOFUEL: "Biofuel",
};

const getDateRange = (start, end) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  if (startDate.isValid && endDate.isValid) {
    return `${startDate.toLocaleString(DateTime.DATE_MED)} to ${endDate.toLocaleString(
      DateTime.DATE_MED
    )}`;
  }
  if (startDate.isValid) {
    return `Starts ${startDate.toLocaleString(DateTime.DATE_MED)}`;
  }
  if (endDate.isValid) {
    return `Ends ${endDate.toLocaleString(DateTime.DATE_MED)}`;
  }
  return "N/A";
};

const getActivity = (program, isEnvironmental) =>
  isEnvironmental
    ? {
        name: "Emissions Reductions Activity",
        value: get(EMISSION_REDUCTION_ACTIVITIES, program?.emissionReductionActivity, "N/A"),
      }
    : {
        name: "Activity",
        value: get(ACTIVITY_TYPES, program?.activityType, "N/A"),
      };

const Program = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { programId } = useParams();
  const showAppMessage = useAppMessage();

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getProgram({ accessToken, programId }));
      dispatch(getProgramSiteStatuses({ accessToken, programId }));
    }
    init();
  }, [getAccessTokenSilently, programId, dispatch]);

  const { program, status } = useSelector(state => state.getProgram);
  const isLoading = includes(["init", "request"], status);
  const isEnvironmental = program?.type === "ENVIRONMENTAL_CREDITS";

  const { userProfile } = useSelector(state => state.getUserProfile);
  useEffect(() => {
    if (!isNil(program.organization?.id) && program.organization.id !== userProfile.organizationId) {
      showAppMessage({
        severity: "warning",
        message: "Note that this (public) program is not in your organization, so parts of this page will not work."
      })
    }
  }, [program.organization?.id, showAppMessage, userProfile.organizationId, status]);  

  const downloadTerms = async (pdf) => {
    try {
      await downloadFile(pdf);
    } catch (err) {
      showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      });
    }
  };

  const [aspectRatio, setAspectRatio] = useState(1);
  const imgBoxDim = 200 - 2 * 8;

  const programData = [
    {
      name: "Program Name",
      value: program.name,
    },
    {
      name: "Program Type",
      value: get(PROGRAM_TYPES, program.type, program.type),
    },
    getActivity(program, isEnvironmental),
    {
      name: "Program Dates",
      value: getDateRange(program.startDate, program.endDate),
    },
    {
      name: "Program Manager",
      value: getFullName(
        program.manager?.familyName,
        program.manager?.givenName,
        program.manager?.userId
      ),
    },
    {
      name: "Target Participants",
      value: (
        <List disablePadding>
          {map(program.targetParticipants, (tp) => (
            <ListItem disableGutters key={tp} sx={{ padding: 0 }}>
              <ListItemText primary={get(TARGET_PARTICIPANTS, tp, tp)} sx={{ margin: 0 }} />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      name: "Terms and Conditions",
      value: (
        <Link
          href="#"
          onClick={(ev) => {
            ev.preventDefault();
            downloadTerms(program.termsAndConditions);
          }}
        >
          <Typography>
            <AcrobatLogo />
            {program.termsAndConditions?.name}
          </Typography>
        </Link>
      ),
    },
  ];

  // create programs action
  const [isProgramEditorOpen, setIsProgramEditorOpen] = useState(false);
  const handleOpenProgramEditor = () => {
    setIsProgramEditorOpen(true);
  };
  const handleCloseProgramEditor = () => {
    setIsProgramEditorOpen(false);
  };

  const { siteStatuses } = useSelector(state => state.getProgramSiteStatuses)
  const pending = reduce(
    pick(siteStatuses, PENDING_APPLICATION_STATUSES),
    (result, value) => {
      return result + value;
    },
    0
  );

  const [shareLink, setShareLink] = useState(false);

  const handleInviteToProgram = (selection) => {
    if (selection === "SHARE_LINK") {
      setShareLink(true);
    } else {
      handleOpenInviteQualifiedSites();
    }
  };

  // invite existing sites
  const [isInviteQualifiedSitesOpen, setIsInviteQualifiedSitesOpen] = useState(false);
  const handleOpenInviteQualifiedSites = () => {
    setIsInviteQualifiedSitesOpen(true);
  };
  const handleCloseInviteQualifiedSites = () => {
    setIsInviteQualifiedSitesOpen(false);
  };

  return (
    <Page title={`View Program: ${program.name}`} py={3}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Loading..." />}
        {!isLoading && (
          <>
            <PageHeader
              title={program.name}
              action={
                <InviteButton variant="contained" onSelect={handleInviteToProgram}>
                  Invite To Program
                </InviteButton>
              }
            >
              <Breadcrumb title="Program List" destination="/programs" />
            </PageHeader>
            <Box mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <StatsCard
                    title="Sites Approved"
                    value={get(siteStatuses, "APPROVED", 0)}
                    action={() => navigate(`/programs/${program.id}/sites`)}
                    color="success.light"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatsCard
                    title="Applications Pending"
                    value={pending}
                    action={() => navigate(`/programs/${program.id}/applications/pending`)}
                    color="info.light"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Paper sx={{ mt: 2, px: 5, pb: 5 }}>
                <Grid container spacing={1}>
                  {/* row 1 */}
                  <Grid item xs={12}>
                    <Toolbar disableGutters sx={{ justifyContent: "flex-end" }}>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={handleOpenProgramEditor}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Toolbar>
                  </Grid>

                  {/* row 2 */}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignContent="stretch"
                      alignItems="center"
                    >
                      {/* position image in center, fix it */}
                      <Box
                        sx={{
                          flex: "0 0 200px",
                          boxShadow: "3px 3px 8px 2px rgb(0 0 0 / 15%)",
                          padding: 1,
                          background: "common.white",
                        }}
                      >
                        <Image
                          src={program.logo?.uri}
                          imageStyle={getDims(aspectRatio, imgBoxDim)}
                          onLoad={(e) => {
                            if (e?.target) {
                              setAspectRatio(e.target.naturalWidth / e.target.naturalHeight);
                            }
                          }}
                        />
                      </Box>

                      {/* title */}
                      <Box flex={1} ml={5}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Program Summary
                        </Typography>
                        <Paper
                          sx={{ height: imgBoxDim, p: 2, overflowY: "auto" }}
                          variant="outlined"
                        >
                          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                            {program.description}
                          </Typography>
                        </Paper>
                      </Box>
                    </Box>
                  </Grid>

                  {/* row 3, col 1 */}
                  <Grid item xs={12} md={6} mt={5}>
                    <Grid container spacing={3}>
                      {programData.slice(0, 4).map((row) => (
                        <React.Fragment key={row.name}>
                          <Grid item xs={5}>
                            <Typography color="textSecondary">{row.name}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{row.value}</Typography>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} mt={5}>
                    <Grid container spacing={3}>
                      {programData.slice(4).map((row) => (
                        <React.Fragment key={row.name}>
                          <Grid item xs={5}>
                            <Typography color="textSecondary">{row.name}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            {isString(row.value) ? <Typography>{row.value}</Typography> : row.value}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </>
        )}
      </Container>
      <EditProgramEditor
        open={isProgramEditorOpen}
        onClose={handleCloseProgramEditor}
        program={program}
      />
      <CopyProgramLink program={program} open={shareLink} handleClose={() => setShareLink(false)} />
      {isInviteQualifiedSitesOpen && (
        <InviteQualifiedSites
          open={isInviteQualifiedSitesOpen}
          onClose={handleCloseInviteQualifiedSites}
          program={program}
        />
      )}
    </Page>
  );
};

export default Program;
