import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SitePicker from "../components/SitePicker";
import { findSites } from "@addSites/actions";
import { saveSite } from "../actions";
import { showAppMessage } from "@appMessage/actions";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import { setTableState } from "@views/common/actions";

const tableStateKey = "APPLY_SITE_PICKER";
const initialTableState = {
  sortModel: [
    {
      field: "capacity",
      sort: "desc",
    },
  ],
};

const mapStateToProps = ({
  findSites,
  getProgram,
  application,
  tableState,
}) => ({
  findSitesResult: findSites,
  getProgramResult: getProgram,
  application,
  sitePickerTableState:
    tableState[tableStateKey] || getDefaultTableState(initialTableState),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSite,
      findSites,
      showAppMessage,
      setSitePickerTableState: (props) =>
        setTableState({ ...props, key: tableStateKey }),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SitePicker);
