import { combineReducers } from "redux";
import onboardingReducers from "@onboarding/reducers";
import addSitesReducers from "@addSites/reducers";
import dashboardReducers from "views/dashboard/reducers";
import sitesReducers from "views/sites/reducers";
import siteReducers from "views/site/reducers";
import appMessage from "components/appMessage/reducers";
import membersReducers from "views/settings/members/reducers";
import orgInviteReducers from "views/orgInvites/reducers";
import commonReducers from "views/common/reducers";
import applyReducers from "views/apply/reducers";
import siteOwnerReducers from "views/siteOwner/reducers";
import programsReducers from "views/programs/reducers";
import reviewApplicationReducers from "views/reviewApplication/reducers";
import programReducers from "views/program/reducers";
import projectsReducers from "views/admin/projects/reducers";
import projectReducers from "views/admin/project/reducers";
import projectReportReducers from "views/admin/projectReport/reducers";
import monitoringReducers from "views/admin/monitoring/reducers";
import orgAssetReducers from "views/admin/orgAsset/reducers";
import incidentsReducers from "views/incidents/reducers";
import myApplicationsReducers from "views/myApplications/reducers";
import liveSiteReducers from "views/liveSite/reducers";
import individualDashboardReducers from "views/individual/dashboard/reducers";
import individualApplicationsReducers from "views/individual/applications/reducers";
import individualPayeeStatementReducers from "views/individual/payeeStatements/reducers";
import certificateReducers from "views/certificate/reducers";
import adminMarketplaceReducers from "views/admin/marketplace/reducers";
import buyerInviteReducers from "views/marketplace/buyerInvites/reducers";
import payeeInviteReducers from "views/marketplace/payeeInvites/reducers";
import assetClaimReducers from "views/assetClaim/reducers";
import purchaseCreditsReducers from "views/marketplace/purchaseCredits/reducers";
import claimedCreditsReducers from "views/marketplace/claimedCredits/reducers";
import marketplacePortfolioReducers from "views/marketplace/portfolio/reducers";
import adminChannelPartnerReducers from "views/admin/channelPartners/reducers";
import channelPartnerInviteReducers from "views/channelPartnerInvites/reducers";
import channelPartnerReducers from "views/channelPartner/reducers";
import customerProjectApplyReducers from "views/customerProjects/Apply/reducers";
import customerProjectApplicationsReducers from "views/customerProjects/Applications/reducers";
import customerProjectSiteReducers from "views/customerProjects/Site/reducers";
import customerProjectReducers from "views/customerProjects/CustomerProjects/reducers";
import customerProjectDevicesReducers from "views/customerProjects/Devices/reducers";
import { forEach, entries, has } from "lodash";
import logger from "debug";

const allReducers = {};
const addReducers = (reducers) => {
  forEach(entries(reducers), ([key, value]) => {
    if (has(allReducers, key)) {
      logger("weedle:error")("Duplicate reducers: ", key);
    }
    allReducers[key] = value;
  });
}

addReducers({ appMessage });
addReducers(onboardingReducers);
addReducers(addSitesReducers);
addReducers(dashboardReducers);
addReducers(sitesReducers);
addReducers(siteReducers);
addReducers(membersReducers);
addReducers(orgInviteReducers);
addReducers(commonReducers);
addReducers(applyReducers);
addReducers(siteOwnerReducers);
addReducers(programsReducers);
addReducers(reviewApplicationReducers);
addReducers(programReducers);
addReducers(projectsReducers);
addReducers(projectReducers);
addReducers(monitoringReducers);
addReducers(projectReportReducers);
addReducers(incidentsReducers);
addReducers(myApplicationsReducers);
addReducers(liveSiteReducers);
addReducers(individualDashboardReducers);
addReducers(individualApplicationsReducers);
addReducers(individualPayeeStatementReducers);
addReducers(certificateReducers);
addReducers(orgAssetReducers);
addReducers(adminMarketplaceReducers);
addReducers(buyerInviteReducers);
addReducers(purchaseCreditsReducers);
addReducers(assetClaimReducers);
addReducers(payeeInviteReducers);
addReducers(marketplacePortfolioReducers);
addReducers(claimedCreditsReducers);
addReducers(adminChannelPartnerReducers);
addReducers(channelPartnerInviteReducers);
addReducers(channelPartnerReducers);
addReducers(customerProjectReducers);
addReducers(customerProjectApplyReducers);
addReducers(customerProjectApplicationsReducers);
addReducers(customerProjectSiteReducers);
addReducers(customerProjectDevicesReducers);

const rootReducer = combineReducers(allReducers);

export default rootReducer;
