/*

Abbreviated array version of addresses, received and sent to server.
const arr = [
  {
    "street": "1401 1 Street Southeast",
    "city": "Calgary",
    "region": "Alberta",
    "country": "Canada",
    "postal": "T2G 2J3",
    "types": [
      "MAIN",
      "BILLING"
    ],
  }
]

Abbreviated object version of addresses, used by our forms.
const obj = {
  "main": {
    "street": "1401 1 Street Southeast",
    "city": "Calgary",
    "region": "Alberta",
    "country": "Canada",
    "postal": "T2G 2J3",
  },
  "billing": {} // or null
}

We always have a MAIN address, secondary is BILLING or LEGAL. We expect the secondary key to be there,
but empty if we're using the MAIN address. Thus:

const obj = objectifyAddresses(arr);
const arr = labelAddresses(obj);

 */

import {
  isEmpty,
  includes,
  find,
  every,
  get,
  forEach,
  upperCase,
  isEqual,
  pick,
  size,
  intersection,
  trim,
  first,
  nth,
} from "lodash";

const addressString = (address) => {
  const parts = [];
  if (address?.street) parts.push(address.street);
  if (address?.city) parts.push(address.city);
  if (address?.region) parts.push(address.region);
  return parts.join(", ");
};

const coordsString = (address) => {
  const parts = [];
  if (address?.latitude) parts.push(address.latitude.toFixed(1));
  if (address?.longitude) parts.push(address.longitude.toFixed(1));
  return isEmpty(parts) ? null : `(${parts.join(",")})`;
};

// NB. Likely losing precision here
const parseCoordsString = (coordsString) => {
  const coords = trim(coordsString, "()").split(",");
  return { latitude: first(coords) * 1, longitude: nth(coords, 1) * 1 };
};

// grab address object from Places
const parseAddress = (newValue) => {
  // NB. we can get more info here, like lat/lng, tz
  // see https://developers.google.com/maps/documentation/javascript/places#place_details
  const streetNumber = find(newValue.address_components, (comp) =>
    includes(comp.types, "street_number")
  );
  const route = find(newValue.address_components, (comp) => includes(comp.types, "route"));
  const locality = find(
    newValue.address_components,
    (comp) =>
      includes(comp.types, "locality") || includes(comp.types, "administrative_area_level_3")
  );
  const administrativeArea = find(newValue.address_components, (comp) =>
    includes(comp.types, "administrative_area_level_1")
  );
  const country = find(newValue.address_components, (comp) => includes(comp.types, "country"));
  const postal = find(newValue.address_components, (comp) => includes(comp.types, "postal_code"));

  return {
    street:
      streetNumber?.long_name && route?.long_name
        ? `${streetNumber?.long_name} ${route?.long_name}`
        : "",
    city: locality?.long_name || "",
    region: administrativeArea?.long_name || "",
    country: country?.long_name || "",
    postal: postal?.long_name || "",
  };
};

// checks our addresses object for an address object keyed by type, and that it has all props
const props = ["street", "city", "region", "country", "postal"];
const hasValidAddress = (type, addresses) => every(props, (prop) => get(addresses[type], prop));

// from an object of addresses, return an array of valid addresses, with types = address keys
const labelAddresses = (addresses) => {
  const ret = [];
  forEach(addresses, (address, addressType) => {
    if (hasValidAddress(addressType, addresses)) {
      ret.push({
        ...address,
        types: [upperCase(addressType)],
      });
    }
  });
  return ret;
};

// see if addresses are equal
const isAddressEqual = (adr1, adr2) => {
  return isEqual(
    pick(adr1, ["street", "city", "region", "country", "postal"]),
    pick(adr2, ["street", "city", "region", "country", "postal"])
  );
};

// see if billing and main are in same address, and the only address
const isBillingSameAsMainAddress = (addresses) => {
  if (isEmpty(addresses)) return false;
  return (
    addresses.length === 1 && size(intersection(addresses[0].types, ["MAIN", "BILLING"])) === 2
  );
};

// take an array of addresses, and turn them into an object keyed by type
const objectifyAddresses = (addresses) => {
  const result = {};
  forEach(addresses, (address) => {
    forEach(address.types, (type, index) => {
      result[type.toLowerCase()] = index === 0 ? address : {};
    });
  });
  return result;
};

// from an array of addresses (ie from server), get the labeled address
const getAddress = (addresses, label) =>
  find(addresses, (address) => includes(address.types, label));

export {
  coordsString,
  addressString,
  parseAddress,
  hasValidAddress,
  labelAddresses,
  isAddressEqual,
  isBillingSameAsMainAddress,
  objectifyAddresses,
  parseCoordsString,
  getAddress,
};
