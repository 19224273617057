import map from "lodash/map";
import { fork, all } from "redux-saga/effects";
import onboardingSagas from "@onboarding/sagas";
import addSitesSagas from "@addSites/sagas";
import dashboardSagas from "views/dashboard/sagas";
import sitesSagas from "views/sites/sagas";
import siteSagas from "views/site/sagas";
import membersSagas from "views/settings/members/sagas";
import orgInviteSagas from "views/orgInvites/sagas";
import commonSagas from "views/common/sagas";
import applySagas from "views/apply/sagas";
import siteOwnerSagas from "views/siteOwner/sagas";
import programsSagas from "views/programs/sagas";
import programSagas from "views/program/sagas";
import projectsSagas from "views/admin/projects/sagas";
import projectSagas from "views/admin/project/sagas";
import projectReportSagas from "views/admin/projectReport/sagas";
import monitoringSagas from "views/admin/monitoring/sagas";
import orgAssetSagas from "views/admin/orgAsset/sagas";
import reviewApplicationSagas from "views/reviewApplication/sagas";
import incidentsSagas from "views/incidents/sagas";
import myApplicationsSagas from "views/myApplications/sagas";
import liveSiteSagas from "views/liveSite/sagas";
import individualDashboardSagas from "views/individual/dashboard/sagas";
import individualApplicationsSagas from "views/individual/applications/sagas";
import individualPayeeStatementsSagas from "views/individual/payeeStatements/sagas";
import certificateSagas from "views/certificate/sagas";
import adminMarketplaceSagas from "views/admin/marketplace/sagas";
import buyerInviteSagas from "views/marketplace/buyerInvites/sagas";
import payeeInviteSagas from "views/marketplace/payeeInvites/sagas";
import purchaseCreditsSagas from "views/marketplace/purchaseCredits/sagas";
import marketplacePortfolioSagas from "views/marketplace/portfolio/sagas";
import claimedCreditsSagas from "views/marketplace/claimedCredits/sagas";
import assetClaimSagas from "views/assetClaim/sagas";
import adminChannelPartnerSagas from "views/admin/channelPartners/sagas";
import channelPartnerInviteSagas from "views/channelPartnerInvites/sagas";
import channelPartnerSagas from "views/channelPartner/sagas";
import customerProjectSagas from "views/customerProjects/CustomerProjects/sagas";
import customerProjectApplySagas from "views/customerProjects/Apply/sagas";
import customerProjectApplicationsSagas from "views/customerProjects/Applications/sagas";
import customerProjectSiteSagas from "views/customerProjects/Site/sagas";
import customerProjectDevicesSagas from "views/customerProjects/Devices/sagas";

const childSagas = [
  ...onboardingSagas,
  ...addSitesSagas,
  ...dashboardSagas,
  ...sitesSagas,
  ...siteSagas,
  ...membersSagas,
  ...orgInviteSagas,
  ...commonSagas,
  ...applySagas,
  ...siteOwnerSagas,
  ...programsSagas,
  ...reviewApplicationSagas,
  ...programSagas,
  ...projectsSagas,
  ...projectSagas,
  ...projectReportSagas,
  ...monitoringSagas,
  ...orgAssetSagas,
  ...incidentsSagas,
  ...myApplicationsSagas,
  ...liveSiteSagas,
  ...individualDashboardSagas,
  ...individualApplicationsSagas,
  ...certificateSagas,
  ...adminMarketplaceSagas,
  ...buyerInviteSagas,
  ...purchaseCreditsSagas,
  ...marketplacePortfolioSagas,
  ...assetClaimSagas,
  ...payeeInviteSagas,
  ...claimedCreditsSagas,
  ...adminChannelPartnerSagas,
  ...channelPartnerInviteSagas,
  ...channelPartnerSagas,
  ...customerProjectSagas,
  ...customerProjectApplySagas,
  ...customerProjectApplicationsSagas,
  ...customerProjectSiteSagas,
  ...customerProjectDevicesSagas,
  ...individualPayeeStatementsSagas
];

export default function* mainSaga() {
  yield all(map(childSagas, (childSaga) => fork(childSaga)));
}
