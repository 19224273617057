import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box, CircularProgress, Link } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { map, isEmpty, first } from "lodash";
import { DocumentTextIcon } from "@heroicons/react/solid";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadFile, fileSizeValidator } from "@utils/fileHelpers";
import byteSize from "byte-size";
import AcrobatLogo from "@components/AcrobatLogo";
import DropZone from "@components/DropZone";
import HeroIcon from "@components/HeroIcon";
import { showAppMessage, hideAppMessage } from "@appMessage/actions";
import { useDispatch, useSelector } from "react-redux";
import { uploadTerms } from "../actions";

const maxFileSize = 10 * 1000 * 1000; // bytes

const TermsUploader = ({ onChange, value, error }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const downloadTerms = async (pdf) => {
    try {
      await downloadFile(pdf);
    } catch (err) {
      dispatch(
        showAppMessage({
          severity: "error",
          message: `Sorry, there was a problem downloading the file: ${err}`,
        })
      );
    }
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/pdf": [".pdf"],
    },
    validator: fileSizeValidator(maxFileSize),
  });

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      dispatch(
        showAppMessage({
          severity: "error",
          message: fileRejections[0].errors[0].message,
        })
      );
    } else {
      dispatch(hideAppMessage());
    }
  }, [fileRejections, dispatch]);

  // we have some new files to upload
  useEffect(() => {
    async function uploadNewFiles() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      dispatch(
        uploadTerms({
          filespecs: map(acceptedFiles, (file) => ({
            file,
            tags: ["Terms and Conditions"],
            public: true,
          })),
          accessToken,
        })
      );
    }
    if (acceptedFiles.length) {
      uploadNewFiles();
    }
    // eslint-disable-next-line
  }, [acceptedFiles]);

  // inform our form
  const { filespecs, status } = useSelector((state) => state.uploadTerms);
  const uploadedFilespec = first(filespecs);
  const isUploading = status === "request";
  useEffect(() => {
    if (uploadedFilespec) {
      onChange(uploadedFilespec);
    }
  }, [onChange, uploadedFilespec]);

  return (
    <>
      <DropZone
        {...getRootProps({
          isFocused,
          isDragAccept,
          isDragReject,
          isDragActive,
          isError: !isEmpty(error),
        })}
        width="unset"
        height={120}
        sx={{ mt: 1, mb: 2 }}
      >
        <input {...getInputProps()} />
        <Box m={1}>
          <HeroIcon icon={DocumentTextIcon} />
          <Typography variant="body2" color="textPrimary" gutterBottom>
            Drag and drop your document, or browse
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Max file size: {byteSize(maxFileSize, { precision: 0 }).toString()}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            PDF only
          </Typography>
        </Box>
        {isUploading && (
          <CircularProgress
            size={32}
            sx={{
              color: (theme) => theme.palette.primary.main,
              position: "absolute",
              top: "50%",
              left: "50%",
              mt: -2,
              ml: -2,
            }}
          />
        )}
      </DropZone>
      {value?.uri && (
        <Link
          href="#"
          onClick={(ev) => {
            ev.preventDefault();
            downloadTerms(value);
          }}
        >
          <Typography>
            <AcrobatLogo />
            Uploaded File ({value.file?.name || value.name})
          </Typography>
        </Link>
      )}
    </>
  );
};

TermsUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
  error: PropTypes.string,
};

export default TermsUploader;
