import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProgramFormFields from "../components/ProgramFormFields";
import { getOrganizationUsers } from "../actions";

const mapStateToProps = ({
  getOrganizationUsers: getOrganizationUsersResult,
  getProgramSiteStatuses: getProgramSiteStatusesResult,
}) => ({
  getOrganizationUsersResult,
  getProgramSiteStatusesResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrganizationUsers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProgramFormFields);
