import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseRequestChart } from "../actions";
import { PURCHASE_REQUEST_STATUSES_BUYER } from "./PurchaseRequest";
import { pick, includes, find, round } from "lodash";

const chartedStatuses = [
  "NOT_STARTED",
  "PAYMENT_REQUESTED",
  "PAYMENT_RECEIVED",
  "OWNERSHIP_TRANSFERRED",
];

/**
 * Base chart options.
 *
 * @param  {Object} theme our mui theme
 * @return {Object} chart options
 */
const getChartOptions = (theme) => ({
  chart: {
    type: "bar",
    fontFamily: theme.typography.fontFamily,
    height: 350,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 10,
      borderRadiusApplication: "end",
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: Object.values(pick(PURCHASE_REQUEST_STATUSES_BUYER, chartedStatuses)),
    labels: {
      style: {
        fontSize: 14,
        fontFamily: "Karla",
        fontWeight: 800,
        colors: "#082B4B",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: 14,
        fontFamily: "Karla",
        colors: "#082B4B",
      },
    },
    title: {
      style: {
        fontSize: 14,
        fontFamily: "Karla",
        fontWeight: 600,
        color: "#082B4B",
      },
    },
  },
  colors: [theme.palette.primary.light, theme.palette.secondary.light],
  tooltip: {
    x: {
      formatter: (value) => `Status: ${value}`,
    },
  },
});

const PurchaseRequestChart = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const theme = useTheme();

  const { status, data } = useSelector((state) => state.getPurchaseRequestChart);
  const isLoading = includes(["init", "request"], status);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(getPurchaseRequestChart({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch]);

  // initial empty chart
  const [chartData, setChartData] = useState([
    {
      name: "Number of Purchase Requests",
      data: [0, 0, 0, 0],
    },
  ]);

  // by default, show the number of purchase requests by status, i.e. 'count'
  const [chartType, setChartType] = useState("count");

  // load chart data
  useEffect(() => {
    if (status === "success") {
      const newData = chartedStatuses.map((status) => {
        const chartItem = find(data, { status });
        if (chartItem) {
          switch (chartType) {
            case "count":
              return chartItem.count;
            case "cost":
              return round(chartItem.cost, 2);
            default:
              return 0;
          }
        }
        return 0;
      });
      const chartTypeLabels = {
        count: "Number of Purchase Requests",
        cost: "Total Cost of Purchase Requests",
      };
      setChartData([
        {
          name: chartTypeLabels[chartType],
          data: newData,
        },
      ]);
    }
  }, [status, data, chartType]);

  const opts = getChartOptions(theme);
  const chartOptions = {
    ...opts,
    yaxis: {
      title: {
        ...opts.yaxis.title,
        text: chartData[0].name,
      },
    },
    noData: {
      text: isLoading ? "Loading..." : "",
    },
  };

  const totalCount = status === "success" ? data.reduce((prev, curr) => prev + curr.count, 0) : 0;
  const totalCost =
    status === "success"
      ? round(
          data.reduce((prev, curr) => prev + curr.cost, 0),
          2
        )
      : 0;
  return (
    <Paper>
      <Grid container sx={{ padding: 3 }}>
        <Grid item container xs={12}>
          <Grid item xs={4}>
            <Box pl={3} display="flex" flexDirection="column" alignItems="center">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="chart-type-radio-buttons-group-label"
                  name="chart-type-buttons-group"
                  onChange={(event) => {
                    setChartType(event.target.value);
                  }}
                  value={chartType}
                >
                  <FormControlLabel value="count" control={<Radio />} label="Count" />
                  <FormControlLabel value="cost" control={<Radio />} label="Cost" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography>Total Credits</Typography>
              <Typography variant="h4">{totalCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography>Value of Credits</Typography>
              <Typography variant="h4">{`$${totalCost}`}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="bar"
            height={400}
            style={{ fontFamily: theme.typography.fontFamily }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PurchaseRequestChart;
