import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)`
  /* no box around cells */
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  },

  /* bold headers */
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: 800;
  },
  
  /* get rid of the last separator in the cell header */
  & .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
    display: none;
  },
  
  /* disabled cell style, but skip checkboxes (already disabled) */
  &.MuiDataGrid-root .MuiDataGrid-row.disabled .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox) {
    opacity: 0.5;
  }
`;

const CustomDataGrid = (props) => {
  return <StyledDataGrid pagination {...props} />;
};

export default CustomDataGrid;
