import React from "react";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  Typography,
  Grid,
  TextField,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const DataConnector = 'saasCharge';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="API Key"
              variant="standard"
              fullWidth
              error={!isNil(errors.apiKey?.message)}
              helperText={errors.apiKey?.message || " "}
              required
              onChange={onChange}
              value={value}
            />
          )}
          name="apiKey"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={dataConnectorParams?.apiKey || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Station Box ID"
              variant="standard"
              fullWidth
              error={!isNil(errors.chargerId?.message)}
              helperText={errors.chargerId?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="qupump-811525451"
            />
          )}
          name="chargerId"
          control={control}
          rules={{ required: "Station Box ID is required." }}
          defaultValue={dataConnectorParams?.chargerId || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        We can collect charging data via SaasCharge's API. Please provide a valid API key and the list of chargers you want to add.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Get an API Key"
            secondary="You need to provide an API key to access the SaasCharge endpoints"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Test the connection"
            secondary="Input the station/charge box ID you want connected in the field below."
          />
        </ListItem>
      </List>
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;