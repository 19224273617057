import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import authConfig from "./authConfig";
import { AppMessage } from "@appMessage";
import { Auth0Provider } from "@auth0/auth0-react";
import Router from "./router/Router";
import ErrorBoundary from "@components/ErrorBoundary";
import { useJsApiLoader } from "@react-google-maps/api";
import logger from "debug";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

const libraries = ["places"];

const App = () => {
  const navigate = useNavigate();

  // can be for going directly to a deeply nested page, or after login with a returnTo
  const onRedirectCallback = async (appState) => {
    setTimeout(() => {
      // normally we go to /callback whose job is to load the userProfile, and then go to returnTo
      // (DashboardLayout gets the org)
      // but for invites/apply, we don't have an org yet, so we want to go straight to their returnTo
      navigate(appState?.returnTo || "/callback", {
        state: appState,
      })
    }, 250);
  };

  // keep google maps loaded
  const { loadError } = useJsApiLoader({
    id: "google-maps",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries,
  });

  // ignore for now
  if (loadError) {
    logger("weedle:error")("Error loading maps: ", loadError);
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ErrorBoundary>
        <Auth0Provider
          domain={authConfig.domain}
          clientId={authConfig.clientId}
          audience={authConfig.audience}
          // this is where we process the `code` param
          redirectUri={window.location.origin}
          // after processing code, this func is executed
          onRedirectCallback={onRedirectCallback}
          // NB. you can request scopes later, but be prepared to promp for consent
          // (and of course the user needs the perm in auth0)
          scope="openid profile email"
          // this should cache the access token for getAccessTokenSilently, but has some consequences
          // see https://community.auth0.com/t/storing-auth0-tokens-in-session-storage-instead-of-local-storage/63154
          cacheLocation="localstorage"
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <AppMessage />
            <Router />
          </LocalizationProvider>
        </Auth0Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
