import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
  Tooltip,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import * as Yup from "yup";
import {
  coordsString,
  parseAddress,
} from "@utils/locationHelpers";
import Places, { placesService } from "@components/Places";
import { AddressTextField } from "@addSites/sitePicker";
import SimpleMap from "@views/addSites/sitePicker/components/SimpleMap";
import { first, isNil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getTimeZone, getAddress } from "@views/addSites/actions";
import { addressString } from "utils/locationHelpers";
import logger from "debug";

// validation
export const schema = Yup.object().shape({
  latitude: Yup.number().required("Coordinates are required."),
  longitude: Yup.number().required("Coordinates are required."),
  timeZone: Yup.string().required("Time zone is required."),

  addresses: Yup.object().shape({
    main: Yup.object().shape({
      street: Yup
        .string()
        .max(60, "Too long.")
        .required("Street name is required."),
      city: Yup.string().max(60, "Too long.").required("City is required."),
      region: Yup.string().max(60, "Too long.").required("Region is required."),
      country: Yup
        .string()
        .max(60, "Too long.")
        .required("Country is required."),
      postal: Yup
        .string()
        .matches(
          /^[a-z0-9][a-z0-9\- ]{2,10}[a-z0-9]$/i,
          "That does not look like a valid postal code format."
        ), // sanity-only
    }),
  }),
});

const SiteLocationMap = ({ marker, setMarker, readOnly }) => {
  const initialSiteLocation = useSelector(
    (state) => state.application.siteInfo.site.location // generally missing lat/lng
  );
  const initialAddress = initialSiteLocation?.addresses?.main;

  const onLoad = (map) => {
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.querySelector("#google-maps")
      );
    }
    if (initialAddress) {
      placesService.current.findPlaceFromQuery(
        { query: addressString(initialAddress), fields: ["geometry"] },
        function (results, status) {
          if (status === "OK") {
            const { viewport } = results[0].geometry;
            // set an appropriate zoom/viewport:
            // 13 is kind of arbitrary - not too zoomed in, not too zoomed out
            // we also want the user to drop the pin in case the initial address is wrong
            map.fitBounds(viewport);
            if (map.getZoom() && map.getZoom() > 13) {
              map.setZoom(13);
            }
          } else {
            logger("weedle:error")("status: ", status);
          }
        }
      );
    }
  };

  return (
    <SimpleMap
      marker={marker}
      setMarker={setMarker}
      readOnly={readOnly}
      onLoad={onLoad}
    />
  );
}

const SiteLocationForm = ({
  showHeading,
  readOnly,
  allRequired = true,
}) => {
  const dispatch = useDispatch();

  // setup form validation
  const { errors, setValue, control, watch } = useFormContext();

  // changehandler for autocomplete
  const populateMainAddress = useCallback(
    (newValue) => {
      const address = parseAddress(newValue);
      setValue("addresses.main.street", address.street);
      setValue("addresses.main.city", address.city);
      setValue("addresses.main.region", address.region);
      setValue("addresses.main.country", address.country);
      setValue("addresses.main.postal", address.postal);

      // coords are only for the site location, not per address
      const loc = newValue.geometry.location;
      const coords = { latitude: loc.lat(), longitude: loc.lng() };
      setValue("latitude", coords.latitude, { shouldValidate: true });
      setValue("longitude", coords.longitude, { shouldValidate: true });

      // find the timeZone from the coords; nb. not part of the googlemaps/js-api-loader lib
      dispatch(getTimeZone(coords));
    },
    [setValue, dispatch]
  );

  // if we get a tz response
  const timeZone = useSelector(
    (state) => state.getTimeZone.timeZone.timeZoneId
  );
  useEffect(() => {
    if (timeZone) {
      setValue("timeZone", timeZone);
    }
  }, [setValue, timeZone]);

  // if we get an address response from clicking on the map
  const addressResults = useSelector((state) => state.getAddress.results);
  useEffect(() => {
    const newValue = first(addressResults);
    if (newValue) {
      const address = parseAddress(newValue);
      setValue("addresses.main.street", address.street);
      setValue("addresses.main.city", address.city);
      setValue("addresses.main.region", address.region);
      setValue("addresses.main.country", address.country);
      setValue("addresses.main.postal", address.postal);
    }
  }, [addressResults, setValue]);

  // don't always fill in the address on map-click
  const [fillAddress, setFillAddress] = useState(false);
  const toggleFillAddress = (event) => {
    setFillAddress(event.target.checked);
  };

  // if we click on the map, update the marker
  const setMarker = ({ lat, lng }) => {
    const coords = { latitude: lat, longitude: lng };
    setValue("latitude", lat, { shouldValidate: true });
    setValue("longitude", lng, { shouldValidate: true });
    dispatch(getTimeZone(coords));
    if (fillAddress) {
      dispatch(getAddress(coords));
    }
  };

  // if we have a location, show the marker on the map
  const latitude = watch("latitude", "");
  const longitude = watch("longitude", "");
  const marker =
    latitude && longitude ? { lat: latitude, lng: longitude } : null;

  return (
    <Box m={4}>
      {showHeading && (
        <Box mb={2}>
          <Typography variant="h5" my={1}>
            Site Location and Timezone
            <Typography variant="body2" color="textSecondary">
              Please provide the geographical location and time zone of your
              site. This information is vital in providing insights about your
              power generation.
            </Typography>
          </Typography>
        </Box>
      )}

      <form noValidate>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Places
                  changeHandler={populateMainAddress}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item>
                <AddressTextField
                  errors={errors}
                  readOnly={readOnly}
                  control={control}
                  required={allRequired}
                  name="addresses.main.street"
                  label="Street"
                  placeholder="621 23 Ave NE"
                  helperText="The street and number of your site."
                />
              </Grid>
              <Grid item>
                <AddressTextField
                  errors={errors}
                  readOnly={readOnly}
                  control={control}
                  required={allRequired}
                  name="addresses.main.city"
                  label="City"
                  placeholder="Calgary"
                  helperText="The city, town or municipality in which your site resides."
                />
              </Grid>
              <Grid item>
                <AddressTextField
                  errors={errors}
                  readOnly={readOnly}
                  control={control}
                  required={allRequired}
                  name="addresses.main.region"
                  label="Province/State/Region"
                  placeholder="Alberta"
                  helperText="The region in which your site resides."
                />
              </Grid>
              <Grid item>
                <AddressTextField
                  errors={errors}
                  readOnly={readOnly}
                  control={control}
                  required={allRequired}
                  name="addresses.main.country"
                  label="Country"
                  placeholder="Canada"
                  helperText="The full country name in which your site resides."
                />
              </Grid>
              <Grid item>
                <AddressTextField
                  errors={errors}
                  readOnly={readOnly}
                  control={control}
                  required={allRequired}
                  name="addresses.main.postal"
                  label="Postal Code"
                  placeholder="T2E 1W5"
                  helperText="The postal or zip code of your site."
                />
              </Grid>
              <Grid item>
                <Controller
                  render={({ value, onChange }) => (
                    <input type="hidden" value={value} onChange={onChange} />
                  )}
                  name="latitude"
                  control={control}
                />
                <Controller
                  render={({ value, onChange }) => (
                    <input type="hidden" value={value} onChange={onChange} />
                  )}
                  name="longitude"
                  control={control}
                />
                <TextField
                  label="Geographic Coordinates (DD)"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.latitude?.message)}
                  helperText="Click the map, or search an address, to set coordinates."
                  required={allRequired}
                  value={coordsString({ latitude, longitude }) || ""}
                  color="secondary"
                  inputProps={{
                    readOnly: true,
                  }}
                  name="coords"
                />
              </Grid>
              <Grid item>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      label="Time Zone"
                      variant="standard"
                      fullWidth
                      error={!isNil(errors.timeZone?.message)}
                      helperText={`${
                        errors.timeZone?.message || ""
                      } Click the map, or search an address, to set time zone.`}
                      // nb time zone is always required as it is required to timestamp measurements accurately
                      required
                      onChange={onChange}
                      value={value}
                      color="secondary"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                  control={control}
                  name="timeZone"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Tooltip title="If not checked, only fill in the coordinates and time zone.">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="fillInAddress"
                        checked={fillAddress}
                        onChange={toggleFillAddress}
                        disabled={readOnly}
                      />
                    }
                    label="Fill in address on pin drop"
                  />
                </Tooltip>
                <SiteLocationMap
                  marker={marker}
                  setMarker={setMarker}
                  readOnly={readOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

SiteLocationForm.defaultProps = {
  showHeading: true,
  readOnly: false,
  allRequired: true,
};

SiteLocationForm.propTypes = {
  showHeading: PropTypes.bool,
  readOnly: PropTypes.bool,
  allRequired: PropTypes.bool,
};

export default SiteLocationForm;
