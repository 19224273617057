import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ProgramFormFields from "@views/programs/containers/ProgramFormFields";
import { schema } from "@views/programs/components/ProgramFormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import GreenButton from "@components/GreenButton";
import { DateTime } from "luxon";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProgramEditor = ({
  open,
  onClose,
  patchProgram,
  patchProgramResult,
  resetPatchProgram,
  program,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: program.name,
      description: program.description,
      type: program.type,
      activityType: program.activityType || "",
      emissionReductionActivity: program.emissionReductionActivity || "",
      dateRange: [
        DateTime.fromISO(program.startDate),
        DateTime.fromISO(program.endDate),
      ],
      targetParticipants: program.targetParticipants,
      logo: program.logo, // filespec
      termsAndConditions: program.termsAndConditions, // filespec
      managerId: program.manager?.id || "",
    },
  });

  // submit
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    patchProgram({ program: { id: program.id, ...data }, accessToken });
  };

  const isSubmitting = patchProgramResult.status === "request";
  const isSuccess = patchProgramResult.status === "success";

  // if success, we show a continue button
  const onContinue = () => {
    methods.reset();
    resetPatchProgram();
    onClose();
  };

  useEffect(() => {
    // every time we open the dialog, reset the form with new defaults
    methods.reset({
      name: program.name,
      description: program.description,
      type: program.type,
      activityType: program.activityType || "",
      emissionReductionActivity: program.emissionReductionActivity || "",      
      dateRange: [
        DateTime.fromISO(program.startDate),
        DateTime.fromISO(program.endDate),
      ],
      targetParticipants: program.targetParticipants,
      logo: program.logo, // filespec
      termsAndConditions: program.termsAndConditions, // filespec
      managerId: program.manager?.id || "",
    });

    // methods gets changed on re-render, but defaultValues stick, so we can't listen on mthods (and we must reset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          bgcolor: "background.dark",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Edit Program
          </Typography>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <ProgramFormFields />
        <Box display="flex" justifyContent="flex-end" m={5}>
          {isSuccess && (
            <GreenButton
              onClick={onContinue}
              size="large"
            >
              Continue
            </GreenButton>
          )}
          {!isSuccess && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isSubmitting}
              loading={isSubmitting}
              size="large"
            >
              Save Program
            </LoadingButton>
          )}
        </Box>
      </FormProvider>
    </Dialog>
  );
};

EditProgramEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  program: PropTypes.shape({}).isRequired,
  resetPatchProgram: PropTypes.func.isRequired,
  patchProgram: PropTypes.func.isRequired,
  patchProgramResult: PropTypes.shape({}).isRequired,
};

export default EditProgramEditor;
