import { getBuyerProjectReport } from "../actions";

export const initialState = {
  statuses: {},
  projectReports: {},
  errors: {},
};

// this reducer stores data from multiple requests for project report detail
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getBuyerProjectReport.REQUEST: {
      const { projectReportId } = action.payload;
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [projectReportId]: "request",
        },
        errors: {},
      };
    }

    case getBuyerProjectReport.SUCCESS: {
      const { projectReport } = action.payload;
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [projectReport.id]: "success",
        },        
        projectReports: {
          ...state.projectReports,
          [projectReport.id]: projectReport,
        },
      };
    }

    case getBuyerProjectReport.FAILURE: {
      const { projectReportId, error } = action.payload;
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [projectReportId]: "failure",
        },
        errors: {
          ...state.errors,
          [projectReportId]: error,          
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
