import { call, put, takeLatest } from "redux-saga/effects";
import { getBuyerProjectReport } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, projectReportId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/marketplace/projectReports/${projectReportId}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getBuyerProjectReportWorker(action) {
  const { accessToken, projectReportId } = action.payload;
  yield put(getBuyerProjectReport.request({ projectReportId }));

  try {
    const response = yield call(callApi, accessToken, projectReportId);
    const projectReport = response.data;
    yield put(getBuyerProjectReport.success({ projectReport }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting projectReport: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting projectReport: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting projectReport: ", err);
      message = err.message;
    }
    message = `Unable to get projectReport: ${message}`;
    yield put(getBuyerProjectReport.failure({ error: message, projectReportId }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getBuyerProjectReportWatcher() {
  yield takeLatest(getBuyerProjectReport.TRIGGER, getBuyerProjectReportWorker);
}

export default [getBuyerProjectReportWatcher];
