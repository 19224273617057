import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getCustomerProjectChart } from "../actions";
import ChartPanel from "@views/common/components/ChartPanel";
import { Paper } from "@mui/material";

const LCFSChart = () => null;

const SolarChart = ({ customerProject }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const fetchChartData = useCallback(
    async ({ startDate, endDate }) => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProjectChart({
        accessToken,
        customerProjectId: customerProject.id,
        startDate,
        endDate,
      }));
    },
    [dispatch, getAccessTokenSilently, customerProject?.id]
  );

  return (
    <Paper>
      <ChartPanel
        fetchChartData={fetchChartData}
        resetChartData={() => dispatch(getCustomerProjectChart.reset())}
        fetchSelector={(state) => state.getCustomerProjectChart}
        defaultTabName="year"
        defaultGranularity="month"
        defaultCustomStartInterval={3}
        seriesStats={["generation"]}
      />
    </Paper>
  )
};

const CustomerProjectChart = ({ customerProject }) => {
  switch (customerProject?.emissionReductionActivity) {
  case 'LOW_CARBON_FUEL':
    return <LCFSChart customerProject={customerProject} />;
  case 'SOLAR_ELECTRICITY_POWER_GENERATION':
    return <SolarChart customerProject={customerProject} />;
  default:
    return null;
  }
};

export default CustomerProjectChart;