import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Start from "../components/Start";
import { getUserProfile, getOrganization } from "@views/common/actions";
import {
  getProgram,
  initApplication,
  saveSiteOwner,
} from "../actions";
import { getMyApplications } from "@views/myApplications/actions";

const mapStateToProps = ({
  getUserProfile,
  getProgram,
  application,
  getOrganization,
  getMyApplications,
}) => ({
  getUserProfileResult: getUserProfile,
  getProgramResult: getProgram,
  application,
  getOrganizationResult: getOrganization,
  getMyApplicationsResult: getMyApplications,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserProfile,
      getProgram,
      initApplication,
      getOrganization,
      saveSiteOwner,
      getMyApplications,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Start);
