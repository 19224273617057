import { call, put, takeLatest } from "redux-saga/effects";
import { addChannelPartnerInvite } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, invite) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/channelPartners/invites`,
    invite,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const prepareRequestBody = (invite) => {
  return {
    ...invite,
    termsAndConditions: invite.termsAndConditions.map(tcs => ({
      ...tcs,
      tags: ['Terms and Conditions'],
      name: tcs.file.path
    })),
  };
}

function* addChannelPartnerInviteWorker(action) {
  yield put(addChannelPartnerInvite.request());
  try {
    const { accessToken, invite } = action.payload;
    const requestBody = prepareRequestBody(invite);
    const response = yield call(callApi, accessToken, requestBody);
    yield put(addChannelPartnerInvite.success({ invite: response.data }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error adding channel partner invite: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error adding channel partner invite: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error adding channel partner invite: ", err);
      message = err.message;
    }
    message = `Unable to add channel partner invite: ${message}`;
    yield put(addChannelPartnerInvite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* addChannelPartnerInviteWatcher() {
  yield takeLatest(addChannelPartnerInvite.TRIGGER, addChannelPartnerInviteWorker);
}

export default [addChannelPartnerInviteWatcher];
