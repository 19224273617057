import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  CircularProgress,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import SiteOwnershipForm, { siteOwnershipSchema } from "./SiteOwnershipForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { extractSiteOwnership } from "./SiteOwnershipPanel";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { patchAppSiteOwnership } from "../actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const emptyInfo = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const defaultValues = (siteOwnership) => ({
  ...siteOwnership,
  ownerType: siteOwnership.ownerType || "",
  siteOwner: {
    ...siteOwnership.siteOwner,
    external:
      siteOwnership.siteOwner?.external ||
      siteOwnership.siteOwner?.contact ||
      emptyInfo,
    individual: siteOwnership.siteOwner?.individual || emptyInfo,
  },
});

const SiteOwnershipEditor = ({ open, onClose, onSave, siteOwnership }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const patchAppSiteOwnershipResult = useSelector(
    (state) => state.patchAppSiteOwnership
  );
  // if you are setting (or patching), can only be to your own org
  const { organization } = useSelector((state) => state.getOrganization);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(siteOwnershipSchema),
    defaultValues: defaultValues(siteOwnership),
  });

  // patch application on BE
  const onSubmit = async (siteOwnership) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    dispatch(
      patchAppSiteOwnership({
        accessToken,
        // include the org id if needed
        siteOwnership: {
          ...siteOwnership,
          organization:
            siteOwnership.ownerType === "ORGANIZATION"
              ? { id: organization.id }
              : undefined,
        },
      })
    );
  };

  // close and cleanup on success
  useEffect(() => {
    if (patchAppSiteOwnershipResult.status === "success") {
      const siteOwnership = extractSiteOwnership(
        patchAppSiteOwnershipResult.application
      );

      // ie. update component state in SiteOwnershipPanel
      onSave(siteOwnership);

      // make sure our form has the proper default values
      methods.reset(defaultValues(siteOwnership));
    }
    // "methods" get changed on re-render, so ignore dependency (otherwise circular ref)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchAppSiteOwnershipResult, onSave]);

  const isSubmitting = patchAppSiteOwnershipResult.status === "request";
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Site Ownership
          </Typography>
          <LoadingButton
            color="inherit"
            onClick={() => methods.handleSubmit(onSubmit)()}
            loading={isSubmitting}
            loadingIndicator={
              <CircularProgress sx={{ color: "common.white" }} size={16} />
            }
          >
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <SiteOwnershipForm />
      </FormProvider>
    </Dialog>
  );
};

SiteOwnershipEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  siteOwnership: PropTypes.shape({
    equipmentOwnership: PropTypes.string,
    fundingSource: PropTypes.string,
    ownerType: PropTypes.string,
    siteOwner: PropTypes.shape({
      // individual, oragnization, or contact/external
    }),
  }),
};

export default SiteOwnershipEditor;
