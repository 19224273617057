import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SiteActivity from "../components/SiteActivity";
import { saveSiteActivity } from "@addSites/actions";
import { getOrganization } from "@views/common/actions";

const mapStateToProps = ({ siteInfo, getOrganization }) => ({
  siteInfo,
  getOrganizationResult: getOrganization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSiteActivity,
      getOrganization,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiteActivity);
