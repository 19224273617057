import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Box, Container } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import { useAuth0 } from "@auth0/auth0-react";

const CreateComplianceSuccess = ({ project, projectReport }) => {
  const showAppMessage = useAppMessage();
  const { getAccessTokenSilently } = useAuth0();

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadReportingSheet = async ({ projectReport, projectName }) => {
    try {
      setIsDownloading(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_project_reports",
      });
      await downloadFile(
        {
          name: `${projectName} - Aggregated Reporting Sheet to ${projectReport.endDate}.xlsm`,
          url: projectReport.aggregatedReportingSheet.uri,
        },
        accessToken
      );
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      showAppMessage({
        message: `Sorry, there was a problem downloading the reporting sheet: ${err}`,
        severity: "error",
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "calc(80vh - 64px)" }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h1" color="secondary.main">
            Success!
          </Typography>
          <Typography mt={2}>
            You have created a new project report for the project:{" "}
            <Typography fontWeight="bold" component="span">
              {project.title}
            </Typography>
          </Typography>
          <Typography mt={1}>
            Date range for your report is from{" "}
            <Typography fontWeight="bold" component="span">
              {projectReport.startDate}
            </Typography>{" "}
            to{" "}
            <Typography fontWeight="bold" component="span">
              {projectReport.endDate}
            </Typography>
            .
          </Typography>
          <Box mt={5}>
            <LoadingButton
              variant="contained"
              size="large"
              color="primary"
              onClick={() =>
                downloadReportingSheet({
                  projectReport,
                  projectName: project.title,
                })
              }
              disabled={isDownloading}
              loading={isDownloading}
            >
              Download Aggregated Reporting Sheet
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

CreateComplianceSuccess.propTypes = {
  project: PropTypes.shape({}).isRequired,
  projectReport: PropTypes.shape({}).isRequired,
};

export default CreateComplianceSuccess;
