import { submitProjectApplication } from "../actions";

export const initialState = {
  status: "init",
  error: null,
  application: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case submitProjectApplication.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case submitProjectApplication.SUCCESS: {
      const { application } = action.payload;
      return {
        ...state,
        application,
        status: "success",
      };
    }

    case submitProjectApplication.LOAD_SAVED: {
      const { application } = action.payload;
      return {
        ...state,
        application,
      };
    }

    case submitProjectApplication.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    case submitProjectApplication.RESET: {
      return {
        ...initialState,
      };
    }

    case submitProjectApplication.CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
