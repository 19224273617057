import React from "react";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  Typography,
  Grid,
  TextField,
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const DataConnector = 'wallbox';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Charger IDs"
              variant="standard"
              fullWidth
              error={!isNil(errors.chargerIds?.message)}
              helperText={errors.chargerIds?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="355092, 355078"
              autoFocus
            />
          )}
          name="chargerIds"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={dataConnectorParams?.chargerIds || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
    <Typography variant="h4" fontWeight="bold" gutterBottom>
      Instructions to Grant Rewatt Access
    </Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Login to the Wallbox platform, using this link"
          secondary={
            <Link href="https://my.wallbox.com/" target="_blank" rel="noopener">
              https://my.wallbox.com/
            </Link>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Invite Rewatt to share data"
          secondary="Select the correct Organization, then click Users -> Invite Users"
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Sharing Details"
          secondary="Invite service@rewattpower.com, select Admin role, provide custom access, and assign which chargers to share with Rewatt."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Test the connection"
          secondary="Input the serial numbers/charger ID(s) you have shared with Rewatt in the field below. Separate each charger ID with a comma if you have more than one."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="For more detailed instructions and screenshots, see this page."
          secondary={
            <Link href="https://rewattpower.com/wallbox/" target="_blank" rel="noopener">
              https://rewattpower.com/wallbox/
            </Link>
          }
        />
      </ListItem>
    </List>
  </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;