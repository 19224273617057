import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import DocumentIcon from "@mui/icons-material/Description";
import { isEmpty, join, map } from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { createDocuments, deleteDocument } from "../actions";
import Navigation from "@components/Navigation";
import DocumentUploader from "@addSites/sitePicker/containers/DocumentUploader";
import { EVTags, SolarTags } from "@addSites/sitePicker/components/TagSelectorContext";
import { Delete, Download } from "@mui/icons-material";
import { downloadFile } from "@utils/fileHelpers";
import { showAppMessage } from "components/appMessage/actions";
import DialogSaveButton from "components/DialogSaveButton";
import styled from "@emotion/styled";

const StyledList = styled(List)`
  & .MuiListSubheader-root {
    line-height: 1rem;
  }
  ,
  & .MuiListItem-root {
    padding-bottom: 0;
  }
  ,
  & .MuiListItemText-root {
    margin: 0;
  }
`;

export const UnbuiltSolarList = () => (
  <StyledList
    dense
    subheader={
      <ListSubheader>
        Please upload one or more of the following types of documents, and then tag with their type:
      </ListSubheader>
    }
  >
    <ListItem dense>
      <ListItemText primary="- Quotation for system" />
    </ListItem>
    <ListItem dense>
      <ListItemText primary="- Application for micro-generation, interconnection or operating agreement" />
    </ListItem>
    <ListItem dense>
      <ListItemText primary="- Lease agreement (if applicable)" />
    </ListItem>
  </StyledList>
);

export const BuiltSolarList = () => (
  <StyledList>
    <ListItem>
      <ListItemText primary="Microgeneration interconnection agreement with your electricity distribution company." />
    </ListItem>
    <ListItem>
      <ListItemText primary="Single-line diagram (i.e. your electrical schematic of your installation)." />
    </ListItem>
    <ListItem>
      <ListItemText primary="Paid-in-full invoice from your installer." />
    </ListItem>
    <ListItem>
      <ListItemText primary="Photo(s) of your installation, including any communication devices (e.g. AP Systems ECU or Hoymiles DTUs) and solar inverters with the serial numbers visible in the photos." />
    </ListItem>
  </StyledList>
);

export const BuiltLCFSList = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      <StyledList
        dense
        subheader={
          <ListSubheader>
            Ownership
          </ListSubheader>
        }
      >
        <ListItem dense>
          <ListItemText primary="- Equipment purchase invoice, including detailed equipment list" />
        </ListItem>
        <ListItem dense>
          <ListItemText primary="- Lease Agreement" />
        </ListItem>
        <ListItem dense>
          <ListItemText primary="- Grant Application/Approval" />
        </ListItem>
      </StyledList>
    </Grid>
    <Grid item xs={12} md={6}>
      <StyledList
        dense
        subheader={
          <ListSubheader>
            System Details
          </ListSubheader>
        }
      >
        <ListItem dense>
          <ListItemText primary="- Hardware serial numbers (e.g. photos)" />
        </ListItem>
        <ListItem dense>
          <ListItemText primary="- Installation diagrams" />
        </ListItem>
      </StyledList>
    </Grid>
    <Grid item xs={12} md={6}>
      <StyledList
        dense
        subheader={
          <ListSubheader>
            Electricity Supply
          </ListSubheader>
        }
      >
        <ListItem dense>
          <ListItemText primary="- Utility bill" />
        </ListItem>
        <ListItem dense>
          <ListItemText primary="- Purchase power agreement" />
        </ListItem>
      </StyledList>
    </Grid>
  </Grid>
);

export const UnbuiltLCFSList = () => (
  <StyledList
    dense
    subheader={
      <ListSubheader>
        Please upload one or more of the following types of documents,
        and then tag with their type:
      </ListSubheader>
    }
  >
    <ListItem dense>
      <ListItemText primary="- Quotation for system" />
    </ListItem>
    <ListItem dense>
      <ListItemText primary="- Agreement with an electricity supplier" />
    </ListItem>
  </StyledList>
);

export const SolarDocs = ({ children }) => (
  <>
    <Typography variant="h5" gutterBottom>
      Choose documents to support your ownership claim.
    </Typography>
    <Typography variant="body2" color="textSecondary" gutterBottom>
      This is important to participate in various carbon offset programs,
      and when audits are performed on your site. Without these documents,
      you cannot sell your green attributes. Locate any or all of the
      following documents, and tag them with their type.
    </Typography>
    <BuiltSolarList />
    <SolarTags>
      {children}
    </SolarTags>
  </>
);

export const LCFDocs = ({ children }) => (
  <>
    <Typography variant="h5" gutterBottom>
      Upload supporting documents 
    </Typography>
    <Typography
      gutterBottom
      variant="body2"
      color="textSecondary"
    >
      Uploading documents is optional at this time. If you are not able to upload documents now, you may click <em>Continue</em> below and add documents later.
    </Typography>
    <Box pb={1}>
      <Typography variant="body2" color="textSecondary">
        Documents are important to help verify and prove:
      </Typography>
      <List>
        <ListItem sx={{ py: 0 }}>
          <ListItemText
            primary="the ownership status of the site, hardware, and/or credits"
            primaryTypographyProps={{ variant: "body2", color: "textSecondary" }}
          />
        </ListItem>
        <ListItem sx={{ py: 0 }}>
          <ListItemText
            primary="the system details, including hardware serial numbers and installation diagrams"
            primaryTypographyProps={{ variant: "body2", color: "textSecondary" }}
          />
        </ListItem>
        <ListItem sx={{ py: 0 }}>
          <ListItemText
            primary="the supply of electricity from an electric utility or other source"
            primaryTypographyProps={{ variant: "body2", color: "textSecondary" }}
          />
        </ListItem>
      </List>
    </Box>
    <Box>
      <Typography variant="body2" color="textSecondary">
        If you have any of these documents handy, please upload them now and provide an appropriate tag:
      </Typography>
      <BuiltLCFSList />
    </Box>
    <EVTags>
      {children}
    </EVTags>
  </>
);

const UnbuiltLCFDocs = ({ children }) => (
  <>
    <Typography variant="h5" gutterBottom>
      Upload supporting documents 
    </Typography>
    <Typography
      gutterBottom
      variant="body2"
      color="textSecondary"
    >
      Uploading documents is optional at this time. If you are not able to upload documents now, you may click <em>Continue</em> below and add documents later.
    </Typography>
    <UnbuiltLCFDocs />
    <EVTags>
      {children}
    </EVTags>
  </>
);

const DocumentForm = ({ customerProject, documents, setDocuments, isSuccess=false }) => {
  switch (customerProject.emissionReductionActivity) {
    case 'LOW_CARBON_FUEL':
      return (
      <LCFDocs>
        {isSuccess ? (
          <>
          <Typography>
            Success! Your documents have been upload.
          </Typography>
            <TableContainer>
              <Table aria-label="new documents">
                <TableBody>
                  {map(documents, (doc) => (
                    <TableRow key={doc.uri}>
                      <TableCell component="th">
                        <Box display="flex" alignItems="center">
                          <DocumentIcon sx={{ mr: 1 }} />
                          <span>{doc.name || doc.file.path}</span>
                        </Box>
                      </TableCell>
                      <TableCell>{join(doc.tags.map(t => t.name || t), ", ")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <DocumentUploader documents={documents} dispatch={setDocuments} />
        )}
      </LCFDocs>
    );
    case 'SOLAR_POWER_ELECTRICITY_GENERATION':
      return (<SolarDocs><DocumentUploader documents={documents} dispatch={setDocuments} /></SolarDocs>);
    default:
      return (<SolarDocs><DocumentUploader documents={documents} dispatch={setDocuments} /></SolarDocs>);
  }
};

/**
 * Add documents to an existing site
 */
export const EditSiteAddDocuments = ({
  site,
  customerProject,
  close,
}) => {
  const dispatch = useDispatch();

  // setup the form
  const [documents, setDocuments] = useReducer(documentsReducer, []);

  // submit patch
  const { getAccessTokenSilently } = useAuth0();
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(createDocuments({
      documents: documents.map(d => ({ ...d, name: d.file.path })),
      accessToken,
      customerProjectId: customerProject.id,
      siteId: site.id
    }));
  };
  const { status } = useSelector((state) => state.createDocuments);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  // clean-up effect when form is closed
  useEffect(() => () => {
    dispatch(createDocuments.reset());
  }, [dispatch]);

  return (
    <>
      <DialogContent>
        <Paper sx={{ p: 2 }}>
          <DocumentForm
            customerProject={customerProject}
            documents={documents}
            setDocuments={setDocuments}
            isSuccess={isSuccess}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          handleClose={close}
          handleSubmit={onSubmit}
        />
      </DialogActions>
    </>
  );
};

/**
 * Add documents when adding a new site
 *
 * @param {*} param0 
 * @returns 
 */
export const AddDocuments = ({
  customerProject,
  nextAction,
  backAction,
  siteDetails,
  save
}) => {
  const initialDocuments = siteDetails.documents ?? [];

  const [documents, setDocuments] = useReducer(documentsReducer, initialDocuments);

  const onSubmit = () => {
    save({ documents: documents.map(d => ({ ...d, name: d.file.path })) });
    nextAction();
  };

  return (
    <>
      <DocumentForm
        customerProject={customerProject}
        documents={documents}
        setDocuments={setDocuments}
      />
      <Navigation
        backAction={backAction}
        nextAction={onSubmit}
        nextActionText="Continue"
      />
    </>
  );
};

/**
 * View a sites documents
 * @param {*} param0 
 * @returns 
 */
export const ViewDocuments = ({ site, customerProjectId, reviewOnly = false }) => {
  const documents = site?.documents;

  // hack to download the file, sort of like opening in new window
  const [uriDownloading, setUriDownloading] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const downloadDoc = async (doc) => {
    try {
      setUriDownloading(doc.uri);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(doc, accessToken);
    } catch (err) {
      dispatch(showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      }));
    }
    setUriDownloading("");
  };

  const downloadAll = async (docs) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    for (const doc of docs) {
      await downloadFile(doc, accessToken);
    }
  };

  const [uriDeleting, setUriDeleting] = useState();
  const deleteDoc = async (doc) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    setUriDeleting(doc.uri);
    dispatch(deleteDocument({
      accessToken,
      documentId: doc.id,
      siteId: site.id,
      customerProjectId,
    }));
  };
  const { status: deletingDocStatus } = useSelector((state) => state.deleteDocument);
  const isDeleting = deletingDocStatus === 'request';
  // reset delete
  useEffect(() => {
    if (deletingDocStatus === 'success') {
      deleteDocument.reset();
      setUriDeleting(null);
    }
  }, [deletingDocStatus]);

  return isEmpty(documents) ? (
    <Box ml={2}>
      <Typography color="textSecondary">No documents available</Typography>
    </Box>
  ) : (
    <TableContainer>
      <Table aria-label="site documents" size="small">
        <TableBody>
          {map(documents, (doc) => (
            <TableRow key={doc.uri}>
              <TableCell component="th">
                <Box display="flex" alignItems="center">
                  <DocumentIcon sx={{ mr: 1 }} />
                  <span>{doc.name || doc.file.path}</span>
                </Box>
              </TableCell>
              <TableCell>{join(doc.tags.map(t => t.name || t), ", ")}</TableCell>
              {!reviewOnly && (
                <TableCell align="right">
                  <Box>
                    <IconButton
                      color="inherit"
                      aria-label="download"
                      onClick={() => downloadDoc(doc)}
                      disabled={uriDownloading === doc.uri}
                      size="small"
                      edge="end"
                    >
                      <Download />
                    </IconButton>
                    <IconButton
                      disabled={isDeleting}
                      size="small"
                      edge="end"
                      color="inherit"
                      aria-label="download"
                      onClick={() => deleteDoc(doc)}
                    >
                      {uriDeleting === doc.uri ? <CircularProgress size={20} /> : <Delete />}
                    </IconButton>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!isEmpty(documents) && (
        <Box display="flex">
          <Box flexGrow={1} />
          <span>
            <Button
              edge="end"
              color="inherit"
              aria-label="download all"
              disabled={isEmpty(documents)}
              onClick={() => downloadAll(documents)}
              startIcon={<Download />}
              sx={{ mt: 1 }}
            >
              Download All
            </Button>
          </span>
        </Box>
      )}
    </TableContainer>
  );
};

