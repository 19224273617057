import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";
import { createAction } from "redux-actions";

const getProjects = createRoutine("GET_PROJECTS");

const setProjectTableState = createAction("SET_PROJECT_TABLE_STATE");
setProjectTableState.TYPE = setProjectTableState.toString();

const createProject = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_PROJECT"
);

const getAcceptableSites = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_ACCEPTABLE_SITES"
);

export { getProjects, setProjectTableState, createProject, getAcceptableSites };
