import { put, call, takeLatest } from "redux-saga/effects";
import { getProjectReportsForStatements } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async ({ accessToken }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projectReports`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getProjectReportsForStatementsWorker(action) {
  yield put(getProjectReportsForStatements.request());

  try {
    const response = yield call(callApi, action.payload);
    const projectReports = response.data;
    yield put(getProjectReportsForStatements.success({ projectReports }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to get project reports: ${message}`;
    yield put(getProjectReportsForStatements.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProjectReportsForStatementsWatcher() {
  yield takeLatest(getProjectReportsForStatements.TRIGGER, getProjectReportsForStatementsWorker);
}

export default [getProjectReportsForStatementsWatcher];
