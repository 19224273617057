import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DocumentUploader from "../components/DocumentUploader";
import { uploadFiles } from "@addSites/actions";
import { showAppMessage, hideAppMessage } from "@appMessage/actions";

const mapStateToProps = ({ uploadFiles: uploadFilesResult }) => ({
  uploadFilesResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      uploadFiles,
      showAppMessage,
      hideAppMessage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploader);
