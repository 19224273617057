import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Tabs, Tab } from "@mui/material";
import SetPayeeEditor from "./SetPayeeEditor";
import InvitePayeeEditor from "./InvitePayeeEditor";
import TabPanel from "@components/TabPanel";
import { useDispatch } from "react-redux";
import { patchSitePayee } from "../../actions";

/**
 *
 * @param  {[type]} options.id      cell id
 * @param  {[type]} options.value   cell value (of the payee)
 * @param  {[type]} options.api     cell api
 * @param  {[type]} options.field   cell fieldname (the payee)
 * @param  {[type]} options.row     cell the site in question
 * @param  {[type]} options.project cell the project we're looking at
 */
const PayeeEditor = ({ id, value, field, api, row: site, project }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const onClose = async () => {
    api.setCellMode(id, field, "view");
    setOpen(false);
    dispatch(patchSitePayee.reset());
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Site Payee: {site.name}</DialogTitle>
      <DialogContent>
        <Tabs
          variant="fullWidth"
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Payee tabs"
          sx={{ m: 0 }}
        >
          <Tab label="Existing User" />
          <Tab label="New User" />
        </Tabs>
        <TabPanel
          value={tabIndex}
          index={0}
          sx={{
            "& .MuiBox-root": { px: 0, py: 1 },
          }}
        >
          <SetPayeeEditor onClose={onClose} site={site} project={project} />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={1}
          sx={{
            "& .MuiBox-root": { px: 0, py: 1 },
          }}
        >
          <InvitePayeeEditor onClose={onClose} site={site} project={project} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default PayeeEditor;
