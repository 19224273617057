import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  CardHeader,
} from "@mui/material";

const CreditCard = ({
  title,
  primary,
  secondary,
  loading,
  sx,
}) => (
  <Card
    sx={{
      height: "100%",
      ...sx,
    }}
  >
    <CardHeader title={<Typography variant="h5">{title}</Typography>} />
    <CardContent
      sx={{
        minHeight: 68,
        "&:last-child": {
          py: 0,
        },
      }}
    >
      {loading && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <CircularProgress sx={{ color: "grey.500" }} size={24} />
        </Box>
      )}
      {!loading && (
        <>
          <Typography variant="body1">
            {primary}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            mt={0.5}
          >
            {secondary}
          </Typography>
        </>
      )}
    </CardContent>
  </Card>
);

CreditCard.defaultProps = {
  loading: false,
  secondary: null,
};

CreditCard.propTypes = {
  title: PropTypes.string.isRequired,

  // primary stat line
  primary: PropTypes.string.isRequired,

  // secondary stat line, if any
  secondary: PropTypes.string,

  loading: PropTypes.bool,
};

export default CreditCard;
