import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PersonalInfo from "../components/PersonalInfo";
import { updateUserProfile } from "@views/common/actions";

const mapStateToProps = ({
  application,
  getProgram,
  updateUserProfile,
  getUserProfile,
}) => ({
  application,
  getProgramResult: getProgram,
  updateUserProfileResult: updateUserProfile,
  getUserProfileResult: getUserProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfile,
      resetUpdateUserProfile: () => updateUserProfile.reset(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
