import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { map, join, isEmpty } from "lodash";
import DownloadIcon from "@mui/icons-material/GetApp";
import DocumentIcon from "@mui/icons-material/Description";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";

const Documents = ({ application }) => {
  const showAppMessage = useAppMessage();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const downloadDoc = async (doc) => {
    try {
      await downloadFile(doc, token);
    } catch (err) {
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error"
      });
    }
  };

  const downloadAll = async (docs) => {
    for (const doc of docs) {
      await downloadDoc(doc);
    }
  };

  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Documents</Typography>
        <Button
          variant="text"
          color="primary"
          startIcon={<DownloadIcon />}
          disabled={isEmpty(application?.asset?.documents)}
          onClick={() => downloadAll(application?.asset?.documents)}
          sx={{ mt: -1 }}
        >
          Download All
        </Button>
      </Box>
      <Typography variant="body1" gutterBottom>
        Here are documents for you to download and keep for your records.
      </Typography>

      <TableContainer>
        <Table aria-label="site documents">
          <TableBody>
            {map(application?.asset?.documents, (doc) => (
              <TableRow key={doc.id}>
                <TableCell component="th">
                  <Box display="flex" alignItems="center">
                    <DocumentIcon sx={{ mr: 1 }} />
                    <span>{doc.name}</span>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {join(map(doc.tags, "name"), ", ")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    startIcon={<DownloadIcon />}
                    onClick={() => downloadDoc(doc)}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEmpty(application?.asset?.documents) && (
        <Box ml={2}>
          <Typography color="textSecondary">No documents available</Typography>
        </Box>
      )}
    </Paper>
  );
};

Documents.propTypes = {
  application: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Documents;
