import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Table,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { find, includes } from "lodash";
import Navigation from "@components/Navigation";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { round } from "@utils/numberHelpers";
import { mountingTypes } from "@views/site/components/details/SiteDetails";
import Address from "@components/Address";
import { patchAppSiteConnection } from "@views/reviewApplication/actions";
import { useDispatch, useSelector } from "react-redux";
import { useAppMessage } from "@components/appMessage";

const SiteCompare = ({ application, siteInfo, prev, onSubmit, onClose }) => {
  const showAppMessage = useAppMessage();
  const missing = <Typography color="error.main">Missing</Typography>;
  const tableRows = (site) => [
    {
      label: "Site Address",
      value: site?.address ? <Address address={site?.address} height={50} /> : missing,
    },
    {
      label: "Mounting Type",
      value: find(mountingTypes, { value: site?.mountingType })?.label || "N/A",
    },
    {
      label: "Capacity (kW)",
      value: site?.capacity ? round(site?.capacity / 1000, 3) : missing,
    },
    {
      label: "Installation Date",
      value: site?.completionDate
        ? DateTime.fromISO(site?.completionDate)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_MED)
        : missing,
    },
  ];

  const applicationSite = {
    address: find(application.asset.addresses, (address) => includes("MAIN", address.types)),
    mountingType: application.asset.mountingType,
    capacity: application.asset.capacity,
    completionDate: application.asset.completionDate,
  };

  const connectionSite = {
    address: siteInfo.site.location.addresses.main,
    mountingType: siteInfo.site.details.mountingType,
    capacity: siteInfo.site.capacity,
    completionDate: siteInfo.site.completionDate,
  };

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const submit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    dispatch(
      patchAppSiteConnection({
        accessToken,
        siteInfo,
      })
    );
  };

  const { status, application: patchedApplication } = useSelector(
    (state) => state.patchAppSiteConnection
  );
  useEffect(() => {
    if (status === "success") {
      // update parent component
      onSubmit(patchedApplication.asset);

      showAppMessage({
        severity: "success",
        message: "Successfully connected site to your application. Please press continue.",
      });
    }
  }, [status, showAppMessage, onSubmit, patchedApplication?.asset]);

  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  return (
    <>
      <Typography variant="h4" color="textPrimary">
        Confirm that the application site details match the site connection details.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Ensure that the chosen site is correct - this cannot be changed once submitted.
      </Typography>

      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Paper sx={{ mt: 2, p: 4 }} elevation={2}>
            <Typography variant="h4">Application Site</Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              The originally submitted site details. These will take precedent.
            </Typography>

            <TableContainer>
              <Table aria-label="application site">
                <TableBody>
                  {tableRows(applicationSite).map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" width="50%">
                        {row.label}
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper sx={{ mt: 2, p: 4 }} elevation={2}>
            <Typography variant="h4">Site Connection</Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Site details provided from the chosen site connection.
            </Typography>

            <TableContainer>
              <Table aria-label="connection site">
                <TableBody>
                  {tableRows(connectionSite).map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" width="50%">
                        {row.label}
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Navigation
        backAction={prev}
        nextAction={isSuccess ? onClose : submit}
        nextActionText={isSuccess ? "Continue" : "Submit"}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

SiteCompare.propTypes = {
  // from the initial application
  application: PropTypes.shape({}),

  // from the connection
  siteInfo: PropTypes.shape({}),

  onSubmit: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,

  onClose: PropTypes.func.isRequired,
};

export default SiteCompare;
