import { call, put, takeLatest } from "redux-saga/effects";
import { addProjectDocuments } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";
import { map } from "lodash";

const callApi = async (accessToken, project, status, documents) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${project.id}/docs`,
    { status, documents },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* addProjectDocumentsWorker(action) {
  yield put(addProjectDocuments.request());

  const { accessToken, project, status, filespecs } = action.payload;

  const documents = map(filespecs, (fs) => ({
    name: fs.file.name,
    uri: fs.uri,
    tags: fs.tags, // empty atm
  }));

  try {
    const response = yield call(callApi, accessToken, project, status, documents);
    yield put(addProjectDocuments.success({ project: response.data }));
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error adding project document: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error adding project document: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error adding project document: ", err);
      message = err.message;
    }
    message = `Unable to add project document: ${message}`;
    yield put(addProjectDocuments.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* addProjectDocumentsWatcher() {
  yield takeLatest(addProjectDocuments.TRIGGER, addProjectDocumentsWorker);
}

export default [addProjectDocumentsWatcher];
