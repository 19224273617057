import React, { useMemo } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import CustomDataGrid from "@components/CustomDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { last, sortBy } from "lodash";
import { getBuyerPurchaseRequests } from "../actions";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import ViewIcon from "@mui/icons-material/Visibility";
import { ENVIRONMENTAL_CREDIT_TYPES } from "views/admin/project/components/Project";
import { renderCellExpand } from "components/GridCellExpand";
import { useNavigate } from "react-router-dom";
import { PURCHASE_REQUEST_STATUSES_BUYER } from "./PurchaseRequest";

const PurchaseRequests = ({ setPurchaseRequestToShow }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { status, purchaseRequests } = useSelector((state) => state.getBuyerPurchaseRequests);
  const isLoading = status === "request";
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(getBuyerPurchaseRequests({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch]);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);

  const columns = useMemo(() => [
    {
      headerName: "Request Status",
      field: "status",
      flex: 1,
      valueGetter: ({ value }) => PURCHASE_REQUEST_STATUSES_BUYER[value],
      renderCell: renderCellExpand,
    },
    {
      headerName: "Status Date",
      field: "date",
      flex: 1,
      valueFormatter: ({ value }) => 
        DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
    },
    {
      headerName: "Project",
      field: "project",
      flex: 1.5,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Credit Type",
      field: "environmentalCredit",
      flex: 1.2,
      valueGetter: ({ value }) => ENVIRONMENTAL_CREDIT_TYPES[value] ?? "Unknown",
      renderCell: renderCellExpand,
    },
    {
      headerName: "Credits",
      field: "totalEnvironmentalCredits",
      flex: 0.6,
      type: "number",
      valueFormatter: ({ value }) =>
        value.toLocaleString(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        }),
    },
    {
      headerName: "Price",
      field: "unitCost",
      flex: 0.6,
      type: "number",
      valueFormatter: ({ value }) =>
        value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      headerName: "Total",
      field: "totalCost",
      type: "number",
      flex: 0.6,
      valueFormatter: ({ value }) =>
        value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      field: "viewAction",
      headerName: "View",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: ({ row: purchaseRequest }) => (
        <IconButton
          onClick={(ev) => {
            const url = `/marketplace/portfolio/purchaseRequests/${purchaseRequest.id}`;
            navigate(url);
          }}
          aria-label="view purchase request"
          size="small"
          sx={{ ml: 1 }}
        >
          <ViewIcon />
        </IconButton>
      ),
    },
  ], [navigate]);

  const rows =
    purchaseRequests.map((pr) => {
      const mostRecentStatus = last(sortBy(pr.statusHistory, "date"));      
      return {
        id: pr.id,
        status: mostRecentStatus.status,
        date: mostRecentStatus.date,
        buyer: pr.buyer,
        project: pr.projectReport.project.title,
        environmentalCredit: pr.projectReport.project.environmentalCredit,
        totalEnvironmentalCredits: pr.projectReport.totalEnvironmentalCredits,
        unitCost: pr.pricePerCredit,
        totalCost: pr.projectReport.totalEnvironmentalCredits * pr.pricePerCredit,
      };
    }) ?? [];

  return (
    <Paper>
      <Box height={400}>
        <CustomDataGrid
          style={{ border: 0 }}
          rowHeight={50}
          loading={isLoading}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Box>
    </Paper>
  );
};

export default PurchaseRequests;
