import { call, put, takeLatest } from "redux-saga/effects";
import { getPrograms, createProgram } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (
  accessToken,
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/programs`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getProgramsWorker(action) {
  yield put(getPrograms.request());

  const { accessToken } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
    );
    yield put(
      getPrograms.success({ programs: response.data })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting programs: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting programs: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting programs: ", err);
      message = err.message;
    }
    message = `Unable to get programs: ${message}`;
    yield put(getPrograms.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProgramsWatcher() {
  yield takeLatest(getPrograms.TRIGGER, getProgramsWorker);
}

function* createProgramWatcher() {
  yield takeLatest(createProgram.SUCCESS, getProgramsWorker);
}

export default [getProgramsWatcher, createProgramWatcher];
