import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  List,
  ListItem,
  TableBody,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth0 } from "@auth0/auth0-react";
import { APPLICATION_STATUSES } from "@views/programs";
import { find, flatMap, get, includes, isNil } from "lodash";
import { getFullName } from "utils/stringHelpers";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import ApproveWithTermsDialog from "./ApproveWithTermsDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  approveApplication,
  declineApplication,
  requireChangesToApplication,
} from "../actions";
import TextArea from "components/TextArea";
import { difference } from "lodash";
import { solarTags } from "views/addSites/sitePicker/components/TagSelectorContext";
import { remove } from "lodash";
import { join } from "lodash";
import { useAppMessage } from "components/appMessage";
import { getProjectApplication } from "views/customerProjects/CustomerProjects/actions";

const isApproved = status => status === 'APPROVED';

const isPermanentlyDeclined = status => status === 'PERMANENTLY_DECLINED';

const isChangesRequired = status => status === 'CHANGES_REQUIRED';

const checkComplete = (application) => {
  if (isNil(application)) {
    return ['missing application'];
  }
  const checks = [];

  // check T&Cs
  if (isNil(application.dateAcceptedTermsAndConditions)) {
    checks.push('applicant has not accepted the terms and conditions of the customer project.');
  }
  if (!application.applicant.acceptedTermsAndConditions) {
    checks.push("applicant has not accepted Rewatt's platform terms and conditions.");
  }

  // check consistent funding source/grant responses
  const { asset } = application;
  if (asset.hasReceivedGrants && isNil(asset.fundingSource)) {
    checks.push("applicant has received grants but did not specify which grants.");
  }

  // check several asset level props
  ['capacity', 'completionDate', 'latitude', 'longitude'].forEach((prop) => {
    if (isNil(asset[prop])) checks.push(`missing ${prop}`);
  });

  // check address
  const { addresses } = asset;
  const mainAddress = find(addresses, ({ types }) => includes(types, 'MAIN'));
  if (isNil(mainAddress)) {
    checks.push("missing main address.");
  }
  ['street', 'city', 'region', 'country', 'postal'].forEach((prop) => {
    if (isNil(mainAddress[prop])) checks.push(`address is missing ${prop}`);
  });

  // check docs
  const { documents } = asset;
  if (isNil(documents) || documents.length === 0) {
    checks.push("missing documents.");
  }
  const tags = flatMap(documents.map(({ tags }) => tags.map(({ name }) => name)));
  const requiredTags = [...solarTags];
  remove(requiredTags, t => t === 'Other');
  const unusedTags = difference(requiredTags, tags);
  if (unusedTags.length > 0) {
    checks.push(`documents are missing the following tags: ${join(unusedTags, ', ')}`);
  }
  return checks;
};

/**
 * Button to approve an application
 *
 * @param {*} param0 
 * @returns 
 */
const ApproveButton = ({ notifyApplicant, application }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // missing terms and conditions override dialog
  const [openTCDialog, setOpenTCDialog] = useState(false);

  // open a warning dialog admin has to override to approve an incomplete application
  const [openIncompleteDialog, setOpenIncompleteDialog] = useState(false);
  const completenessCheck = checkComplete(application);

  const approveApplicationResult = useSelector(state => state.approveApplication);
  const isApproving = approveApplicationResult.status === "request";
  const isSuccess = approveApplicationResult.status === "success";

  useEffect(() => () => {
    dispatch(approveApplication.reset())
  }, [dispatch]);

  useEffect(() => {
    if (approveApplicationResult.status === "success") {
      dispatch(getProjectApplication.load({ application: approveApplicationResult.application }));
    }
  }, [dispatch, approveApplicationResult]);

  const handleApprove = async () => {
    if (application.status === "MISSING_TERMS_AND_CONDITIONS") {
      setOpenTCDialog(true);
    } else if (completenessCheck.length > 0) {
      setOpenIncompleteDialog(true);
    } else {
      await doApprove();
    }
  };

  const doApprove = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(approveApplication({
      accessToken,
      applicationId: application.id,
      notifyApplicant,
    }));
  }

  return (
    <>
      <ApproveWithTermsDialog
        open={openTCDialog}
        onClose={() => { setOpenTCDialog(false); }}
        applicationId={application.id}
        notifyApplicant={notifyApplicant}
      />
      <Dialog
        open={openIncompleteDialog}
        onClose={() => { setOpenIncompleteDialog(false); }}
        maxWidth="sm"
        fullWidth
      >
        <AppBar position="sticky" color="warn">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => { setOpenIncompleteDialog(false); }}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" ml={2}>
              Warning! Application appears incomplete!
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {isSuccess ? (
            <Typography gutterBottom>
              The application has been approved - you may close this dialog window.
            </Typography>
          ) : (
            <>
              <Typography gutterBottom>
                The application appears to be missing the following critical things:
              </Typography>
              <List dense>
                {completenessCheck.map(check => (
                  <ListItem key={check}>
                    <Typography>
                      {check}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            aria-label="override"
            size="large"
            onClick={doApprove}
            loading={isApproving}
            variant="contained"
            disabled={isApproving || isSuccess}
            color="warn"
            sx={{ marginRight: 1 }}
          >
            Override Warning and Approve Anyway
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <LoadingButton
        aria-label="approve"
        size="large"
        onClick={handleApprove}
        loading={isApproving}
        variant="contained"
        disabled={isApproving || isApproved(application?.status) || isPermanentlyDeclined(application?.status)}
        color="success"
        sx={{ marginRight: 1 }}
      >
        Approve
      </LoadingButton>
    </>
  );
};

/**
 * Button that handles requiring the applicant to supply changes to the application
 *
 * @param {*} param0 
 * @returns 
 */
const RequireChangesButton = ({ notifyApplicant, application }) => {
  const [openDecisionNotes, setOpenDecisionNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();
  const dispatch = useDispatch();

  const requireChangesToApplicationResult = useSelector(state => state.requireChangesToApplication);
  const isRequiringChanges = requireChangesToApplicationResult.status === "request";
  const isSuccess = requireChangesToApplicationResult.status === "success";

  useEffect(() => {
    if (requireChangesToApplicationResult.status === "success") {
      dispatch(getProjectApplication.load({ application: requireChangesToApplicationResult.application }));
    }
  }, [dispatch, requireChangesToApplicationResult]);

  useEffect(() => () => {
    dispatch(requireChangesToApplication.reset())
  }, [dispatch]);

  const handleRequiringChanges = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(requireChangesToApplication({
      accessToken,
      applicationId: application?.id,
      notifyApplicant,
      notes,
    }));
  };

  const closeEditor = () => {
    setOpenDecisionNotes(false);
    setNotes("");
    dispatch(requireChangesToApplication.reset());
  };

  useEffect(() => {
    if (isSuccess) {
      showAppMessage({
        severity: "warning",
        message: "Applicant has been notified of the required changes",
        duration: 2000,
      });
    }
  }, [isSuccess, showAppMessage]);

  const disabled = isRequiringChanges || isApproved(application?.status) || isPermanentlyDeclined(application?.status) || isChangesRequired(application?.status);

  return (
    <>
      <Dialog
        open={openDecisionNotes}
        onClose={closeEditor}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Itemize changes</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>Provide the list of changes the applicant can make to get their application approved.</Typography>
          <TextArea
            onChange={(ev) => setNotes(ev.target.value)}
            value={notes}
            inputProps={{
              maxLength: 8192,
            }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            aria-label="submit-decision"
            loading={isRequiringChanges}
            disabled={isSuccess}
            onClick={handleRequiringChanges}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        aria-label="require-changes"
        size="large"
        onClick={() => { setOpenDecisionNotes(true); }}
        variant="contained"
        disabled={disabled}
        color="warn"
        sx={{ marginRight: 1 }}
      >
        Require Changes
      </Button>
    </>
  );
};

/**
 * Button to decline an application with a reason
 *
 * @param {*} param0 
 * @returns 
 */
const DeclineButton = ({ notifyApplicant, application }) => {
  const [openDecisionNotes, setOpenDecisionNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();

  const declineApplicationResult = useSelector(state => state.declineApplication);
  const isDeclining = declineApplicationResult.status === "request";
  const isSuccess = declineApplicationResult.status === "success";

  useEffect(() => {
    if (declineApplicationResult.status === "success") {
      dispatch(getProjectApplication.load({ application: declineApplicationResult.application }));
    }
  }, [dispatch, declineApplicationResult]);

  useEffect(() => () => {
    dispatch(declineApplication.reset())
  }, [dispatch]);

  const handleDecline = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(declineApplication({
      accessToken,
      applicationId: application?.id,
      notifyApplicant,
      notes,
    }));
  };

  // close the dialog and reset the state
  const closeEditor = () => {
    setOpenDecisionNotes(false);
    setNotes("");
    dispatch(declineApplication.reset());
  };

  useEffect(() => {
    if (isSuccess) {
      showAppMessage({
        severity: "warning",
        message: "Application has been declined",
        duration: 2000,
      });
    }
  }, [isSuccess, showAppMessage]);

  const disabled = isDeclining || isApproved(application?.status) || isPermanentlyDeclined(application?.status);

  return (
    <>
      <Dialog
        open={openDecisionNotes}
        onClose={closeEditor}
        maxWidth="sm"
        fullWidth
      >
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeEditor}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="common.white" ml={2}>
              Provide a reason
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Typography gutterBottom>Provide a reason to the applicant why their application has been declined.</Typography>
          <TextArea
            onChange={(ev) => setNotes(ev.target.value)}
            value={notes}
            inputProps={{
              maxLength: 8192,
            }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            aria-label="submit-decision"
            loading={isDeclining}
            disabled={isSuccess}
            onClick={handleDecline}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        aria-label="approve"
        size="large"
        onClick={() => { setOpenDecisionNotes(true); }}
        variant="contained"
        disabled={disabled}
        color="error"
        sx={{ marginRight: 1 }}
      >
        Decline
      </Button>
    </>
  );
};

const ApprovalPanel = ({ application }) => {
  const [notifyApplicant, setNotifyApplicant] = useState(true);

  // render
  const statusChangeDate = application?.lastStatusChange
    ? DateTime.fromISO(application.lastStatusChange.date)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATETIME_SHORT)
    : null;

  return (
    <Paper sx={{ p: 4 }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4" gutterBottom>
          Current Status and Decisioning
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  {get(APPLICATION_STATUSES, application?.status)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Status Notes
                </TableCell>
                <TableCell>
                  {application?.lastStatusChange?.notes}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Change By
                </TableCell>
                <TableCell>
                  {getFullName(application?.lastStatusChange?.changedBy)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Change Date
                </TableCell>
                <TableCell>
                  {statusChangeDate ?? ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="notifyApplicant"
              checked={notifyApplicant}
              onChange={(e) => setNotifyApplicant(e.target.checked)}
            />
          }
          label="Notify applicant of your decision by email"
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <ApproveButton
          notifyApplicant={notifyApplicant}
          application={application}
        />
        <RequireChangesButton
          notifyApplicant={notifyApplicant}
          application={application}
        />
        <DeclineButton
          notifyApplicant={notifyApplicant}
          application={application}
        />
      </Box>
    </Paper>
  );
};

ApprovalPanel.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
  }),
};

export default ApprovalPanel;
