import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sites from "../components/Sites";
import { getOrganizationStats } from "@views/dashboard/actions";
import { getSites, setSitesTableState } from "../actions";
import { putSite } from "@views/site/actions";
import {
  subscribeToSiteNotifications,
  unsubscribeFromSiteNotifications,
  getSubscriptions,
} from "@site/actions";

const mapStateToProps = ({
  getOrganizationStats: getOrganizationStatsResult,
  getSites: getSitesResult,
  sitesTableState,
  subscribeToSiteNotifications: subscribeToSiteNotificationsResult,
  unsubscribeFromSiteNotifications: unsubscribeFromSiteNotificationsResult,
  getSubscriptions: getSubscriptionsResult,
}) => ({
  getOrganizationStatsResult,
  getSitesResult,
  sitesTableState,
  subscribeToSiteNotificationsResult,
  unsubscribeFromSiteNotificationsResult,
  getSubscriptionsResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrganizationStats,
      getSites,
      putSite,
      setSitesTableState,
      subscribeToSiteNotifications,
      unsubscribeFromSiteNotifications,
      getSubscriptions,
      mutateSubscriptions: (payload) => dispatch(getSubscriptions.mutate(payload))
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
