import React from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import { intersection, flatten, map, find } from "lodash";
import LiveChartPanel from "./LiveChartPanel";
import { livePageTags } from "@views/addSites/sitePicker/components/TagSelectorContext";
import FittedImage from "components/FittedImage";

const SimpleHeader = ({ siteId, siteName }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h1">
          Energy Dashboard:
          <Typography variant="h2" component="span" fontWeight={400}>
            {" "}
            {siteName}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <LiveChartPanel siteId={siteId}  />
        </Paper>
      </Grid>
    </>
  );
};

const Header = ({ siteId, siteName, documents }) => {
  const tags = flatten(map(documents, (doc) => map(doc.tags, "name")));
  if (intersection(tags, livePageTags).length !== 2) {
    return <SimpleHeader siteId={siteId} siteName={siteName} />;
  }

  const logo = find(documents, (doc) =>
    find(doc.tags, { name: "Logo for live page" })
  );
  const photo = find(documents, (doc) =>
    find(doc.tags, { name: "Site photo for live page" })
  );
  return (
    <>
      <Grid item xs={12} sm={3}>
        <FittedImage uri={logo.uri} height={87} width={87} fit="contain" />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Box display="flex" alignItems="flex-end" height="100%">
          <Typography variant="h1">
            Energy Dashboard:
            <Typography variant="h2" component="span" fontWeight={400}>
              {" "}
              {siteName}
            </Typography>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FittedImage uri={photo.uri} height="100%" width="100%" fit="cover" />
      </Grid>

      <Grid item xs={12} sm={9}>
        <LiveChartPanel siteId={siteId} />
      </Grid>
    </>
  );
};

export default Header;

