import { addProjectReportDocuments } from "../actions";

export const initialState = {
  status: "init",
  projectReport: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case addProjectReportDocuments.RESET:
      return initialState;

    case addProjectReportDocuments.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case addProjectReportDocuments.SUCCESS: {
      const { projectReport } = action.payload;
      return {
        ...state,
        status: "success",
        projectReport,
      };
    }

    case addProjectReportDocuments.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
