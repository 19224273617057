import {
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";
import { createAction } from "redux-actions";

const getOrganization = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
  "UPDATE",
])("GET_ORGANIZATION");

const getUserProfile = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
  "UPDATE",
])("GET_USER_PROFILE");

const updateUserProfile = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("UPDATE_USER_PROFILE");

const updateOrganization = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("UPDATE_ORGANIZATION");

const setTableState = createAction("SET_TABLE_STATE");
setTableState.TYPE = setTableState.toString();

// use these in a custom hook eg. @views/apply/components/useNextPrev.js

const next = createAction("HISTORY_NEXT");
next.TYPE = next.toString();

const prev = createAction("HISTORY_PREV");
prev.TYPE = prev.toString();

const resetHistory = createAction("HISTORY_RESET");
resetHistory.TYPE = resetHistory.toString();

// end history

export {
  getOrganization,
  getUserProfile,
  updateUserProfile,
  updateOrganization,
  setTableState,
  next,
  prev,
  resetHistory,
};
