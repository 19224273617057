import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Logo from "@components/Logo";
import { AppBar, Box, Toolbar } from "@mui/material";
import ActionMenu from "./ActionMenu";

const TopBar = (props) => {
  return (
    <AppBar
      sx={{
        color: "primary.main",
        backgroundColor: "common.white",
      }}
      elevation={1}
      {...props}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
          <ActionMenu/>        
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
