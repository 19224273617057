import React from "react";
import PropTypes from "prop-types";
import OrganizationInfo from "./OrganizationInfo";
import { usePrev, useNext } from "./useNextPrev";

const CompanyProfile = ({
  getProgramResult,
  application,
  saveOrganizationInfo,
}) => {
  const prev = usePrev();
  const next = useNext();

  const onSubmit = (organization) => {
    saveOrganizationInfo({ organization });
    next("/apply/siteowner");
  };

  return (
    <OrganizationInfo
      processTitle={`${getProgramResult.program?.name} Registration`}
      organization={application.organization}
      backAction={prev}
      onSubmit={onSubmit}
      submitText="Continue"
    />
  );
};

CompanyProfile.propTypes = {
  getProgramResult: PropTypes.shape({
    program: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  application: PropTypes.shape({}).isRequired,
  saveOrganizationInfo: PropTypes.func.isRequired,
};

export default CompanyProfile;
