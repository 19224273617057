import { put, call, takeLatest } from "redux-saga/effects";
import logger from "debug";
import { addBuyerOrganization } from "../actions";
import axios from "axios";
import { join, values } from "lodash";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, organization) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations`,
    organization,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* addBuyerOrganizationWorker(action) {
  yield put(addBuyerOrganization.request());

  const { accessToken, organization } = action.payload;

  try {
    const response = yield call(callApi, accessToken, organization);
    yield put(
      addBuyerOrganization.success({
        organization: response.data,
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error saving organization: ", err.response);
      if (err.response.data?.validationErrors) {
        message = join(values(err.response.data.validationErrors), "; ");
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error saving organization: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error saving organization: ", err);
      message = err.message;
    }
    message = `Unable to save organization: ${message}`;
    yield put(
      addBuyerOrganization.failure({
        error: message,
      })
    );
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* addBuyerOrganizationWatcher() {
  yield takeLatest(addBuyerOrganization.TRIGGER, addBuyerOrganizationWorker);
}

export default [addBuyerOrganizationWatcher];
