import React, { useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import pluralize from "pluralize";
import { get } from "lodash";
import { ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import { wmTokWh } from "@utils/energyHelpers";

const ProjectReportDetailPanel = ({
  project,
  projectReport,
  hideReportingSheet = false,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadReportingSheet = async () => {
    try {
      setIsDownloading(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_project_reports",
      });
      const startDate = DateTime.fromISO(projectReport?.startDate)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_SHORT);
      const endDate = DateTime.fromISO(projectReport?.endDate)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_SHORT);
      await downloadFile(
        {
          name: `Aggregated Reporting Sheet ${startDate} to ${endDate}.xlsm`,
          url: projectReport.aggregatedReportingSheet.uri,
        },
        accessToken
      );
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error",
      });
    }
  };

  const projectReportDetails = [
    {
      label: "Start Date",
      value: DateTime.fromISO(projectReport?.startDate)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_SHORT),
    },
    {
      label: "End Date",
      value: projectReport?.endDate
        ? DateTime.fromISO(projectReport.endDate)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT)
        : "N/A",
    },
    {
      label: `Total ${pluralize(
        get(ENVIRONMENTAL_CREDIT_TYPES, project?.environmentalCredit) || ""
      )}`,
      value: projectReport.totalEnvironmentalCredits?.toLocaleString() || 0
    },
    {
      label: "Number of Contributing Sites",
      value: projectReport.numberOfSites,
    },
    {
      label: "Total Solar Energy (kWh)",
      value: wmTokWh(projectReport.totalEnergyGenerated).toLocaleString(),
    },
  ];

  const isAlbertaEmissionsOffset =
    project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";
  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={1}>
            {projectReportDetails.map((row) => (
              <React.Fragment key={row.label}>
                <Grid item xs={3}>
                  <Typography color="textSecondary" gutterBottom>{row.label}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{row.value}</Typography>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
        {isAlbertaEmissionsOffset && !hideReportingSheet && (
          <Box mt={3}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={downloadReportingSheet}
              disabled={isDownloading}
              loading={isDownloading}
            >
              Download Aggregated Reporting Sheet
            </LoadingButton>
            <Typography ml={2} color="textSecondary">
              * This can take a few minutes.
            </Typography>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default ProjectReportDetailPanel;
