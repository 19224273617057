import React from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { includes, filter, map } from "lodash";
import { useForm } from "react-hook-form";
import getDataConnectorComponents from "./DataConnectors/getDataConnectorComponents";
import { DataConnector as apsEmaType } from "./DataConnectors/ApsEMA";
import { DataConnector as apsPublicType } from "./DataConnectors/ApsPublic";
import { DataConnector as cachelanType } from "./DataConnectors/CachelanSolarVu";
import { DataConnector as energSyncType } from "./DataConnectors/EnergSync";
import { DataConnector as enphaseEnlightenType } from "./DataConnectors/EnphaseEnlighten";
import { DataConnector as froniusSolarWebType } from "./DataConnectors/FroniusSolarWeb";
import { DataConnector as hoymilesSmilesCloudType } from "./DataConnectors/HoymilesSmilesCloud";
import { DataConnector as huaweiLocusNOCType } from "./DataConnectors/HuaweiLocusNOC";
import { DataConnector as refusolRefulogType } from "./DataConnectors/RefusolRefulog";
import { DataConnector as smaApiType } from "./DataConnectors/SMASunnyPortalEnnexOS";
import { DataConnector as solarEdgeApiType } from "./DataConnectors/SolarEdgeMonitoringAPI";
import { DataConnector as swiftType } from "./DataConnectors/Swift";
import { DataConnector as wallboxType } from "./DataConnectors/Wallbox";
import { DataConnector as saasChargeType } from "./DataConnectors/SaasCharge";
import { saveDataConnectorParams, testDataConnectorConnection } from "../actions";

const DataConnector = ({ customerProject }) => {
  const dispatch = useDispatch();
  const { dataConnectorParams } = useSelector((state) => state.addDevicesForm);
  const testConnectionFormMethods = useForm();
  const { getAccessTokenSilently } = useAuth0();

  // select data connector
  const handleDataConnectorChange = (event) => {
    dispatch(saveDataConnectorParams({ type: event.target.value }));
    dispatch(testDataConnectorConnection.reset());
  };

  // test connection form
  const runConnectionTest = async (formValues) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(saveDataConnectorParams(formValues));
    dispatch(testDataConnectorConnection({ accessToken }));
  };

  // N.B. values **must** precisely match Surge DataConnetor types (see Surge enum Rewatt\DataConnectors\DataConnectorType)
  const dataConnectors = customerProject.emissionReductionActivity === 'LOW_CARBON_FUEL'
    ? [
      { name: "Wallbox", value: wallboxType },
      { name: "SaasCharge", value: saasChargeType },
      { name: "Swift", value: swiftType },
      { name: "EnergSync", value: energSyncType },
    ] : [
      { name: "AP Systems - Login", value: apsEmaType },
      { name: "AP Systems - Public", value: apsPublicType },
      { name: "Cachelan Solar VU", value: cachelanType },
      { name: "Enphase Enlighten", value: enphaseEnlightenType },
      { name: "Fronius Solar.Web", value: froniusSolarWebType },
      { name: "Hoymiles S-Miles Cloud", value: hoymilesSmilesCloudType },
      { name: "Huawei LocusNoc", value: huaweiLocusNOCType },
      { name: "REFUsol REFUlog", value: refusolRefulogType },
      { name: "SMA - ennexOS", value: smaApiType },
      { name: "SolarEdge", value: solarEdgeApiType },
    ];
  const includedDataConnectors = customerProject.omittedDataConnectors
    ? filter(dataConnectors, (dc) => !includes(customerProject.omittedDataConnectors, dc.value))
    : dataConnectors;

  const { Form, Instructions } = getDataConnectorComponents({ type: dataConnectorParams?.type });
  return (
    <>
      {/* select data connector - instructions, creds */}
      <Typography variant="h4" fontWeight="bold">
        Who is the manufacturer of your metering device(s) and/or monitoring software?
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        We currently allow you to connect one manufacturer at a time. If you use more than one
        manufacturer, you will get a chance to connect them later.
      </Typography>
      <FormControl variant="standard" sx={{ minWidth: 300 }}>
        <InputLabel id="dataConnectorLabel">Manufacturer/Data Connector</InputLabel>
        <Select
          labelId="dataConnectorLabel"
          name="type"
          value={dataConnectorParams?.type || ""}
          onChange={handleDataConnectorChange}
        >
          {map(includedDataConnectors, (dataConnector) => (
            <MenuItem
              key={dataConnector.value}
              value={dataConnector.value}
              disabled={dataConnector.disabled}
            >
              {dataConnector.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>The data connector for the devices at this site.</FormHelperText>
      </FormControl>

      {/* instructions */}
      <Instructions />

      {/* form and credentials test */}
      <FormProvider {...testConnectionFormMethods}>
        <form onSubmit={testConnectionFormMethods.handleSubmit(runConnectionTest)}>
          <Form dataConnectorParams={dataConnectorParams} />
        </form>
      </FormProvider>
    </>
  );
};

DataConnector.propTypes = {
  customerProject: PropTypes.shape({
    emissionReductionActivity: PropTypes.string.isRequired,
    omittedDataConnectors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default DataConnector;


