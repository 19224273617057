import { call, put, takeLatest } from "redux-saga/effects";
import { patchCustomerProject } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, payload,  customerProjectId) => await axios.patch(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}`,
  payload,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* patchCustomerProjectWorker(action) {
  yield put(patchCustomerProject.request());

  const { accessToken, payload, customerProjectId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, payload, customerProjectId);
    const customerProject = response.data;
    yield put(patchCustomerProject.success({ customerProject }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to patch customer project: ${message}`;
    yield put(patchCustomerProject.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchCustomerProjectWatcher() {
  yield takeLatest(patchCustomerProject.TRIGGER, patchCustomerProjectWorker);
}

export default [patchCustomerProjectWatcher];
