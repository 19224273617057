// generic table state reducer
import { setTableState } from "../actions";
import { omit } from "lodash";

const basicState = {
  page: 0,
  pageSize: 10,
  sortModel: [
    // eg.
    // {
    //   field: "created",
    //   sort: "asc",
    // },
  ],
  filterModel: {
    items: [
      // eg.
      // {
      //   columnField: "programName",
      //   id: 13167,
      //   operatorValue: "contains",
      //   value: "asd",
      // },
    ],
    linkOperator: "and",
  },
};

export const initialState = {};

export const getDefaultTableState = (defaultState) => ({
  ...basicState,
  ...defaultState,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setTableState.TYPE:
      const currentTableState = state[action.payload.key] || basicState;
      return {
        ...state,
        [action.payload.key]: {
          ...currentTableState,
          ...omit(action.payload, "key"),
        },
      };
    default: {
      return state;
    }
  }
};

export default reducer;
