import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { patchAppSiteProfile } from "@views/reviewApplication/actions";
import SiteProfile from "../components/SiteProfile";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPatchAppSiteProfile: () => dispatch(patchAppSiteProfile.reset()),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SiteProfile);
