import React from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  TextField,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  Checkbox,
} from "@mui/material";
import Page from "@components/Page";
import { useForm, Controller } from "react-hook-form";
import { isNil, isEmpty, isUndefined } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Navigation from "@components/Navigation";
import { usePrev, useNext, usePrevPath } from "./useNextPrev";
import { phoneRegExp } from "utils/errorHelpers";
import { saveSiteOwner } from "@apply/actions";
import { useDispatch, useSelector } from "react-redux";

const schema = yup.object().shape({
  isOrgOwner: yup.boolean(),

  firstName: yup
    .string()
    .nullable()
    .notRequired()
    .when("isOrgOwner", {
      is: true,
      otherwise: yup
        .string()
        .max(60, "Too long.")
        .required("First name is required."),
    }),
  lastName: yup
    .string()
    .nullable()
    .notRequired()
    .when("isOrgOwner", {
      is: true,
      otherwise: yup
        .string()
        .max(60, "Too long.")
        .required("Last name is required."),
    }),
  email: yup
    .string()
    .nullable()
    .notRequired()
    .when("isOrgOwner", {
      is: true,
      otherwise: yup.string().email().required("Email is required."),
    }),
  phone: yup
    .string()
    .nullable()
    .notRequired()
    .when("isOrgOwner", {
      is: true,
      otherwise: yup
        .string()
        .trim()
        .transform((value) => (value === "" ? undefined : value))
        .min(10, "Too short.")
        .max(20, "Too long.")
        .test("phone", "Phone number is not valid.", (value) => {
          if (!isEmpty(value)) {
            return phoneRegExp.test(value);
          }
          return true;
        })
        .required("Phone number is required."),
    }),
});

const SiteOwner = () => {
  const dispatch = useDispatch();

  const program = useSelector((state) => state.getProgram.program);
  const application = useSelector((state) => state.application);
  const organization = useSelector(
    (state) => state.getOrganization.organization
  );

  const prevPath = usePrevPath();
  const prev = usePrev();
  const next = useNext();
  const backAction = prevPath ? () => prev() : null; // null, terms or personal

  const { siteOwner } = application;
  const { handleSubmit, errors, control, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      isOrgOwner: siteOwner.ownerType === "ORGANIZATION",
      firstName: siteOwner?.external?.firstName || "",
      lastName: siteOwner?.external?.lastName || "",
      email: siteOwner?.external?.email || "",
      phone: siteOwner?.external?.phone || "",
      notify: isUndefined(siteOwner?.external?.notify)
        ? true
        : siteOwner.external.notify,
    },
  });

  const isOrgOwner = watch("isOrgOwner", true);

  const onSubmit = (data) => {
    if (data.isOrgOwner) {
      dispatch(
        saveSiteOwner({
          siteOwner: {
            ownerType: "ORGANIZATION",
          },
        })
      );
    } else {
      const { firstName, lastName, email, phone, notify } = data;
      dispatch(
        saveSiteOwner({
          siteOwner: {
            ownerType: "EXTERNAL",
            external: {
              firstName,
              lastName,
              email,
              phone,
              notify,
            },
          },
        })
      );
    }
    next("/apply/opstatus");
  };

  return (
    <Page title="Site Owner" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box ml={3}>
            <Typography variant="h2" color="textPrimary">
              {program?.name} Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Start your site application
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel>
                      <Typography fontWeight="bold" mt={2}>
                        Is your organization (
                        {application.organization?.name || organization?.name})
                        the owner of the site you want to register?
                      </Typography>
                    </FormLabel>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioGroup
                          aria-label="Site Owner"
                          name="isOrgOwner"
                          value={value}
                          onChange={(event) => {
                            onChange(event.target.value === "true");
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                      control={control}
                      name="isOrgOwner"
                    />
                  </FormControl>
                </Grid>
                {!isOrgOwner && (
                  <>
                    <Grid item xs={12}>
                      <Typography mt={1} fontWeight="bold">
                        Site Owner Contact Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <TextField
                            label="First Name"
                            variant="standard"
                            fullWidth
                            error={!isNil(errors.firstName?.message)}
                            helperText={errors.firstName?.message}
                            required
                            onChange={onChange}
                            value={value}
                            color="secondary"
                          />
                        )}
                        control={control}
                        name="firstName"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <TextField
                            label="Last Name"
                            variant="standard"
                            fullWidth
                            error={!isNil(errors.lastName?.message)}
                            helperText={errors.lastName?.message}
                            required
                            onChange={onChange}
                            value={value}
                            color="secondary"
                          />
                        )}
                        control={control}
                        name="lastName"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <TextField
                            label="Email"
                            variant="standard"
                            fullWidth
                            error={!isNil(errors.email?.message)}
                            helperText={errors.email?.message}
                            required
                            onChange={onChange}
                            value={value}
                            type="email"
                            color="secondary"
                          />
                        )}
                        control={control}
                        name="email"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <TextField
                            label="Phone Number"
                            variant="standard"
                            fullWidth
                            error={!isNil(errors.phone?.message)}
                            helperText={errors.phone?.message}
                            required
                            onChange={onChange}
                            value={value}
                            color="secondary"
                          />
                        )}
                        control={control}
                        name="phone"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        render={({ onChange, value }) => (
                          <FormControlLabel
                            sx={{ mt: 2, ml: 1 }}
                            control={
                              <Checkbox
                                checked={value === true}
                                onChange={(event) =>
                                  onChange(event.target.checked)
                                }
                              />
                            }
                            label="Send email to site owner with terms and conditions to accept."
                          />
                        )}
                        control={control}
                        name="notify"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Navigation
                backAction={backAction}
                nextAction={() => handleSubmit(onSubmit)()}
                nextActionText="Continue"
              />
            </form>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default SiteOwner;
