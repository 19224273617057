import dataUpdated from "./dataUpdated";
import getOrganizationStats from "./getOrganizationStats";
import getChartData from "./getChartData";
import getOrganizationEnergy from "./getOrganizationEnergy";
import getOrganizationEmissions from "./getOrganizationEmissions";
import getOrganizationCredits from "./getOrganizationCredits";

const reducers = {
  dataUpdated,
  getOrganizationStats,
  getChartData,
  getOrganizationEnergy,
  getOrganizationEmissions,
  getOrganizationCredits,
};

export default reducers;
