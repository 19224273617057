import { getSiteIncidents } from "../actions";

export const initialState = {
  status: "init",
  incidents: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSiteIncidents.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getSiteIncidents.SUCCESS: {
      const { incidents } = action.payload;
      return {
        ...state,
        status: "success",
        incidents,
      };
    }

    case getSiteIncidents.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
