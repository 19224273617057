import getProject from "./getProject";
import getProjectSites from "./getProjectSites";
import getProjectReports from "./getProjectReports";
import getProjectCredits from "./getProjectCredits";
import getProjectStats from "./getProjectStats";
import getProjectChartByMonth from "./getProjectChartByMonth";
import getProjectChartByYear from "./getProjectChartByYear";
import createProjectReport from "./createProjectReport";
import uploadReportingSheetTemplate from "./uploadReportingSheetTemplate";
import getProjectReportCalculation from "./getProjectReportCalculation";
import patchProject from "./patchProject";
import addProjectDocuments from "./addProjectDocuments";
import uploadProjectFiles from "./uploadProjectFiles";
import setProjectStatus from "./setProjectStatus";
import deleteProjectDocument from "./deleteProjectDocument";
import patchSitePayee from "./patchSitePayee";
import addPayeeInvite from "./addPayeeInvite";
import getPayeeInvites from "./getPayeeInvites";
import removeSiteFromProject from "./removeSiteFromProject";

const reducers = {
  getProject,
  getProjectSites,
  getProjectReports,
  getProjectCredits,
  getProjectStats,
  getProjectChartByMonth,
  getProjectChartByYear,
  createProjectReport,
  uploadReportingSheetTemplate,
  getProjectReportCalculation,
  patchProject,
  addProjectDocuments,
  uploadProjectFiles,
  setProjectStatus,
  deleteProjectDocument,
  patchSitePayee,
  addPayeeInvite,
  getPayeeInvites,
  removeSiteFromProject,
};

export default reducers;
