import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const IndividualGuard = ({ children }) => {
  const navigate = useNavigate();

  const { userProfile, status } = useSelector((state) => state.getUserProfile);
  useEffect(() => {
    // if an org user tries to access an individual page, send them to a 404
    if (!userProfile.assetId && status === "success") {
      navigate("/404", { replace: true });
    }
  }, [navigate, userProfile.assetId, status]);

  return children;
};

IndividualGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IndividualGuard;
