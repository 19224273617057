import React, { useEffect} from "react";
import { GlobalStyles, Box, Container, Typography, Button, Link } from "@mui/material";
import Page from "@components/Page";
import PageLoader from "@components/PageLoader";
import { useLocation } from "react-router-dom";
import { includes } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { verifyBuyerInviteToken } from "../actions";
import { getUserProfile } from "@views/common/actions";
import WelcomeMessage from "./WelcomeMessage";
import { RewattAddressFooter } from "@components";

/**
 * For buyer invites, we follow the same general flow as organization invites, weith a few differences.
 * - organization is optional in the invite (in which case the buyer is asked to provide that info during onboarding)
 *   - we must then create the organization (and update user profile with personal info) before accepting the invite
 * - we accept the invite (and T&C) while providing an existing org, at the end of the process
 * - keep the back actions off, so we don't need to store progress or info or re-populate forms
 */
const Welcome = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(verifyBuyerInviteToken({ token }));
    }
  }, [token, dispatch]);

  useEffect(() => {
    // just to be safe, if you closed without logging out, then signed up again
    dispatch(getUserProfile.reset());
  });

  const { status, invite, error } = useSelector(state => state.verifyBuyerInviteToken)
  const isLoading = includes(
    ["init", "request"],
    status
  );

  const { loginWithRedirect } = useAuth0();
  const signup = () => {
    loginWithRedirect({
      redirectUri: window.location.origin,
      appState: {
        orgId: invite.organization?.id,
        inviteId: invite.id,
        returnTo: "/marketplace/buyer/start",
      },
      screen_hint: "signup",
    });
  };

  return (
    <Page title="Welcome" py={3}>
      <GlobalStyles styles={{
        // hide the login button
        "header .MuiButton-root": {
          display: "none"
        }
      }} />
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Checking invite..." />}
        {!isLoading && error && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "calc(100vh - 64px)" }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Sorry, we had a problem verifying your invitation. Please
                request another one (or <Link href="/">login</Link>).
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {error}
              </Typography>
            </Box>
          </Box>
        )}
        {!isLoading && !error && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            minHeight="75vh"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <WelcomeMessage invite={invite} />
              <Button
                variant="contained"
                color="primary"
                onClick={signup}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        )}
        <RewattAddressFooter />
      </Container>
    </Page>
  );
};

export default Welcome;
