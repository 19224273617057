import { call, put, takeLatest } from "redux-saga/effects";
import { getProjectApplications } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, customerProjectId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/applications`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getProjectApplicationsWorker(action) {
  yield put(getProjectApplications.request());

  const { accessToken, customerProject } = action.payload;

  try {
    const response = yield call(callApi, accessToken, customerProject.id);
    yield put(getProjectApplications.success({ applications: response.data }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get applications for customer project: ${message}`;
    yield put(getProjectApplications.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getProjectApplicationsWatcher() {
  yield takeLatest(getProjectApplications.TRIGGER, getProjectApplicationsWorker);
}

export default [getProjectApplicationsWatcher];
