import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Paper, Box, FormControlLabel, Checkbox, Link } from "@mui/material";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import AcrobatLogo from "@components/AcrobatLogo";
import LoadingButton from "@mui/lab/LoadingButton";

const TermsAndConditions = ({ program, onSubmit, acceptTerms, isSubmitting }) => {
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const showAppMessage = useAppMessage();

  // download pdf
  const downloadTerms = async (pdf) => {
    try {
      await downloadFile(pdf);
    } catch (err) {
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error",
      });
    }
  };

  const handleChange = (accepted) => {
    setAcceptedTermsAndConditions(accepted);
    acceptTerms(accepted);
  };

  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms and conditions
      </Typography>
      <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                name="acceptTermsAndConditions"
                checked={acceptedTermsAndConditions}
                onChange={(e) => handleChange(e.target.checked)}
              />
            }
            label="I have read and accept the program Terms and Conditions"
          />
          <Link
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              downloadTerms({
                url: program.termsAndConditions.uri,
                name: "Program Terms and Conditions.pdf",
              });
            }}
          >
            <Typography>
              <AcrobatLogo />
              Download PDF
            </Typography>
          </Link>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={isSubmitting || !acceptedTermsAndConditions}
            onClick={onSubmit}
            loading={isSubmitting}
            size="large"
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
};

TermsAndConditions.propTypes = {
  program: PropTypes.shape({
    termsAndConditions: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
  acceptTerms: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default TermsAndConditions;
