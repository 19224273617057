import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Link,
  Divider,
} from "@mui/material";
import Page from "@components/Page";
import ThemeViewer from "./ThemeViewer";
import { useTheme } from "@mui/material/styles";
import { filter, keys, has } from "lodash";
import Intentions from "./Intentions";
import BackgroundColor from "./BackgroundColor";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { LinkIcon, CameraIcon } from "@heroicons/react/solid";
import HeroIcon from "@components/HeroIcon";

const StyleGuide = () => {
  const theme = useTheme();

  return (
    <Page title="StyleGuide" py={3}>
      <Container maxWidth="lg">
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h1">Style Guide</Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Typography"
                  subheader={
                    <Link
                      href="https://mui.com/system/typography"
                      target="_blank"
                    >
                      https://mui.com/system/typography
                    </Link>
                  }
                  sx={{ bgcolor: "grey.200" }}
                />
                <CardContent>
                  {filter(keys(theme.typography), (key) =>
                    has(theme.typography[key], "fontSize")
                  ).map((variant) => (
                    <Typography key={variant} variant={variant}>
                      {variant}{" "}
                    </Typography>
                  ))}

                  <Divider sx={{ my: 2 }} />

                  <Typography sx={{ fontWeight: "light" }}>light</Typography>
                  <Typography sx={{ fontWeight: "regular" }}>
                    regular
                  </Typography>
                  <Typography sx={{ fontWeight: "medium" }}>medium</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>bold</Typography>

                  <Divider sx={{ my: 2 }} />

                  <Typography sx={{ fontStyle: "normal" }}>normal</Typography>
                  <Typography sx={{ fontStyle: "italic" }}>italic</Typography>
                  <Typography sx={{ fontStyle: "oblique" }}>oblique</Typography>

                  <Divider sx={{ my: 2 }} />

                  <Typography sx={{ color: "primary.main" }}>
                    primary.main
                  </Typography>
                  <Typography sx={{ color: "secondary.main" }}>
                    secondary.main
                  </Typography>
                  <Typography sx={{ color: "error.main" }}>
                    error.main
                  </Typography>
                  <Typography sx={{ color: "warning.main" }}>
                    warning.main
                  </Typography>
                  <Typography sx={{ color: "info.main" }}>info.main</Typography>
                  <Typography sx={{ color: "success.main" }}>
                    success.main
                  </Typography>
                  <Typography sx={{ color: "text.primary" }}>
                    text.primary
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    text.secondary
                  </Typography>
                  <Typography sx={{ color: "text.disabled" }}>
                    text.disabled
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Icons"
                  subheader={
                    <Link
                      href="https://mui.com/components/material-icons/"
                      target="_blank"
                    >
                      https://mui.com/components/material-icons/
                    </Link>
                  }
                  sx={{ bgcolor: "grey.200" }}
                />
                <CardContent>
                  <Box mb={1}>
                    <Typography>Choose from material icons:</Typography>
                    <AcUnitIcon />
                    <AddModeratorIcon />
                  </Box>

                  <Box>
                    <Typography>
                      Or from hero icons: (
                      <Link href="https://heroicons.com/">
                        https://heroicons.com/
                      </Link>
                      )
                    </Typography>
                    <HeroIcon icon={LinkIcon} />
                    <HeroIcon icon={CameraIcon} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Palette"
                  subheader={
                    <Link
                      href="https://mui.com/customization/palette/#palette-colors"
                      target="_blank"
                    >
                      https://mui.com/customization/palette/#palette-colors
                    </Link>
                  }
                  sx={{ bgcolor: "grey.200" }}
                />
                <CardContent>
                  <Intentions theme={theme} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Text on Background"
                  subheader={
                    <Link
                      href="https://mui.com/system/palette/#main-content"
                      target="_blank"
                    >
                      https://mui.com/system/palette/#main-content
                    </Link>
                  }
                  sx={{ bgcolor: "grey.200" }}
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold">
                    <BackgroundColor />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Raw Theme (Complete)"
                  subheader={
                    <Link
                      href="https://mui.com/customization/default-theme"
                      target="_blank"
                    >
                      https://mui.com/customization/default-theme
                    </Link>
                  }
                  sx={{ bgcolor: "grey.200" }}
                />
                <CardContent>
                  <ThemeViewer theme={theme} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default StyleGuide;
