import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import FileCopy from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const getURL = (program) => `${process.env.REACT_APP_PUBLIC_URL}/apply/${program?.id}`;

const CopyProgramLink = ({ open, handleClose, program }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="Copy Program Link"
    aria-describedby="Copy a link to this program for a website or for emails"
  >
    <DialogTitle id="alert-dialog-title">Share Program Link</DialogTitle>
    <DialogContent>
      <DialogContentText variant="body1">
        Copy this link to share your program, <strong>{program?.name}</strong>, publicly. Feel free
        to include in emails, websites, DMs, and more.
      </DialogContentText>
      <TextField
        variant="standard"
        value={getURL(program)}
        autoFocus
        margin="dense"
        label="Program Link"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mb: 1 }}>
              <CopyToClipboard text={getURL(program)}>
                <Tooltip title="Copy link to clipboard">
                  <IconButton size="large">
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
          inputProps: { readOnly: true },
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Done
      </Button>
    </DialogActions>
  </Dialog>
);

CopyProgramLink.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  program: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default CopyProgramLink;
