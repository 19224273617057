import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Organization from "../components/Organization";
import { updateOrganization } from "@views/common/actions";

const mapStateToProps = ({ getOrganization, updateOrganization }) => ({
  getOrganizationResult: getOrganization,
  updateOrganizationResult: updateOrganization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateOrganization,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
