import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper, Grid, Toolbar, IconButton, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { get } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import { ENVIRONMENTAL_CREDIT_TYPES, PROTOCOLS } from "./Project";
import EditIcon from "@mui/icons-material/Edit";
import EditProjectEditor from "../containers/EditProjectEditor";
import { useSelector } from "react-redux";

const ProjectDetailPanel = ({ project, editable = true, hidePlanningSheet = false }) => {
  const isAlbertaEmissionsOffsetProject = project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";

  const projectDetails = [
    {
      label: "Project Name",
      value: project?.title,
      url: project?.listingUrl,
    },
    {
      label: "Environmental Credit",
      value: get(ENVIRONMENTAL_CREDIT_TYPES, project?.environmentalCredit),
    },
    {
      label: "Authority",
      value: project?.authority?.name,
      url: project?.authority?.url,
    },
    {
      label: "Registry",
      value: project?.registry?.name,
      url: project?.registry?.url,
    },
    {
      label: "Protocol",
      value: get(PROTOCOLS, project?.protocol?.name),
      url: project?.protocol?.url,
    },
    {
      label: "Start Date",
      value: DateTime.fromISO(project?.startDate)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_SHORT),
    },
    {
      label: "End Date",
      value: project?.endDate
        ? DateTime.fromISO(project.endDate)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT)
        : "N/A"
    },
    {
      label: "Emissions Factor",
      value: project?.emissionsFactor
    }
  ];

  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPlanningSheet = async ({ projectId, projectName }) => {
    try {
      setIsDownloading(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects",
      });
      await downloadFile(
        {
          name: `${projectName} - Aggregated Planning Sheet.xlsm`,
          url: `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/aggregatedPlanningSheet`,
        },
        accessToken
      );
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error",
      });
    }
  };

  // edit projects action
  const [isProjectEditorOpen, setIsProjectEditorOpen] = useState(false);
  const handleOpenProjectEditor = () => {
    setIsProjectEditorOpen(true);
  };
  const handleCloseProjectEditor = () => {
    setIsProjectEditorOpen(false);
  };

  const { status } = useSelector((state) => state.getProject);
  return (
    <>
      <Paper sx={{ p: 3 }}>
        {editable && (
          <Toolbar disableGutters sx={{ justifyContent: "flex-end" }} variant="dense">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={handleOpenProjectEditor}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Toolbar>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {projectDetails.slice(0, 4).map((row) => (
                <React.Fragment key={row.label}>
                  <Grid item xs={5}>
                    <Typography color="textSecondary">{row.label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {row.url
                        ? <Link href={row.url} target="_blank">{row.value}</Link>
                        : row.value
                      }
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {projectDetails.slice(4).map((row) => (
                <React.Fragment key={row.label}>
                  <Grid item xs={5}>
                    <Typography color="textSecondary">{row.label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {row.url
                        ? <Link href={row.url} target="_blank">{row.value}</Link>
                        : row.value
                      }
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {isAlbertaEmissionsOffsetProject && !hidePlanningSheet && (
          <Box mt={3}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={() =>
                downloadPlanningSheet({
                  projectId: project.id,
                  projectName: project.title,
                })
              }
              disabled={isDownloading && true}
              loading={isDownloading}
            >
              Download Aggregated Planning Sheet
            </LoadingButton>
            <Typography ml={2} color="textSecondary">
              * This can take a few minutes.
            </Typography>
          </Box>
        )}
      </Paper>
      {status === "success" && editable && (
        <EditProjectEditor
          open={isProjectEditorOpen}
          onClose={handleCloseProjectEditor}
          project={project}
        />
      )}
    </>
  );
};

ProjectDetailPanel.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
};

export default ProjectDetailPanel;
