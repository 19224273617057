import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Navigation from "@components/Navigation";
import { useNext, usePrev } from "./useNextPrev";
import { useSelector, useDispatch } from "react-redux";
import { application as appActions } from "../actions";
import { contactSchema } from "@views/common/components/ContactForm";
import { ApplicationPage } from "./ApplicationLayout";
import InstallerForm from "views/apply/components/InstallerForm";

const Installer = () => {
  const dispatch = useDispatch();

  const application = useSelector((state) => state.customerProjectApplication);

  const prev = usePrev();
  const next = useNext();

  const methods = useForm({
    resolver: yupResolver(contactSchema),
    mode: "onChange",
    defaultValues: application.installer ?? {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    }
  });

  const onSubmit = async (installer) => {
    const installerDetails = {
      ...installer,
      givenName: installer.firstName,
      familyName: installer.lastName
    };
    dispatch(appActions.saveAssetDetails({ installer: installerDetails }));
    next("docs");
  };

  return (
    <ApplicationPage pageName="Installer Details">
      <FormProvider {...methods}>
        <InstallerForm />
      </FormProvider>
      <Navigation
        backAction={prev}
        nextAction={() => methods.handleSubmit(onSubmit)()}
        nextActionText="Continue"
      />
    </ApplicationPage>
  );
};

export default Installer;
