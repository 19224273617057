import { call, put, takeLatest } from "redux-saga/effects";
import { deleteDocument } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, customerProjectId, siteId, documentId) => await axios.delete(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites/${siteId}/documents/${documentId}`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* deleteDocumentWorker(action) {
  yield put(deleteDocument.request());
  try {
    const { accessToken, customerProjectId, siteId, documentId } = action.payload;
    yield call(callApi, accessToken, customerProjectId, siteId, documentId);
    yield put(deleteDocument.success({ accessToken, customerProjectId, siteId }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Error deleting document: ${message}`;
    yield put(deleteDocument.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* deleteDocumentWatcher() {
  yield takeLatest(deleteDocument.TRIGGER, deleteDocumentWorker);
}

export default [deleteDocumentWatcher];
