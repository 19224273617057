import React, { useEffect } from "react";
import { Typography, Paper, Box } from "@mui/material";
import { map, includes } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { getOnboardingChart } from "../actions";

const chartOptions = (theme) => ({
  chart: {
    type: "line",
    fontFamily: theme.typography.fontFamily,
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: [0, 4],
  },
  legend: {
    position: "top",
    fontSize: 14,
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1],
    style: {
      fontSize: 12,
    },
  },
  labels: [],
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontSize: 12,
      },
    },
  },
  yaxis: [
    {
      title: {
        text: "Capacity Added (kW)",
      },
      labels: {
        style: {
          fontSize: 14,
        },
        formatter: (val) => val.toLocaleString(),
      },
    },
    {
      opposite: true,
      title: {
        text: "Sites Added",
      },
      labels: {
        style: {
          fontSize: 14,
        },
        formatter: (val) => val.toLocaleString(),
      },
    },
  ],
  tooltip: {
    style: {
      fontSize: 14,
    },
    x: {
      format: 'MMMM yyyy',
    }
  },
  noData: {
    text: "Loading...",
  },
  colors: [theme.palette.secondary.dark, theme.palette.primary.light],
});

const OnboardingChart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // fetch data
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_sites",
      });
      dispatch(getOnboardingChart.reset());
      dispatch(
        getOnboardingChart({
          accessToken,
        })
      );
    }
    init();
  }, [getAccessTokenSilently, dispatch]);
  const { chart, status } = useSelector((state) => state.getOnboardingChart);

  // setup chart
  const isLoading = includes(["init", "request"], status);
  const opts = chartOptions(theme);
  const options = {
    ...opts,
    noData: {
      text: isLoading ? "Loading..." : "No Data",
    },
    labels: map(chart, "yearMonth"),
  };
  const series = [
    {
      name: "Capacity Added (kW)",
      type: "column",
      data: map(chart, (datum) => datum.capacity / 1000),
    },
    {
      name: "Sites Added",
      type: "line",
      data: map(chart, "assetCount"),
    },
  ];

  return (
    <Paper>
      <Typography variant="h5" pt={2} pl={2}>
        Onboarding Progress
      </Typography>

      <Box height={400} position="relative" px={3} pt={3}>
        <ReactApexChart options={options} series={series} height={350} />
      </Box>
    </Paper>
  );
};
export default OnboardingChart;
