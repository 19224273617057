import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Tooltip,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import EditIcon from "@mui/icons-material/Edit";
import SiteProfileEditor from "../containers/SiteProfileEditor";
import Address from "@components/Address";
import { includes, find } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { round } from "@utils/numberHelpers";
import { mountingTypes } from "@views/site/components/details/SiteDetails";

export const extractSiteProfile = (application) => {
  const siteProfile = {
    name: application?.asset?.name,
    address: find(application?.asset?.addresses, (address) =>
      includes(address.types, "MAIN")
    ),
    capacity: application?.asset?.capacity || "", // watts
    mountingType: application?.asset?.mountingType || "",
    completionDate: application?.asset?.completionDate
      ? DateTime.fromISO(application?.asset?.completionDate)
      : "", // iso format "2017-09-29"
  };
  return siteProfile;
};

const SiteProfile = ({ application, resetPatchAppSiteProfile }) => {
  // store site profile from application in component state
  const [siteProfile, setSiteProfile] = useState();
  useEffect(() => {
    if (application?.asset) {
      setSiteProfile(extractSiteProfile(application));
    }
  }, [application]);

  useEffect(() => {
    resetPatchAppSiteProfile();
  }, [resetPatchAppSiteProfile]);

  // site profile editor
  const [isSiteProfileEditorOpen, setIsSiteProfileEditorOpen] = useState(false);
  const handleEdit = () => {
    setIsSiteProfileEditorOpen(true);
  };
  const handleClose = () => {
    setIsSiteProfileEditorOpen(false);
  };

  const handleSave = useCallback(
    (siteProfile) => {
      setSiteProfile(siteProfile);
      handleClose();
    },
    [setSiteProfile]
  );

  const missing = <Typography color="error.main">Missing</Typography>;

  const tableRows = [
    {
      label: "Site Name",
      value: siteProfile?.name || missing,
    },
    {
      label: "Site Address",
      value: siteProfile?.address ? (
        <Address address={siteProfile?.address} />
      ) : (
        missing
      ),
    },
    {
      label: "Mounting Type",
      value:
        find(mountingTypes, { value: siteProfile?.mountingType })?.label ||
        "N/A",
    },
    {
      label: "Capacity (kW)",
      value: siteProfile?.capacity
        ? round(siteProfile?.capacity / 1000, 3)
        : missing,
    },
    {
      label: "Installation Date",
      value: siteProfile?.completionDate
        ? DateTime.fromISO(siteProfile?.completionDate)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_FULL)
        : missing,
    },
  ];

  const isApplicationApproved = application.status === "APPROVED";

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Profile
          </Typography>
          <Tooltip
            title={
              isApplicationApproved
                ? "Application already approved"
                : "Edit Site Information"
            }
          >
            <span>
              <IconButton
                aria-label="edit"
                color="primary"
                sx={{ mt: -1.5 }}
                onClick={handleEdit}
                disabled={isApplicationApproved}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {siteProfile && (
        <SiteProfileEditor
          open={isSiteProfileEditorOpen}
          onClose={handleClose}
          onSave={handleSave}
          siteProfile={siteProfile}
        />
      )}
    </>
  );
};

SiteProfile.propTypes = {
  application: PropTypes.shape({}),
  resetPatchAppSiteProfile: PropTypes.func.isRequired,
};

export default SiteProfile;
