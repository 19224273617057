import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChartPanel from "@site/components/operations/ChartPanel";
import { getSiteChart } from "@site/actions";

const mapStateToProps = ({
  getSiteChart: getSiteChartResult,
}) => ({
  getSiteChartResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSiteChart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChartPanel);
