import React, { useState } from "react";
import { Typography, Container, Box, Grid, Paper } from "@mui/material";
import Page from "@components/Page";
import Image from "@jy95/material-ui-image";
import { useLocation } from "react-router-dom";
import { getDims } from "@utils/imageHelpers";

const ThankYou = () => {
  const { state } = useLocation();

  // logo handling
  const [aspectRatio, setAspectRatio] = useState(1);
  const imgBoxDim = 225 - 2 * 8;

  return (
    <Page title="Thank You" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Paper sx={{ mt: 2, p: 10 }}>
            <Grid container>
              {state?.applicationId && (
                <Grid item xs={12}>
                  {/* row 1 */}
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignContent="stretch"
                    alignItems="center"
                  >
                    {/* position image in center */}
                    <Box
                      style={{
                        flex: `0 0 225px`,
                        boxShadow: "3px 3px 8px 2px rgb(0 0 0 / 15%)",
                        padding: 8,
                      }}
                    >
                      <Image
                        src={state?.program?.logo?.uri}
                        imageStyle={getDims(aspectRatio, imgBoxDim)}
                        onLoad={(e) => {
                          if (e?.target) {
                            setAspectRatio(
                              e.target.naturalWidth / e.target.naturalHeight
                            );
                          }
                        }}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </Box>

                    {/* title */}
                    <Box flex={1}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h2" pl={12}>
                          Thank you for applying to the{" "}
                          {state?.program?.organization?.name}{" "}
                          {state?.program?.name}!
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
              {/* end of row 1 */}

              <Grid item xs={12}>
                {state?.applicationId && (
                  <Box
                    mt={8}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography
                      variant="h3"
                      sx={{ mb: 3, textAlign: "center" }}
                    >
                      Your application has been successfully received by{" "}
                      {state?.program?.organization?.name} and is under review.
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ mb: 3, textAlign: "center" }}
                    >
                      This is your application ID number. Please keep for your
                      records.
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{ mb: 3, textAlign: "center" }}
                    >
                      {state?.applicationId}
                    </Typography>
                  </Box>
                )}
                {!state?.applicationId && (
                  <Box mt={8}>
                    <Typography
                      variant="h3"
                      sx={{ mb: 3, textAlign: "center" }}
                    >
                      We're sorry, there was a problem submitting your
                      application. Please try again.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default ThankYou;
