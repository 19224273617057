import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Box,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Page from "@components/Page";
import { useForm } from "react-hook-form";
import ManufacturerForm from "@addSites/manufacturer/components/ManufacturerForm";
import Navigation from "@components/Navigation";
import { usePrev, useNext } from "./useNextPrev";
import { isEmpty } from "lodash";

const Manufacturer = (props) => {
  const formMethods = useForm();
  const prev = usePrev();
  const next = useNext();

  const { connectionTestResult, showAppMessage, getProgramResult } = props;
  const { canAccessData, status } = connectionTestResult;

  const [contactInstaller, setContactInstaller] = useState(false);

  // next button
  const onNext = async () => {
    // make sure we show any validation on the embedded form ie. required fields
    // the only way we can do that is if we get the form setup here
    await formMethods.trigger();

    // we can only get this if we had a successful connection test
    // we could probably do more here, eg. if you delete the key after a success,
    // we don't clear the form/redux straight away (so it is still a success), but diminishing returns
    if (canAccessData) {
      next("/apply/sitepicker");
    } else {
      if (contactInstaller) {
        next("/apply/installer");
      } else {
        showAppMessage({
          severity: "warning",
          message: "Please ensure you have a valid connection first.",
        });        
      }
    }
  };

  return (
    <Page title="Manufacturer" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box ml={3}>
            <Typography variant="h2" color="textPrimary">
              {getProgramResult.program?.name} Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Manufacturer
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <ManufacturerForm {...props} formMethods={formMethods} />

            {((status === "success" && !canAccessData) || !isEmpty(formMethods.errors)) && (
              <FormControlLabel
                sx={{ mt: 2, ml: 1 }}
                control={
                  <Checkbox
                    checked={contactInstaller === true}
                    onChange={(event) => {
                      setContactInstaller(event.target.checked);
                    }}
                  />
                }
                label="Having trouble connecting? Check and press Continue."
              />
            )}
            <Navigation
              backAction={prev}
              nextAction={onNext}
              nextActionText="Continue"
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

Manufacturer.propTypes = {
  // NB. all props are passed onto the ManufacturerForm
  siteInfo: PropTypes.shape({
    manufacturer: PropTypes.string,
  }),
  showAppMessage: PropTypes.func.isRequired,
  saveManufacturer: PropTypes.func.isRequired,
  saveCredentials: PropTypes.func.isRequired,
  testConnection: PropTypes.func.isRequired,
  resetTestConnection: PropTypes.func.isRequired,
  connectionTestResult: PropTypes.shape({
    canAccessData: PropTypes.bool,
    status: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  getProgramResult: PropTypes.shape({
    program: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default Manufacturer;
