import { createRoutine, createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const getProject = createRoutineCreator([...defaultRoutineStages, "INIT"])("GET_PROJECT");

const getProjectSites = createRoutine("GET_PROJECT_SITES");
const removeSiteFromProject = createRoutineCreator([...defaultRoutineStages, "RESET"])("REMOVE_SITE_FROM_PROJECT");
const getPayeeInvites = createRoutine("GET_PAYEE_INVITES");
const getProjectReports = createRoutine("GET_PROJECT_REPORTS");
const getProjectCredits = createRoutine("GET_PROJECT_CREDITS");
const getProjectStats = createRoutine("GET_PROJECT_STATS");
const getProjectChartByMonth = createRoutine("GET_PROJECT_CHART_BY_MONTH");
const getProjectChartByYear = createRoutine("GET_PROJECT_CHART_BY_YEAR");
const uploadReportingSheetTemplate = createRoutine("UPLOAD_REPORTING_SHEET");
const patchProject = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_PROJECT");
const uploadProjectFiles = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "UPLOAD_PROJECT_FILES"
);
const addProjectDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_PROJECT_DOCUMENTS"
);
const setProjectStatus = createRoutine("SET_PROJECT_STATUS");
const deleteProjectDocument = createRoutine("DELETE_PROJECT_DOCUMENT");
const createProjectReport = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_PROJECT_REPORT"
);
const getProjectReportCalculation = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_PROJECT_REPORT_CALCULATION"
);
const patchSitePayee = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_SITE_PAYEE");
const addPayeeInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])("ADD_PAYEE_INVITE");

export {
  getProject,
  getProjectSites,
  getPayeeInvites,
  getProjectReports,
  getProjectCredits,
  getProjectStats,
  getProjectChartByMonth,
  getProjectChartByYear,
  createProjectReport,
  patchProject,
  uploadReportingSheetTemplate,
  getProjectReportCalculation,
  uploadProjectFiles,
  addProjectDocuments,
  setProjectStatus,
  deleteProjectDocument,
  patchSitePayee,
  addPayeeInvite,
  removeSiteFromProject,
};
