import React, { useEffect } from "react";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { findIndex } from "lodash";
import { useSearchParams } from "react-router-dom";
import ProjectMembership from "./ProjectMembership";
import { TabPanel } from "@components";

/**
 * We may have multiple project memberships for an orgasset. Each has an activity start date.
 *
 * @param orgAsset
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectMembershipPanel = ({ orgAsset }) => {
  const [searchParams] = useSearchParams();
  const selectedProjectId = searchParams.get("projectId") * 1;

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    const idx = findIndex(orgAsset?.projects, { id: selectedProjectId });
    if (idx >= 0) {
      setTab(idx);
    }
  }, [orgAsset, selectedProjectId]);

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Project Membership(s)
          </Typography>
        </Box>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="Project Memberships"
        >
          {orgAsset?.projects?.map((projectMembership) => (
            <Tab label={projectMembership.title} key={projectMembership.id}/>
          ))}
        </Tabs>
        {orgAsset?.projects?.map((projectMembership, idx) => (
          <TabPanel value={tab} index={idx} key={idx}>
            <ProjectMembership
              projectMembership={projectMembership}
              orgAsset={orgAsset}
            />
          </TabPanel>
        ))}
        {orgAsset && orgAsset?.projects?.length === 0 && (
          <Typography>This Org Asset is not in any projects.</Typography>
        )}
      </Paper>
    </>
  );
};

export default ProjectMembershipPanel;
