import { getUserProfile } from "../actions";

export const initialState = {
  status: "init",
  userProfile: {}, // familyName, givenName, phone
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getUserProfile.RESET: {
      return initialState;
    }

    case getUserProfile.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getUserProfile.SUCCESS: {
      return {
        ...state,
        status: "success",
        userProfile: action.payload.userProfile,
      };
    }

    case getUserProfile.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    // special case after calling an update
    case getUserProfile.UPDATE: {
      return {
        ...state,
        status: "updated",
        userProfile: {
          ...state.userProfile,
          ...action.payload.userProfile,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
