import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  CardHeader,
} from "@mui/material";

const bgcolors = {
  blue: "info.light",
  green: "success.light",
  orange: "warning.light",
  red: "error.main",
};

const ScoreCard = ({
  title,
  primary,
  secondary,
  icon,
  color,
  loading,
  disabled,
  sx,
}) => (
  <Card
    sx={{
      height: "100%",
      opacity: disabled ? 0.4 : 1,
      ...sx,
    }}
  >
    <CardHeader
      sx={{ bgcolor: bgcolors[color], color: "common.white" }}
      avatar={icon}
      title={
        <Typography color="common.white" variant="h5" fontWeight="bold">
          {title}
        </Typography>
      }
    />
    <CardContent
      sx={{
        minHeight: 88,
        "&:last-child": {
          pb: 2,
        },
      }}
    >
      {loading && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <CircularProgress sx={{ color: "grey.500" }} size={24} />
        </Box>
      )}
      {!loading && (
        <>
          <Typography gutterBottom variant="h5" sx={{ pl: 5 }} className="primary">
            {primary}
          </Typography>
          <Typography variant="body2" color="textSecondary" className="secondary" sx={{ pl: 5 }}>
            {secondary}
          </Typography>
        </>
      )}
    </CardContent>
  </Card>
);

ScoreCard.defaultProps = {
  color: "blue",
  loading: false,
  disabled: false,
};

ScoreCard.propTypes = {
  title: PropTypes.string.isRequired,

  // primary stat line
  primary: PropTypes.string.isRequired,

  //secondary stat line
  secondary: PropTypes.string.isRequired,

  // material-ui or hero icon; hero needs style={{ height: 18, marginRight: 4 }}
  icon: PropTypes.element.isRequired,

  color: PropTypes.oneOf(["red", "green", "orange", "blue"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,

  // applied to the root (Card) element, used to target primary and secondary className
  sx: PropTypes.shape({}),
};

export default ScoreCard;
