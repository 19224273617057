import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationPage } from "./ApplicationLayout";
import { application as appActions } from "../actions";
import { useNext } from "./useNextPrev";
import { SolarSiteDetails, EVSiteDetails } from "../../Site/components/Details";

const SiteDetails = () => {
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  const application = useSelector((state) => state.customerProjectApplication);
  const next = useNext();
  const dispatch = useDispatch();
  const save = (formData) => dispatch(appActions.saveAssetDetails(formData));
  return (
    <ApplicationPage pageName="Site Details">
      {customerProject?.emissionReductionActivity === 'LOW_CARBON_FUEL' ? (
        <EVSiteDetails
          siteDetails={application.asset ?? {}}
          save={save}
          nextAction={() => next("location")}
        />
      ) : (
        <SolarSiteDetails
          siteDetails={application.asset ?? {}}
          save={save}
          nextAction={() => next("fromradicle")}
        />
      )}
    </ApplicationPage>
  );
};

export default SiteDetails;
