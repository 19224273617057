import { call, put, takeLatest, select } from "redux-saga/effects";
import { addSites } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { map, join, values, omit, transform, isArray } from "lodash";
import { labelAddresses } from "@utils/locationHelpers";
import { getCredentials, getSiteOwner } from "@addSites/selectors";
import { getUserProfile } from "@views/common/selectors";

const callApi = async (accessToken, organizationId, sites) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/assets`,
    {
      assets: sites,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const prepareSites = (sites, credentials, siteOwner) => {
  return map(sites, (site) => {
    return {
      ...omit(site, ["location", "details", "tableData"]),
      id: new RegExp(/^[0-9.]+$/).test(site.id) ? site.id : undefined, // don't submit our uuids
      type: "SOLAR",
      timeZone: site.location.timeZone,
      latitude: site.location.latitude,
      longitude: site.location.longitude,
      completionDate: site.completionDate,
      siteOwner,
      addresses: labelAddresses(site.location.addresses),
      capacity: site.capacity,
      documents: map(site.documents, (doc) => ({
        ...doc,
        name: doc.file.path,
      })),
      meters: site.meters.map((meter) => ({
        ...meter,
        dataConnector: {
          ...meter.dataConnector,
          ...credentials,
        },
      })),
      ...transform(
        site.details,
        (result, value, key) => (result[key] = value ? value : undefined),
        {}
      ),
    };
  });
};

export function* addSitesWorker(action) {
  yield put(addSites.request());

  const { accessToken, sites } = action.payload;
  const userProfile = yield select(getUserProfile);
  const credentials = yield select(getCredentials);
  const siteOwner = yield select(getSiteOwner);

  try {
    const response = yield call(
      callApi,
      accessToken,
      userProfile.organizationId,
      prepareSites(sites, credentials, siteOwner)
    );
    const { assets } = response.data;
    yield put(addSites.success({ sites: assets, accessToken }));
    yield put(
      showAppMessage({
        severity: "success",
        message: `Successfuly added ${assets.length} site(s). Please press Continue.`,
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error adding sites: ", err.response);
      if (err.response.data?.validationErrors?.assets) {
        const validationErrors = err.response.data.validationErrors.assets;
        message = isArray(validationErrors)
          ? join(values(err.response.data.validationErrors.assets), "; ")
          : validationErrors;
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error adding sites: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error adding sites: ", err);
      message = err.message;
    }
    message = `Unable to add sites: ${message}`;
    yield put(addSites.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* addSitesWatcher() {
  yield takeLatest(addSites.TRIGGER, addSitesWorker);
}

export default [addSitesWatcher];
