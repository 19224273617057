import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Skeleton,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { getFullName } from "@utils/stringHelpers";
import { find, includes } from "lodash";
import { PhoneLink, EmailLink } from "@components";

const InstallerPanel = ({ site, isLoading }) => {
  // put installer in component state
  const [installer, setInstaller] = useState(null);
  useEffect(() => {
    if (site?.id) {
      const siteInstaller = find(site?.contacts, (contact) => includes(contact.roles, "INSTALLER"));
      setInstaller(siteInstaller);
    }
  }, [site?.contacts, site?.id]);

  const tableRows = [
    {
      label: "Name",
      value: getFullName(installer),
    },
    {
      label: "Email",
      value: <EmailLink email={installer?.email} />
    },
    {
      label: "Phone",
      value: <PhoneLink phone={installer?.phone} />
    },
  ];

  return (
    <TableContainer>
      <Table aria-label="contractor info" size="small">
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.label}>
              <TableCell component="th" width="50%">
                {row.label}
              </TableCell>
              <TableCell>
                {isLoading ? (<Skeleton variant="text" />) : row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstallerPanel;
