import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import configureStore from "./store";
import reducer from "./reducers";
import mainSaga from "./sagas";
import App from "./App";
import { LicenseInfo } from "@mui/x-license-pro";
import axios from "axios";

LicenseInfo.setLicenseKey(
  "929e41f9516d4deb009929cc57f9fc84Tz01MDI0MyxFPTE2OTM4NTAzMTkxMTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// enable console debugging
if (process.env.NODE_ENV !== "production") {
  // NB. chrome console has another setting to enable verbose logging so you can see this
  localStorage.setItem("debug", "weedle:*");
}

// redux and persistance
const { store, persistor } = configureStore({ reducer, mainSaga });

// store PHP Session id cookie
axios.defaults.withCredentials = true;

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
