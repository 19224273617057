import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import TraceError from "trace-error";
import { useDispatch } from "react-redux";

/**
 * Note that for Safari/ios/firefox, you will need to enable cross-origin authentication. That means unchecking
 * Settings -> Privacy ->  Prevent Cross Site Tracking.
 * Otherwise, the site will log you out with insufficient scope privileges. ie We would need to log you back in with
 * all the required scopes from the outset, or purchase the custom domain option at auth0.com.
 * See https://auth0.com/docs/authenticate/login/cross-origin-authentication
 *
 * @param children
 * @param action
 * @param scope
 * @returns {JSX.Element}
 * @constructor
 */
const Consent = ({ children, action, scope }) => {
  const { getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();
  const [consentRequired, setConsentRequired] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const dispatch = useDispatch();

  // if we need to, provide consent
  const provideConsent = async () => {
    try {
      await getAccessTokenWithPopup({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope,
      });
      setConsentRequired(false);
      setRefetch(true);
    } catch (error) {
      throw new TraceError("Auth0 consent error", error);
    }
  };

  useEffect(() => {
    async function init() {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope,
        });
        dispatch(action({ accessToken }));
      } catch (err) {
        // other known errors: Timeout, login_required
        if (err.error === "consent_required") {
          setConsentRequired(true);
        } else {
          throw new TraceError("Unknown auth0 error.", err);
        }
      }
    }
    init();
  }, [getAccessTokenSilently, refetch, dispatch, action, scope]);

  return (
    <>
      {children}
      {consentRequired && (
        <Box alignItems="center" display="flex" flexDirection="column" mt={3}>
          <Typography gutterBottom>
            Please click here to provide consent for the Rewatt portal to access admin information.
          </Typography>
          <Button variant="contained" color="primary" onClick={provideConsent}>
            Consent
          </Button>
        </Box>
      )}
    </>
  );
};

export default Consent;
