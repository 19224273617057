import React from 'react';
import logo from "@assets/rewatt3_pb_whitebg.svg";

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={logo}
      width={150}
      height={37}
      {...props}
    />
  );
};

export default Logo;