import { call, put, takeLatest } from "redux-saga/effects";
import { getIncompleteSitesCount } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, customerProjectId) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites/incompleteCount`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getIncompleteSitesCountWorker(action) {
    yield put(getIncompleteSitesCount.request());

    const { accessToken, customerProjectId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, customerProjectId);
    const { count } = response.data;
    yield put(getIncompleteSitesCount.success({ count }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get incomplete sites count: ${message}`;
    yield put(getIncompleteSitesCount.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getIncompleteSitesCountWatcher() {
  yield takeLatest(getIncompleteSitesCount.TRIGGER, getIncompleteSitesCountWorker);
}

export default [getIncompleteSitesCountWatcher];
