import addInvite from "./addInvite";
import getOrgInvites from "./getOrgInvites";
import deleteInvite from "./deleteInvite";
import resendInvite from "./resendInvite";
import updatePrimaryContact from "./updatePrimaryContact";

export default [
  ...addInvite,
  ...getOrgInvites,
  ...deleteInvite,
  ...resendInvite,
  ...updatePrimaryContact,
];
