import { call, put, takeLatest } from "redux-saga/effects";
import {
  getSite,
  patchSiteDetails,
  patchSiteLocation,
  patchSiteDocuments,
  publishLiveSite,
  updateSite,
} from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { pick } from "lodash";

const callApi = async (accessToken, siteId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/assets/${siteId}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getSiteWorker(action) {
  yield put(getSite.request());

  const { accessToken, siteId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, siteId);
    const site = response.data;
    yield put(getSite.success({ site }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting site details: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting site details: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting site details: ", err);
      message = err.message;
    }
    message = `Unable to get site details: ${message}`;
    yield put(getSite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchSiteDetailsWorker(action) {
  const { site } = action.payload;
  yield put(
    updateSite({
      site: pick(site, [
        "name",
        "capacity",
        "completionDate",
        "mountingType",
        "utility",
        "customerId",
        "equipmentOwnership",
        "fundingSource",
        "cost",
      ]),
    })
  );
}

function* patchSiteLocationWorker(action) {
  const { site } = action.payload;
  yield put(
    updateSite({
      site: pick(site, ["addresses", "timeZone", "latitude", "longitude"]),
    })
  );
}

function* patchSiteDocumentsWorker(action) {
  const { site } = action.payload;
  yield put(
    updateSite({
      site: pick(site, ["documents"]),
    })
  );
}

function* publishLiveSiteWorker(action) {
  const { site } = action.payload;
  yield put(
    updateSite({
      site: pick(site, ["publicEnabled"]),
    })
  );
}

function* getSiteWatcher() {
  yield takeLatest(getSite.TRIGGER, getSiteWorker);
}

// if we patch the site, we update getSite reducer, rather than refetch site

function* patchSiteDetailsWatcher() {
  yield takeLatest(patchSiteDetails.SUCCESS, patchSiteDetailsWorker);
}

function* patchSiteLocationWatcher() {
  yield takeLatest(patchSiteLocation.SUCCESS, patchSiteLocationWorker);
}

function* patchSiteDocumentsWatcher() {
  yield takeLatest(patchSiteDocuments.SUCCESS, patchSiteDocumentsWorker);
}

function* publishLiveSiteWatcher() {
  yield takeLatest(publishLiveSite.SUCCESS, publishLiveSiteWorker);
}

export default [
  getSiteWatcher,
  patchSiteDetailsWatcher,
  patchSiteLocationWatcher,
  patchSiteDocumentsWatcher,
  publishLiveSiteWatcher,
];
