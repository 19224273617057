import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  colors,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import DateRangePresetMenu, { DATE_RANGE_PRESETS } from "./DateRangePresetMenu";
import { DateTime } from "luxon";
import { useAuth0 } from "@auth0/auth0-react";
import { reduce, values, every, isNil, isFunction, filter, find } from "lodash";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { round } from "@utils/numberHelpers";
import CustomDateRangePicker from "@components/CustomDateRangePicker";

const baseChartOptions = (dateRangePreset, granularity, theme) => ({
  chart: {
    type: "line",
    fontFamily: theme.typography.fontFamily,
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [2, 2, 2, 2, 2, 2],
    curve: "straight",
    dashArray: [0, 0, 0, 5, 5, 5],
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        if (value) {
          return dateRangePreset.formatter(value, granularity);
        }
        return value;
      },
    },
  },
  yaxis: [
    {
      seriesName: "Generation", // Generation
      labels: {
        formatter: (val) => round(val, 1),
      },
      title: {
        text: "Energy (kWh)",
      },
    },
    {
      seriesName: "Generation", // Grid Import
      show: false,
    },
    {
      seriesName: "Generation", // Grid Export
      show: false,
    },
    {
      seriesName: "Temperature", // Temperature
      opposite: true,
      labels: {
        formatter: (val) => round(val, 1),
      },
      title: {
        text: "Temperature (°C) / Snow (cm) / Daylight (h)",
      },
    },
    {
      seriesName: "Temperature", // Snow
      opposite: true,
      show: false,
    },
    {
      seriesName: "Temperature", // Daylight
      opposite: true,
      show: false,
    },
  ],
  tooltip: {
    style: {
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
    },
    y: [
      {
        formatter: (val) => (val ? `${val.toFixed(2)} kWh` : "N/A"),
      },
      {
        formatter: (val) => (val ? `${val.toFixed(2)} kWh` : "N/A"),
      },
      {
        formatter: (val) => (val ? `${val.toFixed(2)} kWh` : "N/A"),
      },
      {
        // temp
        formatter: (val) => (val ? `${val} °C` : "N/A"),
      },
      {
        // snow
        formatter: (val) => (val ? `${val.toFixed(1)} cm` : "N/A"),
      },
      {
        // daylight
        formatter: (val) => (val ? `${val.toFixed(3)} h` : "N/A"),
      },
    ],
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  noData: {
    text: "Loading...",
  },
  colors: values({
    generation: "#003f5c", // blue
    consumption: "#bc5090", // purple
    export: "#ffa600", // orange
    temperature: colors.red[600],
    snow: colors.green[600],
    daylight: colors.yellow[600],
  }),
});

const ChartPanel = ({ getSiteChartResult, getSiteChart }) => {
  const { siteId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const chartRef = useRef();

  // our chart data
  const { data, granularity } = getSiteChartResult;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(filter(data, (series) => series.name !== "Emission Reductions"));
    // slight delay until chart has redrawn with these series, so we can hide them by default
    // nb. when we change the chart via the date controls, we go back to our default state (very buggy otherwise)
    setTimeout(() => {
      try {
        chartRef.current.chart.hideSeries("Grid Export");
        chartRef.current.chart.hideSeries("Grid Import");
      } catch (e) {}
    }, 250);
  }, [data]);

  // date range
  const today = DateTime.now();
  const sevenDaysAgo = DATE_RANGE_PRESETS.find((drp) => drp.key === "LAST_7_DAYS");
  const [dateRange, setDateRange] = React.useState({
    start: sevenDaysAgo.start,
    end: today,
  });
  const handleDateRangeChange = (dateRange) => {
    const valid = every(dateRange, (datetime) => !isNil(datetime) && datetime.isValid);
    if (valid) {
      // fix an issue where if a series is "off", and we change the date, the x-axis get borked
      chartData.map((series) => chartRef.current.chart.showSeries(series.name));

      // we want all of the day, ie with fixed we get the time included in the end date
      const hours = today.get("hour");
      const minutes = today.get("minute");
      const seconds = today.get("second");
      setDateRange({ start: dateRange.start, end: dateRange.end.plus({ hours, minutes, seconds }) });
      setDateRangePreset(DATE_RANGE_PRESETS.find((drp) => drp.key === "FIXED"));
    }
  };

  // get default chart data
  useEffect(() => {
    async function initChart() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getSiteChart({
        accessToken,
        startDate: dateRange.start,
        endDate: dateRange.end,
        siteId,
      });
    }
    initChart();
  }, [getAccessTokenSilently, getSiteChart, dateRange, siteId]);

  // date range preset menu
  const [dateRangePreset, setDateRangePreset] = React.useState(
    DATE_RANGE_PRESETS.find((drp) => drp.key === "LAST_7_DAYS")
  );
  const handleDateRangePresetChange = (dateRangePreset) => {
    // fix an issue where if a series is "off", and we change the date, the x-axis get borked
    chartData.map((series) => chartRef.current.chart.showSeries(series.name));
    setDateRangePreset(dateRangePreset);
    setDateRange({ start: dateRangePreset.start, end: today });
  };

  // loading
  const isLoading =
    getSiteChartResult.status !== "success" && getSiteChartResult.status !== "failure";
  const opts = baseChartOptions(dateRangePreset, granularity, theme);
  const chartOptions = {
    ...opts,
    noData: {
      text: isLoading ? "Loading..." : "No Data",
    },
    xaxis: {
      ...opts.xaxis,
      tickAmount: isFunction(dateRangePreset.ticks)
        ? Math.floor(dateRangePreset.ticks(chartData) * 0.66)
        : Math.floor(dateRangePreset.ticks * 0.66),
    },
  };

  // summary stats
  const sumEmissionReductions = reduce(
    find(data, (series) => series.name === "Emission Reductions")?.data || 0,
    (sum, datum) => sum + datum.y,
    0
  );
  const sumGeneration = reduce(
    find(data, (series) => series.name === "Generation")?.data || 0,
    (sum, datum) => sum + datum.y,
    0
  );

  return (
    <Card>
      {isLoading && <LinearProgress />}
      <CardHeader
        sx={{
          pt: (theme) => theme.spacing(isLoading ? 2 : 2.5), // progress is 0.5 spacing
          pb: (theme) => theme.spacing(2.5),
          "&:last-child": {
            paddingBottom: (theme) => theme.spacing(2.5),
          },
        }}
        action={
          <Box display="flex" alignItems="flex-end">
            <CustomDateRangePicker value={dateRange} onChange={handleDateRangeChange} />
            <DateRangePresetMenu
              dateRangePreset={dateRangePreset}
              onDateRangePresetChange={handleDateRangePresetChange}
            />
          </Box>
        }
      />
      <Divider />
      <CardContent sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Box display="flex" alignItems="flex-start" flexWrap="wrap">
          <Typography>
            {find(DATE_RANGE_PRESETS, { key: dateRangePreset.key })?.label}:{" "}
            <Typography component="span" color="secondary">
              {round(sumGeneration / 1000).toLocaleString()} MWh{" "}
              <Typography component="span" color="primary">
                ({round(sumEmissionReductions).toLocaleString()} tCO2e)
              </Typography>
            </Typography>
          </Typography>
        </Box>
      </CardContent>
      <CardContent>
        <Box height={400} position="relative" px={3}>
          <ReactApexChart
            ref={chartRef}
            options={chartOptions}
            series={chartData}
            type="line"
            height={400}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

ChartPanel.propTypes = {
  getSiteChartResult: PropTypes.shape({
    status: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.string,
            y: PropTypes.number,
          })
        ),
      })
    ),
    granularity: PropTypes.oneOf(["hour", "day", "month", "year"]),
  }).isRequired,
  getSiteChart: PropTypes.func.isRequired,
};

export default ChartPanel;
