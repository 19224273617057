import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const acceptAssetClaimInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ACCEPT_ASSET_CLAIM_INVITE"
);
const verifyAssetClaimInviteToken = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "VERIFY_ASSET_CLAIM_INVITE_TOKEN"
);

export { verifyAssetClaimInviteToken, acceptAssetClaimInvite };
