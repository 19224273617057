import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIncompleteSitesCount } from "../actions";
import { Alert } from "@mui/material";

const IncompleteSitesAlert = ({ customerProject }) => {

  // get incomplete site count
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getIncompleteSitesCount({ accessToken, customerProjectId: customerProject.id }));
    }
    if (customerProject?.id) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, customerProject?.id]);

  const { count } = useSelector((state) => state.getIncompleteSitesCount);

  return count
    ? (
      <Alert
        severity="warning"
        variant="filled"
      >
        {`You have ${count} sites that are missing information required to generate credits.`}
      </Alert>
    ) : null;
};

export default IncompleteSitesAlert;
