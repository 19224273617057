import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent, Divider, Grid, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { find, isNil } from "lodash";
import { useForm, Controller } from "react-hook-form";
import LogoUploader from "@views/common/components/LogoUploader";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { patchProjectReportWithStatus } from "@admin/projectReport/actions";
import TextArea from "@components/TextArea";
import { uploadVerifierLogo } from "../actions";

const schema = Yup.object().shape({
  statement: Yup.string().max(4096, "Too long.").required("Statement is required."),
  logo: Yup.object().required("A logo is required"),
});

const VerifierPanel = ({ projectReport }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [verified, setVerified] = useState(
    find(projectReport.statusHistory, { status: "VERIFIED" })
  );
  const [verificationSubmitted, setVerificationSubmitted] = useState(
    find(projectReport.statusHistory, { status: "VERIFICATION_SUBMITTED" })
  );

  const { control, errors, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      statement: "",
      logo: null, // filespec
    },
  });

  // after loading, but also after patching and a status change (see saga)
  useEffect(() => {
    reset({
      statement: projectReport.verifierStatement || "",
      logo: projectReport.verifierLogo || null,
    });
  }, [projectReport, reset]);

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_project_reports",
    });

    const statuses = [];
    if (!verificationSubmitted) {
      setVerificationSubmitted(true);
      statuses.push("VERIFICATION_SUBMITTED");
    }
    if (!verified) {
      setVerified(true);
      statuses.push("VERIFIED");
    }

    dispatch(
      patchProjectReportWithStatus({
        projectReport: {
          id: projectReport.id,
          verifierStatement: data.statement,
          verifierLogo: {
            name: data.logo.file?.name || data.logo.name,
            tags: ["Logo", "Verifier"],
            uri: data.logo.uri,
          },
        },
        statuses,
        key: "VERIFY",
        accessToken,
      })
    );
  };

  const { status, key } = useSelector((state) => state.patchProjectReport);
  const isSubmitting = status === "request" && key === "VERIFY";

  return (
    <>
      <Typography variant="h3" gutterBottom ml={2}>
        Verification
      </Typography>

      <Card>
        <CardHeader
          title="Verify Credits"
          subheader={`Mark the credits in this project report as verified by an external institution.
            Provide a logo and a statement to represent verification.`}
          action={
            verified ? (
              <Typography color="success.main">VERIFIED</Typography>
            ) : (
              <Typography color="warning.main">UNVERIFIED</Typography>
            )
          }
          sx={{
            "& .MuiCardHeader-action": {
              marginTop: 0,
              marginRight: 0,
              marginLeft: 2,
            },
          }}
        />
        <Divider />
        <CardContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Controller
                  render={({ onChange, value }) => (
                    <LogoUploader
                      title="Verifier logo*"
                      onChange={onChange}
                      value={value}
                      error={errors.logo?.message}
                      discriminator="VERIFIER_LOGO"
                      uploadLogo={uploadVerifierLogo}
                      selector={(state) => state.uploadVerifierLogo}
                    />
                  )}
                  control={control}
                  name="logo"
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextArea
                      label="Verifier Statement"
                      rows={7}
                      error={!isNil(errors.statement?.message)}
                      helperText={errors.statement?.message}
                      required
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 4096,
                      }}
                      placeholder={`Add your verifier statement here. \
This will be seen on the certificate of authenticity.`}
                    />
                  )}
                  control={control}
                  name="statement"
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <LoadingButton
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

VerifierPanel.propTypes = {
  projectReport: PropTypes.shape({
    id: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

export default VerifierPanel;
