import { put, takeLatest } from "redux-saga/effects";
import { getOrganizationStats, dataUpdated } from "../actions";
import { DateTime } from "luxon";

export function* dataUpdatedWorker(action) {
  const timestamp = DateTime.now().toSeconds();
  yield put(dataUpdated({ timestamp }));
}

export function* getOrganizationStatsWatcher() {
  yield takeLatest(getOrganizationStats.SUCCESS, dataUpdatedWorker);
}

export default [getOrganizationStatsWatcher];
