import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import OrganizationInfo from "@views/apply/components/OrganizationInfo";
import { useAuth0 } from "@auth0/auth0-react";

const OrgInfo = ({
  organization,
  saveOrganization,
  saveOrganizationInfo,
  saveOrganizationResult,
  resetOrganization,
  resetSaveOrganization,
}) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();

  const submit = async (organization) => {
    saveOrganizationInfo(organization);

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });    
    saveOrganization({ accessToken, userId: user.sub });
  };

  const goDashboard = () => {
    resetOrganization();
    resetSaveOrganization();
    navigate("/dashboard");
  }

  const isSuccess = saveOrganizationResult.status === "success";
  const isSubmitting = saveOrganizationResult.status === "request";
  return (
    <OrganizationInfo
      processTitle="Sign Up"
      organization={organization}
      backAction={() => navigate("/onboarding/contact")}
      onSubmit={isSuccess ? goDashboard : submit}
      submitText={isSuccess ? "Continue" : "Submit"}
      isSubmitting={isSubmitting}
    />
  );
};

OrgInfo.propTypes = {
  organization: PropTypes.shape({}).isRequired,
  saveOrganization: PropTypes.func.isRequired,
  saveOrganizationInfo: PropTypes.func.isRequired,
  saveOrganizationResult: PropTypes.shape({}).isRequired,

  resetOrganization: PropTypes.func.isRequired,
  resetSaveOrganization: PropTypes.func.isRequired,
};

export default OrgInfo;
