import { call, put, takeLatest } from "redux-saga/effects";
import { createProject, setProjectTableState } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";
import { DateTime } from "luxon";

const determineProtocol = (environmentalCredit) => {
  switch (environmentalCredit) {
    case 'ALBERTA_EMISSION_OFFSET':
    case 'REWATT_VOLUNTARY_CREDIT':
    case 'CSA_CLEAN_PROJECTS_OFFSET':
      return 'DISTRIBUTED_RENEWABLE_ENERGY_GENERATION';
    case 'CANADA_FUEL_REGULATION':
      return 'CFR_EVS';
    case 'BC_LOW_CARBON_FUEL':
      return 'BC_LCFS_EVS';
    default:
      return 'DISTRIBUTED_RENEWABLE_ENERGY_GENERATION';
  }
}

const callApi = async (accessToken, project) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects`,
    project,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const transform = (project) => {
  const isVoluntary = project.environmentalCredit === "REWATT_VOLUNTARY_CREDIT";
  return {
    ...project,
    protocol: determineProtocol(project.environmentalCredit),
    startDate: DateTime.fromJSDate(project.startDate).toISODate(),
    endDate: isVoluntary ? undefined : DateTime.fromJSDate(project.endDate).toISODate(),
  };
};

function* createProjectWorker(action) {
  yield put(createProject.request());

  const { accessToken, project } = action.payload;

  try {
    const response = yield call(callApi, accessToken, transform(project));
    yield put(createProject.success({ project: response.data, accessToken }));
    yield put(
      setProjectTableState({
        sortModel: [
          {
            field: "created",
            sort: "desc",
          },
        ],
      })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error creating project: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error creating project: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error creating project: ", err);
      message = err.message;
    }
    message = `Unable to create project: ${message}`;
    yield put(createProject.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* createProjectWatcher() {
  yield takeLatest(createProject.TRIGGER, createProjectWorker);
}

export default [createProjectWatcher];
