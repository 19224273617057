import React, { useEffect } from "react";
import { Grid, Typography, Link, Box, Container, GlobalStyles } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectReportCertificate } from "../actions";
import PageLoader from "@components/PageLoader";
import { includes } from "lodash";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "typeface-cormorant";
import Logo from "@components/Logo";
import { getDefaultLocale } from "@utils/dateHelpers";
import { Navigate } from "react-router-dom";
import FittedImage from "components/FittedImage";

const theme = createTheme({
  typography: {
    htmlFontSize: 16, // for rem
    fontSize: 14,
    fontFamily: ["Cormorant", "serif"].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "2rem",
      letterSpacing: "normal",
      textTransform: "uppercase",
    },
    body1: {
      fontWeight: 500,
      fontSize: "1.2rem",
      letterSpacing: "normal",
    },
    body2: {
      fontWeight: 500,
      fontSize: "1rem",
      letterSpacing: "normal",
    },
  },
});

const ProjectReportCertificate = () => {
  const dispatch = useDispatch();
  const { projectReportId } = useParams();

  // get cert on load
  useEffect(() => {
    dispatch(getProjectReportCertificate({ projectReportId }));
  }, [projectReportId, dispatch]);

  const { status, certificate, error } = useSelector((state) => state.getProjectReportCertificate);
  const isLoading = includes(["init", "request"], status);
  const is404 = status === "failure" && error?.indexOf("Permission Denied") > 0;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: "#fff" },
        }}
      />
      {isLoading && <PageLoader message="Just a moment..." />}
      {!isLoading && is404 && <Navigate replace to="/404" />}
      {!isLoading && !is404 && (
        <Box p={3}>
          <Helmet>
            <title>{`Certificate of Authenticity: ${certificate.title}`}</title>
            <meta property="og:title" content={`${certificate.title}`} />
            <meta
              property="og:description"
              content="Certificate of Authenticity. This certificate is a testament to the validity of the credits described herewithin."
            />
            <meta property="og:image" content={certificate.logo?.uri} />
            <meta property="og:publisher" content="Rewatt Power" />
            <meta
              property="article:published_time"
              content={DateTime.fromSeconds(certificate.created).toISO()}
            />
          </Helmet>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  height="100%"
                  flexDirection="column"
                >
                  <Typography variant="h1">{certificate.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Certified{" "}
                    {DateTime.fromSeconds(certificate.created)
                      .setLocale(getDefaultLocale())
                      .toLocaleString(DateTime.DATE_FULL)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box display="flex" justifyContent="flex-end">
                  <FittedImage uri={certificate.logo?.uri} height={65} fit="contain" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography align="justify" mt={3}>
                  {certificate.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {certificate.verifierStatement && (
                  <Typography align="justify" mt={3}>
                    {certificate.verifierStatement}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box height="100%" display="flex" alignItems="center">
                  <Logo />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-end">
                  <FittedImage uri={certificate.verifierLogo?.uri} height={65} fit="contain" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign="center" color="textSecondary">
                  © {DateTime.now().get("year")}{" "}
                  <Link href="https://rewattpower.com" target="_blank">
                    Rewatt Power
                  </Link>{" "}
                  | All Rights Reserved.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default ProjectReportCertificate;
