import { verifyToken } from "../actions";

export const initialState = {
  status: "init",
  program: {
    name: null,
    description: null,
    organization: null,
    logo: null,
  },
  application: {
    id: null,
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case verifyToken.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case verifyToken.SUCCESS: {
      const { program, application } = action.payload;
      return {
        ...state,
        status: "success",
        program,
        application,
      };
    }

    case verifyToken.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
