import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const acceptBuyerInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ACCEPT_BUYER_INVITE"
);
const addBuyerOrganization = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_BUYER_ORGANIZATION"
);
const verifyBuyerInviteToken = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "VERIFY_BUYER_INVITE_TOKEN"
);

export { verifyBuyerInviteToken, acceptBuyerInvite, addBuyerOrganization };
