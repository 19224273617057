import React, { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Breadcrumb from 'components/Breadcrumb';
import {
  AppBar,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { isNil, includes, get } from "lodash";
import PageLoader from "components/PageLoader";
import { ViewDetails, EditDetails } from "../../Site/components/Details";
import DialogTransition from "components/DialogTransition";
import { startCase } from "lodash";
import { EditLocation, ViewLocation } from "../../Site/components/Location";
import { EditSiteAddDevices, ViewDevices } from "../../Devices/components/Devices";
import { EditSiteAddDocuments, ViewDocuments } from "../../Site/components/Documents";
import DisplayStatus from "views/sites/components/DisplayStatus";
import { isEmpty } from "lodash";
import ApplicationNotes from "views/reviewApplication/containers/ApplicationNotes";
import { getApplicationNotes } from "views/reviewApplication/actions";
import ApprovalPanel from "./ApprovalPanel";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import { getFullName } from "utils/stringHelpers";
import { patchCustomerProjectSite } from "../../Site/actions";
import { getCustomerProject, getProjectApplication } from "../../CustomerProjects/actions";
import { APPLICATION_STATUSES } from "views/programs";

const ReviewApplication = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { customerProjectId, applicationId } = useParams();

  // get the customer project, if we don't have it
  const getCustomerProjectState = useSelector((state) => state.getCustomerProject);
  useEffect(() => {
    async function fetchProject() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getCustomerProject({ accessToken, customerProjectId }));
    };
    if (isNil(getCustomerProjectState.customerProject)) {
      fetchProject();
    }
  }, [dispatch, getAccessTokenSilently, customerProjectId, getCustomerProjectState.customerProject]);
  const { customerProject } = getCustomerProjectState;
  const isProjectLoading = includes(['request', 'init'], getCustomerProjectState.status);

  // get the application
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getProjectApplication({ accessToken, customerProjectId, applicationId }));
      dispatch(getApplicationNotes({ accessToken, applicationId }));
    }
    init();
  }, [getAccessTokenSilently, customerProjectId, applicationId, dispatch]);
  const getProjectApplicationState = useSelector((state) => state.getProjectApplication);
  const { application } = getProjectApplicationState;
  const isAppLoading = includes(['request', 'init'], getProjectApplicationState.status);
  const isApplicationApproved = application?.status === "APPROVED";

  const isLoading = isProjectLoading || isAppLoading;

  const { status: deleteDocumentStatus } = useSelector((state) => state.deleteDocument)
  // refetch the app after successfully deleting a document
  useEffect(() => {
    async function refresh() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getProjectApplication({ accessToken, customerProjectId, applicationId }));
      dispatch(getApplicationNotes({ accessToken, applicationId }));
    }
    if (deleteDocumentStatus === 'success') {
      refresh();
    }
  }, [getAccessTokenSilently, customerProjectId, applicationId, dispatch, deleteDocumentStatus]);

  // edit
  const [editing, setEditing] = useState(null);
  const closeEditor = async () => {
    setEditing(null);
    dispatch(patchCustomerProjectSite.reset());
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(getProjectApplication({ accessToken, customerProjectId, applicationId }));
    dispatch(getApplicationNotes({ accessToken, applicationId }));
  };

  // clear application when we're done reviewing
  useEffect(() => () => {
    dispatch(getProjectApplication.reset());
  }, [dispatch]);

  const applicationDetails = useMemo(() => application ? [
    {
      label: "Date Received",
      value: DateTime.fromSeconds(application?.created)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_MED),
    },
    {
      label: "Applicant",
      value: getFullName(application?.applicant.familyName, application?.applicant.givenName, "")
    },
    {
      label: "Applicant Email",
      value: application?.applicant.email
    },
    {
      label: "Applicant Phone",
      value: application?.applicant.phone
    },
    {
      label: "Transferred from Radicle",
      value: application?.transferredFromRadicle ? 'Yes' : 'No',
    }
  ] : [], [application]);

  const [showFromRadicleWarning, setShowRadicleWarning] = useState(false);
  useEffect(() => {
    if (application?.transferredFromRadicle) {
      setShowRadicleWarning(true);
    }
  }, [application]);

  return (
    <Page title={`Review Application: ${applicationId}`} py={3}>
      <Container maxWidth="lg">
        <PageHeader title={`Review Application ${applicationId}`}>
          <Breadcrumb title="Projects List" destination="/projects" />
          <Breadcrumb title={`Project ${customerProjectId}`} destination={`/projects/${customerProjectId}`} />
        </PageHeader>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={!!editing}
          onClose={closeEditor}
          TransitionComponent={DialogTransition}
          sx={{
            "& .MuiPaper-root.MuiDialog-paper": {
              bgcolor: "background.dark",
            },
          }}
        >
          <AppBar position="sticky">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeEditor}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" color="common.white" ml={2}>
                {`${startCase(editing)}`}
              </Typography>
            </Toolbar>
          </AppBar>
          {editing === 'details' && (
            <EditDetails
              site={application.asset}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
          {editing === 'location' && (
            <EditLocation
              site={application.asset}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
          {editing === 'documents' && (
            <EditSiteAddDocuments
              site={application.asset}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
          {editing === 'addDevice' && (
            <EditSiteAddDevices
              site={application.asset}
              customerProject={customerProject}
              close={closeEditor}
            />
          )}
        </Dialog>
        <Dialog
          open={showFromRadicleWarning}
          onClose={() => { setShowRadicleWarning(false); }}
        >
          <DialogTitle>
            Warning!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This application is from Radicle!
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {isLoading ? <PageLoader message="Loading..." /> : (
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} lg={6}>
              <Paper sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                  Application Details
                </Typography>
                <TableContainer>
                  <Table aria-label="applicant profile" size="small">
                    <TableBody>
                      {applicationDetails.map((row) => (
                        <TableRow key={row.label}>
                          <TableCell component="th" width="50%">
                            {row.label}
                          </TableCell>
                          <TableCell>{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Paper sx={{ p: 4 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" gutterBottom>
                    Site Profile
                  </Typography>
                  <Tooltip
                    title={isApplicationApproved ? "Application already approved" : "Edit Site Profile"}
                  >
                    <span>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{ mt: -1.5 }}
                        onClick={() => {
                          setEditing('details');
                        }}
                        disabled={isApplicationApproved}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <ViewDetails
                  site={application?.asset}
                  customerProject={customerProject}
                  reviewOnly
                  isLoading={isAppLoading}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 4 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" gutterBottom>
                    Location
                  </Typography>
                  <Tooltip
                    title={isApplicationApproved ? "Application already approved" : "Edit Location"}
                  >
                    <span>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{ mt: -1.5 }}
                        onClick={() => {
                          setEditing('location');
                        }}
                        disabled={isApplicationApproved}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <ViewLocation
                  site={application?.asset}
                  reviewOnly
                  isLoading={isAppLoading}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 4 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" gutterBottom>
                    Documents
                  </Typography>
                  <Tooltip
                    title={isApplicationApproved ? "Application already approved" : "Edit Documents"}
                  >
                    <span>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{ mt: -1.5 }}
                        onClick={() => {
                          setEditing('documents');
                        }}
                        disabled={isApplicationApproved}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <ViewDocuments
                  site={application?.asset}
                  customerProjectId={customerProject.id}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 4 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" gutterBottom>
                    Site Connection and Devices
                  </Typography>
                  <Tooltip
                    title={isApplicationApproved ? "Application already approved" : "Edit Site Connection and Devices"}
                  >
                    <span>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        sx={{ mt: -1.5 }}
                        onClick={() => {
                          setEditing('addDevice');
                        }}
                        disabled={application?.asset.status !== "NOT_INSTALLED"}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography component="span" sx={{ pr: 2 }}>
                    Connection Status:
                  </Typography>
                  <DisplayStatus status={application?.asset} />
                </Box>
                {isEmpty(application?.asset.meters) ? (
                  <Typography>
                    There are no devices or systems associated with this application
                  </Typography>
                ) : (
                  <ViewDevices
                    devices={application?.asset.meters}
                    siteId={application?.asset.id}
                    customerProject={customerProject}
                    reviewOnly
                    editable={!isApplicationApproved}
                    isLoading={isAppLoading}
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper sx={{ p: 4 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" gutterBottom>
                    Site Contractor
                  </Typography>
                </Box>
                <TableContainer>
                  <Table size="small" aria-label="site installer">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" width="50%">
                          Name
                        </TableCell>
                        <TableCell>
                          {getFullName(application?.asset?.installer?.familyName, application?.asset?.installer?.givenName) ?? "N/A" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" width="50%">
                          Email
                        </TableCell>
                        <TableCell>
                          {application?.asset?.installer?.email ?? "N/A" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" width="50%">
                          Phone
                        </TableCell>
                        <TableCell>
                          {application?.asset?.installer?.phone ?? "N/A" }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <ApplicationNotes applicationId={applicationId} dense/>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 4 }}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h4" gutterBottom>
                    Status History
                  </Typography>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {application?.statusHistory.map(({ status, date, notes }) => (
                        <TableRow>
                          <TableCell>{get(APPLICATION_STATUSES, status)}</TableCell>
                          <TableCell>{DateTime.fromISO(date).toLocaleString()}</TableCell>
                          <TableCell>{notes}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <ApprovalPanel application={application} dense/>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default ReviewApplication;
