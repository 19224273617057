import React, { useEffect } from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { isNil, omit } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addPayeeInvite } from "@admin/project/actions";
import { getOrganizations } from "@admin/marketplace/actions";
import Notification, { notificationReducer } from "@components/Notification";
import { getPayeeInvites } from "../../actions";

const schema = yup.object().shape({
  givenName: yup.string().max(60, "Too long.").required("First name is required."),
  familyName: yup.string().max(60, "Too long.").required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  notes: yup.string().max(255, "Too long."),
});

/**
 * Invite a new user on the platform to be payee.
 *
 * @param  {[type]} options.site    This is the org asset
 * @param  {[type]} options.project The project in which this site resides.
 * @param  {[type]} options.onClose Close the dialog
 */
const InvitePayeeEditor = ({ site, onClose, project }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [notification, setNotification] = React.useReducer(notificationReducer, {
    open: false,
  });

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      givenName: "",
      familyName: "",
      email: "",
      notes: "",
      organization: "",
    },
  });

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace admin_sites",
    });

    const invite = {
      ...omit(data, "organization"),
      organizationId: data.organization?.id,
      assetId: site.id,
    };

    const onError = (message) =>
      setNotification({
        type: "show",
        payload: {
          severity: "error",
          message,
        },
      });

    const onSuccess = () =>
      setNotification({
        type: "show",
        payload: {
          severity: "success",
          message: "Successfully invited payee. Please press done.",
        },
      });

    dispatch(addPayeeInvite({ accessToken, invite, onError, onSuccess }));
  };

  const { status } = useSelector((state) => state.addPayeeInvite);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleDone = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace admin_sites",
    });

    dispatch(getPayeeInvites({ accessToken, projectId: project.id }));
    dispatch(addPayeeInvite.reset());
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  // get orgs for the dropdown
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_marketplace",
      });
      dispatch(getOrganizations({ accessToken }));
    }
    init();
  }, [dispatch, getAccessTokenSilently]);

  const { organizations } = useSelector((state) => state.getOrganizations);

  return (
    <>
      <Notification
        open={notification.open}
        hide={() => setNotification({ type: "hide" })}
        message={notification.message}
        severity={notification.severity}
      />
      <DialogContent sx={{ m: 0 }}>
        <DialogContentText>
          Invite a payee to join the platform, for this site. They will be paid when credits are
          sold. Optionally, specify an organization for them to join. We'll send an email with
          further instructions, on your behalf.
        </DialogContentText>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              autoFocus
              error={!isNil(errors.givenName?.message)}
              label="First Name"
              placeholder="John"
              helperText={errors.givenName?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="givenName"
        />
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.familyName?.message)}
              label="Last Name"
              placeholder="Doe"
              helperText={errors.familyName?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="familyName"
        />
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.email?.message)}
              label="Email"
              placeholder="john@company.org"
              helperText={errors.email?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="email"
        />
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.notes?.message)}
              label="Notes"
              placeholder="Hi John, please accept my invitation to trade with Rewatt."
              helperText="A short note to the invitee (optional)"
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="notes"
        />
        <Controller
          render={({ onChange, value }) => (
            <Autocomplete
              getOptionLabel={(org) => org.name}
              options={organizations.sort((orgA, orgB) => -orgB.name.localeCompare(orgA.name))}
              autoComplete
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.organization?.message)}
                  helperText={
                    errors.organization?.message || "Invite to an existing organization (optional)"
                  }
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  value={value}
                  color="secondary"
                />
              )}
            />
          )}
          control={control}
          name="organization"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="text"
          disabled={isSubmitting || isSuccess}
          sx={{ marginRight: 1 }}
        >
          Cancel
        </Button>
        {!isSuccess && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(onSubmit)()}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Invite
          </LoadingButton>
        )}
        {isSuccess && (
          <Button variant="contained" color="primary" onClick={handleDone}>
            Done
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default InvitePayeeEditor;
