import { call, put, takeLatest } from "redux-saga/effects";
import { patchCustomerProjectSite } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, payload, customerProjectId, siteId) => await axios.patch(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites/${siteId}`,
  payload,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* patchCustomerProjectSiteWorker(action) {
  yield put(patchCustomerProjectSite.request());

  const { accessToken, payload, customerProjectId, siteId } = action.payload;
  try {
    const response = yield call(callApi, accessToken, payload, customerProjectId, siteId);
    const site = response.data;
    yield put(patchCustomerProjectSite.success({ site, accessToken, customerProjectId, siteId }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Error patching site: ${message}`;
    yield put(patchCustomerProjectSite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchCustomerProjectSiteWatcher() {
  yield takeLatest(patchCustomerProjectSite.TRIGGER, patchCustomerProjectSiteWorker);
}

export default [patchCustomerProjectSiteWatcher];
