import getOrganization from "./getOrganization";
import getUserProfile from "./getUserProfile";
import updateUserProfile from "./updateUserProfile";
import updateOrganization from "./updateOrganization";
import tableState from "./tableState";
import history from "./history";

const reducers = {
  getOrganization,
  getUserProfile,
  updateUserProfile,
  updateOrganization,
  tableState,
  history,
};

export default reducers;
