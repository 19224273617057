import { getProjectReport } from "../actions";

export const initialState = {
  status: "init",
  projectReport: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getProjectReport.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getProjectReport.SUCCESS: {
      const { projectReport } = action.payload;
      return {
        ...state,
        status: "success",
        projectReport,
      };
    }

    case getProjectReport.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    // we can re-init the project report with some values from another op (eg add pr doc)
    case getProjectReport.INIT: {
      const { projectReport } = action.payload;
      return {
        ...state,
        status: "init",
        projectReport,
        error: null,
      };
    }


    default: {
      return state;
    }
  }
};

export default reducer;
