import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CompanyProfile from "../components/CompanyProfile";
import { saveOrganizationInfo } from "../actions";

const mapStateToProps = ({ application, getProgram }) => ({
  application,
  getProgramResult: getProgram,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveOrganizationInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
