import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import Address from "@components/Address";
import { getAddress } from "@utils/locationHelpers";
import { getFullName } from "@utils/stringHelpers";
import { PhoneLink, EmailLink } from "@components";

const OrganizationPanel = ({ orgAsset }) => {
  const missing = <Typography color="error.main">Missing</Typography>;

  const tableRows = [
    {
      label: "Name",
      value: orgAsset?.organization?.name || missing,
    },
    {
      label: "Address",
      value: getAddress(orgAsset?.organization?.addresses, "MAIN") ? (
        <Address address={getAddress(orgAsset?.organization?.addresses, "MAIN")} />
      ) : (
        missing
      ),
    },
    {
      label: "Contact Name",
      value: getFullName(orgAsset?.organization?.primaryContact),
    },
    {
      label: "Contact Email",
      value: <EmailLink email={orgAsset?.organization?.primaryContact?.email} />
    },
    {
      label: "Contact Phone",
      value: <PhoneLink phone={orgAsset?.organization?.primaryContact?.phone} />
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Organization
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="organization">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default OrganizationPanel;
