import { getSiteCredits } from "../actions";

export const initialState = {
  status: "init",
  credits: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSiteCredits.REQUEST: {
      return {
        ...state,
        ...initialState,
        status: "request",
      };
    }

    case getSiteCredits.SUCCESS: {
      const { credits } = action.payload;
      return {
        ...state,
        status: "success",
        credits,
      };
    }

    case getSiteCredits.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
