import { verifyBuyerInviteToken } from "../actions";

export const initialState = {
  status: "init",
  invite: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case verifyBuyerInviteToken.RESET: {
      return initialState;
    }
    
    case verifyBuyerInviteToken.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case verifyBuyerInviteToken.SUCCESS: {
      const { invite } = action.payload;
      return {
        ...state,
        status: "success",
        invite,
      };
    }

    case verifyBuyerInviteToken.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
