import getProjects from "./getProjects";
import createProject from "./createProject";
import projectTableState from "./projectTableState";
import getAcceptableSites from "./getAcceptableSites";

const reducers = {
  getProjects,
  projectTableState,
  createProject,
  getAcceptableSites,
};

export default reducers;
