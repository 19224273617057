import { call, put, takeLatest, select } from "redux-saga/effects";
import { patchAppSiteProfile } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getApplication } from "../selectors";
import { isNil } from "lodash";
import { DateTime } from "luxon";

const callApi = async (accessToken, application) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${application.id}`,
    {
      application,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const mapResponse = (siteProfile, application) => {
  const body = {
    id: application.id,
    asset: {
      name: siteProfile.name,
      addresses: {
        main: {
          ...siteProfile.address,
          types: ["MAIN"],
        },
      },
      capacity: siteProfile.capacity * 1, // watts
      mountingType: siteProfile.mountingType || undefined,
      completionDate:
        siteProfile.completionDate &&
        DateTime.fromJSDate(siteProfile.completionDate).toISODate(), // can be a luxon or iso format
    },
  };

  // don't submit a siteOwner if an org owns the site
  const hasSiteOwner = !isNil(siteProfile.firstName);

  return hasSiteOwner
    ? {
        ...body,
        siteOwner: {
          givenName: siteProfile.firstName,
          familyName: siteProfile.lastName,
          email: siteProfile.email,
          phone: siteProfile.phone,
        },
      }
    : body;
};

function* patchAppSiteProfileWorker(action) {
  yield put(patchAppSiteProfile.request());

  const { accessToken, siteProfile } = action.payload;
  const app = yield select(getApplication);

  try {
    const response = yield call(
      callApi,
      accessToken,
      mapResponse(siteProfile, app)
    );
    yield put(patchAppSiteProfile.success({ application: response.data }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")(
        "Error updating application site profile: ",
        err.response
      );
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")(
        "Error updating application site profile: ",
        err.request
      );
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error updating application site profile: ", err);
      message = err.message;
    }
    message = `Unable to update application site profile: ${message}`;
    yield put(patchAppSiteProfile.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchAppSiteProfileWatcher() {
  yield takeLatest(patchAppSiteProfile.TRIGGER, patchAppSiteProfileWorker);
}

export default [patchAppSiteProfileWatcher];
