import { createAction } from "redux-actions";

const reset = createAction("RESET_APP_MESSAGE");
reset.TYPE = reset.toString();

const showAppMessage = createAction("SHOW_APP_MESSAGE");
showAppMessage.TYPE = showAppMessage.toString();

const hideAppMessage = createAction("RESET_APP_MESSAGE");
hideAppMessage.TYPE = hideAppMessage.toString();

export { reset, showAppMessage, hideAppMessage };
