import { next, prev, resetHistory } from "../actions";
import { dropRight } from "lodash";

export const initialState = {
  // [key]: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case next.TYPE: {
      const { path, key } = action.payload;
      const history = state[key] || [];
      return {
        ...state,
        [key]: [...history, path],
      };
    }

    case prev.TYPE: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: dropRight(state[key]),
      };
    }

    case resetHistory.TYPE: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
