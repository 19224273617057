import { call, put, takeLatest } from "redux-saga/effects";
import { getOnboardingChart } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/monitoring/onboarding`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getOnboardingChartWorker(action) {
  yield put(getOnboardingChart.request());

  const { accessToken } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
    );
    const chart = response.data;
    yield put(getOnboardingChart.success({ chart }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")(
        "Error getting onboarding chart: ",
        err.response
      );
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting onboarding chart: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting onboarding chart: ", err);
      message = err.message;
    }
    message = `Unable to get onboarding chart: ${message}`;
    yield put(getOnboardingChart.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getOnboardingChartWatcher() {
  yield takeLatest(getOnboardingChart.TRIGGER, getOnboardingChartWorker);
}

export default [getOnboardingChartWatcher];
