import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Page from "@components/Page";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@components/PanelDisplayTable";
import OrganizationEditor from "../containers/OrganizationEditor";
import { INDUSTRIES } from "@views/apply/components/OrganizationInfo";
import { includes, keys, get, find } from "lodash";
import Address from "@components/Address";
import { PhoneLink } from "@components";

const Organization = ({ getOrganizationResult }) => {
  const { organization } = getOrganizationResult;

  // edit profile action
  const [isOrganizationEditorOpen, setIsOrganizationEditorOpen] = useState(
    false
  );
  const handleEdit = () => {
    setIsOrganizationEditorOpen(true);
  };
  const handleClose = () => {
    setIsOrganizationEditorOpen(false);
  };

  const hasIndustry = includes(keys(INDUSTRIES), organization?.industry);

  const tableRows = [
    {
      label: "Name",
      value: organization?.name,
    },
    {
      label: "Phone Number",
      value: <PhoneLink phone={organization?.phone} />
    },
    {
      label: "Website",
      value: organization?.website || "N/A",
    },
    {
      label: "Industry",
      value: hasIndustry
        ? get(INDUSTRIES, organization.industry)
        : organization?.industry || "N/A",
    },
    {
      label: "Address",
      value: (
        <Address
          address={find(organization?.addresses, (address) =>
            includes(address.types, "MAIN")
          )}
        />
      ),
    },
  ];

  return (
    <Page title="Organization" py={3}>
      <Container maxWidth="lg">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h1">Organization</Typography>
          </Box>
        </Box>
        <Paper sx={{ mt: 2, px: 10, py: 4 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Contact
            </Typography>
            <Tooltip title="Edit Organization">
              <span>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  sx={{ mt: -1.5 }}
                  onClick={handleEdit}
                  size="large"
                  disabled={getOrganizationResult.status === "request"}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <TableContainer>
            <Table aria-label="organization">
              <TableBody>
                {tableRows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell component="th">{row.label}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
      {organization && (
        <OrganizationEditor
          open={isOrganizationEditorOpen}
          onClose={handleClose}
          organization={organization}
        />
      )}
    </Page>
  );
};

Organization.propTypes = {
  getOrganizationResult: PropTypes.shape({}).isRequired,
};

export default Organization;
