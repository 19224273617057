import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { requestSmaPermission } from "views/addSites/actions";

export const DataConnector = 'smaSunnyPortalEnnexOS';

const Form = ({ dataConnectorParams }) => {
  const [systemOwner, setSystemOwner] = useState('');

  const { errors, control } = useFormContext();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const onRequestPermission = () => {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }).then((accessToken) => {
      dispatch(requestSmaPermission({ email: systemOwner, accessToken }));
    });
  };
  const smaPermissionResult = useSelector(state => state.requestSmaPermission);

  const isLoading = smaPermissionResult.status === "request";
  const isSuccess = smaPermissionResult.status === "success";

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label="System Owner Email"
          variant="standard"
          fullWidth
          placeholder="joe@company.com"
          autoFocus
          value={systemOwner}
          onChange={e => setSystemOwner(e.target.value)}
          helperText="Email address of the system owner."
        />
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onRequestPermission}
          disabled={isSuccess}
          loading={isLoading}
          sx={{ mb: 5 }}
        >
          Request Permission
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="System ID"
              variant="standard"
              fullWidth
              placeholder="897459874"
              autoFocus
              error={!isNil(errors.plantId?.message)}
              helperText={
                errors.plantId?.message ||
                "ID of the system/plant you want to onboard"
              }
            />
          }
          name="plantId"
          control={control}
          rules={{ required: "System ID is required" }}
          defaultValue={dataConnectorParams?.plantId || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Instructions to Grant Rewatt Access
      </Typography>
      <Typography gutterBottom>
        Follow our{" "}
        <Link href="https://rewattpower.com/sma" target="_new">
          step-by-step instructions
        </Link>{" "}
        to onboard your SMA site.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <Box>
            <ListItemText
              primary="Determine if you are a system owner for the site you want to onboard."
              secondary="Only the system owner may grant Rewatt permission to collect site data. You cannot complete onboarding your site without permission. Click 'View Instructions' for more information."
            />
          </Box>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Enter your email address, as it appears in Sunny Portal, in the field below and click 'Request Permission'."
            secondary="This action will begin the process to acquire your permission for Rewatt to collect the site data."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText primary="Check your email inbox for instructions from SMA on how to give Rewatt access to your site data." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Enter the ID of the system you want to onboard in the field below and click 'Test Connection'"
            secondary="Rewatt requires data access to your site in order for 'Test Connection' to be successful. If the previous step was not completed successfully you will not be able to onboard your site."
          />
        </ListItem>
      </List>
    </Box>
  );
};
const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;
