import React, { useEffect } from "react";
import { Box, Typography, Grid, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayStatus } from "@views/sites";
import { round } from "@utils/numberHelpers";
import { isNil } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { getSiteStats } from "../actions";

const StatsPanel = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.getUserProfile);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSiteStats({ accessToken, siteId: userProfile.assetId })); // lifetime energy, specific yield
    }
    if (userProfile.assetId) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, userProfile?.assetId]);
  const { site, getSiteStatus } = useSelector((state) => state.getSite);
  const isLoadingSite = getSiteStatus === 'request';
  const displayStatus = getDisplayStatus(site?.status);
  const { stats, status: getStatsStatus } = useSelector((state) => state.getSiteStats);
  const isLoadingStats = getStatsStatus === 'request';

  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Box
          my={3}
          ml={3}
          sx={{ borderRight: (theme) => `1px solid ${theme.palette.primary.main}` }}
        >
          <Typography variant="h5" fontWeight={500}>
            Specific Yield
          </Typography>
          {isLoadingStats ? (
            <Box pr={5}>
              <Skeleton variant="text" />
            </Box>
          ) : (
            isNil(stats.specificYield) ? (
              <Typography color="grey.500" variant="h5">
                N/A
              </Typography>
            ) : (
              <Typography color="info.light" variant="h5">
                {round(stats.specificYield, 1).toLocaleString()}
                <Typography color="textSecondary" component="span">
                  {" "}
                  kWh/kWp
                </Typography>
              </Typography>
            )
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box my={3} ml={3}>
          <Typography variant="h5" fontWeight={500}>
            Site Status
          </Typography>
          {isLoadingSite ? <Skeleton variant="text" /> : (
            <Typography color={displayStatus.color} variant="h5">
              {displayStatus.statusName}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default StatsPanel;
