import getProgram from "./getProgram";
import patchProgram from "./patchProgram";
import getProgramSiteStatuses from "./getProgramSiteStatuses";
import getProgramSites from "./getProgramSites";
import applicationsTableState from "./applicationsTableState";
import programSitesTableState from "./programSitesTableState";
import getQualifiedSites from "./getQualifiedSites";
import inviteQualifiedSites from "./inviteQualifiedSites";

const reducers = {
  getProgram,
  patchProgram,
  getProgramSiteStatuses,
  getProgramSites,
  applicationsTableState,
  programSitesTableState,
  getQualifiedSites,
  inviteQualifiedSites,
};

export default reducers;
