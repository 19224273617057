import { setMemberTableState } from "../actions";

export const initialState = {
  page: 0,
  pageSize: 10,
  sortModel: [
    {
      field: "date",
      sort: "desc",
    },
  ],
  filterModel: {
    items: [
    ],
    linkOperator: "and",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setMemberTableState.TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
