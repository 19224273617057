import React, { useEffect, useState } from "react";
import { Box, IconButton, InputAdornment, Link, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopy from "@mui/icons-material/FileCopy";

const ApiKeyPanel = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const fetchAccessToken = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      setAccessToken(accessToken);
    };
    fetchAccessToken();
  }, [accessToken, getAccessTokenSilently]);

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Access Token
          </Typography>
          <Typography variant="body2" gutterBottom>
            Use this access token for up to 24 hours to access the underlying{" "}
            <Link
              target="_new"
              href="https://editor.swagger.io/?url=https://rewattpower.github.io/surge/dist/openapi.yaml"
            >
              Surge API
            </Link>.
          </Typography>
          <TextField
            sx={{ ml: 2 }}
            label=""
            value={accessToken}
            variant="standard"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ mb: 1 }}>
                  <CopyToClipboard text={accessToken}>
                    <Tooltip title="Copy link to clipboard">
                      <IconButton size="large">
                        <FileCopy />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </InputAdornment>
              ),
              inputProps: { readOnly: true },
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

export default ApiKeyPanel;
