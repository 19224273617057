import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ProjectFormFields, { createProjectSchema } from "./ProjectFormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { get } from "lodash";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "../actions";
import { PROTOCOLS, ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateProjectEditor = ({ open, onClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(createProjectSchema),
    defaultValues: {
      title: "",
      startDate: "",
      endDate: "",
      environmentalCredit: "",
      protocol: "",
      authority: "",
      registry: "",
      emissionsFactor: "",
      listingUrl: "",
      assetIds: [],
    },
  });

  // submit
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });

    dispatch(createProject({ project: data, accessToken }));
  };

  const done = () => {
    methods.reset();
    dispatch(createProject.reset());
    onClose();
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPlanningSheet = async ({ projectId, projectName }) => {
    try {
      setIsDownloading(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects",
      });
      await downloadFile(
        {
          name: `${projectName} - Aggregated Planning Sheet.xlsm`,
          url: `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/aggregatedPlanningSheet`,
        },
        accessToken
      );
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error",
      });
    }
  };

  const { project, status } = useSelector((state) => state.createProject);
  const { id: projectId, title: projectName } = project;
  const isSubmitting = status === "request";
  const isSuccess = status === "success";
  const isAlbertaEmissionsOffsetProject = project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={done}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ bgcolor: "primary.main" }} position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={done} aria-label="close" size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Create Project
          </Typography>
        </Toolbar>
      </AppBar>
      {isSuccess ? (
        <Container maxWidth="md">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "calc(80vh - 64px)" }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h1" color="secondary.main">
                Success!
              </Typography>
              <Typography mt={2}>
                You have created a new project under the{" "}
                <strong>
                  {get(ENVIRONMENTAL_CREDIT_TYPES, project?.environmentalCredit, "N/A")}
                </strong>{" "}
                system using the <strong>{get(PROTOCOLS, project?.protocol?.name, "N/A")}</strong>{" "}
                protocol.
              </Typography>
              {isAlbertaEmissionsOffsetProject && (
                <>
                  <Typography mt={1}>
                    You will be required to submit an aggregated planning sheet to the registry
                    which you can download by clicking the button below. Please be patient, this can
                    take a minute or two.
                  </Typography>
                  <Box mt={5}>
                    <LoadingButton
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => downloadPlanningSheet({ projectId, projectName })}
                      disabled={isDownloading}
                      loading={isDownloading}
                    >
                      Download Aggregated Planning Sheet
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="lg">
          <FormProvider {...methods}>
            <ProjectFormFields />
            <Box display="flex" justifyContent="flex-end" mt={5} mb={3}>
              <LoadingButton
                variant="contained"
                size="large"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Create Project
              </LoadingButton>
            </Box>
          </FormProvider>
        </Container>
      )}
    </Dialog>
  );
};

CreateProjectEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateProjectEditor;
