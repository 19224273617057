import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DocumentUploader from "@addSites/sitePicker/containers/DocumentUploader";
import DocumentsInfo from "@addSites/sitePicker/components/DocumentsInfo";
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentsEditor = ({
  open,
  documents: initialDocuments,
  onClose,
  onSave,
  patchAppSiteDocuments,
  patchAppSiteDocumentsResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [documents, dispatch] = useReducer(documentsReducer, initialDocuments);

  // once loaded, initialDocuments changes (but not the reducer)
  useEffect(() => {
    dispatch({
      type: "reset",
      payload: initialDocuments,
    });
  }, [initialDocuments]);

  // patch application on BE
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    patchAppSiteDocuments({ accessToken, documents });
  };

  // close and cleanup on success
  useEffect(() => {
    if (patchAppSiteDocumentsResult.status === "success") {
      // ie. update component state in Documents
      onSave(patchAppSiteDocumentsResult.application.asset.documents);
    }

    // "documents" gets changed on re-render, so ignore dependency (otherwise can't re-open)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchAppSiteDocumentsResult, onSave]);

  const isSubmitting = patchAppSiteDocumentsResult.status === "request";
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          backgroundColor: (theme) => theme.palette.background.dark,
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Upload Documents
          </Typography>
          <LoadingButton
            color="inherit"
            onClick={onSubmit}
            title="Save"
            loading={isSubmitting}
            loadingIndicator={<CircularProgress sx={{ color: "common.white" }} size={16} />}
          >
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Box m={4}>
        <Paper elevation={1} sx={{ p: 2 }}>
          <DocumentsInfo>
            <DocumentUploader documents={documents} dispatch={dispatch} />
          </DocumentsInfo>
        </Paper>
      </Box>
    </Dialog>
  );
};

DocumentsEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})),

  patchAppSiteDocuments: PropTypes.func.isRequired,
  patchAppSiteDocumentsResult: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default DocumentsEditor;
