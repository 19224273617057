import React, { useEffect, useRef } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Page from "@components/Page";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "@components/PageLoader";
import Breadcrumb from "@components/Breadcrumb";
import PageHeader from "@components/PageHeader";
import ContributionsPanel from "./ContributionsPanel";
import { ProjectReportStatusPanel } from "@admin/projectReport";
import VerifierPanel from "./VerifierPanel";
import { useDispatch, useSelector } from "react-redux";
import { getProjectReport } from "@admin/projectReport/actions";
import { getProject } from "@admin/project/actions";
import PRDetails from "./ProjectReportDetailPanel";
import { ProjectDetailPanel } from "@admin/project";

const ProjectReport = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { projectId, projectReportId } = useParams();
  const pageRef = useRef();
  const dispatch = useDispatch();

  const { projectReport, status: projectReportStatus } = useSelector((state) => state.getProjectReport);
  const { project, status: projectStatus } = useSelector((state) => state.getProject);

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports",
      });
      if (!project?.title) {
        dispatch(getProject({ accessToken, projectId }));
      }
    }
    init();
  }, [getAccessTokenSilently, dispatch, projectId, project?.title]);

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports",
      });
      dispatch(getProjectReport({ accessToken, projectReportId }));
    }
    init();
  }, [getAccessTokenSilently, dispatch, projectReportId]);

  const isLoading =
    projectReportStatus === "request" || projectStatus === "request";

  // scroll to top
  useEffect(() => {
    if (!isLoading) {
      pageRef?.current?.scrollIntoView();
    }
  }, [isLoading]);

  const projectTitle = project?.title || `Project ${projectId}`;
  return (
    <Page title={`Project Report: ${projectReport?.id}`} py={3} ref={pageRef}>
      <Container maxWidth="lg">
        {isLoading
          ? <PageLoader message="Loading..." />
          : (
            <>
              <PageHeader title={`Project Report #${projectReport?.id}`}>
                <Breadcrumb title="Projects" destination={`/admin/projects`} />
                <Breadcrumb
                  title={projectTitle}
                  destination={`/admin/projects/${projectId}`}
                />
              </PageHeader>
              <Box mt={3} ml={1}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography variant="h3" gutterBottom ml={2}>
                      Project Details
                    </Typography>
                    {project && <ProjectDetailPanel project={project} editable={false} />}
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h3" gutterBottom ml={2}>
                      Project Report
                    </Typography>
                    {projectReport && project && <PRDetails projectReport={projectReport} project={project} />}
                  </Grid>
                  <Grid item sm={12}>
                    {projectReport && <ProjectReportStatusPanel projectReport={projectReport} />}
                  </Grid>
                  <Grid item xs={12}>
                    {projectReport && <ContributionsPanel projectReport={projectReport} />}
                  </Grid>
                  <Grid item xs={12}>
                    {projectReport && <VerifierPanel projectReport={projectReport} />}
                  </Grid>
                </Grid>
              </Box>
            </>
          )
        }
      </Container>
    </Page>
  );
};

export default ProjectReport;
