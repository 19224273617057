import React from "react";
import ContactForm from "views/common/components/ContactForm";

const InstallerForm = () => (
  <ContactForm
    instructions="Please tell us about your Solar Contractor so that we can request
            access to the generation data."
    email={true}
  />
);

export default InstallerForm;
