import * as Yup from "yup";
import { DateTime } from "luxon";

export const voluntarySchema = Yup.object().shape({
  calculateByDateRange: Yup.string().required(),
  startDate: Yup.date().when("calculateByDateRange", {
    is: "yes",
    then: (schema) =>
      schema
        .required("Start date is required.")
        .max(Yup.ref("endDate"), "Start date must be before end date")
        .typeError("Date should be in format MM/DD/YYYY"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  endDate: Yup.date().when("calculateByDateRange", {
    is: "yes",
    then: (schema) =>
      schema
        .required("End date is required.")
        .min(Yup.ref("startDate"), "End date must be after start date")
        .max(DateTime.now().minus({ days: 2 }).endOf("day"), "End date cannot be in the future")
        .typeError("Date should be in format MM/DD/YYYY"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  credits: Yup.number().when("calculateByDateRange", {
    is: "no",
    then: (schema) =>
      schema.required("Credits are required.").typeError("Credits should be an integer"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
});

export const complianceSchema = voluntarySchema.shape({
  aggregatedReportingSheetTemplate: Yup.object().required(
    "An aggregated reporting sheet template is required"
  ),
});
