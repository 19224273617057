import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { StepNav } from "@views/addSites";
import { useAuth0 } from "@auth0/auth0-react";

// this is a base class
const SiteActivity = ({
  siteInfo,
  saveSiteActivity,
  stepLayout: StepLayout,
  backAction,
  nextAction,
  getOrganization,
  getOrganizationResult,
}) => {
  const handleActivityTypeChange = (event) => {
    saveSiteActivity({ activityType: event.target.value });
  };

  const handleOwnerChange = (event, isOrgOwner) => {
    const siteOwner = isOrgOwner
      ? { organizationId: getOrganizationResult.organization?.id }
      : null;
    saveSiteActivity({
      siteOwner,
    });
  };

  // get org
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }).then((accessToken) => {
      getOrganization({ accessToken });
    });
  }, [getAccessTokenSilently, getOrganization]);

  return (
    <StepLayout activeStep={0}>
      <form noValidate autoComplete="off">
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              What kind of site are you adding?
            </Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="activityLabel">Type of Activity</InputLabel>
              <Select
                labelId="activityLabel"
                name="activityType"
                value={siteInfo.activityType}
                onChange={handleActivityTypeChange}
              >
                <MenuItem value="solar">Solar</MenuItem>
                <MenuItem value="wind" disabled>
                  Wind
                </MenuItem>
                <MenuItem value="geothermal" disabled>
                  Geothermal
                </MenuItem>
              </Select>
              <FormHelperText>
                The source of your energy generation for this site.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Is your organization ({getOrganizationResult?.organization?.name})
              the owner of these sites?
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <ToggleButtonGroup
                value={siteInfo.siteOwner !== null}
                exclusive
                onChange={handleOwnerChange}
                aria-label="Is your organization the owner?"
                size="small"
                fullWidth
              >
                <ToggleButton value={true} aria-label="yes">
                  YES
                </ToggleButton>
                <ToggleButton value={false} aria-label="no">
                  NO
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Grid>
        </Grid>

        <StepNav backAction={backAction} nextAction={nextAction} />
      </form>
    </StepLayout>
  );
};

SiteActivity.defaultProps = {
  backAction: null,
};

SiteActivity.propTypes = {
  siteInfo: PropTypes.shape({
    activityType: PropTypes.string,
  }),
  saveSiteActivity: PropTypes.func.isRequired,

  getOrganizationResult: PropTypes.shape({
    name: PropTypes.string,
  }),
  getOrganization: PropTypes.func.isRequired,

  // ie. renderable
  stepLayout: PropTypes.func.isRequired,

  // override the back and next button actions
  // set to `null` to disable
  backAction: PropTypes.func,
  nextAction: PropTypes.func.isRequired,
};

export default SiteActivity;
