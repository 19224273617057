import { getSiteChart } from "../actions";

export const initialState = {
  status: "init",
  data: [
    {
      name: "Generation",
      data: [],
    },
    {
      name: "Grid Export",
      data: [],
    },
    {
      name: "Grid Import",
      data: [],
    },
    {
      name: "Temperature",
      data: [],
    },
    {
      name: "Snow",
      data: [],
    },
    {
      name: "Daylight",
      data: [],
    },
  ],
  granularity: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSiteChart.RESET: {
      return initialState;
    }

    case getSiteChart.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getSiteChart.SUCCESS: {
      const { data, granularity } = action.payload;
      return {
        ...state,
        status: "success",
        data,
        granularity,
      };
    }

    case getSiteChart.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
