import { call, put, takeLatest } from "redux-saga/effects";
import { setProjectStatus } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { createProject } from "@views/admin/projects/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async (accessToken, project, status) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${project.id}/status`,
    { status },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* setProjectStatusWorker(action) {
  yield put(setProjectStatus.request());

  const { accessToken, project, status } = action.payload;

  try {
    const response = yield call(callApi, accessToken, project, status);
    yield put(setProjectStatus.success({ project: response.data }));
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error setting project status: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error setting project status: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error setting project status: ", err);
      message = err.message;
    }
    message = `Unable to set project status: ${message}`;
    yield put(setProjectStatus.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* defaultProjectStatusWorker(action) {
  // if voluntary, set status to submitted/registered
  const { project, accessToken } = action.payload;
  if (project.environmentalCredit === "REWATT_VOLUNTARY_CREDIT") {
    yield put(setProjectStatus({ accessToken, project, status: "SUBMITTED" }));
    yield put(setProjectStatus({ accessToken, project, status: "REGISTERED" }));
  }
}

function* setProjectStatusWatcher() {
  yield takeLatest(setProjectStatus.TRIGGER, setProjectStatusWorker);
}

function* createProjectWatcher() {
  yield takeLatest(createProject.SUCCESS, defaultProjectStatusWorker);
}

export default [setProjectStatusWatcher, createProjectWatcher];
