import { createAction } from "redux-actions";
import {
  createRoutineCreator,
  defaultRoutineStages,
  createRoutine,
} from "redux-saga-routines";

const getSite = createRoutine("GET_SITE");
const getSiteChart = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_SITE_CHART"
);
const getSiteAvailability = createRoutine("GET_SITE_AVAILABILITY");
const getFirstReportDate = createRoutine("GET_FIRST_REPORT_DATE");
const patchSiteDetails = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_SITE_DETAILS"
);
const patchSiteLocation = createRoutine("PATCH_SITE_LOCATION");
const patchSiteDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_SITE_DOCUMENTS"
);
const getSiteIncidents = createRoutine("GET_SITE_INCIDENTS");
const getIncidentLog = createRoutine("GET_INCIDENT_LOG");
const subscribeToSiteNotifications = createRoutine("SUBSCRIBE_SITE_NOTIFICATIONS");
const unsubscribeFromSiteNotifications = createRoutine("UNSUBSCRIBE_SITE_NOTIFICATIONS");

const publishLiveSite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PUBLISH_LIVE_SITE"
);

const getSubscriptions = createRoutineCreator([...defaultRoutineStages, "MUTATE"])(
  "GET_SUBSCRIPTIONS"
);

// we can put a brief site summary from the info we got fetching all sites, in the getSite reducer
const putSite = createAction("PUT_SITE");
putSite.TYPE = putSite.toString();

// if we edit the site, update the getSite reducer too
const updateSite = createAction("UPDATE_SITE");
updateSite.TYPE = updateSite.toString();

export {
  getSite,
  putSite,
  getSiteChart,
  getSiteAvailability,
  getFirstReportDate,
  patchSiteDetails,
  patchSiteLocation,
  patchSiteDocuments,
  publishLiveSite,
  updateSite,
  getSiteIncidents,
  getIncidentLog,
  subscribeToSiteNotifications,
  unsubscribeFromSiteNotifications,
  getSubscriptions,
};
