import { updateOrganization } from "@views/common/actions";

export const initialState = {
  status: "init",
  organization: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case updateOrganization.RESET: {
      return initialState;
    }

    case updateOrganization.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case updateOrganization.SUCCESS: {
      return {
        ...state,
        status: "success",
        organization: action.payload.organization,
      };
    }

    case updateOrganization.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
