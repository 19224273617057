import React from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Breadcrumb = ({ title, destination, ...other }) => {
  const navigate = useNavigate();
  
  if (!destination) {
    return <Typography variant="h4" color="primary.light">{title}</Typography>;
  }

  return (
    <Button
      variant="text"
      sx={{ textTransform: "none" }}
      onClick={() => navigate(destination)}
      {...other}
    >
      <Typography variant="h4">{title}</Typography>
    </Button>
  );
};

Breadcrumb.defaultProps = {
  title: "",
  destination: null,
};

Breadcrumb.propTypes = {
  title: PropTypes.string,
  destination: PropTypes.string,
};

export default Breadcrumb;
