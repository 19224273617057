import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const acceptChannelPartnerInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ACCEPT_CHANNEL_PARTNER_INVITE"
);

const verifyChannelPartnerInviteToken = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "VERIFY_CHANNEL_PARTNER_INVITE_TOKEN"
);

export { verifyChannelPartnerInviteToken, acceptChannelPartnerInvite };
