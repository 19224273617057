import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { map, isEmpty, first } from "lodash";
import { DocumentTextIcon } from "@heroicons/react/solid";
import HeroIcon from "@components/HeroIcon";
import { useAuth0 } from "@auth0/auth0-react";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { fileSizeValidator } from "@utils/fileHelpers";
import byteSize from "byte-size";
import { useDispatch, useSelector } from "react-redux";
import { showAppMessage, hideAppMessage } from "@appMessage/actions";
import { uploadReportingSheetTemplate } from "@admin/project/actions";
import DropZone from "@components/DropZone";

const maxFileSize = 2 * 1000 * 1000; // bytes

const ReportingSheetTemplateUploader = ({ onChange, value, error }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/vnd.ms-excel": [".xlsm"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsm"],
      "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    },
    validator: fileSizeValidator(maxFileSize),
  });

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      dispatch(
        showAppMessage({
          severity: "error",
          message: fileRejections[0].errors[0].message,
        })
      );
    } else {
      dispatch(hideAppMessage());
    }
  }, [fileRejections, dispatch]);

  // we have some new files to upload
  useEffect(() => {
    async function uploadNewFiles() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      dispatch(
        uploadReportingSheetTemplate({
          filespecs: map(acceptedFiles, (file) => ({
            file,
            tags: [],
            public: true,
          })),
          accessToken,
        })
      );
    }
    if (acceptedFiles.length) {
      uploadNewFiles();
    }
    // eslint-disable-next-line
  }, [acceptedFiles]);

  // inform our form
  const { filespecs, status } = useSelector((state) => state.uploadReportingSheetTemplate);
  const uploadedFilespec = first(filespecs);
  useEffect(() => {
    if (uploadedFilespec) {
      onChange(uploadedFilespec);
    }
  }, [onChange, uploadedFilespec]);

  const isUploading = status === "request";
  return (
    <>
      <DropZone
        {...getRootProps({
          isFocused,
          isDragAccept,
          isDragReject,
          isDragActive,
          isError: !isEmpty(error),
        })}
        width="unset"
        height={120}
        sx={{ mt: 1, mb: 2 }}
      >
        <input {...getInputProps()} />
        <Box m={1}>
          <HeroIcon icon={DocumentTextIcon} />
          <Typography variant="body2" color="textPrimary" gutterBottom>
            Drag and drop your Aggregated Project Reporting Sheet, or click to browse
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Max file size: {byteSize(maxFileSize, { precision: 0 }).toString()}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            XLSM (Excel Spreadsheet with Macros) only
          </Typography>
        </Box>
        {isUploading && (
          <CircularProgress
            size={32}
            sx={{
              color: (theme) => theme.palette.primary.main,
              position: "absolute",
              top: "50%",
              left: "50%",
              mt: -2,
              ml: -2,
            }}
          />
        )}
      </DropZone>
      {value?.uri && (
        <Box display="flex" alignItems="center">
          <FilePresentIcon />
          <Typography ml={1} color="secondary">
            Uploaded File ({value.file?.name || value.name})
          </Typography>
        </Box>
      )}
    </>
  );
};

ReportingSheetTemplateUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
  error: PropTypes.string,
};

export default ReportingSheetTemplateUploader;
