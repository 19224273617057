/**
 * Use the reverse geocoding api.
 * https://developers.google.com/maps/documentation/geocoding/overview#ReverseGeocoding
 * For this api, you cannot use a referrer-restricted key.
 */
import { call, put, takeLatest } from "redux-saga/effects";
import { getAddress } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { isNil } from "lodash";

const callApi = async (latitude, longitude) => {
  const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
    params: {
      latlng: `${latitude},${longitude}`,
      key: process.env.REACT_APP_TZ_API_KEY,
    },
    withCredentials: false,
  });
  return response;
};

export function* getAddressWorker(action) {
  yield put(getAddress.request());

  const { latitude, longitude } = action.payload;

  try {
    const response = yield call(callApi, latitude, longitude);
    if (!isNil(response.data) && !response.data.error_message) {
      yield put(getAddress.success({ results: response.data.results }));
    } else {
      const message = `Failed to get address: ${response.data.error_message}`;
      yield put(getAddress.failure({ error: message }));
      yield put(showAppMessage({ severity: "error", message }));
    }
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting address: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting address: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting address: ", err);
      message = err.message;
    }
    message = `Unable to get address: ${message}`;
    yield put(getAddress.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getAddressWatcher() {
  yield takeLatest(getAddress.TRIGGER, getAddressWorker);
}

export default [getAddressWatcher];
