import { call, put, takeLatest, select } from "redux-saga/effects";
import { submitApplication } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getApplication } from "../selectors";
import { getUserProfile } from "@views/common/selectors";
import { flattenObject } from "@utils/errorHelpers";
import ValidationErrors from "@components/ValidationErrors";
import { labelAddresses } from "@utils/locationHelpers";
import { map, transform, isEmpty } from "lodash";

const callApi = async (accessToken, programId, requestBody) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/programs/${programId}/applications`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const prepareRequestBody = (application, userProfile) => {
  const asset = application.siteInfo.site;
  if (asset.exists && application.siteOwner.ownerType === "INDIVIDUAL") {
    return {
      application: {
        acceptedProgramTermsAndConditions:
          application.acceptedProgramTermsAndConditions,
        referrer: application.referrer,
        asset,
      },
    };
  }

  const assetAddresses = labelAddresses(asset.location.addresses);
  let requestBody = {
    application: {
      acceptedProgramTermsAndConditions:
        application.acceptedProgramTermsAndConditions,
      referrer: application.referrer,
      asset: {
        ...asset,
        id: new RegExp(/^[0-9.]+$/).test(asset.id) ? asset.id : undefined, // don't submit our uuids
        latitude: asset.location.latitude,
        longitude: asset.location.longitude,
        timeZone: asset.location.timeZone,
        completionDate: asset.completionDate,
        addresses: assetAddresses,
        capacity: asset.capacity,
        documents: map(asset.documents, (doc) => ({
          ...doc,
          name: doc.file.path,
        })),
        installer: application.siteInfo.installer.email
          ? {
              ...application.siteInfo.installer,
              givenName: application.siteInfo.installer.firstName,
              familyName: application.siteInfo.installer.lastName,
            }
          : undefined,
        meters: map(asset.meters, (meter) => ({
          ...meter,
          dataConnector: {
            ...meter.dataConnector,
            ...application.siteInfo.credentials,
          },
        })),
        ...transform(
          asset.details,
          (result, value, key) => (result[key] = value ? value : undefined),
          {}
        ),
      },
    },
  };

  // org, but with external owner
  if (application.siteOwner.ownerType === "EXTERNAL") {
    const { external } = application.siteOwner;
    requestBody = {
      ...requestBody,
      application: {
        ...requestBody.application,
        siteOwner: {
          ...application.siteOwner,
          external: {
            givenName: external.firstName,
            familyName: external.lastName,
            email: external.email,
            phone: external.phone,
            roles: ["OWNER"],
          },
        },
        notifyOwner: application.siteOwner.external.notify,
      },
    };
  } else {
    requestBody = {
      ...requestBody,
      application: {
        ...requestBody.application,
        siteOwner: application.siteOwner,
      },
    };
  }

  // create org if necessary
  const hasOrg = !isEmpty(application.organization.name);
  if (hasOrg) {
    requestBody = {
      ...requestBody,
      organization: {
        name: application.organization.name,
        industry: application.organization.industry, // missing from BE
        addresses: labelAddresses(application.organization.addresses),
        contact: {
          roles: [application.role],
          givenName: userProfile.givenName,
          familyName: userProfile.familyName,
          email: userProfile.email,
          phone: userProfile.phone,
          jobTitle: "Relax me!", // todo: relax requirement
        },
      },
    };
  }

  return requestBody;
};

export function* submitApplicationWorker(action) {
  yield put(submitApplication.request());

  const { accessToken } = action.payload;
  const app = yield select(getApplication);
  const userProfile = yield select(getUserProfile);

  try {
    const response = yield call(
      callApi,
      accessToken,
      app.programId,
      prepareRequestBody(app, userProfile)
    );
    const { application, organization } = response.data;
    // supply accessToken and userId for a getUserProfile
    yield put(
      submitApplication.success({
        application,
        organization,
        accessToken,
        userId: userProfile.userId,
      })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error submitting application: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = (
          <ValidationErrors validationErrors={validationErrors} />
        );
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error submitting application: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error submitting application: ", err);
      message = err.message;
    }
    yield put(
      submitApplication.failure({
        error: `Unable to submit application: ${message}`,
      })
    );
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

export function* submitApplicationWatcher() {
  yield takeLatest(submitApplication.TRIGGER, submitApplicationWorker);
}

export default [submitApplicationWatcher];
