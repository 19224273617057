import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "@components/Page";
import { Container, Typography } from "@mui/material";
import { getUserProfile } from "@views/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Start = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // has inviteId from welcome page (set in onRedirectCallback)
  const { state } = useLocation();
  const { invite } = useSelector((state) => state.verifyChannelPartnerInviteToken);
  const inviteId = parseInt(state?.inviteId) || invite.id;

  // get user profile
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getUserProfile({ accessToken, userId: user.sub }));
    }

    fetchUserProfile();
  }, [user, getAccessTokenSilently, dispatch]);

  // once userProfile is loaded, navigate
  const { status, userProfile } = useSelector((state) => state.getUserProfile);
  useEffect(() => {
    if (status === "success") {
      const missingTerms = !!!userProfile.acceptedTermsAndConditions;
      const missingPersonal = !!!userProfile.givenName || !!!userProfile.familyName || !!!userProfile.phone
      if (missingTerms) {
        navigate("/channelPartners/terms");
      } else if (missingPersonal){
        navigate("/channelPartners/personal");
      } else {
        navigate("/channelPartners/organization");
      }
    }
  }, [status, userProfile, getAccessTokenSilently, inviteId, navigate]);

  const error = status === "failure";

  return error ? (
    <Page title="Channel Partner Onboarding" py={3}>
      <Container maxWidth="lg">
        <Typography variant="h4">
          Sorry we had a problem starting your onboarding. Please try again.
        </Typography>
      </Container>
    </Page>
  ) : null;
};

export default Start;
