import React from "react";
import { Typography, Box, Link } from "@mui/material";

const ApsInstructionPanel = () => {
  return (
    <Box mt={4} mb={2} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        APS supports two mechanisms for data access.
      </Typography>
      <Typography gutterBottom>
        The preferred method is to
        enable visitor access. For some applications, for example if you run a
        fleet, you will need to use the user/password option.
      </Typography>
      <Typography>
        <Link href="https://rewattpower.com/aps" target="_new">
          See detailed instructions here.
        </Link>
      </Typography>
    </Box>
  );
};

export default ApsInstructionPanel;
