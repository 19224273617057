import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { includes } from "lodash";
import TermsAndConditions from "@components/TermsAndConditions";
import Page from "@components/Page";
import { Container } from "@mui/material";
import RewattTermsPDF from "@assets/ReWatt - Account Terms and Conditions - v.3.pdf";

const OnboardingTermsAndConditions = ({
  updateUserProfile,
  updateUserProfileResult,
  showAppMessage,
  getUserProfile,
  getUserProfileResult,
  resetUpdateUserProfile,
}) => {
  const navigate = useNavigate();

  const { getAccessTokenSilently, user } = useAuth0();

  // fetch user profile if we don't already have it
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      if (
        !getUserProfileResult.userProfile?.id &&
        getUserProfileResult.status === "init"
      ) {
        getUserProfile({ accessToken, userId: user.sub });
      }
    }
    fetchUserProfile();
  }, [getUserProfile, getUserProfileResult, getAccessTokenSilently, user]);

  // submit
  const acceptTermsAndConditions = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      updateUserProfile({ accessToken, acceptedTermsAndConditions: true });
    } catch (err) {
      showAppMessage({ severity: "warning", message: err.message });
    }
  };

  // redirect on success
  useEffect(() => {
    if (updateUserProfileResult.status === "success") {
      resetUpdateUserProfile();
      navigate("/onboarding/contact");
    }
  }, [navigate, updateUserProfileResult, resetUpdateUserProfile]);

  const isLoading = includes(["init", "request"], getUserProfileResult.status);
  const isSubmitting = updateUserProfileResult.status === "request";
  return (
    <Page title="Terms and Conditions" py={3}>
      <Container maxWidth={false}>
        <TermsAndConditions
          acceptTermsAndConditions={acceptTermsAndConditions}
          isSubmitting={isLoading || isSubmitting}
          pdf={{
            url: RewattTermsPDF,
            name: "Rewatt Terms and Conditions.pdf",
          }}
          submitActionText="Continue to questions"
        />
      </Container>
    </Page>
  );
};

OnboardingTermsAndConditions.propTypes = {
  showAppMessage: PropTypes.func.isRequired,
  resetUpdateUserProfile: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserProfileResult: PropTypes.shape({}).isRequired,
  getUserProfile: PropTypes.func.isRequired,
  getUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default OnboardingTermsAndConditions;
