import { useAuth0 } from '@auth0/auth0-react';
import Breadcrumb from 'components/Breadcrumb';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Container,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DownloadIcon from "@mui/icons-material/GetApp";
import { isEmpty, isNil } from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from "@utils/fileHelpers";
import { showAppMessage } from "@appMessage/actions";
import { useState } from 'react';
import { DateTime } from 'luxon';
import { getDefaultLocale } from 'utils/dateHelpers';
import { EMISSION_REDUCTION_ACTIVITIES } from 'views/program/components/Program';
import { getChannelPartner } from '../actions';
import { getChannelPartnerReferrals } from '../actions';
import { ReferralsTable } from 'views/channelPartner/components/Referrals';

const TermsAndConditionsTable = () => {
  const { status, channelPartner } = useSelector((state) => state.getChannelPartner);
  const isLoading = status === 'request';
  const termsAndConditions = channelPartner?.termsAndConditions ?? [];

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // hack to download the file, sort of like opening in new window
  const [uriDownloading, setUriDownloading] = useState("");
  const downloadDoc = async (doc) => {
    try {
      setUriDownloading(doc.uri);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(doc, accessToken);
    } catch (err) {
      dispatch(showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      }));
    }
    setUriDownloading("");
  };
  return (
    <Paper sx={{ p: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Date Uploaded
            </TableCell>
            <TableCell>
              Size
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell>
                <Skeleton variant='text' />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' />
              </TableCell>
              <TableCell>
                <Skeleton variant='text' />
              </TableCell>
            </TableRow>
          ) : (
            isEmpty(termsAndConditions) || isNil(termsAndConditions) ? (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                  No channel partner agreements were found for this channel partner
                </TableCell>
              </TableRow>
            ) : (
              termsAndConditions.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell>
                    {doc.name}
                  </TableCell>
                  <TableCell>
                    {DateTime
                      .fromSeconds(doc.created)
                      .setLocale(getDefaultLocale())
                      .toLocaleString(DateTime.DATE_SHORT)}
                  </TableCell>
                  <TableCell>
                    {`${doc.size} bytes`}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      edge="end"
                      aria-label="view"
                      color="inherit"
                      onClick={() => downloadDoc(doc)}
                      disabled={uriDownloading === doc.uri}
                      size="large"
                      sx={{ p: 0 }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

const ContractDetails = () => {
  const { status, channelPartner } = useSelector((state) => state.getChannelPartner);
  const isLoading = status === 'request';
  const contractDetails = channelPartner?.contractDetails ?? [];
  return (
    <Paper sx={{ p: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Emissions Reduction Activity
            </TableCell>
            <TableCell>
              Percentage (%)
            </TableCell>
            <TableCell>
              Date Last Modified
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell>
                <Skeleton variant='text'/>
              </TableCell>
              <TableCell>
                <Skeleton variant='text'/>
              </TableCell>
              <TableCell>
                <Skeleton variant='text'/>
              </TableCell>
            </TableRow>
          ) : contractDetails.map(({ id, percentage, modified, emissionsReductionActivity }) => (
            <TableRow key={id}>
              <TableCell>
                {EMISSION_REDUCTION_ACTIVITIES[emissionsReductionActivity]}
              </TableCell>
              <TableCell>
                {percentage}
              </TableCell>
              <TableCell>
              {DateTime
                .fromSeconds(modified)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const Referrals = ({ channelPartnerId }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { status, referrals } = useSelector((state) => state.getChannelPartnerReferrals);
  // init
  useEffect(() => {
    async function init() {
      if (channelPartnerId) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "admin_sites",
        });
        dispatch(getChannelPartnerReferrals({ accessToken, channelPartnerId }));
      }
    }
    init();
  }, [dispatch, getAccessTokenSilently, channelPartnerId]);

  // clean up
  useEffect(() => () => {
    dispatch(getChannelPartnerReferrals.reset());
  }, [dispatch]);

  return (
    <Paper sx={{ p: 2 }}>
      <ReferralsTable
        referrals={referrals}
        isLoading={status === "request"}
      />
    </Paper>
  );
};

const ChannelPartnerDetails = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { channelPartnerId } = useParams();

  const { channelPartner } = useSelector((state) => state.getChannelPartner);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_sites",
      });
      dispatch(getChannelPartner({ accessToken, channelPartnerId }));
    }
    init();
  }, [dispatch, getAccessTokenSilently, channelPartnerId]);

  useEffect(() => () => {
    dispatch(getChannelPartner.reset());
  }, [dispatch]);

  return (
    <Page py={3} title="Channel Partner Details">
      <Container maxWidth="lg">
        <PageHeader title={channelPartner?.name ?? ""}>
          <Breadcrumb title="Channel Partners" destination={`/admin/channelPartners`} />
        </PageHeader>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom ml={2}>
              Signed Channel Partner Agreements
            </Typography>
            <TermsAndConditionsTable />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom ml={2}>
              Contract Details
            </Typography>
            <ContractDetails />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom ml={2}>
              Referrals
            </Typography>
            <Referrals channelPartnerId={channelPartnerId} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ChannelPartnerDetails;