import getClaimedProjectReports from "./getClaimedProjectReports";
import createCertificate from "./createCertificate";
import patchCertificate from "./patchCertificate";
import deleteCertificate from "./deleteCertificate";

const reducers = {
  getClaimedProjectReports,
  deleteCertificate,
  createCertificate,
  patchCertificate,
};

export default reducers;
