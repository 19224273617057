import React from "react";
import { Typography, Link } from "@mui/material";

const RewattAddressFooter = () => (
  <Typography variant="body1" color="textPrimary" align="center" component="div">
    <Link color="inherit" href="https://rewattpower.com/">
      Rewatt Power, Inc.
    </Link>
    <Typography variant="body2" color="textSecondary">
      1401 1st Street SE, Suite 200 <br />
      Calgary, Alberta <br />
      T2G 2J3 <br />
      <Link color="inherit" href="https://rewattpower.com/">
        www.rewattpower.com
      </Link>
    </Typography>
  </Typography>
);

export default RewattAddressFooter;
