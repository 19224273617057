import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Container,
  Box,
  Switch,
} from "@mui/material";
import Page from "@components/Page";
import { DateTime } from "luxon";
import ScoreCard from "@views/sites/components/ScoreCard";
import PowerIcon from "@mui/icons-material/Power";
import BoltIcon from "@mui/icons-material/Bolt";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AddSitesEditor from "../containers/AddSitesEditor";
import { isEmpty, includes, filter, findIndex, map, find, isNil } from "lodash";
import DisplayStatus from "./DisplayStatus";
import { formatPower } from "@utils/stringHelpers";
import CustomDataGrid from "@components/CustomDataGrid";
import ViewIcon from "@mui/icons-material/Visibility";
import { getDefaultLocale } from "@utils/dateHelpers";
import PageHeader from "@components/PageHeader";
import {
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import { getFullName } from "@utils/stringHelpers";
import { renderCellExpand } from "@components/GridCellExpand";
import { EmailLink } from "@components";

// nb you must wrap this in useMemo
export const getSiteColumns = ({ navigate, putSite = () => {} }) => {
  const stringOps = filter(getGridStringOperators(), {
    value: "contains",
  });
  const numericOps = filter(getGridNumericOperators(), { value: "=" });
  const stringEqOps = filter(getGridStringOperators(), {
    value: "equals",
  });

  return [
    {
      headerName: "Site ID",
      field: "id",
      flex: 0.5,
      filterOperators: numericOps,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Creation Date",
      // NB. this is when the orgAsset was created, not when the asset was created
      field: "created",
      flex: 1,
      hide: true,
      filterable: false,
      valueFormatter: ({ value }) =>
        DateTime.fromSeconds(value)
          .setLocale(getDefaultLocale())
          .toLocaleString(DateTime.DATE_SHORT),
      filterOperators: stringOps,
    },
    {
      headerName: "Site Name",
      field: "name",
      flex: 1,
      renderCell: renderCellExpand,
      filterOperators: stringOps,
    },
    {
      headerName: "Capacity (kW)",
      field: "capacity",
      flex: 0.8,
      valueFormatter: ({ value }) => {
        return (value / 1000).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
      },
      type: "number",
      filterOperators: numericOps,
    },
    {
      headerName: "Location",
      field: "location",
      flex: 1.2,
      renderCell: ({ value: location }) => {
        return (
          <Typography fontWeight="bold" component="div" variant="body2">
            {location.city},{" "}
            <Typography display="inline" variant="body2">
              {location.region}
            </Typography>
            <Typography variant="body2">{location.street}</Typography>
          </Typography>
        );
      },
      filterOperators: stringOps,
    },
    {
      headerName: "Owner",
      field: "siteOwner",
      flex: 1,
      valueGetter: ({ row: site }) => {
        if (site.siteOwner?.external) {
          return getFullName(site.siteOwner.external);
        } else if (site.siteOwner?.individual) {
          return getFullName(site.siteOwner.individual);
        } else {
          return site.siteOwner?.organization?.name || "-";
        }
      },
      renderCell: renderCellExpand,      
      filterOperators: stringOps,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: ({ value }) => <DisplayStatus status={value} />,
      filterOperators: stringEqOps,
    },
    {
      field: "viewAction",
      headerName: "View",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(ev) => {
              const site = params.row;
              putSite({
                site: {
                  id: site.id,
                  name: site.name,
                  mountingType: null,
                  capacity: site.capacity,
                  utility: null,
                  customerId: null,
                  numMeters: null,
                  manufacturers: [],
                  addresses: [
                    {
                      street: site.location.street,
                      city: site.location.city,
                      region: site.location.region,
                      country: "",
                      postal: "",
                      types: ["MAIN"],
                    },
                  ],
                  timeZone: "",
                },
              });
              const url = `/sites/${site.id}`;
              if (navigate) {
                navigate(`/sites/${site.id}`);
              } else {
                const newWindow = window.open(
                  url,
                  "_blank",
                  "noopener,noreferrer"
                );
                if (newWindow) newWindow.opener = null;
              }
            }}
            aria-label="view"
            size="small"
            sx={{ ml: 1 }}
          >
            <ViewIcon />
          </IconButton>
        );
      },
    },
  ];
};

const Sites = ({
  getOrganizationStats,
  getOrganizationStatsResult,
  getSites,
  getSitesResult,
  putSite,
  sitesTableState,
  setSitesTableState,
  subscribeToSiteNotifications,
  unsubscribeFromSiteNotifications,
  getSubscriptions,
  getSubscriptionsResult,
  mutateSubscriptions,
}) => {
  const navigate = useNavigate();

  // get stats, subs on load
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getOrganizationStats({ accessToken });
      getSubscriptions({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getOrganizationStats, getSubscriptions]);

  // org stats
  const { stats } = getOrganizationStatsResult;
  const statsLoading =
    getOrganizationStatsResult.status === "request" ||
    getOrganizationStatsResult.status === "init";

  // subs
  const { subscriptions } = getSubscriptionsResult;

  // site list
  const { sites: initialSites, total } = getSitesResult;
  const sitesLoading =
    includes(["init", "request"], getSitesResult.status) ||
    includes(["init", "request"], getSubscriptionsResult.status);

  // add incidentNotifications
  const sites = map(initialSites, (site) => {
    const subscription = find(
      subscriptions,
      (sub) =>
        sub.type === "SITE" &&
        sub.assetId === parseInt(site.id) &&
        includes(sub.notifications, "INCIDENTS")
    );
    return {
      ...site,
      incidentNotification: !isNil(subscription),
    };
  });

  // add sites action
  const [isAddSitesEditorOpen, setIsAddSitesEditorOpen] = useState(false);
  const addSites = () => {
    setIsAddSitesEditorOpen(true);
  };
  const cancelAddSitesEditor = () => {
    setIsAddSitesEditorOpen(false);
  };

  // after adding a site, we don't want to do this until clicking continue (so don't watch the saga)
  const showNewRows = () => {
    setSitesTableState({
      page: 0,
      sortModel: [
        {
          field: "created",
          sort: "desc",
        },
      ],
      filterModel: {
        items: [],
        linkOperator: "and",
      },
    });
  };

  // get columns for sites table
  const columns = useMemo(() => {
    const columns = getSiteColumns({ navigate, putSite });

    const toggleIncidentNotifications = async (siteId, value) => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      // update the local data immediately
      const newSubscriptions = value
        ? [
            ...subscriptions,
            { assetId: siteId, type: "SITE", notifications: "INCIDENTS" },
          ]
        : filter(subscriptions, (sub) => sub?.assetId !== siteId);
      mutateSubscriptions({ subscriptions: newSubscriptions });

      // send our request
      const toggleSubscription = value
        ? subscribeToSiteNotifications
        : unsubscribeFromSiteNotifications;
      toggleSubscription({
        accessToken,
        siteId,
        notifications: ["INCIDENTS"],
      });
    };

    // payee
    let idx = findIndex(columns, { field: "status" });
    columns.splice(idx, 0, {
      headerName: "Payee",
      field: "payee",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: ({ row: site }) => (
        <EmailLink email={site.payee?.email}>{getFullName(site.payee)}</EmailLink>
      )
    });

    // add incident notification switch
    columns.splice(++idx, 0, {
      headerName: "Incident Notification",
      field: "incidentNotification",
      flex: 0.8,
      filterable: false,
      renderCell: (params) => {
        const site = params.row;
        return (
          <Switch
            color="secondary"
            onChange={(ev, value) =>
              toggleIncidentNotifications(site.id, value)
            }
            checked={site.incidentNotification}
          />
        );
      },
    });

    return columns;
  }, [
    navigate,
    putSite,
    getAccessTokenSilently,
    subscribeToSiteNotifications,
    unsubscribeFromSiteNotifications,
    subscriptions,
    mutateSubscriptions,
  ]);

  // refetch if table state changes
  const { page, pageSize, sortModel, filterModel } = sitesTableState;
  useEffect(() => {
    const fetchRows = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getSites({
        accessToken,
        page: page + 1,
        pageSize: pageSize,
        order: !isEmpty(sortModel)
          ? `${sortModel[0]?.field}:${sortModel[0]?.sort}`
          : undefined,
        search: !isEmpty(filterModel?.items) && !isNil(filterModel?.items[0].value)
          ? `${filterModel.items[0].columnField}:${filterModel.items[0].value}`
          : undefined,
      });
    };
    fetchRows();
  }, [
    page,
    pageSize,
    sortModel,
    filterModel,
    getAccessTokenSilently,
    getSites,
  ]);

  return (
    <Page title="Site List" py={3}>
      <Container maxWidth="lg">
        <PageHeader
          title="Site List"
          action={{
            name: "Add Sites",
            handle: addSites,
          }}
        />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <ScoreCard
                title="Sites Online"
                primary={`${stats.sites.online} Online`}
                secondary={`${stats.sites.total} Sites Total`}
                icon={<PowerIcon />}
                color="blue"
                loading={statsLoading}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ScoreCard
                title="Capacity Online"
                primary={`${formatPower(stats.capacity.online, 1)} Reporting`}
                secondary={`${formatPower(stats.capacity.total, 1)} Total`}
                icon={<BoltIcon />}
                color="green"
                loading={statsLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <div style={{ height: "100%", width: "100%" }}>
                  <CustomDataGrid
                    style={{ border: 0 }}
                    rowHeight={75}
                    autoHeight
                    loading={sitesLoading}
                    rows={sites}
                    columns={columns}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={(sortModel) =>
                      setSitesTableState({ sortModel })
                    }
                    page={page}
                    onPageChange={(page) => setSitesTableState({ page })}
                    pageSize={pageSize}
                    onPageSizeChange={(pageSize) => {
                      if (page * pageSize > total) {
                        setSitesTableState({ page: 1, pageSize });
                      } else {
                        setSitesTableState({ pageSize });
                      }
                    }}
                    rowsPerPageOptions={[10, 20, 50]}
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) =>
                      setSitesTableState({ filterModel })
                    }
                    paginationMode="server"
                    filterMode="server"
                    sortingMode="server"
                    rowCount={total || 0}
                  />
                  <AddSitesEditor
                    open={isAddSitesEditorOpen}
                    cancelAction={cancelAddSitesEditor}
                    showNewRows={showNewRows}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

Sites.propTypes = {
  getOrganizationStats: PropTypes.func.isRequired,
  getOrganizationStatsResult: PropTypes.shape({}).isRequired,
  getSites: PropTypes.func.isRequired,
  getSitesResult: PropTypes.shape({}).isRequired,
  putSite: PropTypes.func.isRequired,
  setSitesTableState: PropTypes.func.isRequired,
  sitesTableState: PropTypes.shape({}).isRequired,
  subscribeToSiteNotifications: PropTypes.func.isRequired,
  unsubscribeFromSiteNotifications: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  getSubscriptionsResult: PropTypes.shape({}).isRequired,
  mutateSubscriptions: PropTypes.func.isRequired,
};

export default Sites;
