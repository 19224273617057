import getProjectReport from "./getProjectReport";
import setProjectReportStatus from "./setProjectReportStatus";
import addProjectReportDocuments from "./addProjectReportDocuments";
import deleteProjectReportDocument from "./deleteProjectReportDocument";
import patchProjectReport from "./patchProjectReport";
import patchProjectReportWithStatus from "./patchProjectReportWithStatus";

export default [
  ...getProjectReport,
  ...setProjectReportStatus,
  ...addProjectReportDocuments,
  ...deleteProjectReportDocument,
  ...patchProjectReport,
  ...patchProjectReportWithStatus,
];
