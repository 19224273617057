import createDevices from "./createDevices";
import createDocuments from "./createDocuments";
import createSite from "./createSite";
import deleteDocument from "./deleteDocument";
import getCustomerProjectSite from "./getCustomerProjectSite";
import getCustomerProjectSites from "./getCustomerProjectSites";
import patchCustomerProjectSite from "./patchCustomerProjectSite";
import patchDevice from "./patchDevice"

const reducers = {
  getCustomerProjectSites,
  createSite,
  getCustomerProjectSite,
  patchCustomerProjectSite,
  patchDevice,
  createDevices,
  createDocuments,
  deleteDocument,
};

export default reducers;
