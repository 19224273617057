import React, { useEffect } from 'react';
import Page from '@components/Page';
import { Box, Chip, Container, Paper } from '@mui/material';
import PageHeader from '@components/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getReferrals } from '../actions';
import CustomDataGrid from 'components/CustomDataGrid';
import { renderCellExpand } from 'components/GridCellExpand';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';
import { get } from 'lodash';
import { APPLICATION_STATUSES, APPLICATION_STATUS_COLORS } from '@views/programs/components/Applications';
import { getFullName } from '@utils/stringHelpers';

export const ReferralsTable = ({ referrals, isLoading }) => {
  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "projectTitle",
      headerName: "Project",
      flex: 1,
      valueGetter: ({ row }) => (row.program?.name || row.customerProject?.title) ?? "",
      renderCell: renderCellExpand,
    },
    {
      field: "siteName",
      headerName: "Site Name",
      flex: 1,
      valueGetter: ({ row: { asset } }) => asset.name || "-",
      renderCell: renderCellExpand,
    },
    {
      field: "siteOwnerName",
      headerName: "Site Owner",
      flex: 1,
      valueGetter: ({ row: { asset: { siteOwner: { external, individual, organization } } }}) => {
        if (external) {
          return getFullName(external);
        }
        if (individual) {
          return getFullName(individual);
        }
        if (organization) {
          return organization.name;
        }
        return '-';
      },
      renderCell: renderCellExpand,
    },
    {
      field: "created",
      headerName: "Date Submitted",
      flex: 1,
      valueGetter: ({ row: application }) => {
        const duration = humanizeDuration(
          DateTime.fromMillis(application.created * 1000)
            .diffNow()
            .toMillis(),
          {
            units: ["mo", "d", "h"],
            round: true,
            largest: 1,
          }
        );
        return `${duration} ago`;
      },
      renderCell: renderCellExpand,
      sortComparator: (v1, v2, param1, param2) =>
        param2.api.getCellValue(param2.id, "created") - param1.api.getCellValue(param1.id, "created"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 175,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Chip
            label={get(APPLICATION_STATUSES, status, status)}
            variant="outlined"
            sx={{
              borderColor: get(APPLICATION_STATUS_COLORS, `${status}.color`),
              color: get(APPLICATION_STATUS_COLORS, `${status}.color`),
            }}
          />
        );
      },
    },
  ];

  return (
    <Box height="100%" width="100%">
      <CustomDataGrid
        style={{ border: 0 }}
        autoHeight
        loading={isLoading}
        rows={referrals ?? []}
        columns={columns}
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        localeText={{
          noRowsLabel: "No referrals",
        }}
      />
    </Box>
  );
}

const Referrals = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { organization } = useSelector((state) => state.getOrganization);
  const { referrals, status } = useSelector((state) => state.getReferrals);
  useEffect(() => {
    const init = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getReferrals({ accessToken, channelPartnerId: organization.id }));
    };
    init();
  }, [dispatch, getAccessTokenSilently, organization.id]);

  return (
    <Page py={3} title="Referrals">
      <Container maxWidth="lg">
        <PageHeader
          title="Referrals"
          subtitle="List of all your customers' applications that used your referal code."
        />
        <Paper sx={{ mt: 3 }}>
          <ReferralsTable referrals={referrals} isLoading={status === "request"} />
        </Paper>
      </Container>
    </Page>
  );
};

export default Referrals;
