import React from "react";
import PropTypes from "prop-types";
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import SiteProfileForm from "./SiteProfileForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { siteSchema, withContactOwner } from "./SiteProfileFormSchema";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SiteProfileEditor = ({ open, onClose, onSave, siteProfile }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(withContactOwner(siteSchema)),
    defaultValues: siteProfile,
  });

  // submit
  const onSubmit = (data) => {
    onSave(data);

    // make sure our form has the proper default values
    methods.reset(data);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          backgroundColor: (theme) => theme.palette.background.dark,
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Site Profile
          </Typography>
          <Button color="inherit" onClick={() => methods.handleSubmit(onSubmit)()}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <SiteProfileForm />
      </FormProvider>
    </Dialog>
  );
};

SiteProfileEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  siteProfile: PropTypes.shape({}),
};

export default SiteProfileEditor;
