import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

// invites
const addChannelPartnerInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_CHANNEL_PARTNER_INVITE"
);
const getChannelPartnerInvites = createRoutine("GET_CHANNEL_PARTNER_INVITES");
const resendChannelPartnerInvite = createRoutine("RESEND_CHANNEL_PARTNER_INVITE");
const deleteChannelPartnerInvite = createRoutine("DELETE_CHANNEL_PARTNER_INVITE");

// channel partners
const getChannelPartners = createRoutine("GET_CHANNEL_PARTNERS");
const getChannelPartner = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_CHANNEL_PARTNER"
);

const getChannelPartnerReferrals = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_CHANNEL_PARTNER_REFERRALS"
);

export {
  addChannelPartnerInvite,
  getChannelPartnerInvites,
  resendChannelPartnerInvite,
  deleteChannelPartnerInvite,
  getChannelPartners,
  getChannelPartner,
  getChannelPartnerReferrals,
};
