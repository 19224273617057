import getBuyerInvites from "./getBuyerInvites";
import getOrganizations from "./getOrganizations";
import addBuyerInvite from "./addBuyerInvite";
import getAdminPurchaseRequests from "./getAdminPurchaseRequests";
import updatePurchaseRequestStatus from "./updatePurchaseRequestStatus";
import getProjectReportsForStatements from "./getProjectReportsForStatements";
import getProjectReportForStatements from "./getProjectReportForStatements";
import createPayeeStatement from "./createPayeeStatement";
import downloadEft from "./downloadEft";
import sendPayeeStatements from "./sendPayeeStatements";
import getPurchaseRequestForProjectReport from "./getPurchaseRequestForProjectReport";

const reducers = {
  getBuyerInvites,
  getOrganizations,
  addBuyerInvite,
  getAdminPurchaseRequests,
  updatePurchaseRequestStatus,
  getProjectReportsForStatements,
  getProjectReportForStatements,
  createPayeeStatement,
  downloadEft,
  sendPayeeStatements,
  getPurchaseRequestForProjectReport,
};

export default reducers;
