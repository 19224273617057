import { call, put, takeLatest, all } from "redux-saga/effects";
import { uploadFiles } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { some, has, join, map, values } from "lodash";

const callApi = async (accessToken, filespec) => {
  const body = new FormData();
  body.append("file", filespec.file);
  body.append("isPublic", filespec.public === true);

  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/files`,
    body,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export function* uploadFilesWorker(action) {
  yield put(uploadFiles.request());

  const { accessToken, filespecs } = action.payload;

  const callEffects = filespecs.map((filespec) => {
    return call(function* () {
      try {
        const response = yield call(callApi, accessToken, filespec);
        const { uri } = response.data;
        return { uri, ...filespec };
      } catch (err) {
        let message;
        if (err.response) {
          // client received an error response (5xx, 4xx)
          logger("weedle:error")("Error uploading files: ", err.response);
          if (err.response.data?.validationErrors) {
            message = join(values(err.response.data.validationErrors), "; ");
          } else {
            message = err.response.data.reason || err.response.data.message;
          }
        } else if (err.request) {
          // client never received a response, or request never left
          logger("weedle:error")("Error uploading files: ", err.request);
          message = err.message;
        } else {
          // anything else
          logger("weedle:error")("Error uploading files: ", err);
          message = err.message;
        }
        message = `Unable to upload files: ${message}`;
        return { error: message };
      }
    });
  });

  const uploadedFilespecs = yield all(callEffects);

  // fail the whole thing if we get any errors, no biggie
  if (some(uploadedFilespecs, f => has(f, "error"))) {
    const errors = map(uploadedFilespecs, f => f.error);
    const message = join(errors, "; ");
    yield put(uploadFiles.failure({ errors }));
    yield put(showAppMessage({ severity: "error", message }));
  } else {
    yield put(uploadFiles.success({ filespecs: uploadedFilespecs }));
  }
}

export function* uploadFilesWatcher() {
  yield takeLatest(uploadFiles.TRIGGER, uploadFilesWorker);
}

export default [uploadFilesWatcher];
