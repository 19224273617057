import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const application = createRoutineCreator([
  "INIT",
  "RESET",
  "SAVE",
  "SAVE_ASSET_DETAILS",
])("CUSTOMER_PROJECT_APPLY");

const submitProjectApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
  "CLEAR_ERROR",
  "LOAD_SAVED"
])("SUBMIT_CUSTOMER_PROJECT_APPLICATION");

export {
  application,
  submitProjectApplication,
};
