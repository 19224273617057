import React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDateRangePicker = ({
  value: dateRange,
  onChange: setDateRange,
}) => {
  const onChange = (value, label) => {
    if (value.isValid) {
      setDateRange({ ...dateRange, [label]: value });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ "& .MuiFormControl-root": { ml: 2 } }}
    >
      <DatePicker
        label="Start"
        value={dateRange.start}
        onChange={(value) => onChange(value, "start")}
        renderInput={(params) => (
          <TextField required variant="standard" {...params} />
        )}
        disableMaskedInput
      />
      <DatePicker
        label="End"
        value={dateRange.end}
        onChange={(value) => onChange(value, "end")}
        renderInput={(params) => (
          <TextField required variant="standard" {...params} />
        )}
        disableMaskedInput
      />
    </Box>
  );
};

CustomDateRangePicker.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.instanceOf(DateTime).isRequired,
    end: PropTypes.instanceOf(DateTime).isRequired,
  }),
  // called with ({ start: DateTime, end: DateTime })
  onChange: PropTypes.func.isRequired,
};

export default CustomDateRangePicker;
