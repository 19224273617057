import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DocumentsEditor from "../components/DocumentsEditor";
import { patchAppSiteDocuments } from "../actions";

const mapStateToProps = ({ patchAppSiteDocuments }) => ({
  patchAppSiteDocumentsResult: patchAppSiteDocuments,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchAppSiteDocuments,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsEditor);
