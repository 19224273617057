import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  CircularProgress,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import SiteProfileForm from "./SiteProfileForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { extractSiteProfile } from "./SiteProfile";
import {
  siteSchema,
  withSiteProfile,
} from "@views/siteOwner/components/SiteProfileFormSchema";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SiteProfileEditor = ({
  open,
  onClose,
  onSave,
  siteProfile,
  patchAppSiteProfile,
  patchAppSiteProfileResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(withSiteProfile(siteSchema)),
    defaultValues: siteProfile, // this gets good values first time (ie. after load) (see parent logic)
  });

  // patch application on BE
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    patchAppSiteProfile({ accessToken, siteProfile: data });
  };

  // close and cleanup on success
  useEffect(() => {
    if (patchAppSiteProfileResult.status === "success") {
      const siteProfile = extractSiteProfile(
        patchAppSiteProfileResult.application
      );

      // ie. update component state in SiteProfile
      onSave(siteProfile);

      // make sure our form has the proper default values
      methods.reset(siteProfile);
    }
    // "methods" get changed on re-render, so ignore dependency (otherwise circular ref)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchAppSiteProfileResult, onSave]);

  const isSubmitting = patchAppSiteProfileResult.status === "request";
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Site Profile
          </Typography>
          <LoadingButton
            color="inherit"
            onClick={() => methods.handleSubmit(onSubmit)()}
            loading={isSubmitting}
            loadingIndicator={
              <CircularProgress sx={{ color: "common.white" }} size={16} />
            }
          >
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <FormProvider {...methods}>
        <SiteProfileForm />
      </FormProvider>
    </Dialog>
  );
};

SiteProfileEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  siteProfile: PropTypes.shape({}),

  patchAppSiteProfile: PropTypes.func.isRequired,
  patchAppSiteProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default SiteProfileEditor;
