import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import InviteQualifiedSites from "../components/InviteQualifiedSites";
import { getQualifiedSites, inviteQualifiedSites } from "../actions";

const mapStateToProps = ({
  getQualifiedSites: getQualifiedSitesResult,
  inviteQualifiedSites: inviteQualifiedSitesResult,
}) => ({
  getQualifiedSitesResult,
  inviteQualifiedSitesResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getQualifiedSites,
      inviteQualifiedSites,
      resetInviteQualifiedSites: () => inviteQualifiedSites.reset(),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteQualifiedSites);
