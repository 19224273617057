import { call, put, takeLatest } from "redux-saga/effects";
import { getCustomerProjectChart } from "../actions";
import axios from "axios";
import { fillData } from "@utils/chartHelpers";
import { wmTokWh } from "@utils/energyHelpers";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, customerProjectId, startDate, endDate) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/chart`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        start: startDate.toISODate(),
        end: endDate.toISODate(),
      },
    }
  );
  return response;
};

// nb in org context, a chart does not have a timezone in its dates
// export for testing
export const transform = (data, granularity, dateRange) => {
  return [
    {
      stat: "generation",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date, // yyyy-MM-dd HH:mm:ss
          y: datum.generation ? wmTokWh(datum.generation) : null,
        })),
        granularity,
        dateRange
      }),
    },
    {
      stat: "export",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date,
          y: datum.export ? wmTokWh(datum.export) : null,
        })),
        granularity,
        dateRange
      }),
    },
    {
      stat: "consumption",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date,
          y: datum.consumption ? wmTokWh(datum.consumption) : null,
        })),
        granularity,
        dateRange
      }),
    },
    {
      stat: "emissionReductions",
      data: fillData({
        data: data.map((datum) => ({
          x: datum.date,
          y: datum.emissionReductions,
        })),
        granularity,
        dateRange
      }),
    },
  ];
};

export function* getCustomerProjectChartWorker(action) {
  yield put(getCustomerProjectChart.request());

  const { accessToken, customerProjectId, startDate, endDate } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      customerProjectId,
      startDate,
      endDate
    );
    const { data, granularity } = response.data;
    yield put(getCustomerProjectChart.success({ data: transform(data, granularity, [startDate, endDate]), granularity }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to get customer project chart: ${message}`;
    yield put(getCustomerProjectChart.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getCustomerProjectChartWatcher() {
  yield takeLatest(getCustomerProjectChart.TRIGGER, getCustomerProjectChartWorker);
}

export default [getCustomerProjectChartWatcher];
