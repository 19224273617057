import { isEmpty } from "lodash";
import * as yup from "yup";
import { phoneRegExp } from "utils/errorHelpers";

// basic schema, can't edit siteOwner when it's an org
const siteSchema = yup.object().shape({
  name: yup.string().max(255, "Too long.").required("Site name is required."),

  address: yup.object().shape({
    street: yup
      .string()
      .max(60, "Too long.")
      .required("Street name is required."),
    city: yup.string().max(60, "Too long.").required("City is required."),
    region: yup.string().max(60, "Too long.").required("Region is required."),
    country: yup.string().max(60, "Too long.").required("Country is required."),
    postal: yup
      .string()
      .matches(
        /^[a-z0-9][a-z0-9\- ]{2,10}[a-z0-9]$/i,
        "Invalid postal code format."
      ), // sanity-only
  }),
});

// site owner can't edit these
const withSiteProfile = (schema) =>
  schema.shape({
    capacity: yup.number().required().positive(),
    completionDate: yup.date().required(), // allows iso strings and Date instances
    mountingType: yup.string(),
  });

const withContactOwner = (schema) =>
  schema.shape({
    firstName: yup
      .string()
      .max(60, "Too long.")
      .required("First name is required."),
    lastName: yup
      .string()
      .max(60, "Too long.")
      .required("Last name is required."),
    email: yup.string().email().required("Email is required."),
    phone: yup
      .string()
      .trim()
      .transform((value) => (value === "" ? undefined : value))
      .min(10, "Too short.")
      .max(20, "Too long.")
      .test("phone", "Phone number is not valid.", (value) => {
        if (!isEmpty(value)) {
          return phoneRegExp.test(value);
        }
        return true;
      })
      .required("Phone number is required."),
  });

export {
  siteSchema,
  withContactOwner,
  withSiteProfile,
};
