import getLiveSite from "./getLiveSite";
import getLiveStats from "./getLiveStats";
import getLiveChartData from "./getLiveChartData";
import getLiveEnergyToday from "./getLiveEnergyToday";

const reducers = {
  getLiveSite,
  getLiveStats,
  getLiveChartData,
  getLiveEnergyToday,
};

export default reducers;
