import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { map, includes, find, sortBy, reverse } from "lodash";
import { getFullName } from "@utils/stringHelpers";
import { round } from "@utils/numberHelpers";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import CustomDataGrid from "components/CustomDataGrid";
import { renderCellExpand } from "@components/GridCellExpand";

const MAX_WEDGES = 7;

const baseChartOptions = (theme) => ({
  dataLabels: {
    style: {
      fontSize: 12,
      fontFamily: theme.typography.fontFamily,
    },
  },
  legend: {
    position: "bottom",
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
  tooltip: {
    style: {
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
    },
    z: {
      formatter: (val) => `${round(val, 3)} credits`,
    },
  },
  colors: [
    theme.palette.secondary.dark,
    theme.palette.success.light,
    theme.palette.warning.light,
    theme.palette.error.light,
    theme.palette.info.light,
    theme.palette.primary.light,  
    theme.palette.grey[500],
  ],
});

const getSiteOwner = (siteOwner) =>
  siteOwner?.organization
    ? siteOwner.organization.name
    : getFullName(siteOwner?.contact || siteOwner?.external);

const groupData = (contributions) => {
  const data = contributions.map(({ organizationAsset: { name: label }, vintages }) => {
    const credits = vintages.reduce((total, { credits }) => total + credits, 0);
    return { label, credits };
  });
  let ordered = reverse(sortBy(data, "credits"));
  if (ordered.length > MAX_WEDGES) {
    const mainContribs = ordered.slice(0, MAX_WEDGES - 1);
    const otherContribs = ordered.slice(MAX_WEDGES - 1);
    const leftoverCredits = otherContribs.reduce((total, { credits }) => total + credits, 0);
    mainContribs.push({ label: "Other", credits: leftoverCredits });
    ordered = mainContribs;
  }
  return {
    labels: ordered.map(({ label }) => label),
    data: ordered.map(({ credits }) => credits),
  };
};

const ContributionsPanel = ({ projectReport }) => {
  const theme = useTheme();

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([{ field: "id", sort: "desc" }]);
  const rows = map(projectReport.contributions, (contribution) => {
    const { organizationAsset: site } = contribution;
    const address = find(site.addresses, (address) => includes(address.types, "MAIN"));
    const credits = contribution.vintages.reduce((total, { credits }) => total + credits, 0);
    return {
      id: site.id,
      name: site.name,
      capacity: site.capacity,
      location: `${address.city}, ${address.region}`,
      owner: getSiteOwner(site.siteOwner),
      contribution: projectReport.totalEnvironmentalCredits
        ? (credits / projectReport.totalEnvironmentalCredits) * 100
        : 0,
      credits,
    };
  });

  const columns = [
    {
      field: 'id',
      headerName: 'Site ID',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'name',
      headerName: 'Site Name',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'capacity',
      headerName: 'Capacity (kW)',
      flex: 1,
      type: 'number',
      valueFormatter: ({ value }) => round(value / 1000, 3)
    },
    {
      field: 'location',
      headerName: 'Address',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'contribution',
      headerName: 'Contribution (%)',
      flex: 1,
      type: 'number',
    },
    {
      field: 'credits',
      headerName: 'Credits',
      flex: 1,
      type: 'number',
    },
  ];

  const chartData = groupData(projectReport.contributions);

  const chartOptions = {
    ...baseChartOptions(theme),
    labels: chartData.labels,
  };

  return (
    <>
      <Typography variant="h3" gutterBottom ml={2}>
        Site Contributions
      </Typography>
      <Paper>
        <Box>
          <CustomDataGrid
            autoHeight
            disableSelectionOnClick
            style={{ border: 0 }}
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
          />
        </Box>
        <Box p={3}>
          <ReactApexChart
            options={chartOptions}
            series={chartData.data}
            type="donut"
            height={350}
          />
        </Box>
      </Paper>
    </>
  );
};

ContributionsPanel.propTypes = {
  projectReport: PropTypes.shape({
    contributions: PropTypes.arrayOf(
      PropTypes.shape({
        vintages: PropTypes.arrayOf(
          PropTypes.shape({}).isRequired
        ).isRequired,
        organizationAsset: PropTypes.shape({}).isRequired,
      }).isRequired
    ).isRequired,
    totalEnvironmentalCredits: PropTypes.number.isRequired,
  }).isRequired
};

export default ContributionsPanel;
