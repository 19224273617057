import React from "react";
import { Typography, Paper, Grid, Box, SvgIcon, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { ReactComponent as SunIcon } from "../assets/sun.svg";
import { ReactComponent as CO2Icon } from "../assets/co2.svg";
import { ReactComponent as TreesIcon } from "../assets/trees.svg";
import { styled } from "@mui/material/styles";
import { upperFirst } from "lodash";
import { round } from "@utils/numberHelpers";
import { wmTokWh } from "@utils/energyHelpers";
import { useSelector } from "react-redux";

// see for reference https://www.cer-rec.gc.ca/en/data-analysis/energy-markets/provincial-territorial-energy-profiles/provincial-territorial-energy-profiles-canada.html#:~:text=The%20greenhouse%20gas%20intensity%20of,e%2FkWh)%20in%202020.
// using national average grid intensity of 110 g/kWh
const calculateEmissionReductions = wattMinutes => wmTokWh(wattMinutes) * 110 / 1000000

// see https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references
// use 0.060 metric ton CO2 per urban tree planted
const calculateTressPlanted = wattMinutes => calculateEmissionReductions(wattMinutes) / 0.06;

const Statistic = styled(({ value, ...other }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    mt={1}
    p={1}
    backgroundColor="primary.main"
    {...other}
  >
    <Typography variant="h3" color="common.white" textAlign="center">
      {value}
    </Typography>
  </Box>
))(({ theme, side }) => {
  return side === "center"
    ? {
        borderLeft: "1px solid white",
        borderRight: "1px solid white",
      }
    : {
        [`borderBottom${upperFirst(side)}Radius`]: theme.shape.borderRadius,
      };
});

const GenerationCard = () => {
  const { lifetimeEnergy, firstReportDate } = useSelector(
    (state) => state.getLiveStats.stats
  );
  const totalEmissionsReduction = lifetimeEnergy ? calculateEmissionReductions(lifetimeEnergy) : 0;
  const totalTrees = lifetimeEnergy ? calculateTressPlanted(lifetimeEnergy) : 0;

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" p={2}>
            <Typography variant="h3">Impact to Date</Typography>
            <Typography color="text.secondary">
              Since{" "}
              {DateTime.fromISO(firstReportDate)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center" height="100%">
            <Typography variant="h5">SOLAR GENERATION</Typography>
            <SvgIcon
              component={SunIcon}
              inheritViewBox
              sx={{ width: 86, height: 81 }}
            />
            <Statistic side="left" value={`${round(wmTokWh(lifetimeEnergy), 0).toLocaleString()} kWh`} />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center" height="100%">
            <Typography variant="h5">EMISSIONS REDUCED</Typography>
            <SvgIcon
              component={CO2Icon}
              inheritViewBox
              sx={{ width: 76, height: 81 }}
            />
            <Statistic side="center" value={`${round(totalEmissionsReduction, 0).toLocaleString()} tCO2e`} />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack alignItems="center" height="100%">
            <Typography variant="h5">TREES PLANTED</Typography>
            <SvgIcon
              component={TreesIcon}
              inheritViewBox
              sx={{ width: 81, height: 81 }}
            />
            <Statistic side="right" value={round(totalTrees, 0).toLocaleString()} />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default GenerationCard;
