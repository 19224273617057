import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "@components/Page";
import { Container, Typography } from "@mui/material";
import { includes, isNil, isEmpty, find } from "lodash";
import PageLoader from "@components/PageLoader";
import { useNext } from "./useNextPrev";

const Start = ({
  getUserProfile,
  getUserProfileResult,
  getProgram,
  getProgramResult,
  initApplication,
  application,
  getOrganization,
  getOrganizationResult,
  saveSiteOwner,
  getMyApplications,
  getMyApplicationsResult,
}) => {
  const navigate = useNavigate();
  const next = useNext();

  // has programId from welcome page (set in onRedirectCallback)
  const { state } = useLocation();
  const programId = parseInt(state?.programId) || application.programId;

  const { user, getAccessTokenSilently } = useAuth0();

  // get user profile
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getUserProfile({ accessToken, userId: user.sub });
    }

    fetchUserProfile();
  }, [
    navigate,
    user,
    getAccessTokenSilently,
    getUserProfile,
    getUserProfile.status,
  ]);

  // fetch program info
  useEffect(() => {
    // public endpoint
    getProgram({ programId });
  }, [getProgram, programId]);

  // fetch org if we have it
  const { userProfile } = getUserProfileResult;
  const hasOrg = userProfile?.organizationId > 0;
  useEffect(() => {
    async function fetchOrganization() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getOrganization({ accessToken });
    }

    if (userProfile?.organizationId > 0) {
      fetchOrganization();
    }
  }, [userProfile?.organizationId, getAccessTokenSilently, getOrganization]);

  // if isIndividualOwner, get their submitted applications
  const { applications } = getMyApplicationsResult;
  useEffect(() => {
    async function fetchApplications() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getMyApplications({ accessToken });
    }
    if (userProfile?.isIndividualOwner && isEmpty(applications)) {
      fetchApplications();
    }
  }, [
    getAccessTokenSilently,
    getMyApplications,
    applications,
    userProfile?.isIndividualOwner,
  ]);

  // once userProfile is loaded, we can check for terms and personal
  useEffect(() => {
    if (!isEmpty(userProfile)) {
      // todo: don't re-init if we did a bunch of prevs
      initApplication({
        programId,
        userId: user.sub,
        email: user.email,
      });

      // legacy user who missed out on supplying personal info
      const missingPersonal =
        isNil(userProfile.givenName) ||
        isNil(userProfile.familyName) ||
        isNil(userProfile.phone);
      const missingTerms = !userProfile.acceptedTermsAndConditions;

      const missingOwnerType = !hasOrg && isNil(userProfile.isIndividualOwner);

      // nb. we must return to this page, after visiting either of the first 2 (terms and personal)
      if (missingTerms) {
        next("/apply/terms");
      } else if (missingPersonal) {
        next("/apply/personal");
      } else if (missingOwnerType) {
        next("/apply/ownertype");
      }
    }
  }, [initApplication, programId, user, userProfile, hasOrg, next]);

  // if we hav an org already, we can move on to ownership
  useEffect(() => {
    if (
      hasOrg &&
      userProfile?.organizationId === getOrganizationResult.organization?.id
    ) {
      saveSiteOwner({
        siteOwner: {
          ownerType: "ORGANIZATION",
        },
      });
      next("/apply/siteowner");
    }
  }, [
    hasOrg,
    next,
    getOrganizationResult.organization?.id,
    saveSiteOwner,
    userProfile?.organizationId,
  ]);

  // if we're individual, can only submit to the same program once, otherwise most info is already entered
  useEffect(() => {
    if (
      userProfile?.isIndividualOwner &&
      getMyApplicationsResult.status === "success"
    ) {
      // already submit to _this_ program?
      const submittedApplication = find(applications, { programId });

      if (submittedApplication) {
        const { id } = submittedApplication;

        // populate our application with some basics, so we can show thank you
        initApplication({
          id,
          programId,
          userId: user.sub,
          email: user.email,
        });
        navigate("/apply/thankyou");
      } else if (!isEmpty(applications)) {
        // at this point we know this is for a different program
        // we will resubmit without site id, but surge can figure that out
        initApplication({
          programId,
          userId: user.sub,
          email: user.email,
          siteInfo: {
            site: {
              documents: [],
              exists: true, // hint for resubmission of same site to a different program
            },
          },
          siteOwner: {
            ownerType: "INDIVIDUAL",
          },
        });

        // now navigate
        if (getProgramResult.program.type === "ENVIRONMENTAL_CREDITS") {
          next("/apply/docs");
        } else {
          next("/apply/programterms");
        }
      } else {
        initApplication({
          programId,
          userId: user.sub,
          email: user.email,
          siteOwner: {
            ownerType: "INDIVIDUAL",
          },
        });
        navigate("/apply/opstatus");
      }
    }
  }, [
    application.siteOwner.ownerType,
    applications,
    getMyApplicationsResult.status,
    getProgramResult.program?.type,
    programId,
    userProfile?.isIndividualOwner,
    userProfile?.address,
    user,
    next,
    navigate,
    initApplication,
    saveSiteOwner,
  ]);

  const isError =
    includes(["failure"], getProgramResult.status) ||
    includes(["failure"], getUserProfileResult.status) ||
    (hasOrg && includes(["failure"], getOrganizationResult.status)) ||
    (userProfile?.isIndividualOwner &&
      includes(["failure"], getMyApplicationsResult.status));

  return (
    <Page title="Start" py={3}>
      <Container maxWidth="lg">
        {!isError && <PageLoader message="Just a moment..." />}
        {isError && (
          <Typography variant="h4">
            Sorry we had a problem starting your application. Please try again.
          </Typography>
        )}
      </Container>
    </Page>
  );
};

Start.propTypes = {
  saveSiteOwner: PropTypes.func.isRequired,
  getProgram: PropTypes.func.isRequired,
  getProgramResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  getUserProfile: PropTypes.func.isRequired,
  getUserProfileResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  initApplication: PropTypes.func.isRequired,
  application: PropTypes.shape({}).isRequired,
  getOrganization: PropTypes.func.isRequired,
  getOrganizationResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  getMyApplications: PropTypes.func.isRequired,
  getMyApplicationsResult: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default Start;
