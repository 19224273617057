import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreateProgramEditor from "../components/CreateProgramEditor";
import { createProgram } from "../actions";

const mapStateToProps = ({ createProgram: createProgramResult }) => ({
  createProgramResult,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      createProgram,
    },
    dispatch
  ),
  resetProgram: () => {
    dispatch(createProgram.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProgramEditor);
