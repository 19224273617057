import React from "react";
import { Collapse, AlertTitle, Alert, Button } from "@mui/material";

const SectionWarning = ({ open, targetRef, title, description }) => (
  <Collapse in={open}>
    <Alert
      severity="warning"
      variant="filled"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            targetRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          View
        </Button>
      }
      sx={{
        mt: 1,
        "& .MuiAlert-action": {
          alignItems: "center",
        },
      }}
    >
      <AlertTitle color="common.white">{title}</AlertTitle>
      {description}
    </Alert>
  </Collapse>
);

export default SectionWarning;
