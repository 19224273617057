import { useCallback } from "react";
import { useDispatch } from "react-redux";

const useAppMessage = () => {
  const dispatch = useDispatch();
  const func = ({ message, severity, ...other }) => {
    dispatch({
      type: "SHOW_APP_MESSAGE",
      payload: {
        severity,
        message,
        ...other,
      },
    });
  };
  return useCallback(func, [dispatch]);
};

export default useAppMessage;
