import { call, put, takeLatest } from "redux-saga/effects";
import { patchDevice } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, payload, customerProjectId, siteId, deviceId) => await axios.patch(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites/${siteId}/devices/${deviceId}`,
  payload,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* patchDeviceWorker(action) {
    yield put(patchDevice.request());

    const { accessToken, payload, customerProjectId, siteId, deviceId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, payload, customerProjectId, siteId, deviceId);
    const device = response.data;
    yield put(patchDevice.success({ device, accessToken, customerProjectId, siteId }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Error patching site: ${message}`;
    yield put(patchDevice.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchDeviceWatcher() {
  yield takeLatest(patchDevice.TRIGGER, patchDeviceWorker);
}

export default [patchDeviceWatcher];
