import React from "react";
import { Manufacturer as BaseManufacturer } from "@addSites/manufacturer";
import { StepNav } from "@views/addSites";
import StepLayout from "./StepLayout";

const Manufacturer = (props) => {
  return <BaseManufacturer
    stepLayout={StepLayout}
    {...props}
    StepNav={StepNav}
  />;
};

export default Manufacturer;
