import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";

/**
 * @todo ensure these instructions are correct
 * @returns
 */
const SwiftInstructionPanel = () => (
  <Box mt={4} pt={4} borderTop={1} borderColor="divider">
    <Typography variant="h4" fontWeight="bold" gutterBottom>
      Instructions to Grant Rewatt Access
    </Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Test the connection"
          secondary="Input the serial numbers/charger ID(s) you have shared with Rewatt in the field below. Separate each charger ID with a comma if you have more than one."
        />
      </ListItem>
    </List>
  </Box>
);

export default SwiftInstructionPanel;
