import React from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  Paper,
  TextField,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import Page from "@components/Page";
import { useForm, Controller } from "react-hook-form";
import { isNil } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Navigation from "@components/Navigation";
import { usePrev, useNext } from "./useNextPrev";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FundingSourceComboBox from "@views/site/components/details/FundingSourceComboBox";
import { saveSiteDetails } from "../actions";

const schema = Yup.object().shape({
  siteName: Yup.string()
    .max(60, "Too long.")
    .required("Site name is required."),
  completionDate: Yup.date().required(), // allows iso strings and Date instances
  capacity: Yup.number().required().positive(),
  hasReceivedGrants: Yup.bool().nullable().required("You must declare if you have received a grant."),
  fundingSource: Yup.string(),
});

const SiteDetails = () => {
  const dispatch = useDispatch();

  const program = useSelector((state) => state.getProgram.program);
  const siteInfo = useSelector((state) => state.application.siteInfo);

  const prev = usePrev();
  const next = useNext();

  const { handleSubmit, errors, control, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      siteName: siteInfo?.site?.name || "",
      completionDate: siteInfo?.site?.completionDate
        ? DateTime.fromISO(siteInfo.site.completionDate)
        : "", // iso format "2017-09-29"
      capacity: siteInfo?.site?.capacity || "", // watts
      hasReceivedGrants: siteInfo.site?.details?.hasReceivedGrants,
      fundingSource: siteInfo.site?.details?.fundingSource || "",
    },
  });

  const onSubmit = (data) => {
    const site = {
      name: data.siteName,
      capacity: data.capacity,
      completionDate: DateTime.fromJSDate(data.completionDate).toISODate(),
      details: {
        hasReceivedGrants: data.hasReceivedGrants,
        fundingSource: data.fundingSource,
      },
    };

    dispatch(saveSiteDetails({ site }));
    next("/apply/sitelocation");
  };

  const hasReceivedGrants = watch("hasReceivedGrants");
  return (
    <Page title="Contact Info" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              {program.name} Registration
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Site Information
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <TextField
                        label="Site Name"
                        variant="standard"
                        fullWidth
                        error={!isNil(errors.siteName?.message)}
                        helperText={errors.siteName?.message}
                        required
                        onChange={onChange}
                        value={value}
                        color="secondary"
                      />
                    )}
                    control={control}
                    name="siteName"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    render={({ onChange, value }) => (
                      <DatePicker
                        label="Installation Date"
                        value={DateTime.fromISO(value)}
                        onChange={onChange}
                        minDate={siteInfo?.isBuilt ? DateTime.fromISO("2000-01-01") : DateTime.now()}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="standard"
                            fullWidth
                            {...params}
                            error={!isNil(errors.completionDate?.message)}
                            helperText={errors.completionDate?.message}
                          />
                        )}
                        disableMaskedInput
                      />
                    )}
                    control={control}
                    name="completionDate"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    render={({ onChange, value }) => {
                      return (
                        <TextField
                          label="Capacity (Watts)"
                          variant="standard"
                          fullWidth
                          error={!isNil(errors.capacity?.message)}
                          helperText={errors.capacity?.message}
                          required
                          onChange={onChange}
                          value={value}
                          color="secondary"
                          type="number"
                        />
                      );
                    }}
                    control={control}
                    name="capacity"
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sm={12}>
                  <FormControl
                    component="fieldset"
                    error={!isNil(errors.hasReceivedGrants?.message)}
                  >
                    <FormLabel>
                      <Typography fontWeight="bold" mt={5}>
                        I attest that <em>no</em> funding/grants were received from any third-parties that may take ownership of offsets generated from this site
                      </Typography>
                    </FormLabel>
                    <Controller
                      render={({ onChange, value }) => (
                        <RadioGroup
                          aria-label="Received grants"
                          name="hasReceivedGrants"
                          value={value}
                          onChange={(event) => {
                            onChange(event.target.value === "true");
                          }}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="I have not received a grant"
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="I have received a grant"
                          />
                        </RadioGroup>
                      )}
                      control={control}
                      name="hasReceivedGrants"
                    />
                    {!isNil(errors.hasReceivedGrants?.message) && (<FormHelperText>{errors.hasReceivedGrants.message}</FormHelperText>)}
                  </FormControl>
                </Grid>
              </Grid>
              {hasReceivedGrants && (
                <Grid container sx={{ mt: 1 }}>
                  <Grid item sm={12}>
                    <Controller
                      render={({ onChange, value }) => (
                        <FundingSourceComboBox
                          value={value}
                          onChange={onChange}
                          readOnly={false}
                        />
                      )}
                      control={control}
                      name="fundingSource"
                    />
                  </Grid>
                </Grid>
              )}
              <Navigation
                backAction={prev}
                nextAction={() => handleSubmit(onSubmit)()}
                nextActionText="Continue"
              />
            </form>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default SiteDetails;
