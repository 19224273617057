import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Page from "@components/Page";
import notFoundImg from "@assets/undraw_page_not_found_su7k.svg";

const NotFoundView = () => (
  <Page title="404" py={3}>
    <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          404: The page you are looking for isn’t here.
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          You either tried some shady route or you came here by mistake. Whichever it is, try using
          the navigation.
        </Typography>
        <Box textAlign="center" mt={6} sx={{ img: { display: "inline-block", width: 560 } }}>
          <img alt="Under development" src={notFoundImg} />
        </Box>
      </Container>
    </Box>
  </Page>
);

export default NotFoundView;
