import React from "react";
import { Container, Paper, Box, IconButton } from "@mui/material";
import Page from "@components/Page";
import { useSelector, useDispatch } from "react-redux";
import Consent from "@admin/components/Consent";
import PageHeader from "@components/PageHeader";
import CustomDataGrid from "@components/CustomDataGrid";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import { setTableState } from "@views/common/actions";
import { get } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import { renderCellExpand } from "@components/GridCellExpand";
import ViewIcon from "@mui/icons-material/Visibility";
import { getClaimedProjectReports } from "../actions";
import { useNavigate } from "react-router";
import { useMemo } from "react";

const tableStateKey = "OWNED_PROJECT_REPORTS";
const initialTableState = {
  sortModel: [
    {
      field: "startDate",
      sort: "asc",
    },
  ],
};

const ClaimedCredits = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectReports, status } = useSelector((state) => state.getClaimedProjectReports);
  const isLoading = status === "request";
  const total = projectReports.length;

  const tableState =
    useSelector((state) => state.tableState[tableStateKey]) ||
    getDefaultTableState(initialTableState);
  const { page, pageSize, sortModel, filterModel } = tableState;

  const columns = useMemo(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 0.4,
      },
      {
        headerName: "Start Date",
        field: "startDate",
        flex: 0.6,
        filterable: false,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "End Date",
        field: "endDate",
        flex: 0.6,
        filterable: false,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Project",
        field: "projectTitle",
        flex: 1.2,
        valueGetter: ({ row: projectReport }) => projectReport.project?.title,
        renderCell: renderCellExpand,
      },
      {
        headerName: "Credit",
        field: "projectCredit",
        flex: 1,
        valueGetter: ({ row: projectReport }) =>
          get(ENVIRONMENTAL_CREDIT_TYPES, projectReport.project?.environmentalCredit),
      },
      {
        headerName: "Quantity",
        type: "number",
        field: "totalEnvironmentalCredits",
        flex: 0.6,
        valueFormatter: ({ value }) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          }),
      },
      {
        headerName: "Cost ($)",
        type: "number",
        field: "cost",
        flex: 0.6,
        valueGetter: ({ row: projectReport }) =>
          projectReport.pricePerCredit * projectReport.totalEnvironmentalCredits,
        valueFormatter: ({ value }) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: ({ row: projectReport }) => (
          <IconButton
            onClick={(ev) => {
              const url = `/marketplace/claimedCredits/${projectReport.id}`;
              navigate(url);
            }}
            aria-label="view claimed project report"
            size="small"
            sx={{ ml: 1 }}
          >
            <ViewIcon />
          </IconButton>
        ),
      },
    ],
    [navigate]
  );

  return (
    <Page title="Claimed Credits" py={3}>
      <Container maxWidth="lg">
        <Consent action={getClaimedProjectReports} scope="manage_credits">
          <PageHeader
            title="Claimed Credits"
            subtitle="Project reports whose credits you have claimed."
          />
          <Paper sx={{ mt: 1 }}>
            <Box height={500}>
              <CustomDataGrid
                style={{ border: 0 }}
                rowHeight={50}
                loading={isLoading}
                rows={projectReports}
                columns={columns}
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(sortModel) =>
                  dispatch(setTableState({ key: tableStateKey, sortModel }))
                }
                page={page}
                onPageChange={(page) => dispatch(setTableState({ key: tableStateKey, page }))}
                pageSize={pageSize}
                onPageSizeChange={(pageSize) => {
                  if (page * pageSize > total) {
                    dispatch(setTableState({ key: tableStateKey, page: 1, pageSize }));
                  } else {
                    dispatch(setTableState({ key: tableStateKey, pageSize }));
                  }
                }}
                rowsPerPageOptions={[10, 20, 50]}
                filterModel={filterModel}
                onFilterModelChange={(filterModel) =>
                  dispatch(setTableState({ key: tableStateKey, filterModel }))
                }
                rowCount={total || 0}
                rowThreshold={0}
              />
            </Box>
          </Paper>
        </Consent>
      </Container>
    </Page>
  );
};

export default ClaimedCredits;
