import React from "react";
import logger from "debug";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger("weedle:error")("Unexpected error: ", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Sorry, something unexpected went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
