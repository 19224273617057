import React from "react";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";

export const DataConnector = 'apsPublic';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Authorized ID"
              variant="standard"
              fullWidth
              autoFocus
              error={!isNil(errors.authCode?.message)}
              helperText={errors.authCode?.message || " "}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          name="authCode"
          control={control}
          rules={{ required: "Authorized ID is required." }}
          defaultValue={dataConnectorParams?.authCode || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} flexDirection="column">
          <Box>
            <ConnectionTest />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} mb={2} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Access Instructions
      </Typography>
      <Typography gutterBottom>
        Using the AP Systems - Public data connector you will need to make your system available with an authorized ID.
      </Typography>
      <Typography>
        <Link href="https://rewattpower.com/aps" target="_new">
          See detailed instructions here.
        </Link>
      </Typography>
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;