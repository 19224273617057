import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Paper } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { findIndex } from "lodash";
import { useNavigate } from "react-router-dom";
import Page from "@components/Page";
import CustomDataGrid from "@components/CustomDataGrid";
import PageHeader from "@components/PageHeader";
import { getApplicationColumns } from "@views/programs/components/Applications";
import { getFullName } from "@utils/stringHelpers";
import { renderCellExpand } from "@components/GridCellExpand";

const MyApplications = ({
  getMyApplications,
  getMyApplicationsResult,
  setApplicationTableState,
  applicationTableState,
}) => {
  const navigate = useNavigate();

  // get applications on load
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getMyApplications({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getMyApplications]);

  const applicationsLoading =
    getMyApplicationsResult.status === "request" ||
    getMyApplicationsResult.status === "init";
  const { applications } = getMyApplicationsResult;

  const columns = React.useMemo(() => {
    const viewAction = (applicationId) => navigate(`/myApplications/${applicationId}`)
    const columns = getApplicationColumns(viewAction);

    let idx = findIndex(columns, { field: "created" });
    columns.splice(idx, 0, {
      field: "applicant",
      headerName: "Applicant",
      flex: 1,
      valueGetter: ({ row: application }) => getFullName(application.applicant, null, application.applicant.id),
      renderCell: renderCellExpand,
    });
    return columns;
  }, [navigate]);

  const { page, pageSize, sortModel, filterModel } = applicationTableState;
  return (
    <Page title="My Applications" py={3}>
      <Container maxWidth="lg">
        <PageHeader
          title="My Applications"
          subtitle="Applications that you (or anyone in your organization) have submitted to various programs."
        />
        <Paper sx={{ mt: 3 }}>
          <div style={{ height: "100%", width: "100%" }}>
            <CustomDataGrid
              style={{ border: 0 }}
              autoHeight
              loading={applicationsLoading}
              rows={applications}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(sortModel) =>
                setApplicationTableState({ sortModel })
              }
              page={page}
              onPageChange={(page) => setApplicationTableState({ page })}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) =>
                setApplicationTableState({ pageSize })
              }
              rowsPerPageOptions={[10, 20, 50]}
              filterModel={filterModel}
              onFilterModelChange={(filterModel) =>
                setApplicationTableState({ filterModel })
              }
              localeText={{
                noRowsLabel: "No submitted applications.",
              }}
            />
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

MyApplications.propTypes = {
  getMyApplications: PropTypes.func.isRequired,
  getMyApplicationsResult: PropTypes.shape({}).isRequired,
  setApplicationTableState: PropTypes.func.isRequired,
  applicationTableState: PropTypes.shape({}).isRequired,
};

export default MyApplications;
