import getBuyerPurchaseRequests from "./getBuyerPurchaseRequests";
import getPurchaseSummary from "./getPurchaseSummary";
import getPurchaseRequestChart from "./getPurchaseRequestChart";

const reducers = {
  getBuyerPurchaseRequests,
  getPurchaseSummary,
  getPurchaseRequestChart,
};

export default reducers;
