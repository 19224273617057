import { call, put, takeLatest } from "redux-saga/effects";
import { getChannelPartner } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, channelPartnerId) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/admin/channelPartners/${channelPartnerId}`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* getChannelPartnerWorker(action) {
  yield put(getChannelPartner.request());

  const { accessToken, channelPartnerId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, channelPartnerId);
    const channelPartner = response.data;
    yield put(getChannelPartner.success({ channelPartner }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting channel partner: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting channel partner: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting channel partner: ", err);
      message = err.message;
    }
    message = `Unable to get channel partner: ${message}`;
    yield put(getChannelPartner.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getChannelPartnerWatcher() {
  yield takeLatest(getChannelPartner.TRIGGER, getChannelPartnerWorker);
}

export default [getChannelPartnerWatcher];
