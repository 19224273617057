// this version of Places expects window.google to be loaded onto #google-maps (ie as per useJsApiLoader)
import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField, Autocomplete, Grid, Typography, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import logger from "debug";
import { map } from "lodash";

const autocompleteService = { current: null };
export const placesService = { current: null };

const Places = ({ changeHandler, disabled }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  // throttled fetch
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  // fetch results as (primarily) inputValue changes
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    const request = {
      input: inputValue,
      componentRestrictions: { country: ["CA", "US"] },
    };

    fetch(request, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // if value changes, grab the details
  React.useEffect(() => {
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.querySelector("#google-maps")
      );
    }
    if (value) {
      placesService.current.getDetails(
        { placeId: value.place_id, fields: ["address_component", "geometry"] },
        function (results, status) {
          if (status === "OK") {
            changeHandler(results);
          } else {
            logger("weedle:error")("status: ", status);
          }
        }
      );
    }
  }, [value, changeHandler]);

  return (
    <Autocomplete
      disabled={disabled}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Address"
          variant="standard"
          helperText="Start typing your street number"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable browser auto-complete
          }}
          sx={{
            width: {
              xs: "100%",
              sm: "85%",
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          color="secondary"
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          map(matches, (match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

Places.defaultProps = {
  disabled: false,
};

Places.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Places;
