import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataGrid from "@components/CustomDataGrid";
import { every, find, isNil, isNaN, round } from "lodash";
import { DateTime } from "luxon";
import { renderCellExpand } from "@components/GridCellExpand";
import { getFullName } from "@utils/stringHelpers";
import {
    createPayeeStatement,
    getProjectReportForStatements,
    downloadEft,
    sendPayeeStatements,
    getPurchaseRequestForProjectReport,
  } from "../../actions"
import Page from "components/Page";
import PageHeader from "components/PageHeader";
import { useParams } from "react-router";
import { LoadingButton } from "@mui/lab";
import Breadcrumb from "components/Breadcrumb";
import { localeStringCurrencyOpts as currencyOpts } from "utils/stringHelpers";
import { calculateAmountOwed, calculateFeeValue, calculateGrossSubTotal } from "utils/financialHelpers";
import { ProjectReportDetailPanel } from "views/admin/projectReport";
import { ProjectDetailPanel } from "views/admin/project";
import { PURCHASE_REQUEST_STATUSES } from "../PurchaseRequests/PurchaseRequest";
import CreditCard from "views/dashboard/components/CreditCard";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";

const DEFAULT_TRANSACTION_FEE = 25;

const isBankInfoSet = (contribution) => {
  if (!contribution.payee?.bankInformation) return false;
  const { institutionNumber, transitNumber, accountNumber } =
    contribution.payee.bankInformation;
  return every(
    [institutionNumber, transitNumber, accountNumber],
    (s) => !isNil(s)
  );
};

const ViewPurchaseRequest = ({ projectReportId }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const fetch = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports",
      });
      dispatch(getPurchaseRequestForProjectReport({ accessToken, projectReportId }));
    };
    fetch();
  }, [dispatch, getAccessTokenSilently, projectReportId]);
  useEffect(() => () => {
    dispatch(getPurchaseRequestForProjectReport.reset());
  }, [dispatch]);
  const { purchaseRequest, status } = useSelector((state) => state.getPurchaseRequestForProjectReport);
  const txCompleted = find(purchaseRequest?.statusHistory, { status: 'TRANSACTION_COMPLETE' });
  const isLoading = status === 'request';

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <CreditCard
          loading={isLoading}
          title="Current Status"
          primary={
            purchaseRequest ? PURCHASE_REQUEST_STATUSES[purchaseRequest.status].display : 'No Purchase Request Available'
          }
          sx={{
            "& .MuiCardContent-root .MuiTypography-root": {
              color: "info.main",
              fontSize: "1.2rem",
              fontWeight: 600,
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <CreditCard
          loading={isLoading}
          title="Offered Price per Credit"
          primary={
            purchaseRequest ? (purchaseRequest.pricePerCredit / 100).toLocaleString(undefined, currencyOpts) : ""
          }
          sx={{
            "& .MuiCardContent-root .MuiTypography-root": {
              fontSize: "1.2rem",
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <CreditCard
          loading={isLoading}
          title="Buyer Details"
          primary={purchaseRequest ? `Requested by ${getFullName(purchaseRequest.buyer)}` : ""}
          secondary={purchaseRequest && `Organization: ${purchaseRequest.buyer.organizationId}`}
        />
      </Grid>
      <Grid item xs={3}>
        <CreditCard
          loading={isLoading}
          title="Dates"
          primary={purchaseRequest ? `Completed: ${DateTime.fromISO(txCompleted.date).toLocaleString()}` : ""}
          secondary={purchaseRequest && `Initialized: ${DateTime.fromSeconds(purchaseRequest.created).toLocaleString()}`}
        />
      </Grid>
    </Grid>
  );
};

const ViewPayeeStatement = ({ payeeStatement, projectReportId }) => {
  const sendingStatement = useSelector((state) => state.sendPayeeStatements);
  const isSendingStatement = sendingStatement.status === 'request';
  const downloadingEft = useSelector((state) => state.downloadEft);
  const isDownloadingEFT = downloadingEft.status === 'request';
  const alreadySent = !isNil(payeeStatement.sentDate);

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const handleDownloadEFT = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects admin_project_reports",
    });
    dispatch(downloadEft({ accessToken, payeeStatementId: payeeStatement.id }));
  };
  const handleSendStatement = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects admin_project_reports",
    });
    dispatch(sendPayeeStatements({ accessToken, payeeStatementId: payeeStatement.id }));
  };

  const sendSuccessful = sendingStatement.status === 'success';
  useEffect(() => {
    const reload = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports",
      });
      dispatch(getProjectReportForStatements({ accessToken, projectReportId }));
    };
    if (sendSuccessful) {
      reload();
    }
  }, [dispatch, getAccessTokenSilently, sendSuccessful, projectReportId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ViewPayeeStatementTable { ...payeeStatement } />
      </Grid>
      {alreadySent && (
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow="1" />
            <Box>
              <Typography>Statement sent on {DateTime.fromISO(payeeStatement.sentDate).toLocaleString()}</Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex">
          <Box flexGrow="1" />
          <Box>
            <LoadingButton
              loading={isDownloadingEFT}
              onClick={handleDownloadEFT}
            >
              Download EFT
            </LoadingButton>
          </Box>
          <Box>
            <LoadingButton
              loading={isSendingStatement}
              onClick={handleSendStatement}
              disabled={(sendSuccessful || isSendingStatement || alreadySent)}
            >
              Send Statement
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const ViewPayeeStatementTable = ({
  grossValue,
  feeValue,
  amountOwed,
  lineItems,
  transactionFee,
}) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Vintage
          </TableCell>
          <TableCell align="right">
            Credits
          </TableCell>
          <TableCell align="right">
            Unit Price ($/credit)
          </TableCell>
          <TableCell />
          <TableCell align="right">
            Value
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lineItems.map(({ description, quantity, total, price }) => (
          <TableRow key={description}>
            <TableCell>
              {description}
            </TableCell>
            <TableCell align="right">
              {quantity.toLocaleString()}
            </TableCell>
            <TableCell align="right">
              {(price / 100).toLocaleString(undefined, currencyOpts)}
            </TableCell>
            <TableCell />
            <TableCell align="right">
              {(total / 100).toLocaleString(undefined, currencyOpts)}
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={3}/>
          <TableCell>
            Subtotal
          </TableCell>
          <TableCell align="right">
            {(grossValue / 100).toLocaleString(undefined, currencyOpts)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}/>
          <TableCell>
            {`Fee (${transactionFee} %)`}
          </TableCell>
          <TableCell align="right">
            {(feeValue / 100).toLocaleString(undefined, currencyOpts)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}/>
          <TableCell>
            Amount Owed
          </TableCell>
          <TableCell align="right">
            {(amountOwed / 100).toLocaleString(undefined, currencyOpts)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const CreatePayeeStatement = ({
  contribution,
  projectReportId,
  pricePerCredit: initialPricePerCredit,
  transactionFee: initialTransactionFee,
}) => {
  const [pricePerCredit, setPrice] = useState(4500);
  const [transactionFee, setTransactionFee] = useState(0);
  
  useEffect(() => {
    setPrice(initialPricePerCredit);
    setTransactionFee(initialTransactionFee);
  }, [initialPricePerCredit, initialTransactionFee]);


  const lineItems = contribution.vintages.map(({ description, credits }) => ({
    description,
    quantity: credits,
    price: pricePerCredit,
    total: round(calculateGrossSubTotal({ credits, pricePerCredit })),
  }));
  const grossValue = lineItems.reduce((total, li) => total + li.total, 0);
  const feeValue = calculateFeeValue({ grossValue, transactionFee });
  const amountOwed = calculateAmountOwed({ grossValue, feeValue });

  const { status, payeeStatement } = useSelector((state) => state.createPayeeStatement);
  const isCreating = status === 'request';
  const isSuccessful = status === 'success';

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    // reload project report on sucess
    const reload = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports",
      });
      dispatch(getProjectReportForStatements({ accessToken, projectReportId }));
    };
    if (isSuccessful) {
      reload();
    }
  }, [dispatch, getAccessTokenSilently, projectReportId, isSuccessful]);

  const handleCreateStatement = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects admin_project_reports",
    });
    dispatch(createPayeeStatement({
      accessToken,
      projectReportId,
      organizationAssetId: contribution.organizationAsset.id,
      details: {
        transactionFee,
        grossValue,
        feeValue,
        amountOwed,
        lineItems,
      },
    }));
  };

  const isMissingBankInfo = !!!contribution.organizationAsset.payee?.bankInformation;

  // show the newly created payee statement
  return payeeStatement
    ? <ViewPayeeStatement payeeStatement={payeeStatement} projectReportId={projectReportId} />
    : (
      <Grid container spacing={1} mt={1}>
        <Grid item xs={3}>
          <TextField
            onChange={event => {
              const newPrice = parseInt(event.target.value);
              setPrice(isNaN(newPrice) ? 0 : parseInt(newPrice));
            }}
            value={pricePerCredit}
            label={"Unit Price (\u00A2/credit)"}
            variant="standard"
            type="number"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            onChange={event => {
              const newTransactionFee = parseInt(event.target.value);
              setTransactionFee(isNaN(newTransactionFee) ? 0 : parseInt(newTransactionFee));
            }}
            value={transactionFee}
            label="Transaction Fee (%)"
            variant="standard"
            type="number"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={12}>
          <ViewPayeeStatementTable
            transactionFee={transactionFee}
            grossValue={grossValue}
            feeValue={feeValue}
            amountOwed={amountOwed}
            lineItems={lineItems}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex">
            <Box flexGrow="1" />
            <Box>
              <LoadingButton
                onClick={handleCreateStatement}
                disabled={isCreating ||isMissingBankInfo}
                loading={isCreating}
              >
                Create Payee Statement
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
};

const ProjectReport = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { projectReportId } = useParams();

  const getProjectReportState = useSelector((state) => state.getProjectReportForStatements)
  useEffect(() => {
    const fetch = async () => {
      const isLoading = getProjectReportState.status === "request";
      if (isNil(getProjectReportState.projectReport) && !isLoading) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "admin_projects admin_project_reports",
        });
        dispatch(getProjectReportForStatements({ accessToken, projectReportId }));
      }
    };
    fetch();
  }, [getProjectReportState, projectReportId, getAccessTokenSilently, dispatch]);
  const { projectReport } = getProjectReportState;

  const [pricePerCredit, setPricePerCredit] = useState(6000);
  useEffect(() => {
    if (projectReport?.pricePerCredit) {
      setPricePerCredit(projectReport.pricePerCredit);
    }
  }, [projectReport?.pricePerCredit]);

  const { invites } = useSelector((state) => state.getPayeeInvites);

  const contributionColumns = [
    {
      headerName: "Site",
      field: "name",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: ({ row: contribution }) => contribution.organizationAsset.name,
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Payee",
      field: "payee",
      flex: 1,
      valueGetter: ({ row: { organizationAsset: site } }) => {
        const fullName = getFullName(site.payee, null, "MISSING");
        return fullName === "MISSING"
          ? "MISSING"
          : `${fullName} <${site.payee?.email}>`;
      },
      cellClassName: ({ row: contribution }) =>
        contribution.organizationAsset.invite ? "invited" : contribution.organizationAsset.payee ? "" : "missing",
      renderCell: renderCellExpand,
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Bank Info",
      field: "bankInfo",
      flex: 1,
      description: "Has the payee set their bank information",
      type: "boolean",
      valueGetter: ({ row: { organizationAsset } }) => isBankInfoSet(organizationAsset),
      cellClassName: ({ row: { organizationAsset } }) =>
        isBankInfoSet(organizationAsset) ? "checkmark" : "xmark",
      disableExport: true,
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Credits",
      field: "credits",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Gross",
      field: "grossValue",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Fee",
      description: "The Rewatt fee, calculated using our transaction fee",
      field: "feeValue",
      flex: 1,
      hide: true,
      type: "number",
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Fee (%)",
      description: "The Rewatt transaction fee",
      field: "feePerc",
      flex: 1,
      type: "number",
      valueGetter: ({ row }) => row.organizationAsset.payee?.transactionFee || DEFAULT_TRANSACTION_FEE,
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Owed",
      description: "Rewatt owes this amount to the payee",
      field: "amountOwed",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Has Statement",
      description: "Rewatt owes this amount to the payee",
      field: "payeeStatement",
      flex: 1,
      type: "number",
      valueGetter: ({ value }) => {
        return !isNil(value) ? 'Yes' : 'No';
      },
      filterable: false,
      hideable: false,
      pinnable: false,
    }
  ];

  const contributions = projectReport?.contributions.map((contribution) => {
    const { organizationAsset: site } = contribution;
    if (site.payee) return contribution;
    const invite = find(invites, (invite) => invite.asset.id === site.id);
    if (invite?.status === "INVITED") {
      return {
        ...contribution,
        organizationAsset: {
          ...contribution.organizationAsset,
          invite,
        }
      };
    }
    return contribution;
  })
  .map((contribution) => {
    if (contribution.payeeStatement) {
      const payeeStatement = contribution.payeeStatement;
      return {
        ...contribution,
        credits: payeeStatement.lineItems.reduce((total, li) => total + li.quantity, 0),
        grossValue: payeeStatement.grossValue,
        feeValue: payeeStatement.feeValue,
        amountOwed: payeeStatement.amountOwed,
      };
    }
    const transactionFee = contribution.organizationAsset.payee?.transactionFee || 25;
    const credits = contribution.vintages.reduce((total, { credits }) => total + credits, 0);
    const pricePerCredit = projectReport?.pricePerCredit || 0;
    const grossValue = calculateGrossSubTotal({ credits, pricePerCredit });
    const feeValue = calculateFeeValue({ grossValue, transactionFee });
    const amountOwed = calculateAmountOwed({ grossValue, feeValue });
    return {
      ...contribution,
      credits,
      grossValue,
      feeValue,
      amountOwed,
    };
  });

  const getDetailPanelHeight = useCallback(() => 500, []);
  const getStatementPanel = useCallback(
    ({ row: contribution }) => {
      return contribution.payeeStatement
        ? <ViewPayeeStatement payeeStatement={contribution.payeeStatement} projectReportId={projectReportId} />
        : <CreatePayeeStatement
            contribution={contribution}
            projectReportId={projectReportId}
            transactionFee={contribution.organizationAsset.payee?.transactionFee || 25}
            pricePerCredit={pricePerCredit}
        />;
    },
    [projectReportId, pricePerCredit]
  );

  const statements = projectReport?.contributions.filter(c => !!c.payeeStatement).map(c => ({
    payee: c.organizationAsset.payee,
    ...c.payeeStatement,
  }));
  const statementColumns = [
    {
      headerName: "Payee",
      field: "payee",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: ({ row: { payee } }) => {
        const fullName = getFullName(payee, null, "MISSING");
        return fullName === "MISSING" ? "MISSING" : `${fullName} <${payee?.email}>`;
      },
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Date Sent",
      description: "Date the statement was emailed to the payee",
      field: "sentDate",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => value ? DateTime.fromISO(value).toLocaleString() : "Not Sent",
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Gross",
      description: "Gross value of credits.",
      field: "grossValue",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Transaction Fee",
      description: "Dollar amount of Rewatt's fee",
      field: "feeValue",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerName: "Amount Owed",
      description: "Amount owing on the payee statement. NB may or may not have been paid",
      field: "amountOwed",
      flex: 1,
      type: "number",
      valueFormatter: ({ value }) => (value / 100).toLocaleString(undefined, currencyOpts),
      filterable: false,
      hideable: false,
      pinnable: false,
    },
  ];

  return (
    <Page py={3} title="Payee Statements">
      <Container maxWidth="lg">
        <PageHeader title={`Statements for Project Report ${projectReportId}`}>
          <Breadcrumb title="Disbursable Project Reports" destination="/admin/marketplace/payeeStatements" />
        </PageHeader>
        <Stack spacing={3} sx={{ mt: 3 }}>
          <Box>
            <Typography variant="h5">Purchase Request</Typography>
            <ViewPurchaseRequest projectReportId={projectReportId} />
          </Box>
          <Box>
            <Typography variant="h5">Project Details</Typography>
            {projectReport && (<ProjectDetailPanel
              project={projectReport.project}
              editable={false}
              hidePlanningSheet={true}
            />)}
          </Box>
          <Box>
            <Typography variant="h5">Project Details</Typography>
            {projectReport && (<ProjectReportDetailPanel
              project={projectReport.project}
              projectReport={projectReport}
              hideReportingSheet={true}
            />)}
          </Box>
          <Box>
            <Typography variant="h5">Statements Calculator</Typography>
            <Paper sx={{ p: 2 }}>
              <TextField
                onChange={event => {
                  const newPrice = parseInt(event.target.value);
                  setPricePerCredit(isNaN(newPrice) ? 0 : parseInt(newPrice));
                }}
                fullWidth
                value={pricePerCredit}
                label={"Default Price per Credit (\u00A2/credit)"}
                variant="standard"
                type="number"
              />
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  mb: 1,
                  "& .missing": {
                    color: "error.main",
                  },
                  "& .invited": {
                    color: "info.main",
                  },
                  "& .checkmark .MuiSvgIcon-root.MuiDataGrid-booleanCell[data-value='true']":
                    {
                      color: "success.main",
                    },
                  "& .xmark .MuiSvgIcon-root.MuiDataGrid-booleanCell[data-value='false']":
                    {
                      color: "error.main",
                    },
                }}
              >
                <CustomDataGrid
                  style={{ border: 0 }}
                  autoHeight
                  rows={contributions || []}
                  columns={contributionColumns}
                  disableSelectionOnClick
                  hideFooter
                  getDetailPanelHeight={getDetailPanelHeight}
                  getDetailPanelContent={getStatementPanel}
                />
              </Box>
            </Paper>
          </Box>
          <Box>
            <Typography variant="h5">Summary of Statements</Typography>
            <Paper sx={{ p: 2 }}>
              <CustomDataGrid
                style={{ border: 0 }}
                autoHeight
                rows={statements || []}
                columns={statementColumns}
                disableSelectionOnClick
                hideFooter
                components={{ Toolbar: () => (<GridToolbarContainer><GridToolbarExport /></GridToolbarContainer>) }}
              />
            </Paper>
          </Box>
        </Stack>
      </Container>
    </Page>
  );
};

export default ProjectReport;
