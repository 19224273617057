import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";

const SmaInstructionPanel = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Instructions to Grant Rewatt Access
      </Typography>
      <Typography gutterBottom>
        Follow our{" "}
        <Link href="https://rewattpower.com/sma" target="_new">
          step-by-step instructions
        </Link>{" "}
        to onboard your SMA site.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <Box>
            <ListItemText
              primary="Determine if you are a system owner for the site you want to onboard."
              secondary="Only the system owner may grant Rewatt permission to collect site data. You cannot complete onboarding your site without permission. Click 'View Instructions' for more information."
            />
          </Box>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Enter your email address, as it appears in Sunny Portal, in the field below and click 'Request Permission'."
            secondary="This action will begin the process to acquire your permission for Rewatt to collect the site data."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText primary="Check your email inbox for instructions from SMA on how to give Rewatt access to your site data." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Enter the ID of the system you want to onboard in the field below and click 'Test Connection'"
            secondary="Rewatt requires data access to your site in order for 'Test Connection' to be successful. If the previous step was not completed successfully you will not be able to onboard your site."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default SmaInstructionPanel;
