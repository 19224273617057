import {
  saveContactInfo,
  saveOrganizationInfo,
  resetOrganization,
} from "../actions";

export const initialState = {
  name: "",
  industry: "",
  addresses: {
    main: {
      street: "",
      city: "",
      region: "",
      country: "",
      postal: "",
    },
  },
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    jobTitle: "",
    role: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case saveContactInfo.TYPE: {
      const contact = action.payload;
      return {
        ...state,
        contact,
      };
    }
    case saveOrganizationInfo.TYPE: {
      const { name, industry, addresses } = action.payload;
      return {
        ...state,
        name,
        industry,
        addresses,
      };
    }
    case resetOrganization.TYPE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
