import getCustomerProjects from "./getCustomerProjects";
import getCustomerProject from "./getCustomerProject";
import createCustomerProject from "./createCustomerProject";
import patchCustomerProject from "./patchCustomerProject";
import getIncompleteSitesCount from "./getIncompleteSitesCount";
import getProjectApplications from "./getProjectApplications";
import getProjectApplication from "./getProjectApplication";
import getCustomerProjectChart from "./getCustomerProjectChart";
import getCustomerProjectCreditSummary from "./getCustomerProjectCreditSummary";

export default [
  ...getCustomerProjects,
  ...getCustomerProject,
  ...createCustomerProject,
  ...patchCustomerProject,
  ...getIncompleteSitesCount,
  ...getProjectApplications,
  ...getProjectApplication,
  ...getCustomerProjectChart,
  ...getCustomerProjectCreditSummary,
];
