import React, { useEffect, useReducer } from "react";
import {
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { patchApplicationAddDocuments } from "../actions";
import { SolarDocs } from "views/customerProjects/Site/components/Documents";
import DocumentUploader from "views/addSites/sitePicker/containers/DocumentUploader";
import DialogSaveButton from "components/DialogSaveButton";

const DocumentsEditor = ({ close, applicationId }) => {
  
  const dispatch = useDispatch();

  // setup the form
  const [documents, setDocuments] = useReducer(documentsReducer, []);

  // submit patch
  const { getAccessTokenSilently } = useAuth0();
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(patchApplicationAddDocuments({
      applicationId,
      documents: documents.map(d => ({ ...d, name: d.file.path })),
      accessToken,
    }));
  };
  const { status } = useSelector((state) => state.patchApplicationAddDocuments);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  useEffect(() => () => {
    dispatch(patchApplicationAddDocuments.reset());
  }, [dispatch]);

  return (
    <>
      <DialogContent>
        <Paper sx={{ p: 2 }}>
          <SolarDocs>
            <DocumentUploader documents={documents} dispatch={setDocuments} />
          </SolarDocs>
        </Paper>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          handleClose={close}
          handleSubmit={onSubmit}
        />
      </DialogActions>
    </>
  );
};

export default DocumentsEditor;
