import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getSiteChart } from "@views/site/actions";
import { useDispatch } from "react-redux";
import ChartPanel from "@views/common/components/ChartPanel";

const IndividualChartPanel = ({ siteId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const fetchChartData = React.useCallback(
    async ({ startDate, endDate }) => {
      if (!siteId) return;
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      dispatch(
        getSiteChart({
          accessToken,
          siteId,
          startDate,
          endDate,
        })
      );
    },
    [dispatch, getAccessTokenSilently, siteId]
  );

  return (
    <ChartPanel
      fetchChartData={fetchChartData}
      resetChartData={() => dispatch(getSiteChart.reset())}
      fetchSelector={(state) => state.getSiteChart}
      defaultTabName="year"
      defaultGranularity="month"
      seriesStats={["generation", "consumption", "export"]}
    />
  );
};

export default IndividualChartPanel;
