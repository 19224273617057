import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Welcome from "@views/orgInvites/components/Welcome";
import { verifyInviteToken } from "@views/orgInvites/actions";
import { getUserProfile } from "@views/common/actions";

const mapStateToProps = ({
  verifyInviteToken: verifyInviteTokenResult,
}) => ({
  verifyInviteTokenResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verifyInviteToken,
      resetUserProfile: () => dispatch(getUserProfile.reset()),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
