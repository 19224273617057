import React, { useEffect, useState } from "react";
import { Grid, Typography, Link, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLiveSite, getLiveStats, getLiveEnergyToday } from "../actions";
import PageLoader from "@components/PageLoader";
import { includes, find } from "lodash";
import Header from "./Header";
import EnergyCard from "./EnergyCard";
import GenerationCard from "./GenerationCard";
import TodayCard from "./TodayCard";
import { round } from "@utils/numberHelpers";
import { wmTokWh } from "@utils/energyHelpers";
import { DateTime } from "luxon";
import { Helmet } from 'react-helmet';

const LiveSite = () => {
  const dispatch = useDispatch();
  const { siteId } = useParams();

  // get site detail on load
  useEffect(() => {
    dispatch(getLiveSite({ siteId }));
  }, [siteId, dispatch]);

  // get site stats
  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    if (refresh) {
      dispatch(getLiveStats({ siteId }));
      dispatch(getLiveEnergyToday({ siteId }));
      setRefresh(false);
      setTimeout(() => {
        setRefresh(true);
      }, 30 * 60 * 1000);
    }
  }, [siteId, dispatch, refresh]);

  const { status, site } = useSelector((state) => state.getLiveSite);
  const isLoading = includes(["init", "request"], status);

  const { last30DayEnergy, lifetimeEnergy, specificYield } = useSelector(
    (state) => state.getLiveStats.stats
  );

  const logo = find(site.documents, (doc) =>
    find(doc.tags, { name: "Logo for live page" })
  );

  return (
    <Box p={3}>
      <Helmet>
        <title>{site.name || ""}</title>
        <meta property="og:title" content={`${site?.name} by Rewatt Power`} />
        <meta property="og:description" content="Public solar generation dashboard." />
        {logo && <meta property="og:image" content={logo.uri} />}
      </Helmet>
      {isLoading && <PageLoader message="Just a moment..." />}
      {!isLoading && (
        <Grid container spacing={2}>
          <Header siteId={siteId} siteName={site.name} documents={site.documents} />
          <Grid item xs={12} sm={4}>
            <EnergyCard
              title="Last 30 Days"
              value={`${round(wmTokWh(last30DayEnergy), 0).toLocaleString()} kWh`}
              color="success.dark"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <EnergyCard
              title="Total Lifetime Energy"
              value={`${round(wmTokWh(lifetimeEnergy), 0).toLocaleString()} kWh`}
              color="success.main"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <EnergyCard
              title="Specific Yield"
              value={specificYield ? `${round(specificYield, 0).toLocaleString()} kWh/kWp` : ' kWh/kWp'}
              color="success.light"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenerationCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TodayCard />
          </Grid>
          <Grid item xs>
            <Typography textAlign="center" color="textSecondary">
              © {DateTime.now().get("year")}{" "}
              <Link href="https://rewattpower.com" target="_blank">
                Rewatt Power
              </Link>{" "}
              | All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default LiveSite;
