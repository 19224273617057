import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { patchAppSiteOwnership } from "@views/reviewApplication/actions";
import SiteOwnershipPanel from "../components/SiteOwnershipPanel";
import { sendProgramTerms } from "../actions";

const mapStateToProps = ({ sendProgramTerms }) => ({
  sendProgramTermsResult: sendProgramTerms,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPatchAppSiteOwnership: () => dispatch(patchAppSiteOwnership.reset()),
      sendProgramTerms
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiteOwnershipPanel);
