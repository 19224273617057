/**
 * Special saga to chain 3 calls together, which would otherwise execute async, and thus we
 * get back differring responses (ie we don't usually have the complete statusHistory, 
 * or the form field values otherwise).
 */
import { call, put, takeLatest } from "redux-saga/effects";
import { patchProjectReportWithStatus, patchProjectReport } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const patchApi = async (accessToken, projectReport) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projectReports/${projectReport.id}`,
    projectReport,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

const setStatusApi = async (accessToken, projectReport, status) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projectReports/${projectReport.id}/status`,
    { status },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* patchProjectReportWithStatusWorker(action) {
  const { accessToken, projectReport, statuses, key } = action.payload;

  // nb. keep track in the general patch reducer (we don't have our own for patchProjectReportWithStatus)
  yield put(patchProjectReport.request({ key }));

  try {
    for (const status of statuses) {
      yield call(setStatusApi, accessToken, projectReport, status);
    }
    const response = yield call(patchApi, accessToken, projectReport);
    yield put(patchProjectReport.success({ projectReport: response.data }));
    yield put(
      showAppMessage({
        severity: "success",
        message: "Saved project report.",
        duration: 3000,
      })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error patching project report with status: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error patching project report with status: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error patching project report with status: ", err);
      message = err.message;
    }
    message = `Unable to patch project report with status: ${message}`;
    yield put(patchProjectReport.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* patchProjectReportWithStatusWatcher() {
  yield takeLatest(patchProjectReportWithStatus.TRIGGER, patchProjectReportWithStatusWorker);
}

export default [patchProjectReportWithStatusWatcher];
