import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { getDefaultLocale } from "@utils/dateHelpers";
import { DateTime } from "luxon";

const SiteDetails = ({ application, program }) => {
  const tableRows = [
    {
      label: "Type",
      value: program?.activityType || "N/A",
    },
    {
      label: "Mounting Type",
      value: application?.asset?.mountingType || "N/A",
    },
    {
      label: "Capacity (kW)",
      value:
        Math.round(
          (application?.asset?.capacity / 1000 + Number.EPSILON) * 100
        ) / 100, // max 2 decimal places
    },
    {
      label: "Installation Date",
      value: DateTime.fromISO(application?.asset?.completionDate)
        .setLocale(getDefaultLocale())
        .toLocaleString(DateTime.DATE_MED),
    },
  ];

  return (
    <Paper sx={{ mt: 2, px: 10, py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Site details
      </Typography>
      <TableContainer>
        <Table aria-label="site details">
          <TableBody>
            {tableRows.map((row) => (
              <TableRow key={row.label}>
                <TableCell component="th" width="50%">{row.label}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

SiteDetails.propTypes = {
  application: PropTypes.shape({}).isRequired,
  program: PropTypes.shape({
    activityType: PropTypes.string,
  }),
};

export default SiteDetails;
