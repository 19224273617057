import {
  dataUpdated,
} from "../actions";

export const initialState = {
  timestamp: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case dataUpdated.TYPE: {
      const { timestamp } = action.payload;
      return {
        ...state,
        timestamp,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
