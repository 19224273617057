import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { map, isEmpty } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { fileSizeValidator } from "@utils/fileHelpers";
import byteSize from "byte-size";
import { showAppMessage, hideAppMessage } from "@appMessage/actions";
import { useDispatch, useSelector } from "react-redux";
import { uploadProjectFiles } from "../../actions";
import DropZone from "@components/DropZone";

const maxFileSize = 30 * 1000 * 1000; // bytes

const ProjectFilesUploader = ({ onChange, discriminator, ...other }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    maxFiles: 5,
    validator: fileSizeValidator(maxFileSize),
  });

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      dispatch(
        showAppMessage({
          severity: "error",
          message: fileRejections[0].errors[0].message,
        })
      );
    } else {
      dispatch(hideAppMessage());
    }
  }, [fileRejections, dispatch]);

  // we have some new files to upload
  useEffect(() => {
    async function uploadNewFiles() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      dispatch(
        uploadProjectFiles({
          filespecs: map(acceptedFiles, (file) => ({
            file,
            tags: [],
            public: false,
          })),
          accessToken,
          discriminator,
        })
      );
    }
    if (acceptedFiles.length) {
      uploadNewFiles();
    }
    // eslint-disable-next-line
  }, [acceptedFiles]);

  // inform our form
  const { filespecs, status, discriminator: key } = useSelector(
    (state) => state.uploadProjectFiles
  );
  useEffect(() => {
    if (!isEmpty(filespecs) && discriminator === key) {
      onChange(filespecs);
    }
  }, [onChange, filespecs, discriminator, key]);

  const isUploading = status === "request" && discriminator === key;
  return (
    <DropZone
      {...getRootProps({
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
      })}
      width="unset"
      height={60}
      sx={{ mr: 3 }}
    >
      <input {...getInputProps()} />
      <Box m={1}>
        <Typography variant="body2" color="textPrimary" gutterBottom>
          Add docs...
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Max file size: {byteSize(maxFileSize, { precision: 0 }).toString()}
        </Typography>
      </Box>
      {isUploading && (
        <CircularProgress
          size={32}
          sx={{
            color: (theme) => theme.palette.primary.main,
            position: "absolute",
            top: "50%",
            left: "50%",
            mt: -2,
            ml: -2,
          }}
        />
      )}
    </DropZone>
  );
};

ProjectFilesUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  discriminator: PropTypes.string.isRequired,
};

export default ProjectFilesUploader;
