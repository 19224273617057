import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import { Box, Card, CardContent, Typography } from "@mui/material";
import FittedImage from "components/FittedImage";

const VerificationPanel = ({ projectReport }) => {
  const verifiedStatus = find(projectReport.statusHistory, { status: "VERIFIED" });
  return (
    <>
      <Typography variant="h3" gutterBottom ml={2}>
        Verification
      </Typography>
      <Card>
        <CardContent>
          {verifiedStatus ? (
            <Box display="flex" alignItems="flex-start">
              {projectReport.verifierLogo?.uri && (<FittedImage uri={projectReport.verifierLogo.uri} height={100} fit="contain" alt="Verifier logo" />)}
              <Typography ml={1}>
                {projectReport.verifierStatement ?? "No verification statement available."}
              </Typography>
            </Box>
          ) : (
            <Typography ml={1}>This Project Report has not been verified!</Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

VerificationPanel.propTypes = {
  projectReport: PropTypes.shape({
    id: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

export default VerificationPanel;
