import React from "react";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DocumentIcon from "@mui/icons-material/Description";
import PhotoIcon from "@mui/icons-material/Photo";
import ListIcon from "@mui/icons-material/List";
import ReportIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssessmentIcon from "@mui/icons-material/Assessment";

const DocumentsInfo = ({ children }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Choose documents to support your ownership claim.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        This is important to participate in various carbon offset programs, and
        when audits are performed on your site. Without these documents, you
        cannot sell your green attributes.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Locate any or all of the following documents, and tag them with their
        type.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <DocumentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Interconnection Agreement or Commissioning Report"
                secondary="Provided to you by your utility"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Final Electrical Inspection Report"
                secondary="Provided to you by your installer or electrician"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Signal Line Diagram"
                secondary="Electrical diagram, provided to you by your installer"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText
                primary="Equipment List and/or Invoice"
                secondary="Brand names of your generation gear, provided to you by your installer"
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <PhotoIcon />
              </ListItemIcon>
              <ListItemText
                primary="Photo of Inverter"
                secondary="Take a picture of your inverter with your smartphone."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhotoIcon />
              </ListItemIcon>
              <ListItemText
                primary="Photo of Measuring Device"
                secondary="You may have a downstream smart meter which is providing the actual energy measurements."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhotoIcon />
              </ListItemIcon>
              <ListItemText
                primary="Photo of Power Generation Equipment"
                secondary="eg. a photo of your solar panels"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      {children}
    </>
  );
};

export default DocumentsInfo;
