import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OrganizationEditor from "../components/OrganizationEditor";
import { updateOrganization } from "@views/common/actions";

const mapStateToProps = ({ updateOrganization }) => ({
  updateOrganizationResult: updateOrganization,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateOrganization,
      resetUpdateOrganization: () => dispatch(updateOrganization.reset()),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationEditor);
