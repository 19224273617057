import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Page from "components/Page";
import PageHeader from "components/PageHeader";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InstallerPanel from "./InstallerPanel";
import { getApplication } from "views/reviewApplication/actions";
import Breadcrumb from "components/Breadcrumb";
import { ViewSolarDetails } from "views/customerProjects/Site/components/Details";
import { ViewLocation } from "views/customerProjects/Site/components/Location";
import { ViewDocuments } from "views/customerProjects/Site/components/Documents";
import { ViewDevices } from "views/customerProjects/Devices/components/Devices";
import CloseIcon from "@mui/icons-material/Close";
import { startCase } from "lodash";
import DialogTransition from "components/DialogTransition";
import LocationEditor from "./LocationEditor";
import { patchApplicationEditDetails } from "../actions";
import SiteDetailsEditor from "./SiteDetailsEditor";
import DocumentsEditor from "./DocumentsEditor";
import DevicesEditor from "./DevicesEditor";

const Application = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { applicationId } = useParams();

  // init the application
  const { application, status } = useSelector(state => state.getApplication);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getApplication({ accessToken, applicationId }));
    }
    if (isNil(application) || application.id !== +applicationId) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, applicationId, application]);

  // edit the application
  const [editing, setEditing] = useState(null);
  const closeEditor = async () => {
    setEditing(null);
    dispatch(patchApplicationEditDetails.reset());
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    // refetch to refresh the changes
    dispatch(getApplication({
      accessToken,
      applicationId
    }));
  };

  const isLoading = status === 'request' || status === 'init';

  return (
    <Page title={`View Application: ${applicationId}`} py={3}>
      <Container maxWidth="lg">
        <PageHeader title={`View Application: ${applicationId}`}>
          <Breadcrumb title="Dashboard" destination="/individual/dashboard" />
        </PageHeader>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={!!editing}
          onClose={closeEditor}
          TransitionComponent={DialogTransition}
          sx={{
            "& .MuiPaper-root.MuiDialog-paper": {
              bgcolor: "background.dark",
            },
          }}
        >
          <AppBar position="sticky">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeEditor}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" color="common.white" ml={2}>
                {`${startCase(editing)}`}
              </Typography>
            </Toolbar>
          </AppBar>
          {editing === 'details' && (
            <SiteDetailsEditor
              site={application.asset}
              close={closeEditor}
              applicationId={application.id}
            />
          )}
          {editing === 'location' && (
            <LocationEditor
              site={application.asset}
              close={closeEditor}
              applicationId={application.id}
            />
          )}
          {editing === 'addDocuments' && (
            <DocumentsEditor
              applicationId={application.id}
              close={closeEditor}
            />
          )}
          {editing === 'addDevices' && (
            <DevicesEditor
              close={closeEditor}
              customerProject={application.customerProject}
              applicationId={applicationId}
            />
          )}
        </Dialog>
        <Box mt={2} ml={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Site Details"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('details');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewSolarDetails siteDetails={application.asset} reviewOnly isLoading={isLoading} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Installer Details"/>
                <CardContent>
                  <InstallerPanel site={application.asset} isLoading={isLoading} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Location"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('location');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewLocation site={application.asset} reviewOnly isLoading={isLoading} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Application Documents"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isLoading}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('addDocuments');
                      }}
                    >
                      Add
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewDocuments site={application.asset} reviewOnly customerProjectId={1} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Devices"
                  action={(
                    <Button
                      aria-label="edit"
                      disabled={isLoading || application.asset?.meters?.length > 0}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setEditing('addDevices');
                      }}
                    >
                      Add
                    </Button>
                  )}
                />
                <CardContent>
                  <ViewDevices
                    customerProject={{
                      id: 1,
                      emissionReductionActivity: "SOLAR"
                    }}
                    devices={application.asset?.meters}
                    siteId={application.asset?.id}
                    editable={false}
                    reviewOnly
                    isLoading={isLoading}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};


export default Application;
