import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const OrganizationGuard = ({ children }) => {
  const navigate = useNavigate();

  // if an individual tries to access an organization page, send them to a 404

  const { userProfile, status } = useSelector((state) => state.getUserProfile);
  if (!userProfile.organizationId && status === "success") {
    navigate("/404", { replace: true });
    return null;
  }

  return children;
};

OrganizationGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrganizationGuard;
