import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { acceptPayeeInvite, verifyPayeeInviteToken } from "../actions";
import { Typography, Container, Box, Paper } from "@mui/material";
import Page from "@components/Page";
import { Navigation } from "@components";
import { getUserProfile } from "@views/common/actions";
import { pick } from "lodash";

const AcceptInvite = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { userProfile } = useSelector((state) => state.getUserProfile);
  const { invite } = useSelector((state) => state.verifyPayeeInviteToken);

  const acceptInvite = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    // nb. we never collected an address for this user profile
    dispatch(
      acceptPayeeInvite({
        accessToken,
        inviteId: invite.id,
        acceptedTermsAndConditions: userProfile.acceptedTermsAndConditions,
        organizationId: invite.organization?.id, // not required if individual owner
      })
    );
  };
  const { status: acceptPayeeInviteStatus, userProfile: updatedUserProfile } = useSelector(
    (state) => state.acceptPayeeInvite
  );
  const isSubmitting = acceptPayeeInviteStatus === "request";

  // invite accepted
  useEffect(() => {
    const finish = async () => {
      // update userProfile locally
      dispatch(
        getUserProfile.update({
          userProfile: { ...pick(updatedUserProfile, ["assetId", "isIndividualOwner", "organizationId"]) },
        })
      );

      dispatch(verifyPayeeInviteToken.reset());
      dispatch(acceptPayeeInvite.reset());

      if (updatedUserProfile.isIndividualOwner) {
        navigate("/individual/dashboard")
      } else {
        navigate("/dashboard");
      }
    };
    if (acceptPayeeInviteStatus === "success") {
      finish();
    }
  }, [
    acceptPayeeInviteStatus,
    dispatch,
    navigate,
    updatedUserProfile,
  ]);

  return (
    <Page title="Accept Invite" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box>
            <Typography variant="h2" color="textPrimary">
              Site Payee
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Create your account
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <Typography gutterBottom>You're all set!</Typography>
            <Typography>
              Press continue to accept your invitation and go to your dashboard.
            </Typography>
            <Navigation
              nextAction={acceptInvite}
              nextActionText="Continue"
              isSubmitting={isSubmitting}
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default AcceptInvite;
