import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Programs from "../components/Programs";
import { getPrograms, setProgramTableState } from "../actions";

const mapStateToProps = ({
  getPrograms: getProgramsResult,
  programTableState,
}) => ({
  getProgramsResult,
  programTableState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrograms,
      setProgramTableState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
