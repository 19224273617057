import React, { useEffect } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
  Grid,
  Button,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import pluralize from "pluralize";
import { ENVIRONMENTAL_CREDIT_TYPES } from "@admin/project/components/Project";
import PricingPanel from "./PricingPanel";
import { ProjectReportStatusPanel } from "@admin/projectReport";
import { LoadingButton } from "@mui/lab";
import {
  getBuyerProjectReports,
  getBuyerProject,
  submitRequestToPurchase,
} from "../actions";
import { round } from "utils/numberHelpers";
import { ProjectStatusPanel } from "@admin/project";
import { ProjectDetailPanel } from "@admin/project";
import { ProjectReportDetailPanel } from "@admin/projectReport";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = Yup.object().shape({
  pricePerCredit: Yup.number()
    .typeError("Price is required")
    .required("Price is required.")
    .positive(),
});

const ReviewProjectReport = ({ open, onClose, projectReport }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      pricePerCredit: "",
    },
  });

  const doSubmitPurchaseRequest = async (formData) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "manage_credits",
    });
    dispatch(
      submitRequestToPurchase({
        accessToken,
        projectReportId: projectReport.id,
        pricePerCredit: formData.pricePerCredit,
      })
    );
  };

  const cancel = async () => {
    onClose();
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "manage_credits",
    });
    dispatch(submitRequestToPurchase.reset());
    dispatch(getBuyerProjectReports({ accessToken }));
  };

  const { purchaseRequest, status } = useSelector(
    (state) => state.submitRequestToPurchase
  );
  const isSubmitting = status === "request";
  const showSuccess = status === "success";

  // we need the start/end date and the statusHistory, which is not included with the projectReport.project
  const { project } = useSelector((state) => state.getBuyerProject);
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(
        getBuyerProject({ accessToken, projectId: projectReport?.project?.id })
      );
    }
    if (projectReport?.project?.id) {
      init();
    }
  }, [dispatch, getAccessTokenSilently, projectReport?.project?.id]);

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          bgcolor: "background.dark",
        },
      }}
    >
      <AppBar sx={{ bgcolor: "primary.main" }} position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={cancel}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Review Project Report and make Purchase Request
          </Typography>
        </Toolbar>
      </AppBar>

      {showSuccess ? (
        <Container maxWidth="md">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "calc(80vh - 64px)" }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h1" color="secondary.main">
                Success!
              </Typography>
              <Typography mt={2}>
                {`You have successfully submitted a request to purchase ${round(
                  purchaseRequest.projectReport.totalEnvironmentalCredits,
                  3
                )} ${pluralize(
                  ENVIRONMENTAL_CREDIT_TYPES[
                    purchaseRequest.projectReport.project.environmentalCredit
                  ]
                )} for $${
                  purchaseRequest.pricePerCredit
                } per credit, from the ${project.title} project.`}
              </Typography>
              <Typography mt={1}>
                Rewatt will review the request and respond with further
                instructions.
              </Typography>
              <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button variant="contained" color="primary" onClick={cancel}>
                  Done
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="lg">
          <FormProvider {...methods}>
            <Box my={3} ml={1}>
              <Typography mb={2} ml={3}>
                Review project report details here. When you finalize your
                purchase request, Rewatt Power will issue an invoice and begin the
                process of transferring ownership. Upon completion, you will be
                notified by email, and on your dashboard.
              </Typography>
              <Grid container spacing={3}>
                {/* Project details */}
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Project
                  </Typography>
                  <ProjectDetailPanel
                    project={project}
                    editable={false}
                    hidePlanningSheet
                  />
                </Grid>
                <Grid item sm={12}>
                  <ProjectStatusPanel
                    project={project}
                    title=""
                    editable={false}
                  />
                </Grid>

                {/* Project Report details */}
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Project Report
                  </Typography>
                  <ProjectReportDetailPanel
                    project={project}
                    projectReport={projectReport}
                    hideReportingSheet
                  />
                </Grid>
                <Grid item sm={12}>
                  <ProjectReportStatusPanel
                    projectReport={projectReport}
                    editable={false}
                    title=""
                  />
                </Grid>

                {/* Purchase Request details */}
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Purchase Request
                  </Typography>
                  {projectReport && <PricingPanel projectReport={projectReport} />}
                </Grid>

                <Grid item sm={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={methods.handleSubmit(doSubmitPurchaseRequest)}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Finalize Purchase Request
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </Container>
      )}
    </Dialog>
  );
};

export default ReviewProjectReport;
