import React, { useState } from 'react';
import CustomDataGrid from "@components/CustomDataGrid";
import { renderCellExpand } from "@components/GridCellExpand";
import {
  Alert,
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { toLower, startCase, isNil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigation } from "components";
import { createDevices, patchDevice } from "../../Site/actions";
import DataConnector from './DataConnector';
import SystemPicker from './SystemPicker';
import DialogSaveButton from 'components/DialogSaveButton';
import DialogTransition from 'components/DialogTransition';
import { isEmpty } from 'lodash';
import { v4 as uuid } from "uuid";
import RequiredField from 'views/common/components/RequiredField';
import { showAppMessage } from 'components/appMessage/actions';

const schema = Yup.object().shape({
  name: Yup.string()
    .max(60, "Too long.")
    .required("Device name is required."),
  model: Yup.string(),
  serialNumber: Yup.string(),
});

export const transformDevices = (devices, credentials) => devices.map(m => ({
  ...m,
  dataConnector: {
    ...m.dataConnector,
    ...credentials
  },
}));

export const AddDevices = ({
  customerProject,
  handleFinish,
  handleBack,
  children,
  skipAction = null,
}) => {
  // track which component to display
  const instructionsStep = 0;
  const testConnectionStep = 1;
  const systemPickerStep = 2;
  const firstStep = children ? instructionsStep : testConnectionStep
  const [activeStep, setActiveStep] = useState(firstStep);

  const dispatch = useDispatch()
  const { meters } = useSelector(state => state.addDevicesForm);
  const { canAccessData } = useSelector(state => state.testDataConnectorConnection);
  const stepNext = () => {
    if (activeStep === instructionsStep) {
      setActiveStep(testConnectionStep);
    }
    if (activeStep === testConnectionStep) {
      if (canAccessData) {
        setActiveStep(systemPickerStep);
      } else {
        dispatch(showAppMessage({
          severity: 'warning',
          message: 'Please ensure a valid connection has been established or skip.'
        }));
      }
    }
    if (activeStep === systemPickerStep) {
      if (meters.length === 0) {
        dispatch(showAppMessage({
          severity: 'warning',
          message: 'Select at least one system.'
        }));
      } else {
        handleFinish();
      }
    }
  };
  const stepBack = () => {
    if (activeStep === firstStep) {
      handleBack();
    }
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
  };

  const handleSkip = activeStep === firstStep ? skipAction : null;

  return (
    <>
      {activeStep === instructionsStep && children}
      {activeStep === testConnectionStep && <DataConnector customerProject={customerProject}/>}
      {activeStep === systemPickerStep && <SystemPicker customerProject={customerProject} />}
      <Navigation
        nextAction={stepNext}
        backAction={stepBack}
        skipAction={handleSkip}
        nextActionText="Continue"
      />
    </>
  );
};

const devicesColumns = ({ handleEdit, reviewOnly = false, emissionReductionActivity }) => {
  const renderCell = emissionReductionActivity === 'LOW_CARBON_FUEL'
    ? ({ value, ...rest }) => renderCellExpand({ value: <RequiredField reviewOnly={reviewOnly} value={value} />, ...rest })
    : ({ value }) => value;
  const cols = [
    {
      headerName: "Name",
      field: 'name',
      flex: 1,
      renderCell,
    },
    {
      headerName: "Manufacturer",
      field: 'manufacturer',
      flex: 1,
      renderCell,
      valueGetter: ({ value }) => startCase(toLower(value)),
    },
    {
      headerName: "Model",
      field: 'model',
      flex: 1,
      renderCell,
    },
    {
      headerName: "Serial Number",
      field: 'serialNumber',
      flex: 1,
      renderCell,
    },
  ];
  if (handleEdit) {
    cols.push({
      headerName: "Actions",
      field: "actions",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row: device }) => (
        <IconButton
          onClick={() => {
            handleEdit(device);
          }}
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
        >
          <EditIcon />
        </IconButton>
      ),
    })
  }
  return cols;
};

const EditDeviceForm = ({ device, close, siteId, customerProjectId }) => {
  const dispatch = useDispatch();
  // setup the form
  const { control, errors, handleSubmit } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: device?.name || "",
      model: device?.model || "",
      serialNumber: device?.serialNumber || "",
      manufacturer: device?.manufacturer || "",
    }
  });

  // submit patch
  const { getAccessTokenSilently } = useAuth0();
  const onSubmit = async (payload) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    for (const key in payload) {
      if (payload[key] === "") {
        payload[key] = null;
      }
    }
    dispatch(patchDevice({
      payload,
      accessToken,
      deviceId: device.id,
      siteId,
      customerProjectId
    }));
  };
  const { status } = useSelector((state) => state.patchDevice);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  return (
    <form noValidate autoComplete="off">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Device Name"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.name?.message)}
                  helperText={errors.name?.message}
                  required
                  onChange={onChange}
                  value={value}
                  color="secondary"
                />
              )}
              control={control}
              name="name"
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Manufacturer"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.manufacturer?.message)}
                  helperText={errors.manufacturer?.message}
                  onChange={onChange}
                  value={value}
                  color="secondary"
                />
              )}
              control={control}
              name="manufacturer"
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Model"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.model?.message)}
                  helperText={errors.model?.message}
                  onChange={onChange}
                  value={value}
                  color="secondary"
                />
              )}
              control={control}
              name="model"
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Serial Number"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.serialNumber?.message)}
                  helperText={errors.serialNumber?.message}
                  onChange={onChange}
                  value={value}
                  color="secondary"
                />
              )}
              control={control}
              name="serialNumber"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          handleClose={close}
          handleSubmit={() => handleSubmit(onSubmit)()}
        />
      </DialogActions>
    </form>
  )
};

export const EditDevice = ({ open, onClose, device, siteId, customerProjectId }) => {
  const dispatch = useDispatch();
  // close editor
  const handleClose = () => {
    dispatch(patchDevice.reset());
    onClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      TransitionComponent={DialogTransition}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          bgcolor: "background.dark",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            {`Edit Device ${device?.id}`}
          </Typography>
        </Toolbar>
      </AppBar>
      {device && (
        <EditDeviceForm
          device={device}
          close={handleClose}
          siteId={siteId}
          customerProjectId={customerProjectId}
        />
      )}
    </Dialog>
  );
};

export const ViewDevices = ({
    devices,
    siteId,
    customerProject,
    editable = false,
    reviewOnly = false,
    isLoading = false,
}) => {
  const [deviceToEdit, setEditing] = useState(null);
  const handleEdit = editable ? (device) => { setEditing(device); } : null;
  const closeEditor = () => { setEditing(null); };

  // table state params
  const [pageSize, setPageSize] = React.useState(5);
  return (
    <>
      {!reviewOnly && isEmpty(devices) && !isLoading && (
        <Alert
          severity="warning"
          variant="filled"
        >
          At least one device/meter/system is required to generate credits.
        </Alert>
      )}
      <CustomDataGrid
        rows={devices ?? []}
        columns={devicesColumns({
          handleEdit,
          emissionReductionActivity: customerProject?.emissionReductionActivity,
        })}
        autoHeight
        loading={isLoading}
        disableSelectionOnClick
        style={{ border: 0 }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 20, 50]}
      />
      {editable && (
        <EditDevice
          open={!!deviceToEdit}
          onClose={closeEditor}
          device={deviceToEdit}
          siteId={siteId}
          customerProjectId={customerProject?.id}
        />
      )}
    </>
  );
};

export const EditSiteAddDevices = ({ close, site, customerProject }) => {
  const dispatch = useDispatch();
  const [showReview, setShowReview] = useState(false);

  // close editor
  const handleClose = () => {
    dispatch(createDevices.reset());
    close();
  };

  // submit patch
  const { getAccessTokenSilently } = useAuth0();
  const { meters } = useSelector((state) => state.addDevicesForm);
  const onSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    dispatch(createDevices({
      payload: meters,
      accessToken,
      siteId: site.id,
      customerProjectId: customerProject.id
    }));
  };
  const { status } = useSelector((state) => state.createDevices);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  return (
    <DialogContent>
      {isSuccess ? (
        <>
          <Typography>
            Success! You have added new devices to your site. Feel free to close this window.
          </Typography>
          <Navigation nextAction={handleClose} nextActionText="Close" />
        </>
      ) : (
        showReview ? (
          <div>
            <ViewDevices
              devices={meters?.map(m => ({ ...m, id: m.id || uuid() }))}
              reviewOnly
            />
            <Navigation
              backAction={() => { setShowReview(false); }}
              nextAction={onSubmit}
              nextActionText="Submit"
              isSubmitting={isSubmitting}
            />
          </div>
        ) : (
          <AddDevices
            customerProject={customerProject}
            handleFinish={() => { setShowReview(true); }}
            handleBack={handleClose}
          >
            <Typography>
              Add new systems, devices, and/or meters to your existing site. Click "Continue" below to get started.
            </Typography>
          </AddDevices>
        )
      )}
    </DialogContent>
  );
};
