/**
 * Use next and prev to navigate between pages, when you want to record the pages for later traversal.
 * Use navigate if you don't want to record the page (eg going to Start, which then redirects to another page).
 */
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { next, prev, resetHistory } from "@views/common/actions";
import { nth } from "lodash";

// if we make another history, this is the only thing that needs changing
const key = "apply";

const usePrev = () => {
  const navigate = useNavigate();
  const history = useSelector((state) => state.history[key] || []);
  const dispatch = useDispatch();

  const func = (opts) => {
    const path = nth(history, -2);
    if (path) {
      dispatch(prev({ key }));
      navigate(path, opts);      
    } else {
      dispatch(resetHistory({ key }));
      navigate("/apply/start"); // todo: generify
    }
  };
  return useCallback(func, [navigate, dispatch, history]);
};

const useNext = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const func = (path, opts) => {
    dispatch(next({ path, key }));
    navigate(path, opts);
  };
  return useCallback(func, [navigate, dispatch]);
};

const usePrevPath = () => {
  const history = useSelector((state) => state.history[key]);
  return nth(history, -2);
};

const useResetHistory = () => {
  const dispatch = useDispatch();
  const func = () => {
    dispatch(resetHistory({ key }));
  };
  return useCallback(func, [dispatch]);
};

export { usePrev, useNext, usePrevPath, useResetHistory };
