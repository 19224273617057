import React from "react";
import PropTypes from "prop-types";
import {
  SolarEdgeInstructionPanel,
  UsernamePasswordInstructionPanel,
  EnphaseInstructionPanel,
  FroniusInstructionPanel,
  SmaInstructionPanel,
  CachelanInstructionPanel,
  WallboxInstructionPanel,
  ApsInstructionPanel,
  SwiftInstructionPanel,
  EnergSyncInstructionPanel,
} from "./instructions";

const InstructionPanel = ({ manufacturer }) => {
  switch (manufacturer) {
    case "SOLAREDGE":
      return <SolarEdgeInstructionPanel />;
    case "APS":
      return <ApsInstructionPanel />;
    case "HOYMILES":
      return <UsernamePasswordInstructionPanel manufacturer="HOYMILES" />;
    case "HUAWEI":
      return <UsernamePasswordInstructionPanel manufacturer="HUAWEI" />;
    case "REFUSOL":
      return <UsernamePasswordInstructionPanel manufacturer="REFUSOL" />;
    case "ENPHASE":
      return <EnphaseInstructionPanel />;
    case "FRONIUS":
      return <FroniusInstructionPanel />;
    case "SMA":
      return <SmaInstructionPanel />;
    case "CACHELAN":
      return <CachelanInstructionPanel />;
    case "WALLBOX":
      return <WallboxInstructionPanel />;
    case "SWIFT":
      return <SwiftInstructionPanel />;
    case "ENERGSYNC":
      return <EnergSyncInstructionPanel />;
    default:
      return null;
  }
};

InstructionPanel.propTypes = {
  manufacturer: PropTypes.string,
};

export default InstructionPanel;
