import React, { useReducer } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { isNil, uniq, map } from "lodash";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppMessage } from 'components/appMessage';
import { useAuth0 } from '@auth0/auth0-react';
import { addChannelPartnerInvite, getChannelPartnerInvites } from '../actions';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { documentsReducer } from "@addSites/sitePicker/components/DocumentUploader";
import DocumentUploader from "@addSites/sitePicker/containers/DocumentUploader";
import { useCallback } from 'react';
import { ChannelPartnerInviteTagProvider } from '@addSites/sitePicker/components/TagSelectorContext';
import { EMISSION_REDUCTION_ACTIVITIES } from '@views/program/components/Program';

const useYupValidationResolver = (validationSchema, termsAndConditions) =>
  useCallback(
    async (data) => {
      try {
        const opts = { abortEarly: false };
        const values = await validationSchema.validate({ termsAndConditions, ...data }, opts);
        return { values, errors: {} };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => {
              return {
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message
                }
              }
            },
            {}
          ),
        };
      }
    },
    [validationSchema, termsAndConditions]
  );

const inviteFormSchema = yup.object().shape({
  givenName: yup.string().max(60, "Too long.").required("First name is required."),
  familyName: yup.string().max(60, "Too long.").required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  notes: yup.string().max(255, "Too long."),
  contractDetails: yup.array()
    .min(1)
    .of(yup.object().shape({
      emissionsReductionActivity: yup.string().oneOf(Object.keys(EMISSION_REDUCTION_ACTIVITIES))
        .required("You must specify an emissions reduction activity"),
      percentage: yup.number()
        .transform((value) => isNaN(value) ? undefined : value)
        .min(0).max(100, "Contract percentage cannot exceed 100 %")
        .required("You must specify the channel partner's percentage")
    }))
    .required("At least one set of contract details is required"),
  termsAndConditions: yup.array()
    .min(1, "At least one signed terms and conditions is required.")
    .of(yup.object().shape({
      uri: yup.string().required(),
      file: yup
        .mixed()
        .test("path", "File path is required", (f) => !isNil(f.path)),
    }))
    .required("At least one signed terms and conditions is required.")
})
.test({
  name: 'uniqueActivities',
  test: function ({ contractDetails }) {
    if (contractDetails) {
      const detailsCount = contractDetails.length;
      const activities = contractDetails.map(({ emissionsReductionActivity }) => emissionsReductionActivity);
      const uniqActivities = uniq(activities);
      if (uniqActivities?.length !== detailsCount) {
        return this.createError({
          message: 'Unique emissions reductions activities is required',
          path: 'contractDetails'
        });
      }
    }
    return true;
  }
});

const InviteeDetails = ({ errors, control }) => (
  <Grid item xs={12}>
    <Typography>Invitee Details</Typography>
    <Controller
      render={({ onChange, value }) => (
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          error={!isNil(errors.givenName?.message)}
          label="First Name"
          placeholder="John"
          helperText={errors.givenName?.message}
          margin="dense"
          required
          onChange={onChange}
          value={value}
          color="secondary"
        />
      )}
      control={control}
      name="givenName"
    />
    <Controller
      render={({ onChange, value }) => (
        <TextField
          variant="standard"
          fullWidth
          error={!isNil(errors.familyName?.message)}
          label="Last Name"
          placeholder="Doe"
          helperText={errors.familyName?.message}
          margin="dense"
          required
          onChange={onChange}
          value={value}
          color="secondary"
        />
      )}
      control={control}
      name="familyName"
    />
    <Controller
      render={({ onChange, value }) => (
        <TextField
          variant="standard"
          fullWidth
          error={!isNil(errors.email?.message)}
          label="Email"
          placeholder="john@company.org"
          helperText={errors.email?.message}
          margin="dense"
          required
          onChange={onChange}
          value={value}
          color="secondary"
        />
      )}
      control={control}
      name="email"
    />
    <Controller
      render={({ onChange, value }) => (
        <TextField
          variant="standard"
          fullWidth
          error={!isNil(errors.notes?.message)}
          label="Notes"
          placeholder="Hi John, please accept my invitation to become a channel partner with Rewatt."
          helperText="A short note to the invitee (optional)"
          margin="dense"
          required
          onChange={onChange}
          value={value}
          color="secondary"
        />
      )}
      control={control}
      name="notes"
    />
  </Grid>
);

const ContractDetails = ({ errors, control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contractDetails"
  });

  return (
    <Grid item xs={12}>
      <Box>
        <FormLabel error={!isNil(errors.contractDetails?.message) || !isNil(errors.uniqueActivities?.message)}>Contract Details</FormLabel>
        {errors.contractDetails && <FormHelperText error>{errors.contractDetails.message}</FormHelperText>}
        {errors.uniqueActivities && <FormHelperText error>{errors.uniqueActivities.message}</FormHelperText>}
      </Box>
      {fields.map((contractDetails, index) => (
        <Grid container key={contractDetails.id} spacing={1} alignItems="center">
          <Grid item xs={6}>
            <Controller
              render={({ onChange, value }) => (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id={`emissions-reduction-selection-${index}-label-id`} color="secondary" required>
                    Emissions Reduction Activity
                  </InputLabel>
                  <Select
                    labelId={`emissions-reduction-selection-${index}-label`}
                    id={`emissions-reduction-selection-${index}`}
                    value={value}
                    label="Emissions Reduction Activity"
                    onChange={onChange}
                  >
                    {map(EMISSION_REDUCTION_ACTIVITIES, (readable, activity) => (
                      <MenuItem key={activity} value={activity}>{readable}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              control={control}
              name={`contractDetails[${index}].emissionsReductionActivity`}
              defaultValue=""
            />
            {errors.contractDetails?.[index]?.emissionsReductionActivity?.message && (
              <FormHelperText error>
                {errors.contractDetails[index].emissionsReductionActivity.message}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.contractDetails?.[index]?.percentage?.message)}
                  helperText={errors.contractDetails?.[index]?.percentage?.message ? errors.contractDetails[index].percentage.message : ""}
                  label="Percentage"
                  placeholder=""
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  margin="dense"
                  value={value}
                  onChange={onChange}
                  color="secondary"
                />
              )}
              control={control}
              name={`contractDetails[${index}].percentage`}
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              size="small"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <IconButton
        size="small"
        onClick={() => { append({ emissionsReductionActivity: "" }); }}
      >
        <AddIcon />
      </IconButton>
    </Grid>
  );
};

const TermsAndConditionsUploader = ({ termsAndConditions, dispatchDocsAction, errors }) => (
  <Grid item xs={12}>
    <Box>
      <FormLabel error={!isNil(errors.termsAndConditions?.message)}>Upload Terms and Conditions</FormLabel>
      {errors.termsAndConditions && <FormHelperText error>{errors.termsAndConditions.message}</FormHelperText>}
    </Box>
    <ChannelPartnerInviteTagProvider>
      <DocumentUploader
        documents={termsAndConditions}
        dispatch={dispatchDocsAction}
        defaultTags={["Terms and Condition"]}
      />
    </ChannelPartnerInviteTagProvider>
  </Grid>
);

const InviteChannelPartner = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();
  const { getAccessTokenSilently } = useAuth0();
  const [termsAndConditions, dispatchDocsAction] = useReducer(
    documentsReducer,
    []
  );

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: useYupValidationResolver(inviteFormSchema, termsAndConditions),
    mode: "onChange",
    defaultValues: {
      givenName: "",
      familyName: "",
      email: "",
      notes: "",
      contractDetails: [],
    },
  });

  const onSubmit = async (invite) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_sites",
    });
    dispatch(addChannelPartnerInvite({ accessToken, invite }));
  };

  const { status } = useSelector((state) => state.addChannelPartnerInvite);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleDone = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_sites",
    });

    dispatch(addChannelPartnerInvite.reset());
    dispatch(getChannelPartnerInvites({ accessToken }));
    onClose();
  };

  const handleCancel = () => {
    onClose();
    reset();
    dispatchDocsAction({
      type: "reset",
      payload: [],
    });
  }

  useEffect(() => {
    if (isSuccess) {
      showAppMessage({
        severity: "success",
        message: "Successfully invited channel partner. Please press done.",
      });
    }
  }, [isSuccess, showAppMessage]);

  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="invite-channel-partner-form">
      <DialogTitle id="invite-channel-partner-form">Invite a Channel Partner</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={5}>
          <InviteeDetails errors={errors} control={control} />
          <TermsAndConditionsUploader
            errors={errors}
            termsAndConditions={termsAndConditions}
            dispatchDocsAction={dispatchDocsAction}
          />
          <ContractDetails errors={errors} control={control} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box mr={2} mb={1}>
          <Button
            onClick={handleCancel}
            variant="text"
            disabled={isSubmitting || isSuccess}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          {!isSuccess && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(onSubmit)()}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Invite
            </LoadingButton>
          )}
          {isSuccess && (
            <Button variant="contained" color="primary" onClick={handleDone}>
              Done
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};



export default InviteChannelPartner;
