import { getTimeZone } from "../actions";

export const initialState = {
  status: "init",
  timeZone: {
    timeZoneId: "",
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getTimeZone.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getTimeZone.SUCCESS: {
      const { timeZone } = action.payload;
      return {
        ...state,
        status: "success",
        timeZone,
      };
    }

    case getTimeZone.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
