import getChannelPartnerInvites from "./getChannelPartnerInvites";
import addChannelPartnerInvite from "./addChannelPartnerInvite";
import resendChannelPartnerInvite from "./resendChannelPartnerInvite";
import deleteChannelPartnerInvite from "./deleteChannelPartnerInvite";
import getChannelPartners from "./getChannelPartners";
import getChannelPartner from "./getChannelPartner";
import getChannelPartnerReferrals from "./getChannelPartnerReferrals";

export default [
  ...getChannelPartnerInvites,
  ...addChannelPartnerInvite,
  ...resendChannelPartnerInvite,
  ...deleteChannelPartnerInvite,
  ...getChannelPartners,
  ...getChannelPartner,
  ...getChannelPartnerReferrals,
];
