import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { isNil } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from '@mui/lab/LoadingButton';

const schema = yup.object().shape({
  givenName: yup
    .string()
    .max(60, "Too long.")
    .required("First name is required."),
  familyName: yup
    .string()
    .max(60, "Too long.")
    .required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  notes: yup.string().max(255, "Too long."),
});

const AddInvite = ({ addInvite, addInviteResult, resetAddInvite }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetAddInvite();
    setOpen(false);
  };

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = (invite) => {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }).then((accessToken) => {
      addInvite({ accessToken, invite });
    });
  };

  const isSubmitting = addInviteResult.status === "request";
  const isSuccess = addInviteResult.status === "success";

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Invite Member
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-invite-form"
      >
        <DialogTitle id="add-invite-form">Invite Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invite a colleague to join your organization. We'll send an email
            with further instructions, on your behalf.
          </DialogContentText>
          <TextField
            name="givenName"
            variant="standard"
            fullWidth
            inputRef={register}
            autoFocus
            error={!isNil(errors.givenName?.message)}
            label="First Name"
            placeholder="John"
            helperText={errors.givenName?.message}
            margin="dense"
          />
          <TextField
            name="familyName"
            variant="standard"
            fullWidth
            inputRef={register}
            error={!isNil(errors.familyName?.message)}
            label="Last Name"
            placeholder="Doe"
            helperText={errors.familyName?.message}
            margin="dense"
          />
          <TextField
            name="email"
            variant="standard"
            fullWidth
            inputRef={register}
            error={!isNil(errors.email?.message)}
            label="Email"
            placeholder="john@company.org"
            helperText={errors.email?.message}
            margin="dense"
            type="email"
          />
          <TextField
            name="notes"
            variant="standard"
            fullWidth
            inputRef={register}
            error={!isNil(errors.notes?.message)}
            label="Notes"
            placeholder="Hi John, please join my organization!"
            helperText={
              errors.notes?.message ||
              "A short note to your colleague (optional)"
            }
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Box mr={2} mb={1}>
            <Button
              onClick={handleClose}
              variant="text"
              disabled={isSubmitting}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            {!isSuccess && (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(onSubmit)()}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Invite
              </LoadingButton>
            )}
            {isSuccess && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Done
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddInvite.propTypes = {
  addInvite: PropTypes.func.isRequired,
  addInviteResult: PropTypes.shape({
    status: PropTypes.string,
    invite: PropTypes.shape({}),
  }),
  resetAddInvite: PropTypes.func.isRequired,
};

export default AddInvite;
