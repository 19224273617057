import { call, put, takeLatest } from "redux-saga/effects";
import { getLiveEnergyToday } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { DateTime } from "luxon";
import { reduce } from "lodash";

const callApi = async (siteId, startDate, endDate) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/public/assets/${siteId}/chart`,
    {
      params: {
        start: startDate.toISODate(),
        end: endDate.toISODate(),
      },
    }
  );
  return response;
};

const transform = (data) => ({
  generated: reduce(data, (sum, datum) => sum + datum.generation, 0),
  exported: reduce(data, (sum, datum) => sum + datum.export, 0),
  imported: reduce(data, (sum, datum) => sum + datum.consumption, 0),
});

export function* getLiveEnergyTodayWorker(action) {
  yield put(getLiveEnergyToday.request());

  const { siteId } = action.payload;
  const startDate = DateTime.now().startOf("day");
  const endDate = DateTime.now();

  try {
    const response = yield call(callApi, siteId, startDate, endDate);
    const { data } = response.data;
    yield put(getLiveEnergyToday.success(transform(data)));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting live energy today: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting live energy today: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting live energy today: ", err);
      message = err.message;
    }
    message = `Unable to get live energy today: ${message}`;
    yield put(getLiveEnergyToday.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getLiveEnergyTodayWatcher() {
  yield takeLatest(getLiveEnergyToday.TRIGGER, getLiveEnergyTodayWorker);
}

export default [getLiveEnergyTodayWatcher];
