import React, { useRef, useState } from "react";
import { Typography, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CalculationByCreditRequest from "./CalculationByCreditRequest";
import { isNil, debounce } from "lodash";
import ReportingSheetTemplateUploader from "./ReportingSheetTemplateUploader";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { getProjectReportCalculation } from "@views/admin/project/actions";

const ByCreditRequest = ({ project, startDate }) => {
  const { errors, control } = useFormContext();
  const isAlbertaEmissionsOffset = project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";
  const creditsRef = useRef();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [credits, setCredits] = useState(0);

  const handleChange = useRef(
    debounce(async (credits) => {
      if (credits) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "admin_project_reports",
        });
        dispatch(
          getProjectReportCalculation({
            accessToken,
            projectId: project.id,
            startDate,
            credits,
          })
        );
        setCredits(credits * 1);
      }
    }, 300)
  ).current;

  return (
    <>
      <Typography variant="h2">How many credits are you requesting?</Typography>
      <Typography my={1}>Enter a number, and we will determine the optimal end date.</Typography>
      <form noValidate autoComplete="off">
        <Controller
          render={({ onChange, value }) => (
            <input type="hidden" value={value} onChange={onChange} />
          )}
          control={control}
          name="calculateByDateRange"
        />
        <Grid container spacing={3} sx={{ maxWidth: 600 }}>
          <Grid item xs={12} md={6}>
            <Controller
              render={({ onChange, value }) => (
                <TextField
                  label="Credits"
                  inputRef={creditsRef}
                  required
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.credits?.message)}
                  helperText={errors.credits?.message || "Number of credits requested"}
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e.target.value);
                  }}
                  value={value}
                  color="primary"
                  type="number"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      creditsRef.current.blur();
                      e.preventDefault();
                    }
                  }}
                />
              )}
              control={control}
              name="credits"
            />
          </Grid>
          {isAlbertaEmissionsOffset && (
            <Grid item xs={12}>
              <Controller
                render={({ onChange, value }) => (
                  <ReportingSheetTemplateUploader
                    onChange={onChange}
                    value={value}
                    error={errors.aggregatedReportingSheetTemplate?.message}
                  />
                )}
                control={control}
                name="aggregatedReportingSheetTemplate"
              />
            </Grid>
          )}
        </Grid>
      </form>
      <CalculationByCreditRequest project={project} credits={credits} />
    </>
  );
};

export default ByCreditRequest;
