import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";

const EnphaseAuthorizationCodePanel = ({ credentials }) => {
  const { errors, control } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="Authorization Code"
              variant="standard"
              fullWidth
              placeholder=""
              autoFocus
              error={!isNil(errors.authCode?.message)}
              helperText={errors.authCode?.message || " "}
            />
          }
          name="authCode"
          control={control}
          rules={{ required: "Authorization Code is required." }}
          defaultValue={credentials.authCode}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

EnphaseAuthorizationCodePanel.propTypes = {
  credentials: PropTypes.shape({
    authCode: PropTypes.string,
  }).isRequired,
};

export default EnphaseAuthorizationCodePanel;
