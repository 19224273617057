import { connect } from "react-redux";
import Welcome from "../components/Welcome";
import { bindActionCreators } from "redux";
import { getProgram } from "../actions";
import { getUserProfile, getOrganization } from "@views/common/actions";
import { getMyApplications } from "@views/myApplications/actions";

const mapStateToProps = ({ getProgram: getProgramResult }) => ({
  getProgramResult,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getProgram,
    },
    dispatch
  ),
  resetAll: () => {
    dispatch(getUserProfile.reset());
    dispatch(getOrganization.reset());
    dispatch(getMyApplications.reset());
    dispatch(getProgram.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
