import { createRoutine, createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const getBuyerInvites = createRoutine("GET_BUYER_INVITES");
const getOrganizations = createRoutine("GET_ORGANIZATIONS");
const addBuyerInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])("ADD_BUYER_INVITE");
const resendBuyerInvite = createRoutine("RESEND_BUYER_INVITE");
const deleteBuyerInvite = createRoutine("DELETE_BUYER_INVITE");
const getAdminPurchaseRequests = createRoutine("GET_ADMIN_PURCHASE_REQUESTS");
const getPurchaseRequestForProjectReport = createRoutineCreator([...defaultRoutineStages, "PRELOAD", "RESET"])(
  "GET_PURCHASE_REQUEST_FOR_PROJECT_REPORT"
);
const getProjectReportsForStatements = createRoutine("GET_PROJECT_REPORTS_FOR_STATEMENTS");
const getProjectReportForStatements = createRoutineCreator([...defaultRoutineStages, "PRELOAD", "RESET"])("GET_PROJECT_REPORT_FOR_STATEMENTS");
const createPayeeStatement = createRoutine("CREATE_PAYEE_STATEMENT");
const downloadEft = createRoutine("DOWNLOAD_EFT");
const sendPayeeStatements = createRoutine("SEND_PAYEE_STATEMENTS");

const updatePurchaseRequestStatus = createRoutineCreator(
  [...defaultRoutineStages, "RESET"]
)("UPDATE_PURCHASE_REQUEST_STATUS");

export {
  getBuyerInvites,
  addBuyerInvite,
  getOrganizations,
  resendBuyerInvite,
  deleteBuyerInvite,
  getAdminPurchaseRequests,
  getProjectReportsForStatements,
  getProjectReportForStatements,
  createPayeeStatement,
  updatePurchaseRequestStatus,
  sendPayeeStatements,
  downloadEft,
  getPurchaseRequestForProjectReport,
};
