import { createRoutine, createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const getClaimedProjectReports = createRoutine("GET_CLAIMED_PROJECT_REPORTS");

const createCertificate = createRoutineCreator([...defaultRoutineStages, "RESET"])("CREATE_CERTIFICATE");
const patchCertificate = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_CERTIFICATE");
const deleteCertificate = createRoutineCreator([...defaultRoutineStages, "RESET"])("DELETE_CERTIFICATE");

export {
  getClaimedProjectReports,
  createCertificate,
  patchCertificate,
  deleteCertificate,
};
