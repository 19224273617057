import verifyBuyerInviteToken from "./verifyBuyerInviteToken";
import acceptBuyerInvite from "./acceptBuyerInvite";
import addBuyerOrganization from "./addBuyerOrganization";

const reducers = {
  verifyBuyerInviteToken,
  acceptBuyerInvite,
  addBuyerOrganization,
};

export default reducers;
