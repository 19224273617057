import React, { useEffect, useMemo } from "react";
import {
  Stack,
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { isNil } from "lodash";
import { useForm, Controller } from "react-hook-form";
import LogoUploader from "@views/common/components/LogoUploader";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "@components/TextArea";
import { uploadCorporateLogo } from "@admin/projectReport/actions";
import { createCertificate, patchCertificate } from "../actions";

const schema = Yup.object().shape({
  title: Yup.string().max(255, "Too long.").required("Title is required."),
  message: Yup.string().max(4096, "Too long.").required("Message is required."),
  logo: Yup.object().required("A logo is required"),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CertificateEditor = ({ open, close, onSave, certificate, projectReportId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const mode = isNil(certificate) ? "create" : "patch";

  const defaultValues = useMemo(
    () => ({
      title: certificate?.title || "",
      message: certificate?.message || "",
      logo: certificate?.logo || null, // filespec
    }),
    [certificate]
  );

  const { control, errors, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleSave = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "manage_credits",
    });

    const certificate = {
      title: data.title,
      message: data.message,
      logo: {
        name: data.logo.file?.name || data.logo.name,
        tags: ["Logo", "Corporate"],
        uri: data.logo.uri,
      },
    };

    const action = mode === "create" ? createCertificate : patchCertificate;
    dispatch(
      action({
        certificate,
        accessToken,
        projectReportId,
      })
    );
  };

  const { status, certificate: savedCertificate } = useSelector((state) =>
    mode === "create" ? state.createCertificate : state.patchCertificate
  );
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleClose = () => {
    onSave(savedCertificate);
    close();
  };

  return (
    <Dialog open={open} onClose={close} TransitionComponent={Transition} fullWidth maxWidth="md">
      <DialogTitle>Customize your certificate of authenticity</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Controller
                render={({ onChange, value }) => (
                  <LogoUploader
                    title="Corporate logo*"
                    onChange={onChange}
                    value={value}
                    error={errors.logo?.message}
                    discriminator="CORPORATE_LOGO"
                    uploadLogo={uploadCorporateLogo}
                    selector={(state) => state.uploadCorporateLogo}
                  />
                )}
                control={control}
                name="logo"
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Stack direction="column" spacing={2}>
                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      label="Title"
                      variant="standard"
                      fullWidth
                      error={!isNil(errors.title?.message)}
                      helperText={errors.title?.message}
                      required
                      onChange={onChange}
                      value={value}
                      color="secondary"
                    />
                  )}
                  control={control}
                  name="title"
                />

                <Controller
                  render={({ onChange, value }) => (
                    <TextArea
                      label="Mission Statement"
                      rows={4}
                      error={!isNil(errors.message?.message)}
                      helperText={errors.message?.message}
                      required
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 4096,
                      }}
                      placeholder={`Add your mission statement here. \
This will be seen on the certificate of authenticity.`}
                      rounded={false}
                    />
                  )}
                  control={control}
                  name="message"
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  aria-label="cancel"
                  variant="text"
                  disabled={isSubmitting}
                  onClick={close}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  aria-label="save"
                  variant="contained"
                  color="primary"
                  sx={{ mr: 1 }}
                  disabled={isSubmitting}
                  onClick={() => (isSuccess ? handleClose() : handleSubmit(handleSave)())}
                  loading={isSubmitting}
                >
                  {isSuccess ? "Done" : "Save"}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CertificateEditor;
