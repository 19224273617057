// You need to make sure these vars are in your env when doing the webpack build
// `source .env.staging``
const authConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  // appOrigin: '{OPTIONAL: THE BASE URL OF YOUR APPLICATION (default: http://localhost:3000)}',
  // apiOrigin: '{OPTIONAL: THE BASE URL OF YOUR API (default: http://localhost:3001)}',
};

export default authConfig;
