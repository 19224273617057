import { setSitesTableState } from "../actions";

export const initialState = {
  page: 0,
  pageSize: 10,
  sortModel: [],
  filterModel: {
    items: [
    ],
    linkOperator: "and",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setSitesTableState.TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
