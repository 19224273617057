import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { isNull } from "lodash";

const StepNav = ({ backAction, nextAction }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      mt={4}
      pt={2}
      borderTop={1}
      borderColor="divider"
    >
      <Button variant="text" onClick={backAction} disabled={isNull(backAction)}>
        Back
      </Button>
      <Button variant="contained" color="primary" onClick={nextAction}>
        Next
      </Button>
    </Box>
  );
};

StepNav.defaultProps = {
  backAction: null,
};

StepNav.propTypes = {
  backAction: PropTypes.func,
  nextAction: PropTypes.func.isRequired,
};

export default StepNav;
