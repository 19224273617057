import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import EditIcon from "@mui/icons-material/Edit";
import { getFullName } from "@utils/stringHelpers";
import SiteProfileEditor from "./SiteProfileEditor";
import Address from "@components/Address";
import { PhoneLink, EmailLink } from "@components";

const SiteProfile = ({ siteProfile, updateSiteProfile }) => {
  // add sites action
  const [isSiteProfileEditorOpen, setIsSiteProfileEditorOpen] = useState(false);
  const handleEdit = () => {
    setIsSiteProfileEditorOpen(true);
  };
  const handleClose = () => {
    setIsSiteProfileEditorOpen(false);
  };

  const handleSave = (data) => {
    updateSiteProfile(data);
    handleClose();
  };

  const tableRows = [
    {
      label: "Site Owner Name",
      value: getFullName(siteProfile?.lastName, siteProfile?.firstName),
    },
    {
      label: "Email",
      value: <EmailLink email={siteProfile?.email} />,
    },
    {
      label: "Phone Number",
      value: <PhoneLink phone={siteProfile?.phone} />
    },
    {
      label: "Site Name",
      value: siteProfile?.name,
    },
    {
      label: "Site Address",
      value: <Address address={siteProfile?.address} />,
    },
    {
      label: "Equipment Ownership",
      value: siteProfile?.equipmentOwnership || "N/A",
    },
    {
      label: "Grant/Funding Source",
      value: siteProfile?.fundingSource || "N/A",
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site profile
          </Typography>
          <IconButton
            aria-label="edit"
            color="primary"
            sx={{ mt: -1.5 }}
            onClick={handleEdit}
            size="large"
          >
            <EditIcon />
          </IconButton>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">{row.label}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {siteProfile && (
        <SiteProfileEditor
          open={isSiteProfileEditorOpen}
          onClose={handleClose}
          onSave={handleSave}
          siteProfile={siteProfile}
        />
      )}
    </>
  );
};

SiteProfile.propTypes = {
  siteProfile: PropTypes.shape({}),
  updateSiteProfile: PropTypes.func.isRequired,
};

export default SiteProfile;
