import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Link,
  Button,
  Tooltip,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { getBuyerProjectReport } from "@views/marketplace/purchaseCredits/actions";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { reduce, get } from "lodash";
import { PROTOCOLS } from "@views/admin/project/components/Project";
import ReviewProjectReport from "./ReviewProjectReport";

const TableHeader = styled(TableCell)(() => ({
  fontWeight: "bold",
}));

const ProjectReportDetailPanel = ({ projectReportSummary }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(getBuyerProjectReport({ accessToken, projectReportId: projectReportSummary.id }));
    }
    init();
  }, [getAccessTokenSilently, dispatch, projectReportSummary.id]);

  const { statuses, projectReports } = useSelector((state) => {
    return state.getBuyerProjectReport;
  });
  const status = statuses[projectReportSummary.id];
  const projectReport = projectReports[projectReportSummary.id];
  const isLoading = status === "request";

  const total = reduce(projectReport?.vintages, (sum, { credits }) => sum + credits, 0);

  const [isReviewProjectReportOpen, setIsReviewProjectReportOpen] = useState(false);
  const handleOpenReviewProjectReport = () => {
    setIsReviewProjectReportOpen(true);
  };
  const handleCloseReviewProjectReport = () => {
    setIsReviewProjectReportOpen(false);
  };

  return (
    <Box height={300} mx={3} mt={1}>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" gutterBottom>
              Vintages
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableHeader>Year</TableHeader>
                    <TableHeader align="right">Credits</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectReport?.vintages?.map((row) => (
                    <TableRow key={row.year}>
                      <TableCell>{row.year}</TableCell>
                      <TableCell align="right">{row.credits.toFixed(3)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableHeader>Total</TableHeader>
                    <TableHeader align="right">{total.toFixed(3)}</TableHeader>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" gutterBottom>
              Details
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableHeader>Registry</TableHeader>
                    <TableCell>
                      <Link href={projectReport?.project?.registry?.url} target="_new">
                        {projectReport?.project?.registry?.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Protocol</TableHeader>
                    <TableCell>
                      <Link href={projectReport?.project?.protocol?.url} target="_new">
                        {get(PROTOCOLS, projectReport?.project?.protocol?.name)}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Governing Authority</TableHeader>
                    <TableCell>
                      <Link href={projectReport?.project?.authority?.url} target="_new">
                        {projectReport?.project?.authority?.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableHeader>Number of Participating Sites</TableHeader>
                    <TableCell>{projectReport?.numberOfSites}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mb={1}>
              <Tooltip title="Review this Project Report and Purchase its credits.">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleOpenReviewProjectReport}
                >
                  Purchase
                </Button>
              </Tooltip>
              <ReviewProjectReport
                open={isReviewProjectReportOpen}
                onClose={handleCloseReviewProjectReport}
                projectReport={projectReport}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ProjectReportDetailPanel;
