import { call, put, takeLatest, select } from "redux-saga/effects";
import { patchAppSiteDocuments } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getApplication } from "../selectors";

const callApi = async (accessToken, documents, applicationId) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${applicationId}`,
    {
      application: {
        asset: {
          documents,
        },
      },
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* patchAppSiteDocumentsWorker(action) {
  yield put(patchAppSiteDocuments.request());

  const { accessToken, documents } = action.payload;
  const { id: applicationId } = yield select(getApplication);

  try {
    const response = yield call(
      callApi,
      accessToken,
      documents.map((doc) => ({ ...doc, name: doc.name || doc.file?.path })),
      applicationId
    );
    yield put(patchAppSiteDocuments.success({ application: response.data }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error updating documents: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error updating documents: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error updating documents: ", err);
      message = err.message;
    }
    message = `Unable to update documents: ${message}`;
    yield put(patchAppSiteDocuments.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchAppSiteDocumentsWatcher() {
  yield takeLatest(patchAppSiteDocuments.TRIGGER, patchAppSiteDocumentsWorker);
}

export default [patchAppSiteDocumentsWatcher];
