import { createRoutine, createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";
import { createAction } from "redux-actions";

const getFleetStats = createRoutine("GET_ADMIN_STATS");
const getOnboardingChart = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_ONBOARDING_CHART"
);
const getDataConnectorHealth = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_DATA_CONNECTOR_HEALTH"
);
const getSitesByAdmin = createRoutine("GET_SITES_BY_ADMIN");
const setSitesByAdminTableState = createAction("SET_SITES_BY_ADMIN_TABLE_STATE");
setSitesByAdminTableState.TYPE = setSitesByAdminTableState.toString();
const addAssetInvite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ADD_ASSET_INVITE"
);

export {
  getFleetStats,
  getOnboardingChart,
  getDataConnectorHealth,
  getSitesByAdmin,
  setSitesByAdminTableState,
  addAssetInvite,
};
