import { call, put, takeLatest } from "redux-saga/effects";
import { getProjectReportCertificate } from "../actions";
import axios from "axios";
import logger from "debug";

const callApi = async (projectReportId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/public/projectReports/${projectReportId}/certificate`
  );
  return response;
};

function* getProjectReportCertificateWorker(action) {
  yield put(getProjectReportCertificate.request());

  const { projectReportId } = action.payload;

  try {
    const response = yield call(callApi, projectReportId);
    const certificate = response.data;
    yield put(getProjectReportCertificate.success({ certificate }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting certificate details: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting certificate details: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting certificate details: ", err);
      message = err.message;
    }
    message = `Unable to get certificate details: ${message}`;
    yield put(getProjectReportCertificate.failure({ error: message }));
  }
}

function* getProjectReportCertificateWatcher() {
  yield takeLatest(getProjectReportCertificate.TRIGGER, getProjectReportCertificateWorker);
}

export default [getProjectReportCertificateWatcher];
