import getProgram from "./getProgram";
import patchProgram from "./patchProgram";
import getProgramSiteStatuses from "./getProgramSiteStatuses";
import getProgramSites from "./getProgramSites";
import getQualifiedSites from "./getQualifiedSites";
import inviteQualifiedSites from "./inviteQualifiedSites";

export default [
  ...patchProgram,
  ...getProgram,
  ...getProgramSiteStatuses,
  ...getProgramSites,
  ...getQualifiedSites,
  ...inviteQualifiedSites,
];
