import { createRoutineCreator, defaultRoutineStages, createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

const getProgram = createRoutineCreator([...defaultRoutineStages, "RESET"])("GET_PROGRAM");
const getProgramSiteStatuses = createRoutineCreator([...defaultRoutineStages, "RESET"])("GET_PROGRAM_SITE_STATUSES");
const getProgramSites = createRoutine("GET_PROGRAM_SITES");
const getQualifiedSites = createRoutine("GET_QUALIFIED_SITES");
const inviteQualifiedSites = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "INVITE_QUALIFIED_SITES"
);

const patchProgram = createRoutineCreator([...defaultRoutineStages, "RESET"])("PATCH_PROGRAM");

const setApplicationsTableState = createAction("SET_APPLICATION_TABLE_STATE");
setApplicationsTableState.TYPE = setApplicationsTableState.toString();

const setProgramSitesTableState = createAction("SET_PROGRAM_SITES_TABLE_STATE");
setProgramSitesTableState.TYPE = setProgramSitesTableState.toString();

export {
  getProgram,
  patchProgram,
  getProgramSiteStatuses,
  getProgramSites,
  setApplicationsTableState,
  setProgramSitesTableState,
  getQualifiedSites,
  inviteQualifiedSites,
};
