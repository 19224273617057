import { useAuth0 } from "@auth0/auth0-react";
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ENVIRONMENTAL_CREDIT_TYPES } from "views/admin/project/components/Project";
import { getPurchaseSummary } from "../actions";

const currencyOptions = {
  style: "currency",
  currency: "USD", // to get rid of the eg CAD prefix
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const TableLoading = () => (
  <TableBody>
    <TableRow>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
    </TableRow>
  </TableBody>
);

const PurchaseSummary = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { status, purchaseSummary } = useSelector((state) => state.getPurchaseSummary);
  const isLoading = status === "request";
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(getPurchaseSummary({ accessToken }));
    }
    init();
  }, [getAccessTokenSilently, dispatch]);

  const totalEnvironmentalCredits = purchaseSummary.reduce(
    (total, row) => total + row.totalEnvironmentalCredits,
    0
  );
  const totalCost = purchaseSummary.reduce((total, row) => total + row.totalCost, 0);

  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} sx={{ maxWidth: "sm" }}>
      <Table aria-label="Purchase Summary" sx={{ "& th": { fontWeight: 800 } }}>
        <TableHead>
          <TableRow>
            <TableCell>Credit Type</TableCell>
            <TableCell align="right">Credits</TableCell>
            <TableCell align="right">Total Spend</TableCell>
          </TableRow>
        </TableHead>
        {isLoading && <TableLoading />}
        {!isLoading && (
          <TableBody>
            {purchaseSummary.map(({ creditType, totalEnvironmentalCredits, totalCost }) => (
              <TableRow key={creditType}>
                <TableCell>{ENVIRONMENTAL_CREDIT_TYPES[creditType]}</TableCell>
                <TableCell align="right">{totalEnvironmentalCredits.toLocaleString()}</TableCell>
                <TableCell align="right">
                  {totalCost.toLocaleString(undefined, currencyOptions)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        {!isLoading && !purchaseSummary.length && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}>You have not purchased any credits</TableCell>
            </TableRow>
          </TableBody>
        )}
        <TableFooter sx={{ "& td": { fontSize: "0.875rem" } }}>
          <TableRow>
            <TableCell>Grand Total</TableCell>
            <TableCell align="right">{totalEnvironmentalCredits.toLocaleString()}</TableCell>
            <TableCell align="right">
              {totalCost.toLocaleString(undefined, currencyOptions)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <Box display="flex">
        <Box ml="auto" mr={0} >
          <Button
            sx={{ }}
            onClick={() => {
              navigate("/marketplace/claimedCredits");
            }}
          >
            View
          </Button>
        </Box>
      </Box>
    </TableContainer>
  );
};

export default PurchaseSummary;
