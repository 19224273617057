import React from "react";
import { Typography, Box, List, ListItem, ListItemIcon, ListItemText, Link } from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";

const FroniusInstructionPanel = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Instructions to Grant Rewatt Access
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Login to the Fronius SolarWeb platform, using this link"
            secondary={
              <Link href="https://www.solarweb.com" target="_blank" rel="noopener">
                https://www.solarweb.com
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText primary="Click on the site you want to onboard with Rewatt" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Share with Rewatt"
            secondary="Navigate to Settings (not User Settings) -> Permissions -> Add"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="For E-mail address, enter service@rewattpower.com"
            secondary="For rights, select Guest and click OK"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Copy and paste the link URL from the browser (NOT the guest access URL)"
            secondary="eg. https://www.solarweb.com/PvSystemSettings/Permissions?pvSystemId=72f5bd60-3112-4c44-ad89-a64b01843ab6"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="For more detailed instructions and screenshots, see this page."
            secondary={
              <Link href="https://rewattpower.com/fronius/" target="_blank" rel="noopener">
                https://rewattpower.com/fronius/
              </Link>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default FroniusInstructionPanel;
