import React, { useEffect, useRef, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { Box, Tabs, Tab, CircularProgress, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { DateTime } from "luxon";
import { find, filter, map, findIndex, reduce, values, includes } from "lodash";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { round } from "@utils/numberHelpers";
import { PeriodFlipper } from "@views/dashboard";
import CustomDateRangePicker from "@components/CustomDateRangePicker";

export const SERIES = [
  {
    name: "Generation",
    stat: "generation",
  },
  {
    name: "Grid Import",
    stat: "consumption",
  },
  {
    name: "Grid Export",
    stat: "export",
  },
  {
    name: "Temperature",
    stat: "temperature",
  },
  {
    name: "Snow",
    stat: "snow",
  },
  {
    name: "Daylight",
    stat: "daylight",
  },
  {
    name: "Emission Reductions",
    stat: "emissionReductions",
  },
];

// tabs
export const tabs = [
  {
    name: "day",
  },
  {
    name: "week",
  },
  {
    name: "month",
  },
  {
    name: "year",
  },
  {
    name: "custom",
  },
];

const xAxisFormatter = (value, granularity, format) => {
  if (granularity === "hour") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd HH:mm:ss");
    return d.toFormat(format || "HH:mm");
  } else if (granularity === "day") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd");
    return d.toFormat(format || "MMM d");
  } else if (granularity === "month") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd");
    return d.toFormat(format || "MMM yyyy");
  } else if (granularity === "year") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd");
    return d.toFormat(format || "yyyy");
  }
  return value;
};

// we only cache one dataset per tab (not one for each periodValue, for each tab)
export const cacheReducer = (cache, action) => {
  switch (action.type) {
    case "chartData":
      const { name, data, granularity, summaryStats, periodValue, customValue } = action.payload;
      return {
        ...cache,
        [name]: { data, granularity, summaryStats, periodValue, customValue },
      };
    default:
      throw new Error();
  }
};

export const baseChartOptions = (granularity, theme, tab) => ({
  chart: {
    height: 350,
    type: "line",
    fontFamily: theme.typography.fontFamily,
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6,
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        if (value) {
          return tabs[tab].name === "week"
            ? xAxisFormatter(value, granularity, "EEE HH:mm")
            : xAxisFormatter(value, granularity);
        }
        return value;
      },
    },
    tickAmount: 10,
  },
  yaxis: {
    labels: {
      formatter: (val) => round(val, 1),
    },
    title: {
      text: "Energy (kWh)",
    },
  },
  tooltip: {
    style: {
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
    },
    y: [
      {
        formatter: (val) => (val ? `${val.toFixed(2)} kWh` : "N/A"),
      },
      {
        formatter: (val) => (val ? `${val.toFixed(2)} kWh` : "N/A"),
      },
      {
        formatter: (val) => (val ? `${val.toFixed(2)} kWh` : "N/A"),
      },
    ],
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  noData: {
    text: "",
  },
  colors: values({
    generation: theme.palette.success.light,
    consumption: theme.palette.secondary.dark,
    export: theme.palette.info.main,
  }),
});

const ChartPanel = ({
  fetchChartData,
  resetChartData,
  fetchSelector,
  defaultTabName,
  defaultGranularity,
  defaultCustomStartInterval = 30,
  seriesStats = ["generation", "consumption", "export", "temperature", "snow", "daylight"],
}) => {
  const chartRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [cache, setCache] = useReducer(cacheReducer, {});
  const [chartData, setChartData] = useState([]);
  const [summaryStats, setSummaryStats] = useState({
    sumEmissionReductions: 0,
    sumGeneration: 0,
  });
  const [chartOptions, setChartOptions] = useState(baseChartOptions(defaultGranularity, theme));
  const [periodValue, setPeriodValue] = useState(DateTime.now());
  const [customValue, setCustomValue] = useState({
    start: DateTime.now().minus({ days: defaultCustomStartInterval }),
    end: DateTime.now(),
  });

  // tab is an index
  const [tab, setTab] = useState(findIndex(tabs, { name: defaultTabName }));
  const handleChange = async (event, newTab) => {
    setTab(newTab);
  };

  const { status, data, granularity } = useSelector(fetchSelector);
  const isLoading = status === "request";

  // get chart data each time we change tab
  useEffect(() => {
    const initChart = async (tab) => {
      const tabName = tabs[tab].name;
      if (!cache[tabName]) {
        // clear while we load, mostly to cover up that unwanted re-render
        setChartData([]);
        setSummaryStats({ sumEmissionReductions: 0, sumGeneration: 0 });

        // this getLiveChartData only runs the first time we load the tab
        fetchChartData({
          startDate:
            tabName === "custom"
              ? DateTime.now().minus({ days: defaultCustomStartInterval })
              : DateTime.now().startOf(tabName),
          endDate: DateTime.now(),
        });
      } else {
        setChartData(cache[tabName].data);
        setSummaryStats(cache[tabName].summaryStats);
        const chartOptions = baseChartOptions(cache[tabName].granularity, theme, tab);
        setChartOptions(chartOptions);
        setPeriodValue(cache[tabName].periodValue);
        setCustomValue(cache[tabName].customValue);
      }
    };
    initChart(tab);
  }, [tab, cache, dispatch, theme, fetchChartData, defaultCustomStartInterval]);

  const tabName = tabs[tab].name;

  // process chart data from remote
  useEffect(() => {
    if (status === "success") {
      const namedData = map(data, (series) => ({
        ...series,
        name: find(SERIES, { stat: series.stat }).name,
      }));

      // NB. we never want to see emissionReductions, just the sum
      const filtered = filter(namedData, (series) => includes(seriesStats, series.stat));

      // draw it
      // biggest issue with apex is that it _always_ redraws on every render, no matter what
      setChartOptions(baseChartOptions(granularity, theme, tab));
      setChartData(filtered);

      // summary stats
      const sumEmissionReductions = reduce(
        find(data, (series) => series.stat === "emissionReductions")?.data || 0,
        (sum, datum) => sum + datum.y,
        0
      );
      const sumGeneration = reduce(
        find(data, (series) => series.stat === "generation")?.data || 0,
        (sum, datum) => sum + datum.y,
        0
      );
      setSummaryStats({ sumGeneration, sumEmissionReductions });

      // cache everything
      setCache({
        type: "chartData",
        payload: {
          name: tabs[tab].name,
          data: filtered,
          granularity,
          summaryStats: { sumGeneration, sumEmissionReductions },
          periodValue,
          customValue,
        },
      });

      // reset the api call results
      resetChartData();

      // slight delay until chart has redrawn with these series, so we can hide them by default
      setTimeout(() => {
        try {
          chartRef.current.chart.hideSeries(find(SERIES, { stat: "export" }).name);
          chartRef.current.chart.hideSeries(find(SERIES, { stat: "consumption" }).name);
        } catch (e) {}
      }, 250);
    }
  }, [
    data,
    granularity,
    status,
    dispatch,
    tab,
    theme,
    periodValue,
    customValue,
    resetChartData,
    seriesStats,
  ]);

  const fetchNewPeriod = async (periodValue) => {
    setChartData([]);
    setSummaryStats({ sumEmissionReductions: 0, sumGeneration: 0 });

    fetchChartData({
      startDate: periodValue.startOf(tabName),
      endDate: DateTime.min(periodValue.endOf(tabName), DateTime.now()),
    });
  };

  const fetchNewCustomPeriod = async (dateRange) => {
    setChartData([]);
    setSummaryStats({ sumEmissionReductions: 0, sumGeneration: 0 });

    const { start, end } = dateRange;
    fetchChartData({
      startDate: start.startOf("day"),
      endDate: end.endOf("day"),
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="center" pb={3}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="day | week | month | year | custom"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Day" disabled={isLoading} />
          <Tab label="Week" disabled={isLoading} />
          <Tab label="Month" disabled={isLoading} />
          <Tab label="Year" disabled={isLoading} />
          <Tab label="Custom" disabled={isLoading} />
        </Tabs>
      </Box>

      <Box
        height={84}
        display="flex"
        flexDirection="column"
        bgcolor="grey.50"
        justifyContent="center"
        alignItems="center"
      >
        {tabName !== "custom" && (
          <PeriodFlipper
            unit={tabName}
            value={periodValue}
            onChange={(periodValue) => {
              setPeriodValue(periodValue);
              fetchNewPeriod(periodValue);
            }}
          />
        )}
        {tabName === "custom" && (
          <CustomDateRangePicker
            value={customValue}
            onChange={(dateRange) => {
              setCustomValue(dateRange);
              fetchNewCustomPeriod(dateRange);
            }}
          />
        )}
        <Box display="flex" alignItems="center" mt={1}>
          <Typography color="secondary.dark">
            {round(summaryStats.sumGeneration / 1000).toLocaleString()} MWh
          </Typography>
          <Box px={2}>|</Box>
          <Typography color="secondary.dark">
            {round(summaryStats.sumEmissionReductions).toLocaleString()} tCO2e
          </Typography>
        </Box>
      </Box>

      <Box p={3} position="relative">
        <ReactApexChart
          ref={chartRef}
          options={chartOptions}
          series={chartData}
          type="area"
          height={300}
        ></ReactApexChart>
        {isLoading && (
          <Box
            position="absolute"
            width="100%"
            top={10}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            height={300}
          >
            <CircularProgress sx={{ color: "grey.500" }} size={24} />
          </Box>
        )}
      </Box>
    </>
  );
};

ChartPanel.propTypes ={
  // how to fetch the chart data
  fetchChartData: PropTypes.func.isRequired,

  // reset the chart data
  resetChartData: PropTypes.func.isRequired,

  // getting chart data, once fetched
  fetchSelector: PropTypes.func.isRequired,

  // which tab to start with
  defaultTabName: PropTypes.string.isRequired,

  // for the default tab, what granularity will we use?
  defaultGranularity: PropTypes.string.isRequired,
  
  // number of days before now we should use for the custom tab
  defaultCustomStartInterval: PropTypes.number,

  // these are the stat names from SERIES
  seriesStats: PropTypes.arrayOf(PropTypes.string),  
}

export default ChartPanel;
