import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectReportDetailPanel } from "@admin/projectReport";
import { ProjectReportStatusPanel } from "@admin/projectReport";
import { getClaimedProjectReports } from "../actions";
import { useParams } from "react-router";
import { find } from "lodash";
import { useRef } from "react";
import Page from "components/Page";
import PageLoader from "components/PageLoader";
import PageHeader from "components/PageHeader";
import Breadcrumb from "components/Breadcrumb";
import CertificatePanel from "./CertificatePanel";
import VerificationPanel from "@views/marketplace/portfolio/components/VerificationPanel";
import ProjectDetailPanel from "@admin/project/components/ProjectDetailPanel";

const ClaimedProjectReport = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { projectReportId } = useParams();

  // see if we have a project report
  const projectReport = useSelector((state) =>
    find(state.getClaimedProjectReports.projectReports, { id: projectReportId * 1 })
  );

  // otherwise refetch it
  const { status } = useSelector((state) => state.getClaimedProjectReports);
  const isLoading = status === "request" || status === "init";
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(getClaimedProjectReports({ accessToken }));
    }
    if (!projectReport) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, projectReport]);

  // scroll to top
  const pageRef = useRef();
  useEffect(() => {
    if (!isLoading) {
      pageRef?.current?.scrollIntoView();
    }
  }, [isLoading]);

  return (
    <Page py={3} title="Purchase Request" ref={pageRef}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Loading..." />}
        {!isLoading && (
          <>
            <PageHeader title={`Claimed project report #${projectReport.id}`}>
              <Breadcrumb title="Claimed Credits" destination="/marketplace/claimedCredits" />
            </PageHeader>
            <Box mt={3} ml={1}>
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Project
                  </Typography>
                  <ProjectDetailPanel
                    project={projectReport.project}
                    editable={false}
                    hidePlanningSheet
                  />
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Project Report
                  </Typography>
                  <ProjectReportDetailPanel
                    project={projectReport.project}
                    projectReport={projectReport}
                    hideReportingSheet
                  />
                </Grid>
                <Grid item sm={12}>
                  <ProjectReportStatusPanel projectReport={projectReport} editable={false} />
                </Grid>
                <Grid item xs={12}>
                  <VerificationPanel projectReport={projectReport} />
                </Grid>
                <Grid item xs={12}>
                  <CertificatePanel projectReport={projectReport} />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default ClaimedProjectReport;
