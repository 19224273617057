import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";
import { Wrapper, ContentContainer, Content } from "@layouts/GenericLayout";

const IndividualLayout = () => (
  <>
    <TopBar />
    <Wrapper>
      <ContentContainer>
        <Content>
          <Outlet />
        </Content>
      </ContentContainer>
    </Wrapper>
  </>
);

export default IndividualLayout;
