import React, { useRef, useEffect, useState } from "react";
import { Container, Box, Grid } from "@mui/material";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { includes, every, filter } from "lodash";
import PageHeader from "@components/PageHeader";
import { useSelector } from "react-redux";
import { getFleetStats } from "../actions";
import { formatPower } from "@utils/stringHelpers";
import { ScoreCard } from "@views/dashboard";
import OnboardingChart from "./OnboardingChart";
import DataConnectorHealth from "./DataConnectorHealth";
import Sites from "./Sites";
import { Consent, SectionWarning } from "@admin/components";
import pluralize from "pluralize";

const Monitoring = () => {
  const { isLoading } = useAuth0();
  const [loading, setLoading] = useState(true);

  const { status, stats } = useSelector((state) => state.getFleetStats);
  useEffect(() => {
    // if we get consent_required, we'll be stuck on init
    const statsLoading = includes(["init", "request"], status);
    setLoading(statsLoading || isLoading);
  }, [isLoading, status]);

  // dataConnector health
  const dataConnectorHealthRef = useRef();
  const { dataConnectors, status: healthStatus } = useSelector(
    (state) => state.getDataConnectorHealth
  );
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (healthStatus === "success") {
      const allWorking = every(dataConnectors, "isWorking");
      setOpen(!allWorking);
    }
  }, [healthStatus, dataConnectors]);
  const notWorking = filter(dataConnectors, { isWorking: false }).length;

  return (
    <Page py={3} title="Monitoring">
      <Container maxWidth="lg">
        <Consent action={getFleetStats} scope="admin_sites">
          <PageHeader title="Monitoring" />
          <SectionWarning
            open={open}
            targetRef={dataConnectorHealthRef}
            title="Data Connector Health"
            description={`${notWorking} data ${pluralize("connectors", notWorking)} ${pluralize(
              "is",
              notWorking
            )} not collecting data.`}
          />
          <Box mt={3} pb={6}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <ScoreCard
                  title="Capacity Online"
                  primary={`${formatPower(stats.capacity.online, 1)} Reporting`}
                  secondary={`${formatPower(stats.capacity.total, 1)} Total`}
                  color="success.light"
                  loading={loading}
                  percentComplete={Math.floor((stats.capacity.online / stats.capacity.total) * 100)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ScoreCard
                  title="Sites Online"
                  primary={`${stats.sites.online} Online`}
                  secondary={`${stats.sites.total} Total`}
                  color="secondary.main"
                  loading={loading}
                  percentComplete={Math.floor((stats.sites.online / stats.sites.total) * 100)}
                />
              </Grid>
              <Grid item xs={12}>
                <OnboardingChart />
              </Grid>
              <Grid item xs={12}>
                <DataConnectorHealth ref={dataConnectorHealthRef} />
              </Grid>
              <Grid item xs={12}>
                <Sites />
              </Grid>
            </Grid>
          </Box>
        </Consent>
      </Container>
    </Page>
  );
};

export default Monitoring;
