import getCustomerProjectSites from "./getCustomerProjectSites";
import createSite from "./createSite";
import getCustomerProjectSite from "./getCustomerProjectSite";
import patchCustomerProjectSite from "./patchCustomerProjectSite";
import patchDevice from "./patchDevice";
import createDevices from "../sagas/createDevices";
import createDocuments from "./createDocuments";
import deleteDocument from "./deleteDocument";

export default [
  ...getCustomerProjectSites,
  ...createSite,
  ...getCustomerProjectSite,
  ...patchCustomerProjectSite,
  ...patchDevice,
  ...createDevices,
  ...createDocuments,
  ...deleteDocument,
];
