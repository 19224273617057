import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const FittedImage = styled(({ uri, ...other }) => (
  <Box display="flex" justifyContent="flex-start" {...other}>
    <img alt="" src={uri} height="100%" width="100%" />
  </Box>
))(({ theme, fit }) => {
  return {
    // nb: if you remove objectFit, the image will just squish into the dimensions
    "& img": { borderRadius: theme.shape.borderRadius, objectFit: fit },
  };
});

export default FittedImage;
