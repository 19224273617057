import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ProjectSitesPicker from "../components/ProjectSitesPicker";
import { getAcceptableSites } from "../actions";

const mapStateToProps = ({ getAcceptableSites }) => ({
  getAcceptableSitesResult: getAcceptableSites,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAcceptableSites,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSitesPicker);
