import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import { getFullName } from "@utils/stringHelpers";
import { find, isNil } from "lodash";
import { ownershipTypes } from "@views/site/components/details/SiteDetails";
import { PhoneLink, EmailLink } from "@components";

const OwnershipPanel = ({ orgAsset }) => {
  const isIndividual = !isNil(orgAsset.siteOwner?.individual);
  const isExternal = !isNil(orgAsset.siteOwner?.contact) || !isNil(orgAsset.siteOwner?.external);
  const isOrg = !isNil(orgAsset.siteOwner?.organization);
  const ownerType = isIndividual
    ? "INDIVIDUAL"
    : isExternal
    ? "EXTERNAL"
    : isOrg
    ? "ORGANIZATION"
    : null;

  const siteOwner = {
    ...orgAsset.siteOwner,
    ownerType,
  };

  const missing = <Typography color="error.main">Missing</Typography>;
  const missingOwnerRow = {
    label: "Site Owner",
    value: missing,
  };

  const orgOwnerTableRows = [
    {
      label: "Site Owner",
      value: siteOwner?.organization?.name,
    },
    {
      label: "Primary Contact",
      value: getFullName(siteOwner?.organization?.primaryContact, null, missing),
    },
    {
      label: "Primary Contact Email",
      value: <EmailLink email={siteOwner?.organization?.primaryContact?.email} missing={missing} />
    },
    {
      label: "Primary Contact Phone",
      value: <PhoneLink phone={siteOwner?.organization?.primaryContact?.phone} missing={missing} />
    },
  ];

  const singleOwner = siteOwner?.individual || siteOwner?.external || siteOwner?.contact;
  const singleOwnerTableRows = [
    {
      label: "Site Owner",
      value: getFullName(singleOwner),
    },
    {
      label: "Email",
      value: <EmailLink email={singleOwner?.email} />,
    },
    {
      label: "Phone Number",
      value: <PhoneLink phone={singleOwner?.phone} />
    },
  ];

  const tableRows = [
    ...(isIndividual || isExternal
      ? singleOwnerTableRows
      : isOrg
      ? orgOwnerTableRows
      : [missingOwnerRow]),
    {
      label: "Equipment Ownership",
      value: find(ownershipTypes, { value: orgAsset?.equipmentOwnership })?.label || "N/A",
    },
    {
      label: "Grant/Funding Source",
      value: orgAsset?.fundingSource || "N/A",
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Ownership
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default OwnershipPanel;
