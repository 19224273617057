import React, { useEffect } from "react";
import { Typography, Container, Box, Paper } from "@mui/material";
import Page from "@components/Page";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Navigation from "@components/Navigation";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ContactForm, { contactSchema } from "@views/common/components/ContactForm";

const ContactInfo = ({
  contactInfo,
  saveContactInfo,
  updateUserProfile,
  updateUserProfileResult,
  resetUpdateUserProfile,
  backPath,
  nextPath,
}) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();

  const methods = useForm({
    resolver: yupResolver(contactSchema),
    mode: "onChange",
    defaultValues: {
      ...contactInfo,
      email: user.email,
    },
  });

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    const { firstName, lastName, phone } = data;

    // redux only
    saveContactInfo(data);

    // BE
    updateUserProfile({ accessToken, firstName, lastName, phone });
  };

  // nav on success
  useEffect(() => {
    if (updateUserProfileResult.status === "success") {
      resetUpdateUserProfile();
      navigate(nextPath);
    }
  }, [updateUserProfileResult, navigate, resetUpdateUserProfile, nextPath]);

  const isSubmitting = updateUserProfileResult.status === "request";

  return (
    <Page title="Contact Info" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box ml={3}>
            <Typography variant="h2" color="textPrimary">
              Sign Up
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Tell us about yourself
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <FormProvider {...methods}>
              <ContactForm instructions="Please tell us about yourself." email={false} />
            </FormProvider>
            <Navigation
              backAction={() => navigate(backPath)}
              nextAction={() => methods.handleSubmit(onSubmit)()}
              nextActionText="Continue Questions"
              isSubmitting={isSubmitting}
            />
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

ContactInfo.defaultProps = {
  backPath: "/onboarding/terms",
  nextPath: "/onboarding/organization",
  contactInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
};

ContactInfo.propTypes = {
  backPath: PropTypes.string,
  nextPath: PropTypes.string,
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired, // from your signup
    phone: PropTypes.string,
  }),
  saveContactInfo: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  updateUserProfileResult: PropTypes.shape({}).isRequired,
  resetUpdateUserProfile: PropTypes.func.isRequired,
};

export default ContactInfo;
