import getOrganization from "./getOrganization";
import getUserProfile from "./getUserProfile";
import updateUserProfile from "./updateUserProfile";
import updateOrganization from "./updateOrganization";
import uploadFiles from "./uploadFiles";

export default [
  ...getOrganization,
  ...getUserProfile,
  ...updateUserProfile,
  ...updateOrganization,
  ...uploadFiles,
];
