import { isObject } from "lodash";

const getFirstLastName = (last, first, defaultFullName) => {
  if (last && first) {
    return `${first} ${last}`;
  }
  if (last) {
    return last;
  }
  if (first) {
    return first;
  }
  return defaultFullName;
};

const getFullName = (last, first, defaultFullName = "N/A") => {
  if (isObject(last)) {
    return getFirstLastName(
      last.familyName || last.lastName,
      last.givenName || last.firstName,
      defaultFullName
    );
  }
  return getFirstLastName(last, first, defaultFullName);
};

// best guess - get the first word
const getFirstName = (fullName) => {
  const regex = /^[^\b]+?\b/gm;
  const matches = regex.exec(fullName);
  return matches ? matches[0] : fullName;
}

// best guess, everything other than the first word
const getLastName = (fullName) => {
  const firstName = getFirstName(fullName);
  return fullName.replace(firstName, "").trim();
}

const formatPower = (power, decimals = 2) => {
  if (power === 0) return "0 W";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["W", "kW", "MW", "GW", "TW"];

  const i = Math.floor(Math.log(power) / Math.log(k));

  return parseFloat((power / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const quotify = (s) => {
  let quoted = s.replace(/"/g, '""');
  return `"${quoted}"`;
};

const localeStringCurrencyOpts = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "currency",
  currency: "CAD",
  currencyDisplay: "narrowSymbol" // to get rid of the country (i.e. CAD) prefix
};

export {
  getFullName,
  formatPower,
  getFirstName,
  getLastName,
  quotify,
  localeStringCurrencyOpts,
};
