import { put, call, takeLatest } from "redux-saga/effects";
import { getPurchaseRequestForProjectReport } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, projectReportId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/marketplace/purchaseRequests/${projectReportId}`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getPurchaseRequestForProjectReportWorker(action) {
  try {
    const { accessToken, projectReportId } = action.payload;
    yield put(getPurchaseRequestForProjectReport.request());
    const response = yield call(callApi, accessToken, projectReportId);
    const purchaseRequest = response.data;
    yield put(getPurchaseRequestForProjectReport.success({ purchaseRequest }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to get purchase request for project report: ${message}`;
    yield put(getPurchaseRequestForProjectReport.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getPurchaseRequestForProjectReportWatcher() {
  yield takeLatest(getPurchaseRequestForProjectReport.TRIGGER, getPurchaseRequestForProjectReportWorker);
}

export default [getPurchaseRequestForProjectReportWatcher];
