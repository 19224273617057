import { getSite, putSite, updateSite } from "../actions";

export const initialState = {
  status: "init",
  site: {
    id: null,
    name: null,
    mountingType: null,
    capacity: null,
    utility: null,
    customerId: null,
    numMeters: null,
    manufacturers: [],
    documents: [],
    addresses: [
      {
        street: "",
        city: "",
        region: "",
        country: "",
        postal: "",
        types: ["MAIN"],
      },
    ],
    timeZone: "",
    status: null,
    timeSinceReport: null,
    timeInStatus: null,    
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSite.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getSite.SUCCESS: {
      const { site } = action.payload;
      return {
        ...state,
        status: "success",
        site,
      };
    }

    case getSite.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    // we can manually update the site info, comes from when we grabbed all sites
    case putSite.TYPE: {
      const { site } = action.payload;
      return {
        ...state,
        status: "init",
        site,
      };
    }

    // comes from patching the site
    case updateSite.TYPE: {
      const { site } = action.payload;
      return {
        ...state,
        status: "init",
        site: {
          ...state.site,
          ...site,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
