import { getApplication } from "../actions";

export const initialState = {
  status: "init",
  application: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getApplication.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getApplication.SUCCESS: {
      const { application } = action.payload;
      return {
        ...state,
        status: "success",
        application,
      };
    }

    case getApplication.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
