import { call, put, takeLatest } from "redux-saga/effects";
import { getPublicProjects } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken) => await axios.get(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects`,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
    params: {
      isPublic: true,
    },
  }
);

function* getPublicProjectsWorker(action) {
  yield put(getPublicProjects.request());

  const { accessToken } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
    );
    const projects = response.data.filter((project) => project.id === 1); // this is Rewatt's solar project;
    yield put(getPublicProjects.success({ projects }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting programs: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting programs: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting programs: ", err);
      message = err.message;
    }
    message = `Unable to get programs: ${message}`;
    yield put(getPublicProjects.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getPublicProjectsWatcher() {
  yield takeLatest(getPublicProjects.TRIGGER, getPublicProjectsWorker);
}

export default [getPublicProjectsWatcher];
