import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "@components/PageLoader";
import { Wrapper, ContentContainer, Content } from "@layouts/GenericLayout";
import { styled } from "@mui/material/styles";

const ResponsiveWrapper = styled(Wrapper)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 256,
  },
}));

const DashboardLayout = ({ getOrganization, getOrganizationResult }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  // unfortunately their error handler (brokenly) doesn't catch auth0 500's
  const { getAccessTokenSilently, isLoading, error } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getOrganization({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getOrganization]);

  const { organization } = getOrganizationResult;

  return (
    <div>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        organization={organization}
      />
      <ResponsiveWrapper>
        <ContentContainer>
          <Content>
            {!isLoading && !error && <Outlet />}
            {isLoading && <PageLoader message="Authenticating..." />}

            {error && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "calc(100vh - 64px)" }}
              >
                <Box alignItems="center" display="flex" flexDirection="column">
                  <Typography>Authentication: {error.message}</Typography>
                </Box>
              </Box>
            )}
          </Content>
        </ContentContainer>
      </ResponsiveWrapper>
      {/* we need a spacer here for the hubspot webchat */}
      <Box height={80}/>
    </div>
  );
};

DashboardLayout.propTypes = {
  getOrganization: PropTypes.func.isRequired,
  getOrganizationResult: PropTypes.shape({}).isRequired,
};

export default DashboardLayout;
