import { call, put, takeLatest } from "redux-saga/effects";
import { getQualifiedSites } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { trim, omitBy, isNil } from "lodash";
import { transformSites } from "@views/sites/sagas/getSites";

const callApi = async (
  accessToken,
  programId,
  page,
  pageSize,
  order,
  search
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/programs/${programId}/assets/find`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: omitBy(
        {
          page,
          pageSize,
          order,
          search: search ? trim(search) : search,
        },
        isNil
      ),
    }
  );
  return response;
};

export function* getQualifiedSitesWorker(action) {
  yield put(getQualifiedSites.request());

  const {
    accessToken,
    programId,
    page,
    pageSize,
    order,
    search,
  } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      programId,
      page,
      pageSize,
      order,
      search
    );
    const { assets, total } = response.data;
    yield put(
      getQualifiedSites.success({
        sites: transformSites(assets),
        total: Number(total),
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting qualified sites: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting qualified sites: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting qualified sites: ", err);
      message = err.message;
    }
    message = `Unable to get qualified sites: ${message}`;
    yield put(getQualifiedSites.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getQualifiedSitesWatcher() {
  yield takeLatest(getQualifiedSites.TRIGGER, getQualifiedSitesWorker);
}

export default [getQualifiedSitesWatcher];
