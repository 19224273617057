import { patchApplicationAddDocuments } from "../actions";

export const initialState = {
  status: "init",
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case patchApplicationAddDocuments.RESET: {
      return initialState;
    }

    case patchApplicationAddDocuments.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case patchApplicationAddDocuments.SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }

    case patchApplicationAddDocuments.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        status: "failure",
        error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
