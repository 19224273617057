import * as React from "react";

const defaultTags = [
  "Site Equipment and Ownership",
  "Connection to Grid",
  "Installation",
  "Environmental Credit Ownership",
  "Other",
];

export const solarTags = [
  'Microgeneration interconnection agreement',
  'Single-line diagram',
  'Paid-in-full invoice',
  'Installation photo(s)',
  'Other'
];

export const livePageTags = ["Site photo for live page", "Logo for live page"];

const TagContext = React.createContext(defaultTags);

const DefaultTagProvider = ({ children }) => {
  return (
    <TagContext.Provider value={defaultTags}>{children}</TagContext.Provider>
  );
};

const LivePageTagProvider = ({ children }) => {
  return (
    <TagContext.Provider value={livePageTags}>
      {children}
    </TagContext.Provider>
  );
};

const ChannelPartnerInviteTagProvider = ({ children }) => {
  return (
    <TagContext.Provider
      value={['Terms and Conditions', ...defaultTags]}
    >
      {children}
    </TagContext.Provider>
  );
};

const PurchaseRequestStatusTagProvider = ({ children }) => {
  return (
    <TagContext.Provider
      value={[
        'Contract',
        'Invoice',
        'Receipt',
        'Screenshot',
        'Other'
      ]}
    >
      {children}
    </TagContext.Provider>
  )
}

const SolarTags = ({ children }) => {
  return (
    <TagContext.Provider value={solarTags}>
      {children}
    </TagContext.Provider>
  );
};

const EVTags = ({ children }) => {
  return (
    <TagContext.Provider
      value={[
        'Ownership',
        'System Details',
        'Electricity Supply',
        'Other'
      ]}
    >
      {children}
    </TagContext.Provider>
  );
};

const useTags = () => {
  const context = React.useContext(TagContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a TagProvider");
  }
  return context;
};

export {
  DefaultTagProvider,
  LivePageTagProvider,
  ChannelPartnerInviteTagProvider,
  PurchaseRequestStatusTagProvider,
  SolarTags,
  EVTags,
  useTags,
};
