import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";

const ChargerIdPanel = ({ credentials }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Charger IDs"
              variant="standard"
              fullWidth
              error={!isNil(errors.chargerIds?.message)}
              helperText={errors.chargerIds?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="355092, 355078"
              autoFocus
            />
          )}
          name="chargerIds"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={credentials.chargerIds || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

ChargerIdPanel.propTypes = {
  credentials: PropTypes.shape({
    chargerIds: PropTypes.string,
  }).isRequired,
};

export default ChargerIdPanel;
