import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

/**
 * This link won't reload the whole page, and matches our default style.
 */
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "underline",
  color: theme.palette.primary.main,
}));

export default StyledLink;
