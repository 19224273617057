import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ConfirmDeleteInvite = ({ open, cancelAction, okAction, invite }) => {
  return (
    <Dialog
      open={open}
      onClose={cancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Invitation</DialogTitle>
      <DialogContent>
        <DialogContentText color="primary" gutterBottom>
          Are you sure you want to delete the invite?
        </DialogContentText>
        <DialogContentText variant="body2">
          {invite?.inviteeName} &lt;{invite?.invitee}&gt;
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction}>
          Cancel
        </Button>
        <Button onClick={okAction} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDeleteInvite.propTypes = {
  open: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func.isRequired,
  okAction: PropTypes.func.isRequired,
  invite: PropTypes.shape({
    id: PropTypes.number,
    inviteeName: PropTypes.string,
  }),
};

export default ConfirmDeleteInvite;
