import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "@views/common/actions";
import { includes } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import PageLoader from "@components/PageLoader";
import logger from "debug";

const TermsAndConditionsGuard = ({ children }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // get user profile
  const { userProfile, status } = useSelector(state => state.getUserProfile)
  useEffect(() => {
    async function fetchUserProfile() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getUserProfile({ accessToken, userId: user.sub }));
    }

    // use cached userProfile
    const id = userProfile?.id;
    if ((!id && user?.sub && status === "init")) {
      fetchUserProfile();
    }
  }, [user, getAccessTokenSilently, dispatch, userProfile?.id, status]);

  useEffect(() => {
    if (status === "success" && !userProfile?.acceptedTermsAndConditions) {
      logger("weedle:error")("Terms not accepted, redirecting.");
      navigate("/onboarding/terms");
    }
  }, [userProfile, status, navigate]);


  // contact has its own progress
  const isContactInfo = /contactinfo/.test(pathname);

  const isLoading = includes(["init", "request"], status) && !isContactInfo;
  return (
    <>
      {!isLoading && userProfile?.acceptedTermsAndConditions && children}
      {isLoading && <PageLoader message="Logging you in/out..." />}
    </>
  );
};

export default TermsAndConditionsGuard;
