import React from "react";
import PropTypes from "prop-types";
import { GlobalStyles, Box, Container, Typography, Button, Link } from "@mui/material";
import Page from "@components/Page";
import PageLoader from "@components/PageLoader";
import { useLocation } from "react-router-dom";
import { includes } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { RewattAddressFooter } from "@components";

const Welcome = ({
  verifyInviteToken,
  verifyInviteTokenResult,
  resetUserProfile,
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");

  React.useEffect(() => {
    if (token) {
      verifyInviteToken({ token });
    }
  }, [token, verifyInviteToken]);

  React.useEffect(() => {
    // just to be safe, if you closed without logging out, then signed up again
    resetUserProfile();
  });

  const isLoading = includes(
    ["init", "request"],
    verifyInviteTokenResult.status
  );
  const { invite, error } = verifyInviteTokenResult;

  const { loginWithRedirect } = useAuth0();
  const signup = () => {
    loginWithRedirect({
      redirectUri: window.location.origin,
      appState: {
        orgId: invite.organization.id,
        inviteId: invite.id,
        returnTo: "/organizations/accept/terms",
      },
      screen_hint: "signup",
    });
  };

  return (
    <Page title="Welcome" py={3}>
      <GlobalStyles styles={{
        // hide the login button
        "header .MuiButton-root": {
          display: "none"
        }
      }} />
      <Container maxWidth={false}>
        {isLoading && <PageLoader message="Checking invite..." />}
        {!isLoading && error && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "calc(100vh - 64px)" }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" color="textPrimary" gutterBottom>
                Sorry, we had a problem verifying your invitation. Please
                request another one (or <Link href="/">login</Link>).
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {error}
              </Typography>
            </Box>
          </Box>
        )}
        {!isLoading && !error && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "75vh" }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h3" color="textPrimary" gutterBottom>
                Sign Up
              </Typography>
              <Typography variant="h4" color="textSecondary" gutterBottom>
                Invitation to join {invite.organization.name}
              </Typography>

              <Container maxWidth="xs">
                <Box py={3} mb={4} sx={{
                  my: 4,
                  py: 3,
                  borderTop: theme => `1px solid ${theme.palette.divider}`,
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                }}>
                <Typography variant="body1" gutterBottom>
                  {invite.inviteeName} ({invite.inviteeEmail}),
                </Typography>
                <Typography variant="body1" gutterBottom>
                  You've been invited by {invite.shareName} to join{" "}
                  {invite.organization.name} on the Rewatt Power Portal.
                </Typography>
                {invite.notes && (
                  <>
                    <Typography variant="body1">
                      Notes from {invite.shareName}:
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {invite.notes}
                    </Typography>
                  </>
                )}
                </Box>
              </Container>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={signup}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        )}
        <RewattAddressFooter />
      </Container>
    </Page>
  );
};

Welcome.propTypes = {
  verifyInviteToken: PropTypes.func.isRequired,
  verifyInviteTokenResult: PropTypes.shape({}),
  resetUserProfile: PropTypes.func.isRequired,
};

export default Welcome;
