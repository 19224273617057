import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Container, Typography, Grid, Box } from "@mui/material";
import { includes, find } from "lodash";
import Page from "@components/Page";
import PageLoader from "@components/PageLoader";
import { useLocation } from "react-router-dom";
import Image from "@jy95/material-ui-image";
import ApplicantProfile from "./ApplicantProfile";
import SiteProfile from "./SiteProfile";
import SiteDetails from "./SiteDetails";
import Documents from "./Documents";
import TermsAndConditions from "./TermsAndConditions";
import { useNavigate } from "react-router-dom";
import { getDims } from "@utils/imageHelpers";
import { EmailLink } from "@components";

const reducer = (submission, action) => {
  switch (action.type) {
    case "init": {
      const { siteProfile, applicationId } = action.payload;
      return {
        ...submission,
        siteProfile,
        applicationId,
      };
    }
    case "acceptTerms":
      const { acceptedProgramTermsAndConditions } = action.payload;
      return {
        ...submission,
        acceptedProgramTermsAndConditions,
      };
    case "updateSiteProfile":
      const { siteProfile } = action.payload;
      return {
        ...submission,
        siteProfile,
      };
    default:
      throw new Error(`Unsupported action: ${action}`);
  }
};

const Landing = ({
  verifyToken,
  verifyTokenResult,
  patchApplication,
  patchApplicationResult,
  resetPatchApplication,
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    verifyToken({ token });
  }, [verifyToken, token]);

  const isLoading = includes(["init", "request"], verifyTokenResult.status);
  const { application, error } = verifyTokenResult;
  const { program } = application;

  const [aspectRatio, setAspectRatio] = React.useState(1);

  const imgBoxDim = 56 - 2 * 8;

  // keep track of the updated application for submission
  const [submission, updateSubmission] = useReducer(reducer, {
    acceptedProgramTermsAndConditions: false,
  });

  // init the submission, in our own convenient flat format suitable for our form
  useEffect(() => {
    if (verifyTokenResult.status === "success") {
      const {
        givenName: firstName,
        familyName: lastName,
        email,
        phone,
      } = application.asset.siteOwner.external;
      const { name, equipmentOwnership, fundingSource } = application.asset;
      updateSubmission({
        type: "init",
        payload: {
          applicationId: application.id,
          siteProfile: {
            firstName,
            lastName,
            email,
            phone,
            name,
            equipmentOwnership: equipmentOwnership || "",
            fundingSource: fundingSource || "",
            address: find(application.asset.addresses, (address) =>
              includes(address.types, "MAIN")
            ),
          },
        },
      });
    }
  }, [verifyTokenResult.status, application]);

  // submit
  const handleSubmit = () => {
    patchApplication({ submission, token });
  };
  const isSubmitting = patchApplicationResult.status === "request";
  const isSuccess = patchApplicationResult.status === "success";

  useEffect(() => {
    if (isSuccess) {
      resetPatchApplication();
      navigate("/siteOwner/thankyou", {
        replace: true,
        state: { program, applicationId: application.id },
      });
    }
  }, [isSuccess, navigate, application.id, program, resetPatchApplication]);

  return (
    <Page title="Welcome to Rewatt" py={3}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Just a moment..." />}
        {!isLoading && !error && (
          <>
            <Box ml={10} mr={10}>
              <Grid container>
                <Grid item xs={12}>
                  {/* row 1 */}
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignContent="stretch"
                    alignItems="center"
                  >
                    {/* position image in center, fix it */}
                    <Box
                      sx={{
                        flex: "0 0 56px",
                        boxShadow: "3px 3px 8px 2px rgb(0 0 0 / 15%)",
                        padding: 1,
                        background: theme => theme.palette.common.white,
                      }}
                    >
                      <Image
                        src={program?.logo?.uri}
                        imageStyle={getDims(aspectRatio, imgBoxDim)}
                        onLoad={(e) => {
                          if (e?.target) {
                            setAspectRatio(e.target.naturalWidth / e.target.naturalHeight);
                          }
                        }}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </Box>

                    {/* title */}
                    <Box flex={1}>
                      <Typography variant="h3" pl={3}>
                        {program?.organization?.name} {program?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* end of row 1 */}

                {/* row 2 and 3 */}
                <Grid item xs={12}>
                  <Box mt={2} ml={7}>
                    <Typography
                      sx={{
                        whiteSpace: "pre-wrap",
                        pl: 3,
                        mb: 1,
                      }}
                    >
                      {program?.description}
                    </Typography>

                    {program?.manager?.email && (
                      <Typography pl={3} mb={1}>
                        For questions or concerns, email us at {" "}
                        <EmailLink email={program.manager.email} />
                      </Typography>
                    )}

                    <Typography variant="h6" pl={3}>
                      Application ID: {application.id}
                    </Typography>
                  </Box>
                </Grid>
                {/* end of row 2 and 3 */}

                <Grid item xs={12}>
                  <ApplicantProfile application={application} />
                </Grid>

                <Grid item xs={12}>
                  <SiteProfile
                    siteProfile={submission.siteProfile}
                    updateSiteProfile={(siteProfile) =>
                      updateSubmission({
                        type: "updateSiteProfile",
                        payload: { siteProfile },
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <SiteDetails application={application} program={program} />
                </Grid>

                <Grid item xs={12}>
                  <Documents application={application} />
                </Grid>

                <Grid item xs={12}>
                  <TermsAndConditions
                    program={program}
                    acceptTerms={() => {
                      updateSubmission({
                        type: "acceptTerms",
                        payload: { acceptedProgramTermsAndConditions: true },
                      });
                    }}
                    onSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {error && (
          <Typography variant="h4">
            Sorry we had a problem loading your application. Please try again.
          </Typography>
        )}
      </Container>
    </Page>
  );
};

Landing.propTypes = {
  verifyToken: PropTypes.func.isRequired,
  verifyTokenResult: PropTypes.shape({
    status: PropTypes.string,
    program: PropTypes.shape({}),
  }),
  patchApplication: PropTypes.func.isRequired,
  patchApplicationResult: PropTypes.shape({
    status: PropTypes.string,
  }),
  resetPatchApplication: PropTypes.func.isRequired,
};

export default Landing;
