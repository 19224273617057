import { call, put, takeLatest, select } from "redux-saga/effects";
import { getIncidents } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getOrganization } from "@views/common/selectors";
import { trim, omitBy, isNil } from "lodash";

const callApi = async (
  accessToken,
  organizationId,
  page,
  pageSize,
  order,
  search
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/incidents`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: omitBy(
        {
          page,
          pageSize,
          order,
          search: search ? trim(search) : search,
        },
        isNil
      ),
    }
  );
  return response;
};

export function* getIncidentsWorker(action) {
  yield put(getIncidents.request());

  const organization = yield select(getOrganization);
  const { accessToken, page, pageSize, order, search } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      organization.id,
      page,
      pageSize,
      order,
      search
    );
    const { incidents, total } = response.data;
    yield put(
      getIncidents.success({ incidents, total: Number(total) })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting incidents: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting incidents: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting incidents: ", err);
      message = err.message;
    }
    message = `Unable to get incidents: ${message}`;
    yield put(getIncidents.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getIncidentsWatcher() {
  yield takeLatest(getIncidents.TRIGGER, getIncidentsWorker);
}

export default [getIncidentsWatcher];
