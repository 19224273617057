import { uploadTerms } from "../actions";

export const initialState = {
  status: "init",
  filespecs: [],
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadTerms.REQUEST: {
      return {
        ...state,
        status: "request",
        filespecs: [],
        errors: null,
      };
    }

    case uploadTerms.SUCCESS: {
      const { filespecs } = action.payload;
      return {
        ...state,
        status: "success",
        filespecs,
      };
    }

    case uploadTerms.FAILURE: {
      const { errors } = action.payload;
      return {
        ...state,
        status: "failure",
        errors,
      };
    }

    case uploadTerms.RESET: {
      return initialState;
    }    

    default: {
      return state;
    }
  }
};

export default reducer;
