import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Paper, IconButton, Tooltip, Switch } from "@mui/material";
import { filter, find, includes, isEmpty, isNil, map } from "lodash";
import CustomDataGrid from "@components/CustomDataGrid";
import { renderCellExpand } from "@components/GridCellExpand";
import { getGridStringOperators, getGridNumericOperators, } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ViewIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router";
import { getFullName } from "@utils/stringHelpers";
import { transformSites } from "@views/sites/sagas/getSites";
import DisplayStatus from "@views/sites/components/DisplayStatus";
import { getCustomerProjectSites } from "../../Site/actions";
import { setTableState } from "@views/common/actions";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import AddSiteEditor from "../../Site/components/AddSiteEditor";
import { AddCircle, Check, Error } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { EmailLink } from "@components";
import { isNull } from "lodash";
import { getSubscriptions, subscribeToSiteNotifications, unsubscribeFromSiteNotifications } from "views/site/actions";

const tableStateKey = "CUSTOMER_PROJECT_SITES";
const initialTableState = {
  sortModel: [{ field: "id", sort: "asc" }],
};

const CustomerProjectSites = ({ customerProject, title }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const { sites, total, status: getSitesStatus } = useSelector(state => state.getCustomerProjectSites);
  const isSitesLoading = getSitesStatus === "request";

  // table state for back-end pagination
  const tableState =
    useSelector((state) => state.tableState[tableStateKey]) ||
    getDefaultTableState(initialTableState);
  const { page, pageSize, sortModel, filterModel } = tableState;

  useEffect(() => {
    const fetchRows = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      if (customerProject?.id) {
        dispatch(
          getCustomerProjectSites({
            accessToken,
            customerProjectId: customerProject?.id,
            page: page + 1,
            pageSize: pageSize,
            order: !isEmpty(sortModel) ? `${sortModel[0]?.field}:${sortModel[0]?.sort}` : undefined,
            search:
              !isEmpty(filterModel?.items) && !isNil(filterModel?.items[0].value)
                ? `${filterModel.items[0].columnField}:${filterModel.items[0].value}`
                : undefined,
          })
        );
      }
    };
    fetchRows();
  }, [customerProject?.id, page, pageSize, sortModel, filterModel, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    async function fetchSubs() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      dispatch(getSubscriptions({ accessToken }));
    };
    fetchSubs();
  }, [dispatch, getAccessTokenSilently]);
  const { subscriptions, status: getSubsStatus } = useSelector(state => state.getSubscriptions);
  const sitesWithSubs = map(sites, (site) => {
    const subscription = find(
      subscriptions,
      (sub) => sub.type === "SITE" &&
        sub.assetId === parseInt(site.id) &&
        includes(sub.notifications, "INCIDENTS")
    );
    return {
      ...site,
      incidentNotification: !isNil(subscription),
    };
  });
  const isSubsLoading = getSubsStatus === "request";

  const navigate = useNavigate();
  const columns = useMemo(() => {
    const stringOps = filter(getGridStringOperators(), { value: "contains" });
    const stringEqOps = filter(getGridStringOperators(), { value: "equals" });
    const numericOps = filter(getGridNumericOperators(), { value: "=" });

    const toggleIncidentNotifications = async (siteId, value) => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });

      // update the local data immediately
      const newSubscriptions = value
        ? [
            ...subscriptions,
            { assetId: siteId, type: "SITE", notifications: "INCIDENTS" },
          ]
        : filter(subscriptions, (sub) => sub?.assetId !== siteId);
      dispatch(getSubscriptions.mutate({ subscriptions: newSubscriptions }));

      // send our request
      const toggleSubscription = value
        ? subscribeToSiteNotifications
        : unsubscribeFromSiteNotifications;
      dispatch(toggleSubscription({
        accessToken,
        siteId,
        notifications: ["INCIDENTS"],
      }));
    };

    return customerProject?.emissionReductionActivity === 'LOW_CARBON_FUEL' ? [
      {
        headerName: "Site Name",
        field: "name",
        flex: 1,
        renderCell: renderCellExpand,
        filterOperators: stringOps,
      },
      {
        headerName: "Location",
        field: "location",
        flex: 1.2,
        renderCell: ({ value: location }) => (
          <Typography fontWeight="bold" component="div" variant="body2">
            {location.city ?? ""},{" "}
            <Typography display="inline" variant="body2">
              {location.region ?? ""}
            </Typography>
            <Typography variant="body2">{location.street}</Typography>
          </Typography>
        ),
        filterOperators: stringOps,
      },
      {
        headerName: "Incident Notification",
        field: "incidentNotification",
        flex: 0.8,
        filterable: false,
        renderCell: ({ row: site }) => (
          <Switch
            color="secondary"
            onChange={(ev, value) =>
              toggleIncidentNotifications(site.id, value)
            }
            checked={site.incidentNotification}
          />
        )
      },
      {
        headerName: "Reporting Status",
        field: "status",
        flex: 1,
        renderCell: ({ value }) => <DisplayStatus status={value} />,
        filterOperators: stringEqOps,
      },
      {
        headerName: "Is Complete",
        field: "isComplete",
        flex: 1,
        sortable: false,
        renderCell: ({ value: isComplete }) => isComplete
          ? <Check sx={{ color: theme.palette.success.main }}/>
          : <Error sx={{ color: theme.palette.error.main }} />,
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              navigate(`/projects/${customerProject?.id}/sites/${params.row.id}`);
            }}
            aria-label="view"
            size="small"
            sx={{ ml: 1 }}
          >
            <ViewIcon />
          </IconButton>
        ),
      }
    ] : [
      {
        headerName: "Site Name",
        field: "name",
        flex: 1,
        renderCell: renderCellExpand,
        filterOperators: stringOps,
      },
      {
        headerName: "Capacity (kW)",
        field: "capacity",
        flex: 0.8,
        valueFormatter: ({ value }) => {
          return isNull(value) ? '-' : (value / 1000).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          });
        },
        type: "number",
        filterOperators: numericOps,
      },
      {
        headerName: "Location",
        field: "location",
        flex: 1.2,
        renderCell: ({ value: location }) => (
          <Typography fontWeight="bold" component="div" variant="body2">
            {location.city ?? ""},{" "}
            <Typography display="inline" variant="body2">
              {location.region ?? ""}
            </Typography>
            <Typography variant="body2">{location.street}</Typography>
          </Typography>
        ),
        filterOperators: stringOps,
      },
      {
        headerName: "Owner",
        field: "siteOwner",
        flex: 1,
        valueGetter: ({ row: site }) => {
          if (site.siteOwner?.external) {
            return getFullName(site.siteOwner.external);
          } else if (site.siteOwner?.individual) {
            return getFullName(site.siteOwner.individual);
          } else {
            return site.siteOwner?.organization?.name || "-";
          }
        },
        renderCell: renderCellExpand,      
        filterOperators: stringOps,
      },
      {
        headerName: "Payee",
        field: "payee",
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: ({ row: site }) => (
          <EmailLink email={site.payee?.email}>{getFullName(site.payee)}</EmailLink>
        )
      },
      {
        headerName: "Incident Notification",
        field: "incidentNotification",
        flex: 0.8,
        filterable: false,
        renderCell: ({ row: site }) => (
          <Switch
            color="secondary"
            onChange={(ev, value) =>
              toggleIncidentNotifications(site.id, value)
            }
            checked={site.incidentNotification}
          />
        )
      },
      {
        headerName: "Reporting Status",
        field: "status",
        flex: 1,
        renderCell: ({ value }) => <DisplayStatus status={value} />,
        filterOperators: stringEqOps,
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              navigate(`/projects/${customerProject?.id}/sites/${params.row.id}`);
            }}
            aria-label="view"
            size="small"
            sx={{ ml: 1 }}
          >
            <ViewIcon />
          </IconButton>
        ),
      }
    ];
  }, [
    navigate,
    customerProject,
    theme,
    getAccessTokenSilently,
    subscriptions,
    dispatch,
  ]);

  // add site editor
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleOpenEditor = () => {
    setIsEditorOpen(true);
  };
  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  return (
    <Paper sx={{ px: 3 , py: 1}}>
      <Box display="flex" alignItems="center" py={1}>
        {title && <Typography variant="h5">{title}</Typography>}
        <Box flexGrow={1} />
        <Tooltip
          title="Add site to project"
        >
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={handleOpenEditor}
            size="large"
            sx={{ py: 0 }}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
      </Box>
      <CustomDataGrid
        autoHeight
        style={{ border: 0 }}
        rowHeight={50}
        loading={isSitesLoading || isSubsLoading}
        rows={sitesWithSubs ? transformSites(sitesWithSubs) : []}
        columns={columns}
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(sortModel) =>
          dispatch(setTableState({ key: tableStateKey, sortModel }))
        }
        page={page}
        onPageChange={(page) => dispatch(setTableState({ key: tableStateKey, page }))}
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => {
          if (page * pageSize > total) {
            dispatch(setTableState({ key: tableStateKey, page: 1, pageSize }));
          } else {
            dispatch(setTableState({ key: tableStateKey, pageSize }));
          }
        }}
        rowsPerPageOptions={[5, 10, 20]}
        filterModel={filterModel}
        onFilterModelChange={(filterModel) =>
          dispatch(setTableState({ key: tableStateKey, filterModel }))
        }
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowCount={total || 0}
        rowThreshold={0}
        localeText={{
          noRowsLabel: "No project sites.",
        }}
      />
      {customerProject && (
        <AddSiteEditor
          open={isEditorOpen}
          onClose={handleCloseEditor}
          customerProject={customerProject}
        />
      )}
    </Paper>
  );
};

export default CustomerProjectSites;
