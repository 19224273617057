import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/VisibilityOff";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";

const UsernamePasswordPanel = ({ credentials, manufacturer }) => {
  const { errors, control } = useFormContext();

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={6}>
        <Controller
          as={
            <TextField
              label="Site Username"
              variant="standard"
              fullWidth
              placeholder="joe@company.com"
              autoFocus
              error={!isNil(errors.username?.message)}
              helperText={errors.username?.message || " "}
              autoComplete="new-username"
            />
          }
          name="username"
          control={control}
          rules={{ required: "Username is required." }}
          defaultValue={credentials.username}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={
            <TextField
              label="Password"
              variant="standard"
              fullWidth
              placeholder="***"
              error={!isNil(errors.password?.message)}
              helperText={errors.password?.message || " "}
              autoComplete="new-password"
              type={values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
          name="password"
          control={control}
          rules={{ required: "Password is required." }}
          defaultValue={credentials.password}
        />
      </Grid>
      {manufacturer === "HOYMILES" && (
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Plant Name"
                variant="standard"
                fullWidth
                placeholder="site name"
                error={!isNil(errors.siteName?.message)}
                helperText={errors.siteName?.message || " "}
                autoComplete="site-name"
                type="text"
              />
            }
            name="siteName"
            control={control}
            rules={{ required: "Plant name is required." }}
            defaultValue={credentials.siteName}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

UsernamePasswordPanel.propTypes = {
  credentials: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    siteName: PropTypes.string,
  }).isRequired,
};

export default UsernamePasswordPanel;
