import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import DashboardLayout from "@layouts/DashboardLayout";
import OnboardingLayout from "@layouts/OnboardingLayout";
import GenericLayout from "@layouts/GenericLayout";
import ApplyLayout from "@layouts/ApplyLayout";
import IndividualLayout from "@layouts/IndividualLayout";
import {
  TermsAndConditions as OnboardingTermsAndConditions,
  ContactInfo as OnboardingContactInfo,
  OrganizationInfo as OnboardingOrganizationInfo,
} from "@views/onboarding";
import LiveSite from "@views/liveSite";
import ProjectReportCertificate from "@views/certificate";
import {
  Monitoring,
  Project,
  Projects,
  ProjectReport,
  OrgAsset,
  Buyers,
  PurchaseRequests,
  ChannelPartners,
  ChannelPartnerDetails,
} from "@views/admin";
import { Landing, Callback } from "@views/landing";
import {
  Welcome as OrgInviteWelcome,
  TermsAndConditions as OrgInviteTermsAndConditions,
} from "@views/orgInvites";
import {
  Welcome as BuyerInviteWelcome,
  Start as BuyerStart,
  TermsAndConditions as BuyerInviteTermsAndConditions,
  PersonalInfo as BuyerPersonalInfo,
  Organization as BuyerOrganization,
  AcceptInvite as BuyerAcceptInvite,
} from "@views/marketplace/buyerInvites";
import {
  Welcome as ChannelPartnerInviteWelcome,
  Start as ChannelPartnerStart,
  TermsAndConditions as ChannelPartnerInviteTermsAndConditions,
  PersonalInfo as ChannelPartnerPersonalInfo,
  Organization as ChannelPartnerOrganizationInvite,
} from "@views/channelPartnerInvites";
import {
  Welcome as PayeeInviteWelcome,
  Start as PayeeStart,
  TermsAndConditions as PayeeInviteTermsAndConditions,
  BankInformation,
  PersonalInfo as PayeePersonalInfo,
  AcceptInvite as PayeeAcceptInvite,
} from "@views/marketplace/payeeInvites";
import {
  Welcome as AssetClaimWelcome,
  Start as AssetClaimStart,
  TermsAndConditions as AssetClaimTermsAndConditions,
  PersonalInfo as AssetClaimPersonalInfo,
  AcceptInvite as AssetClaimAcceptInvite,
} from "@views/assetClaim";
import NotFound from "@views/errors/NotFound";
import TermsAndConditionsGuard from "./TermsAndConditionsGuard";
import OrganizationGuard from "./OrganizationGuard";
import IndividualGuard from "./IndividualGuard";
import MarketplaceGuard from "./MarketplaceGuard";
import ChannelPartnerGuard from "./ChannelPartnerGuard";
import StyleGuide from "@views/dev/StyleGuide";
import { MyApplications, SubmittedApplication } from "@views/myApplications";
import {
  Dashboard as IndividualDashboard,
  Account as IndividualAccount,
  PayeeStatements as IndividualPayeeStatements,
  Application as IndividualApplication
} from "@views/individual";
import {
  Portfolio,
  PurchaseCredits,
  PurchaseRequest,
  ClaimedCredits,
  ClaimedProjectReport,
} from "@views/marketplace";

import {
  Welcome as ApplyWelcome,
} from "@views/apply";
import { Landing as SiteOwnerLanding, ThankYou as SiteOwnerThankYou } from "@views/siteOwner";
import { Account, Organization, Members } from "@views/settings";
import {
  Referrals,
  PublicProjects as ChannelPartnerProjects,
} from "@views/channelPartner";
import {
  CustomerProjects,
  CustomerProject,
  Site as CustomerProjectSite,
  ReviewApplication as CustomerProjectApplication,
} from "@views/customerProjects";
import {
  Documents as CustomerProjectApplyDocs,
  SiteDetails as CustomerProjectApplySiteDetails,
  FromRadicle as CustomerProjectApplyFromRadicle,
  Location as CustomerProjectApplyLocation,
  Devices as CustomerProjectApplyDevices,
  Review as CustomerProjectApplyReview,
  ProjectTermsAndConditions as CustomerProjectApplyProjectTerms,
  Installer as CustomerProjectApplyInstaller,
  PersonalInfo as CustomerProjectApplyPersonalInfo,
  Start as CustomerProjectApplyStart,
  TermsAndConditions as CustomerProjectApplyTerms,
  ThankYou as CustomerProjectApplyThankYou,
  Welcome as CustomerProjectApplyWelcome,
} from "@views/customerProjects/Apply/components";
import PayeeStatementsProjectReports from "views/admin/marketplace/components/PayeeStatements/ProjectReports";
import PayeeStatementsProjectReport from "views/admin/marketplace/components/PayeeStatements/ProjectReport";

// used in onboarding and applying, must be authenticated
const ProtectedRoute = ({ component, ...props }) => {
  const WrappedComponent = withAuthenticationRequired(component);
  return <WrappedComponent {...props} />;
};

const MarketplaceRoute = ({ component, ...props }) => {
  return (
    <MarketplaceGuard>
      <OrgRoute component={component} {...props} />
    </MarketplaceGuard>
  );
};

const ChannelParnterRoute = ({ component, ...props }) => {
  return (
    <ChannelPartnerGuard>
      <OrgRoute component={component} {...props} />
    </ChannelPartnerGuard>
  )
}

// these routes check to make sure you have a signed T&C first (as well as auth and orgId)
const OrgRoute = ({ component, ...props }) => {
  const WrappedComponent = withAuthenticationRequired(component);
  return (
    <OrganizationGuard>
      <TermsAndConditionsGuard>
        <WrappedComponent {...props} />
      </TermsAndConditionsGuard>
    </OrganizationGuard>
  );
};

const IndiRoute = ({ component, ...props }) => {
  const WrappedComponent = withAuthenticationRequired(component);
  return (
    <IndividualGuard>
      <TermsAndConditionsGuard>
        <WrappedComponent {...props} />
      </TermsAndConditionsGuard>
    </IndividualGuard>
  );
};

// these routes only work in the dev environment
const DevRoute = ({ component, ...props }) => {
  const prod = /app/.test(process.env.REACT_APP_PUBLIC_URL);
  if (prod) {
    return <NotFound />;
  } else {
    let Component = component;
    return <Component {...props} />;
  }
};

const Router = () => (
  <Routes>
    <Route path="/" element={<Landing />} />
    <Route path="/" element={<GenericLayout />}>
      <Route path="callback" element={<Callback />} />
      <Route path="404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/dashboard" element={<Navigate to="/projects" replace />} />
    <Route path="/projects" element={<DashboardLayout />}>
      <Route path="" element={<OrgRoute component={CustomerProjects} />} />
      <Route path=":customerProjectId" element={<OrgRoute component={CustomerProject} />} />
      <Route path=":customerProjectId/sites/:siteId" element={<OrgRoute component={CustomerProjectSite} />} />
      <Route path=":customerProjectId/applications/:applicationId" element={<OrgRoute component={CustomerProjectApplication} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/projects/apply" element={<ApplyLayout />}>
      {/* NB. this catches 404's too because :customerProjectId param can be a string */}
      <Route path=":customerProjectId" element={<CustomerProjectApplyWelcome />} />
      <Route path="start" element={<ProtectedRoute component={CustomerProjectApplyStart} />} />
      {/* NB. terms and personal are only access if the applicant doesn't have a user account */}
      <Route path="terms" element={<ProtectedRoute component={CustomerProjectApplyTerms} />} />
      <Route path="personal" element={<ProtectedRoute component={CustomerProjectApplyPersonalInfo} />} />
      {/* Application pages are below */}
      <Route path="sitedetails" element={<ProtectedRoute component={CustomerProjectApplySiteDetails} />} />
      <Route path="fromradicle" element={<ProtectedRoute component={CustomerProjectApplyFromRadicle} />} />
      <Route path="location" element={<ProtectedRoute component={CustomerProjectApplyLocation} />} />
      <Route path="devices" element={<ProtectedRoute component={CustomerProjectApplyDevices} />} />
      <Route path="docs" element={<ProtectedRoute component={CustomerProjectApplyDocs} />} />
      <Route path="installer" element={<ProtectedRoute component={CustomerProjectApplyInstaller} />} />
      <Route path="review" element={<ProtectedRoute component={CustomerProjectApplyReview} />} />
      <Route path="projectterms" element={<ProtectedRoute component={CustomerProjectApplyProjectTerms} />} />
      <Route path="thankyou" element={<ProtectedRoute component={CustomerProjectApplyThankYou} />} />
    </Route>
    <Route path="/myApplications" element={<DashboardLayout />}>
      <Route path="" element={<OrgRoute component={MyApplications} />} />
      <Route path=":applicationId" element={<OrgRoute component={SubmittedApplication} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/marketplace" element={<DashboardLayout />}>
      <Route path="" element={<MarketplaceRoute component={Portfolio} />} />
      <Route path="portfolio" element={<MarketplaceRoute component={Portfolio} />} />
      <Route path="portfolio/purchaseRequests/:purchaseRequestId" element={<MarketplaceRoute component={PurchaseRequest} />} />
      <Route path="purchase" element={<MarketplaceRoute component={PurchaseCredits} />} />
      <Route path="claimedCredits" element={<MarketplaceRoute component={ClaimedCredits} />} />
      <Route path="claimedCredits/:projectReportId" element={<MarketplaceRoute component={ClaimedProjectReport} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>

    <Route path="/channelPartner" element={<DashboardLayout />}>
      <Route path="referrals" element={<ChannelParnterRoute component={Referrals} />} />
      <Route path="projects" element={<ChannelParnterRoute component={ChannelPartnerProjects} />} />
    </Route>

    <Route path="/settings" element={<DashboardLayout />}>
      <Route path="account" element={<OrgRoute component={Account} />} />
      <Route path="organization" element={<OrgRoute component={Organization} />} />
      <Route path="members" element={<OrgRoute component={Members} />} />
      <Route path="*" element={<Navigate to="/404" />} />{" "}
    </Route>
    <Route path="/onboarding" element={<OnboardingLayout />}>
      <Route path="" element={<ProtectedRoute component={OnboardingTermsAndConditions} />} />
      <Route path="terms" element={<ProtectedRoute component={OnboardingTermsAndConditions} />} />
      <Route path="contact" element={<ProtectedRoute component={OnboardingContactInfo} />} />
      <Route
        path="organization"
        element={<ProtectedRoute component={OnboardingOrganizationInfo} />}
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/channelPartners" element={<OnboardingLayout />}>
      <Route path="accept" element={<ChannelPartnerInviteWelcome />} />
      <Route path="start" element={<ProtectedRoute component={ChannelPartnerStart} />} />
      <Route path="terms" element={<ProtectedRoute component={ChannelPartnerInviteTermsAndConditions} />} />
      <Route path="personal" element={<ProtectedRoute component={ChannelPartnerPersonalInfo} />} />
      <Route path="organization" element={<ProtectedRoute component={ChannelPartnerOrganizationInvite} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/organizations/accept" element={<OnboardingLayout />}>
      <Route path="" element={<OrgInviteWelcome />} />
      <Route path="terms" element={<ProtectedRoute component={OrgInviteTermsAndConditions} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/marketplace/buyer" element={<OnboardingLayout />}>
      <Route path="accept" element={<BuyerInviteWelcome />} />
      <Route path="start" element={<ProtectedRoute component={BuyerStart} />} />
      <Route path="terms" element={<ProtectedRoute component={BuyerInviteTermsAndConditions} />} />
      <Route path="personal" element={<ProtectedRoute component={BuyerPersonalInfo} />} />
      <Route path="organization" element={<ProtectedRoute component={BuyerOrganization} />} />
      <Route path="join" element={<ProtectedRoute component={BuyerAcceptInvite} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/marketplace/payee" element={<OnboardingLayout />}>
      <Route path="accept" element={<PayeeInviteWelcome />} />
      <Route path="start" element={<ProtectedRoute component={PayeeStart} />} />
      <Route path="terms" element={<ProtectedRoute component={PayeeInviteTermsAndConditions} />} />
      <Route path="personal" element={<ProtectedRoute component={PayeePersonalInfo} />} />
      <Route path="bankinfo" element={<ProtectedRoute component={BankInformation} />} />
      <Route path="join" element={<ProtectedRoute component={PayeeAcceptInvite} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/assetclaim/accept" element={<OnboardingLayout />}>
      <Route path="" element={<AssetClaimWelcome />} />
      <Route path="start" element={<ProtectedRoute component={AssetClaimStart} />} />
      <Route path="terms" element={<ProtectedRoute component={AssetClaimTermsAndConditions} />} />
      <Route path="personal" element={<ProtectedRoute component={AssetClaimPersonalInfo} />} />
      <Route path="join" element={<ProtectedRoute component={AssetClaimAcceptInvite} />} />
    </Route>
    {/* @deprecated - replaced by "/projects/apply" routes */}
    <Route path="/apply" element={<ApplyLayout />}>
      {/* NB. Apply Welcome redirects to projects/apply */}
      <Route path=":programId" element={<ApplyWelcome />} />
    </Route>
    <Route path="/siteOwner" element={<ApplyLayout />}>
      <Route path="landing" element={<SiteOwnerLanding />} />
      <Route path="thankyou" element={<SiteOwnerThankYou />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/individual" element={<IndividualLayout />}>
      <Route path="" element={<Navigate to="/individual/dashboard" />} />
      <Route path="dashboard" element={<IndiRoute component={IndividualDashboard} />} />
      <Route path="account" element={<IndiRoute component={IndividualAccount} />} />
      <Route path="statements" element={<IndiRoute component={IndividualPayeeStatements} />} />
      <Route path="applications/:applicationId" element={<IndiRoute component={IndividualApplication} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/admin" element={<DashboardLayout />}>
      <Route path="monitoring" element={<OrgRoute component={Monitoring} />} />
      <Route path="orgAssets/:orgAssetId" element={<OrgRoute component={OrgAsset} />} />
      <Route path="projects" element={<OrgRoute component={Projects} />} />
      <Route path="projects/:projectId" element={<OrgRoute component={Project} />} />
      <Route
        path="projects/:projectId/projectReports/:projectReportId"
        element={<OrgRoute component={ProjectReport} />}
      />
      <Route path="marketplace/buyers" element={<OrgRoute component={Buyers} />} />
      <Route
        path="marketplace/purchaseRequests"
        element={<OrgRoute component={PurchaseRequests} />}
      />
      <Route path="marketplace/payeeStatements" element={<OrgRoute component={PayeeStatementsProjectReports} />} />
      <Route path="marketplace/payeeStatements/:projectReportId" element={<OrgRoute component={PayeeStatementsProjectReport} />} />
      <Route path="channelPartners" element={<OrgRoute component={ChannelPartners} />} />
      <Route path="channelPartners/:channelPartnerId" element={<OrgRoute component={ChannelPartnerDetails} />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="/styleguide" element={<DevRoute component={StyleGuide} />} />
    <Route path="/public">
      <Route path="sites/:siteId" element={<LiveSite />} />
      <Route path="certificates/:projectReportId" element={<ProjectReportCertificate />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </Routes>
);

export default Router;
