import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const getOrgAsset = createRoutineCreator([...defaultRoutineStages, "RESET", "PATCH_ACTIVITY_START_DATE"])("GET_ORG_ASSET");
const getGenerationChart = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "GET_GENERATION_CHART"
);

// admin can patch
const patchOrgAssetDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_ORG_ASSET_DOCUMENTS"
);

const patchActivityStartDate = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_ACTIVITY_START_DATE"
);

export { getOrgAsset, patchOrgAssetDocuments, patchActivityStartDate, getGenerationChart };
