import React from "react";
import PropTypes from "prop-types";
import { Typography, Paper, Box } from "@mui/material";
import { find } from "lodash";
import { DateTime } from "luxon";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import ProjectFilesUploader from "./ProjectFilesUploader";
import ProjectDocumentList from "./ProjectDocumentList";
import { addProjectDocuments, setProjectStatus, uploadProjectFiles } from "../../actions";
import StatusStep from "./StatusStep";

const ProjectStatusPanel = ({ project, editable = true, title = "Project Status" }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const submitted = find(project.statusHistory, { status: "SUBMITTED" });
  const registered = find(project.statusHistory, { status: "REGISTERED" });
  const archived = find(project.statusHistory, { status: "ARCHIVED" });

  // file was uploaded so record it with project and status
  const handleUploaded = async (filespecs, status) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });
    dispatch(addProjectDocuments({ accessToken, project, status, filespecs }));
    dispatch(uploadProjectFiles.reset());
  };

  // project status is changed
  const handleProjectStatusChange = async (status) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });
    dispatch(setProjectStatus({ accessToken, project, status }));
  };

  return (
    <>
      {title && (
        <Typography variant="h3" gutterBottom ml={2}>
          {title}
        </Typography>
      )}

      <Paper sx={{ p: 3 }}>
        <Box display="flex" direction="row" justifyContent="flex-start">
          <StatusStep
            title="Created"
            subtitle={
              project.created &&
              DateTime.fromSeconds(project.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!project.created}
          />

          <StatusStep
            title="Submitted"
            subtitle={
              submitted && DateTime.fromSeconds(submitted.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!submitted}
            onChangeStatus={() => handleProjectStatusChange("SUBMITTED")}
            disabled={!editable}
          >
            <Box>
              {editable && (
                <ProjectFilesUploader
                  onChange={(fs) => handleUploaded(fs, "SUBMITTED")}
                  sx={{ mr: 3 }}
                  discriminator="SUBMITTED"
                />
              )}
              <ProjectDocumentList
                documents={submitted?.documents}
                project={project}
                editable={editable}
              />
            </Box>
          </StatusStep>

          <StatusStep
            title="Registered"
            subtitle={
              registered &&
              DateTime.fromSeconds(registered.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!registered}
            onChangeStatus={() => handleProjectStatusChange("REGISTERED")}
            disabled={!editable}
          >
            <Box>
              {editable &&  (<ProjectFilesUploader
                onChange={(fs) => handleUploaded(fs, "REGISTERED")}
                sx={{ mr: 3 }}
                discriminator="REGISTERED"
              />)}
              <ProjectDocumentList
                documents={registered?.documents}
                project={project}
                editable={editable}
              />
            </Box>
          </StatusStep>

          <StatusStep
            title="Archived"
            subtitle={
              archived && DateTime.fromSeconds(archived.created).toLocaleString(DateTime.DATE_MED)
            }
            incomplete={!archived}
            isEnd
            onChangeStatus={() => handleProjectStatusChange("ARCHIVED")}
            sx={{ minWidth: 100 }}
          />
        </Box>
      </Paper>
    </>
  );
};

ProjectStatusPanel.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
};

export default ProjectStatusPanel;
