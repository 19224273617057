import React from "react";
import { Typography, Box } from "@mui/material";
import { get, reduce } from "lodash";
import { ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import pluralize from "pluralize";
import CustomDataGrid from "@components/CustomDataGrid";
import { wmTokWh } from "@utils/energyHelpers";
import { getFullName } from "@utils/stringHelpers";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { renderCellExpand } from "@components/GridCellExpand";

const SiteContributions = ({ project, isLoading }) => {
  const {
    projectReportCalculation: { sites, startDate, endDate },
  } = useSelector((state) => state.getProjectReportCalculation);

  // table
  const columns = React.useMemo(() => {
    return [
      { headerName: "Site ID", field: "id", flex: 0.5 },
      { headerName: "Site Name", field: "name", flex: 1, renderCell: renderCellExpand },
      {
        headerName: "Capacity (kW)",
        field: "capacity",
        flex: 0.8,
        valueFormatter: ({ value }) => {
          return (value / 1000).toLocaleString();
        },
      },
      {
        headerName: "Location",
        field: "location",
        flex: 1.2,
        renderCell: ({ value: location }) => {
          return (
            <Typography fontWeight="bold" component="div" variant="body2">
              {location.city},{" "}
              <Typography display="inline" variant="body2">
                {location.region}
              </Typography>
              <Typography variant="body2">{location.street}</Typography>
            </Typography>
          );
        },
      },
      {
        headerName: "Owner",
        field: "siteOwner",
        flex: 1,
        valueFormatter: ({ value: siteOwner }) => {
          if (siteOwner?.external) {
            return getFullName(siteOwner.external);
          } else if (siteOwner?.individual) {
            return getFullName(siteOwner.individual);
          } else {
            return siteOwner?.organization?.name || "-";
          }
        },
      },
      {
        headerName: "Energy Generated (kWh)",
        field: "energy",
        flex: 1,
        valueFormatter: ({ value }) => wmTokWh(value).toLocaleString(),
      },
      {
        headerName: "Environmental Offsets",
        field: "emissionOffsets",
        flex: 1,
        valueFormatter: ({ value }) => value.toLocaleString(),
      },
    ];
  }, []);

  // sums
  const totalOffsets = reduce(sites, (sum, site) => sum + site.emissionOffsets, 0);
  const totalEnergy = wmTokWh(reduce(sites, (sum, site) => sum + site.energy, 0));

  return (
    <Box mt={5}>
      <Typography variant="h3">
        {pluralize(get(ENVIRONMENTAL_CREDIT_TYPES, project.environmentalCredit))}
      </Typography>
      <Typography variant="h4" my={1}>
        <Typography variant="h4" component="span" color="secondary.main" fontWeight="bold">
          {totalOffsets.toLocaleString()}{" "}
        </Typography>
        {pluralize("credit", totalOffsets)} calculated from{" "}
        <Typography variant="h4" component="span" color="secondary.main" fontWeight="bold">
          {Math.floor(totalEnergy).toLocaleString()}{" "}
        </Typography>
        kWh generated, from{" "}
        <Typography variant="h4" component="span" color="secondary.main">
          {startDate
            ? DateTime.fromISO(startDate)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)
            : "N/A"}
        </Typography>{" "}
        to{" "}
        <Typography variant="h4" component="span" color="secondary.main">
          {endDate
            ? DateTime.fromISO(endDate)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)
            : "N/A"}
        </Typography>
        .
      </Typography>
      <div style={{ height: "100%", width: "100%" }}>
        <CustomDataGrid
          autoHeight
          loading={isLoading}
          rows={sites}
          columns={columns}
          hideFooter
          pagination={false}
        />
      </div>
    </Box>
  );
};

export default SiteContributions;
