import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";

const ApiKeyPanel = ({ credentials }) => {
  const { errors, control } = useFormContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="API Key"
              variant="standard"
              fullWidth
              autoFocus
              error={!isNil(errors.apiKey?.message)}
              helperText={errors.apiKey?.message || " "}
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          name="apiKey"
          control={control}
          rules={{ required: "API Key is required." }}
          defaultValue={credentials.apiKey}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} flexDirection="column">
          <Box>
            <ConnectionTest />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

ApiKeyPanel.propTypes = {
  credentials: PropTypes.shape({
    apiKey: PropTypes.string,
  }).isRequired,
};

export default ApiKeyPanel;
