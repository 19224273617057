import { createAction } from "redux-actions";
import { createRoutineCreator, defaultRoutineStages } from "redux-saga-routines";

const approveApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("APPROVE_APPLICATION");

const declineApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("DECLINE_APPLICATION");

const requireChangesToApplication = createRoutineCreator([
  ...defaultRoutineStages,
  "RESET",
])("REQUIRE_CHANGES_TO_APPLICATION");

const setApplicationsTableState = createAction("CUSTOMER_PROJECT_APPLICATIONS_TABLE_STATE/SET");
setApplicationsTableState.TYPE = `${setApplicationsTableState.toString()}`;

export {
  setApplicationsTableState,
  approveApplication,
  declineApplication,
  requireChangesToApplication,
};
