import { call, put, takeLatest } from "redux-saga/effects";
import { getPayeeStatements } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken) => {
  return await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/payeeStatements`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
};

function* getPayeeStatementsWorker(action) {
  try {
    yield put(getPayeeStatements.request());
    const { accessToken } = action.payload;
    const response = yield call(callApi, accessToken);
    yield put(
      getPayeeStatements.success({ statements: response.data })
    );
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to get statements: ${message}`;
    yield put(getPayeeStatements.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getPayeeStatementsWatcher() {
  yield takeLatest(
    getPayeeStatements.TRIGGER,
    getPayeeStatementsWorker
  );
}

export default [getPayeeStatementsWatcher];
