import { call, put, takeLatest } from "redux-saga/effects";
import { getApplicationNotes } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, applicationId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${applicationId}/notes`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getApplicationNotesWorker(action) {
  yield put(getApplicationNotes.request());

  const { accessToken, applicationId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, applicationId);
    const { notes } = response.data;
    yield put(getApplicationNotes.success({ notes }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting application notes: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting application notes: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting application notes: ", err);
      message = err.message;
    }
    message = `Unable to get application notes: ${message}`;
    yield put(getApplicationNotes.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getApplicationNotesWatcher() {
  yield takeLatest(getApplicationNotes.TRIGGER, getApplicationNotesWorker);
}

export default [getApplicationNotesWatcher];
