import React from "react";
import PropTypes from "prop-types";
import { Button, Menu, MenuItem, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";

const xAxisFormatter = (value, granularity, format) => {
  if (granularity === "hour") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd HH:mm:ss");
    return d.toFormat(format || "MMM d, HH:mm");
  } else if (granularity === "day") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd");
    return d.toFormat(format || "MMM d");
  } else if (granularity === "month") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd");
    return d.toFormat(format || "MMM yyyy");
  } else if (granularity === "year") {
    const d = DateTime.fromFormat(value, "yyyy-MM-dd");
    return d.toFormat(format || "yyyy");
  }
  return value;
};

// just a simple div by 2 algorithm to get < 30 ticks
const getNumberOfTicks = (data, isSmallScreen) => {
  const maxTicks = isSmallScreen ? 15 : 30;
  if (isEmpty(data)) return maxTicks;
  let i = data[0].data.length;
  while (i > maxTicks) {
    i = i / 2;
  }
  return i;
};

// all ends are today
export const DATE_RANGE_PRESETS = [
  {
    // hour
    key: "TODAY",
    label: "Today",
    start: DateTime.now().startOf("day"),
    ticks: (data, isSmallScreen) => getNumberOfTicks(data, isSmallScreen),
    formatter: (value, granularity) =>
      xAxisFormatter(value, granularity, "HH:mm"),
  },
  {
    // hour
    key: "LAST_3_DAYS",
    label: "Last 3 days",
    // we include part of today
    start: DateTime.now().minus({ days: 2 }).startOf("day"),
    ticks: (data, isSmallScreen) => getNumberOfTicks(data, isSmallScreen),
    formatter: xAxisFormatter,
  },
  {
    // hour
    key: "LAST_7_DAYS",
    label: "Last 7 days",
    // we include part of today
    start: DateTime.now().minus({ days: 6 }).startOf("day"),
    ticks: (data, isSmallScreen) => getNumberOfTicks(data, isSmallScreen),
    formatter: xAxisFormatter,
  },
  {
    // day
    key: "LAST_30_DAYS",
    label: "Last 30 days",
    // we include part of today
    start: DateTime.now().minus({ days: 29 }).startOf("day"),
    ticks: 15,
    formatter: xAxisFormatter,
  },
  {
    // day
    key: "LAST_3_MONTHS",
    label: "Last 3 months",
    // we'll include part of this month, so just subtract 2
    start: DateTime.now().minus({ months: 2 }).startOf("month"),
    ticks: (data, isSmallScreen) => (isSmallScreen ? 10 : 20),
    formatter: xAxisFormatter,
  },
  {
    // month
    key: "LAST_12_MONTHS",
    label: "Last 12 months",
    // we'll include part of this month, so just subtract 11
    start: DateTime.now().minus({ months: 11 }).startOf("month"),
    ticks: 12,
    formatter: xAxisFormatter,
  },
  {
    // month
    key: "LAST_3_YEARS",
    label: "Last 3 years",
    // we'll include part of this year, so just subtract 2
    start: DateTime.now().minus({ years: 2 }).startOf("year"),
    ticks: 18,
    formatter: xAxisFormatter,
  },
  {
    // year
    key: "LAST_10_YEARS",
    label: "Last 10 years",
    // we'll include part of this year, so just subtract 9
    start: DateTime.now().minus({ years: 9 }).startOf("year"),
    ticks: 10,
    formatter: xAxisFormatter,
  },
  {
    key: "FIXED",
    label: "Fixed",
    disabled: true,
    formatter: xAxisFormatter,
    ticks: (data, isSmallScreen) => getNumberOfTicks(data, isSmallScreen),
  },
];

const DateRangePresetMenu = ({
  dateRangePreset,
  onDateRangePresetChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (dateRangePreset) => {
    onDateRangePresetChange(dateRangePreset);
    handleClose();
  };

  return (
    <Box display="flex" justifyContent="flex-end" sx={{ minWidth: 160 }}>
      <Button
        endIcon={<ArrowDropDownIcon />}
        size="small"
        variant="text"
        aria-controls="dateRangePreset"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {dateRangePreset.label}
      </Button>
      <Menu
        id="dateRangePreset"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {DATE_RANGE_PRESETS.map((dp) => {
          const selected = dp.key === dateRangePreset.key;
          return (
            <MenuItem
              onClick={() => handleSelect(dp)}
              selected={selected}
              key={dp.key}
              disabled={dp.disabled}
            >
              {dp.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

DateRangePresetMenu.propTypes = {
  dateRangePreset: PropTypes.oneOf(DATE_RANGE_PRESETS).isRequired,
  onDateRangePresetChange: PropTypes.func.isRequired,
};

export default DateRangePresetMenu;
