import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditProjectEditor from "../components/EditProjectEditor";
import {
  patchProject,
  getProject,
  getProjectSites,
  getProjectStats,
} from "../actions";
import { getAcceptableSites } from "@views/admin/projects/actions";

const mapStateToProps = ({ patchProject: patchProjectResult }) => ({
  patchProjectResult,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      patchProject,
      getProject,
      getProjectSites,
      getProjectStats,
    },
    dispatch
  ),
  resetPatchProject: () => {
    dispatch(patchProject.reset());
  },
  resetGetAcceptableSites: () => {
    dispatch(getAcceptableSites.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectEditor);
