import { call, put, takeLatest } from "redux-saga/effects";
import { patchApplicationAddDocuments } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, applicationId, documents) => await axios.post(
  `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${applicationId}/documents`,
  documents,
  { headers: { Authorization: `Bearer: ${accessToken}` } }
);

function* patchApplicationAddDocumentsWorker(action) {
  try {
    yield put(patchApplicationAddDocuments.request());
    const { accessToken, applicationId, documents } = action.payload;
    const response = yield call(callApi, accessToken, applicationId, documents);
    yield put(patchApplicationAddDocuments.success({ application: response.data }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to add documents to the application: ${message}`;
    yield put(patchApplicationAddDocuments.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchApplicationAddDocumentsWatcher() {
  yield takeLatest(patchApplicationAddDocuments.TRIGGER, patchApplicationAddDocumentsWorker);
}

export default [patchApplicationAddDocumentsWatcher];
