import getApplication from "./getApplication";
import patchAppSiteProfile from "./patchAppSiteProfile";
import patchAppSiteDocuments from "./patchAppSiteDocuments";
import patchAppSiteOwnership from "./patchAppSiteOwnership";
import patchAppSiteConnection from "./patchAppSiteConnection";
import patchAppSiteInstaller from "./patchAppSiteInstaller";
import getApplicationNotes from "./getApplicationNotes";
import putApplicationNotes from "./putApplicationNotes";
import sendProgramTerms from "./sendProgramTerms";

export default [
  ...getApplication,
  ...patchAppSiteProfile,
  ...patchAppSiteDocuments,
  ...patchAppSiteOwnership,
  ...patchAppSiteConnection,
  ...patchAppSiteInstaller,
  ...getApplicationNotes,
  ...putApplicationNotes,
  ...sendProgramTerms,
];
