import React from "react";
import { get } from "lodash";
import { styled } from "@mui/material/styles";
import { Typography, Stack } from "@mui/material";

const EnergyCard = styled(({ title, value, ...other }) => (
  <Stack direction="column" alignItems="center" p={2} {...other}>
    <Typography
      variant="h3"
      color="common.white"
      textAlign="center"
    >
      {title}
    </Typography>
    <Typography variant="h4" color="common.white">
      {value}
    </Typography>
  </Stack>
))(({ theme, color }) => {
  return {
    backgroundColor: get(theme.palette, color),
    borderRadius: theme.shape.borderRadius,
  };
});

export default EnergyCard;
