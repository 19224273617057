import React, { useEffect, useMemo, useState, useRef } from "react";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { find, last, sortBy } from "lodash";
import { ProjectReportDetailPanel } from "@admin/projectReport";
import { ProjectReportStatusPanel } from "@admin/projectReport";
import VerificationPanel from "./VerificationPanel";
import CustomDataGrid from "@components/CustomDataGrid";
import { DateTime } from "luxon";
import { getDefaultLocale } from "utils/dateHelpers";
import PageHeader from "@components/PageHeader";
import Breadcrumb from "@components/Breadcrumb";
import { downloadFile } from "utils/fileHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { renderCellExpand } from "@components/GridCellExpand";
import Page from "@components/Page";
import { round } from "utils/numberHelpers";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBuyerPurchaseRequests } from "../actions";
import PageLoader from "@components/PageLoader";
import CreditCard from "@views/dashboard/components/CreditCard";
import { ProjectDetailPanel } from "@admin/project";

export const PURCHASE_REQUEST_STATUSES_BUYER = {
  NOT_STARTED: "Purchase Requested",
  PAYMENT_REQUESTED: "Invoice Received",
  PAYMENT_RECEIVED: "Payment Sent",
  OWNERSHIP_TRANSFERRED: "Transferred on Registry",
  TRANSACTION_COMPLETE: "Transaction Complete",
  REQUEST_CANCELLED: "Request Cancelled",
};

const StatusHistory = ({ statusHistory }) => {
  const [pageSize, setPageSize] = useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const { getAccessTokenSilently } = useAuth0();

  const columns = useMemo(() => {
    const handleDownloadDocument = async (document) => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      downloadFile(document, accessToken);
    };

    return [
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        valueGetter: ({ value }) => PURCHASE_REQUEST_STATUSES_BUYER[value],
        renderCell: renderCellExpand,
      },
      {
        headerName: "Date",
        field: "date",
        flex: 1,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATETIME_SHORT),
      },
      {
        headerName: "Changed By",
        field: "changedBy",
        flex: 0.7,
        renderCell: renderCellExpand,
        valueGetter: ({ value }) => `${value.givenName} ${value.familyName}`,
      },
      {
        headerName: "Documents",
        field: "documents",
        flex: 1.2,
        renderCell: ({ value: documents }) =>
          documents.map((doc) => (
            <Button
              key={doc.id}
              variant="text"
              size="small"
              onClick={() => handleDownloadDocument(doc)}
              mx={1}
            >
              {doc.name}
            </Button>
          )),
      },
    ];
  }, [getAccessTokenSilently]);

  return (
    <Paper>
      <Box height={400}>
        <CustomDataGrid
          style={{ border: 0 }}
          rowHeight={50}
          rows={statusHistory}
          columns={columns}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </Box>
    </Paper>
  );
};

const PurchaseRequest = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { purchaseRequestId } = useParams();

  // see if we have a purchase request
  const purchaseRequest = useSelector((state) => {
    return find(state.getBuyerPurchaseRequests.purchaseRequests, {
      id: purchaseRequestId * 1,
    });
  });
  const projectReport = purchaseRequest?.projectReport;

  // otherwise refetch it
  const { status } = useSelector((state) => state.getBuyerPurchaseRequests);
  const isLoading = status === "request" || status === "init";
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "manage_credits",
      });
      dispatch(getBuyerPurchaseRequests({ accessToken }));
    }
    if (!purchaseRequest) {
      init();
    }
  }, [getAccessTokenSilently, dispatch, purchaseRequest]);

  // scroll to top
  const pageRef = useRef();
  useEffect(() => {
    if (!isLoading) {
      pageRef?.current?.scrollIntoView();
    }
  }, [isLoading]);

  const mostRecentStatus = last(
    sortBy(purchaseRequest?.statusHistory, "created")
  );
  return (
    <Page py={3} title="Purchase Request" ref={pageRef}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Loading..." />}
        {!isLoading && (
          <>
            <PageHeader
              title={`Request to purchase project report ${projectReport.id}`}
            >
              <Breadcrumb
                title="Portfolio"
                destination="/marketplace/portfolio"
              />
              <Breadcrumb title="Purchase Request" />
            </PageHeader>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={6}>
                <CreditCard
                  title="Current Status"
                  primary={
                    PURCHASE_REQUEST_STATUSES_BUYER[mostRecentStatus?.status]
                  }
                  loading={isLoading}
                  sx={{
                    "& .MuiCardContent-root .MuiTypography-root": {
                      color: "info.main",
                      fontSize: "1.2rem",
                      fontWeight: 600,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CreditCard
                  title="Purchase Summary"
                  primary={`$${round(
                    projectReport.pricePerCredit *
                      projectReport.totalEnvironmentalCredits,
                    2
                  ).toLocaleString()}`}
                  secondary={`${round(
                    projectReport.totalEnvironmentalCredits,
                    3
                  ).toLocaleString()} credits at $${round(
                    projectReport.pricePerCredit,
                    2
                  )} each`}
                  loading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h3" ml={2}>
                  Purchase Request Status History
                </Typography>
                <StatusHistory statusHistory={purchaseRequest.statusHistory} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography variant="h3" gutterBottom ml={2}>
                      Project
                    </Typography>
                    <ProjectDetailPanel
                      project={projectReport.project}
                      editable={false}
                      hidePlanningSheet
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h3" gutterBottom ml={2}>
                      Project Report
                    </Typography>
                    <ProjectReportDetailPanel
                      project={projectReport.project}
                      projectReport={projectReport}
                      hideReportingSheet
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <ProjectReportStatusPanel
                      projectReport={projectReport}
                      editable={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <VerificationPanel projectReport={projectReport} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
};

export default PurchaseRequest;
