import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useAppMessage } from "@components/appMessage";
import { useSelector, useDispatch } from "react-redux";
import { testConnection } from "@addSites/actions";
import { initialState } from "@views/apply/reducers/application";
import { Manufacturer, SitePicker, SiteCompare } from ".";
import { patchAppSiteConnection } from "@views/reviewApplication/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConnectionEditor = ({ open, application, onClose, onSave }) => {
  const formMethods = useForm();
  const showAppMessage = useAppMessage();
  const dispatch = useDispatch();

  // track which component to display
  const [activeStep, setActiveStep] = useState(0);

  const connectionTestResult = useSelector((state) => state.testConnection);

  // place siteInfo in state, we update manufacturer and credentials
  const [siteInfo, setSiteInfo] = useState(application.siteInfo);
  const saveCredentials = (credentials) => {
    setSiteInfo({
      ...siteInfo,
      credentials,
    });
  };
  const saveManufacturer = ({ manufacturer }) => {
    setSiteInfo({
      ...siteInfo,
      manufacturer,
    });
  };
  const saveSite = ({ site }) => {
    setSiteInfo({
      ...siteInfo,
      site,
    });
  };

  // reset everything
  const onCloseEditor = () => {
    formMethods.reset();
    setSiteInfo({
      credentials: initialState.siteInfo.credentials,
      manufacturer: "",
    });
    setActiveStep(0);
    dispatch(testConnection.reset());
    dispatch(patchAppSiteConnection.reset());
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onCloseEditor}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCloseEditor}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2} flex={1}>
            Site Connection
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Box sx={{ my: 4, mx: { xs: 1, sm: 2 } }}>
          {activeStep === 0 && (
            <Manufacturer
              nextAction={() => setActiveStep(1)}
              formMethods={formMethods}
              siteInfo={siteInfo}
              showAppMessage={showAppMessage}
              saveManufacturer={saveManufacturer}
              saveCredentials={saveCredentials}
              testConnection={(props) => dispatch(testConnection(props))}
              resetTestConnection={() => dispatch(testConnection.reset())}
              connectionTestResult={connectionTestResult}
            />
          )}
          {activeStep === 1 && (
            <SitePicker
              program={application.program}
              siteInfo={siteInfo}
              saveSite={saveSite}
              next={() => setActiveStep(2)}
              prev={() => setActiveStep(0)}
            />
          )}
          {activeStep === 2 && (
            <SiteCompare
              application={application}
              siteInfo={siteInfo}
              onSubmit={onSave}
              onClose={onCloseEditor}
              prev={() => setActiveStep(1)}
            />
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

ConnectionEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  application: PropTypes.shape({}),
};

export default ConnectionEditor;
