import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { ProjectReportDetailPanel } from "@admin/projectReport";
import { ProjectReportStatusPanel } from "@admin/projectReport";
import VerifierPanel from "@admin/projectReport/components/VerifierPanel";
import PageHeader from "components/PageHeader";
import Breadcrumb from "@components/Breadcrumb";
import UpdatePurchaseRequestStatus from "./UpdatePurchaseRequestStatus";
import Page from "components/Page";
import CreditCard from "@views/dashboard/components/CreditCard";
import { getFullName } from "@utils/stringHelpers";
import PurchaseRequestStatusHistory from "./PurchaseRequestStatusHistory";
import { ProjectDetailPanel } from "@admin/project";

// for admin, there is another version for buyer here:
// /views/marketplace/portfolio/components/PurchaseRequest.jsx
export const PURCHASE_REQUEST_STATUSES = {
  NOT_STARTED: {
    key: "NOT_STARTED",
    display: "Not Started",
    description:
      "Nothing has happened yet, beyond the initial request to purchase.",
  },
  PAYMENT_REQUESTED: {
    key: "PAYMENT_REQUESTED",
    display: "Payment Requested",
    description: "Payment has been asked from the buyer by Rewatt.",
  },
  PAYMENT_RECEIVED: {
    key: "PAYMENT_RECEIVED",
    display: "Payment Received",
    description: "Payment has been received from the buyer by Rewatt.",
  },
  OWNERSHIP_TRANSFERRED: {
    key: "OWNERSHIP_TRANSFERRED",
    display: "Ownership Transferred",
    description:
      "The ownership of the credits has been transferred successfully.",
  },
  TRANSACTION_COMPLETE: {
    key: "TRANSACTION_COMPLETE",
    display: "Transaction Complete",
    description: "All off-platform activites are complete.",
  },
  REQUEST_CANCELLED: {
    key: "REQUEST_CANCELLED",
    display: "Request Cancelled",
    description:
      "The purchase request was cancelled, and all actions reverted as necessary.",
  },
};

const PurchaseRequest = ({ close, purchaseRequest }) => {
  const [showUpdateStatus, setShowUpdateStatus] = React.useState(false);
  const openUpdateStatus = () => {
    setShowUpdateStatus(true);
  };
  const closeUpdateStatus = () => {
    setShowUpdateStatus(false);
  };
  const handleDone = () => {
    setShowUpdateStatus(false);
    close();
  };

  const projectReport = purchaseRequest.projectReport;
  return (
    <Page py={3} title="Purchase Request">
      <Container maxWidth="lg">
        <PageHeader
          title={`Request to purchase project report #${projectReport.id}`}
        >
          <Breadcrumb title="Purchase Requests" destination="close" onClick={close} />
        </PageHeader>
        <UpdatePurchaseRequestStatus
          open={showUpdateStatus}
          handleCancel={closeUpdateStatus}
          handleDone={handleDone}
          purchaseRequest={purchaseRequest}
        />
        <Grid container spacing={3} mt={3}>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={4}>
              <CreditCard
                title="Current Status"
                primary={
                  PURCHASE_REQUEST_STATUSES[purchaseRequest.status].display
                }
                sx={{
                  "& .MuiCardContent-root .MuiTypography-root": {
                    color: "info.main",
                    fontSize: "1.2rem",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CreditCard
                title="Offered Price per Credit"
                primary={`$${purchaseRequest.pricePerCredit}`}
                sx={{
                  "& .MuiCardContent-root .MuiTypography-root": {
                    fontSize: "1.2rem",
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CreditCard
                title="Buyer Details"
                primary={`Requested by ${getFullName(purchaseRequest.buyer)}`}
                secondary={`Organization: ${purchaseRequest.buyer.organizationId}`}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography gutterBottom variant="h3">
                Purchase Request Status History
              </Typography>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={openUpdateStatus}
                sx={{
                  "&.MuiButton-containedSecondary": {
                    color: "common.white",
                  },
                }}
              >
                Change Status
              </Button>
            </Box>
            <PurchaseRequestStatusHistory
              statusHistory={purchaseRequest.statusHistory}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={3} ml={1}>
              <Grid container spacing={3}>
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Project
                  </Typography>
                  <ProjectDetailPanel
                    project={projectReport.project}
                    editable={false}
                    hidePlanningSheet
                  />
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="h3" gutterBottom ml={2}>
                    Project Report
                  </Typography>
                  <ProjectReportDetailPanel
                    project={projectReport.project}
                    projectReport={projectReport}
                    hideReportingSheet
                  />
                </Grid>
                <Grid item sm={12}>
                  <ProjectReportStatusPanel projectReport={projectReport} />
                </Grid>
                <Grid item xs={12}>
                  <VerifierPanel projectReport={projectReport} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PurchaseRequest;
