import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Link,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import AcrobatLogo from "@components/AcrobatLogo";
import RewattTermsPDF from "@assets/ReWatt - Account Terms and Conditions - v.3.pdf";
import { downloadFile } from "@utils/fileHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppMessage } from "@components/appMessage";

const LegalPanel = () => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();

  const downloadTerms = async (pdf) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(pdf, accessToken);
    } catch (err) {
      showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      });
    }
  };

  const tableRows = [
    {
      label: "Terms and Conditions",
      value: (
        <Link
          href="#"
          onClick={(ev) => {
            ev.preventDefault();
            downloadTerms({
              url: RewattTermsPDF,
              name: "Rewatt Terms and Conditions.pdf",
            });
          }}
        >
          <Typography>
            <AcrobatLogo />
            Download PDF
          </Typography>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Legal
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="user profile info">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default LegalPanel;
