import React, { useEffect, useReducer } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormControl,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { isNil, map, omit } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { getAdminPurchaseRequests, updatePurchaseRequestStatus } from "../../actions";
import { useAppMessage } from "@components/appMessage";
import { PURCHASE_REQUEST_STATUSES } from "./PurchaseRequest";
import DocumentUploader from "views/addSites/sitePicker/containers/DocumentUploader";
import { documentsReducer } from "views/addSites/sitePicker/components/DocumentUploader";
import { PurchaseRequestStatusTagProvider } from '@addSites/sitePicker/components/TagSelectorContext';
import TextArea from "@components/TextArea";

const StatusMenuItem = (props) => (
  <MenuItem {...props}>
    <ListItemText
      primary={props["data-value"]["display"]}
      secondary={props["data-value"]["description"]}
    />
  </MenuItem>
);

const schema = yup.object().shape({
  // status: yup.string().oneOf(Object.keys(PURCHASE_REQUEST_STATUSES)).required("Status is required"),
  notes: yup.string().notRequired().max(4096, "Too long."),
});

const UpdatePurchaseRequestStatus = ({ open, handleDone, handleCancel, purchaseRequest }) => {
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();
  const { getAccessTokenSilently } = useAuth0();

  const [documents, specialDispatch] = useReducer(documentsReducer, []);

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      status: "",
      notes: "",
    },
  });

  const onSubmit = async (newStatus) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace",
    });
    dispatch(
      updatePurchaseRequestStatus({
        accessToken,
        newStatus: {
          ...newStatus,
          status: newStatus.status.key,
        },
        documents,
        purchaseRequestId: purchaseRequest.id,
      })
    );
  };

  const { status } = useSelector((state) => state.updatePurchaseRequestStatus);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const cancel = () => {
    dispatch(updatePurchaseRequestStatus.reset());
    handleCancel();
  };

  const done = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace",
    });

    dispatch(updatePurchaseRequestStatus.reset());
    dispatch(getAdminPurchaseRequests({ accessToken }));
    handleDone();
  };

  useEffect(() => {
    if (isSuccess) {
      showAppMessage({
        severity: "success",
        message: "Successfully updated the purchase request status. Please press done.",
      });
    }
  }, [isSuccess, showAppMessage]);
  return (
    <Dialog open={open} onClose={cancel} aria-labelledby="update-status-form">
      <DialogTitle id="update-status-form">Update Purchase Request Status</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Controller
              render={({ onChange, value }) => (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id="update-status-select-label" color="secondary" required>
                    Status
                  </InputLabel>
                  <Select
                    labelId="update-status-select-label"
                    value={value}
                    label="Status"
                    onChange={onChange}
                    required
                    color="secondary"
                    error={!isNil(errors.status?.message)}
                  >
                    {map(omit(PURCHASE_REQUEST_STATUSES, "NOT_STARTED"), (value, key) => (
                      <StatusMenuItem key={key} value={value}>
                        {value.display}
                      </StatusMenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{errors.status?.message}</FormHelperText>
                </FormControl>
              )}
              control={control}
              name="status"
            />
          </Grid>
          <Grid item>
            <Controller
              render={({ onChange, value }) => (
                <TextArea
                  label="Notes"
                  rows={3}
                  error={!isNil(errors.notes?.message)}
                  helperText={errors.notes?.message}
                  onChange={onChange}
                  value={value}
                  inputProps={{
                    maxLength: 4096,
                  }}
                  placeholder={`Any notes regarding this change.`}
                  rounded={false}
                />
              )}
              control={control}
              name="notes"
            />
          </Grid>
          <Grid item>
            <PurchaseRequestStatusTagProvider>
              <DocumentUploader documents={documents} dispatch={specialDispatch} />
            </PurchaseRequestStatusTagProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box mr={2} mb={1}>
          <Button
            onClick={handleCancel}
            variant="text"
            disabled={isSubmitting || isSuccess}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          {!isSuccess && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(onSubmit)()}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Update
            </LoadingButton>
          )}
          {isSuccess && (
            <Button variant="contained" color="primary" onClick={done}>
              Done
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePurchaseRequestStatus;
