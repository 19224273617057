import { call, put, takeLatest } from "redux-saga/effects";
import { createCustomerProject } from "../actions";
import axios from "axios";
import { requestErrorMessageExtractor } from "utils/errorHelpers";
import { showAppMessage } from "components/appMessage/actions";

const callApi = async (accessToken, organizationId, customerProject) => await axios.post(
  `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/customerProjects`,
  customerProject,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* createCustomerProjectWorker(action) {
  yield put(createCustomerProject.request());

  const { accessToken, customerProject, organizationId } = action.payload;

  try {
    const response = yield call(callApi, accessToken, organizationId, customerProject);
    yield put(createCustomerProject.success({ customerProject: response.data }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Error creating project: ${message}`;
    yield put(createCustomerProject.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* createCustomerProjectWatcher() {
  yield takeLatest(createCustomerProject.TRIGGER, createCustomerProjectWorker);
}

export default [createCustomerProjectWatcher];
