import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import Address from "@components/Address";
import { find } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { mountingTypes } from "@views/site/components/details/SiteDetails";
import { getAddress } from "@utils/locationHelpers";
import { formatCapacity } from "@utils/energyHelpers";

const ProfilePanel = ({ orgAsset }) => {
  const missing = <Typography color="error.main">Missing</Typography>;

  const tableRows = [
    {
      label: "Name",
      value: orgAsset?.name || missing,
    },
    {
      label: "Address",
      value: getAddress(orgAsset.addresses, "MAIN") ? (
        <Address address={getAddress(orgAsset.addresses, "MAIN")} />
      ) : (
        missing
      ),
    },
    {
      label: "Mounting Type",
      value:
        find(mountingTypes, { value: orgAsset?.mountingType })?.label ||
        "N/A",
    },
    {
      label: "Capacity (kW)",
      value: orgAsset?.capacity
        ? formatCapacity(orgAsset?.capacity)
        : missing,
    },
    {
      label: "Installation Date",
      value: orgAsset?.completionDate
        ? DateTime.fromISO(orgAsset?.completionDate)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_FULL)
        : missing,
    },
  ];

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Profile
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ProfilePanel;
