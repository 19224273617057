import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MyApplications from "../components/MyApplications";
import { getMyApplications } from "../actions";
import { setTableState } from "@views/common/actions";
import { getDefaultTableState } from "@views/common/reducers/tableState";

const tableStateKey = "MY_APPLICATIONS";
const initialTableState = {
  sortModel: [
    {
      field: "created",
      sort: "asc",
    },
  ],
};

const mapStateToProps = ({ getMyApplications: getMyApplicationsResult, tableState }) => ({
  getMyApplicationsResult,
  applicationTableState: tableState[tableStateKey] || getDefaultTableState(initialTableState),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMyApplications,
      setApplicationTableState: (props) => setTableState({ ...props, key: tableStateKey }),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyApplications);
