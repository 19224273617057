import {
  createRoutine,
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const getCustomerProjectSites = createRoutine("GET_CUSTOMER_PROJECT_SITES");
const createSite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_CUSTOMER_PROJECT_SITE"
);
const getCustomerProjectSite = createRoutine("GET_CUSTOMER_PROJECT_SITE");

const patchCustomerProjectSite = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_CUSTOMER_PROJECT_SITE"
);

const patchDevice = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "PATCH_CUSTOMER_PROJECT_DEVICE"
);
const createDevices = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_CUSTOMER_PROJECT_DEVICE"
);

const createDocuments = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "CREATE_CUSTOMER_PROJECT_SITE_DOCUMENTS"
);
const deleteDocument = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "DELETE_CUSTOMER_PROJECT_SITE_DOCUMENT"
);


export {
  getCustomerProjectSites,
  createSite,
  getCustomerProjectSite,
  patchCustomerProjectSite,
  patchDevice,
  createDevices,
  createDocuments,
  deleteDocument,
};
