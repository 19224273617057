import getOwnedProjectReports from "./getClaimedProjectReports";
import createCertificate from "./createCertificate";
import patchCertificate from "./patchCertificate";
import deleteCertificate from "./deleteCertificate";

export default [
  ...getOwnedProjectReports,
  ...createCertificate,
  ...patchCertificate,
  ...deleteCertificate,
];
