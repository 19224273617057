import { call, put, takeLatest } from "redux-saga/effects";
import { declineApplication } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, applicationId, status, notifyApplicant, notes) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${applicationId}/decline`,
    {
      status,
      notifyOwner: notifyApplicant,
      notes,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

export function* declineApplicationWorker(action) {
  yield put(declineApplication.request());

  const {
    accessToken,
    applicationId,
    status,
    notifyApplicant,
    notes,
  } = action.payload;
  try {
    const response = yield call(
      callApi,
      accessToken,
      applicationId,
      status,
      notifyApplicant,
      notes,
    );
    yield put(declineApplication.success({ application: response.data }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Failed to decline application: ${message}`;
    yield put(declineApplication.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* declineApplicationWatcher() {
  yield takeLatest(declineApplication.TRIGGER, declineApplicationWorker);
}

export default [declineApplicationWatcher];
