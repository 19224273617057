import React from "react";
import { Box, Link, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "@components";
import UsernamePasswordPanel from "./UsernamePasswordPanel";
import AuthCodePanel from "./AuthCodePanel";
import { useDispatch } from "react-redux";
import { testConnection } from "@views/addSites/actions";

const PORTAL_URL = "https://apsystemsema.com/ema/index.action";

const ApsCredentialPanel = ({ credentials }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(testConnection.reset());
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="APS Authentication Methods"
        >
          <Tab label="Visitor Access" />
          <Tab label="Username / Password" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography gutterBottom>
          <Link href={PORTAL_URL} target="_new">
            Login to the APS portal
          </Link>{" "}
          and enable visitor access. Copy/paste the Authorized ID here.
        </Typography>
        <AuthCodePanel credentials={credentials} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography gutterBottom>
          Please enter your username and password for the{" "}
          <Link href={PORTAL_URL} target="_new">
            APS portal
          </Link>
          .
        </Typography>
        <UsernamePasswordPanel manufacturer="APS" credentials={credentials} />
      </TabPanel>
    </>
  );
};

export default ApsCredentialPanel;
