import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Applications from "../components/Applications";
import { getApplications, setApplicationTableState } from "../actions";

const mapStateToProps = ({
  getApplications: getApplicationsResult,
  applicationTableState,
}) => ({
  getApplicationsResult,
  applicationTableState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getApplications,
      setApplicationTableState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
