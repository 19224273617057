import React, { useEffect } from "react";
import GreenButton from "@components/GreenButton";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { showAppMessage } from "components/appMessage/actions";

const ConnectionTest = ({ disabled }) => {

  const dispatch = useDispatch();
  const { status, canAccessData, error } = useSelector(state => state.testDataConnectorConnection);

  const loading = status === "request";
  useEffect(() => {
    if (error) {
      dispatch(showAppMessage({ severity: "error", message: error, duration: 4000 }));
    } else if (canAccessData) {
      dispatch(showAppMessage({
        severity: "success",
        message: "Success. We can access the monitoring portal.",
        duration: 4000,
      }));
    } else if (
      !canAccessData &&
      !error &&
      status !== "init" &&
      status !== "request"
    ) {
      dispatch(showAppMessage({
        severity: "warning",
        message: "Those credentials appear invalid. Please try again.",
      }));
    }
  }, [dispatch, error, canAccessData, status]);

  return (
    <GreenButton type="submit" disabled={disabled} loading={loading}>
      Test Connection
    </GreenButton>
  );
};

ConnectionTest.propTypes = {
  disabled: PropTypes.bool,
};

export default ConnectionTest;
