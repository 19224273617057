import React, { useEffect, useState } from "react";
import { IconButton, Paper, Box, Typography, Grid, Button } from "@mui/material";
import { includes, get } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import InviteChannelPartner from "./InviteChannelPartner";
import CustomDataGrid from "@components/CustomDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { renderCellExpand } from "@components/GridCellExpand";
import Consent from "@admin/components/Consent";
import ConfirmDeleteInvite from "@views/settings/members/components/ConfirmDeleteInvite";
import {
  getChannelPartnerInvites,
  addChannelPartnerInvite,
  resendChannelPartnerInvite,
  deleteChannelPartnerInvite
} from "../actions";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth0 } from "@auth0/auth0-react";

const statusColors = {
  joined: "success.main",
  invited: "warning.main",
};

const ChannelPartnerInvites = () => {
  const dispatch = useDispatch();
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);

  const { status, invites } = useSelector((state) => state.getChannelPartnerInvites);
  useEffect(() => {
    // if we get consent_required, we'll be stuck on init
    const invitesLoading = includes(["init", "request"], status);
    setLoading(invitesLoading || isLoading);
  }, [isLoading, status]);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "created",
      sort: "desc",
    },
  ]);

  // confirm if deleting an invite
  const [open, setOpen] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const cancelAction = () => {
    setOpen(false);
  };
  const okAction = async () => {
    setOpen(false);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_sites",
    });
    dispatch(deleteChannelPartnerInvite({ accessToken, inviteId: inviteToDelete.id }));
    setInviteToDelete(null);
  };
  const confirmDeleteInvite = ({ invite }) => {
    setInviteToDelete(invite);
    setOpen(true);
  };

  // invites table
  const columns = React.useMemo(
    () => [
      {
        headerName: "Name",
        field: "inviteeName",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        headerName: "Email",
        field: "invitee",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        headerName: "Creation Date",
        field: "created",
        flex: 1,
        hide: true,
        valueFormatter: ({ value }) =>
          DateTime.fromSeconds(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Date",
        field: "date",
        flex: 0.6,
        valueGetter: ({ row: invite }) =>
          invite.dateAccepted
            ? DateTime.fromISO(invite.dateAccepted)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT)
            : DateTime.fromSeconds(invite.modified)
                .setLocale(getDefaultLocale())
                .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Invite Status",
        field: "status",
        flex: 0.8,
        renderCell: ({ value: status }) => (
          <Typography color={get(statusColors, status.toLowerCase(), "primary")}>
            {status === "ACCEPTED" ? "Joined" : "Invited"}
          </Typography>
        ),
      },
      {
        field: "resendInviteAction",
        headerName: "Resend",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const invite = params.row;
          return (
            <IconButton
              onClick={async (ev) => {
                const accessToken = await getAccessTokenSilently({
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                  scope: "admin_sites",
                });
                dispatch(resendChannelPartnerInvite({ accessToken, inviteId: invite.id }));
              }}
              aria-label="resend invite"
              size="small"
              sx={{ ml: 1 }}
              disabled={invite.status === "ACCEPTED"}
            >
              <ReplayIcon />
            </IconButton>
          );
        },
      },
      {
        field: "CancelInviteAction",
        headerName: "Cancel",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row: invite }) => {
          return (
            <IconButton
              onClick={async (ev) => {
                confirmDeleteInvite({ invite });
              }}
              aria-label="cancel invite"
              size="small"
              sx={{ ml: 1 }}
              disabled={invite.status === "ACCEPTED"}
            >
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    [getAccessTokenSilently, dispatch]
  );

  // invite channel partner action
  const [isInviteChannelPartnerOpen, setIsInviteChannelPartnerOpen] = useState(false);
  const handleOpenInviteChannelPartner = () => {
    setIsInviteChannelPartnerOpen(true);
    addChannelPartnerInvite.reset();
  };
  const handleCloseInviteChannelPartner = () => {
    setIsInviteChannelPartnerOpen(false);
  };

  return (
    <Consent action={getChannelPartnerInvites} scope="admin_sites">
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Box>
                <Typography variant="h4">
                  Invites
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleOpenInviteChannelPartner}
                >
                  Invite Channel Partner
                </Button>
              </Box>
            </Box>
            <Typography variant="h4">
              
            </Typography>
            <Paper>
              <Box height={250}>
                <CustomDataGrid
                  style={{ border: 0 }}
                  rowHeight={50}
                  loading={loading}
                  rows={invites ?? []}
                  columns={columns}
                  disableSelectionOnClick
                  sortModel={sortModel}
                  onSortModelChange={setSortModel}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                />
                <ConfirmDeleteInvite
                  open={open}
                  cancelAction={cancelAction}
                  okAction={okAction}
                  invite={inviteToDelete}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <InviteChannelPartner open={isInviteChannelPartnerOpen} onClose={handleCloseInviteChannelPartner}/>
    </Consent>
  );
};

export default ChannelPartnerInvites;
