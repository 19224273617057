import { call, put, takeLatest, select } from "redux-saga/effects";
import { getOrganizationEmissions } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getUserProfile } from "@views/common/selectors";
import { addSites } from "@views/addSites/actions";

const callApi = async (accessToken, organizationId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/emissions`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* getOrganizationEmissionsWorker(action) {
  yield put(getOrganizationEmissions.request());

  const userProfile = yield select(getUserProfile);
  const { accessToken } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      userProfile.organizationId
    );
    const { emissionReductions, cars, homes, trees } = response.data;
    yield put(getOrganizationEmissions.success({ emissionReductions, cars, homes, trees }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")(
        "Error getting organization emissions: ",
        err.response
      );
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting organization emissions: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting organization emissions: ", err);
      message = err.message;
    }
    message = `Unable to get org emissions: ${message}`;
    yield put(getOrganizationEmissions.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getOrganizationEmissionsWatcher() {
  yield takeLatest(getOrganizationEmissions.TRIGGER, getOrganizationEmissionsWorker);
}

function* getAddSitesWatcher() {
  yield takeLatest(addSites.SUCCESS, getOrganizationEmissionsWorker);
}

export default [getOrganizationEmissionsWatcher, getAddSitesWatcher];
