import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Box } from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";

const EnergSyncPanel = ({ credentials, apiUrl }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="API URL"
              variant="standard"
              fullWidth
              error={!isNil(errors.apiUrl?.message)}
              helperText={errors.apiUrl?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="https://un5m373ml5.execute-api.us-west-2.amazonaws.com/v2/"
              autoFocus
            />
          )}
          name="apiUrl"
          control={control}
          rules={{ required: "API URL is required." }}
          defaultValue={apiUrl || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="API Key"
              variant="standard"
              fullWidth
              error={!isNil(errors.apiKey?.message)}
              helperText={errors.apiKey?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="DmcpF2kRmF4xAvKUdOBAYZl6UidCdI8olAXE"
            />
          )}
          name="apiKey"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={credentials.apiKey || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Charger IDs"
              variant="standard"
              fullWidth
              error={!isNil(errors.chargerIds?.message)}
              helperText={errors.chargerIds?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="355092, 355078"
            />
          )}
          name="chargerIds"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={credentials.chargerIds || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

EnergSyncPanel.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  credentials: PropTypes.shape({
    chargerIds: PropTypes.string,
    apiKey: PropTypes.string,
  }).isRequired,
};

export default EnergSyncPanel;
