import getSiteStats from "./getSiteStats";
import getSiteCredits from "./getSiteCredits";
import getSiteEnergyToday from "./getSiteEnergyToday";
import getIndividualApplications from "./getIndividualApplications";

const reducers = {
  getSiteStats,
  getSiteCredits,
  getSiteEnergyToday,
  getIndividualApplications,
};

export default reducers;
