import React, { useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  includes,
  find,
  omitBy,
  isEmpty,
  get,
} from "lodash";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleMap from "@views/addSites/sitePicker/components/SimpleMap";
import SiteLocationForm from "@views/apply/components/SiteLocationForm";
import { Navigation } from "components";
import DialogSaveButton from "components/DialogSaveButton";
import { getAddress } from "views/addSites/actions";
import { patchCustomerProjectSite } from "../actions";
import RequiredField from "views/common/components/RequiredField";

export const schema = Yup.object().shape({
  latitude: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().optional(),
  longitude: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().optional(),
  timeZone: Yup.string().required('Time Zone is required.'),

  // SiteLocationForm is expecting this shape for it's address - however the 'main' key
  // must be flattened before submission
  addresses: Yup.object().shape({
    main: Yup.object().shape({
      street: Yup.string().max(60, "Too long.")
        .when('$allRequired', {
          is: true,
          then: (schema) => schema.required('Street is required.'),
        }),
      city: Yup.string().max(60, "Too long.")
        .when('$allRequired', {
          is: true,
          then: (schema) => schema.required('City is required.')
        }),
      region: Yup.string().max(60, "Too long.")
        .when('$allRequired', {
          is: true,
          then: (schema) => schema.required('Region is required.')
        }),
      country: Yup.string().max(60, "Too long.")
        .when('$allRequired', {
          is: true,
          then: (schema) => schema.required('Country is required.')
        }),
      postal: Yup.string().max(7, "Too long.")
        .when('$allRequired', {
          is: true,
          then: (schema) => schema.required('Postal is required.')
        }),
    }),
  }),
});

export const transformLocation = (addresses) => {
  const mainAddress = omitBy(addresses?.main, v => v === "");
  return isEmpty(mainAddress) ? [] : [{ ...mainAddress, types: ["MAIN"] }]
}

export const EditLocation = ({
  site,
  close,
  customerProject,
}) => {
  const dispatch = useDispatch();

  // setup the form
  // SiteLocationForm is looking for site.addresses.main, so if we have a main address, we need to set it here
  const mainAddress = find(site?.addresses, ({ types }) => includes(types, 'MAIN'));
  if (mainAddress) {
    site.addresses.main = mainAddress;
  };
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      timeZone: site?.timeZone || "",
      latitude: site?.latitude || "",
      longitude: site?.longitude || "",
      addresses: site?.addresses,
    }
  });

  // submit
  const { getAccessTokenSilently } = useAuth0();
  const onSubmit = async (payload) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    if(payload.addresses?.main) {
      payload.addresses = transformLocation(payload.addresses);
    }
    dispatch(patchCustomerProjectSite({
      payload,
      accessToken,
      customerProjectId: customerProject.id,
      siteId: site.id
    }));
  };
  const { status } = useSelector((state) => state.patchCustomerProjectSite);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  // n.b. pin dropped address state isn't reset by SiteLocationForm when
  // the component "unmounts" so we have to reset it here
  useEffect(() => () => {
    const cleanup = async () => {
      dispatch(getAddress.reset());
    };
    cleanup();
  }, [dispatch]);

  return (
    <>
      <DialogContent>
        <FormProvider {...methods}>
          <SiteLocationForm allRequired={false} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          handleClose={close}
          handleSubmit={() => methods.handleSubmit(onSubmit)()}
        />
      </DialogActions>
    </>
  );
};

export const AddLocation = ({ nextAction, backAction, save, siteDetails, allRequired = false }) => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    context: { allRequired },
    defaultValues: {
      timeZone: siteDetails?.timeZone || "",
      latitude: siteDetails?.latitude || "",
      longitude: siteDetails?.longitude || "",
      addresses: siteDetails?.addresses,
    }
  });
  const onSubmit = (formData) => {
    save(formData);
    nextAction();
  };

  return (
    <>
      <FormProvider {...methods}>
        <SiteLocationForm allRequired={allRequired} />
      </FormProvider>

      <Navigation
        backAction={backAction}
        nextAction={() => methods.handleSubmit(onSubmit)()}
        nextActionText="Continue"
      />
    </>
  );
};

export const ViewLocation = ({ site, reviewOnly = false, isLoading = false }) => {
  const address = site?.addresses?.main || find(site?.addresses, ({ types }) => types && includes(types, 'MAIN'));
  const latitude = get(site, 'latitude');
  const longitude = get(site, 'longitude');
  const marker = latitude && longitude ? { lat: latitude, lng: longitude } : null;
  const coords = latitude && longitude ? `${latitude.toFixed(3)}, ${longitude.toFixed(3)}` : null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <TableContainer>
          <Table aria-label="site location" size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" width="50%">
                  Street
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={address?.street} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" width="50%">
                  City
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={address?.city} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" width="50%">
                  Region
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={address?.region} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" width="50%">
                  Country
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={address?.country} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" width="50%">
                  Postal
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={address?.postal} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" width="50%">
                  Coordinates (DD)
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={coords} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" width="50%">
                  Time Zone
                </TableCell>
                <TableCell>
                  {isLoading ? <Skeleton variant="text" /> : <RequiredField reviewOnly={reviewOnly} value={site?.timeZone} />}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item lg={6} xs={12}>
        <SimpleMap
          marker={marker}
          setMakrer={() => {}}
          readOnly
          mapContainerStyle = {{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};

