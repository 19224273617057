import React from "react";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  Typography,
  Grid,
  TextField,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const DataConnector = 'energSync';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="API URL"
              variant="standard"
              fullWidth
              error={!isNil(errors.apiUrl?.message)}
              helperText={errors.apiUrl?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="https://un5m373ml5.execute-api.us-west-2.amazonaws.com/v2/"
              autoFocus
            />
          )}
          name="apiUrl"
          control={control}
          rules={{ required: "API URL is required." }}
          defaultValue={dataConnectorParams?.apiUrl || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="API Key"
              variant="standard"
              fullWidth
              error={!isNil(errors.apiKey?.message)}
              helperText={errors.apiKey?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="DmcpF2kRmF4xAvKUdOBAYZl6UidCdI8olAXE"
            />
          )}
          name="apiKey"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={dataConnectorParams?.apiKey || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              label="Charger IDs"
              variant="standard"
              fullWidth
              error={!isNil(errors.chargerIds?.message)}
              helperText={errors.chargerIds?.message || " "}
              required
              onChange={onChange}
              value={value}
              placeholder="355092, 355078"
            />
          )}
          name="chargerIds"
          control={control}
          rules={{ required: "Charger IDs are required." }}
          defaultValue={dataConnectorParams?.chargerIds || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        If your charger manufacturer has enabled EnergSync, you can use this form
        to connect.
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="EnergSync API root URL"
            secondary="Don't forget the trailing slash. eg. https://energsync.rewatt.amazonaws.com/v2/"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Get an API Key"
            secondary="You need to provide an API key to access the EnergSync endpoints"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Test the connection"
            secondary="Input the serial numbers/charger ID(s) you want connected in the field below. Separate each charger ID with a comma if you have more than one."
          />
        </ListItem>
      </List>
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;