import getSite from "./getSite";
import getSiteChart from "./getSiteChart";
import getSiteAvailability from "./getSiteAvailability";
import getFirstReportDate from "./getFirstReportDate";
import patchSiteDetails from "./patchSiteDetails";
import patchSiteLocation from "./patchSiteLocation";
import patchSiteDocuments from "./patchSiteDocuments";
import publishLiveSite from "./publishLiveSite";
import getSiteIncidents from "./getSiteIncidents";
import getIncidentLog from "./getIncidentLog";
import subscribeToSiteNotifications from "./subscribeToSiteNotifications";
import unsubscribeFromSiteNotifications from "./unsubscribeFromSiteNotifications";
import getSubscriptions from "./getSubscriptions";

export default [
  ...getSite,
  ...getSiteChart,
  ...getSiteAvailability,
  ...getFirstReportDate,
  ...patchSiteDetails,
  ...patchSiteLocation,
  ...patchSiteDocuments,
  ...publishLiveSite,
  ...getSiteIncidents,
  ...getIncidentLog,
  ...subscribeToSiteNotifications,
  ...unsubscribeFromSiteNotifications,
  ...getSubscriptions,
];
