import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Link, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import CustomerProjectEditor from "./CustomerProjectEditor";
import EditIcon from "@mui/icons-material/Edit";
import { EMISSION_REDUCTION_ACTIVITIES } from "@views/program/components/Program";
import Image from "@jy95/material-ui-image";
import { getFullName } from "@utils/stringHelpers";
import { downloadFile } from "@utils/fileHelpers";
import AcrobatLogo from "@components/AcrobatLogo";
import { useAppMessage } from "@components/appMessage";
import HeroIcon from "components/HeroIcon";
import { LinkIcon } from "@heroicons/react/solid";
import CopyToClipboard from "react-copy-to-clipboard";
import { getDims } from "@utils/imageHelpers";
import { FileCopy } from "@mui/icons-material";

export const getURL = (customerProject, referrerId) => referrerId
  ? `${process.env.REACT_APP_PUBLIC_URL}/projects/apply/${customerProject?.id}?referrer=${referrerId}`
  : `${process.env.REACT_APP_PUBLIC_URL}/projects/apply/${customerProject?.id}`;

const ApplicationLink = ({ open, handleClose, customerProject }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="Copy Application Link"
    aria-describedby="Copy the application link to this proejct for a website or for emails"
  >
    <DialogTitle id="alert-dialog-title">Share Application Link</DialogTitle>
    <DialogContent>
      <DialogContentText variant="body1">
        You can enable external stakeholders, like your customers, to participate in your project.
        Stakeholders can use the link below to submit applications to join your project.
        Copy the link and share it in emails, websites, DMs, and more.
      </DialogContentText>
      <TextField
        variant="standard"
        value={getURL(customerProject)}
        autoFocus
        margin="dense"
        label="Application Link"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mb: 1 }}>
              <CopyToClipboard text={getURL(customerProject)}>
                <Tooltip title="Copy link to clipboard">
                  <IconButton size="large">
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
          inputProps: { readOnly: true },
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Done
      </Button>
    </DialogActions>
  </Dialog>
);

const PublicLayout = ({ customerProject, setOpenAppLink, downloadTerms }) => {
  const [aspectRatio, setAspectRatio] = useState(1);
  const imgBoxDim = 200 - 2 * 8;
  return (
    <Grid container rowSpacing={3} columnSpacing={1}>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="stretch"
          alignItems="center"
        >
          {/* position image in center, fix it */}
          <Box
            sx={{
              flex: "0 0 200px",
              boxShadow: "3px 3px 8px 2px rgb(0 0 0 / 15%)",
              padding: 1,
              background: "common.white",
            }}
          >
            <Image
              src={customerProject.logo?.uri}
              imageStyle={getDims(aspectRatio, imgBoxDim)}
              onLoad={(e) => {
                if (e?.target) {
                  setAspectRatio(e.target.naturalWidth / e.target.naturalHeight);
                }
              }}
            />
          </Box>
          {/* title */}
          <Box flex={1} ml={5}>
            <Typography color="textSecondary" gutterBottom>
              Project Summary
            </Typography>
            <Paper
              sx={{ p: 2, height: imgBoxDim, overflowY: "auto" }}
              variant="outlined"
            >
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {customerProject?.description}
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4} md={3}>
        <Typography color="textSecondary">Emission Reduction Activity</Typography>
      </Grid>
      <Grid item xs={8} md={3}>
        <Typography>{EMISSION_REDUCTION_ACTIVITIES[customerProject?.emissionReductionActivity]}</Typography>
      </Grid>
      <Grid item xs={4} md={3}>
        <Typography color="textSecondary">Is Public</Typography>
      </Grid>
      <Grid item xs={8} md={3}>
        <Box display="flex" alignItems="center">
          <Typography>Yes</Typography>
          <IconButton
            onClick={() => {
              setOpenAppLink(true);
            }}
            aria-label="view"
            size="small"
            sx={{ ml: 1, p: 0 }}
          >
            <HeroIcon icon={LinkIcon} />
          </IconButton>
        </Box>
      </Grid>
      {customerProject?.manager && (
        <>
          <Grid item xs={4} md={3}>
            <Typography color="textSecondary">Manager</Typography>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography>
              {getFullName(customerProject.manager.familyName, customerProject.manager.givenName)}
            </Typography>
          </Grid>
        </>
      )}
      {customerProject?.termsAndConditions && (
        <>
          <Grid item xs={4} md={3}>
            <Typography color="textSecondary">Terms and Conditions</Typography>
          </Grid>

          <Grid item xs={8} md={3}>
            <Link
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                downloadTerms(customerProject.termsAndConditions);
              }}
            >
              <Typography>
                <AcrobatLogo />
                {customerProject.termsAndConditions.name}
              </Typography>
            </Link>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const PrivateLayout = ({ customerProject }) => (
  <Grid container rowSpacing={3} columnSpacing={1}>
    <Grid item xs={12}>
      <Typography color="textSecondary" gutterBottom>
        Project Summary
      </Typography>
      {customerProject?.description && (
        <Paper
          sx={{ p: 2, overflowY: "auto", maxHeight: 200 }}
          variant="outlined"
        >
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
            {customerProject.description}
          </Typography>
        </Paper>
      )}
    </Grid>
    <Grid item xs={4} md={3}>
      <Typography color="textSecondary">Emission Reduction Activity</Typography>
    </Grid>
    <Grid item xs={8} md={3}>
      <Typography>{EMISSION_REDUCTION_ACTIVITIES[customerProject?.emissionReductionActivity]}</Typography>
    </Grid>
    <Grid item xs={4} md={3}>
      <Typography color="textSecondary">Is Public</Typography>
    </Grid>
    <Grid item xs={8} md={3}>
      <Box display="flex" alignItems="center">
        <Typography>No</Typography>
      </Box>
    </Grid>
  </Grid>
);

const CustomerProjectDetails = ({ customerProject, title }) => {
  // edit customer project
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleOpenEditor = () => {
    setIsEditorOpen(true);
  };
  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  const showAppMessage = useAppMessage();
  const downloadTerms = async (pdf) => {
    try {
      await downloadFile(pdf);
    } catch (err) {
      showAppMessage({
        severity: "error",
        message: `Sorry, there was a problem downloading the file: ${err}`,
      });
    }
  };

  const [openAppLink, setOpenAppLink] = useState(false);
  return (
    <Paper sx={{ p: 3 , pt: 1 }}>
      <Box display="flex" alignItems="center" py={1}>
        {title && <Typography variant="h5">{title}</Typography>}
        <Box flexGrow={1} />
        <Tooltip
          title="Edit project details"
        >
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={handleOpenEditor}
            size="large"
            sx={{ py: 0 }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {customerProject && customerProject.isPublic ? (
        <PublicLayout
          customerProject={customerProject}
          downloadTerms={downloadTerms}
          setOpenAppLink={setOpenAppLink}
        />
      ) : (
        <PrivateLayout customerProject={customerProject}/>
      )}
      <ApplicationLink
        open={openAppLink}
        handleClose={() => setOpenAppLink(false)}
        customerProject={customerProject}
      />
      {customerProject && (
        <CustomerProjectEditor
          open={isEditorOpen}
          onClose={handleCloseEditor}
          customerProject={customerProject}
        />
      )}
    </Paper>
  );
};

export default CustomerProjectDetails;
