import getFleetStats from "./getFleetStats";
import getOnboardingChart from "./getOnboardingChart";
import getDataConnectorHealth from "./getDataConnectorHealth";
import getSitesByAdmin from "./getSitesByAdmin";
import addAssetInvite from "./addAssetInvite";

export default [
  ...getFleetStats,
  ...getOnboardingChart,
  ...getDataConnectorHealth,
  ...getSitesByAdmin,
  ...addAssetInvite,
];
