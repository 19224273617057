import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from "luxon";
import { getProjectReportCalculation } from "@views/admin/project/actions";
import { useSelector, useDispatch } from "react-redux";
import SiteContributions from "./SiteContributions";

const CalculationByDateRange = ({ project, startDate, endDate }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // luxon dates do not compare properly in dependency array
  const startMillis = startDate.toMillis();
  const endMillis = endDate?.toMillis();
  useEffect(() => {
    async function calculate() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_project_reports",
      });
      dispatch(
        getProjectReportCalculation({
          accessToken,
          projectId: project.id,
          startDate: DateTime.fromMillis(startMillis),
          endDate: DateTime.fromMillis(endMillis),
        })
      );
    }
    if (startMillis > 0 && endMillis > 0) {
      calculate();
    }
  }, [startMillis, endMillis, project.id, getAccessTokenSilently, dispatch]);

  const { status } = useSelector((state) => state.getProjectReportCalculation);
  const isLoading = status === "request";
  const showCalculations = startMillis > 0 && endMillis > 0;

  return showCalculations ? <SiteContributions project={project} isLoading={isLoading} /> : null;
};

CalculationByDateRange.propTypes = {
  project: PropTypes.shape({}).isRequired,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  endDate: PropTypes.instanceOf(DateTime).isRequired,
};

export default CalculationByDateRange;
