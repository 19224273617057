/**
 * Base class for showing terms and conditions
 */

import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  Link,
  Button,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { Document as PdfDocument, Page as PdfPage, pdfjs } from "react-pdf";
import { Skeleton } from "@mui/material";
import NextIcon from "@mui/icons-material/ArrowForwardIos";
import { useAuth0 } from "@auth0/auth0-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAppMessage } from "@appMessage/actions";
import { downloadFile } from "@utils/fileHelpers";
import Pagination from "@mui/material/Pagination";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import { isNull, isNil } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import AcrobatLogo from "@components/AcrobatLogo";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TermsAndConditions = ({
  backAction,
  acceptTermsAndConditions,
  isSubmitting,
  submitActionText,
  pdf,
  agreementLabelText,
  showError,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // figure out size
  const pdfRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    const resizeHandler = () => {
      setDimensions({
        width: pdfRef.current.offsetWidth,
        height: pdfRef.current.offsetHeight,
      });
    };

    if (pdfRef.current) {
      resizeHandler();
    }
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(pdf);

  useEffect(() => {
    if (!isNil(pdf) && pdf?.url !== file?.url) {
      setFile(pdf);
    }
  }, [pdf, file]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const downloadTerms = async (pdf) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      await downloadFile(pdf, accessToken);
    } catch (err) {
      showError(`Sorry, there was a problem downloading the file: ${err}`);
    }
  };

  return (
    <Box my={2} ref={pdfRef}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <PdfDocument
            file={file}
            loading={
              <React.Fragment>
                <Skeleton variant="text" width="60%" height={50} />
                <Skeleton variant="text" width="100%" height={150} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="80%" height={30} />
              </React.Fragment>
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <PdfPage pageNumber={pageNumber} width={dimensions.width} />
          </PdfDocument>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" mt={2}>
            <Pagination count={numPages} page={pageNumber} onChange={handlePageChange} />
          </Box>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="space-between">
            {backAction && (
              <>
                <Button
                  variant="contained"
                  onClick={backAction}
                  disabled={isNull(backAction)}
                  startIcon={<BackIcon style={{ fontSize: 14 }} />}
                  sx={{ position: "relative", maxHeight: 36 }}
                >
                  Back
                </Button>
                <Box flexGrow={1} />
              </>
            )}

            {/* Agree and download */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems={backAction ? "center" : "flex-start"}
              mt={-1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="acceptTermsAndConditions"
                    checked={acceptedTermsAndConditions}
                    onChange={(e) => setAcceptedTermsAndConditions(e.target.checked)}
                  />
                }
                label={agreementLabelText}
              />
              <Link
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  downloadTerms(pdf);
                }}
              >
                <Typography>
                  <AcrobatLogo />
                  Download PDF
                </Typography>
              </Link>
            </Box>

            {/* Spacing */}
            <Box flexGrow={1} />

            {/* Submit */}
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={acceptTermsAndConditions}
              disabled={isSubmitting || !acceptedTermsAndConditions}
              endIcon={<NextIcon style={{ fontSize: 14 }} />}
              loading={isSubmitting}
              sx={{ position: "relative", maxHeight: 36 }}
            >
              {submitActionText}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

TermsAndConditions.defaultProps = {
  submitActionText: "Submit",
  pdf: null, // ie. loading
  agreementLabelText: "I have read Rewatt Power's data sharing & privacy policy.",
};

TermsAndConditions.propTypes = {
  backAction: PropTypes.func,
  acceptTermsAndConditions: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitActionText: PropTypes.string,
  agreementLabelText: PropTypes.string,

  // you should import the pdf and use that as the url prop or
  // provide a custom request object (see react-pdf) eg. with access token
  pdf: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  }),
};

// connect to redux

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showError: (message) =>
        showAppMessage({
          severity: "error",
          message,
        }),
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TermsAndConditions);
