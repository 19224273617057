import React, { useState } from "react";
import { Grid, Paper, Box, TextField } from "@mui/material";
import { isNil } from "lodash";
import { getDefaultLocale } from "@utils/dateHelpers";
import { DateTime } from "luxon";
import CustomDataGrid from "@components/CustomDataGrid";
import { useAuth0 } from "@auth0/auth0-react";

const IncidentExplorer = ({ incidents, loading, getIncidentLog, getIncidentLogResult }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sortModel, setSortModel] = useState([
    {
      field: "endDate",
      sort: "desc",
    },
  ]);

  // column definitions
  const columns = React.useMemo(
    () => [
      {
        headerName: "Incident ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Start Date",
        field: "startDate",
        flex: 1,
        valueFormatter: ({ value }) =>
          DateTime.fromISO(value).setLocale(getDefaultLocale()).toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "End Date",
        field: "endDate",
        flex: 1,
        renderCell: (params) => {
          const { endDate } = params.row;
          if (isNil(endDate)) {
            return <Box color="error.dark">OPEN</Box>;
          }
          return DateTime.fromISO(endDate)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT);
        },
        sortComparator: (a, b) => (a === null || a > b ? 1 : b === null || a < b ? -1 : 0),
      },
    ],
    []
  );

  const fetchLog = async (params) => {
    const { id: incidentId } = params;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    getIncidentLog({ accessToken, incidentId });
  };

  const { logEntries, status } = getIncidentLogResult;
  const log =
    logEntries.map((l) => `${l.date} ${l.meterId} ${l.message}`).join("\n") || "No log entries";
  const value =
    status === "init"
      ? "Select an incident to view any associated device logs. Please be patient, loading could take a moment."
      : status === "request"
      ? "Loading..."
      : log;

  return (
    <Paper sx={{ p: 4 }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={5}>
          <div style={{ height: "250px", width: "100%" }}>
            <CustomDataGrid
              style={{ border: 0 }}
              rowHeight={50}
              loading={loading}
              rows={incidents}
              columns={columns}
              disableColumnSelector
              localeText={{
                noRowsLabel: "No incidents.",
              }}
              disableMultipleSelection
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              onRowClick={fetchLog}
              pagination={false}
              components={{
                Footer: () => null,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box ml={3}>
            <TextField
              id="device-log"
              label="Device Log"
              multiline
              minRows={9}
              variant="filled"
              placeholder="Choose an incident. Please be patient, loading could take a moment."
              fullWidth
              inputProps={{ readOnly: true }}
              value={value}
              InputProps={{ disableUnderline: true }}
              sx={{
                "& .MuiFilledInput-root": {
                  borderRadius: 0.5,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IncidentExplorer;
