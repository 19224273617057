import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import { ConnectionTest } from "@addSites/manufacturer";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";

const SmaPermissionRequestPanel = ({
  credentials,
  requestSmaPermission,
  smaPermissionResult,
}) => {
  const [systemOwner, setSystemOwner] = useState('');

  const { errors, control } = useFormContext();
  const { getAccessTokenSilently } = useAuth0();

  const onRequestPermission = () => {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }).then((accessToken) => {
      requestSmaPermission({ email: systemOwner, accessToken });
    });
  };

  const isLoading = smaPermissionResult.status === "request";
  const isSuccess = smaPermissionResult.status === "success";

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label="System Owner Email"
          variant="standard"
          fullWidth
          placeholder="joe@company.com"
          autoFocus
          value={systemOwner}
          onChange={e => setSystemOwner(e.target.value)}
          helperText="Email address of the system owner."
        />
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onRequestPermission}
          disabled={isSuccess}
          loading={isLoading}
          sx={{ mb: 5 }}
        >
          Request Permission
        </LoadingButton>
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="System ID"
              variant="standard"
              fullWidth
              placeholder="897459874"
              autoFocus
              error={!isNil(errors.plantId?.message)}
              helperText={
                errors.plantId?.message ||
                "ID of the system/plant you want to onboard"
              }
            />
          }
          name="plantId"
          control={control}
          rules={{ required: "System ID is required" }}
          defaultValue={credentials.plantId}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

SmaPermissionRequestPanel.propTypes = {
  credentials: PropTypes.shape({
    plantId: PropTypes.string,
  }).isRequired,
  smaPermissionResult: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.any,
  }),
  requestSmaPermission: PropTypes.func.isRequired,
};

export default SmaPermissionRequestPanel;
