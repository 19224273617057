import getProject from "./getProject";
import getProjectSites from "./getProjectSites";
import getProjectReports from "./getProjectReports";
import getProjectCredits from "./getProjectCredits";
import getProjectStats from "./getProjectStats";
import getProjectChart from "./getProjectChart";
import createProjectReport from "./createProjectReport";
import getProjectReportCalculation from "./getProjectReportCalculation";
import patchProject from "./patchProject";
import setProjectStatus from "./setProjectStatus";
import addProjectDocuments from "./addProjectDocuments";
import deleteProjectDocument from "./deleteProjectDocument";
import patchSitePayee from "./patchSitePayee";
import addPayeeInvite from "./addPayeeInvite";
import getPayeeInvites from "./getPayeeInvites";
import removeSiteFromProject from "./removeSiteFromProject";

export default [
  ...getProject,
  ...getProjectSites,
  ...getProjectReports,
  ...getProjectCredits,
  ...getProjectChart,
  ...createProjectReport,
  ...getProjectReportCalculation,
  ...getProjectStats,
  ...patchProject,
  ...setProjectStatus,
  ...addProjectDocuments,
  ...deleteProjectDocument,
  ...patchSitePayee,
  ...addPayeeInvite,
  ...getPayeeInvites,
  ...removeSiteFromProject,
];
