import axios from "axios";
import mime from 'mime-types';
import byteSize from "byte-size";

/**
 * Downloads the file represented by doc, with bearer auth if provided.
 *
 * @param  object doc         must have a url/uri and name or file.path prop
 * @param  string accessToken auth0 access token
 * @return void
 */
const downloadFile = async (doc, accessToken) => {
  const response = await axios({
    url: doc.url || doc.uri,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  });
  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  const fname = doc.name || doc.file.path;
  const hasExtension = mime.lookup(fname);
  const filename = hasExtension ? fname : `${fname}.${mime.extension(doc.mediaType)}`;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

// maxFileSize in bytes
const fileSizeValidator = (maxFileSize) => (file) => {
  if (file.size > maxFileSize) {
    return {
      code: "file-too-large",
      message: `Please select a file smaller than ${byteSize(maxFileSize, {
        precision: 0,
      })}`,
    };
  }

  return null;
};


export { downloadFile, fileSizeValidator };
