import React, { useCallback, useReducer, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Review from "./Review";
import { useDispatch, useSelector } from "react-redux";
import { createSite } from "../actions";
import { AddDevices } from "../../Devices/components/Devices";
import { AddLocation } from "./Location";
import { AddDetails } from "./Details";
import { AddDocuments } from "./Documents";
import DialogTransition from "@components/DialogTransition";
import { getAddress } from "views/addSites/actions";

const addSiteFormReducer = (form, action) => {
  switch (action.type) {
    case "save": {
      return {
        ...form,
        ...action.payload,
      };
    }
    case "reset": {
      return {};
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

const AddSiteForm = ({ customerProject }) => {
  // track which component to display
  const [activeStep, setActiveStep] = useState(0);
  const [siteDetails, modifyForm] = useReducer(addSiteFormReducer, null);
  const save = (siteDetails) => modifyForm({ type: "save", payload: siteDetails });

  const { meters } = useSelector((state) => state.addDevicesForm);
  const saveDevices = useCallback(() => {
    save({ meters });
  }, [meters]);

  const dispatch = useDispatch();
  const addAnother = () => {
    modifyForm({ type: "reset" });
    setActiveStep(0);
    dispatch(createSite.reset());
    // n.b. pin dropped address state isn't reset by SiteLocationForm when
    // the component "unmounts" so we have to reset it here
    dispatch(getAddress.reset());
  };

  return (
    <Container maxWidth="lg" sx={{ p: 4 }}>
      {activeStep === 0 && (
        <AddDetails
          nextAction={() => {
            setActiveStep(1);
          }}
          customerProject={customerProject}
          siteDetails={siteDetails}
          save={save}
        />
      )}
      {activeStep === 1 && (
        <AddLocation
          nextAction={() => {
            setActiveStep(2);
          }}
          backAction={() => {
            setActiveStep(0);
          }}
          customerProject={customerProject}
          siteDetails={siteDetails}
          save={save}
        />
      )}
      {activeStep === 2 && (
        <AddDocuments
          nextAction={() => {
            setActiveStep(3);
          }}
          backAction={() => {
            setActiveStep(1);
          }}
          save={save}
          siteDetails={siteDetails}
          customerProject={customerProject}
        />
      )}
      {activeStep === 3 && (
        <AddDevices
          customerProject={customerProject}  
          handleFinish={() => {
            saveDevices();
            setActiveStep(4);
          }}
          handleBack={() => {
            setActiveStep(2);
          }}
          skipAction={() => {
            setActiveStep(4);
          }}
        >
          <Typography>
            Add new systems, devices, and/or meters to your existing site. Click "Continue" below to get started.
          </Typography>
        </AddDevices>
      )}
      {activeStep === 4 && (
        <Review
          addAnother={addAnother}
          backAction={() => {
            setActiveStep(3);
          }}
          customerProject={customerProject}
          siteDetails={siteDetails}
          save={save}
        />
      )}
    </Container>
  );
};

const AddSiteEditor = ({ open, onClose, customerProject }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(createSite.reset());
    // n.b. pin dropped address state isn't reset by SiteLocationForm when
    // the component "unmounts" so we have to reset it here
    dispatch(getAddress.reset());
    onClose();
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={DialogTransition}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          bgcolor: "background.light",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Add Sites
          </Typography>
        </Toolbar>
      </AppBar>
      <AddSiteForm customerProject={customerProject} />
    </Dialog>
  );
};

export default AddSiteEditor;
