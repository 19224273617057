import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, CircularProgress } from "@mui/material";

/**
 * A full page loading animation, centered vertically and horizontally, with a message.
 * @param  {[type]} options.message [description]
 */
const PageLoader = ({ message, sx = {} }) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: "calc(100vh - 64px)", ...sx }}
  >
    <Box alignItems="center" display="flex" flexDirection="column">
      <CircularProgress />
      <Box m={2}>
        <Typography>{message}</Typography>
      </Box>
    </Box>
  </Box>
);

PageLoader.defaultProps = {
  message: "Loading...",
};

PageLoader.propTypes = {
  message: PropTypes.string,
};

export default PageLoader;
