import { call, put, takeLatest } from "redux-saga/effects";
import { getIndividualApplications } from "@views/individual/dashboard/actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken) => {
  return await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
};

function* getIndividualApplicationsWorker(action) {
  try {
    yield put(getIndividualApplications.request());
    const { accessToken } = action.payload;
    const response = yield call(callApi, accessToken);
    yield put(
      getIndividualApplications.success({ applications: response.data })
    );
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Unable to get applications: ${message}`;
    yield put(getIndividualApplications.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* getIndividualApplicationsWatcher() {
  yield takeLatest(
    getIndividualApplications.TRIGGER,
    getIndividualApplicationsWorker
  );
}

export default [getIndividualApplicationsWatcher];
