import getOrgAsset from "./getOrgAsset";
import patchOrgAssetDocuments from "./patchOrgAssetDocuments";
import patchActivityStartDate from "./patchActivityStartDate";
import getGenerationChart from "./getGenerationChart";

const reducers = {
  getOrgAsset,
  patchOrgAssetDocuments,
  patchActivityStartDate,
  getGenerationChart,
};

export default reducers;
