import React from "react";
import { Typography, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import CalculationByDateRange from "./CalculationByDateRange";
import { isNil } from "lodash";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReportingSheetTemplateUploader from "./ReportingSheetTemplateUploader";

const ByDateRange = ({ project, startDate, endDate }) => {
  const { errors, control } = useFormContext();
  const isAlbertaEmissionsOffset = project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";
  return (
    <>
      <Typography variant="h2">Choose a date range to report.</Typography>
      <Typography mt={1}>
        If this is your first project report, the start date is the day the project started.
      </Typography>
      <Typography variant="h4" mt={3} color="secondary.main">
        Created a report already?
      </Typography>
      <Typography my={1}>
        The start date is the day after the end of your most recent report.
      </Typography>
      <form noValidate autoComplete="off">
        <Controller
          render={({ onChange, value }) => (
            <input type="hidden" value={value} onChange={onChange} />
          )}
          control={control}
          name="calculateByDateRange"
        />
        <Grid container spacing={3} sx={{ maxWidth: 600 }}>
          <Grid item xs={12} md={6}>
            <Controller
              render={({ onChange, value }) => (
                <DatePicker
                  label="Start Date"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      fullWidth
                      {...params}
                      error={!isNil(errors.startDate?.message)}
                      helperText={errors.startDate?.message || "Read Only"}
                    />
                  )}
                  readOnly
                  disableMaskedInput
                />
              )}
              control={control}
              name="startDate"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              render={({ onChange, value }) => (
                <DatePicker
                  label="End Date"
                  value={value}
                  onChange={onChange}
                  maxDate={DateTime.now().minus({ days: 2 })}
                  showDaysOutsideCurrentMonth
                  defaultCalendarMonth={DateTime.now().minus({ days: 2 })}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      fullWidth
                      {...params}
                      error={!isNil(errors.endDate?.message)}
                      helperText={errors.endDate?.message || "Last date (inclusive) to report"}
                    />
                  )}
                  disableMaskedInput
                />
              )}
              control={control}
              name="endDate"
            />
          </Grid>
          {isAlbertaEmissionsOffset && (
            <Grid item xs={12}>
              <Controller
                render={({ onChange, value }) => (
                  <ReportingSheetTemplateUploader
                    onChange={onChange}
                    value={value}
                    error={errors.aggregatedReportingSheetTemplate?.message}
                  />
                )}
                control={control}
                name="aggregatedReportingSheetTemplate"
              />
            </Grid>
          )}
        </Grid>
      </form>
      <CalculationByDateRange
        project={project}
        startDate={DateTime.fromISO(startDate)}
        endDate={DateTime.fromISO(endDate)}
      />
    </>
  );
};

export default ByDateRange;
