import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Autocomplete,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { isNil, omit } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { addBuyerInvite, getOrganizations, getBuyerInvites } from "../../actions";
import { useAppMessage } from "@components/appMessage";

const schema = yup.object().shape({
  givenName: yup.string().max(60, "Too long.").required("First name is required."),
  familyName: yup.string().max(60, "Too long.").required("Last name is required."),
  email: yup.string().email().required("Email is required."),
  notes: yup.string().max(255, "Too long."),
});

const InviteBuyerEditor = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const showAppMessage = useAppMessage();
  const { getAccessTokenSilently } = useAuth0();

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      givenName: "",
      familyName: "",
      email: "",
      notes: "",
      organization: "",
    },
  });

  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace",
    });

    const invite = {
      ...omit(data, "organization"),
      organizationId: data.organization?.id,
    };

    dispatch(addBuyerInvite({ accessToken, invite }));
  };

  const { status } = useSelector((state) => state.addBuyerInvite);

  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  const handleDone = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_marketplace",
    });

    dispatch(addBuyerInvite.reset());
    dispatch(getBuyerInvites({ accessToken }))
    onClose();
  };

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_marketplace",
      });
      dispatch(getOrganizations({ accessToken }));
    }
    init();
  }, [dispatch, getAccessTokenSilently]);

  const { organizations } = useSelector((state) => state.getOrganizations);

  useEffect(() => {
    if (isSuccess) {
      showAppMessage({
        severity: "success",
        message: "Successfully invited buyer. Please press done.",
      });
    }
  }, [isSuccess, showAppMessage]);

  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="add-invite-form">
      <DialogTitle id="add-invite-form">Invite a Buyer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Invite a buyer to trade on the Rewatt platform. Optionally, specify an organization for
          them to join. We'll send an email with further instructions, on your behalf.
        </DialogContentText>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              autoFocus
              error={!isNil(errors.givenName?.message)}
              label="First Name"
              placeholder="John"
              helperText={errors.givenName?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="givenName"
        />
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.familyName?.message)}
              label="Last Name"
              placeholder="Doe"
              helperText={errors.familyName?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="familyName"
        />
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.email?.message)}
              label="Email"
              placeholder="john@company.org"
              helperText={errors.email?.message}
              margin="dense"
              required
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="email"
        />
        <Controller
          render={({ onChange, value }) => (
            <TextField
              variant="standard"
              fullWidth
              error={!isNil(errors.notes?.message)}
              label="Notes"
              placeholder="Hi John, please accept my invitation to trade with Rewatt."
              helperText="A short note to the invitee (optional)"
              margin="dense"
              onChange={onChange}
              value={value}
              color="secondary"
            />
          )}
          control={control}
          name="notes"
        />
        <Controller
          render={({ onChange, value }) => (
            <Autocomplete
              getOptionLabel={(org) => org.name}
              options={organizations.sort((orgA, orgB) => -orgB.name.localeCompare(orgA.name))}
              autoComplete
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  variant="standard"
                  fullWidth
                  error={!isNil(errors.organization?.message)}
                  helperText={
                    errors.organization?.message || "Invite to an existing organization (optional)"
                  }
                  margin="dense"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  value={value}
                  color="secondary"
                />
              )}
            />
          )}
          control={control}
          name="organization"
        />
      </DialogContent>
      <DialogActions>
        <Box mr={2} mb={1}>
          <Button
            onClick={handleCancel}
            variant="text"
            disabled={isSubmitting || isSuccess}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          {!isSuccess && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(onSubmit)()}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Invite
            </LoadingButton>
          )}
          {isSuccess && (
            <Button variant="contained" color="primary" onClick={handleDone}>
              Done
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InviteBuyerEditor;
