import getProjectReport from "./getProjectReport";
import addProjectReportDocuments from "./addProjectReportDocuments";
import deleteProjectReportDocument from "./deleteProjectReportDocument";
import setProjectReportStatus from "./setProjectReportStatus";
import patchProjectReport from "./patchProjectReport";
import uploadVerifierLogo from "./uploadVerifierLogo";
import uploadCorporateLogo from "./uploadCorporateLogo";

const reducers = {
  getProjectReport,
  addProjectReportDocuments,
  deleteProjectReportDocument,
  setProjectReportStatus,
  patchProjectReport,
  uploadVerifierLogo,
  uploadCorporateLogo,
};

export default reducers;
