import React, { useMemo, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { DataGridPro, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { every, first, isObject, map } from "lodash";
import { getAddress } from "@utils/locationHelpers";
import { formatCapacity } from "@utils/energyHelpers";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useDispatch } from "react-redux";
import AddAssetInviteEditor from "./AddAssetInviteEditor";
import { addAssetInvite } from "../actions";
import { getFullName } from "@utils/stringHelpers";
import { EmailLink, LinkTooltip, RelativeLink } from "@components";
import ViewIcon from "@mui/icons-material/Visibility";
import { getOrgAsset } from "@views/admin/orgAsset/actions";
import { useNavigate } from "react-router-dom";

const CustomToolbar = ({ action }) => (
  <GridToolbarContainer>
    <Button
      variant="text"
      color="secondary"
      size="small"
      startIcon={<PersonAddIcon />}
      onClick={action}
    >
      Invite an owner to claim
    </Button>
  </GridToolbarContainer>
);

const extractSiteOwner = (siteOwner) => {
  if (!siteOwner) return "Missing";
  const isOrg = !!siteOwner?.organization?.name;
  const contact = isOrg
    ? siteOwner.organization.primaryContact
    : siteOwner.external || siteOwner.individual;
  if (contact?.email === "N/A") {
    return "Auth0 Fail";
  }

  return {
    ownerName: isOrg ? siteOwner.organization.name : getFullName(contact),
    contactName: getFullName(contact),
    email: contact.email,
  };
};

const OrgAssetsDetailPanel = ({ site }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orgAssets = map(site.organizationAssets, (orgAsset) => {
    const { city, region } = getAddress(orgAsset.addresses, "MAIN") || { city: undefined, region: undefined };
    return {
      ...orgAsset,
      location: `${city}, ${region}`,
    };
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    name: !every(orgAssets, (oa) => oa.name === first(orgAssets).name),
    capacity: !every(
      orgAssets,
      (oa) => oa.capacity === first(orgAssets).capacity
    ),
    location: !every(
      orgAssets,
      (oa) => oa.location === first(orgAssets).location
    ),
  });

  // create invite action
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleOpenEditor = () => {
    setIsEditorOpen(true);
    dispatch(addAssetInvite.reset());
  };
  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Site ID", flex: 0.5 },
      {
        headerName: "Created",
        field: "created",
        flex: 0.8,
        valueFormatter: ({ value }) =>
          DateTime.fromSeconds(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT),
      },
      { field: "name", headerName: "Name", flex: 1 },
      {
        field: "organization",
        headerName: "Organization",
        flex: 1,
        valueGetter: ({ value }) => value?.name || "N/A",
      },
      { headerName: "Location", field: "location", flex: 1 },
      {
        headerName: "Capacity (kW)",
        field: "capacity",
        flex: 0.8,
        valueFormatter: ({ value }) => formatCapacity(value),
      },
      {
        field: "siteOwner",
        headerName: "Owner",
        flex: 1,
        renderCell: ({ row: orgAsset }) => {
          const owner = extractSiteOwner(orgAsset.siteOwner);
          if (!isObject(owner)) {
            return owner; // missing or auth0 fail
          }
          const emailLink = (
            <EmailLink email={owner.email}>
              {owner.contactName} {`<${owner.email}>`}
            </EmailLink>
          );
          return (
            <LinkTooltip title={emailLink}>
              <Typography variant="body2">{owner.ownerName}</Typography>
            </LinkTooltip>
          );
        },
        cellClassName: ({ row: orgAsset }) => {
          const owner = extractSiteOwner(orgAsset.siteOwner);
          return isObject(owner) ? "" : "missing";
        },
      },
      {
        headerName: "Projects",
        field: "projects",
        flex: 0.6,
        renderCell: ({ value: projects }) => {
          return (
            <Stack direction="row" spacing={1}>
              {map(projects, ({ id, title }) => (
                <LinkTooltip title={title}>
                  <RelativeLink to={`/admin/projects/${id}`} key={id}>
                    {id}
                  </RelativeLink>
                </LinkTooltip>
              ))}
            </Stack>
          );
        },
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        renderCell: (params) => (
          <IconButton
            onClick={(ev) => {
              dispatch(getOrgAsset.reset());
              const orgAsset = params.row;
              const url = `/admin/orgAssets/${orgAsset.id}`;
              navigate(url);
            }}
            aria-label="view org asset"
            size="small"
            sx={{ ml: 1 }}
          >
            <ViewIcon />
          </IconButton>
        ),
      },
    ],
    [dispatch, navigate]
  );

  return (
    <Box height="100%" ml={3}>
      <DataGridPro
        sx={{
          border: 0,
          "& .missing": {
            color: "error.main",
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(columnVisibilityModel) =>
          setColumnVisibilityModel(columnVisibilityModel)
        }
        density="compact"
        columns={columns}
        rows={orgAssets}
        hideFooter
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: { action: handleOpenEditor },
        }}
      />
      <AddAssetInviteEditor
        open={isEditorOpen}
        onClose={handleCloseEditor}
        site={site}
      />
    </Box>
  );
};

export default OrgAssetsDetailPanel;
