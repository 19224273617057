import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Container, Grid, Typography } from "@mui/material";
import Page from "@components/Page";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Breadcrumb from "@components/Breadcrumb";
import PageHeader from "@components/PageHeader";
import ProjectDetailPanel from "./ProjectDetailPanel";
import ProjectStatsPanel from "../containers/ProjectStatsPanel";
import ProjectSites from "./ProjectSites";
import ProjectReports from "../containers/ProjectReports";
// import ProjectCredits from "../containers/ProjectCredits";
// import ProjectChart from "../containers/ProjectChart";
import ProjectStatusPanel from "./projectStatus/ProjectStatusPanel";
import { every } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { SectionWarning } from "@admin/components";
import { getPayeeInvites, getProject, getProjectChartByMonth, getProjectCredits, getProjectReports, getProjectSites, getProjectStats } from "../actions";
import PageLoader from "components/PageLoader";

export const ENVIRONMENTAL_CREDIT_TYPES = {
  ALBERTA_EMISSION_OFFSET: "Alberta Emission Offset",
  REWATT_VOLUNTARY_CREDIT: "Rewatt Voluntary Credit",
  CSA_CLEAN_PROJECTS_OFFSET: "CSA CleanProjects Offset",
  CANADA_FUEL_REGULATION: "Canada Fuel Regulation Credit",
  BC_LOW_CARBON_FUEL: "BC Low Carbon Fuel Standard Credit",
};

export const PROTOCOLS = {
  DISTRIBUTED_RENEWABLE_ENERGY_GENERATION: "Distributed Renewable Energy Generation",
  CFR_EVS: "Canada Fuel Regulation Section 101/102",
  BC_LCFS_EVS: "BC Low Carbon Fuel Standard Electrification",
};

const Project = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  // for scrolling
  const projectSitesRef = useRef();
  const projectReportsRef = useRef();

  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects admin_project_reports admin_marketplace admin_sites",
      });
      dispatch(getProject({ accessToken, projectId }));
      dispatch(getProjectCredits({ accessToken, projectId }));
      dispatch(getProjectSites({ accessToken, projectId }));
      dispatch(getProjectReports({ accessToken, projectId }));
      dispatch(getProjectStats({ accessToken, projectId }));
      dispatch(getProjectChartByMonth({ accessToken, projectId }));
      dispatch(getPayeeInvites({ accessToken, projectId }));
    }
    init();
  }, [dispatch, getAccessTokenSilently, projectId]);

  const { project, status: getProjectStatus } = useSelector((state) => state.getProject);
  const isLoading = getProjectStatus === 'request';

  // payees
  const { sites, status: getProjectSitesStatus } = useSelector((state) => state.getProjectSites);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (getProjectSitesStatus === "success") {
      const allPayees = every(sites, (site) => site.payee);
      setOpen(!allPayees);
    }
  }, [getProjectSitesStatus, sites]);

  return (
    <Page py={3} title={`View Project: ${project?.title}`}>
      <Container maxWidth="lg">
        {isLoading
          ? <PageLoader message="Loading..." />
          : (
            <>
              <PageHeader title={project?.title}>
                <Breadcrumb title="Projects" destination={`/admin/projects`} />
              </PageHeader>
              <SectionWarning
                open={open}
                targetRef={projectSitesRef}
                title="Missing Payee"
                description="Some project sites are missing a payee. Invite (or set) them below."
              />
              <Box mt={3}>
                <Grid container spacing={4}>
                  {/* <Grid item sm={12}>
                    {project && <ProjectCredits project={project} />}
                  </Grid>
                  <Grid item sm={12}>
                    {project && <ProjectChart project={project} />}
                  </Grid> */}
                  <Grid item sm={12}>
                    {project && <ProjectStatsPanel
                      project={project}
                      goProjectSites={() =>
                        projectSitesRef.current.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                      goProjectReports={() =>
                        projectReportsRef.current.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    />}
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h3" gutterBottom ml={2}>
                      Project Details
                    </Typography>
                    {project && <ProjectDetailPanel project={project} />}
                  </Grid>
                  <Grid item sm={12}>
                    {project && <ProjectStatusPanel project={project} />}
                  </Grid>
                  <Grid item sm={12}>
                    {project && <ProjectSites project={project} ref={projectSitesRef} />}
                  </Grid>
                  <Grid item sm={12}>
                    {project && <ProjectReports project={project} ref={projectReportsRef} />}
                  </Grid>
                </Grid>
              </Box>
            </>
          )
        }
      </Container>
    </Page>
  );
};

Project.propTypes = {
  getPayeeInvites: PropTypes.func.isRequired,
  getProjectCredits: PropTypes.func.isRequired,
  getProjectStats: PropTypes.func.isRequired,
  getProjectSites: PropTypes.func.isRequired,
  getProjectReports: PropTypes.func.isRequired,
  getProjectChartByMonth: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  getProjectResult: PropTypes.shape({
    project: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default Project;
