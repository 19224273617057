/**
 * Calculate dimensions for an image, such that it fits centered inside a square with dim length
 * @param  float aspectRatio The natural aspect ratio of the image (width:height)
 * @param  int dim           length of a side of the desired square box
 * @return object            the calculated width, height, and offset (left or top)
 */
const getDims = (aspectRatio, dim) => {
  if (aspectRatio >= 1) {
    // width bigger
    return {
      width: dim,
      height: dim / aspectRatio,
      top: (dim - dim / aspectRatio) / 2,
    };
  } else {
    // height bigger
    return {
      width: dim * aspectRatio,
      height: dim,
      left: (dim - dim / aspectRatio) / 2,
    };
  }
};

export { getDims };
