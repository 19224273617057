import React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DocumentIcon from "@mui/icons-material/Description";
import { useTags } from "./TagSelectorContext";

const TagSelector = ({ setValue, open, documentName }) => {
  const handleListItemClick = (value) => {
    setValue(value);
  };
  const tagTypes = useTags();

  return (
    <Dialog
      aria-labelledby="tag-selector"
      open={open}
      onClose={() => setValue(null)}
    >
      <DialogTitle id="tag-selector">
        What type of document is this?
        <Typography variant="body2">{documentName}</Typography>
      </DialogTitle>

      <List dense>
        {tagTypes.map((tag) => (
          <ListItem button onClick={() => handleListItemClick(tag)} key={tag}>
            <ListItemAvatar>
              <Avatar>
                <DocumentIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={tag} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

TagSelector.propTypes = {
  setValue: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  documentName: PropTypes.string.isRequired,
};

export default TagSelector;
