import React, { useEffect, forwardRef } from "react";
import { Paper, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { getDataConnectorHealth } from "../actions";
import { includes } from "lodash";
import CustomDataGrid from "@components/CustomDataGrid";

const columns = [
  { headerName: "Data Connector", field: "dataConnector", flex: 1 },
  {
    headerName: "Meter Count",
    flex: 1,
    field: "numberOfMeters",
  },
  {
    headerName: "Status",
    flex: 1,
    field: "isWorking",
    type: "boolean",
    cellClassName: ({ row: connector }) => (connector.isWorking ? "checkmark" : "xmark"),
  },
];

const DataConnectorHealth = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // fetch data
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_sites",
      });
      dispatch(getDataConnectorHealth.reset());
      dispatch(
        getDataConnectorHealth({
          accessToken,
        })
      );
    }
    init();
  }, [getAccessTokenSilently, dispatch]);

  const { dataConnectors, status } = useSelector((state) => state.getDataConnectorHealth);
  const isLoading = includes(["init", "request"], status);

  const [pageSize, setPageSize] = React.useState(10);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "isWorking",
      sort: "desc",
    },
  ]);

  return (
    <Paper ref={ref}>
      <Box
        sx={{
          height: 358,
          width: "100%",
          "& .checkmark .MuiSvgIcon-root": {
            color: "success.main",
          },
          "& .xmark .MuiSvgIcon-root": {
            color: "error.main",
          },
        }}
      >
        <CustomDataGrid
          style={{ border: 0 }}
          rowHeight={50}
          loading={isLoading}
          rows={dataConnectors}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.dataConnector}
        />
      </Box>
    </Paper>
  );
});

export default DataConnectorHealth;
