import { call, put, takeLatest } from "redux-saga/effects";
import { getAcceptableSites } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { omitBy, isNil } from "lodash";
import { transformSites } from "@views/sites/sagas/getSites";

const callApi = async (accessToken, params) => {
  const {
    page,
    pageSize,
    order,
    search,
    protocol,
    environmentalCredit,
  } = params;
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/acceptableAssets`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: {
        ...omitBy(
          {
            page,
            pageSize,
            order,
            search,
          },
          isNil
        ),
        protocol,
        environmentalCredit,
      },
    }
  );
  return response;
};

export function* getAcceptableSitesWorker(action) {
  yield put(getAcceptableSites.request());

  const { accessToken, ...params } = action.payload;

  try {
    const response = yield call(callApi, accessToken, params);
    const { assets, total } = response.data;
    yield put(
      getAcceptableSites.success({
        sites: transformSites(assets),
        total: Number(total),
      })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting all sites: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting all sites: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting all sites: ", err);
      message = err.message;
    }
    message = `Unable to get all sites: ${message}`;
    yield put(getAcceptableSites.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getAcceptableSitesWatcher() {
  yield takeLatest(getAcceptableSites.TRIGGER, getAcceptableSitesWorker);
}

export default [getAcceptableSitesWatcher];
