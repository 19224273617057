import getOrganizationStats from "./getOrganizationStats";
import getChartData from "./getChartData";
import dataUpdated from "./dataUpdated";
import getOrganizationEnergy from "./getOrganizationEnergy";
import getOrganizationEmissions from "./getOrganizationEmissions";
import getOrganizationCredits from "./getOrganizationCredits";

export default [
  ...getOrganizationStats,
  ...dataUpdated,
  ...getChartData,
  ...getOrganizationEnergy,
  ...getOrganizationEmissions,
  ...getOrganizationCredits,
];
