import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  Tooltip,
  IconButton,
} from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import LaunchIcon from "@mui/icons-material/Launch";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const SolarEdgeInstructionPanel = () => (
  <Box mt={4} pt={4} borderTop={1} borderColor="divider">
    <Typography variant="h4" fontWeight="bold" gutterBottom>
      Steps to access your SolarEdge API key
    </Typography>
    <Typography variant="body2" gutterBottom>
      SolarEdge inverter devices report site data to SolarEdge’s monitoring platform. In order for
      Rewatt Power to compute credits for you, we use API keys to create a secure connection to
      SolarEdge’s monitoring platform.
    </Typography>
    <Typography variant="body2">Follow these steps to get your API key.</Typography>
    <List dense>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Login to the SolarEdge Monitoring platform"
          secondary="https://monitoring.solaredge.com/solaredge-web/p/login"
        />
        <ListItemSecondaryAction>
          <Link
            href="https://monitoring.solaredge.com/solaredge-web/p/login"
            target="_blank"
            rel="noopener"
          >
            <Tooltip title="Open link in a new tab/window">
              <IconButton edge="end" aria-label="SolarEdge" size="large">
                <LaunchIcon />
              </IconButton>
            </Tooltip>
          </Link>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText primary="Choose what kind of API key you want to provide" />
      </ListItem>
      <ListItem sx={{ pl: 8 }}>
        <ListItemIcon>
          <SubdirectoryArrowRightIcon />
        </ListItemIcon>
        <ListItemText
          primary="User API key"
          secondary="Click Account Admin &gt; Company Details tab &gt; API Access"
        />
      </ListItem>
      <ListItem sx={{ pl: 8 }}>
        <ListItemIcon>
          <SubdirectoryArrowRightIcon />
        </ListItemIcon>
        <ListItemText
          primary="Site-specific API key"
          secondary="Grants Rewatt Power
        access to data for the single site associated with the API key. You
        will add a single site at a time."
        />
      </ListItem>
      <ListItem sx={{ pl: 14 }}>
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
        <ListItemText secondary="You should see a list of sites. Click on the site you want to give Rewatt access to." />
      </ListItem>
      <ListItem sx={{ pl: 14 }}>
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
        <ListItemText
          secondary="You should see a
        site specific page. At the top of this page, you should see a menu item
        called “Admin”. Note: If you do not see the “Admin” menu item, you may
        not have permission to share this site with Rewatt."
        />
      </ListItem>
      <ListItem sx={{ pl: 14 }}>
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
        <ListItemText
          secondary="View the Admin
        page for the selected site. At the top of the page, but below the
        “Admin” menu item, you should see a row of Admin sub-menu items. Click
        the sub-menu item “Site Access”."
        />
      </ListItem>
      <ListItem sx={{ pl: 14 }}>
        <ListItemIcon>
          <ChevronRightIcon />
        </ListItemIcon>
        <ListItemText secondary="In the new page, you should click the tab “Access Control”." />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Within the new Access Control page, scroll down until you find the section called “API Access”."
          secondary="Note: Within this section, if the field “API Key” is empty, then you must accept the
              SolarEdge Terms and Conditions (if they have not been accepted)."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Click “New Key”, then click “Save”."
          secondary="If the field is not empty, then proceed to the next step."
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <BulletIcon />
        </ListItemIcon>
        <ListItemText
          primary="Copy the API key code into the “API Key” field below."
          secondary="The API Key is typically longer then the field will show. 
              Ensure the entire API key has been copied. It should have 32 characters."
        />
      </ListItem>
    </List>
  </Box>
);

export default SolarEdgeInstructionPanel;
