import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}, ref) => {
  return (
    <Box
      component="div"
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>Rewatt - {title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
