import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AppMessage from '../components/AppMessage';
import { reset } from '../actions';

const mapStateToProps = ({ appMessage }) => ({
  appMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  reset,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppMessage);
