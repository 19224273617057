import { find } from "lodash";
import logger from "debug";

/**
 * Computes an appropriate set of x categories, given the data, granularity and dateRange.
 *
 * @param  {array<(x,y)>} options.data         the actual chart data, tends to be missing lots of x values
 *                                             in { x: date, y: value } format, where date is /yyyy-MM-dd[ T]HH:mm:ss/
 * @param  {string} options.granularity        eg. hour
 * @param  {array<DateTime>} options.dateRange the start and end date to yyyy-MM-dd HH:mm:ss precision
 *
 * @return {object<categories, tickAmount>}    xaxis props for apex chart
 */
const fillData = ({ data, granularity, dateRange }) => {
  if (granularity === "hour") {
    let duration = Math.ceil(
      dateRange[1].diff(dateRange[0], ["hour"]).hours
    );
    if (dateRange[0].hasSame(dateRange[1], 'day')) {
      duration = 24;
    }

    // figure out start date, compute all hours, merge
    let date = dateRange[0].startOf("hour");
    const filledData = [];
    for (let i = 0; i < duration; i += 1) {
      const x = date.toFormat("yyyy-MM-dd HH:mm:ss");
      filledData.push(find(data, { x }) || { x, y: null });
      date = date.plus({ hours: 1 });
    }
    return filledData;
  } else if (granularity === "day") {
    const duration = Math.ceil(
      dateRange[1].diff(dateRange[0].startOf("day"), ["day"]).days
    );
    let date = dateRange[0].startOf("day");
    const filledData = [];
    for (let i = 0; i < duration; i += 1) {
      const x = date.toFormat("yyyy-MM-dd");
      filledData.push(find(data, { x }) || { x, y: null });
      date = date.plus({ days: 1 });
    }
    return filledData;
  } else if (granularity === "month") {
    const duration = Math.ceil(
      dateRange[1].diff(dateRange[0].startOf("month"), ["month"]).months
    );
    let date = dateRange[0].startOf("month");
    const filledData = [];
    for (let i = 0; i < duration; i += 1) {
      const x = date.toFormat("yyyy-MM-dd");
      filledData.push(find(data, { x }) || { x, y: null });
      date = date.plus({ months: 1 });
    }
    return filledData;
  } else if (granularity === "year") {
    const duration = Math.ceil(
      dateRange[1].diff(dateRange[0].startOf("year"), ["year"]).years
    );

    let date = dateRange[0].startOf("year");
    const filledData = [];
    for (let i = 0; i < duration; i += 1) {
      const x = date.toFormat("yyyy-MM-dd");
      filledData.push(find(data, { x }) || { x, y: null });
      date = date.plus({ years: 1 });
    }
    return filledData;
  } else {
    logger("weedle:error")("unsupported granularity: ", granularity);
  }
};

export { fillData };
