import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SiteActivity, Manufacturer, SitePicker } from "./steps";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddSitesEditor = ({ open, cancelAction, resetAddSites, showNewRows }) => {
  // track which component to display
  const [activeStep, setActiveStep] = useState(0);

  const onCancel = () => {
    resetAddSites();
    cancelAction();
    setActiveStep(0);
  };

  // close dialog and refresh the table
  const continueAction = async () => {
    onCancel();
    showNewRows();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-paperFullScreen": {
          bgcolor: "background.light",
        },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCancel}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Add Sites
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ p: 4 }}>
        {activeStep === 0 && (
          <SiteActivity nextAction={() => setActiveStep(1)} backAction={null} />
        )}
        {activeStep === 1 && (
          <Manufacturer
            nextAction={() => setActiveStep(2)}
            backAction={() => setActiveStep(0)}
          />
        )}
        {activeStep === 2 && (
          <SitePicker
            nextAction={continueAction}
            backAction={() => setActiveStep(1)}
          />
        )}
      </Container>
    </Dialog>
  );
};

AddSitesEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func.isRequired,
  resetAddSites: PropTypes.func.isRequired,
  showNewRows: PropTypes.func.isRequired,
};

export default AddSitesEditor;
