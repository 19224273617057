import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TermsAndConditions from "../components/TermsAndConditions";
import { updateUserProfile } from "@views/common/actions";
import { showAppMessage } from "@appMessage/actions";

const mapStateToProps = ({
  getUserProfile,
  getProgram,
  application,
  updateUserProfile,
}) => ({
  getUserProfileResult: getUserProfile,
  updateUserProfileResult: updateUserProfile,
  getProgramResult: getProgram,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateUserProfile,
      showAppMessage,
    },
    dispatch
  ),
  resetUpdateUserProfile: () => {
    dispatch(updateUserProfile.reset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
