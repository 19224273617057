import { call, put, takeLatest } from "redux-saga/effects";
import { createSite } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, customerProject, asset) => await axios.post(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProject.id}/sites`,
  asset,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* createSiteWorker(action) {
  yield put(createSite.request());
  try {
    const { accessToken, customerProject, siteDetails: asset } = action.payload;
    const response = yield call(
      callApi,
      accessToken,
      customerProject,
      {
        ...asset,
        organizationIsOwner: true,
      }
    );
    yield put(createSite.success({
      site: response.data,
      // supply accessToken and customerProjectId so we can refectch the project sites after creation
      accessToken,
      customerProjectId: customerProject.id
    }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Error creating site: ${message}`;
    yield put(createSite.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* createSiteWatcher() {
  yield takeLatest(createSite.TRIGGER, createSiteWorker);
}

export default [createSiteWatcher];
