import {
  initApplication,
  acceptTermsAndConditions,
  saveOrganizationInfo,
  saveSiteOwner,
  saveOwnerType,
  saveSiteIsBuilt,
  saveManufacturer,
  saveInstallerInfo,
  saveCredentials,
  saveSite,
  saveSiteDetails,
  saveSiteLocation,
  saveSiteDocuments,
  saveIndividualAddress,
  saveReferrer,
  resetApplication,
} from "../actions";
import { isUndefined } from "lodash";

export const initialState = {
  programId: null,
  userId: "",
  email: "",
  referrer: null,

  acceptedProgramTermsAndConditions: false,

  organization: {
    name: "",
    industry: "",
    addresses: {
      main: {
        street: "",
        city: "",
        region: "",
        country: "",
        postal: "",
      },
    },
    website: "",
    phone: "",
  },

  // 3 types of owners: INDIVIDUAL (w/ account), ORGANIZATION,
  // or a separate owner EXTERNAL (w/o account)
  // on a relogin, expectation is you would either have an org or not (rather than not having finished your org info)
  // so we actually start out as org or indiv, and if org, you get the opportunity to switch to external
  siteOwner: {
    ownerType: null,
    organization: null,
    individual: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",      
    },
    external: {
      // for a separate EXTERNAL owner that we email
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notify: true,
    },
  },

  siteInfo: {
    isBuilt: false,
    manufacturer: "",
    credentials: {
      apiKey: "",
      authCode: "",
      froniusAccessUrl: "",
      plantId: "",
      username: "",
      password: "",
      cachelanPortalUrl: "",
    },
    installer: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    // the found site, see @views/addSites/sitePicker/components/SitePicker.jsx#transformSites
    site: {
      id: "",
      name: "",
      capacity: "",
      completionDate: "",
      eligible: null,
      location: {
        addresses: {
          main: {
            street: "",
            city: "",
            region: "",
            country: "",
            postal: "",
          },
        },
        timeZone: "",
        latitude: null,
        longitude: null,
      },
      details: {
        utility: "",
        customerId: "", // for utility
        equipmentOwnership: "",
        hasReceivedGrants: null,
        fundingSource: "",
        mountingType: "",
      },
      documents: [],
      // etc
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resetApplication.TYPE: {
      return initialState;
    }

    case initApplication.TYPE: {
      return {
        ...initialState,
        ...action.payload,
        // n.b. referrer may be set at Welcome. Keep it if we have it
        referrer: state.referrer ?? initialState.referrer,
      };
    }

    case acceptTermsAndConditions.TYPE: {
      const { acceptedProgramTermsAndConditions } = action.payload;
      return {
        ...state,
        acceptedProgramTermsAndConditions,
      };
    }

    case saveOwnerType.TYPE: {
      const { ownerType } = action.payload;
      return {
        ...state,
        siteOwner: {
          ...state.siteOwner,
          ownerType,
        },
      };
    }

    case saveSiteOwner.TYPE: {
      const { siteOwner } = action.payload;
      return {
        ...state,
        siteOwner,
      };
    }

    case saveOrganizationInfo.TYPE: {
      const { organization } = action.payload;
      return {
        ...state,
        organization,
      };
    }

    case saveIndividualAddress.TYPE: {
      const { address } = action.payload;
      return {
        ...state,
        individual: {
          ...state.individual,
          address,
        },
      };
    }

    case saveSiteIsBuilt.TYPE: {
      const { isBuilt } = action.payload;
      return {
        ...state,
        siteInfo: {
          ...state.siteInfo,
          isBuilt,
        },
      };
    }

    case saveManufacturer.TYPE: {
      const { manufacturer } = action.payload;
      return {
        ...state,
        siteInfo: {
          ...state.siteInfo,
          manufacturer,
        },
      };
    }

    // partial update (mostly siteInfo.details) on siteInfo
    case saveSiteDetails.TYPE: {
      const { site } = action.payload;
      const { name, capacity, completionDate, details } = site;
      return {
        ...state,
        siteInfo: {
          ...state.siteInfo,
          site: {
            ...state.siteInfo.site,
            name,
            capacity,
            completionDate,
            details: {
              ...state.siteInfo.site.details,
              ...details,
            },
          },
        },
      };
    }

    case saveSiteLocation.TYPE: {
      const { location } = action.payload;
      return {
        ...state,
        siteInfo: {
          ...state.siteInfo,
          site: {
            ...state.siteInfo.site,
            location: {
              ...state.siteInfo.site.location,
              ...location,
            },
          },
        },
      };
    }

    case saveSiteDocuments.TYPE: {
      const { documents } = action.payload;
      return {
        ...state,
        siteInfo: {
          ...state.siteInfo,
          site: {
            ...state.siteInfo.site,
            documents: [...state.siteInfo.site.documents, ...documents],
          },
        },
      };
    }

    // this is the found site
    case saveSite.TYPE: {
      const { site } = action.payload;
      return {
        ...state,
        siteInfo: {
          ...state.siteInfo,
          site,
        },
      };
    }

    case saveCredentials.TYPE: {
      const {
        apiKey,
        apiUrl,
        authCode,
        froniusAccessUrl,
        cachelanPortalUrl,
        plantId,
        username,
        password,
        chargerIds,
      } = action.payload;
      const { siteInfo } = state;
      return {
        ...state,
        siteInfo: {
          ...siteInfo,
          credentials: {
            apiKey: isUndefined(apiKey) ? siteInfo.credentials.apiKey : apiKey,
            apiUrl: isUndefined(apiUrl) ? siteInfo.credentials.apiUrl : apiUrl,
            authCode: isUndefined(authCode)
              ? siteInfo.credentials.authCode
              : authCode,
            froniusAccessUrl: isUndefined(froniusAccessUrl)
              ? siteInfo.credentials.froniusAccessUrl
              : froniusAccessUrl,
            cachelanPortalUrl: isUndefined(cachelanPortalUrl)
              ? siteInfo.credentials.cachelanPortalUrl
              : cachelanPortalUrl,
            plantId: isUndefined(plantId)
              ? siteInfo.credentials.plantId
              : plantId,
            username: isUndefined(username)
              ? siteInfo.credentials.username
              : username,
            password: isUndefined(password)
              ? siteInfo.credentials.password
              : password,
            chargerIds: isUndefined(chargerIds)
              ? siteInfo.credentials.chargerIds
              : chargerIds,
          },
        },
      };
    }

    case saveInstallerInfo.TYPE: {
      const { firstName, lastName, email, phone } = action.payload;
      const { siteInfo } = state;
      return {
        ...state,
        siteInfo: {
          ...siteInfo,
          installer: {
            firstName,
            lastName,
            email,
            phone,
          },
        },
      };
    }

    case saveReferrer.TYPE: {
      const { referrer } = action.payload;
      return {
        ...state,
        referrer,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
