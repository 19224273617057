import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import { map, entries } from "lodash";
import { v4 as uuid } from "uuid";
import { flattenObject } from "@utils/errorHelpers";

const debug = process.env.NODE_ENV !== "production";

const ValidationErrors = ({ validationErrors }) => {
  return (
    <List
      sx={{ color: theme => theme.palette.common.white }}
      subheader={
        <ListSubheader color="inherit" sx={{ bgcolor: "transparent"}}>
          Error submitting application
        </ListSubheader>
      }
    >
      {map(entries(flattenObject(validationErrors)), ([key, error]) => (
        <ListItem key={uuid()} sx={{ py: 0 }}>
          <ListItemText
            primaryTypographyProps={{ variant: "body2", color: "inherit" }}
          >
            - {debug && `${key}:`} {error}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

ValidationErrors.propTypes = {
  validationErrors: PropTypes.shape({}),
};

export default ValidationErrors;
