// saga is in views/common/sagas/uploadFiles.js
import { uploadProjectFiles } from "../actions";

export const initialState = {
  status: "init",
  filespecs: [],
  errors: null,
  discriminator: undefined, // discriminator for multiple uploaders on the page; will be for project status
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadProjectFiles.RESET: {
      return initialState;
    }

    case uploadProjectFiles.REQUEST: {
      return {
        ...state,
        status: "request",
        filespecs: [],
        errors: null,
        discriminator: action.payload.discriminator,
      };
    }

    case uploadProjectFiles.SUCCESS: {
      const { filespecs, discriminator } = action.payload;
      return {
        ...state,
        status: "success",
        filespecs,
        discriminator,
      };
    }

    case uploadProjectFiles.FAILURE: {
      const { errors, discriminator } = action.payload;
      return {
        ...state,
        status: "failure",
        errors,
        discriminator,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
