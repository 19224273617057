import { acceptAssetClaimInvite } from "../actions";

export const initialState = {
  status: "init",
  invite: {},
  userProfile: {},
  siteOwnerAsset: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case acceptAssetClaimInvite.RESET: {
      return initialState;
    }

    case acceptAssetClaimInvite.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case acceptAssetClaimInvite.SUCCESS: {
      const { invite, userProfile, siteOwnerAsset } = action.payload;
      return {
        ...state,
        status: "success",
        invite,
        userProfile,
        siteOwnerAsset,
      };
    }

    case acceptAssetClaimInvite.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
