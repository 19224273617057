import { getPayeeStatements } from "../actions";

export const initialState = {
  status: "init",
  statements: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getPayeeStatements.REQUEST: {
      return {
        ...state,
        ...initialState,
        status: "request",
      };
    }

    case getPayeeStatements.SUCCESS: {
      const { statements } = action.payload;
      return {
        ...state,
        status: "success",
        statements,
      };
    }

    case getPayeeStatements.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
