import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Page from "components/Page";
import { Box, Container, Paper, Typography } from "@mui/material";

const StandardLayout = ({ pageName, title, children }) => (
  <Page title={pageName} py={3}>
    <Container maxWidth="lg">
      <Box ml={10} mr={10}>
        <Typography variant="h2" color="textPrimary">
          {title}
        </Typography>
        <Typography variant="h4" color="textPrimary">
          {pageName}
        </Typography>
        <Paper sx={{ p: 3, mt: 2 }}>
          {children}
        </Paper>
      </Box>
    </Container>
  </Page>
);

const UserRegistrationPage = ({ pageName, children }) => (
  <StandardLayout title="User Registration" pageName={pageName}>
    {children}
  </StandardLayout>
);
UserRegistrationPage.propTypes = {
  pageName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ApplicationPage = ({ pageName, children }) => {
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  // go back to the start if we're missing customer project and let start figure out
  // where to go
  const navigate = useNavigate();
  useEffect(() => {
    if (isNil(customerProject)) {
      navigate("/projects/apply/start");
    }
  }, [navigate, customerProject]);
  return customerProject ? (
    <StandardLayout pageName={pageName} title={`${customerProject?.title} Registration`}>
      {children}
    </StandardLayout>
  ) : null;
};

ApplicationPage.propTypes = {
  pageName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export {
  ApplicationPage,
  UserRegistrationPage,
};
