import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TermsAndConditions from "@components/TermsAndConditions";
import { useSelector, useDispatch } from "react-redux";
import { useNext, usePrev } from "./useNextPrev";
import { application as appActions } from "../actions";
import { ApplicationPage } from "./ApplicationLayout";
import { Typography } from "@mui/material";

const ProjectTermsAndConditions = () => {
  const next = useNext();
  const prev = usePrev();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { customerProject } = useSelector((state) => state.getCustomerProject);

  const save = () => {
    dispatch(appActions.save({ acceptedProgramTermsAndConditions: true }));
    next("review");
  };

  // load the program pdf
  const [pdf, setPdf] = useState(null);
  useEffect(() => {
    async function fetchPdf() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      const request = {
        url: customerProject.termsAndConditions.uri,
        httpHeaders: { Authorization: `Bearer: ${accessToken}` },
        withCredentials: true,
        name: "Program Terms and Conditions.pdf",
      };
      setPdf(request);
    }
    if (customerProject?.termsAndConditions.uri) {
      fetchPdf();
    }
  }, [getAccessTokenSilently, customerProject?.termsAndConditions.uri]);

  return (
    <ApplicationPage pageName="Project Terms and Conditions">
      <Typography>
        {`In order to apply to ${customerProject?.title}, you must agree to the terms and conditions of the project. Before you continue, please read below.`}
      </Typography>
      <TermsAndConditions
        pdf={pdf}
        acceptTermsAndConditions={save}
        submitActionText="Continue"
        isSubmitting={false}
        agreementLabelText="I have read the program Terms and Conditions"
        backAction={prev}
      />
    </ApplicationPage>
  );
};

export default ProjectTermsAndConditions;
