import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import {  isEmpty, isNil, filter } from "lodash";
import CustomDataGrid from "@components/CustomDataGrid";
import pluralize from "pluralize";
import { getFullName } from "@utils/stringHelpers";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ViewIcon from "@mui/icons-material/Visibility";
import { renderCellExpand } from "@components/GridCellExpand";
import { useDispatch, useSelector } from "react-redux";
import { getAcceptableSites } from "../actions";
import { includes } from "lodash";
import { join } from "lodash";
import { Box } from "@mui/system";
import { getGridNumericOperators, getGridStringOperators } from "@mui/x-data-grid-pro";
import DisplayStatus from "views/sites/components/DisplayStatus";

const FormDataGrid = styled(CustomDataGrid)(({ theme, err }) => ({
  borderColor: err ? theme.palette.error.main : 0,
}));

const OrgAssetCustomerProjects = ({ site }) => (
  <Box m={3}>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>
                Customer Project Title
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                Join Date
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                Emission Reduction Activity
              </strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(site.customerProjects) ? (
            <TableRow>
              <TableCell colSpan={3}>
                Site is not in any customer projects
              </TableCell>
            </TableRow>
          ) : site.customerProjects.map((customerProject) => {
            const dateAdded = DateTime.fromISO(customerProject?.dateAdded)
              .setLocale(getDefaultLocale())
              .toLocaleString(DateTime.DATE_SHORT);
            return (
              <TableRow key={customerProject.id}>
                <TableCell>
                  {customerProject.title}
                </TableCell>
                <TableCell>
                  {dateAdded}
                </TableCell>
                <TableCell>
                  {customerProject.emissionReductionActivity}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

const ProjectSitesPicker = ({
  environmentalCredit,
  onChange,
  value,
  error,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // state of the table (no redux)
  const [tableState, setTableState] = useState({
    page: 0,
    pageSize: 10,
    sortModel: [],
    filterModel: {
      items: [],
      linkOperator: "and",
    },
  });
  const { page, pageSize, sortModel, filterModel } = tableState;

  // table def
  const stringOps = filter(getGridStringOperators(), {
    value: "contains",
  });
  const numericOps = filter(getGridNumericOperators(), { value: "=" });
  const stringEqOps = filter(getGridStringOperators(), {
    value: "equals",
  });
  const columns = [
    {
      headerName: "Site ID",
      field: "id",
      flex: 0.5,
      filterOperators: numericOps,
      type: "number",
      align: "left",
      headerAlign: "left",
      hide: true,
    },
    {
      headerName: "Site Name",
      field: "name",
      flex: 1,
      renderCell: renderCellExpand,
      filterOperators: stringOps,
    },
    {
      headerName: "Capacity (kW)",
      field: "capacity",
      flex: 0.8,
      valueFormatter: ({ value }) => value && (value / 1000).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      type: "number",
      filterOperators: numericOps,
    },
    {
      headerName: "Location",
      field: "location",
      flex: 1.2,
      renderCell: ({ value: location }) => {
        return (
          <Typography fontWeight="bold" component="div" variant="body2">
            {location.city},{" "}
            <Typography display="inline" variant="body2">
              {location.region}
            </Typography>
            <Typography variant="body2">{location.street}</Typography>
          </Typography>
        );
      },
      filterOperators: stringOps,
      sortable: false
    },
    {
      headerName: "Site Owner",
      field: "siteOwner",
      flex: 1,
      valueGetter: ({ row: site }) => {
        if (site.siteOwner?.external) {
          return getFullName(site.siteOwner.external);
        } else if (site.siteOwner?.individual) {
          return getFullName(site.siteOwner.individual);
        } else {
          return site.siteOwner?.organization?.name || "-";
        }
      },
      renderCell: renderCellExpand,
      sortable: false
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: ({ value }) => <DisplayStatus status={value} />,
      filterOperators: stringEqOps,
    },
    {
      headerName: "Customers",
      field: "customer",
      valueGetter: ({ row: site }) => join(site.customerProjects.map(cp => cp.customer), ','),
      flex: 1,
      filterOperators: stringEqOps,
      sortable: false
    },
    {
      headerName: "Customer Projects",
      field: "customerProjects",
      valueGetter: ({ row: site }) => join(site.customerProjects.map(cp => cp.title), ','),
      flex: 1,
      filterOperators: stringEqOps,
      sortable: false
    },
    {
      headerName: "Application(s)",
      field: "applications",
      valueGetter: ({ row: site }) => join(
        site.applications?.map(
          ({ id, mostRecentStatusChange }) => `${id} ${mostRecentStatusChange.status} ${DateTime.fromISO(mostRecentStatusChange.date).toLocaleString()}`
        ),
        ','
      ),
      flex: 1,
      filterOperators: stringEqOps,
      sortable: false
    },
    {
      field: "viewAction",
      headerName: "View",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(ev) => {
            const orgAsset = params.row;
            const url = `/admin/orgAssets/${orgAsset.id}`;
            const newWindow = window.open(url, "_blank", "noopener,noreferrer");
            if (newWindow) newWindow.opener = null;
          }}
          aria-label="view org asset"
          size="small"
          sx={{ ml: 1 }}
        >
          <ViewIcon />
        </IconButton>
      ),
    },
  ];

  // refetch if table state changes
  useEffect(() => {
    const fetchRows = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_sites admin_projects",
      });
      dispatch(getAcceptableSites({
        accessToken,
        environmentalCredit,
        page: page + 1,
        pageSize: pageSize,
        order: !isEmpty(sortModel) ? `${sortModel[0]?.field}:${sortModel[0]?.sort}` : undefined,
        search:
          !isEmpty(filterModel?.items) && !isNil(filterModel?.items?.[0].value)
            ? `${filterModel.items[0].columnField}:${filterModel.items[0].value}`
            : undefined,
      }));
    };
    fetchRows();
  }, [
    getAccessTokenSilently,
    dispatch,
    environmentalCredit,
    page,
    pageSize,
    sortModel,
    filterModel,
  ]);

  // site list
  const { sites, total, status } = useSelector((state) => state.getAcceptableSites);
  const sitesLoading = includes(["requiest", "init"], status);

  const getDetailPanelHeight = useCallback(() => 'auto', []);
  const getOrgAssetsDetailPanel = useCallback(
    ({ row: site }) => <OrgAssetCustomerProjects site={site} />,
    []
  );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <FormDataGrid
        rowHeight={75}
        autoHeight
        loading={sitesLoading}
        rows={sites}
        columns={columns}
        disableSelectionOnClick
        rowsPerPageOptions={[10, 20, 50]}
        checkboxSelection
        onSelectionModelChange={(assetIds) => onChange(assetIds)}
        selectionModel={value}
        err={error}
        localeText={{
          footerRowSelected: (count) =>
            `${count.toLocaleString()} ${pluralize("site", count)} selected`,
          noRowsLabel: "No eligible sites found.",
        }}
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(sortModel) => setTableState({ ...tableState, sortModel })}
        page={page}
        onPageChange={(page) => setTableState({ ...tableState, page })}
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setTableState({ ...tableState, pageSize })}
        filterModel={filterModel}
        onFilterModelChange={(filterModel) => setTableState({ ...tableState, filterModel })}
        rowCount={total || 0}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getOrgAssetsDetailPanel}
      />
    </div>
  );
};

ProjectSitesPicker.propTypes = {
  environmentalCredit: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  error: PropTypes.string,
};

export default ProjectSitesPicker;
