import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Container, Tabs, Tab } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { voluntarySchema } from "./createProjectReportSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import CreateVoluntarySuccess from "./CreateVoluntarySuccess";
import { DateTime } from "luxon";
import { reduce } from "lodash";
import { wmTokWh } from "@utils/energyHelpers";
import { useAppMessage } from "@components/appMessage";
import { useDispatch, useSelector } from "react-redux";
import { createProjectReport } from "@views/admin/project/actions";
import ByDateRange from "./ByDateRange";
import ByCreditRequest from "./ByCreditRequest";
import TabPanel from "@components/TabPanel";

const CreateVoluntaryProjectReport = ({ project, startDate }) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(voluntarySchema),
    defaultValues: {
      calculateByDateRange: "yes",
      startDate: DateTime.fromISO(startDate),
      endDate: "",
      credits: "",
    },
  });

  const { reset } = methods;
  const endDate = methods.watch("endDate");

  // update defaults if startDate changes
  useEffect(() => {
    reset({
      calculateByDateRange: tabIndex === 0 ? "yes" : "no",
      startDate: DateTime.fromISO(startDate),
      endDate: "",
      credits: "",
    });
  }, [startDate, reset, tabIndex]);

  // create project report
  const {
    projectReportCalculation: { sites, startDate: calculatedStartDate, endDate: calculatedEndDate },
  } = useSelector((state) => state.getProjectReportCalculation);
  const totalEnergy = wmTokWh(reduce(sites, (sum, site) => sum + site.energy, 0));
  const onSubmit = async (data) => {
    if (totalEnergy > 0) {
      const projectReport = {
        startDate: DateTime.fromISO(calculatedStartDate),
        endDate: DateTime.fromISO(calculatedEndDate),
      };
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_project_reports",
      });
      dispatch(
        createProjectReport({
          projectReport,
          accessToken,
          projectId: project.id,
        })
      );
    } else {
      showAppMessage({
        severity: "error",
        message: "A project report must contain some energy.",
      });
    }
  };

  // post-submission
  const { projectReport, status } = useSelector((state) => state.createProjectReport);
  const isSubmitting = status === "request";
  const isSuccess = status === "success";

  return (
    <>
      {!isSuccess && (
        <Container maxWidth="lg">
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Site tabs"
            sx={{ m: 3 }}
          >
            <Tab key="By Date Range" label="By Date Range" />
            <Tab key="By Credit Request" label="By Credit Request" />
          </Tabs>
          <TabPanel value={tabIndex} index={0} key="ByDateRange">
            <FormProvider {...methods}>
              <ByDateRange project={project} startDate={startDate} endDate={endDate} />
            </FormProvider>
          </TabPanel>
          <TabPanel value={tabIndex} index={1} key="ByCreditRequest">
            <FormProvider {...methods}>
              <ByCreditRequest project={project} startDate={startDate} />
            </FormProvider>
          </TabPanel>
          <Box display="flex" justifyContent="flex-end" m={3}>
            <LoadingButton
              variant="contained"
              size="large"
              color="primary"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Create Project Report
            </LoadingButton>
          </Box>
        </Container>
      )}
      {isSuccess && <CreateVoluntarySuccess project={project} projectReport={projectReport} />}
    </>
  );
};

CreateVoluntaryProjectReport.propTypes = {
  project: PropTypes.shape({}).isRequired,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
};

export default CreateVoluntaryProjectReport;
