import React from "react";
import PropTypes from "prop-types";
import { Duration } from "luxon";
import humanizeDuration from "humanize-duration";
import { Typography } from "@mui/material";
import logger from "debug";

export const getDisplayStatus = (status) => {
  switch (status) {
    case "NOT_REPORTING": {
      return {
        color: "warning.main",
        statusName: "NOT REPORTING",
      };
    }
    case "INACTIVE": {
      return {
        color: "grey.500",
        statusName: "INACTIVE",
      };
    }
    case "ONLINE": {
      return {
        color: "success.main",
        statusName: "ONLINE",
      };
    }
    case "ERROR": {
      return {
        color: "error.main",
        statusName: "ERROR",
      };
    }
    case "NOT_INSTALLED": {
      return {
        color: "grey.500",
        statusName: "NOT INSTALLED",
      };
    }
    case "N/A": {
      return {
        color: "grey.500",
        statusName: "N/A",
      };
    }
    case null: {
      return {
        color: "grey.500",
        statusName: "N/A",
      };      
    }
    default: {
      logger("weedle:warn")("Unknown site status: ", status);
      return {
        color: "primary",
        statusName: "N/A",
      };
    }
  }
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

const DisplayStatus = ({ status }) => {
  const timeInStatus = Duration.fromObject({
    hours: status.timeInStatus,
  }).as("milliseconds");
  const displayStatus = getDisplayStatus(status.status);
  return (
    <div>
      <Typography
        fontWeight="bold"
        variant="body2"
        color={displayStatus.color}
      >
        {displayStatus.statusName}
      </Typography>
      <Typography variant="body2">
        {status.status !== "NOT_INSTALLED" && status.timeInStatus && `Duration: ${shortEnglishHumanizer(timeInStatus, {
          units: ["d", "h"],
        })}`}
      </Typography>
    </div>
  );
};

DisplayStatus.propTypes = {
  status: PropTypes.shape({
    timeInStatus: PropTypes.number,
    status: PropTypes.oneOf(["NOT_REPORTING", "NOT_INSTALLED", "INACTIVE", "ONLINE", "ERROR", "N/A"]),
  }),
};

export default DisplayStatus;
