import { connect } from "react-redux";
import AddSitesEditor from "../components/AddSitesEditor";
import {
  findSites,
  addSites,
  resetSiteInfo,
  testConnection,
  uploadFiles,
} from "@addSites/actions";

const mapDispatchToProps = (dispatch) => ({
  resetAddSites: () => {
    dispatch(findSites.reset());
    dispatch(addSites.reset());
    dispatch(testConnection.reset());
    dispatch(uploadFiles.reset());
    dispatch(resetSiteInfo());
  },
});

export default connect(null, mapDispatchToProps)(AddSitesEditor);
