import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "components";
import { ApplicationPage } from "./ApplicationLayout";
import { useAuth0 } from "@auth0/auth0-react";
import { submitProjectApplication } from "../actions";
import { usePrev } from "./useNextPrev";
import { useNavigate } from "react-router";
import { omitBy } from "lodash";
import { ReviewDetails } from "../../Site/components/Review";
import { transformLocation } from "../../Site/components/Location";
import { transformCompletionDate } from "../../Site/components/Details";
import { transformDevices } from "../../Devices/components/Devices";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import DialogTransition from "components/DialogTransition";

const Review = () => {
  const { customerProject } = useSelector((state) => state.getCustomerProject);
  const application = useSelector((state) => state.customerProjectApplication);
  const { status, error } = useSelector((state) => state.submitProjectApplication);
  const isSubmitting = status === "request";
  const wasSuccessful = status === "success";
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const handleSubmit = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    if (application.asset.completionDate) {
      application.asset.completionDate = transformCompletionDate(application.asset.completionDate);
    }
    if (application.asset.meters) {
      application.asset.meters = transformDevices(application.asset.meters, application.asset.credentials);
    }
    if (application.asset.addresses?.main) {
      application.asset.addresses = transformLocation(application.asset.addresses);
    }
    const clean = omitBy(application.asset, (v) => v === "");
    dispatch(submitProjectApplication({ accessToken, application: { ...application, asset: { ...clean } }, customerProject }));
  };

  const closeErrorDialog = () => {
    dispatch(submitProjectApplication.clearError());
  }

  const prev = usePrev();

  // use navigate instead of next because there is no going back after submission
  const navigate = useNavigate();
  useEffect(() => {
    if (wasSuccessful) {
      navigate("/projects/apply/thankyou");
    }
  }, [wasSuccessful, navigate]);

  return (
    <ApplicationPage pageName="Application Review">
      <Dialog
        open={!!error}
        onClose={closeErrorDialog}
        TransitionComponent={DialogTransition}
      >
        <DialogTitle>
          Error Submitting Your Application
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Looks like we ran into an issue processing your application. There is a description of the issues below.
          </Typography>
          <Typography gutterBottom>
            If you can, close this pop-up, modify your application, and resubmit. Otherwise, click the webchat icon for assistance.
          </Typography>
          <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={closeErrorDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ReviewDetails
        site={application.asset}
        customerProject={customerProject}
        description='Review the following site details. Click "Submit" at the bottom of this page to finalize your application.'
      />
      <Card elevation={0}>
        <CardHeader title="Application Details" sx={{ pb: 0 }}/>
        <CardContent sx={{ pt: 1 }}>
          <TableContainer>
            <Table aria-label="site details" size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" width="50%">
                    Transferring from Radicle
                  </TableCell>
                  <TableCell>
                    {application.transferredFromRadicle ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Navigation
        backAction={prev}
        nextAction={handleSubmit}
        nextActionText="Submit"
        isSubmitting={isSubmitting}
      />
    </ApplicationPage>
  );
};

export default Review;
