import React from "react";
import { Typography, Grid, Container, Box, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { parseAddress } from "@utils/locationHelpers";
import { AddressTextField } from "@addSites/sitePicker";
import Places from "@components/Places";
import Page from "@components/Page";
import Navigation from "@components/Navigation";
import { usePrev, useNext, usePrevPath } from "./useNextPrev";
import { useSelector, useDispatch } from "react-redux";
import { saveIndividualAddress } from "@views/apply/actions";

export const schema = Yup.object().shape({
  street: Yup.string()
    .max(60, "Too long.")
    .required("Street name is required."),
  city: Yup.string().max(60, "Too long.").required("City is required."),
  region: Yup.string().max(60, "Too long.").required("Region is required."),
  country: Yup.string().max(60, "Too long.").required("Country is required."),
  postal: Yup.string().matches(
    /^[a-z0-9][a-z0-9\- ]{2,10}[a-z0-9]$/i,
    "That does not look like a valid postal code format."
  ), // sanity-only
});

const IndividualAddress = () => {
  const program = useSelector((state) => state.getProgram.program);
  const address = useSelector((state) => state.application.individual.address);

  const prevPath = usePrevPath();
  const prev = usePrev();
  const backAction = prevPath ? () => prev() : null;
  const next = useNext();
  const dispatch = useDispatch();

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: address,
  });

  // from the autocomplete
  const populateMainAddress = React.useCallback(
    (newValue) => {
      const address = parseAddress(newValue);
      setValue("street", address.street);
      setValue("city", address.city);
      setValue("region", address.region);
      setValue("country", address.country);
      setValue("postal", address.postal);
    },
    [setValue]
  );

  const onSubmit = async (address) => {
    dispatch(saveIndividualAddress({ address }));
    next("/apply/opstatus");
  };

  return (
    <Page title="My Address" py={3}>
      <Container maxWidth="lg">
        <Box ml={10} mr={10}>
          <Box ml={3}>
            <Typography variant="h2">{program?.name} Registration</Typography>
            <Typography variant="h4">My Address</Typography>
          </Box>
          <Paper sx={{ p: 3, mt: 2 }}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography mt={3} fontWeight="bold">
                    Main Address
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Places changeHandler={populateMainAddress} />
                </Grid>
                <Grid item xs={6}>
                  <AddressTextField
                    errors={errors}
                    control={control}
                    name="street"
                    label="Street"
                    placeholder="621 23 Ave NE"
                    helperText="Your street and number."
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddressTextField
                    errors={errors}
                    control={control}
                    name="city"
                    label="City"
                    placeholder="Calgary"
                    helperText="The city, town or municipality in which you reside."
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddressTextField
                    errors={errors}
                    control={control}
                    name="region"
                    label="Province/State/Region"
                    placeholder="Alberta"
                    helperText="The region in which you reside."
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddressTextField
                    errors={errors}
                    control={control}
                    name="country"
                    label="Country"
                    placeholder="Canada"
                    helperText="The full country name in which you reside."
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddressTextField
                    errors={errors}
                    control={control}
                    name="postal"
                    label="Postal Code"
                    placeholder="T2E 1W5"
                    helperText="Your postal or zip code."
                  />
                </Grid>
              </Grid>
              <Navigation
                backAction={backAction}
                nextAction={() => handleSubmit(onSubmit)()}
                nextActionText="Continue"
              />
            </form>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default IndividualAddress;
