/* eslint-disable no-useless-escape */
import {
  isString,
  isNumber,
  isBoolean,
  isNull,
  isArray,
  isPlainObject,
  isEmpty,
} from "lodash";

// not a great phone number validator, but this is not trivial to do properly worldwide - google's phone lib is 6 MB
const phoneRegExp = /^(([1-9]{1,4}[ -\.]*)|(\([0-9]{2,3}\)[ -\.]*)|([0-9]{2,4})[ -\.]*)*?[0-9]{3,4}?[ -\.]*[0-9]{3,4}?$/;

const urlRegExp = /^(https?:\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const flattenObject = (o, prefix = "", result = {}, keepNull = true) => {
  if (isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o))) {
    result[prefix] = o;
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref = pref + `[${i}]`;
      } else {
        if (isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + "." + i;
        }
      }
      flattenObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
};

const requestErrorMessageExtractor = (err) => {
  // client received an error response (5xx, 4xx)
  if (err.response) {
    const message = err.response.data.reason || err.response.data.message;
    if (err.response.data.validationErrors) {
      return `Validation errors\n${JSON.stringify(err.response.data.validationErrors, null, 4)}`;
    }
    return message;
  }
  
  // client never received a response, or request never left
  if (err.request) {
    return err.message;
  }
  // anything else
  if (err.message) {
    return err.message;
  }

  return 'Unknown error';
}

export { flattenObject, requestErrorMessageExtractor, phoneRegExp, urlRegExp };
