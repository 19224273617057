import { call, put, takeLatest, select } from "redux-saga/effects";
import { patchApplication } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { labelAddresses } from "@utils/locationHelpers";
import { getApplication } from "../selectors";

// NB. the use of our own custom token
const callApi = async (token, application) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/applications/${application.id}/siteOwner`,
    {
      application,
    },
    {
      headers: {
        Authorization: `Bearer: ${token}`,
      },
    }
  );
  return response;
};

const mapSubmission = (submission, application) => {
  return {
    id: submission.applicationId,
    acceptedProgramTermsAndConditions:
      submission.acceptedProgramTermsAndConditions,
    siteOwner: {
      givenName: submission.siteProfile.firstName,
      familyName: submission.siteProfile.lastName,
      email: submission.siteProfile.email,
      phone: submission.siteProfile.phone,
    },
    asset: {
      name: submission.siteProfile.name,
      equipmentOwnership: submission.siteProfile.equipmentOwnership,
      fundingSource: submission.siteProfile.fundingSource,
      addresses: labelAddresses({ main: submission.siteProfile.address }),
    },
  };
};

export function* patchApplicationWorker(action) {
  yield put(patchApplication.request());

  const { token, submission } = action.payload;
  const app = yield select(getApplication);

  try {
    const response = yield call(callApi, token, mapSubmission(submission, app));
    const { application } = response.data;
    yield put(patchApplication.success({ application }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error submitting application: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error submitting application: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error submitting application: ", err);
      message = err.message;
    }
    message = `Unable to submit application: ${message}`;
    yield put(patchApplication.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* patchApplicationWatcher() {
  yield takeLatest(patchApplication.TRIGGER, patchApplicationWorker);
}

export default [patchApplicationWatcher];
