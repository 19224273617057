import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Typography, Box } from "@mui/material";
import { DateTime } from "luxon";

// suitable for display
const getTimePeriod = (unit, datetime) => {
  switch (unit) {
    case "day":
      return datetime.toLocaleString(DateTime.DATE_FULL);
    case "week":
      const start = datetime.startOf("week");
      const end = datetime.endOf("week");
      return `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(
        DateTime.DATE_MED
      )}`;
    case "month":
      return datetime.toFormat("LLLL yyyy");
    case "year":
      return datetime.toFormat("yyyy");
    default:
      throw new Error(`Illegal date unit: ${unit}.`);
  }
};

const getPrevious = (unit, datetime) => datetime.minus({ [unit]: 1 });
const getNext = (unit, datetime) => datetime.plus({ [unit]: 1 });

const PeriodFlipper = ({ unit, value, onChange }) => {
  // compute if we have a next timePeriod
  const [hasNext, setHasNext] = useState(false);

  useEffect(() => {
    const diff = value.diff(DateTime.now(), [unit]);
    setHasNext(Math.abs(diff.get(unit)) > 0.01);
  }, [unit, value])

  const previousPeriod = () => {
    const newValue = getPrevious(unit, value);
    onChange(newValue);
    setHasNext(true);
  };
  const nextPeriod = () => {
    const newValue = getNext(unit, value);
    onChange(newValue);
    const diff = newValue.diff(DateTime.now(), [unit]);
    setHasNext(Math.abs(diff.get(unit)) > 0.01);
  };

  return (
    <Box display="flex" alignItems="center">
      <Button size="small" variant="text" onClick={previousPeriod}>
        &lt;
      </Button>
      <Typography mx={1}>{getTimePeriod(unit, value)}</Typography>
      <Button
        size="small"
        variant="text"
        disabled={!hasNext}
        onClick={nextPeriod}
      >
        &gt;
      </Button>
    </Box>
  );
};

PeriodFlipper.propTypes = {
  // this is effectively the tab
  unit: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
  // represents the displayed timePeriod - it's a point of time in that timePeriod
  value: PropTypes.instanceOf(DateTime).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PeriodFlipper;
