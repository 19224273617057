import React, { useEffect, useMemo, useCallback } from "react";
import { Paper, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { getSitesByAdmin } from "../actions";
import { includes, map, first, every, isEmpty, isNil, min, filter, isUndefined } from "lodash";
import CustomDataGrid from "@components/CustomDataGrid";
import { getAddress } from "@utils/locationHelpers";
import DisplayStatus from "@views/sites/components/DisplayStatus";
import { getDefaultTableState } from "@views/common/reducers/tableState";
import { setTableState } from "@views/common/actions";
import OrgAssetsDetailPanel from "./OrgAssetsDetailPanel";
import { formatCapacity } from "@utils/energyHelpers";

const tableStateKey = "ADMIN_SITES";
const initialTableState = {
  sortModel: [
    {
      field: "id",
      sort: "asc",
    },
  ],
};

const getSiteLocation = (organizationAssets) => {
  if (organizationAssets.length > 1) {
    const { city, region } = getAddress(first(organizationAssets).addresses, "MAIN");
    const mainAddresses = filter(map(organizationAssets, (orgAsset) =>
      getAddress(orgAsset.addresses, "MAIN")
    ), !isUndefined);
    if (
      every(
        mainAddresses,
        (mainAddress) => mainAddress.city === city && mainAddress.region === region
      )
    ) {
      return `${city}, ${region}`;
    }
    return `${city}, ${region}, ...`;
  }
  const address = getAddress(first(organizationAssets)?.addresses, "MAIN");
  return `${address?.city}, ${address?.region}`;
};

const getSiteName = (organizationAssets) => {
  if (organizationAssets.length > 1) {
    const baseName = first(organizationAssets).name;
    const names = map(organizationAssets, "name");
    if (every(names, (name) => name === baseName)) {
      return baseName;
    }
    return `${first(organizationAssets).name}, ...`;
  }
  return first(organizationAssets)?.name;
};

const getCapacity = (organizationAssets) => {
  if (organizationAssets.length > 1) {
    const baseCapacity = first(organizationAssets).capacity;
    const capacities = map(organizationAssets, "capacity");
    if (every(capacities, (name) => name === baseCapacity)) {
      return formatCapacity(baseCapacity);
    }
    return `${formatCapacity(min(map(organizationAssets, "capacity")))}, ...`;
  }
  return formatCapacity(first(organizationAssets)?.capacity);
};

const Sites = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { sites, total, status } = useSelector((state) => state.getSitesByAdmin);
  const isLoading = includes(["init", "request"], status);

  const tableState =
    useSelector((state) => state.tableState[tableStateKey]) ||
    getDefaultTableState(initialTableState);
  const { page, pageSize, sortModel, filterModel } = tableState;

  useEffect(() => {
    const fetchRows = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_sites",
      });
      dispatch(
        getSitesByAdmin({
          accessToken,
          page: page + 1,
          pageSize: pageSize,
          order: !isEmpty(sortModel) ? `${sortModel[0]?.field}:${sortModel[0]?.sort}` : undefined,
          search:
            !isEmpty(filterModel?.items) && !isNil(filterModel?.items[0].value)
              ? `${filterModel.items[0].columnField}:${filterModel.items[0].value}`
              : undefined,
        })
      );
    };
    fetchRows();
  }, [page, pageSize, sortModel, filterModel, getAccessTokenSilently, dispatch]);

  const data = map(sites, (site) => {
    return {
      ...site,
      name: getSiteName(site.organizationAssets),
      location: getSiteLocation(site.organizationAssets),
      capacity: getCapacity(site.organizationAssets),
      numberOfStakeholders: site.organizationAssets.length,
      status: {
        status: site.status,
        timeInStatus: site.timeInStatus,
      },
    };
  });

  const columns = useMemo(
    () => [
      { headerName: "Root ID", field: "id", flex: 0.8 },
      { headerName: "Name", field: "name", flex: 1, sortable: true, filterable: true },
      { headerName: "Location", field: "location", flex: 1.2, sortable: true, filterable: true },
      {
        headerName: "Capacity (kW)",
        field: "capacity",
        flex: 0.8,
        sortable: false,
        filterable: false,
      },
      {
        headerName: "StakeHolders",
        flex: 0.8,
        field: "numberOfStakeholders",
      },
      {
        headerName: "Status",
        flex: 1,
        field: "status",
        renderCell: ({ value }) => <DisplayStatus status={value} />,
      },
    ],
    []
  );

  const getOrgAssetsDetailPanel = useCallback(
    ({ row: site }) => <OrgAssetsDetailPanel site={site} />,
    []
  );

  const getDetailPanelHeight = useCallback(() => 300, []);

  return (
    <Paper>
      <Box height={400}>
        <CustomDataGrid
          style={{ border: 0 }}
          rowHeight={50}
          loading={isLoading}
          rows={data}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(sortModel) =>
            dispatch(setTableState({ key: tableStateKey, sortModel }))
          }
          page={page}
          onPageChange={(page) => dispatch(setTableState({ key: tableStateKey, page }))}
          pageSize={pageSize}
          onPageSizeChange={(pageSize) => {
            if (page * pageSize > total) {
              dispatch(setTableState({ key: tableStateKey, page: 1, pageSize }));
            } else {
              dispatch(setTableState({ key: tableStateKey, pageSize }));
            }
          }}
          rowsPerPageOptions={[10, 20, 50]}
          filterModel={filterModel}
          onFilterModelChange={(filterModel) =>
            dispatch(setTableState({ key: tableStateKey, filterModel }))
          }
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          rowCount={total || 0}
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getOrgAssetsDetailPanel}
        />
      </Box>
    </Paper>
  );
};
export default Sites;
