import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Container,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ProjectFormFields, {
  editProjectSchema,
} from "@views/admin/projects/components/ProjectFormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { get } from "lodash";
import { downloadFile } from "@utils/fileHelpers";
import { useAppMessage } from "@components/appMessage";
import { PROTOCOLS, ENVIRONMENTAL_CREDIT_TYPES } from "@views/admin/project/components/Project";
import { DateTime } from "luxon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProjectEditor = ({
  open,
  onClose,
  project,
  patchProject,
  patchProjectResult,
  resetPatchProject,
  resetGetAcceptableSites,
  getProject,
  getProjectSites,
  getProjectStats,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const showAppMessage = useAppMessage();
  const isVoluntary = project.environmentalCredit === "REWATT_VOLUNTARY_CREDIT";
  const isAlbertaEmissionsOffset = project.environmentalCredit === "ALBERTA_EMISSION_OFFSET";

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(editProjectSchema),
    defaultValues: {
      title: project.title,
      startDate: DateTime.fromISO(project.startDate),
      endDate: isVoluntary ? "" : DateTime.fromISO(project.endDate),
      environmentalCredit: project.environmentalCredit,
      emissionsFactor: project.emissionsFactor ?? "",
      listingUrl: project.listingUrl ?? "",
      protocol: project.protocol.name,
      authority: project.authority.name,
      registry: project.registry.name,
      assetIds: [], // new ones, so empty to start
    },
  });

  // submit
  const onSubmit = async (data) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "admin_projects",
    });

    patchProject({
      project: {
        id: project.id,
        ...data,
        assetIds: [...data.assetIds, ...project.assetIds],
      },
      accessToken,
    });
  };

  const isSubmitting = patchProjectResult.status === "request";
  const isSuccess = patchProjectResult.status === "success";

  const done = async () => {
    methods.reset();

    if (isSuccess) {
      // make sure we update relevant parts of the project page: getProject, getProjectSites, getProjectStats
      // we don't want to do it via watchers in the saga because then we can't see the success page
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects",
      });

      getProject({ accessToken, projectId: project.id });
      getProjectSites({ accessToken, projectId: project.id });
      getProjectStats({ accessToken, projectId: project.id });
    }
    onClose();

    resetPatchProject();
    resetGetAcceptableSites();
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPlanningSheet = async ({ projectId, projectName }) => {
    try {
      setIsDownloading(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "admin_projects",
      });
      await downloadFile(
        {
          name: `${projectName} - Aggregated Planning Sheet.xlsm`,
          url: `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/aggregatedPlanningSheet`,
        },
        accessToken
      );
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      showAppMessage({
        message: `Sorry, there was a problem downloading the file: ${err}`,
        severity: "error",
      });
    }
  };

  const { id: projectId, title: projectName } = patchProjectResult.project;
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ bgcolor: "primary.main" }} position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={done} aria-label="close" size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" color="common.white" ml={2}>
            Edit Project
          </Typography>
        </Toolbar>
      </AppBar>
      {!isSuccess && (
        <Container maxWidth="xl">
          <FormProvider {...methods}>
            <ProjectFormFields mode="EDIT" />
            <Box display="flex" justifyContent="flex-end" mt={5} mb={3}>
              <LoadingButton
                variant="contained"
                size="large"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Update Project
              </LoadingButton>
            </Box>
          </FormProvider>
        </Container>
      )}
      {isSuccess && (
        <Container maxWidth="md">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "calc(80vh - 64px)" }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h1" color="secondary.main">
                Success!
              </Typography>
              <Typography mt={2}>
                You have created a new project under the{" "}
                <strong>
                  {get(ENVIRONMENTAL_CREDIT_TYPES, project?.environmentalCredit, "N/A")}
                </strong>{" "}
                system using the <strong>{get(PROTOCOLS, project?.protocol?.name, "N/A")}</strong>{" "}
                protocol.
              </Typography>
              {isAlbertaEmissionsOffset && (
                <>
                  <Typography mt={1}>
                    You will be required to submit an aggregated planning sheet to the registry
                    which you can download by clicking the button below. Please be patient, this can
                    take a minute or two.
                  </Typography>
                  <Box mt={5}>
                    <LoadingButton
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => downloadPlanningSheet({ projectId, projectName })}
                      disabled={isDownloading}
                      loading={isDownloading}
                    >
                      Download Aggregated Planning Sheet
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Dialog>
  );
};

EditProjectEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({}).isRequired,
  patchProject: PropTypes.func.isRequired,
  patchProjectResult: PropTypes.shape({}).isRequired,
  resetPatchProject: PropTypes.func.isRequired,
  resetGetAcceptableSites: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  getProjectSites: PropTypes.func.isRequired,
  getProjectStats: PropTypes.func.isRequired,
};

export default EditProjectEditor;
