import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Grid } from "@mui/material";
import Page from "@components/Page";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "@components/PageLoader";
import { includes } from "lodash";
import Breadcrumb from "@components/Breadcrumb";
import PageHeader from "@components/PageHeader";
import SiteProfile from "@views/reviewApplication/containers/SiteProfile";
import Documents from "@views/reviewApplication/containers/Documents";
import SiteOwnershipPanel from "@views/reviewApplication/containers/SiteOwnershipPanel";
import SiteConnectionPanel from "@views/reviewApplication/components/SiteConnectionPanel";
import InstallerPanel from "@views/reviewApplication/components/InstallerPanel";
import StatusPanel from "./StatusPanel";
import ApplicationDetails from "./ApplicationDetails";

const SubmittedApplication = ({
  getApplication,
  getApplicationResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { applicationId } = useParams();

  useEffect(() => {
    async function getApp() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getApplication({ accessToken, applicationId });
    }
    getApp();
  }, [
    getAccessTokenSilently,
    getApplication,
    applicationId,
  ]);

  const { application, status } = getApplicationResult;
  const isLoading = includes(["init", "request"], status);

  return (
    <Page title="Review Application" py={3}>
      <Container maxWidth="lg">
        {isLoading && <PageLoader message="Loading..." />}
        {!isLoading && (
          <>
            <PageHeader title={`Application No. ${application?.id}`}>
              <Breadcrumb
                title="My Applications"
                destination={`/myApplications`}
              />
            </PageHeader>
            <Grid container>
              <Grid item xs={12}>
                <ApplicationDetails application={application} />
              </Grid>

              <Grid item xs={12}>
                <SiteProfile application={application} />
              </Grid>

              <Grid item xs={12}>
                <SiteOwnershipPanel application={application} />
              </Grid>

              <Grid item xs={12}>
                <SiteConnectionPanel application={application} />
              </Grid>

              <Grid item xs={12}>
                <InstallerPanel application={application} />
              </Grid>              

              <Grid item xs={12}>
                <Documents application={application} />
              </Grid>

              <Grid item xs={12}>
                <StatusPanel application={application} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  );
};

SubmittedApplication.propTypes = {
  getApplication: PropTypes.func.isRequired,
  getApplicationResult: PropTypes.shape({
    application: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default SubmittedApplication;
