import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Tooltip,
} from "@mui/material";
import Table from "@components/PanelDisplayTable";
import EditIcon from "@mui/icons-material/Edit";
import { getFullName } from "@utils/stringHelpers";
import SiteOwnershipEditor from "./SiteOwnershipEditor";
import { find } from "lodash";
import EmailIcon from "@mui/icons-material/Email";
import { useAuth0 } from "@auth0/auth0-react";
import { ownershipTypes } from "@views/site/components/details/SiteDetails";
import { mapSiteOwner } from "@views/reviewApplication/sagas/getApplication";
import { PhoneLink, EmailLink } from "@components";

export const extractSiteOwnership = (application) => {
  const siteOwner = mapSiteOwner(application);
  const siteOwnership = {
    equipmentOwnership: application?.asset?.equipmentOwnership || "",
    fundingSource: application?.asset?.fundingSource || "",
    ownerType: application?.siteOwner?.ownerType,
    siteOwner, // this will have an organization, individual or external prop
  };
  return siteOwnership;
};

const SiteOwnershipPanel = ({
  application,
  resetPatchAppSiteOwnership,
  sendProgramTerms,
  sendProgramTermsResult,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // store site ownership from application in component state
  const [siteOwnership, setSiteOwnership] = useState();
  useEffect(() => {
    if (application?.asset) {
      setSiteOwnership(extractSiteOwnership(application));
    }
  }, [application]);

  useEffect(() => {
    resetPatchAppSiteOwnership();
  }, [resetPatchAppSiteOwnership]);

  // add sites action
  const [isSiteOwnershipEditorOpen, setIsSiteOwnershipEditorOpen] = useState(
    false
  );
  const handleEdit = () => {
    setIsSiteOwnershipEditorOpen(true);
  };
  const handleClose = () => {
    setIsSiteOwnershipEditorOpen(false);
  };

  const handleSave = useCallback(
    (siteOwnership) => {
      setSiteOwnership(siteOwnership);
      handleClose();
    },
    [setSiteOwnership]
  );

  // basically if you haven't updated site owner or primary contact this will fail
  const sendTerms = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });

    sendProgramTerms({
      accessToken,
      applicationId: application.id,
      programId: application.program.id,
    });
  };

  const missing = <Typography color="error.main">Missing</Typography>;

  const missingOwnerRow = {
    label: "Site Owner",
    value: missing,
  };

  const orgOwnerTableRows = [
    {
      label: "Site Owner",
      value: siteOwnership?.siteOwner?.organization?.name,
    },
    {
      label: "Primary Contact",
      value: getFullName(
        siteOwnership?.siteOwner?.organization?.primaryContact,
        null,
        missing
      ),
    },
    {
      label: "Primary Contact Email",
      value: <EmailLink email={siteOwnership?.siteOwner?.organization?.primaryContact?.email} missing={missing} />
    },
    {
      label: "Primary Contact Phone",
      value: <PhoneLink phone={siteOwnership?.siteOwner?.organization?.primaryContact?.phone} missing={missing} />
    },
  ];

  const singleOwner =
    siteOwnership?.siteOwner?.individual ||
    siteOwnership?.siteOwner?.external ||
    siteOwnership?.siteOwner?.contact;
  const singleOwnerTableRows = [
    {
      label: "Site Owner",
      value: getFullName(singleOwner),
    },
    {
      label: "Email",
      value: <EmailLink email={singleOwner?.email} />
    },
    {
      label: "Phone Number",
      value: <PhoneLink phone={singleOwner?.phone} />
    },
  ];

  const tableRows = [
    ...(siteOwnership?.ownerType === "INDIVIDUAL" ||
    siteOwnership?.ownerType === "EXTERNAL"
      ? singleOwnerTableRows
      : siteOwnership?.ownerType === "ORGANIZATION"
      ? orgOwnerTableRows
      : [missingOwnerRow]),
    {
      label: "Terms and Conditions Accepted",
      value: (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color={
              application?.acceptedProgramTermsAndConditions
                ? "inherit"
                : "error.main"
            }
          >
            {application?.acceptedProgramTermsAndConditions ? "Yes" : "No"}
          </Typography>
          <Tooltip title="Send program terms and conditions to site owner.">
            <span>
              <IconButton
                edge="end"
                color="primary"
                onClick={sendTerms}
                aria-label="send terms and conditions"
                size="small"
                disabled={application?.acceptedProgramTermsAndConditions}
              >
                <EmailIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      label: "Equipment Ownership",
      value:
        find(ownershipTypes, { value: siteOwnership?.equipmentOwnership })
          ?.label || "N/A",
    },
    {
      label: "Grant/Funding Source",
      value: siteOwnership?.fundingSource || "N/A",
    },
  ];

  const isApplicationApproved = application.status === "APPROVED";

  return (
    <>
      <Paper sx={{ mt: 2, px: 10, py: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Site Ownership
          </Typography>
          <Tooltip
            title={
              isApplicationApproved
                ? "Application already approved"
                : "Edit Site Information"
            }
          >
            <span>
              <IconButton
                aria-label="edit"
                color="primary"
                sx={{ mt: -1.5 }}
                onClick={handleEdit}
                disabled={isApplicationApproved}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <TableContainer>
          <Table aria-label="site profile">
            <TableBody>
              {tableRows.map((row) => (
                <TableRow key={row.label}>
                  <TableCell component="th" width="50%">
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {siteOwnership && (
        <SiteOwnershipEditor
          open={isSiteOwnershipEditorOpen}
          onClose={handleClose}
          onSave={handleSave}
          siteOwnership={siteOwnership}
        />
      )}
    </>
  );
};

SiteOwnershipPanel.propTypes = {
  application: PropTypes.shape({}),
  resetPatchAppSiteOwnership: PropTypes.func.isRequired,
  sendProgramTerms: PropTypes.func.isRequired,
  sendProgramTermsResult: PropTypes.shape({}),
};

export default SiteOwnershipPanel;
