import React, { useState } from "react";
import { Box, Grid, TextField, InputAdornment, IconButton, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { isNil } from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ConnectionTest from "./ConnectionTest";
import { Link } from "react-router-dom";

export const DataConnector = 'huaweiLocusNOC';

const PORTAL_URL = 'https://locusnoc.datareadings.com/';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={6}>
        <Controller
          as={
            <TextField
              label="Site Username"
              variant="standard"
              fullWidth
              placeholder="joe@company.com"
              autoFocus
              error={!isNil(errors.username?.message)}
              helperText={errors.username?.message || " "}
              autoComplete="new-username"
            />
          }
          name="username"
          control={control}
          rules={{ required: "Username is required." }}
          defaultValue={dataConnectorParams?.username || ""}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={
            <TextField
              label="Password"
              variant="standard"
              fullWidth
              placeholder="***"
              error={!isNil(errors.password?.message)}
              helperText={errors.password?.message || " "}
              autoComplete="new-password"
              type={values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
          name="password"
          control={control}
          rules={{ required: "Password is required." }}
          defaultValue={dataConnectorParams?.password || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} mb={2} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Please enter your username and password for the Huawei LocusNOC portal.
      </Typography>
      <Typography>
        <Link href={PORTAL_URL} target="_new">
          {PORTAL_URL}
        </Link>
      </Typography>
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;
