import { call, put, takeLatest } from "redux-saga/effects";
import { createDevices } from "../actions";
import axios from "axios";
import { showAppMessage } from "@appMessage/actions";
import { requestErrorMessageExtractor } from "utils/errorHelpers";

const callApi = async (accessToken, customerProjectId, siteId, payload) => await axios.post(
  `${process.env.REACT_APP_SURGE_API}/api/v2/customerProjects/${customerProjectId}/sites/${siteId}/devices`,
  payload,
  {
    headers: {
      Authorization: `Bearer: ${accessToken}`,
    },
  }
);

function* createDevicesWorker(action) {
  yield put(createDevices.request());
  try {
    const { accessToken, customerProjectId, siteId, payload } = action.payload;
    const response = yield call(callApi, accessToken, customerProjectId, siteId, payload);
    yield put(createDevices.success({ devices: response.data, accessToken, customerProjectId, siteId }));
  } catch (err) {
    let message = requestErrorMessageExtractor(err);
    message = `Error creating devices: ${message}`;
    yield put(createDevices.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* createDevicesWatcher() {
  yield takeLatest(createDevices.TRIGGER, createDevicesWorker);
}

export default [createDevicesWatcher];
