import React from "react";
import { Typography, Grid, TextField, Box, List, ListItem, ListItemIcon, ListItemText, Link } from "@mui/material";
import BulletIcon from "@mui/icons-material/AssignmentTurnedIn";
import { isNil } from "lodash";
import { useFormContext, Controller } from "react-hook-form";
import ConnectionTest from "./ConnectionTest";

export const DataConnector = 'froniusSolarWeb';

const Form = ({ dataConnectorParams }) => {
  const { errors, control } = useFormContext();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              label="System URL"
              variant="standard"
              fullWidth
              placeholder="https://www.solarweb.com/PvSystemSettings/Permissions?pvSystemId=6a98063d-a0f1-13bd-a6fe-765c09823ks"
              autoFocus
              error={!isNil(errors.froniusAccessUrl?.message)}
              helperText={errors.froniusAccessUrl?.message || " "}
            />
          }
          name="froniusAccessUrl"
          control={control}
          rules={{ required: "Fronius System URL is required." }}
          defaultValue={dataConnectorParams?.froniusAccessUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mt={2}>
          <ConnectionTest />
        </Box>
      </Grid>
    </Grid>
  );
};

const Instructions = () => {
  return (
    <Box mt={4} pt={4} borderTop={1} borderColor="divider">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Instructions to Grant Rewatt Access
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Login to the Fronius SolarWeb platform, using this link"
            secondary={
              <Link href="https://www.solarweb.com" target="_blank" rel="noopener">
                https://www.solarweb.com
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText primary="Click on the site you want to onboard with Rewatt" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Share with Rewatt"
            secondary="Navigate to Settings (not User Settings) -> Permissions -> Add"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="For E-mail address, enter service@rewattpower.com"
            secondary="For rights, select Guest and click OK"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="Copy and paste the link URL from the browser (NOT the guest access URL)"
            secondary="eg. https://www.solarweb.com/PvSystemSettings/Permissions?pvSystemId=72f5bd60-3112-4c44-ad89-a64b01843ab6"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BulletIcon />
          </ListItemIcon>
          <ListItemText
            primary="For more detailed instructions and screenshots, see this page."
            secondary={
              <Link href="https://rewattpower.com/fronius/" target="_blank" rel="noopener">
                https://rewattpower.com/fronius/
              </Link>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

const toExport = {
  DataConnector,
  Instructions,
  Form
};

export default toExport;