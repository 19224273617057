import patchApplicationEditDetails from "./patchApplicationEditDetails";
import patchApplicationAddDocuments from "./patchApplicationAddDocuments";
import patchApplicationAddMeters from "./patchApplicationAddMeters";

const reducers = {
  patchApplicationEditDetails,
  patchApplicationAddDocuments,
  patchApplicationAddMeters,
};

export default reducers;
