import { call, put, takeLatest } from "redux-saga/effects";
import { patchProjectReport } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import ValidationErrors from "@components/ValidationErrors";
import { flattenObject } from "@utils/errorHelpers";

const callApi = async (accessToken, projectReport) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projectReports/${projectReport.id}`,
    projectReport,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* patchProjectReportWorker(action) {
  const { accessToken, projectReport, key } = action.payload;
  yield put(patchProjectReport.request({ key }));

  try {
    const response = yield call(callApi, accessToken, projectReport);
    yield put(patchProjectReport.success({ projectReport: response.data }));
    yield put(
      showAppMessage({
        severity: "success",
        message: "Saved project report.",
        duration: 3000,
      })
    );
  } catch (err) {
    let message, formattedMessage;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error patching project report: ", err.response);
      const validationErrors = err.response.data?.validationErrors;
      if (validationErrors) {
        formattedMessage = <ValidationErrors validationErrors={validationErrors} />;
        message = JSON.stringify(flattenObject(validationErrors));
      } else {
        message = err.response.data.reason || err.response.data.message;
      }
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error patching project report: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error patching project report: ", err);
      message = err.message;
    }
    message = `Unable to patch project report: ${message}`;
    yield put(patchProjectReport.failure({ error: message }));
    yield put(
      showAppMessage({
        severity: "error",
        message: formattedMessage || message,
      })
    );
  }
}

function* patchProjectReportWatcher() {
  yield takeLatest(patchProjectReport.TRIGGER, patchProjectReportWorker);
}

export default [patchProjectReportWatcher];
