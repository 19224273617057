import { call, put, takeLatest, select } from "redux-saga/effects";
import { getSites } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";
import { getUserProfile } from "@views/common/selectors";
import { map, find, includes, omitBy, isNil } from "lodash";

const callApi = async (
  accessToken,
  organizationId,
  page,
  pageSize,
  order,
  search
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SURGE_API}/api/v2/organizations/${organizationId}/assets`,
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
      params: omitBy(
        {
          page,
          pageSize,
          order,
          search,
        },
        isNil
      ),
    }
  );
  return response;
};

export const transformSites = (sites) =>
  map(sites, (site) => {
    const mainAddress = find(site.addresses, (address) => includes(address.types, "MAIN"));
    return {
      ...site,
      isMissingInfo: true,
      location: {
        street: mainAddress?.street,
        city: mainAddress?.city,
        region: mainAddress?.region,
      },
      status: {
        status: site.status,
        timeSinceReport: site.timeSinceReport,
        timeInStatus: site.timeInStatus,
      },
    };
  });

export function* getSitesWorker(action) {
  yield put(getSites.request());

  const userProfile = yield select(getUserProfile);
  const { accessToken, page, pageSize, order, search } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      userProfile.organizationId,
      page,
      pageSize,
      order?.replace(/^location/, "city"),
      search
    );
    const { assets, total } = response.data;
    yield put(
      getSites.success({ sites: transformSites(assets), total: Number(total) })
    );
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error getting sites: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error getting sites: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error getting sites: ", err);
      message = err.message;
    }
    message = `Unable to get sites: ${message}`;
    yield put(getSites.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

export function* getSitesWatcher() {
  yield takeLatest(getSites.TRIGGER, getSitesWorker);
}

export default [getSitesWatcher];
