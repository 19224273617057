import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Container, Paper } from "@mui/material";
import Page from "@components/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { get, join, map } from "lodash";
import { DateTime } from "luxon";
import { getDefaultLocale } from "@utils/dateHelpers";
import HeroIcon from "@components/HeroIcon";
import { LinkIcon } from "@heroicons/react/solid";
import ViewIcon from "@mui/icons-material/Visibility";
import CopyProgramLink from "./CopyProgramLink";
import CreateProgramEditor from "../containers/CreateProgramEditor";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "@components/CustomDataGrid";
import {
  PROGRAM_TYPES,
  TARGET_PARTICIPANTS,
  EMISSION_REDUCTION_ACTIVITIES,
} from "@views/program/components/Program";
import PageHeader from "@components/PageHeader";
import { useDispatch } from "react-redux";
import { getProgramSiteStatuses, getProgram } from "@views/program/actions";
import { renderCellExpand } from "@components/GridCellExpand";

const Programs = ({
  getPrograms,
  getProgramsResult,
  createProgram,
  createProgramResult,
  setProgramTableState,
  programTableState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get programs on load
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function init() {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      getPrograms({ accessToken });
    }
    init();
  }, [getAccessTokenSilently, getPrograms]);
  const { programs } = getProgramsResult;

  const programsLoading =
    getProgramsResult.status === "request" || getProgramsResult.status === "init";

  // create programs action
  const [isProgramEditorOpen, setIsProgramEditorOpen] = useState(false);
  const handleOpenProgramEditor = () => {
    setIsProgramEditorOpen(true);
    dispatch(getProgramSiteStatuses.reset());
  };
  const handleCloseProgramEditor = () => {
    setIsProgramEditorOpen(false);
  };

  // public link
  const [program, setProgram] = useState(null);

  // column definitions
  const columns = React.useMemo(
    () => [
      {
        headerName: "Program Name",
        field: "name",
        flex: 1,
        renderCell: renderCellExpand,
      },
      {
        headerName: "Creation Date",
        field: "created",
        flex: 1,
        hide: true,
        valueFormatter: ({ value }) =>
          DateTime.fromSeconds(value)
            .setLocale(getDefaultLocale())
            .toLocaleString(DateTime.DATE_SHORT),
      },
      {
        headerName: "Program Type",
        flex: 1,
        field: "type",
        valueGetter: ({ row: program }) => get(PROGRAM_TYPES, program.type, program.type),
        renderCell: renderCellExpand,
      },
      {
        headerName: "Emission Reduction Activity",
        flex: 1,
        field: "emissionReductionActivity",
        valueGetter: ({ row: program }) =>
          get(
            EMISSION_REDUCTION_ACTIVITIES,
            program.emissionReductionActivity,
            program.emissionReductionActivity
          ),
        renderCell: renderCellExpand,
      },
      {
        headerName: "Target Participants",
        flex: 1,
        field: "targetParticipants",
        sortable: false,
        valueGetter: ({ row: program }) =>
          join(
            map(program.targetParticipants, (tp) => get(TARGET_PARTICIPANTS, tp, tp)),
            ", "
          ),
        renderCell: renderCellExpand,
      },
      {
        field: "shareLinkAction",
        headerName: "Share",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={(ev) => {
                const program = params.row;
                setProgram(program);
              }}
              aria-label="view"
              size="small"
              sx={{ ml: 1 }}
            >
              <HeroIcon icon={LinkIcon} />
            </IconButton>
          );
        },
      },
      {
        field: "viewAction",
        headerName: "View",
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={(ev) => {
                const program = params.row;
                dispatch(getProgram.reset());
                navigate(`/programs/${program.id}`);
              }}
              aria-label="view"
              size="small"
              sx={{ ml: 1 }}
            >
              <ViewIcon />
            </IconButton>
          );
        },
      },
    ],
    [navigate, dispatch]
  );

  const { page, pageSize, sortModel, filterModel } = programTableState;
  return (
    <Page title="Program List" py={3}>
      <Container maxWidth="lg">
        <PageHeader
          title="Program List"
          action={{
            name: "Create Program",
            handle: handleOpenProgramEditor,
          }}
        />
        <Paper sx={{ mt: 3 }}>
          <div style={{ height: "100%", width: "100%" }}>
            <CustomDataGrid
              style={{ border: 0 }}
              autoHeight
              loading={programsLoading}
              rows={programs}
              columns={columns}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(sortModel) => setProgramTableState({ sortModel })}
              page={page}
              onPageChange={(page) => setProgramTableState({ page })}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => setProgramTableState({ pageSize })}
              rowsPerPageOptions={[10, 20, 50]}
              filterModel={filterModel}
              onFilterModelChange={(filterModel) => setProgramTableState({ filterModel })}
            />
            <CopyProgramLink
              program={program}
              open={program !== null}
              handleClose={() => setProgram(null)}
            />
            <CreateProgramEditor open={isProgramEditorOpen} onClose={handleCloseProgramEditor} />
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

Programs.propTypes = {
  getPrograms: PropTypes.func.isRequired,
  getProgramsResult: PropTypes.shape({}).isRequired,
  setProgramTableState: PropTypes.func.isRequired,
  programTableState: PropTypes.shape({}).isRequired,
};

export default Programs;
