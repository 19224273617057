import React from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { isNil } from "lodash";
import { parseAddress } from "@utils/locationHelpers";
import { AddressTextField } from "@addSites/sitePicker";
import Places from "@components/Places";
import { mountingTypes } from "@views/site/components/details/SiteDetails";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const SiteProfileForm = () => {
  const { errors, control, setValue } = useFormContext();

  // from the autocomplete
  const populateAddress = React.useCallback(
    (newValue) => {
      const address = parseAddress(newValue);
      setValue("address.street", address.street, { shouldValidate: true });
      setValue("address.city", address.city, { shouldValidate: true });
      setValue("address.region", address.region, { shouldValidate: true });
      setValue("address.country", address.country, { shouldValidate: true });
      setValue("address.postal", address.postal, { shouldValidate: true });
    },
    [setValue]
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ pb: 4, mx: { xs: 1, sm: 2 } }}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Site Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    label="Site Name"
                    variant="standard"
                    fullWidth
                    error={!isNil(errors.name?.message)}
                    helperText={errors.name?.message}
                    required
                    onChange={onChange}
                    value={value}
                    color="secondary"
                  />
                )}
                control={control}
                name="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => {
                  return (
                    <TextField
                      label="Capacity (Watts)"
                      variant="standard"
                      fullWidth
                      error={!isNil(errors.capacity?.message)}
                      helperText={errors.capacity?.message}
                      required
                      onChange={onChange}
                      value={value}
                      color="secondary"
                      type="number"
                    />
                  );
                }}
                control={control}
                name="capacity"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    variant="standard"
                    name="mountingType"
                    select
                    fullWidth
                    label="Mounting Type"
                    value={value}
                    onChange={onChange}
                    error={!isNil(errors.mountingType?.message)}
                    helperText={errors.mountingType?.message}
                  >
                    {mountingTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
                name="mountingType"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({ onChange, value }) => (
                  <DatePicker
                    label="Installation Date"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        fullWidth
                        {...params}
                        error={!isNil(errors.completionDate?.message)}
                        helperText={errors.completionDate?.message}
                      />
                    )}
                    disableMaskedInput
                  />
                )}
                control={control}
                name="completionDate"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Main Address
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Places changeHandler={populateAddress} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.street"
                label="Street"
                placeholder="621 23 Ave NE"
                helperText="Street and number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.city"
                label="City"
                placeholder="Calgary"
                helperText="City, town or municipality"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.region"
                label="Province/State/Region"
                placeholder="Alberta"
                helperText="Full region name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.country"
                label="Country"
                placeholder="Canada"
                helperText="Full country name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AddressTextField
                errors={errors}
                control={control}
                name="address.postal"
                label="Postal Code"
                placeholder="T2E 1W5"
                helperText="Postal or zip code"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default SiteProfileForm;
