import { call, put, takeLatest } from "redux-saga/effects";
import { patchActivityStartDate } from "../actions";
import axios from "axios";
import logger from "debug";
import { showAppMessage } from "@appMessage/actions";

const callApi = async (accessToken, projectId, orgAssetId, activityStartDate) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_SURGE_API}/api/v2/admin/projects/${projectId}/assets/${orgAssetId}`,
    {
      activityStartDate,
    },
    {
      headers: {
        Authorization: `Bearer: ${accessToken}`,
      },
    }
  );
  return response;
};

function* patchActivityStartDateWorker(action) {
  yield put(patchActivityStartDate.request());

  const { accessToken, projectId, orgAssetId, activityStartDate } = action.payload;

  try {
    const response = yield call(
      callApi,
      accessToken,
      projectId,
      orgAssetId,
      activityStartDate.toISODate(),
    );
    const { asset } = response.data;
    yield put(patchActivityStartDate.success({ orgAsset: asset }));
  } catch (err) {
    let message;
    if (err.response) {
      // client received an error response (5xx, 4xx)
      logger("weedle:error")("Error updating activity start date: ", err.response);
      message = err.response.data.reason || err.response.data.message;
    } else if (err.request) {
      // client never received a response, or request never left
      logger("weedle:error")("Error updating activity start date: ", err.request);
      message = err.message;
    } else {
      // anything else
      logger("weedle:error")("Error updating activity start date: ", err);
      message = err.message;
    }
    message = `Unable to update activity start date: ${message}`;
    yield put(patchActivityStartDate.failure({ error: message }));
    yield put(showAppMessage({ severity: "error", message }));
  }
}

function* patchActivityStartDateWatcher() {
  yield takeLatest(patchActivityStartDate.TRIGGER, patchActivityStartDateWorker);
}

export default [patchActivityStartDateWatcher];
