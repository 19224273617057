import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Collapse,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Input";
import BusinessIcon from "@mui/icons-material/Business";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { getUserProfile } from "@views/common/actions";
import { CollectionIcon } from "@heroicons/react/solid";
import { getFullName } from "@utils/stringHelpers";
import { useNavigate } from "react-router-dom";
import { startsWith, includes, get } from "lodash";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ProjectsIcon from "@mui/icons-material/AccountTree";
import HeroIcon from "@components/HeroIcon";
import WorkIcon from '@mui/icons-material/Work';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import GroupsIcon from '@mui/icons-material/Groups';
import { AttachMoney } from "@mui/icons-material";

const ROLES_CLAIM = "https://rewattpower.com/roles";

const NavBar = ({
  onMobileClose,
  openMobile,
  organization,
  resetUserProfile,
  getUserProfileResult,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { logout, getIdTokenClaims } = useAuth0();

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    const checkSuperAdmin = async () => {
      const claims = await getIdTokenClaims();
      const roles = get(claims, ROLES_CLAIM);
      setIsSuperAdmin(includes(roles, "SuperAdmin"));
    };
    checkSuperAdmin();
  }, [getIdTokenClaims]);

  // folding stragegy:
  // all closed to start
  // if buyer, open up marketplace
  // if channel partner, open up channel partner
  // if !buyer, open nothing
  const [isBuyer, setIsBuyer] = useState(false);
  useEffect(() => {
    const checkIsBuyer = async () => {
      const claims = await getIdTokenClaims();
      const roles = get(claims, ROLES_CLAIM);
      if (includes(roles, "Buyer")) {
        setIsBuyer(true);
        setMarketplaceOpen(true);
      } else if (organization?.isChannelPartner) {
        setIsBuyer(false);
        setChannelPartnerOpen(true);
      } else {
        setIsBuyer(false);
      }
    };
    checkIsBuyer();
  }, [getIdTokenClaims, isSuperAdmin, organization?.isChannelPartner]);

  const logoutWithRedirect = () => {
    resetUserProfile();
    logout({
      returnTo: window.location.origin,
    });
  };

  const [marketplaceOpen, setMarketplaceOpen] = useState(false);
  const toggleMarketplace = () => {
    setMarketplaceOpen(!marketplaceOpen);
  };

  const [channelPartnerOpen, setChannelPartnerOpen] = useState(false);
  const toggleChannelPartner = () => {
    setChannelPartnerOpen(!channelPartnerOpen);
  };

  const [adminMarketplaceOpen, setAdminMarketplaceOpen] = useState(false);
  const toggleAdminMarketplace = () => {
    setAdminMarketplaceOpen(!adminMarketplaceOpen);
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" p={2}>
        <Typography color="textPrimary" variant="body1">
          {getFullName(getUserProfileResult?.userProfile)}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {organization?.name}
        </Typography>
      </Box>
      <Divider />
      <List
        aria-labelledby="General"
        subheader={<ListSubheader component="div">GENERAL</ListSubheader>}
      >

        <ListItemButton
          onClick={() => navigate("/projects")}
          selected={startsWith(location.pathname, "/projects")}
        >
          <ListItemIcon>
            <HeroIcon icon={CollectionIcon} />
          </ListItemIcon>
          <ListItemText primary="Projects" />
        </ListItemButton>

        {isBuyer && (
          <>
            <ListItemButton onClick={toggleMarketplace}>
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary="Marketplace" />
              {marketplaceOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={marketplaceOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() => navigate("/marketplace/portfolio")}
                  selected={startsWith(location.pathname, "/marketplace/portfolio")}
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Portfolio" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigate("/marketplace/purchase")}
                  selected={startsWith(location.pathname, "/marketplace/purchase")}
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <LocalAtmIcon />
                  </ListItemIcon>
                  <ListItemText primary="Purchase Credits" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigate("/marketplace/claimedCredits")}
                  selected={startsWith(location.pathname, "/marketplace/claimedCredits")}
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <EnergySavingsLeafIcon />
                  </ListItemIcon>
                  <ListItemText primary="Claimed Credits" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}

        {organization?.isChannelPartner && (
          <>
            <ListItemButton onClick={toggleChannelPartner}>
              <ListItemIcon>
                <HandshakeIcon />
              </ListItemIcon>
              <ListItemText primary="Channel Partner" />
              {marketplaceOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={channelPartnerOpen} timeout="auto" unmountOnExit>
              <ListItemButton
                onClick={() => navigate("/channelPartner/referrals")}
                selected={startsWith(location.pathname, "/channelPartner/referrals")}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Referrals" />
              </ListItemButton>
              <ListItemButton
                onClick={() => navigate("/channelPartner/projects")}
                selected={startsWith(location.pathname, "/channelPartner/projects")}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Public Projects" />
              </ListItemButton>
            </Collapse>
          </>
        )}
      </List>
      <Divider />
      <List
        aria-labelledby="Settings"
        subheader={<ListSubheader component="div">SETTINGS</ListSubheader>}
      >
        <ListItemButton
          onClick={() => navigate("/settings/account")}
          selected={startsWith(location.pathname, "/settings/account")}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate("/settings/organization")}
          selected={startsWith(location.pathname, "/settings/organization")}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Organization" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate("/settings/members")}
          selected={startsWith(location.pathname, "/settings/members")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Members" />
        </ListItemButton>
      </List>
      <Divider />

      {isSuperAdmin && (
        <>
          <List
            aria-labelledby="Admin"
            subheader={<ListSubheader component="div">ADMIN</ListSubheader>}
          >
            <ListItemButton
              onClick={() => navigate("/admin/monitoring")}
              selected={startsWith(location.pathname, "/admin/monitoring")}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Monitoring" />
            </ListItemButton>
            <ListItemButton
              onClick={() => navigate("/admin/projects")}
              selected={startsWith(location.pathname, "/admin/projects")}
            >
              <ListItemIcon>
                <ProjectsIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItemButton>
            <ListItemButton onClick={toggleAdminMarketplace}>
              <ListItemIcon>
                <HeroIcon icon={StorefrontIcon} />
              </ListItemIcon>
              <ListItemText primary="Marketplace" />
                {adminMarketplaceOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={adminMarketplaceOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() => navigate("/admin/marketplace/buyers")}
                  selected={startsWith(location.pathname, "/admin/marketplace/buyers")}
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Buyers" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigate("/admin/marketplace/purchaseRequests")}
                  selected={startsWith(location.pathname, "/admin/marketplace/purchaseRequests")}
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Purchase Requests" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigate("/admin/marketplace/payeeStatements")}
                  selected={startsWith(location.pathname, "/admin/marketplace/payeeStatements")}
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <AttachMoney />
                  </ListItemIcon>
                  <ListItemText primary="Payee Statements" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              onClick={() => navigate("/admin/channelPartners")}
              selected={startsWith(location.pathname, "/admin/channelPartners")}
            >
              <ListItemIcon>
                <HandshakeIcon />
              </ListItemIcon>
              <ListItemText primary="Channel Partners" />
            </ListItemButton>
          </List>
          <Divider />
        </>
      )}

      <List>
        <ListItemButton onClick={logoutWithRedirect}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{
            ".MuiDrawer-paper": {
              width: "256px",
            },
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          sx={{
            ".MuiDrawer-paper": {
              width: "256px",
              top: "66px !important",
              height: "calc(100% - 66px) !important",
            },
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  organization: PropTypes.shape({
    name: PropTypes.string,
  }),
  resetUserProfile: PropTypes.func.isRequired,
  getUserProfileResult: PropTypes.shape({}).isRequired,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
  organization: {
    name: "Unknown Org",
  },
};

// connect to redux

const mapStateToProps = ({ getUserProfile: getUserProfileResult }) => ({
  getUserProfileResult,
});

const mapDispatchToProps = (dispatch) => ({
  resetUserProfile: () => dispatch(getUserProfile.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
