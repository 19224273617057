import { setApplicationsTableState } from "../actions";

export const initialState = {
  page: 0,
  pageSize: 10,
  sortModel: [
    {
      field: "created",
      sort: "desc",
    },
  ],
  filterModel: {
    items: [
      {
        columnField: 'status',
        operatorValue: 'equals',
        value: 'READY_FOR_REVIEW'
      }
    ],
    linkOperator: "and",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setApplicationsTableState.TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
