import { getApplicationNotes } from "../actions";

export const initialState = {
  status: "init",
  notes: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getApplicationNotes.REQUEST: {
      return {
        ...state,
        status: "request",
        error: null,
      };
    }

    case getApplicationNotes.SUCCESS: {
      const { notes } = action.payload;
      return {
        ...state,
        status: "success",
        notes,
      };
    }

    case getApplicationNotes.FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        status: "failure",
        error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
